import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from "agtech/web/components/Buttons/AgTechButtons";
import { AgTechFormReadOnlyTextControl, AgTechFormRow, AgTechFormSection } from "agtech/web/components/Forms/AgTechForm";
import AgTechFormDialog from "agtech/web/components/Portals/Dialogs/AgTechFormDialog";
import { ChangeItemOnInvoiceDTO, useContactCreditsDataGetCreditsByInvoiceIdForChange, useCreditsDataWriteCreditInvoiceChangesAction } from "app/data/lots/invoices/LotInvoiceDataActions";
import { ContactCredit } from "app/data/operation/contacts/credits/ContactDataCredits";
import InvoiceChangeCreditsGrid from "../components/grids/InvoiceChangeCreditsGrid";
import { getContactFullName, useContact } from "app/data/operation/contacts/ContactData";
import ContactDialog from "app/areas/contacts/dialogs/ContactDialog";
import ContactInformationPopUp from "app/areas/contacts/components/popups/ContactInformationPopUp";
import { AgTechEntitySelectionPreservedOriginal } from "agtech/web/components/Grids/AgTechGridEntities";

export type InvoiceChangeContactCreditsDialogProps = {
    invoiceId: number,
    contactId: number
}

export type SelectableCreditsData = {
    Credits: AgTechEntitySelectionPreservedOriginal<ContactCredit>[],
}

const InvoiceChangeContactCreditsDialog = (props: InvoiceChangeContactCreditsDialogProps) => {
    let contact = useContact(props.contactId);

    let getCreditsAction = useContactCreditsDataGetCreditsByInvoiceIdForChange();
    let writeAction = useCreditsDataWriteCreditInvoiceChangesAction(props.invoiceId);
    
    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: e => e.executeAndConvertRequest({
                    request: getCreditsAction,
                    inputData: {contactId: props.contactId, invoiceId: props.invoiceId},
                    convert: async c => {
                        let ldata: SelectableCreditsData = {
                            Credits: c.map<AgTechEntitySelectionPreservedOriginal<ContactCredit>>(l => ({
                                id: l.id,
                                entity: l,
                                isSelected: l.invoiceId === props.invoiceId,
                                originalSelection: l.invoiceId === props.invoiceId
                            }))
                        }

                        return ldata;
                    }
                })
            }} 
            formAction={{
                dataAction: writeAction,
                getActionData: d => {
                    let filtered = d.Credits.filter(d => d.isSelected != d.originalSelection);

                    return filtered.map<ChangeItemOnInvoiceDTO>(l => ({remove: l.originalSelection == true && l.isSelected == false, id: l.entity.id}))
                }
            }}
            structure={{
                header: 'Update Invoice Credits',
                submitButtonText: 'Update Credits',
                width: 600
            }}
         >
            {(formContext) => (
                <>
                    <h1></h1>
                    <AgTechFormRow>
                        <AgTechFormReadOnlyTextControl
                            caption="Contact Name"
                            value={() => getContactFullName(contact)}
                            entityType={contact}
                            onClick={e => ContactDialog.Open(e, {
                                contactId: contact.id
                            })}
                            showPopUpOnHover={e => ({
                                popup: () => <ContactInformationPopUp contactId={contact.id} />
                            })}
                        />
                    </AgTechFormRow>
                    <AgTechFormSection
                        header="Credits"
                        classes='flexed'
                        bodyClasses='flexed pt-3'
                    >
                        <InvoiceChangeCreditsGrid formContext={formContext} />
                    </AgTechFormSection>  
                </>
            )}
        </AgTechFormDialog>
    )
}

InvoiceChangeContactCreditsDialog.Open = (e: AgTechClickEventHandler, props: InvoiceChangeContactCreditsDialogProps) => e.dialogs.openDialog({
    dialog: () => <InvoiceChangeContactCreditsDialog {...props} />
});

InvoiceChangeContactCreditsDialog.Button = (props: AgTechButtonProps & InvoiceChangeContactCreditsDialogProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'Change Credits',
        bootstrapIcon: 'bi-arrow-left-right',
        ...props,
        action: e => InvoiceChangeContactCreditsDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default InvoiceChangeContactCreditsDialog;