import { AgTechAppLoadingStep } from "agtech/core/app/AgTechApplication";
import { useAgTechAuthContext } from "agtech/core/auth/AgTechAuthContext";
import { AgTechWebApplication } from "agtech/web/application/AgTechWebApplication";
import AgTechWebAppLoginPage from "agtech/web/components/Auth/AgTechWebAppLoginPage";
import React, { useEffect, useState } from "react";

const AgTechWebAppLoadingPage = (props: React.PropsWithChildren<{ app: AgTechWebApplication }>) => {
    let authContext = useAgTechAuthContext();

    let hasUserLoggedIn = authContext.user.token || !(props.app.auth.usesAuth ?? true);
    let [hasAppFinishedLoading, setHasAppFinishedLoading] = useState(false);

    return (
        <div className="agtech-web-app" id="agtech-web-app">
            {hasAppFinishedLoading ? (
                <>
                    {props.children}
                </>
            ) : (
                <div className="agtech-web-app-loading-page-surface" style={{
                    backgroundImage: `url(${props.app.backgroundImage})`
                }}>
                    <div className="agtech-web-app-loading-page-content">
                        <div className="agtech-web-app-loading-logo">
                            <i className={`agtech-web-app-loading-icon fas fa-${props.app.icon}`} />
                            <h1 className="agtech-web-app-loading-name">{props.app.name}</h1>
                        </div>
                        <div className="agtech-web-app-loading-body">
                            { hasUserLoggedIn ? (
                                <AgTechWebAppLoader
                                    app={props.app}
                                    markAppAsLoaded={() => setHasAppFinishedLoading(true)}
                                />
                            ) : (
                                <AgTechWebAppLoginPage />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

const AgTechWebAppLoaderPage = (props: { app: AgTechWebApplication, markAppAsLoaded: () => void }) => {
    let [hasLoadingFailed, setHasLoadingFailed] = useState(false);

    let appLoadingStepDelegates = props.app.loadingSteps ?? [];
    let appLoadingSteps: AgTechAppLoadingStep[] = [];

    for (const step of appLoadingStepDelegates) {
        let loadingStep = step();
        appLoadingSteps.push(loadingStep);
    }
    
    useEffect(() => {
        setTimeout(() => {
            executeLoadingSteps(appLoadingSteps)
                .then(didAllStepsLoad => {
                    if (didAllStepsLoad) {
                       props.markAppAsLoaded();
                    }
                    else {
                        setHasLoadingFailed(true);
                    }
                })
        }, 4000);
    }, []);

    return hasLoadingFailed ? (
        <div className="agtech-web-app-loader">
            <h1 className="agtech-web-app-loader-text">Loading has failed. Please refresh the page.</h1>        
        </div>
    ) : (
        <div className="agtech-web-app-loader">
            <div className="loader" />
            <h1 className="agtech-web-app-loader-text">Loading application...</h1>
        </div>
    )
};

const AgTechWebAppLoader = React.memo(AgTechWebAppLoaderPage);

const executeLoadingSteps = async (appLoadingSteps: AgTechAppLoadingStep[]) => {
    let didAllStepsLoad = true;

        for (const step of appLoadingSteps) {
            let wasLoadedSuccessfully = await step.loadingAction();

            if (!wasLoadedSuccessfully) {
                didAllStepsLoad = false;
                break;
            }
        }

    return didAllStepsLoad;
};

export default React.memo(AgTechWebAppLoadingPage);