import React from 'react'
import AgTechLoadingSurface from 'agtech/web/components/Pages/Loading/AgTechLoadingSurface';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';

export enum AgTechCardStyle {
    Standard,
    Formal,
    SurfaceBody
}

export type AgTechCardLoadingConfig = {
    refreshWhenChanged: string,
    waitUntilLoaded: boolean,
    loadingText?: string
}

export type AgTechCoreCardProps = {
    height?: number,
    width?: number,
    title?: string,
    detail?: string,
    headerContent?: () => React.ReactNode,
    loadingConfig?: AgTechCardLoadingConfig,
    classes?: string,
    bodyClasses?: string,
    footerClasses?: string,
    style?: AgTechCardStyle,
    children?: React.ReactNode
}

export type AgTechCardProps = AgTechCoreCardProps & {
    header?: () => React.ReactNode,
    footer?: () => React.ReactNode,
};

let AgTechCard = (props: AgTechCardProps) => {
    return (
        <div
            className={`row-based bordered rounded bg-white container ${props.classes ?? ''}`}
            style={{ height: props.height ?? '', minHeight: props.height ?? '' }}
        >
            { props.loadingConfig ? (
                <AgTechLoadingSurface refreshWhenChanged={props.loadingConfig.refreshWhenChanged} text={props.loadingConfig.loadingText}>
                    <AgTechCardContent {...props} />
                </AgTechLoadingSurface>
            )
            : <AgTechCardContent {...props} />}
        </div>
    )
};

const AgTechCardContent = (props: AgTechCardProps) => (
    <>
        {props.header ?
            <div className="bottom-bordered wide px-3 py-2 container">
                {props.header()}
            </div>
            : props.title ?
            <div className="bottom-bordered wide px-3 py-4 container">
                <AgTechTitleDetailHeader
                    title={props.title}
                    detail={props.detail}
                >
                    {props.headerContent ? props.headerContent() : null}
                </AgTechTitleDetailHeader>
            </div>
            : null
        }

        { props.children ? (
            <div className={`flexed rounded-inherit scrollable scrollbar-hidden row-based container ${props.bodyClasses ?? ''}`}>
                {props.children}
            </div>
        ) : null}

        {props.footer ?
            <div className={`column-based-vertically-centered wide px-3 py-2-half container ${props.footerClasses ?? ''}`}>
                {props.footer()}
            </div>
            : null
        }
    </>
);

export default AgTechCard;