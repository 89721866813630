import React, { useContext } from "react";

export type AgTechDataContext = {
    baseURL: string
};

export const DataContext = React.createContext<AgTechDataContext>({
    baseURL: ''
});

export const useAgTechDataContext = () => useContext(DataContext);