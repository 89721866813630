import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechFormSection, AgTechFormRow, AgTechFormTextControl, AgTechFormDateControl } from 'agtech/web/components/Forms/AgTechForm'
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog'
import { DefaultSale, Sale, salesDataSlice } from 'app/data/sales/SaleData'
import { useSaleDataWriteSaleAction } from 'app/data/sales/SaleDataActions'
import React from 'react'

export type SaleDialogProps = {
    sale?: Sale,
    onSaleSubmitted?: (writtenSale: Sale) => Promise<void>
}

const SaleDialog = (props: SaleDialogProps) => {
    let saleWriteSubmission = useSaleDataWriteSaleAction();
    let initialSale = props.sale ?? DefaultSale;

    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: () => initialSale
            }}
            formAction={{
                dataAction: saleWriteSubmission,
                getActionData: d => d
            }}
            structure={{
                header: {
                    title: props.sale ? 'Update Sale' : 'Create Sale'
                },
                width: 450
            }}
            behavior={{
                eventHandlers: {
                    onDialogSubmitted: props.onSaleSubmitted
                }
            }}
        >
            <AgTechFormSection header="General">
                <AgTechFormRow>
                    <AgTechFormTextControl 
                        entityType={initialSale}
                        focused={true} 
                        required={true} 
                        caption="Name" 
                        value={d => d.saleName} 
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            saleName: val
                        })}
                        width={100}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
            <AgTechFormSection header="Dates">
                <AgTechFormRow>
                    <AgTechFormDateControl
                        entityType={initialSale}
                        caption="Start Date" 
                        value={d => d.saleStartDate} 
                        width={50} 
                        allowPreviousDates={false}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            saleStartDate: val
                        })}
                    />
                    <AgTechFormDateControl
                        entityType={initialSale}
                        caption="End Date" 
                        value={d => d.saleEndDate} 
                        width={50} 
                        allowPreviousDates={false}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            saleEndDate: val
                        })}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
        </AgTechFormDialog>
    )
}

SaleDialog.Open = (e: AgTechClickEventHandler, props: SaleDialogProps) => {
    return e.dialogs.openDialog({
        dialog: () => <SaleDialog {...props} />
    });
}

SaleDialog.Button = (props: AgTechButtonProps & SaleDialogProps) => {
    return (
        <AgTechButton
            text={props.sale ? 'Update Sale' : 'Create Sale'}
            icon={props.sale ? 'edit' : 'plus'}
            {...props}
            action={e => SaleDialog.Open(e, props)}
        />
    )
}

export default SaleDialog