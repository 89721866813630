import AgTechField, { AgTechFieldProps } from 'agtech/web/components/Fields/AgTechField'
import React from 'react'

export type AgTechFieldListProps = {
    fields: AgTechFieldProps[],
    classes?: string
}

const AgTechFieldList = (props: AgTechFieldListProps) => {
    return (
        <div className={`wide wrappable container ${props.classes ?? ''}`}>
            {props.fields.map(fld => fld.value ? (
                <div key={fld.name} className='mb-4 pr-4 mr-6 container'>
                    <AgTechField {...fld} />
                </div>
            ) : null)}
        </div>
    )
}

export default AgTechFieldList