import React, { useContext, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlMetadata, AgTechFormControlProps } from 'agtech/core/forms/AgTechFormControls';

export type AgTechFormDateControlProps<TEntity> = AgTechFormControlProps<TEntity, Date> & {
    allowPreviousDates?: boolean
}

export const AgTechFormDateControl = <TFormEntity, >(props: AgTechFormDateControlProps<TFormEntity>) => {
    let formContext = useContext(FormContext);
    let inputRef = useRef<any>(null);

    let [dateValue, updateDateValue] = useState<Date>(props.value(formContext.entity));

    let [field, updateField] = useState<AgTechFormControlMetadata<TFormEntity>>({
        id: props.caption,
        isValid: true,
        validateOnSubmit: (entity: any) => validateControlOnFormSubmit(entity),
    });

    let markFieldValid = () => updateField({...field, isValid: true });
    let markFieldInvalid = () => updateField({...field, isValid: false });

    let validateControlOnFormSubmit = (entity: any): string => {
        let fieldValidationWarningMessage = '';
        let controlValue = props.value(entity);

        if (props.validation?.validateOnSubmit) {
            fieldValidationWarningMessage = props.validation.validateOnSubmit(formContext.entity, controlValue) ?? '';
        }

        if (fieldValidationWarningMessage) {
            markFieldInvalid();
        }

        return fieldValidationWarningMessage;
    }

    let formControlWidth: string = `${props.width ?? 50}%`;
    let formControlStatus: string = field.isValid ? '' : 'invalid';

    useEffect(() => {
        formContext.registerFormField(field);

        if (props.focused) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, []);

    useEffect(() => {
        updateDateValue(props.value(formContext.entity))
    }, [props.value(formContext.entity)]);

    let handleDateChanged = (updatedDate: Date) => {
        updateDateValue(updatedDate);

        if (props.onControlValueChanged) {
            formContext.handleFieldChanged(updatedDate, props.onControlValueChanged);

            if (props.validation?.validateOnFocusOut && !props.validation.validateOnFocusOut(updatedDate)) {
                markFieldInvalid();
            }
            else if (!field.isValid) {
                markFieldValid();
            }
        }
    }

    let minDate = props.allowPreviousDates ? null : new Date();

    return (
        <div className={`agtech-form-control ${formControlStatus}`} style={{maxWidth: formControlWidth}}>
            <h1 className="agtech-form-control-caption">{props.caption + (props.required ? ' *' : '')}</h1>
            <DatePicker
                selected={dateValue}
                readOnly={props.readonly}
                ref={inputRef}
                required={props.required}
                onChange={(date) => handleDateChanged(date as Date)} minDate={minDate}
            />
        </div>
    );
}