import React, { ReactNode, useRef, useState } from "react";
import { AgTechForm } from "agtech/web/components/Forms/AgTechForm";
import { AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";
import { useDialogContext } from "agtech/web/components/Portals/Dialogs/AgTechDialogSlot";
import { AgTechDataActionEventHandlers } from "agtech/core/data/actions/AgTechDataActions";
import AgTechTitleDetailHeader, { AgTechTitleDetailHeaderProps, isTitleDetail } from "agtech/web/components/Headers/AgTechTitleDetailHeader";
import { AgTechFormContext } from "agtech/core/forms/AgTechFormContext";
import { delay } from "agtech/core/utilities/AgTechUtilities";
import { AgTechFormActionProps, AgTechFormDataProps } from "agtech/core/forms/AgTechFormProps";
import { logDev } from "agtech/core/logging/AgTechLogger";
import AgTechDataProvider from "agtech/core/data/providers/AgTechDataProvider";
import AgTechDialog from "agtech/web/components/Portals/Dialogs/AgTechDialog";
import AgTechDataActionSurface, { AgTechDataActionSurfaceConfirmationProps } from "agtech/core/data/actions/AgTechDataActionSurfaces";

export type AgTechFormDialogEventHandlers<TDialogOutput> = {
    onDialogSubmitted?: (dialogOutput: TDialogOutput) => Promise<void>,
    onDialogClosed?: () => Promise<void>
}

export type AgTechFormDialogProps<TFormData, TActionData, TResponseData> = {
    formData: AgTechFormDataProps<TFormData>,
    formAction: AgTechFormActionProps<TFormData, TActionData, TResponseData>,
    structure: AgTechFormDialogStructureProps,
    behavior?: AgTechFormDialogBehaviorProps<TActionData, TResponseData>,
    children: ((context: AgTechFormContext<TFormData>) => ReactNode) | ReactNode | ReactNode[],
    refreshWhenChanged?: boolean
}

export type AgTechFormDialogStructureProps = {
    header: string | AgTechTitleDetailHeaderProps,
    width: number,
    bodyClasses?: string,
    submitButtonText?: string,
    cancelButtonText?: string,
}

export type AgTechFormDialogBehaviorProps<TActionData, TResponseData> = {
    confirmation?: AgTechFormDialogConfirmationProps<TActionData, TResponseData>,
    eventHandlers?: AgTechFormDialogEventHandlers<TResponseData>,
}

export type AgTechFormDialogActions = {
    close: () => void,
    grow: (updatedWidth: number) => void
}

export type AgTechFormDialogConfirmationProps<TActionData, TResponseData> = {
    getConfirmationPane: (
        confirmationData: AgTechDataActionSurfaceConfirmationProps<TActionData, TResponseData>,
        actions: AgTechFormDialogActions
    ) => React.ReactNode
}

export const AgTechFormDialogComponent = <TFormData, TActionData, TResponseData>(
    props: AgTechFormDialogProps<TFormData, TActionData, TResponseData>
) => {
    let [dialogWidth, setDialogWidth] = useState(props.structure.width);

    return (
        <AgTechDialog width={dialogWidth}>
            <AgTechDataProvider
                loadData={props.formData.loadInitialFormData}
                content={{
                    loadingText: props.formData.loadingText
                }}
                retry={props.formData.retry ?? {
                    retryButtonText: 'Try again'
                }}
            >
                {(initialFormData) => (
                    <AgTechFormDialogSurface
                        {...props}
                        grow={(width) => setDialogWidth(width)}
                        initialFormData={initialFormData}
                    >
                        {(formContext) => typeof props.children === 'function' ? props.children(formContext) : props.children}
                    </AgTechFormDialogSurface>
                )}
            </AgTechDataProvider>
        </AgTechDialog>
    );
};

type AgTechFormDialogSurfaceProps<TFormData, TActionData, TResponseData> = {
    initialFormData: TFormData,
    formAction: AgTechFormActionProps<TFormData, TActionData, TResponseData>,
    structure: AgTechFormDialogStructureProps,
    behavior?: AgTechFormDialogBehaviorProps<TActionData, TResponseData>,
    children: ((context: AgTechFormContext<TFormData>) => ReactNode) | ReactNode | ReactNode[],
    refreshWhenChanged?: boolean,
    grow: (updatedWidth: number) => void
}

export const AgTechFormDialogSurface = <TFormData, TActionData, TResponseData>(
    props: AgTechFormDialogSurfaceProps<TFormData, TActionData, TResponseData>
) => {
    let formDialog = useAgTechFormDialog({
        formAction: props.formAction,
        behavior: props.behavior
    });

    return (
        <AgTechDataActionSurface
            action={props.formAction.dataAction}
            behavior={{
                eventHandlers: formDialog.dialogDataActionEventHandlers,
                confirmation: props.behavior?.confirmation ? {
                    getConfirmationPane: (data) => {
                        return props.behavior?.confirmation
                            ? props.behavior.confirmation.getConfirmationPane(data, {
                                close: formDialog.closeFormDialog,
                                grow: props.grow
                            })
                            : null;
                    }
                }
                : undefined
            }}
        >
            {() => (
                <>
                    <div className="agtech-dialog-header">
                        {isTitleDetail(props.structure.header) ? (
                            <AgTechTitleDetailHeader {...props.structure.header} />
                        ) : props.structure.header}
                    </div>
                    <div className="agtech-dialog-body">
                        <div className={`agtech-dialog-body-surface ${props.structure.bodyClasses ?? ''}`}>
                            <AgTechForm
                                initialFormData={props.initialFormData}
                                action={props.formAction}
                                structure={{
                                    submitButton: formDialog.dialogSubmissionButton,
                                    classes: 'pt-2 px-4'
                                }}
                                behavior={{
                                    refreshWhenChanged: props.refreshWhenChanged
                                }}
                            >
                                {(formContext) => typeof props.children === 'function' ? props.children(formContext) : props.children}
                            </AgTechForm>
                        </div>
                    </div>
                    <div className="agtech-dialog-footer">
                        <AgTechButton
                            text={props.structure.cancelButtonText ?? 'Cancel'}
                            icon="times"
                            action={formDialog.closeFormDialog}
                        />
                        <AgTechButton
                            text={props.structure.submitButtonText ?? 'Submit'}
                            icon="check"
                            classes='highlight'
                            action={() => formDialog.dialogSubmissionButton.current?.click()}
                        />
                    </div>
                </>
            )}
        </AgTechDataActionSurface>
    )
}

export const useAgTechFormDialog = <TFormData, TActionData, TResponseData>(props: {
    formAction: AgTechFormActionProps<TFormData, TActionData, TResponseData>,
    behavior?: AgTechFormDialogBehaviorProps<TActionData, TResponseData>,
}) => {
    let dialogContext = useDialogContext();

    let submissionButton = useRef<{ click: () => Promise<void> }>({
        click: async () => {}
    });

    let doesDialogShowConfirmationPane = props.behavior?.confirmation?.getConfirmationPane !== undefined;

    let dialogDataActionEventHandlers: AgTechDataActionEventHandlers<TActionData, TResponseData> = {
        onExecutionSuccessful: async actionProps => {
            let doesDialogShowConfirmationMessage =
                actionProps.actionConfiguration.showConfirmationMessage ?? true;

            if (doesDialogShowConfirmationMessage && !doesDialogShowConfirmationPane) {
                await delay(actionProps.actionConfiguration.actionConfirmationDelay ?? 400);
            }
        },
        onExecutionCompleted: async result => {
            if (props.behavior?.eventHandlers?.onDialogSubmitted && result.success) {
                logDev('Executing success handler');
                await props.behavior?.eventHandlers.onDialogSubmitted(result.success.data);
            }

            if (!props.behavior?.confirmation) {
                closeFormDialog();
            }
        }
    }

    const closeFormDialog = async () => {
        if (props.behavior?.eventHandlers?.onDialogClosed) {
            await props.behavior?.eventHandlers.onDialogClosed();
        }
        
        dialogContext.closeDialog(null);
    }

    return {
        dialogSubmissionButton: submissionButton,
        dialogDataActionEventHandlers,
        closeFormDialog
    }
}

const AgTechFormDialog = React.memo(AgTechFormDialogComponent) as typeof AgTechFormDialogComponent;

export default AgTechFormDialog;