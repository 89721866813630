import { AgTechWarningIcon } from 'agtech/web/components/Icons/AgTechIcons'
import React from 'react'

export type AgTechWarningMessageProps = {
    header: string,
    headerFontSize?: string,
    details?: string,
    detailsFontSize?: string,
    actionButton?: () => React.ReactNode
}

const AgTechWarningMessage = (props: AgTechWarningMessageProps) => {
    let headerFontSize = props.headerFontSize ?? 'font-size-large';
    let detailFontSize = props.detailsFontSize ?? 'font-size-medium';

    return (
        <div className='row-based stretched centered container'>
            <AgTechWarningIcon style={{ fontSize: 32 }} />
            <h1 className={`${headerFontSize} font-bold mt-6`}>{props.header}</h1>
            {props.details ? <h1 className={`${detailFontSize} font-color-secondary font-bold mt-3`}>{props.details}</h1> : null}
            {props.actionButton ? (
                <div style={{ marginTop: 40 }}>
                    {props.actionButton()}
                </div>
            ) : null}
        </div>
    )
}

export default AgTechWarningMessage