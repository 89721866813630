import React, { useEffect, useRef, useState } from 'react'
import AgTechSearchControl, { AgTechCoreSearchControlProps, AgTechSearchControlState } from '../Widgets/AgTechSearchControl';
import { AgTechCoreListProps } from './AgTechList';
import AgTechVirtualizedList from './AgTechVirtualizedList';
import AgTechNoRecordsPage, { AgTechNoRecordsPageProps } from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';

export type AgTechFormalListProps<TItem extends { id: string }> = AgTechCoreListProps<TItem> & {
    searchProps?: AgTechCoreSearchControlProps<TItem>,
    header?: {
        title: string,
        headerContent?: () => React.ReactNode
    },
    footer?: () => React.ReactNode,
    noRecordsConfig?: AgTechNoRecordsPageProps,
};

declare type AgTechFormalListState<TItem> = {
    searchState?: AgTechSearchControlState<TItem>
}

const AgTechFormalList = <TItem extends { id: string }>(props: AgTechFormalListProps<TItem>) => {
    let [listState, updateListState] = useState<AgTechFormalListState<TItem>>({});

    let visibleListItems = listState.searchState
        ? props.items.filter(item => listState.searchState?.selectedField.value(item).includes(listState.searchState.filter))
        : props.items;

    return props.items?.length > 0 ? (
        <div className='row-based stretched bordered rounded bg-white container'>
            {props.header ? (
                <div className='column-based-vertically-centered p-2-half bottom-bordered wide container'>
                    <h1 className='font-size-medium ml-1 font-bold'>
                        {props.header.title}
                    </h1>
                    <div className='ml-auto tall'>
                        {props.header.headerContent ? props.header.headerContent() : null}
                        {props.searchProps ? (
                            <AgTechSearchControl
                                {...props.searchProps}
                                onUpdateSearchState={updatedSearchState => updateListState({
                                    ...listState,
                                    searchState: updatedSearchState
                                })}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div className='flexed wide container'>
                <AgTechVirtualizedList
                    {...props}
                    items={visibleListItems}
                    hasFooter={props.footer !== undefined}
                />
            </div>
            {props.footer ? (
                <div className='column-based-vertically-centered wide bg-white top-bordered p-2-half container'>
                    {props.footer()}
                </div>
            ) : null}
        </div>
    ) : (
        <AgTechNoRecordsPage {...(props.noRecordsConfig ?? { message: 'There are no records to show in this list' })} />
    )
}

export default AgTechFormalList