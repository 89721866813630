import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechFormRow, AgTechFormDateControl, AgTechFormTextAreaControl, AgTechFormReadOnlyTextControl } from 'agtech/web/components/Forms/AgTechForm';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import { getContactFullName, useContact } from 'app/data/operation/contacts/ContactData';
import { ContactNote, useContactNoteDataWriteNoteAction } from 'app/data/operation/contacts/notes/ContactDataNotes';
import React from 'react'

export type ContactNoteDialogProps = {
    contactId: number,
    note?: ContactNote
}

const ContactNoteDialog = (props: ContactNoteDialogProps) => {
    let contact = useContact(props.contactId);
    let noteSubmission = useContactNoteDataWriteNoteAction(props.contactId);

    let contactNote = props.note ?? {
        id: 0,
        contactId: props.contactId,
        note: '',
        date: new Date()
    };

    return (
        <AgTechFormDialog
            structure={{
                header: props.note ? 'Update Note' : 'Create Note',
                submitButtonText: props.note ? 'Update' : 'Create',
                width: 500
            }}
            formData={{
                loadInitialFormData: () => contactNote
            }}
            formAction={{
                dataAction: noteSubmission,
                getActionData: d => d
            }}
        >
            <AgTechFormRow>
                <AgTechFormReadOnlyTextControl
                    entityType={contactNote}
                    caption='Contact'
                    value={() => getContactFullName(contact)}
                />
                <AgTechFormDateControl
                    entityType={contactNote}
                    caption='Date'
                    value={note => note.date}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        date: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextAreaControl
                    entityType={contactNote}
                    caption='Note'
                    value={note => note.note}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        note: val
                    })}
                    rows={5}
                    width={100} 
                    required={true}
                    focused={true}
                />
            </AgTechFormRow>
        </AgTechFormDialog>
    )
};

ContactNoteDialog.Open = (e: AgTechClickEventHandler, props: ContactNoteDialogProps) => e.dialogs.openDialog({
    dialog: () => <ContactNoteDialog {...props} />
});

ContactNoteDialog.Button = (props: ContactNoteDialogProps & AgTechButtonProps) => (
    <AgTechButton
        text={props.note ? 'Update Note' : 'Create Note'}
        icon={props.note ? 'edit' : 'plus'}
        {...props}
        action={e => ContactNoteDialog.Open(e, props)}
    />
);

export default ContactNoteDialog;