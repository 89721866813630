import * as React from 'react';
import 'agtech/web/styling/css/custom.css'
import 'agtech/web/styling/css/all.css'
import "react-datepicker/dist/react-datepicker.css";
import 'agtech/web/styling/css/AgTech/AgTech.css'
import AgTechWebApp from 'agtech/web/components/App/AgTechWebApp';
import useSalesWebApp from 'app/SalesApp';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default () => {
    let salesWebApp = useSalesWebApp();
    
    return (
        <AgTechWebApp app={salesWebApp} />
    )
}
