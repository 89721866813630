import React, { Dispatch, useState } from 'react';
import { IAgTechAppContext, useAppContext } from '../app/AgTechAppContext';
import { useDispatch } from 'react-redux';

/// Configuration

export type AgTechDataAccessConfiguration = {
    baseURL: string,
    usesOperation?: boolean
}

/// Data Confirmation

export interface IAgTechModalProps {
    header: string,
    details?: string,
}

export interface IAgTechConfirmationModalProps extends IAgTechModalProps {
    onConfirm: () => void,
    onCancel?: () => void
}

export interface IAgTechWarningModalProps extends IAgTechModalProps {
    okayButtonText?: string
}

/// Actions

declare type AgTechDataActionProps = {
    appContext: IAgTechAppContext,
    dispatch: Dispatch<any>
};

export const useAgTechDataActionProps = (): AgTechDataActionProps => {
    return {
        appContext: useAppContext(),
        dispatch: useDispatch()
    }
};

/// State

declare type AgTechDataState<TData> = {
    data: TData,
    hasLoaded: boolean
}

export type AgTechData<TData> = AgTechDataState<TData> & {
    updateData: (data: TData) => void 
}

export const useAgTechData = <TData, >(initialData: TData): AgTechData<TData> => {
    let [data, updateData] = useState<AgTechDataState<TData>>({
        data: initialData,
        hasLoaded: false
    });

    let updateAgTechData = (data: TData) => {
        updateData({
            data,
            hasLoaded: true
        });
    };

    return {
        data: data.data,
        hasLoaded: data.hasLoaded,
        updateData: data => updateAgTechData(data)
    };
}