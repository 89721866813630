import { AgTechAppLoadingStep } from 'agtech/core/app/AgTechApplication';
import { AgTechHttpRequest, AgTechHttpResponses, AgTechApiEntityResponse, convertFromEntityResponse } from 'agtech/core/data/actions/AgTechHttpRequests';
import { AgTechDataAction, useDataActionExecutor } from 'agtech/core/data/actions/AgTechDataActions';
import { useAgTechGetRequest } from 'agtech/core/data/http/AgTechApiGetRequests';
import { SaleDiscount } from 'app/data/sales/discounts/SaleDiscountData';
import { useBidderDataRefreshBiddersAction } from 'app/data/bidders/BidderDataActions';
import { SaleLotCategory } from 'app/data/lots/categories/LotCategoryData';
import { SaleLot } from 'app/data/lots/LotData';
import { useLotDataRefreshLotsAction } from 'app/data/lots/LotDataActions';
import { Sale, DefaultSale, salesDataSlice, getSales } from 'app/data/sales/SaleData';
import { useDispatch } from 'react-redux';
import { useSaleDiscountDataRefreshDiscountsAction } from 'app/data/sales/discounts/SaleDiscountDataActions';
import { useAgTechHttpPostRequest } from 'agtech/core/data/http/AgTechApiPostRequests';
import { useLotCategoryDataRefreshLotCategoriesAction } from 'app/data/lots/categories/LotCategoryDataActions';

/// Sale Data DTOs

declare type SaleInformationDTO = {
    saleId: number,
    saleName: string,
    saleStartDate: Date,
    saleEndDate: Date,
}

declare type SaleBidderDTO = {
    bidderId: number,
    bidderNumber: string,
    contactId: number,
    date: Date,
    saleId: number
}

declare type SaleLotDTO = {
    lotId: number,
    lotNumber: string,
    saleOrder: number,
    lotDescription: string,
    bidderId: number,
    bidderNumber: string,
    purchasePrice: number,
    timestamp: string,
    invoiceState: number,
    isSplit: boolean
}

export type SaleSelectionDTO = {
    id: number;
    saleName: string;
    saleStartDate: Date;
    saleEndDate: Date;
}

declare type SaleDetailDTO = {
    sale: SaleInformationDTO,
    sales: SaleSelectionDTO[],
    bidders: SaleBidderDTO[],
    lots: SaleLotDTO[],
    lotCategories: SaleLotCategory[],
    discounts: SaleDiscount[]
}

declare type SaleCreationDTO = {
    id: number,
    saleName: string,
    saleStartDate: Date,
    saleEndDate: Date,
    lots: SaleLot[],
}

// Sale Data Requests

export const useSaleDataGetSaleSelectionsRequest = (): AgTechHttpRequest<any, SaleSelectionDTO[]> => {
    let saleSelectionGetRequest = useAgTechGetRequest<SaleSelectionDTO[]>();

    return {
        execute: () => saleSelectionGetRequest.get({
            path: "Sales/SalesDropdown"
        })
    }
}

// Sale Data Actions

export const useSaleDataRefreshSaleAction = (): AgTechDataAction<SaleDetailDTO, any> => {
    const dispatch = useDispatch();
    const actionExecutor = useDataActionExecutor();

    const refreshBiddersAction = useBidderDataRefreshBiddersAction();
    const refreshLotsAction = useLotDataRefreshLotsAction();
    const refreshSaleDiscountsAction = useSaleDiscountDataRefreshDiscountsAction();
    const refreshLotCategoriesAction = useLotCategoryDataRefreshLotCategoriesAction();

    return {
        name: 'RefreshSale',
        action: async props => {
            let saleDetailData = props.submittedEntity;

            if (saleDetailData.bidders) {
                let bidders = saleDetailData.bidders.filter(bd => bd.contactId).map(bd => {
                    return {
                        ...bd,
                        id: bd.bidderId
                    };
                });

                await actionExecutor.executeAction(refreshBiddersAction, {
                    submittedEntity: bidders,
                    actionLoggingContext: props.actionLoggingContext
                });
            }

            if (saleDetailData.lots) {
                let lots = saleDetailData.lots.map<SaleLot>(lt => {
                    return {
                        ...lt,
                        id: lt.lotId,
                        saleId: saleDetailData.sale.saleId,
                        isInvoiced: lt.invoiceState >= 2
                    };
                })

                await actionExecutor.executeAction(refreshLotsAction, {
                    submittedEntity: lots,
                    actionLoggingContext: props.actionLoggingContext
                });
            }

            if (saleDetailData.lotCategories) {
                await actionExecutor.executeAction(refreshLotCategoriesAction, {
                    submittedEntity: saleDetailData.lotCategories,
                    actionLoggingContext: props.actionLoggingContext
                });
            }

            if (saleDetailData.discounts) {
                await actionExecutor.executeAction(refreshSaleDiscountsAction, {
                    submittedEntity: saleDetailData.discounts,
                    actionLoggingContext: props.actionLoggingContext
                });
            }

            let saleOptions = saleDetailData.sales.map<Sale>(selectionSale => {
                return {
                    id: selectionSale.id,
                    saleName: selectionSale.saleName,
                    saleStartDate: selectionSale.saleStartDate,
                    saleEndDate: selectionSale.saleEndDate
                };
            });

            let currentSale = saleDetailData.sale ? {
                ...saleDetailData.sale,
                id: saleDetailData.sale.saleId
            } : DefaultSale;

            dispatch(salesDataSlice.actions.refreshSaleData({
                currentSale: currentSale,
                sales: saleOptions
            }));

            return AgTechHttpResponses.Success(currentSale);
        }
    }
}

export const useSaleDataChangeSelectedSaleAction = (): AgTechDataAction<number | undefined, SaleDetailDTO> => {
    let saleDataGetRequest = useAgTechGetRequest<SaleDetailDTO>();
    let refreshSaleAction = useSaleDataRefreshSaleAction();

    return {
        name: 'ChangeSelectedSale',
        action: async props => {
            return await saleDataGetRequest.get({
                baseURL: process.env.REACT_APP_API_URL + '', // Specify the base URL manually as this may execute outside of app context
                path: props.submittedEntity ? 'Sales/Data/' + props.submittedEntity : 'Sales/Data'
            });
        },
        onSuccess: async props => {
            await props.actionExecutor.executeAction(refreshSaleAction, {
                submittedEntity: props.responseData
            });
        }
    }
}

/// Sale Data Submissions

export type SaleCreationData = {
    sale: Sale,
    lots: SaleLot[]
}

export const useSaleDataWriteSaleAction = (): AgTechDataAction<Sale, Sale> => {
    let changeSaleAction = useSaleDataChangeSelectedSaleAction();
    let submitSalePostRequest = useAgTechHttpPostRequest<SaleCreationDTO, AgTechApiEntityResponse<Sale>>();

    return {
        name: 'WriteSale',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity?.id ? 'Updating sale...' : 'Creating sale...',
            actionConfirmationMessage: `Successfully ${props.originalEntity?.id ? 'updated' : 'created'} sale`
        }),
        validate: async props => {
            let salesInSystem = getSales();

            props.validation.failWithWarningIf(
                salesInSystem.filter(sl => sl.saleName === props.submittedEntity.saleName).length > 0,
                'A sale with this name already exists. Please select a new name for this sale.'
            );
        },
        action: async props => {
            return await submitSalePostRequest.post({
                path: 'Sales/Submit',
                postedData: {
                    ...props.submittedEntity,
                    lots: []
                },
            })
            .then(res => convertFromEntityResponse(res));
        },
        onSuccess: async props => {
            props.executeReducerAction(salesDataSlice.actions.writeSale, props.responseData);

            await props.actionExecutor.executeAction(changeSaleAction, {
                submittedEntity: props.submittedEntity.id
            });
        }
    }
}

/// Sale Data Loading Steps

export const useSaleDataLoadingStep = (): AgTechAppLoadingStep => {
    let actionExecutor = useDataActionExecutor();
    let setSaleAction = useSaleDataChangeSelectedSaleAction();

    return {
        loadingDescription: 'Loading sale data...',
        loadingAction: async () => {
            let loadingResult = await actionExecutor.executeAction(setSaleAction, { submittedEntity: undefined });
            return loadingResult.success !== undefined;
        }
    }
}
