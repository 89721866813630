import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { SaleDiscountActionButton } from 'app/areas/discounts/components/buttons/SaleDiscountButtons';
import SaleDiscountDialog from 'app/areas/discounts/dialogs/SaleDiscountDialog';
import { useSaleDiscounts, SaleDiscount } from 'app/data/sales/discounts/SaleDiscountData';
import React from 'react'

const SaleDiscountViewGrid = () => {
    let saleDiscounts = useSaleDiscounts();

    let saleDiscountGridProps: AgTechGridProps<SaleDiscount> = {
        header: {
            title: `Sale Discounts`,
            content: () => <SaleDiscountDialog.Button />
        },
        items: saleDiscounts,
        columns: [
            {
                header: "Name",
                classes: 'emphasized',
                size: 2,
                value: discount => (
                    <AgTechGridLink
                        content={discount.name}
                        onClick={e => e.dialogs.openDialog({
                            dialog: () => <SaleDiscountDialog discount={discount} />
                        })}
                    />
                ),
            },
            {
                header: "Amount",
                value: discount => (discount.isPercentage ? '' : '$') + discount.amount + (discount.isPercentage ? '%' : '')
            },
            {
                header: "Type",
                value: discount => discount.isPercentage ? 'Percentage' : 'Amount'
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.name === curr.name &&
                   prev.isPercentage === curr.isPercentage &&
                   prev.amount === curr.amount;
        },
        noRecordsConfig: {
            message: 'No discounts were found in the system',
            action: {
                prompt: 'To create one, click the button below',
                button: () => <SaleDiscountDialog.Button classes='highlight' />
            }
        },
        actions: {
            rendering: c => <SaleDiscountActionButton discount={c} />
        }
    }

    return <AgTechGrid {...saleDiscountGridProps} />
}

export default SaleDiscountViewGrid