import { AgTechButton } from "../../Buttons/AgTechButtons";
import AgTechTitleDetailHeader from "../../Headers/AgTechTitleDetailHeader";
import React, { CSSProperties, createContext, useContext, useEffect, useRef, useState } from "react";
import AgTechPortal, { useAgTechLayerCount } from "../AgTechPortal";
import { delay } from "agtech/core/utilities/AgTechUtilities";
import AgTechCloseIcon from "../../Icons/AgTechCloseIcon";

export type AgTechModalContentProps = {
    closeModal: () => void
}

export type AgTechModalRenderProps = {
    content: (props: AgTechModalContentProps) => React.ReactNode,
    height?: string,
    width?: string,
    classes?: string,
    showCloseButton?: boolean
}

export type AgTechModalProps = {
    height?: string,
    width?: string,
    classes?: string,
    styles?: CSSProperties,
    content: () => React.ReactNode,
    closeModal: () => void,
    showCloseButton?: boolean
}

export type AgTechModalContext = {
    growModal: (height?: string, width?: string) => Promise<void>,
    closeModal: (e?: React.MouseEvent<HTMLElement>) => void
}

export const ModalContext = createContext<AgTechModalContext>({
    growModal: async (height?: string, width?: string) => {},
    closeModal: (e?: React.MouseEvent<HTMLElement>) => {}
});

export const useModalContext = () => useContext(ModalContext);

declare type AgTechModalState = {
    isActive: boolean,
    height: string,
    width: string
}

const AgTechModal = (props: React.PropsWithChildren<AgTechModalProps>) => {
    let [modalState, updateModalState] = useState<AgTechModalState>({
        isActive: false,
        height: props.height ?? '',
        width: props.width ?? ''
    });

    let layerCount = useAgTechLayerCount();

    let modalContext: AgTechModalContext = {
        growModal: async (height?: string, width?: string) => {
            if (modalState.height === height && modalState.width === width) {
                return;
            }

            updateModalState({
                ...modalState,
                height: height ?? modalState.height,
                width: width ?? modalState.width
            });

            await delay(1200);
        },
        closeModal: (e?: React.MouseEvent<HTMLElement>) => {
            if (e) e.stopPropagation();

            props.closeModal();
        }
    }

    useEffect(() => {
        window.setTimeout(() => {
            updateModalState({
                ...modalState,
                isActive: true
            });
        }, 10);
    }, []);

    let shouldShowCloseButton = props.showCloseButton ?? true;

    return (
        modalState.isActive ? (
            <AgTechPortal>
                <ModalContext.Provider value={modalContext}>
                    <div
                        className={`agtech-modal-surface agtech-app-layer ${props.classes ?? ''}`}
                        style={{ height: modalState.height, width: modalState.width, ...props.styles }}
                        onClick={e => e.stopPropagation()}
                        data-layer={layerCount.current + 1}
                    >
                        <div className="stretched row-based relative container">
                            {shouldShowCloseButton ? (
                                <AgTechCloseIcon
                                    onClicked={async () => props.closeModal()} 
                                    classes="absolute mb-auto"
                                    styles={{
                                        top: 16,
                                        right: 16,
                                        zIndex: 100
                                    }}
                                />
                            ) : null}
                            {props.content()}
                        </div>
                    </div>
                </ModalContext.Provider>
            </AgTechPortal>
        ) : null
    );
}

export type AgTechModalHeaderProps = {
    title: string,
    details?: string
}

export const AgTechModalHeader = (props: AgTechModalHeaderProps) => {
    return (
        <div className="wide bottom-bordered px-5 py-3 container">
            <AgTechTitleDetailHeader
                title={props.title}
                detail={props.details}
                titleFontSize='font-size-large'
                detailFontSize='font-size-small'
                detailMargin={6}
            >
                {/* <AgTechButton
                    icon="times"
                    classes="agtech-modal-close-button icon-only"
                    action={() => props.closeModal()}
                /> */}
            </AgTechTitleDetailHeader>
        </div>
    )
}

export type AgTechStandardModalProps = AgTechModalHeaderProps & {
    body: () => JSX.Element,
    footer?: () => JSX.Element
}

export const AgTechStandardModal = (props: AgTechStandardModalProps) => {
    return (
        <div className="stretched row-based container">
            <AgTechModalHeader {...props} />
            <div className="flexed p-4 py-6 bg-surface container">
                {props.body()}
            </div>
            { props.footer ? (
                <div className="wide row-based rounded-bottom top-bordered px-5 py-4 bg-white container">
                    {props.footer()}
                </div>
            ) : null}
        </div>
    )
}

export default AgTechModal;