import { AgTechDataAction } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechApiEntityResponse, AgTechHttpRequest, convertHttpResponse } from "agtech/core/data/actions/AgTechHttpRequests";
import { useAgTechGetRequest } from "agtech/core/data/http/AgTechApiGetRequests";
import { useAgTechHttpPostEntityRequest, useAgTechHttpPostRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { invoicingDataSlice } from "app/data/invoicing/InvoicingData";
import { ContactCredit } from "app/data/operation/contacts/credits/ContactDataCredits";
import { useCurrentSale } from "app/data/sales/SaleData";

export type LotsByContactDTO = {
    id: number,
    lotNumber: string,
    invoiceId?: number,
    isSplit: boolean,
    invoiceState: number
}

export type DiscountOnInvoiceDTO = {
    id: number,
    
}

export type ChangeItemOnInvoiceDTO = {
    remove: boolean,
    id: number
}

export const useLotDataGetLotsByContactForLotChange = (): AgTechHttpRequest<{ contactId: number, invoiceId: number}, LotsByContactDTO[]> => {
    let getLotsRequest = useAgTechGetRequest<AgTechApiEntityResponse<LotsByContactDTO[]>>();
    let currentSale = useCurrentSale();

    return {
        execute: async props => {
            return getLotsRequest.get({
                path: `Lot/${currentSale.id}/LotsByContact/${props.data.contactId}/${props.data.invoiceId}`,
            })
            .then(res => convertHttpResponse(res))
        }
    }
}

export const useDiscountsDataGetDiscountsByInvoiceId = (): AgTechHttpRequest<{ invoiceId: number }, DiscountOnInvoiceDTO[]> => {
    let getLotsRequest = useAgTechGetRequest<AgTechApiEntityResponse<DiscountOnInvoiceDTO[]>>();

    return {
        execute: async props => {
            return getLotsRequest.get({
                path: `Invoice/${props.data.invoiceId}/Discounts`,
            })
            .then(res => convertHttpResponse(res))
        }
    }
}

export const useContactCreditsDataGetCreditsByInvoiceIdForChange = (): AgTechHttpRequest<{ invoiceId: number, contactId: number }, ContactCredit[]> => {
    let getLotsRequest = useAgTechGetRequest<AgTechApiEntityResponse<ContactCredit[]>>();

    return {
        execute: async props => {
            return getLotsRequest.get({
                path: `Invoice/${props.data.invoiceId}/ChangeCredits/${props.data.contactId}`,
            })
            .then(res => convertHttpResponse(res))
        }
    }
}

export const useLotDataWriteLotInvoiceChangesAction = (invoiceId: number): AgTechDataAction<ChangeItemOnInvoiceDTO[], boolean> => {
    let saleChangeLotSubmitRequest = useAgTechHttpPostRequest<ChangeItemOnInvoiceDTO[], boolean>();

    return {
        name: 'WriteLotChanges',
        getConfiguration: props => ({
            actionExecutionMessage: 'Updating lots...',
            actionConfirmationMessage: 'Successfully updated lots'
        }),
        validate: async props => {
            // TODO: Add validations to prevent removal of all lots on an invoice
        }, 
        action: async props => {
            return await saleChangeLotSubmitRequest.post({
                path: `Invoice/${invoiceId}/ChangeLots`,
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            await props.executeReducerAction(invoicingDataSlice.actions.refreshInvoicingData, undefined);
        }
    }
}

export const useDiscountDataWriteDiscountInvoiceChangesAction = (invoiceId: number): AgTechDataAction<ChangeItemOnInvoiceDTO[], boolean> => {
    let saleChangeLotSubmitRequest = useAgTechHttpPostRequest<ChangeItemOnInvoiceDTO[], boolean>();

    return {
        name: 'WriteDiscountChanges',
        getConfiguration: props => ({
            actionExecutionMessage: 'Updating discounts...',
            actionConfirmationMessage: 'Successfully updated discounts'
        }),
        action: async props => {
            return await saleChangeLotSubmitRequest.post({
                path: `Invoice/${invoiceId}/ChangeDiscounts`,
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            await props.executeReducerAction(invoicingDataSlice.actions.refreshInvoicingData, undefined);
        }
    }
}

export const useCreditsDataWriteCreditInvoiceChangesAction = (invoiceId: number): AgTechDataAction<ChangeItemOnInvoiceDTO[], boolean> => {
    let saleChangeCreditSubmitRequest = useAgTechHttpPostRequest<ChangeItemOnInvoiceDTO[], boolean>();

    return {
        name: 'WriteCreditChanges',
        getConfiguration: props => ({
            actionExecutionMessage: 'Updating credits...',
            actionConfirmationMessage: 'Successfully updated credits'
        }),
        action: async props => {
            return await saleChangeCreditSubmitRequest.post({
                path: `Invoice/${invoiceId}/ChangeCredits`,
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            await props.executeReducerAction(invoicingDataSlice.actions.refreshInvoicingData, undefined);
        }
    }
}