import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

export type AgTechCollapsibleContainerProps = {
    isCollapsed: boolean,
    classes?: string
}

declare type AgTechCollapsibleContainerState = {
    currentContainerHeight: number,
    containerContentHeight: number,
    showContainerContent: boolean
}

const AgTechCollapsibleContainer = (props: PropsWithChildren<AgTechCollapsibleContainerProps>) => {
    let containerRef = useRef<HTMLDivElement>(null);

    let [containerState, setContainerState] = useState<AgTechCollapsibleContainerState>({
        currentContainerHeight: 0,
        containerContentHeight: 0,
        showContainerContent: true
    });
    
    useEffect(() => {
        if (containerRef.current) {
            setContainerState({
                ...containerState,
                containerContentHeight: containerRef.current.offsetHeight + 2,
                currentContainerHeight: props.isCollapsed ? 0 : containerRef.current.offsetHeight + 2
            });
        }
    }, []);

    useEffect(() => {
        if (props.isCollapsed && containerState.currentContainerHeight > 0) {
            setContainerState({
                ...containerState,
                currentContainerHeight: 0
            });
        }
        else if (containerState.containerContentHeight > 0 && containerRef.current) {
            setContainerState({
                ...containerState,
                currentContainerHeight: containerRef.current.offsetHeight + 2,
                showContainerContent: true
            });
        }
    }, [props.isCollapsed]);

    let handleTransitionEnd = (e: React.TransitionEvent) => {
        if (e.propertyName === 'max-height' && containerState.currentContainerHeight === 0) {
            setContainerState({
                ...containerState,
                showContainerContent: false
            });
        }
    }

    let containerHeight = containerState.currentContainerHeight;

    return (
        <div className={`agtech-growing-container non-scrollable container`}
             style={{ maxHeight: containerHeight }}
             onTransitionEnd={handleTransitionEnd}>
            <div className={props.classes} ref={containerRef}>{ props.children }</div>
        </div>
    )
}

export default AgTechCollapsibleContainer;