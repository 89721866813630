import { AgTechUserLoginProps } from "agtech/core/auth/AgTechAuthContext";
import { AgTechWebAppAuthConfiguration } from "agtech/web/application/AgTechWebAppAuthConfiguration";
import { Log, User, UserManager, WebStorageStateStore } from "oidc-client";

export default class AgTechWebAppAuthenticationService {
    public userManager: UserManager;

    constructor(authConfiguration: AgTechWebAppAuthConfiguration) {
        let authConfig = {
            authority: authConfiguration.authority,
            client_id: authConfiguration.clientId,
            client_secret: authConfiguration.clientSecret,
            scope: authConfiguration.scopes,
            redirect_uri: authConfiguration.redirectUri,
            silent_redirect_uri: authConfiguration.silentRedirectUri,
            response_type: authConfiguration.responseType,
        };

        this.userManager = new UserManager({
            ...authConfig,
            userStore: new WebStorageStateStore({ store: window.sessionStorage })
        });

        Log.logger = console;
    }

    public async getUser(): Promise<User | null> {
        let user: User | null = null;

        try
        {
            user = await this.userManager.getUser();
        }
        catch(error) {
            console.error(error);
        }

        return user;
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public async signinRedirectCallback(props: AgTechUserLoginProps): Promise<User | null> {
        let currentUser = await this.getUser();

        if (!currentUser?.access_token) {
            return await this.userManager.signinRedirectCallback();
        }
        else {
            return await this.getUser();
        }
    };

    public logout(): Promise<void> {
        return this.userManager.removeUser();
    }

    public signoutRedirectCallback() {
        return this.userManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            sessionStorage.clear();

            if (process.env.REACT_APP_BASE_URL) {
                window.location.replace(process.env.REACT_APP_BASE_URL);
            }

            this.userManager.clearStaleState();
        });
    };

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public async isAuthenticated(): Promise<boolean> {
        let isAuthenticated = false;
        let loggedInUser = await this.getUser();

        if (loggedInUser?.access_token) {
            isAuthenticated = true;
        }

        return isAuthenticated;
    }

    public getToken(): string {
        return '';//localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`);
    }
}