import { AgTechActionButton } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu'
import { BidderRemovalButton } from 'app/areas/bidders/components/buttons/BidderRemovalButton'
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog'
import { SaleBidderContact } from 'app/data/bidders/BidderData'
import React from 'react'

export type BidderActionMenuProps = {
    bidder: SaleBidderContact
}

const BidderActionMenu = (props: BidderActionMenuProps) => {
    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Bidder">
                <ContactDialog.Button contactId={props.bidder.id} text="View Contact" classes='eye' />
                <BidderRemovalButton bidderContact={props.bidder} text="Remove" />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
}

BidderActionMenu.Button = (props: BidderActionMenuProps) => (
    <AgTechActionButton>
        <BidderActionMenu {...props} />
    </AgTechActionButton>
)

export default BidderActionMenu