import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { AgTechMoneyFormatter } from "agtech/web/utilities/AgTechUtilities";
import { Payment, PaymentTypes } from "app/data/invoicing/InvoicingData"
import { useContactData } from "app/data/operation/contacts/ContactData";
import PaymentsActionMenu from "../menus/PaymentsActionMenu";
import { AgTechActionButton } from "agtech/web/components/Buttons/AgTechButtons";

export type InvoicePaymentsViewGridProps = {
    payments: Payment[],
    AddActions?: boolean
}

const InvoicePaymentsGrid = (props: InvoicePaymentsViewGridProps) => {
    let contacts = useContactData();
    let gridConfiguration: AgTechGridProps<Payment> = {
        items: props.payments,
        columns: [
            {
                header: 'Amount',
                value: p => AgTechMoneyFormatter.format(p.amount)
            },
            {
                header: 'Payment Type',
                value: p => PaymentTypes.find(pm => pm.id === p.paymentType)?.description ?? ''
            },
            {
                header: 'Payer',
                value: p => `${contacts.contacts[p.payerId].firstName} ${contacts.contacts[p.payerId].lastName}`
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.amount === curr.amount &&
                   prev.paymentType === curr.paymentType;
        },
        noRecordsConfig: {
            message: 'There are currently no payments on the invoice.'
        }
    };

    if (props.AddActions) {
        gridConfiguration.actions = {
            rendering: payment => (
                <AgTechActionButton classes='stretched centered container'>
                    <PaymentsActionMenu payment={payment}/>
                </AgTechActionButton>
            )
        }
    }

    return <AgTechGrid {...gridConfiguration} />
}

export default InvoicePaymentsGrid;