import { useRef, useState } from "react";

export const delay = async (waitFor: number) => await new Promise(res => setTimeout(res, waitFor));

export const isDev = () => process.env.NODE_ENV === 'development';

export const getProgressColor = (progress: number) => {
    let red = 255 - (progress > 50 ? (progress - 50) * 2 : 0);
    let green = 50 + (4 * progress);
    let blue = 20;

    return `rgb(${red}, ${green}, ${blue})`;
}

export const executeWithMinimumDelay = <TResult, >(action: () => Promise<TResult>, minimumDelay: number) => {
    return new Promise<TResult>(res => {
        action().then(result => {
            setTimeout(() => {
                res(result);
            }, minimumDelay);
        });
    });
}

export const useAgTechSafeState = <TState, >(defaultState: TState, stateUpdateCallback?: (updatedState: TState) => void): [React.MutableRefObject<TState>, (state: TState) => void] => {
    let [state, setState] = useState<TState>(defaultState);
    let stateRef = useRef<TState>(state);

    const updateState = (updatedState: TState) => {
        stateRef.current = updatedState;
        setState(stateRef.current);

        if (stateUpdateCallback) {
            stateUpdateCallback(updatedState);
        }

        return stateRef.current;
    }

    return [stateRef, updateState];
}

export const useAgTechSafeStateFunc = <TState, >(defaultState: () => TState, stateUpdateCallback?: (updatedState: TState) => void): [React.MutableRefObject<TState>, (state: TState) => void] => {
    let [state, setState] = useState<TState>(defaultState);
    let stateRef = useRef<TState>(state);

    const updateState = (updatedState: TState) => {
        stateRef.current = updatedState;
        setState(stateRef.current);

        if (stateUpdateCallback) {
            stateUpdateCallback(updatedState);
        }

        return stateRef.current;
    }

    return [stateRef, updateState];
}