import AgTechMetric from 'agtech/web/components/Metrics/AgTechMetric';
import AgTechLoadingSurface from 'agtech/web/components/Pages/Loading/AgTechLoadingSurface';
import BidderSelectionViewDialog from 'app/areas/bidders/dialogs/BidderSelectionViewDialog';
import SaleLotViewGrid from 'app/areas/lots/components/grids/SaleLotViewGrid';
import LotManagementDialog from 'app/areas/lots/dialogs/LotManagementDialog';
import LotSaleOrderUpdateDialog from 'app/areas/lots/dialogs/LotSaleOrderUpdateDialog';
import { useSaleBidders } from 'app/data/bidders/BidderData';
import { useSaleLots } from 'app/data/lots/LotData';
import React from 'react'

export const SaleOverviewPage = () => {
    let lots = useSaleLots();
    let bidders = useSaleBidders();

    return (
        <AgTechLoadingSurface waitOn={true} text='Loading sale overview...'>
            <div className='stretched column-based container'>
                <div className='flexed area-left container'>
                    <SaleLotViewGrid />
                </div>
            <div className='vertical-pane row-based area-right w-240 container'>
                    <AgTechMetric header='Registered Bidders' value={bidders.length.toString()} />
                    <AgTechMetric header='Lots In Sale' value={lots.length.toString()} />
                    <AgTechMetric header='Lots Sold' value={lots.filter(lot => lot.bidderNumber && lot.purchasePrice).length.toString()} />
                    <div className='flexed container' />
                    <LotSaleOrderUpdateDialog.Button classes='block highlight' />
                    <LotManagementDialog.Button classes='block highlight'/>
                    <BidderSelectionViewDialog.Button text="Register New Bidders" classes='block highlight' />
                </div>
            </div>
        </AgTechLoadingSurface>
    )
}