import { AgTechBadge, AgTechBadgeColor } from 'agtech/web/components/Badges/AgTechBadges'
import AgTechField from 'agtech/web/components/Fields/AgTechField'
import AgTechFieldList from 'agtech/web/components/Fields/AgTechFieldList'
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import LotStatusBadge from 'app/areas/lots/components/badges/LotStatusBadge'
import { LotActionButton } from 'app/areas/lots/components/buttons/SaleLotButtons'
import LotDialog from 'app/areas/lots/dialogs/LotDialog'
import { getLotStatus, isLotSold, useSaleLot } from 'app/data/lots/LotData'
import { useLotCategoryData } from 'app/data/lots/categories/LotCategoryData'
import React from 'react'

const LotDetailPopUp = (props: { lotId: number }) => {
    let lot = useSaleLot(props.lotId);
    let lotCategoryData = useLotCategoryData();
    let lotCategory = lot.lotCategoryId ? lotCategoryData[lot.lotCategoryId] : undefined;

    return (
        <div className='row-based container' style={{ height: 240, width: 360 }}>
            <AgTechTitleDetailHeader
                title={'Lot ' + lot.lotNumber}
                classes='py-3 px-4 pr-5 bottom-bordered'
                content={() => (
                    <>
                        <LotActionButton lotId={lot.id} />
                    </>
                )}
            />
            <div className='flexed px-4 py-3 row-based bg-light-gray container'>
                <AgTechFieldList
                    fields={[
                        { name: 'Sale Order', value: lot.saleOrder.toString() },
                        { name: 'Category', value: lotCategory?.name ?? 'N/A' },
                        { name: 'Status', value: getLotStatus(lot) },
                    ]}
                />
                <AgTechFieldList
                    classes='mt-2'
                    fields={[
                        { name: 'Description', value: lot.lotDescription },
                    ]}
                />
            </div>
            <div className='column-based px-4 top-bordered container' style={{ height: 40 }}>
                <LotDialog.Button lotId={props.lotId} classes='ml-auto link' />
            </div>
        </div>
    )
}

export default LotDetailPopUp