import { useState, useEffect, useRef } from "react";
import { AgTechGridControlProps } from "../Data/AgTechGridProps";

export const AgTechGridNumberControl = (props: React.PropsWithChildren<AgTechGridControlProps<number>>) => {
    let [controlValue, setControlValue] = useState(props.value);
    let lastValue = useRef(props.value);

    useEffect(() => {
        if (props.value !== controlValue) {
            setControlValue(props.value);
        }
    }, [props.value]);

    return (
        <div className="agtech-form-control">
            <input
                type="number"
                value={controlValue}
                readOnly={props.readonly}
                onChange={e => setControlValue(e.currentTarget.valueAsNumber) }
                onKeyDown={e => {
                    if (e.key === 'Enter' && props.onUpdated) {
                        props.onUpdated(controlValue);
                    }
                }}
                onBlur={e => {
                    if (props.onUpdated && (controlValue !== lastValue.current)) {
                        props.onUpdated(controlValue);
                    } 
                }}
                tabIndex={props.readonly ? -1 : 1}
            />
        </div>
    )
}