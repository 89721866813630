import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import BidderActionMenu from 'app/areas/bidders/components/menus/BidderActionMenu';
import BidderRegistrationDialog from 'app/areas/bidders/dialogs/BidderRegistrationDialog';
import { ContactGridLink } from 'app/areas/contacts/components/buttons/ContactButtons';
import BidderLotsListPopUp from 'app/areas/lots/components/popups/BidderLotsListPopUp';
import { SaleBidderContact } from 'app/data/bidders/BidderData';
import { isLotInvoiced, useSaleLotData } from 'app/data/lots/LotData';
import _ from 'lodash';
import React from 'react'

const SaleBidderRegistrationGrid = ( { bidders }: { bidders: SaleBidderContact[] }) => {
    let lotData = useSaleLotData();
    let lots = Object.values(lotData.lots);

    let bidderRegistartionGridConfiguration: AgTechGridProps<SaleBidderContact> = {
        header: {
            title: 'Registered Bidders',
            content: () => <BidderRegistrationDialog.Button icon='plus' text='Register new bidder' />
        },
        items: _.orderBy(bidders, bd => bd.bidderNumber),
        searchConfig: {
            options: [
                { field: 'Name', value: bidder => bidder.firstName + ' ' + bidder.lastName },
                { field: 'Bidder Number', value: bidder => bidder.bidderNumber},
                { field: 'Ranch Name', value: bidder => bidder.ranchName},
                { field: 'Email Address', value: bidder => bidder.emailAddress },
            ]
        },
        sortingConfig: {
            getSortingKey: bidder => bidder.bidderNumber
        },
        columns: [
            {
                header: 'Bidder Number',
                value: bidder => bidder.bidderNumber,
                classes: 'centered emphasized',
                width: '130px'
            },
            {
                header: 'Name',
                value: bidder => <ContactGridLink contactId={bidder.id} />
            },
            {
                header: 'Lots Purchased',
                value: bidder => {
                    let lotsPurchasedByBidder = lots.filter(lt => lt.bidderNumber === bidder.bidderNumber && !isLotInvoiced(lt));

                    return (
                        <AgTechGridLink
                            content={lotsPurchasedByBidder.length.toString()}
                            onHover={lotsPurchasedByBidder.length > 0 ? () => ({
                                popup: () => <BidderLotsListPopUp bidderId={bidder.bidderId} />,
                                config: {
                                    
                                }
                            }) : undefined}
                            classes='px-2'
                        />
                    )
                },
                width: '120px',
                classes: 'centered'
            },
            {
                header: 'Lots Invoiced',
                value: bidder => lots.filter(lt => lt.bidderNumber === bidder.bidderNumber && isLotInvoiced(lt)).length,
                width: '120px',
                classes: 'centered'
            }
        ],
        actions: {
            rendering: contactBidder => <BidderActionMenu.Button bidder={contactBidder} />
        },
        isRowEntityTheSame: (prev, curr) => {
            return prev.bidderNumber === curr.bidderNumber &&
                   prev.firstName === curr.firstName &&
                   prev.lastName === curr.lastName &&
                   prev.ranchName === curr.ranchName;
        }
    };

    return <AgTechGrid {...bidderRegistartionGridConfiguration} />
}

export default SaleBidderRegistrationGrid;