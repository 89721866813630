import { AgTechColors } from 'agtech/core/styles/AgTechColors'
import { AgTechIconBadge } from 'agtech/web/components/Icons/AgTechIcons'
import React from 'react'

export type AgTechConfirmationProps = {
    message: string
}

const AgTechConfirmation = (props: AgTechConfirmationProps) => {
    return (
        <div className='centered row-based stretched container'>
            <AgTechIconBadge
                icon={'check'}
                classes={'mb-5'}
                size={36}
                rounded={true}
                styles={{
                    backgroundColor: AgTechColors.DarkGreen,
                    color: AgTechColors.White,
                    fontSize: 18,
                    paddingTop: 2
                }}
            />
            <h1 className='font-size-larger font-bold'>{props.message}</h1>
        </div>
    )
}

export default AgTechConfirmation