import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlMetadata, AgTechFormControlProps } from 'agtech/core/forms/AgTechFormControls';

export type AgTechFormNumberControlProps = {
    caption: string,
    value: () => number,
    onControlValueChanged?: (val: number) => Promise<void>,
    unit?: string,
    unitOnRight?: boolean
}

const isValidNumber = (value: string) => {
    return value === '' || /^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(value);
}

export const AgTechNumberControl = (props: AgTechFormNumberControlProps) => {
    let inputRef = useRef<HTMLInputElement>(null);

    let [numberValue, updateNumberValue] = useState<string>(props.value().toString());

    let handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateNumberValue(e.currentTarget.value);
    }

    let handleFocusOut = async (e: React.FocusEvent<HTMLInputElement>) => {

        if (props.onControlValueChanged) {
            let updatedValue = parseFloat(e.currentTarget.value);
            let shouldFieldBeValid = true;

            if (isValidNumber(e.currentTarget.value)) {
                await props.onControlValueChanged(updatedValue);
            }
            else {
                await props.onControlValueChanged(0);
                shouldFieldBeValid = false;
            }
        }
    }

    let shouldUnitBeOnRight = props.unitOnRight ?? false;

    return (
        <div className={`agtech-form-control`} style={{maxWidth: '100%'}}>
            { props.caption ? (
                <h1 className="agtech-form-control-caption">{props.caption}</h1>
            ) : null}

            { props.unit ? 
                <div className='column-based stretched container'>
                    { !shouldUnitBeOnRight ? <div className='agtech-form-control-unit'>{props.unit}</div> : null }

                    <input type="text" value={numberValue} onChange={handleTextChanged}
                        ref={inputRef}
                        onBlur={handleFocusOut} />

                    { shouldUnitBeOnRight ? <div className='agtech-form-control-unit agtech-form-control-unit-right'>{props.unit}</div> : null }
                </div>
            : 
                <input type="text" value={numberValue} onChange={handleTextChanged}
                    ref={inputRef}
                    onBlur={handleFocusOut} />
            }
        </div>
    );
}