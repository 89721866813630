import AgTechDataProvider from 'agtech/core/data/providers/AgTechDataProvider';
import AgTechMetric from 'agtech/web/components/Metrics/AgTechMetric';
import AgTechGap from 'agtech/web/components/MIsc/AgTechGap';
import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import AgTechDataRequestLoadingPage from 'agtech/web/components/Requests/AgTechDataRequestLoadingPage';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import InvoiceInvoicingGrid from 'app/areas/invoices/components/grids/InvoiceInvoicingGrid';
import { SaleParticipantUnProcessedSalesSummary, useInvoiceDataGetUnProcessedSalesRequest } from 'app/data/invoicing/InvoicingDataActions';
import { useSaleLots } from 'app/data/lots/LotData';
import { getCurrentSale } from 'app/data/SalesAppDataStore';
import _ from 'lodash';
import React from 'react'

const SaleInvoicingUnprocessedLotsPage = () => {
    let getUnprocessedSalesRequest = useInvoiceDataGetUnProcessedSalesRequest();

    return (
        <AgTechDataProvider
            loadData={async executor => await executor.executeRequest(getUnprocessedSalesRequest, {})}
            refresh={{
                refreshOnAllRerenders: true
            }}
        >
            {(invoicingData: SaleParticipantUnProcessedSalesSummary[]) => invoicingData.length > 0 ? (
                <div className='stretched column-based container'>
                    <div className='flexed area-left container'>
                        <InvoiceInvoicingGrid unprocessedSales={invoicingData}  />
                    </div>
                    <div className='tall row-based vertical-pane area-right w-240'>
                        <AgTechMetric header='Unprocessed Sales' value={invoicingData.length.toString()} />
                        <AgTechMetric header='Sold Lots Not Invoiced' value={_.sumBy(invoicingData, d => d.numberOfLots).toString()} />
                        <AgTechMetric header='Total Sales Not Invoiced' value={AgTechMoneyFormatter.format(_.sumBy(invoicingData, d => d.totalPurchasePrice))} />
                        <AgTechGap />
                    </div>
                </div>
            ) : (
                <AgTechNoRecordsPage
                    message='There are no sales ready for invoicing right now.'
                />
            )}
        </AgTechDataProvider>
    );
};

export default SaleInvoicingUnprocessedLotsPage;