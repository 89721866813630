import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons'
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog'
import React from 'react'
import SaleHistoryItemDetailPage from '../pages/SaleHistoryItemDetailPage'

const SaleHistoryViewDialog = () => {
    return (
        <AgTechViewDialog
            header={{
                title: 'My Sale History',
                detail: 'Below is a list of all prior sales for this operation.'
            }}
            width='90%'
            textWhileLoading='Loading sale history...'
            bodyPadding='snug'
        >
            <SaleHistoryItemDetailPage />
        </AgTechViewDialog>
    )
}

SaleHistoryViewDialog.ButtonProps = {
    text: 'View Sale History',
    icon: 'list',
    action: (e: AgTechClickEventHandler) => e.dialogs.openDialog({
        dialog: () => <SaleHistoryViewDialog />
    })
}

SaleHistoryViewDialog.Button = (props: AgTechButtonProps) => (
    <AgTechButton
        {...SaleHistoryViewDialog.ButtonProps}
        {...props}
    />
)

export default SaleHistoryViewDialog