import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlMetadata, AgTechFormControlProps } from 'agtech/core/forms/AgTechFormControls';

export type AgTechFormNumberControlProps<TEntity> = AgTechFormControlProps<TEntity, number> & {
    unit?: string,
    unitOnRight?: boolean
}

const isValidNumber = (value: string) => {
    return value === '' || /^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(value);
}

export const AgTechFormNumberControl = <TFormEntity, >(props: AgTechFormNumberControlProps<TFormEntity>) => {
    let formContext = useContext(FormContext);
    let inputRef = useRef<HTMLInputElement>(null);

    let [numberValue, updateNumberValue] = useState<string>(props.value(formContext.entity).toString());

    let [field, updateField] = useState<AgTechFormControlMetadata<TFormEntity>>({
        id: props.caption,
        isValid: true,
        validateOnSubmit: (entity: any) => validateControlOnFormSubmit(entity),
    });

    let markFieldValid = () => updateField({...field, isValid: true });
    let markFieldInvalid = () => updateField({...field, isValid: false });

    let handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateNumberValue(e.currentTarget.value);
    }

    const validateControlOnFormSubmit = (entity: any): string => {
        let fieldValidationWarningMessage = '';

        if (inputRef.current && inputRef.current.value) {
            let isControlValueValidNumber = isValidNumber(inputRef.current.value);

            if (!isControlValueValidNumber) {
                fieldValidationWarningMessage = `Value '${inputRef.current.value}' in field '${props.caption}' is not a valid number`;
            }
            else {
                let numericValue = parseFloat(inputRef.current.value);
                
                if (props.validation?.validateOnSubmit) {
                    fieldValidationWarningMessage = props.validation.validateOnSubmit(formContext.entity, numericValue) ?? '';
                }
            }
        }
        else if (props.required) {
            fieldValidationWarningMessage = 'Field "' + props.caption + '" is required';
        }

        if (fieldValidationWarningMessage) {
            markFieldInvalid();
        }

        return fieldValidationWarningMessage;
    }

    let formControlWidth: string = `${props.width ?? 50}%`;
    let formControlStatus: string = field.isValid ? '' : 'invalid';

    useEffect(() => {
        formContext.registerFormField(field);

        if (props.focused) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, []);

    let handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {

        if (props.onControlValueChanged) {
            let updatedValue = parseFloat(e.currentTarget.value);
            let shouldFieldBeValid = true;

            if (isValidNumber(e.currentTarget.value)) {
                formContext.handleFieldChanged(updatedValue, props.onControlValueChanged);
            }
            else {
                formContext.handleFieldChanged(0, props.onControlValueChanged);

                markFieldInvalid();
                shouldFieldBeValid = false;
            }

            if (props.validation?.validateOnFocusOut && !props.validation.validateOnFocusOut(updatedValue)) {
                markFieldInvalid();
                shouldFieldBeValid = false;
            }
            else if (props.required && updatedValue === 0) {
                shouldFieldBeValid = false;

                if (field.isValid) {
                    markFieldInvalid();
                }
            }
            
            if (!field.isValid && shouldFieldBeValid) {
                markFieldValid();
            }
        }
    }

    let shouldUnitBeOnRight = props.unitOnRight ?? false;

    return (
        <div className={`agtech-form-control ${formControlStatus}`} style={{maxWidth: formControlWidth}}>
            { props.caption ? (
                <h1 className="agtech-form-control-caption">{props.caption + (props.required ? ' *' : '')}</h1>
            ) : null}

            { props.unit ? 
                <div className='column-based stretched container'>
                    { !shouldUnitBeOnRight ? <div className='agtech-form-control-unit'>{props.unit}</div> : null }

                    <input type="text" value={numberValue} onChange={handleTextChanged}
                        readOnly={props.readonly}
                        ref={inputRef}
                        onBlur={handleFocusOut} />

                    { shouldUnitBeOnRight ? <div className='agtech-form-control-unit agtech-form-control-unit-right'>{props.unit}</div> : null }
                </div>
            : 
                <input type="text" value={numberValue} onChange={handleTextChanged}
                    readOnly={props.readonly}
                    ref={inputRef}
                    onBlur={handleFocusOut} />
            }
        </div>
    );
}