import { AgTechDataAction, DataActionResults } from "agtech/core/data/actions/AgTechDataActions";
import { webConfigurationDataSlice } from "agtech/web/configuration/AgTechWebConfiguration";

export const useWebConfigurationDataSetSelectedGridViewAction = (): AgTechDataAction<{
    gridName: string,
    viewName: string
}, any> => {
    return {
        name: 'SetSelectedGridView',
        action: async props => {
            // TODO: Store selection in database
            return DataActionResults.Success('');
        },
        onSuccess: async props => {
            props.executeReducerAction(webConfigurationDataSlice.actions.setGridView, {
                gridName: props.submittedEntity.gridName,
                viewName: props.submittedEntity.viewName
            });
        }
    }
}