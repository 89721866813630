import { useDataActionExecutor } from 'agtech/core/data/actions/AgTechDataActions'
import { AgTechButton, AgTechButtonProps, AgTechFileUploadButton } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechStandardModal } from 'agtech/web/components/Portals/Modals/AgTechModal'
import AgTechImportModal from 'agtech/web/components/Portals/Modals/Imports/AgTechImportModal'
import AgTechStepInstruction from 'agtech/web/components/Widgets/AgTechStepInstruction'
import { DefaultAgTechContact, getContactFullName, useContacts } from 'app/data/operation/contacts/ContactData'
import { useContactDataImportContactsAction } from 'app/data/operation/contacts/ContactDataActions'
import React from 'react'

const ContactImportModal = () => {
    let contacts = useContacts();
    let actionExecutor = useDataActionExecutor();
    let contactImportAction = useContactDataImportContactsAction();

    return (
        <AgTechImportModal
            title='Import Contacts'
            importEntitySingular='Contact'
            importEntityPlural='Contacts'
            importResultsModalWidth='95%'
            onImportSubmitted={async importedContacts => {
                return await actionExecutor.executeAction(contactImportAction, {
                    submittedEntity: importedContacts
                });
            }}
            importExecutionProps={{
                defaultItem: DefaultAgTechContact,
                importColumns: [
                    {
                        columnName: 'First Name',
                        getItemValue: item => item.firstName,
                        validateItemValue: value => {
                            let isValid: boolean = false;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 50)
                                {
                                    errorMessage = "The maximum length of a first name is 50 characters."
                                }
                                else
                                {
                                    isValid = true;
                                }
                            }
                            else
                            {
                                errorMessage = 'Please specify a first name'
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.firstName = value;
                        }
                    },
                    {
                        columnName: 'Last Name',
                        getItemValue: item => item.lastName,
                        validateItemValue: value => {
                            let isValid: boolean = false;
                            let errorMessage: string = '';

                            if (value)
                            {
                                if (value.length > 25)
                                {
                                    errorMessage = "The maximum length of a last name is 25 characters."
                                }
                                else
                                {
                                    isValid = true;
                                }
                            }
                            else
                            {
                                errorMessage = 'Please specify a last name'
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.lastName = value;
                        }
                    },
                    {
                        columnName: 'Ranch Name',
                        getItemValue: item => item.ranchName,
                        validateItemValue: value => {
                            let isValid: boolean = true;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 50)
                                {
                                    errorMessage = "The maximum length of a ranch name is 50 characters."
                                    isValid = false;
                                }
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.ranchName = value;
                        }
                    },
                    {
                        columnName: 'Email',
                        getItemValue: item => item.emailAddress,
                        validateItemValue: value => {
                            let isValid: boolean = true;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 50)
                                {
                                    isValid = false;
                                    errorMessage = "The maximum length of an email address is 50 characters."
                                }
                                else
                                {
                                    const res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                                    isValid = res.test(String(value).toLowerCase());
                                    if (!isValid)
                                    {
                                        errorMessage = "The email address is not valid."
                                    }
                                }
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.emailAddress = value;
                        }
                    },
                    {
                        columnName: 'Address',
                        getItemValue: item => item.address,
                        validateItemValue: value => {
                            let isValid: boolean = false;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 50)
                                {
                                    errorMessage = "The maximum length of an address is 50 characters."
                                }
                                else
                                {
                                    isValid = true;
                                }
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.address = value;
                        }
                    },
                    {
                        columnName: 'City',
                        getItemValue: item => item.city,
                        validateItemValue: value => {
                            let isValid: boolean = false;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 50)
                                {
                                    errorMessage = "The maximum length of a city is 50 characters."
                                }
                                else
                                {
                                    isValid = true;
                                }
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.city = value;
                        }
                    },
                    {
                        columnName: 'State',
                        getItemValue: item => item.stateAbbreviation,
                        validateItemValue: value => {
                            let isValid: boolean = true;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 100)
                                {
                                    isValid = false;
                                    errorMessage = "The maximum length of a State is 100 characters."
                                }
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.stateAbbreviation = value;
                        }
                    },
                    {
                        columnName: 'Zip',
                        getItemValue: item => item.zip,
                        validateItemValue: value => {
                            let isValid: boolean = false;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 15)
                                {
                                    errorMessage = "The maximum length of a zip code is 15 characters."
                                }
                                else
                                {
                                    isValid = true;
                                }
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.zip = value;
                        }
                    },
                    {
                        columnName: 'Phone Number',
                        getItemValue: item => item.phoneNumber,
                        validateItemValue: value => {
                            let isValid: boolean = true;
                            let errorMessage: string = '';
                            if (value)
                            {
                                if (value.length > 20)
                                {
                                    errorMessage = "The maximum length of a phone number is 20 characters.";
                                    isValid = false;
                                }
                            }

                            return {
                                isValid: isValid,
                                errorMessage: errorMessage
                            }
                        },
                        setItemValue: (item, value) => {
                            item.phoneNumber = value;
                        }
                    },
                ],
                conflictHandling: {
                    existingItems: contacts,
                    itemKeys: [
                        (cnt) => ({
                            key: getContactFullName(cnt) + '_' + cnt.ranchName + '_' + cnt.phoneNumber,
                            warning: 'A contact with this information already exists'
                        }),
                    ]
                }
            }}
            body={importBodyProps => (
                <AgTechStandardModal
                    title='Import Contacts'
                    details='To import contacts into this operation, follow the instructions below'
                    body={() => (
                        <div className='stretched row-based container'>
                            <AgTechStepInstruction
                                stepNumber={1}
                                stepInstruction='Before importing your contacts, please be sure the first line of your csv file matches the columns below:'
                            >
                                <div className='column-based centered container mb-6 mt-6'>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        First Name
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Last Name
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Ranch Name
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Email
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Address
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        City
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        State
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Zip
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Phone Number
                                    </div>
                                </div>
                            </AgTechStepInstruction>
                            <AgTechStepInstruction
                                stepNumber={2}
                                stepInstruction='If your columns match those above, click the button below and select the csv file you wish to import'
                            >
                                <div className='centered container mt-5'>
                                    <AgTechFileUploadButton
                                        text='Import Contacts'
                                        icon='upload'
                                        acceptTypes='.csv,.xlsx,.xlsm,xls,.ods,.fods'
                                        onFileUploaded={importBodyProps.handleFileUploaded}
                                        classes='highlight p-2 px-5 font-size-above-standard rounded mt-4 mb-4'
                                    />
                                </div>
                            </AgTechStepInstruction>
                        </div>
                    )}
                />
            )}
        />
    )
}

ContactImportModal.Button = (props: AgTechButtonProps) => (
    <AgTechButton
        {...props}
        text='Import Contacts'
        icon='upload'
        action={e => e.modals.showModal({
            height: '460px',
            width: '800px',
            content: () => <ContactImportModal />
        })}
    />
)

export default ContactImportModal