import React, { useEffect, useState } from "react";

export type AgTechLoadingSurfaceProps = {
    waitOn?: boolean,
    text?: string,
    showLoader?: boolean,
    refreshWhenChanged?: string,
};

export type AgTechLoaderProps = {
    text?: string,
    classes?: string,
    textClasses?: string
}

export const AgTechLoader = (props: AgTechLoaderProps) => {
    return (
        <div className="agtech-loading-surface">
            <div className={`loader ${props.classes ?? ''}`} />
            { props.text ? <h1 className={`agtech-loader-text ${props.textClasses ?? ''}`}>{props.text}</h1> : null }         
        </div>
    )
}

declare type AgTechLoadingSurfaceState = {
    hasDataLoaded: boolean,
    shouldChildContentBeShown: boolean,
    contentKey?: string
}

let AgTechLoadingSurface = (props: React.PropsWithChildren<AgTechLoadingSurfaceProps>) => {
    let [loadingSurfaceState, updateLoadingSurfaceState] = useState<AgTechLoadingSurfaceState>({
        hasDataLoaded: props.waitOn ?? true,
        shouldChildContentBeShown: false,
        contentKey: props.refreshWhenChanged
    });

    let showLoader = props.showLoader ?? true;
    let hasDataLoaded = loadingSurfaceState.hasDataLoaded;
    let shouldShowChildContent = loadingSurfaceState.shouldChildContentBeShown;

    if (props.refreshWhenChanged && shouldShowChildContent) {
        shouldShowChildContent = props.refreshWhenChanged === loadingSurfaceState.contentKey;
    }

    useEffect(() => {
        let hasFinishedLoading = props.waitOn ?? true;

        if (hasFinishedLoading !== loadingSurfaceState.hasDataLoaded) {
            updateLoadingSurfaceState({
                hasDataLoaded: hasFinishedLoading,
                shouldChildContentBeShown: hasFinishedLoading,
                contentKey: props.refreshWhenChanged
            });
        }
    }, []);

    useEffect(() => {
        let hasFinishedLoading = props.waitOn ?? true;
        let shouldShowChildContent = hasFinishedLoading;
        
        if (props.refreshWhenChanged && shouldShowChildContent) {
            shouldShowChildContent = props.refreshWhenChanged === loadingSurfaceState.contentKey;
        }

        if (hasFinishedLoading !== loadingSurfaceState.hasDataLoaded ||
            shouldShowChildContent !== loadingSurfaceState.shouldChildContentBeShown) {

            updateLoadingSurfaceState({
                hasDataLoaded: hasFinishedLoading,
                shouldChildContentBeShown: shouldShowChildContent,
                contentKey: props.refreshWhenChanged
            });
        }

    }, [props.waitOn, props.refreshWhenChanged]);

    let handleLoaderFinishedFadingOut = () => {
        if (!loadingSurfaceState.shouldChildContentBeShown) {
            updateLoadingSurfaceState({
                ...loadingSurfaceState,
                shouldChildContentBeShown: true
            });
        }
    }

    let fadeInClass = hasDataLoaded ? shouldShowChildContent ? 'agtech-fade-in' : 'agtech-fade-out' : '';

    return (
        <div className={`agtech-loading-surface ${fadeInClass}`} onAnimationEnd={handleLoaderFinishedFadingOut}>
            { hasDataLoaded && shouldShowChildContent ? props.children : showLoader ? <AgTechLoader text={props.text} /> : null }
        </div>
    );
};

export default AgTechLoadingSurface