import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { SalesAppDataStore } from 'app/data/SalesAppDataStore';

let rootNode = document.getElementById("root");

if (rootNode) {
  ReactDOM.createRoot(rootNode).render(
    <BrowserRouter>
      <Provider store={SalesAppDataStore}>
          <App />
      </Provider>
    </BrowserRouter>
  );
}