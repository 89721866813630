import { AgTechButtonProps, AgTechButton, AgTechActionButton } from 'agtech/web/components/Buttons/AgTechButtons';
import SaleDiscountActionMenu from 'app/areas/discounts/components/menus/SaleDiscountActionMenu';
import { SaleDiscount } from 'app/data/sales/discounts/SaleDiscountData';
import { useSaleDiscountDataDeleteDiscountAction } from 'app/data/sales/discounts/SaleDiscountDataActions';
import React, { useContext } from 'react'

export type SaleDiscountRemovalButtonProps = AgTechButtonProps & {
    discount: SaleDiscount
}

export const SaleDiscountRemovalButton = (props: SaleDiscountRemovalButtonProps) => {
    let deleteDiscountAction = useSaleDiscountDataDeleteDiscountAction();

    let buttonProps: AgTechButtonProps = {
        ...props,
        action: e => e.actions.executeDataActionInModal(deleteDiscountAction, {
            submittedEntity: props.discount
        })
    };

    buttonProps.text = buttonProps.text ? buttonProps.text : 'Delete';
    buttonProps.icon = buttonProps.icon ? buttonProps.icon : 'times';

    return <AgTechButton {...buttonProps} />
}

export type SaleDiscountActionButtonProps = {
    discount: SaleDiscount
}

export const SaleDiscountActionButton = (props: SaleDiscountActionButtonProps) => {
    return (
        <AgTechActionButton>
            <SaleDiscountActionMenu discount={props.discount} />
        </AgTechActionButton>
    )
}