import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import { AgTechContactRemovalButton } from 'app/areas/contacts/components/buttons/ContactButtons';
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog';
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog';
import ContactCreditViewDialog from 'app/areas/contacts/dialogs/credits/ContactCreditViewDialog';
import ContactNoteDialog from 'app/areas/contacts/dialogs/notes/ContactNoteDialog';
import ContactNotesViewDialog from 'app/areas/contacts/dialogs/notes/ContactNoteViewDialog';
import { useContact } from 'app/data/operation/contacts/ContactData';
import React from 'react'

export type ContactActionMenuProps = {
    contactId: number
}

const ContactActionMenu = (props: ContactActionMenuProps) => {
    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Contact">
                <ContactDialog.Button contactId={props.contactId} />
                <AgTechContactRemovalButton contactId={props.contactId} />
            </AgTechVerticalPopUpMenuSection>
            <AgTechVerticalPopUpMenuSection header="Notes">
                <ContactNoteDialog.Button contactId={props.contactId} />
                <ContactNotesViewDialog.Button contactId={props.contactId} />
            </AgTechVerticalPopUpMenuSection>
            <AgTechVerticalPopUpMenuSection header="Credits">
                <ContactCreditDialog.Button contactId={props.contactId} />
                <ContactCreditViewDialog.Button contactId={props.contactId} />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
};

export default ContactActionMenu;