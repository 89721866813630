
export type AgTechSideNavigationCollapseIconProps = {
    toggleCollapsed: () => void
}

const AgTechSideNavigationCollapseIcon = (props: AgTechSideNavigationCollapseIconProps) => {
    return (
        <div className='h-40 mt-auto'>
            <i
                className='agtech-icon fas fa-angle-double-left agtech-side-navigation-collapse-icon'
                onClick={() => props.toggleCollapsed()}
            />
        </div>
    )
}

export default AgTechSideNavigationCollapseIcon