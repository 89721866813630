import React, { useEffect, useState, MouseEvent, RefObject, useRef } from 'react'
import ReactDOM from 'react-dom';
import { AgTechCoordinates, AgTechPopUpRendering, DefaultAgTechCoordinate, PopUpContext, usePopUpContext } from './Helpers/AgTechPopUpData';
import { calculatePopUpPosition, usePopUpClickHandler } from './Helpers/AgTechPopUpHelpers';
import { useAgTechLayerCount } from 'agtech/web/components/Portals/AgTechPortal';
import { logDev } from 'agtech/core/logging/AgTechLogger';

let AgTechPopUp = React.forwardRef<HTMLDivElement, AgTechPopUpRendering>((props, ref) => {
    const popupRef = ref as RefObject<HTMLDivElement>;
    const popupSurface = document.getElementById('agtech-app') ?? document.createElement('div');

    let [location, setLocation] = useState<AgTechCoordinates>(DefaultAgTechCoordinate);

    let popupContext = usePopUpContext({
        callerRef: props.callerRef,
        popupRef: popupRef,
        close: () => props.closePopup(props.popupId)
    });
    
    usePopUpClickHandler(popupRef, popupContext, props);

    useEffect(() => {
        let popupLocation = calculatePopUpPosition({
            popupProps: props,
            popupRef: popupRef,
            popupSurface: popupSurface
        });
        
        setLocation(popupLocation);
    }, []);

    const handleBlur = (e: MouseEvent<HTMLDivElement>) => {
        let relatedHtmlElement = e.relatedTarget as HTMLElement;

        if (relatedHtmlElement && relatedHtmlElement.closest) {
            let relatedHtmlElementAppLayer = relatedHtmlElement.closest('.agtech-app-layer');

            if (relatedHtmlElementAppLayer) {
                let popupLayerNumber = popupRef.current?.getAttribute('data-layer') ?? '';
                let targetLayerNumber = relatedHtmlElementAppLayer.getAttribute('data-layer') ?? '';

                if (targetLayerNumber > popupLayerNumber) {
                    return;
                }
            }
        }

        if (props.popupConfig?.shouldCloseOnBlur ?? true) {
            let callerRect = props.callerRef.current.getBoundingClientRect();
            let isMouseInCaller = callerRect.top <= e.clientY && callerRect.bottom >= e.clientY
                && (callerRect.left - 8) <= e.clientX && (callerRect.right + 8) >= e.clientX;

            if (!isMouseInCaller) {
                logDev('Closing popup');
                props.closePopup(props.popupId);
            }
        }
    }

    const popupPlacedClass = popupRef.current ? 'placed' : '';
    const popupShadowClass = props.popupConfig?.emphasizeShadow ? 'agtech-emphasized-shadow' : 'shadow';
    const popupFadeInTime = props.popupConfig?.fadeInTime ?? '500ms';

    let PopUp = () => {
        return (
            <PopUpContext.Provider value={popupContext}>
                <div
                    ref={popupRef}
                    className={`agtech-popup agtech-app-layer ${popupPlacedClass}`}
                    data-layer={popupContext.layer}
                    style={{
                        top: `${location.y}px`,
                        left: `${location.x}px`,
                        animationDuration: popupFadeInTime,
                    }}
                    onClick={e => e.stopPropagation()}
                    onMouseLeave={handleBlur}
                >
                    <div className={`bordered rounded bg-white shadow dark-bordered non-scrollable container`}>
                        { props.popup() }
                    </div>
                </div>
            </PopUpContext.Provider>
        )
    };

    return ReactDOM.createPortal(<PopUp />, popupSurface);
});

const AgTechPopUpMemo = React.memo(AgTechPopUp, () => true);

export default AgTechPopUpMemo;
