import { AgTechWebClickable, AgTechClickEventHandler, useClickable } from '../Buttons/AgTechButtons';
import React, { useRef } from 'react'

export enum AgTechBadgeColor {
    Blue,
    Green,
    Red,
    Yellow,
    Gray,
    Orange,
    Cyan
}

export type AgTechBadgeProps = {
    text: string,
    color?: AgTechBadgeColor,
    classes?: string, 
    customizations?: AgTechBadgeCustomizationProps,
    onClickAction?: (e: AgTechClickEventHandler) => void
};

export type AgTechBadgeCustomizationProps = {
    width?: string
}

let getBadgeColor = (color?: AgTechBadgeColor) => {
    let badgeColorClass: string = "agtech-badge-gray";

    if (color) {
        let badgeColorValue: AgTechBadgeColor = color.valueOf();

        badgeColorClass = badgeColorValue === AgTechBadgeColor.Blue ? "agtech-badge-blue"
            : badgeColorValue === AgTechBadgeColor.Green ? "agtech-badge-green"
            : badgeColorValue === AgTechBadgeColor.Red ? "agtech-badge-red"
            : badgeColorValue === AgTechBadgeColor.Yellow ? "agtech-badge-yellow"
            : badgeColorValue === AgTechBadgeColor.Gray ? "agtech-badge-gray"
            : badgeColorValue === AgTechBadgeColor.Orange ? "agtech-badge-orange"
            : badgeColorValue === AgTechBadgeColor.Cyan ? "agtech-badge-cyan"
            : "agtech-badge-gray";
    }

    return badgeColorClass;
}

export const AgTechBadge = (props: AgTechBadgeProps) => {

    let agtechBadgeRef = useRef(null);
    let badgeClickable = useClickable(agtechBadgeRef);

    let badgeColorClass = getBadgeColor(props.color);
    let badgeClickableClass = props.onClickAction ? 'clickable' : '';

    let handleBadgeClick = (e: React.MouseEvent<HTMLElement>) => {
        if (props.onClickAction) {
            props.onClickAction({
                clickEvent: e,
                ...badgeClickable
            });
        }
    }

    let badgeStyles = props.customizations?.width ? {
        width: props.customizations.width
    } : {};

    return (
        <div className="rounded flex-shrink bg-white container" style={badgeStyles}>
            <div className={`agtech-badge tall py-1 px-3 flex-shrink font-no-wrap centered font-size-smaller wide ${badgeColorClass} ${badgeClickableClass} ${props.classes}`} onClick={handleBadgeClick} ref={agtechBadgeRef}>
                {props.text}
            </div>
        </div>
    )
};

export type AgTechMetricBadgeProps = {
    title: string,
    value: string
    color?: AgTechBadgeColor,
    onClickAction?: (e: AgTechClickEventHandler) => void
};

export const AgTechMetricBadge = (props: AgTechMetricBadgeProps) => {

    let metricBadgeRef = useRef(null);
    let metricBadgeClickable = useClickable(metricBadgeRef);

    let badgeColorClass = getBadgeColor(props.color);
    let badgeClickableClass = props.onClickAction ? 'agtech-link' : '';

    let handleBadgeClick = (e: React.MouseEvent<HTMLElement>) => {
        if (props.onClickAction) {
            props.onClickAction({
                clickEvent: e,
                ...metricBadgeClickable
            });
        }
    }

    return (
        <div className={`agtech-badge agtech-metric-badge ${badgeColorClass} ${badgeClickableClass}`} onClick={handleBadgeClick} ref={metricBadgeRef}>
            <h1 className="agtech-metric-badge-metric-title">{props.title}</h1>
            <h1 className="agtech-metric-badge-metric-value">{props.value}</h1>
        </div>
    )
}