import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid'
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { LotCategoryActionButton } from 'app/areas/lots/components/buttons/SaleLotButtons';
import LotCategoryDialog from 'app/areas/lots/dialogs/LotCategoryDialog';
import { SaleLotCategory, useLotCategories } from 'app/data/lots/categories/LotCategoryData';
import React from 'react'

const LotCategoryGrid = () => {
    let lotCategories = useLotCategories();

    let gridProps: AgTechGridProps<SaleLotCategory> = {
        header: {
            title: `Lot Categories`,
            content: () => <LotCategoryDialog.Button />
        },
        items: lotCategories,
        columns: [
            {
                header: "Name",
                value: item => (
                    <AgTechGridLink
                        content={item.name}
                        onClick={e => e.dialogs.openDialog({
                            dialog: () => <LotCategoryDialog lotCategoryId={item.id} />
                        })}
                    />
                ),
                classes: 'emphasized'
            },
            {
                header: "Description",
                value: i => i.description, 
                size: 2
            },
            {
                header: "Color",
                contentClasses: 'px-4 py-2',
                value: i => (
                    <div className='stretched rounded bordered container'
                        style={{ backgroundColor: i.color }}
                    />
                )
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.name === curr.name &&
                   prev.description === curr.description &&
                   prev.color === curr.color;
        },
        searchConfig: {
            options: [
                { field: 'Name', value: i => i.name },
                { field: 'Description', value: i => i.description }
            ],
            placeholder: 'Search categories...'
        },
        noRecordsConfig: {
            message: 'No lot categories were found in the system',
            action: {
                prompt: 'To create one, click the button below',
                button: () => <LotCategoryDialog.Button classes='highlight' />
            }
        },
        actions: {
            rendering: i => <LotCategoryActionButton lotCategory={i} />
        }
    }
    
    return (
        <AgTechGrid
            {...gridProps}
        />
    )
}

export default LotCategoryGrid