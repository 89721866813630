import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { IAgTechGridItem } from 'agtech/web/components/Grids/Data/AgTechGridData';
import { AgTechGridEntityDataState, AgTechGridState, AgTechGridEntityIndex, AgTechGridStateHandlingProps } from 'agtech/web/components/Grids/Data/AgTechGridState';
import { v4 as uuidv4 } from 'uuid';
import { logDev, logDevError, logEvent } from 'agtech/core/logging/AgTechLogger';

export type AgTechGridEntity<TItem  extends IAgTechGridItem> = {
    id: string,
    item: TItem,
    state: AgTechGridEntityState,
    versionNumber: number
}

export type AgTechGridEntityState = {
    wasCreated: boolean,
    wasWritten: boolean,
    status: AgTechGridEntityStatus,
    isSelected: boolean,
    hasBeenWarned?: boolean,
    message?: string,
    icon?: string,
}

export enum AgTechGridEntityStatus {
    Default,
    Created,
    Updated,
    Invalid
};

export const initializeGridEntities = <TItem extends IAgTechGridItem>(props: AgTechGridProps<TItem>): AgTechGridEntityDataState<TItem> => {
    let doesGridFocusFirstOnInit = props.focusConfig?.focusFirstItem ?? false;

    let focusedEntityId = '';
    let allEntitiesIndex: AgTechGridEntityIndex<TItem> = {};

    props.items.forEach((item, index) => {
        let itemEntity = getGridEntityForNewItem(item, props);

        if (doesGridFocusFirstOnInit && index === 0) {
            focusedEntityId = itemEntity.id;
        }

        allEntitiesIndex[itemEntity.id] = itemEntity;
    });

    let allEntities = Object.values(allEntitiesIndex);

    // If there are no entities to show in the grid and the grid supports editing,
    // add a default item to the entity list
    if (allEntities.length === 0 && props.editConfig && props.editConfig.defaultItem) {
        let defaultItem = props.editConfig.defaultItem([]);
        let defaultEntity = createGridEntityFromItem(defaultItem, props);

        if (defaultEntity) {
            allEntitiesIndex[defaultEntity.id] = { ...defaultEntity };
            
            if (doesGridFocusFirstOnInit) {
                focusedEntityId = defaultEntity.id;
            }
        }
    }

    return {
        allEntities: allEntitiesIndex,
        focusedEntityId: focusedEntityId,
        hasLoaded: true,
        versionNumber: 0
    };
}

export const updateEntitiesOnEntityFocused = async <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    focusedEntity: AgTechGridEntity<TItem>
) => {
    let gridState = props.gridState();

    if (gridState.gridProps.focusConfig) {
        await gridState.gridProps.focusConfig.onItemFocused(focusedEntity.item);
    }

    props.updateGridEntities({
        ...gridState.entityState,
        focusedEntityId: focusedEntity.id
    });
}

export const updateEntitiesOnEntitySelected = async <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    selectedEntity: AgTechGridEntity<TItem>
) => {
    let gridState = props.gridState();

    logDev('Updating grid entities for entity selected');

    if (gridState.gridProps.selectionConfig) {
        let updatedItem = await gridState.gridProps.selectionConfig.onItemSelected(selectedEntity.item);

        if (!updatedItem) {
            updatedItem = selectedEntity.item;
        }

        gridState.entityState.allEntities[updatedItem.id] = {
            ...gridState.entityState.allEntities[updatedItem.id],
            state: {
                ...gridState.entityState.allEntities[updatedItem.id].state,
                isSelected: true
            }
        };

        props.updateGridEntities({
            ...gridState.entityState
        });
    }
}

export const updateEntitiesOnEntityDeselected = async <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    selectedEntity: AgTechGridEntity<TItem>
) => {
    let gridState = props.gridState();

    logDev('Updating grid entities for entity deselected');

    if (gridState.gridProps.selectionConfig) {
        let updatedItem = await gridState.gridProps.selectionConfig.onItemDeSelected(selectedEntity.item);

        if (!updatedItem) {
            updatedItem = selectedEntity.item;
        }

        gridState.entityState.allEntities[updatedItem.id] = {
            ...gridState.entityState.allEntities[updatedItem.id],
            state: {
                ...gridState.entityState.allEntities[updatedItem.id].state,
                isSelected: false
            }
        };

        props.updateGridEntities({
            ...gridState.entityState
        });
    }
}

export const updateEntitiesForNewDefaultEntity = <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>
) => {
    let gridState = props.gridState();
    let gridEditConfig = gridState.gridProps.editConfig;

    logDev('Updating grid entities for default entity added');

    if (gridEditConfig && gridEditConfig.defaultItem && gridEditConfig.onEntityCreated) {
        let currentItems = Object.values(gridState.entityState.allEntities).map(entity => entity.item);
        let newDefaultItem = gridEditConfig.defaultItem(currentItems);
        let newDefaultEntityId = uuidv4();
    
        gridState.entityState.allEntities[newDefaultEntityId] = {
            id: newDefaultEntityId,
            item: {...newDefaultItem},
            state: {
                wasCreated: true,
                wasWritten: false,
                isSelected: false,
                status: AgTechGridEntityStatus.Created
            },
            versionNumber: 0
        };

        props.updateGridEntities({
            ...gridState.entityState,
            focusedEntityId: newDefaultEntityId
        });

        setTimeout(() => {
            let gridRef = gridState.gridSurfaceRef;

            if (gridRef && gridRef.current) {
                let gridRows = gridRef.current.querySelectorAll(`.agtech-grid-surface-body-row`);
                let gridRowForNewEntity = gridRows[gridRows.length - 1];
        
                if (gridRowForNewEntity) {    
                    let newRowInputs: any = gridRowForNewEntity.querySelector('input:not([type="checkbox"])');
        
                    if (newRowInputs) {
                        newRowInputs.focus();
                    }
                }
            }
        }, 300);
    }
}

export const updateEntitiesOnEntityCreated = <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    createdEntity: AgTechGridEntity<TItem>
) => {
    let gridState = props.gridState();

    gridState.entityState.allEntities[createdEntity.id] = { ...createdEntity };

    logDev('Updating grid entities for entity created');
    
    props.updateGridEntities({
        ...gridState.entityState
    });
}

export const updateEntitiesOnEntityUpdated = <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    updatedEntity: AgTechGridEntity<TItem>
) => {
    let gridState = props.gridState();
    gridState.entityState.allEntities[updatedEntity.id] = { ...updatedEntity };

    logDev('Updating grid entities for entity updated');

    props.updateGridEntities({
        ...gridState.entityState
    });
}

export const updateEntitiesOnEntityRemoved = <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    removedEntity: AgTechGridEntity<TItem>
) => {
    let gridState = props.gridState();
    delete gridState.entityState.allEntities[removedEntity.id];

    logDev('Updating grid entities for entity removed');

    props.updateGridEntities({
        ...gridState.entityState
    });
}

export const getUpdatedEntitiesOnItemsChanged = <TItem extends IAgTechGridItem>(
    props: AgTechGridProps<TItem>,
    entityState: AgTechGridEntityDataState<TItem>
) => {
    let updatedEntityIndex: AgTechGridEntityIndex<TItem> = {};

    if (entityState.hasLoaded) {
        props.items.forEach(item => {
            let existingEntity = entityState.allEntities[item.id];

            if (existingEntity) {
                updatedEntityIndex[existingEntity.id] = {
                    ...existingEntity,
                    item: { ...existingEntity.item, ...item }
                };
            }
            else {
                updatedEntityIndex[item.id] = getGridEntityForNewItem(item, props);
            }
        });
    }

    return updatedEntityIndex;
}

export const updateEntitiesOnItemsChanged = <TItem extends IAgTechGridItem>(
    props: AgTechGridProps<TItem>,
    entityState: AgTechGridEntityDataState<TItem>,
    updateGridEntities: (updatedEntityData: AgTechGridEntityDataState<TItem>) => void,
) => {
    logDev('Updating grid entities for incoming items changed');

    if (entityState.hasLoaded) {
        let updatedEntityIndex = getUpdatedEntitiesOnItemsChanged(props, entityState);

        updateGridEntities({
            ...entityState,
            allEntities: updatedEntityIndex
        });
    }
}

export const getEntitiesAvailableBySearchState = <TItem extends IAgTechGridItem>(
    gridState: AgTechGridState<TItem>
): AgTechGridEntityIndex<TItem> => {
    let entities = Object.values(gridState.entityState.allEntities);

    logDev('Updating grid entities for update in search state');

    if (gridState.searchState) {
        let searchTerm = gridState.searchState.filter;
        let searchField = gridState.searchState.selectedField;

        if (searchField) {
            entities = entities.filter(entity => {
                return (entity.state.wasCreated && !entity.state.wasWritten) ||
                    searchField.value(entity.item).toLowerCase().includes(searchTerm.toLowerCase())
            });
        }
    }
    
    return indexGridEntities(entities);
}

export const getEntitiesAvailableByView = <TItem extends IAgTechGridItem>(
    gridState: AgTechGridState<TItem>,
    availableEntityIndex: AgTechGridEntityIndex<TItem>
): AgTechGridEntityIndex<TItem> => {
    let availableEntities = Object.values(availableEntityIndex);

    logDev('Updating grid entities for update in selected view');

    if (gridState.selectedView) {
        availableEntities = availableEntities.filter(entity => gridState.selectedView?.filter(entity.item));
    }
    
    return indexGridEntities(availableEntities);
}

const indexGridEntities = <TItem extends IAgTechGridItem>(entities: AgTechGridEntity<TItem>[]): AgTechGridEntityIndex<TItem> => {
    let entityIndex: AgTechGridEntityIndex<TItem> = {};

    entities.forEach(entity => {
        entityIndex[entity.id] = entity;
    });

    return entityIndex;
}

const getGridEntityForNewItem = <TItem extends IAgTechGridItem>(item: TItem, props: AgTechGridProps<TItem>): AgTechGridEntity<TItem> => {
    if (item.id === undefined) {
        logEvent('GridEntityWithNoId', item);
    }

    return {
        id: item.id?.toString() ?? '',
        item: {...item},
        state: {
            wasCreated: false,
            wasWritten: false,
            status: AgTechGridEntityStatus.Default,
            isSelected: props.selectionConfig ? props.selectionConfig.isItemSelected(item) : false
        },
        versionNumber: 0
    };
}

const createGridEntityFromItem = <TItem extends IAgTechGridItem>(item: TItem, props: AgTechGridProps<TItem>): AgTechGridEntity<TItem> | null => {
    let gridEntity: AgTechGridEntity<TItem> | null = null;

    if (props.editConfig) {
        gridEntity = {
            id: item.id.toString(),
            item: { ...item },
            state: {
                wasCreated: true,
                wasWritten: false,
                status: AgTechGridEntityStatus.Created,
                isSelected: false
            },
            versionNumber: 0
        };
    }

    return gridEntity;
}