import { AgTechButton, AgTechButtonProps } from "agtech/web/components/Buttons/AgTechButtons";
import { Payment } from "app/data/invoicing/InvoicingData";
import { usePaymentDataDeletePaymentAction } from "app/data/invoicing/InvoicingDataActions";

export type PaymentRemovalButtonProps = AgTechButtonProps & {
    payment: Payment
}

export const PaymentRemovalButton = (props: PaymentRemovalButtonProps) => {
    let paymentRemovalSubmission = usePaymentDataDeletePaymentAction();

    let buttonProps: AgTechButtonProps = {
        text: 'Remove Payment',
        icon: 'times',
        action: async e => {
            await e.actions.executeDataAction(paymentRemovalSubmission, {
                submittedEntity: props.payment,
            });
        },
        ...props
    };

    return <AgTechButton {...buttonProps} />
}