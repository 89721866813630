import { useAppContext } from 'agtech/core/app/AgTechAppContext';
import { delay } from 'agtech/core/utilities/AgTechUtilities';
import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechWarningMessage from 'agtech/web/components/Messaging/AgTechWarningMessage';
import { AgTechLoader } from 'agtech/web/components/Pages/Loading/AgTechLoadingSurface';
import { useModalContext } from 'agtech/web/components/Portals/Modals/AgTechModal';
import React, { useState } from 'react'
import { AgTechMessage } from 'agtech/core/functions/AgTechAppMessaging';
import { AgTechDataActionExecutionResult } from 'agtech/core/data/actions/AgTechDataActions';
import AgTechSuccessfulImportResultsView from 'agtech/web/components/Portals/Modals/Imports/Views/AgTechSuccessfulImportResultsView';
import { AgTechImportResult, AgTechImportExecutionProps, AgTechImportColumn, AgTechImportItem, AgTechImportTerminologyProps } from 'agtech/web/components/Portals/Modals/Imports/Common/AgTechImportTypes';
import AgTechFailedImportResultsView from 'agtech/web/components/Portals/Modals/Imports/Views/AgTechFailedImportResultsView';
import AgTechConflictingImportResultsView from 'agtech/web/components/Portals/Modals/Imports/Views/AgTechConflictingImportResultsView';
import {read, utils } from 'xlsx'

export enum AgTechImportStatus {
    Open,
    Importing,
    ImportFailed,
    ImportHadConflicts,
    ShowingFailures,
    ShowingConflicts,
    ShowingResults,
    Loading,
    Submitting
}

export type AgTechImportState<TItem> = {
    status: AgTechImportStatus,
    result?: AgTechImportResult<TItem>
}

export type AgTechImportModalBodyProps = {
    handleFileUploaded: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

export type AgTechImportModalProps<TItem> = AgTechImportTerminologyProps & {
    title: string,
    importExecutionProps: AgTechImportExecutionProps<TItem>,
    body: (props: AgTechImportModalBodyProps) => JSX.Element,
    importResultsModalWidth?: string,
    onImportSubmitted: (importedItems: TItem[]) => Promise<AgTechDataActionExecutionResult<any>>
}

const AgTechImportModal = <TItem, >(props: AgTechImportModalProps<TItem>) => {
    let appContext = useAppContext();
    let modalContext = useModalContext();
    
    let [importState, updateImportState] = useState<AgTechImportState<TItem>>({
        status: AgTechImportStatus.Open
    });

    let handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let importStatePriorToUpload = importState.status;

        updateImportState({
            status: AgTechImportStatus.Importing
        });

        if (e.currentTarget && e.currentTarget.files) {

            const ab = await e.currentTarget.files[0].arrayBuffer();
            const wb = read(ab);
            const sheet0 = wb.Sheets[wb.SheetNames[0]];
            const rowsFromData = utils.sheet_to_json<string[]>(sheet0, {header: 1});
            const columns = rowsFromData[0];
            const rows = rowsFromData.slice(1);

            let validationResult = await validateAgTechImport<TItem>({
                ...props.importExecutionProps,
                header: columns,
                rows: rows
            });

            if (validationResult) {
                appContext.messaging.showWarning(validationResult);

                updateImportState({
                    ...importState,
                    status: importStatePriorToUpload
                });

                return;
            }

            let importResult = await executeAgTechImport<TItem>({
                ...props.importExecutionProps,
                header: columns,
                rows: rows
            });

            if (!importResult.wasSuccessful) {
                updateImportState({
                    status: AgTechImportStatus.ImportFailed,
                    result: importResult
                });
            }
            else if (importResult.hadConflicts) {
                updateImportState({
                    status: AgTechImportStatus.ImportHadConflicts,
                    result: importResult
                });
            }
            else {
                await showImportResults(importResult);
            }
            
        }
    }

    let showImportFailures = async (importResult?: AgTechImportResult<TItem>) => {
        updateImportState({
            status: AgTechImportStatus.Loading
        });

        await modalContext.growModal('720px', props.importResultsModalWidth ?? '1200px');

        updateImportState({
            status: AgTechImportStatus.ShowingFailures,
            result: importResult
        });
    }

    let showImportConflicts = async (importResult?: AgTechImportResult<TItem>) => {
        updateImportState({
            status: AgTechImportStatus.Loading
        });

        await modalContext.growModal('720px', props.importResultsModalWidth ?? '1200px');

        updateImportState({
            status: AgTechImportStatus.ShowingConflicts,
            result: importResult
        });
    }

    let showImportResults = async (importResult?: AgTechImportResult<TItem>) => {
        await modalContext.growModal('720px', props.importResultsModalWidth ?? '1200px');

        updateImportState({
            status: AgTechImportStatus.ShowingResults,
            result: importResult
        });
    }

    const submitResult = async (importedItems: TItem[]) => {
        updateImportState({
            status: AgTechImportStatus.Loading
        });

        let statePriorToSubmission = { ...importState };

        updateImportState({
            status: AgTechImportStatus.Submitting
        });

        await delay(1000);

        let didSubmissionSucceed = false;

        try
        {
            let importResult = await props.onImportSubmitted(importedItems);
            didSubmissionSucceed = importResult.success !== undefined;
        }
        catch
        {
            didSubmissionSucceed = false;
        }

        if (didSubmissionSucceed) {
            modalContext.closeModal();
        }
        else {
            updateImportState({
                ...statePriorToSubmission
            });
        }
    }

    return importState.status === AgTechImportStatus.Open ? (
        <div className='stretched row-based container'>
            {props.body({
                handleFileUploaded: handleFileUpload
            })}
        </div>
    ) : importState.status === AgTechImportStatus.Importing ? (
        <div className='centered flexed fade-in-400 container'>
            <AgTechLoader text={`Importing ${props.importEntityPlural.toLowerCase()}...`} />
        </div>
    ) : importState.status === AgTechImportStatus.ImportFailed ? (
        <div className='centered flexed fade-in-400 container'>
            <AgTechWarningMessage
                header={`One or more ${props.importEntityPlural.toLowerCase()} in the selected file could not be imported`}
                headerFontSize='font-size-larger'
                details={`To view these failed ${props.importEntityPlural.toLowerCase()} and import again, please click the button below`}
                actionButton={() => (
                    <AgTechButton
                        text={`View failed ${props.importEntitySingular.toLowerCase()} imports`}
                        icon='eye'
                        action={async () => showImportFailures(importState.result)}
                        classes='highlight py-3 font-size-above-standard rounded'
                    />
                )}
            />
        </div>
    ) : importState.status === AgTechImportStatus.ImportHadConflicts ? (
        <div className='centered flexed fade-in-400 container'>
            <AgTechWarningMessage
                header={`One or more ${props.importEntityPlural.toLowerCase()} in the selected file already exist in the system`}
                headerFontSize='font-size-larger'
                details={`Please look over these existing ${props.importEntityPlural.toLowerCase()} before finishing the import`}
                actionButton={() => (
                    <AgTechButton
                        text={`View import results`}
                        icon='eye'
                        action={async () => showImportConflicts(importState.result)}
                        classes='highlight py-3 font-size-above-standard rounded'
                    />
                )}
            />
        </div>
    ) : importState.status === AgTechImportStatus.ShowingFailures ? (
        <div className='centered flexed fade-in-400 container'>
            {importState.result ? (
                <AgTechFailedImportResultsView
                    importProps={props.importExecutionProps}
                    importResult={importState.result}
                    terminology={props}
                    onImportAgain={handleFileUpload}
                />
            ) : null}
        </div>
    ) : importState.status === AgTechImportStatus.ShowingConflicts ? (
        <div className='centered flexed fade-in-400 container'>
            {importState.result ? (
                <AgTechConflictingImportResultsView
                    importProps={props.importExecutionProps}
                    importResult={importState.result}
                    terminology={props}
                    onImportAgain={handleFileUpload}
                    onImportSubmitted={submitResult}
                />
            ) : null}
        </div>
    ) : importState.status === AgTechImportStatus.ShowingResults ? (
        <div className='centered flexed fade-in-400 container'>
            {importState.result ? (
                <AgTechSuccessfulImportResultsView
                    importProps={props.importExecutionProps}
                    importResult={importState.result}
                    terminology={props}
                    onImportAgain={handleFileUpload}
                    onImportSubmitted={submitResult}
                />
            ) : null}
        </div>
    ) : importState.status === AgTechImportStatus.Submitting ? (
        <AgTechLoader text={`Submitting ${props.importEntityPlural.toLowerCase()}...`} />
    ) : null
}

declare type AgTechImportInternalExecutionProps<TItem> = AgTechImportExecutionProps<TItem> & {
    header: string[]
    rows: string[][]
}

declare type AgTechImportColumnIndex<TItem> = {
    [index: number]: AgTechImportColumn<TItem>
}

const validateAgTechImport = async <TItem, >(props: AgTechImportInternalExecutionProps<TItem>): Promise<AgTechMessage | void> => {
    let missingColumns: string[] = [];

    props.importColumns.forEach(column => {
        let matchingColumnIndexNumber = props.header.indexOf(column.columnName);

        if (matchingColumnIndexNumber < 0) {
            missingColumns.push(column.columnName);
        }
    });

    if (missingColumns.length > 0) {
        return {
            header: 'The following columns could not be found in the imported file:',
            details: missingColumns.join(', ')
        }
    }
}

const executeAgTechImport = async <TItem, >(props: AgTechImportInternalExecutionProps<TItem>): Promise<AgTechImportResult<TItem>> => {
    let importResult: AgTechImportResult<TItem> = {
        items: [],
        wasSuccessful: true,
        hadConflicts: false
    };

    let columnIndex: AgTechImportColumnIndex<TItem> = {};

    props.importColumns.forEach(column => {
        let matchingColumnIndexNumber = props.header.indexOf(column.columnName);

        if (matchingColumnIndexNumber >= 0) {
            columnIndex[matchingColumnIndexNumber] = {...column};
        }
        else {
            importResult.wasSuccessful = false;
        }
    });

    let existingItems: { [key: string]: { existingItem: TItem, warning: string } } = {};

    props.conflictHandling.existingItems.forEach(existingItem => {
        props.conflictHandling.itemKeys.forEach(itemKey => {
            let existingItemKey = itemKey(existingItem);
            existingItems[existingItemKey.key] = {
                existingItem,
                warning: existingItemKey.warning
            };
        });
    });

    let existingItemKeys = Object.keys(existingItems);

    for (let i = 0; i < props.rows.length; i++) {
        let importItem: AgTechImportItem<TItem> = {
            itemRow: i+1,
            importedItem: {
                ...props.defaultItem
            },
            isConflictingItem: false,
            isValidItem: true,
            invalidColumns: {},
        };

        for (let j = 0; j < props.rows[i].length; j++) {
            let correspondingColumn = columnIndex[j];

            if (correspondingColumn) {
                let cellValue = props.rows[i][j];

                if (cellValue && typeof(cellValue) === "string") {
                    cellValue = cellValue.trim();
                }

                try {
                    let validationResult = correspondingColumn.validateItemValue ?
                        correspondingColumn.validateItemValue(cellValue, []) :
                        { isValid: true };

                    if (validationResult.isValid) {
                        correspondingColumn.setItemValue(importItem.importedItem, cellValue);
                    }
                    else {
                        importItem.isValidItem = false;
                        importItem.invalidColumns[correspondingColumn.columnName] = {
                            importedValue: cellValue,
                            errorMessage: validationResult.errorMessage ?? 'Unable to import'
                        };

                        importResult.wasSuccessful = false;
                    }
                }
                catch {
                    importItem.isValidItem = false;
                    importItem.invalidColumns[correspondingColumn.columnName] = {
                        importedValue: cellValue,
                        errorMessage: 'Unable to import'
                    };

                    importResult.wasSuccessful = false;
                }
            }
        }

        if (importItem.isValidItem) {
            props.conflictHandling.itemKeys.forEach(itemKey => {
                let importedItemKey = itemKey(importItem.importedItem).key;

                if (existingItemKeys.find(existingItemKey => existingItemKey === importedItemKey)) {
                    let matchingItem = existingItems[importedItemKey].existingItem;
    
                    if (matchingItem) {
                        importItem.importedItem = { ...matchingItem };
                        importItem.isConflictingItem = true;
                        importItem.importFailureMessage = existingItems[importedItemKey].warning;

                        importResult.hadConflicts = true;
                    }
                }
            });
        }

        importResult.items.push({
            ...importItem
        });
    }

    return importResult;
}

export default AgTechImportModal