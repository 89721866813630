import { useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechSelectionFormControl } from "agtech/web/components/Forms/Controls/Selection/AgTechFormSelectionControl";
import { IAgTechGridItem } from "agtech/web/components/Grids/Data/AgTechGridData";
import AgTechTitleDetailHeader from "agtech/web/components/Headers/AgTechTitleDetailHeader";
import { PopUpContext, usePopUpContext } from "agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData";
import { useWebConfigurationDataSetSelectedGridViewAction } from "agtech/web/configuration/AgTechWebConfigurationActions";
import { useContext, useState } from "react";

export type AgTechGridViewConfig<TItem extends IAgTechGridItem> = {
    views: AgTechGridView<TItem>[],
}

export type AgTechGridView<TItem extends IAgTechGridItem> = {
    name: string,
    description: string,
    filter: (item: TItem) => boolean
}

export const AgTechGridViewSelectionPopUp = <TItem extends IAgTechGridItem>(props: {
    gridName?: string,
    selectedView?: string,
    viewConfig: AgTechGridViewConfig<TItem>,
    onViewSelected: (selectedView: AgTechGridView<TItem>) => void
}) => {
    let popupContext = useContext(PopUpContext);
    let setGridViewAction = useWebConfigurationDataSetSelectedGridViewAction();
    let actionExecutor = useDataActionExecutor();

    let selectedView = props.selectedView ?? props.viewConfig.views[0]?.name;

    return (
        <div className='row-based container' style={{ width: 360 }}>
            {props.viewConfig.views.map(view => {
                let isViewSelected = view.name === selectedView;
                let viewSelectionIcon = isViewSelected ? 'bi-check-circle-fill' : 'bi-circle-fill';
                let viewSelectionIconColor = isViewSelected ? 'font-color-highlight' : 'font-color-faint';

                return (
                    <div
                        key={view.name}
                        onClick={async () => {
                            if (!isViewSelected) {
                                if (props.gridName) {
                                    await actionExecutor.executeAction(setGridViewAction, {
                                        submittedEntity: { gridName: props.gridName, viewName: view.name }
                                    });
                                }
    
                                props.onViewSelected(view);
    
                                if (popupContext) {
                                    popupContext.closePopUp();
                                }
                            }
                        }}
                        className={`selectable-row pl-3 pr-4 py-3 bottom-bordered-when-not-last ${isViewSelected ? 'selected' : ''}`}
                    >
                        <AgTechTitleDetailHeader
                            icon={`bi ${viewSelectionIcon}`}
                            iconSize={24}
                            iconClasses={`font-size-larger tall mr-4 non-bordered bg-transparent centered ${viewSelectionIconColor}`}
                            title={view.name}
                            detail={view.description}
                            classes='tall'
                        />
                    </div>
                )
            })}
        </div>
    )
}