import { AgTechBadge, AgTechBadgeColor } from 'agtech/web/components/Badges/AgTechBadges';
import AgTechButtonPane from 'agtech/web/components/Buttons/AgTechButtonPane';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridTextControl } from 'agtech/web/components/Grids/Controls/AgTechGridTextControl';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechContactActionButton } from 'app/areas/contacts/components/buttons/ContactButtons';
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog';
import ContactRegisterBidderDialog from 'app/areas/contacts/dialogs/ContactRegisterBidderDialog';
import ContactImportModal from 'app/areas/contacts/modals/ContactImportModal';
import { SaleBidderContact, isSaleBidderTheSame, useSaleBidderContacts } from 'app/data/bidders/BidderData';
import { useBidderDataUpdateBidderRegistrationAction } from 'app/data/bidders/BidderDataActions';
import React from 'react'

const SaleBidderRegistrationSelectionGrid = () => {
    let bidderContacts = useSaleBidderContacts();
    let bidderUpdateAction = useBidderDataUpdateBidderRegistrationAction();

    let gridConfiguration: AgTechGridProps<SaleBidderContact> = {
        header: {
            title: "Bidder Registrations",
            content: () => <ContactRegisterBidderDialog.Button />
        },
        items: Object.values(bidderContacts),
        columns: [
            {
                header: 'Bidder Number',
                value: (bidder, update) => (
                    <AgTechGridTextControl
                        value={bidder.bidderNumber}
                        onUpdated={val => update(orig => ({
                            ...orig,
                            bidderNumber: val
                        }))}
                    />
                ),
                classes: 'emphasized',
                width: '130px'
            },
            {
                header: 'Name',
                value: bidder => (
                    <AgTechGridLink
                        content={bidder.firstName + ' ' + bidder.lastName}
                        onClick={e => e.dialogs.openDialog({
                            dialog: () => <ContactDialog contactId={bidder.id} />
                        })}
                    />
                ),
            },
            {
                header: 'Ranch Name',
                value: bidder => <AgTechGridTextControl value={bidder.ranchName} readonly={true} />,
            },
            {
                header: 'Phone',
                value: bidder => <AgTechGridTextControl value={bidder.phoneNumber} readonly={true} />,
                size: 2
            },
            {
                header: '',
                value: bidder => bidder.bidderNumber && bidder.bidderId
                    ? <AgTechBadge color={AgTechBadgeColor.Green} text="Registered" customizations={{ width: "120px" }} />
                    : <AgTechBadge color={AgTechBadgeColor.Red} text="Not Registered" customizations={{ width: "120px" }} />,
                width: '154px',
                classes: 'snug',
                contentClasses: 'wide centered'
            }
        ],
        isRowEntityTheSame: isSaleBidderTheSame,
        actions: {
            rendering: contact => <AgTechContactActionButton contactId={contact.id} />
        },
        searchConfig: {
            options: [
                { field: 'Name', value: bidder => bidder.firstName + ' ' + bidder.lastName },
                { field: 'Ranch Name', value: bidder => bidder.ranchName},
                { field: 'Phone', value: bidder => bidder.phoneNumber },
                { field: 'Bidder Number', value: bidder => bidder.bidderNumber},
            ]
        },
        editConfig: {
            onEntityUpdated: async props => {
                let updateResult = await props.actionExecutor.executeAction(bidderUpdateAction, {
                    originalEntity: props.originalItem,
                    submittedEntity: props.entity.item
                });

                return updateResult;
            },
            showStatus: false
        },
        noRecordsConfig: {
            message: 'There are no contacts in the system right now.',
            action: {
                prompt: 'To create or import contacts, use the buttons below',
                button: () => (
                    <AgTechButtonPane>
                        <ContactDialog.Button classes='highlight' />
                        <ContactImportModal.Button classes='highlight' />
                    </AgTechButtonPane>
                )
            }
        }
    };

    return <AgTechGrid {...gridConfiguration} />
};

export default SaleBidderRegistrationSelectionGrid;