import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechFormRow, AgTechFormTextControl, AgTechFormTextAreaControl } from 'agtech/web/components/Forms/AgTechForm'
import { AgTechFormColorInputControl } from 'agtech/web/components/Forms/Controls/AgTechFormColorInputControl'
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog'
import { DefaultSaleLotCategory, SaleLotCategory, useLotCategoryData } from 'app/data/lots/categories/LotCategoryData'
import { useLotCategoriesWriteCategoryAction } from 'app/data/lots/categories/LotCategoryDataActions'
import React from 'react'

export type LotCategoryDialogProps = {
    lotCategoryId?: number,
    onLotCategorySubmitted?: (submittedCategory: SaleLotCategory) => Promise<void>
}

const LotCategoryDialog = (props: LotCategoryDialogProps) => {
    let lotCategoryData = useLotCategoryData();

    let lotCategory = props.lotCategoryId ? lotCategoryData[props.lotCategoryId] : DefaultSaleLotCategory;
    let lotCategorySubmission = useLotCategoriesWriteCategoryAction();

    return (
        <AgTechFormDialog
            structure={{
                header: props.lotCategoryId ? 'Update Lot Category' : 'Create Lot Category',
                submitButtonText: props.lotCategoryId ? 'Update Category' : 'Create Category',
                width: 500
            }}
            formData={{
                loadInitialFormData: () => lotCategory
            }}
            formAction={{
                dataAction: lotCategorySubmission,
                getActionData: d => d
            }}
            behavior={{
                eventHandlers: {
                    onDialogSubmitted: props.onLotCategorySubmitted
                }
            }}
        >
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={lotCategory}
                    caption='Name'
                    value={category => category.name}
                    focused={true}
                    required={true}
                    width={60}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        name: val
                    })}
                />
                <AgTechFormColorInputControl
                    entityType={lotCategory}
                    caption='Color'
                    value={cat => cat.color}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        color: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextAreaControl
                    entityType={lotCategory}
                    caption='Description'
                    value={cat => cat.description}
                    rows={5}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        description: val
                    })}
                />
            </AgTechFormRow>
        </AgTechFormDialog>
    )
}

LotCategoryDialog.Button = (props: LotCategoryDialogProps & AgTechButtonProps) => (
    <AgTechButton
        text={props.lotCategoryId ? 'Update Category' : 'Create Category'}
        icon={props.lotCategoryId ? 'edit' : 'plus'}
        {...props}
        action={e => LotCategoryDialog.Open(e, props)}
    />
);

LotCategoryDialog.Open = (e: AgTechClickEventHandler, props?: LotCategoryDialogProps) => e.dialogs.openDialog({
    dialog: () => <LotCategoryDialog lotCategoryId={props?.lotCategoryId} />
});

export default LotCategoryDialog;