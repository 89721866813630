import { useDataActionExecutor } from 'agtech/core/data/actions/AgTechDataActions';
import { AgTechSelectionFormControl } from 'agtech/web/components/Forms/Controls/Selection/AgTechFormSelectionControl';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import SaleDialog from 'app/areas/sales/dialogs/SaleDialog';
import { DefaultSale, Sale, useSaleSelectionData } from 'app/data/sales/SaleData';
import { useSaleDataChangeSelectedSaleAction } from 'app/data/sales/SaleDataActions';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'

let SaleSelectionDropdown = (props: { classes?: string }) => {
    let actionExecutor = useDataActionExecutor();
    let saleChangeAction = useSaleDataChangeSelectedSaleAction();
    let saleSelectionData = useSaleSelectionData();

    let [selectedSale, setSelectedSale] = useState<Sale | null>(saleSelectionData.currentSale);

    let handleSaleChanged = async (sale: Sale | undefined) => {       
        if (sale && (!selectedSale || selectedSale.id != sale.id)) {
            setSelectedSale(sale);

            await actionExecutor.executeAction(saleChangeAction, {
                submittedEntity: sale.id
            });
        }

        return sale ?? DefaultSale;
    }

    useEffect(() => {
        if (saleSelectionData.currentSale) {
            handleSaleChanged(saleSelectionData.currentSale);
        }
    }, [saleSelectionData.currentSale?.id])

    return saleSelectionData.currentSale ? (
        <AgTechSelectionFormControl
            entityType={saleSelectionData.currentSale}
            control={{
                caption: 'Current Sale',
                isRequired: true,
                tabIndex: -1,
                showAsRequired: false,
                containerClasses: `wide ${props.classes ?? ''}`,
                containerStyles: { maxWidth: '100%' },
                controlClasses: 'rounded-light',
                inputClasses: 'non-bordered-f rounded-inherit',
                captionClasses: 'font-white-f mb-2'
            }}
            selection={{
                selectionControlTitle: 'Sales',
                items: saleSelectionData.sales,
                itemText: item => item.saleName,
                getSelectedItemId: () => selectedSale?.id.toString(),
                itemRendering: e => (
                    <AgTechTitleDetailHeader
                        title={e.saleName}
                        detail={`${dayjs(e.saleStartDate).format("MM/DD/YYYY")} - ${dayjs(e.saleEndDate).format("MM/DD/YYYY")}`}
                        titleFontSize='font-size-small'
                        detailFontSize='font-size-very-small'
                        detailClasses='mt-1-half'
                    />
                ),
                itemHeight: 60,
                onItemSelected: async (e, selectedSale) => {
                    return await handleSaleChanged(selectedSale);
                },
                filterItems: (sale, filter) => sale.saleName.includes(filter),
                itemCreationConfig: {
                    creationButtonText: 'Create new sale',
                    creationButtonAction: e => {
                        return new Promise<Sale>((res, fail) => {
                            e.dialogs.openDialog({
                                dialog: () => (
                                    <SaleDialog onSaleSubmitted={async createdSale => {
                                        res(createdSale);
                                    }} />
                                ),
                            });
                        });
                    },
                    noRecordsMessage: 'No sales were found in the system',
                    noRecordsPrompt: 'To create a sale, please click the button below'
                },
                styles: { height: 240, width: 360 }
            }}        
        />
    ) : null;
};

export default SaleSelectionDropdown;