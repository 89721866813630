import { logDev } from 'agtech/core/logging/AgTechLogger';
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechActionPane from 'agtech/web/components/Headers/AgTechActionPane';
import AgTechHeaderMetric from 'agtech/web/components/Metrics/AgTechHeaderMetric';
import AgTechTabbedPage, { AgTechTabbedPageTabProps } from 'agtech/web/components/Pages/Tabbed/AgTechTabPage';
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog';
import SaleLotModificationGrid from 'app/areas/lots/components/grids/SaleLotModificationGrid';
import SaleLotSellingGrid from 'app/areas/lots/components/grids/SaleLotSellingGrid';
import SaleLotViewGrid from 'app/areas/lots/components/grids/SaleLotViewGrid';
import SaleLotImportModal from 'app/areas/lots/modals/SaleLotImportModal';
import { SaleLot, isLotInvoiced, useSaleLots } from 'app/data/lots/LotData';
import { useCurrentSale } from 'app/data/sales/SaleData';
import React from 'react'
import SaleLotOrderModificationGrid from '../components/grids/SaleOrderModificationGrid';

const LotManagementDialog = () => {
    let currentSale = useCurrentSale();
    
    return (
        <AgTechViewDialog
            header={{
                title: `Manage Sale Lots`,
                detail: `Sale - ${currentSale.saleName}`,
                contentClasses: 'tall column-based-vertically-centered',
                content: () => <LotManagementViewDialogHeader />
            }}
            width="1200px"
            waitOnDataLoaded={() => true}
            textWhileLoading='Loading sale lots...'
        >
            <LotManagementViewDialogBody />
        </AgTechViewDialog>
    )
}

const LotManagementViewDialogHeader = () => {
    let saleLots = useSaleLots();
    let lotsInvoiced = saleLots.filter(lt => isLotInvoiced(lt));
    let lotsSold = saleLots.filter(lt => lt.bidderNumber && lt.purchasePrice);

    return (
        <AgTechActionPane
            elements={[
                <AgTechHeaderMetric header='Total Lots' value={saleLots.length.toString()} classes='px-2' />,
                <AgTechHeaderMetric header='Lots Sold' value={lotsSold.length.toString()} classes='px-2' />,
                <AgTechHeaderMetric header='Lots Invoiced' value={lotsInvoiced.length.toString()} classes='px-2' />,
                <SaleLotImportModal.Button classes='highlight' />
            ]}
        />
    )
}

const LotManagementViewDialogBody = () => {
    let saleLots = useSaleLots();
    let lotManagementTabs = useLotManagementDialogTabs(saleLots);

    logDev('Rendered lot management tab page');

    return <AgTechTabbedPage tabs={lotManagementTabs} />
}

const useLotManagementDialogTabs = (saleLots: SaleLot[]): AgTechTabbedPageTabProps[] => {
    return [
        {
            text: 'View Lots',
            content: () => <SaleLotViewGrid />  
        },
        {
            text: 'Update Lots',
            content: () => <SaleLotModificationGrid />  
        },
        {
            text: 'Sell Lots',
            content: () => <SaleLotSellingGrid lots={saleLots} />  
        }
    ];
}

LotManagementDialog.Open = (e: AgTechClickEventHandler) => e.dialogs.openDialog({
    dialog: () => <LotManagementDialog />
})

LotManagementDialog.Button = (props: AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'Manage Lots',
        icon: 'edit',
        ...props,
        action: LotManagementDialog.Open
    };

    return <AgTechButton {...buttonProps} />;
}

export default LotManagementDialog