import { AgTechWebApplicationInfo } from "agtech/web/application/AgTechWebApplication";
import AgTechSideNavigationButton from "agtech/web/components/App/Navigation/Controls/AgTechSideNavigationButton";
import AgTechSideNavigationMenu from "agtech/web/components/App/Navigation/Controls/AgTechSideNavigationMenu";
import { AgTechIconBadge } from "agtech/web/components/Icons/AgTechIcons";
import OperationContactViewDialog from "app/areas/contacts/dialogs/OperationContactViewDialog";
import SaleDiscountManagementDialog from "app/areas/discounts/dialogs/SaleDiscountManagementDialog";
import OperationLogo from "app/areas/operation/components/images/OperationLogo";
import OperationSettingsDialog from "app/areas/operation/dialogs/OperationSettingsDialog";
import ReportsDialog from "app/areas/reports/dialogs/ReportsDialog";
import SaleSelectionDropdown from "app/areas/sales/components/dropdowns/SaleSelectionDropdown";
import SaleHistoryViewDialog from "app/areas/sales/dialogs/SaleHistoryViewDialog";
import SaleSettingsViewDialog from "app/areas/sales/dialogs/SaleSettingsViewDialog";
import { useOperationLogo } from "app/data/operation/SaleDataOperation";
import { useCurrentSale } from "app/data/sales/SaleData";

export type SalesAppSideNavigationMenuProps = {
    app: AgTechWebApplicationInfo
}

const SalesAppSideNavigationMenu = (props: SalesAppSideNavigationMenuProps) => {
    let operationLogo = useOperationLogo();

    return (
        <AgTechSideNavigationMenu
            app={props.app}
            width="280px"
            content={isCollapsed => (
                <>
                    {!isCollapsed && operationLogo ? (
                        <div className="row-based centered bg-secondary top-bordered bottom-bordered dark-bordered container p-6 py-7">
                            {operationLogo ? (
                                <div className="bordered rounded-light non-scrollable container mb-6" style={{ height: 120, width: 220 }}>
                                    <OperationLogo height={120} width={220} />
                                </div>
                            ) : null}
                            <SaleSelectionDropdown classes="mt-6" />
                        </div>
                    ) : !isCollapsed ? (
                        <div className="row-based centered bg-secondary top-bordered bottom-bordered dark-bordered container px-6 py-4">
                            <SaleSelectionDropdown />
                        </div>
                    ) : null}
                    <div className="row-based flexed pt-6 pb-2 container">
                        <div className="mt-auto mb-2 row-based container">
                            <AgTechSideNavigationButton
                                {...OperationContactViewDialog.ButtonProps}
                                isCollapsed={isCollapsed}
                            />
                            <AgTechSideNavigationButton
                                {...SaleHistoryViewDialog.ButtonProps}
                                role={'Admin'}
                                isCollapsed={isCollapsed}
                            />
                            <AgTechSideNavigationButton
                                {...ReportsDialog.ButtonProps}
                                isCollapsed={isCollapsed}
                            />
                            <AgTechSideNavigationButton
                                {...SaleSettingsViewDialog.ButtonProps}
                                isCollapsed={isCollapsed}
                            />
                        </div>
                    </div>
                </>
            )}
        />
    );
}

export default SalesAppSideNavigationMenu;