import { AgTechFormControlUpdate } from "agtech/core/forms/AgTechFormControls"
import { useAgTechSafeState } from "agtech/core/utilities/AgTechUtilities"
import { IAgTechSelectionPopUpItem, useAgTechSelectionPopUp, AgTechSelectionControlContentProps, AgTechSelectionControlItemCreationProps } from "agtech/web/components/Forms/Controls/Selection/AgTechCoreSelectionControlData"
import { AgTechSelectionListHeader, AgTechSelectionListBody } from "agtech/web/components/Forms/Controls/Selection/AgTechSelectionPopUp"
import { CSSProperties, useRef } from "react"

export type AgTechGridSelectionControlItemProps<TFormEntity, TItem extends IAgTechSelectionPopUpItem> = {
    selectionControlTitle: string,
    items: TItem[],
    getSelectedItemId: (formEntity: TFormEntity) => string | undefined,
    onItemSelected: AgTechFormControlUpdate<TFormEntity, TItem>,
    filterItems: (item: TItem, filter: string) => boolean
    itemText: (item: TItem) => string,
    itemHeight: number,
    itemRendering: (item: TItem) => React.ReactNode,
    itemCreationConfig?: AgTechSelectionControlItemCreationProps<TItem>,
    styles?: CSSProperties
}

export type AgTechGridSelectionControlInputProps = {
    readonlyIf?: (val: any) => boolean,
    isRequired?: boolean,
    showAsRequired?: boolean,
    readonly?: boolean,
    width?: number,
}

export type AgTechGridSelectionControlProps<TRowEntity, TItem extends IAgTechSelectionPopUpItem> = {
    rowEntity: TRowEntity,
    control: AgTechGridSelectionControlInputProps,
    selection: AgTechGridSelectionControlItemProps<TRowEntity, TItem>,
    customSelectionContent?: AgTechSelectionControlContentProps<TItem>
}

declare type AgTechSelectionState = {
    searchFilter: string,
    selectedItemText: string,
    selectedItemId: string,
    isOpen: boolean
}

export const AgTechGridSelectionControl = <TFormEntity, TSelectionItem extends IAgTechSelectionPopUpItem>(props: AgTechGridSelectionControlProps<TFormEntity, TSelectionItem>) => {
    let selectionInputRef = useRef<HTMLInputElement>(null);

    let selectedItemId = props.selection.getSelectedItemId(props.rowEntity);
    let selectedItem = selectedItemId ? props.selection.items.filter(item => item.id.toString() === selectedItemId)[0] : undefined;

    let [selectionState, updateSelectionState] = useAgTechSafeState<AgTechSelectionState>({
        searchFilter: '',
        selectedItemText: selectedItem ? props.selection.itemText(selectedItem) : '',
        selectedItemId: selectedItemId ?? '',
        isOpen: false
    });

    useAgTechSelectionPopUp({
        selectionRef: selectionInputRef,
        selectionProps: {
            items: {
                selectionItems: props.selection.items,
                getSelectedItemId: () => selectedItemId,
                onItemSelected: async selectedItem => {
                    await props.selection.onItemSelected(props.rowEntity, selectedItem);

                    updateSelectionState({
                        ...selectionState.current,
                        selectedItemText: props.selection.itemText(selectedItem),
                        selectedItemId: selectedItem.id.toString()
                    });
                },
                filterItems: props.selection.filterItems
            },
            content: props.customSelectionContent ?? {
                header: headerProps => (
                    <AgTechSelectionListHeader
                        {...headerProps}
                        title={props.selection.selectionControlTitle}
                        creationConfig={props.selection.itemCreationConfig}
                    />
                ),
                body: bodyProps => (
                    <AgTechSelectionListBody
                        {...bodyProps}
                        itemHeight={props.selection.itemHeight} 
                        item={props.selection.itemRendering}
                    />
                ),
                styles: props.selection.styles
            }
        },
        onPopupOpened: () => {
            updateSelectionState({
                ...selectionState.current,
                isOpen: true
            });
        },
        onPopupClosed: () => {
            updateSelectionState({
                ...selectionState.current,
                searchFilter: '',
                isOpen: false
            });
        }
    });

    let selectionValueActiveClass = selectionState.current.isOpen ? 'active' : '';
    let selectionValueFilteredClass = selectionState.current.searchFilter ? 'filtered' : '';

    return (
        <div className='grid-selection-control'>
            <div className={`grid-selection-control-value ${selectionValueActiveClass} ${selectionValueFilteredClass}`}>
                {selectionState.current.selectedItemText}
            </div>
            <input
                ref={selectionInputRef}
                className='grid-selection-control-search'
                aria-autocomplete="list"
                autoComplete="off"
                type='text'
                value={selectionState.current.searchFilter}
                onChange={e => {
                    updateSelectionState({
                        ...selectionState.current,
                        searchFilter: e.target.value
                    });
                }}
                tabIndex={props.control.readonly ? -1 : 1}
            />
        </div>
    )
}
