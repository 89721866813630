import { AgTechWebApplicationInfo } from "agtech/web/application/AgTechWebApplication";
import AgTechSideNavigationAppLogo from "agtech/web/components/App/Navigation/Controls/AgTechSideNavigationAppLogo";
import AgTechSideNavigationCollapseIcon from "agtech/web/components/App/Navigation/Controls/AgTechSideNavigationCollapseIcon";
import { useState } from "react";

export type SideNavigationProps = {
    app: AgTechWebApplicationInfo,
    width?: string,
    content: (isCollapsed: boolean) => React.ReactNode
};

const AgTechSideNavigationMenu = (props: SideNavigationProps) => {
    let [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <div
            className={`agtech-app-side-navigation-menu ${isCollapsed ? 'collapsed' : ''}`}
            style={{ width: isCollapsed ? undefined : (props.width ?? '240px') }}
        >
            <AgTechSideNavigationAppLogo
                appIcon={props.app.icon}
                appName={props.app.name}
                isCollapsed={isCollapsed}
            />
            <div className="agtech-app-side-navigation-surface">
                {props.content(isCollapsed)}
                <AgTechSideNavigationCollapseIcon
                    toggleCollapsed={() => setIsCollapsed(!isCollapsed)}
                />
            </div>
        </div>
    )
}

export default AgTechSideNavigationMenu;