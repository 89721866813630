import AgTechDataProvider from 'agtech/core/data/providers/AgTechDataProvider';
import AgTechMetric from 'agtech/web/components/Metrics/AgTechMetric';
import AgTechGap from 'agtech/web/components/MIsc/AgTechGap';
import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import InvoiceProcessedInvoiceGrid from 'app/areas/invoices/components/grids/InvoiceProcessedInvoiceGrid';
import { ProcessedSaleInvoice, useInvoicingData } from 'app/data/invoicing/InvoicingData';
import { useInvoiceDataGetUnpaidSalesRequest } from 'app/data/invoicing/InvoicingDataActions';
import _ from 'lodash';
import React from 'react'

const SaleInvoicingProcessedLotsPage = () => {
    let invoicingData = useInvoicingData();
    let getProcessedSalesRequest = useInvoiceDataGetUnpaidSalesRequest();

    return (
        <AgTechDataProvider
            loadData={async executor => await executor.executeRequest(getProcessedSalesRequest, {})}
            content={{
                loadingText: 'Loading processed sales...',
                failureText: 'Unable to load processed sales. Please try again.'
            }}
            refresh={{
                refreshOnAllRerenders: true
            }}
        >
            {(processedSales: ProcessedSaleInvoice[]) => processedSales.length > 0 
                ? (
                    <div className='stretched column-based container'>
                        <div className='tall flexed column-based pr-3 container'>
                            <InvoiceProcessedInvoiceGrid processedInvoices={processedSales} renderPaymentsButton={true} />
                        </div>
                        <div className='tall row-based pl-3 vertical-pane container w-240'>
                            <AgTechMetric header='Processed Invoices' value={processedSales.length.toString()} />
                            <AgTechMetric header='Total Invoiced' value={AgTechMoneyFormatter.format(_.sum(processedSales.map(sale => sale.invoiceTotal)))} />
                            <AgTechGap />
                        </div>
                    </div>
                ) : (
                    <AgTechNoRecordsPage message="There are no unpaid invoices in the system right now. Invoicing can be performed beneath the 'Unprocessed Sales' tab." />
                ) }
        </AgTechDataProvider>
    )
}

export default SaleInvoicingProcessedLotsPage