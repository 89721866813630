import { AgTechApiEntityResponse } from "agtech/core/data/actions/AgTechHttpRequests";
import { DataActionResults, AgTechDataAction, useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { useAgTechHttpPostAndConvertRequest, useAgTechHttpPostRequest, useAgTechHttpSubmitEntityAndConvertRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { AgTechContact } from "app/data/operation/contacts/ContactData";
import { useLotDataRefreshLotBiddersAction } from "app/data/lots/LotDataActions";
import { getBidders, getCurrentSale, getLots } from "app/data/SalesAppDataStore";
import { BidderIndex, SaleBidder, SaleBidderContact, saleDataBidderSlice } from "app/data/bidders/BidderData";
import { useDispatch } from "react-redux";
import { useContactDataWriteContactAction } from "app/data/operation/contacts/ContactDataActions";
import { getSaleLotsPurchasedByBidder, isLotInvoiced } from "app/data/lots/LotData";

/// Bidder Data DTOs

declare type SaleBidderDTO = SaleBidder & {
    saleId: number
}

declare type SaleBidderRegistrationDTO = {
    bidderId: number,
    bidderNumber: string,
    contactId: number,
    date: Date,
    saleId: number
}

declare type SaleBidderResolutionDTO = {
    saleId: number,
    bidderNumber?: string,
    bidderId?: number,
    contactId: number
}

export type BidderContactRegistration = AgTechContact & {
    bidderId: number,
    bidderNumber: string
};

/// Bidder Data Actions

export const useBidderDataRefreshBiddersAction = (): AgTechDataAction<SaleBidder[], any> => {
    return {
        name: 'RefreshBidders',
        action: async props => {
            let indexedBidders: BidderIndex = {};

            props.submittedEntity.forEach(bidder => indexedBidders[bidder.id] = {
                ...bidder,
            });
        
            return DataActionResults.Success(indexedBidders);
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDataBidderSlice.actions.refreshBidders, props.responseData);
        }
    }
}

export type BidderNumberUpdateProps = {
    originalBidderNumber: string,
    updatedBidderContact: SaleBidderContact
}

export const useBidderDataUpdateBidderLotsAction = (): AgTechDataAction<BidderNumberUpdateProps, SaleBidderContact> => {
    let refreshLotBiddersAction = useLotDataRefreshLotBiddersAction();
    let resolveBidderPostRequest = useAgTechHttpPostRequest<SaleBidderResolutionDTO, any>();

    return {
        name: 'ResolveBidderLotsUpdate',
        action: async props => {
            return await resolveBidderPostRequest.post({
                path: 'Bidder/Resolve',
                postedData: {
                    saleId: getCurrentSale().id,
                    bidderNumber: props.submittedEntity.originalBidderNumber !== '' ? props.submittedEntity.originalBidderNumber : undefined,
                    bidderId: props.submittedEntity.updatedBidderContact.bidderId > 0 ? props.submittedEntity.updatedBidderContact.bidderId : undefined,
                    contactId: props.submittedEntity.updatedBidderContact.id
                    
                }
            });
        },
        onSuccess: async props => {
            await props.actionExecutor.executeAction(refreshLotBiddersAction, {
                submittedEntity: {
                    existingBidderNumber: props.submittedEntity.originalBidderNumber,
                    updatedBidderNumber: props.submittedEntity.updatedBidderContact.bidderNumber,
                    updatedBidderId: props.submittedEntity.updatedBidderContact.bidderId
                }
            });
        }
    }
}

export const useBidderDataUpdateBidderRegistrationAction = (): AgTechDataAction<SaleBidderContact, SaleBidderContact> => {
    let actionExecutor = useDataActionExecutor();

    let bidderNumberUpdateAction = useBidderDataUpdateBidderNumberAction();
    let bidderRegistrationAction = useBidderDataRegisterBidderAction();
    let bidderRemovalAction = useBidderDataRemoveBidderAction();

    return {
        name: 'UpdateBidderRegistration',
        getConfiguration: () => ({
            actionExecutionMessage: 'Updating bidder...',
            actionConfirmationMessage: `Successfully updated bidder`,
            shouldLogExecution: false
        }),
        action: async props => {
            let wasBidderRegistered = props.originalEntity?.bidderNumber === '' && props.submittedEntity.bidderNumber !== '';
            let wasBidderRemoved = props.originalEntity?.bidderNumber !== '' && props.submittedEntity.bidderNumber === '';

            if (wasBidderRegistered) {
                let bidderToRegister: SaleBidder = {
                    id: props.submittedEntity.bidderId,
                    contactId: props.submittedEntity.id,
                    bidderNumber: props.submittedEntity.bidderNumber
                };
    
                let bidderRegistrationResult = await actionExecutor.executeAction(bidderRegistrationAction, {
                    originalEntity: bidderToRegister,
                    submittedEntity: bidderToRegister
                });
    
                return bidderRegistrationResult.success
                    ? DataActionResults.Success({ ...props.submittedEntity, bidderNumber: bidderRegistrationResult.success.data.bidderNumber })
                    : DataActionResults.Failed('An error occurred while registering the bidder. Please try again.');
            }
            else if (wasBidderRemoved) {
                let bidderRemovalResult = await actionExecutor.executeAction(bidderRemovalAction, {
                    originalEntity: { bidder: props.submittedEntity, bidderNumber: props.originalEntity?.bidderNumber ?? '' },
                    submittedEntity: { bidder: props.submittedEntity, bidderNumber: props.submittedEntity.bidderNumber },
                });

                return bidderRemovalResult.success
                    ? DataActionResults.Success(undefined)
                    : DataActionResults.Failed('An error occurred while removing the bidder. Please try again.');
            }
            else {
                return await actionExecutor.executeAction(bidderNumberUpdateAction, { ...props });
            }
        }
    }
}

export const useBidderDataRegisterBidderAction = (): AgTechDataAction<SaleBidder, SaleBidder> => {
    let registerBidderPostRequest = useAgTechHttpPostAndConvertRequest<SaleBidderRegistrationDTO, AgTechApiEntityResponse<number>, SaleBidder>();

    return {
        name: 'RegisterBidder',
        getConfiguration: () => ({
            actionExecutionMessage: 'Registering bidder...',
            actionConfirmationMessage: `Successfully registered bidder`
        }),
        validate: async props  => {
            let existingBidders = getBidders();

            let existingBidderWithSameBidderNumber = existingBidders.find(bidder =>
                bidder.bidderNumber === props.submittedEntity.bidderNumber);

            if (existingBidderWithSameBidderNumber) {
                props.validation.failWithWarning(`A bidder with bidder number ${props.submittedEntity.bidderNumber} already exists in this sale. Please pick a different bidder number.`);
            }

            let existingBidderForSameContact = existingBidders.find(bidder => bidder.contactId === props.submittedEntity.id);

            if (existingBidderForSameContact) {
                props.validation.failWithWarning(`The selected contact is already registered with bidder number: ${existingBidderForSameContact.bidderNumber}`);
            }
        },
        action: async props => {
            let currentSale = getCurrentSale();

            return await registerBidderPostRequest.post({
                path: `Bidder/Register/${currentSale.id}`,
                postedData: {
                    bidderId: 0,
                    bidderNumber: props.submittedEntity.bidderNumber,
                    contactId: props.submittedEntity.contactId,
                    saleId: currentSale.id,
                    date: currentSale.saleStartDate
                },
                convert: registrationResponse => ({
                    id: registrationResponse.data,
                    bidderNumber: props.submittedEntity.bidderNumber,
                    contactId: props.submittedEntity.contactId
                })
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDataBidderSlice.actions.registerBidder, props.responseData);
        }
    }
}

export const useBidderDataRegisterNewContactAction = (): AgTechDataAction<BidderContactRegistration, BidderContactRegistration> => {
    let actionExecutor = useDataActionExecutor();

    let writeContactAction = useContactDataWriteContactAction();
    let registerBidderAction = useBidderDataRegisterBidderAction();

    return {
        name: 'RegisterNewContact',
        getConfiguration: props => ({
            actionExecutionMessage: 'Registering contact...',
            actionConfirmationMessage: `Successfully registered ${props.submittedEntity.firstName} ${props.submittedEntity.lastName} with bidder number: ` + props.submittedEntity.bidderNumber,
        }),
        action: async props => {
            return await actionExecutor.executeStep(writeContactAction, {
                ...props,
                onSuccess: async createdContact => {
                    let contactBidderToRegister: SaleBidder = {
                        id: 0,
                        bidderNumber: props.submittedEntity.bidderNumber,
                        contactId: createdContact.id
                    };
    
                    let contactBidderRegistrationResult = await actionExecutor.executeAction(registerBidderAction, {
                        originalEntity: contactBidderToRegister,
                        submittedEntity: contactBidderToRegister
                    });

                    return contactBidderRegistrationResult.success
                        ? DataActionResults.Success({
                            ...createdContact,
                            bidderNumber: props.submittedEntity.bidderNumber,
                            bidderId: contactBidderRegistrationResult.success.data.id
                        })
                        : DataActionResults.Failed('An error occurred while registering the new contact. Please try again.');
                }
            });
        }
    }
}

export const useBidderDataResolveBidderAction = (): AgTechDataAction<SaleBidderContact, SaleBidderContact> => {
    let actionExecutor = useDataActionExecutor();

    let registerBidderSubmission = useBidderDataRegisterBidderAction();
    let updateBidderLotsAction = useBidderDataUpdateBidderLotsAction();

    return {
        name: 'ResolveBidder',
        getConfiguration: () => ({
            actionExecutionMessage: 'Updating bidder number...',
            actionConfirmationMessage: `Successfully updated bidder number`
        }),
        validate: async props  => {
            props.validation.failWithWarningIf(props.submittedEntity.id === undefined, "Please select a contact a register a new one");
        },
        action: async props => {
            let bidderToRegister: SaleBidder = {
                ...props.submittedEntity,
                contactId: props.submittedEntity.id
            };

            return await actionExecutor.executeStep(registerBidderSubmission, {
                originalEntity: bidderToRegister,
                submittedEntity: bidderToRegister,
                onSuccess: async registeredBidder => {
                    let bidderLotUpdateResult = await actionExecutor.executeAction(updateBidderLotsAction, {
                        submittedEntity: {
                            originalBidderNumber: props.originalEntity?.bidderNumber ?? props.submittedEntity.bidderNumber,
                            updatedBidderContact: {
                                ...props.submittedEntity,
                                bidderId: registeredBidder.id,
                                bidderNumber: registeredBidder.bidderNumber
                            }
                        }
                    });

                    return bidderLotUpdateResult.success
                        ? DataActionResults.Success({
                            ...props.submittedEntity,
                            bidderId: registeredBidder.id,
                            bidderNumber: registeredBidder.bidderNumber
                        })
                        : DataActionResults.Failed('An error occurred while resolving the bidder. Please try again.');
                }
            })
        }
    }
}

export const useBidderDataUpdateBidderNumberAction = (): AgTechDataAction<SaleBidderContact, SaleBidderContact> => {
    let updateBidderLotsAction = useBidderDataUpdateBidderLotsAction();
    let updatedBidderNumberSubmitRequest = useAgTechHttpSubmitEntityAndConvertRequest<SaleBidderDTO, SaleBidderContact>();

    return {
        name: 'UpdateBidderNumber',
        getConfiguration: () => ({
            actionExecutionMessage: 'Updating bidder number...',
            actionConfirmationMessage: `Successfully updated bidder number`,
        }),
        validate: async props  => {
            let existingBidderWithSameBidderNumber = getBidders().find(bidder => bidder.bidderNumber === props.submittedEntity.bidderNumber);

            props.validation.failWithWarningIf(
                existingBidderWithSameBidderNumber !== undefined,
                `A bidder with bidder number "${props.submittedEntity.bidderNumber}" already exists in this sale. Please specify a different bidder number`,
                () => props.originalEntity ? { ...props.originalEntity } : { ...props.submittedEntity });

            if (props.submittedEntity.bidderNumber === '') {
                let lotsAssociatedWithBidder = getLots().filter(lt => lt.bidderNumber === props.originalEntity?.bidderNumber);

                // TODO: Update the warning message here
                props.validation.failWithWarningIf(lotsAssociatedWithBidder.length > 0,
                {
                    header: `Bidder "${props.originalEntity?.bidderNumber ?? props.submittedEntity.bidderNumber}" has purchased ${lotsAssociatedWithBidder.length === 1 ? 'lot "' + lotsAssociatedWithBidder[0].lotNumber + '"' : lotsAssociatedWithBidder.length + ' lots'}
                                in this sale. To remove this bidder, please remove bidder information from ${lotsAssociatedWithBidder.length === 1 ? 'that lot' : 'those lots'} first`,
                    details: 'If these lots have been invoiced, this bidder cannot be removed from this sale'
                });
            }
        },
        confirm: async props => {
            if (props.originalEntity?.bidderNumber !== props.submittedEntity.bidderNumber) {
                if (props.submittedEntity.bidderNumber && props.submittedEntity.bidderId) {
                    return await props.appContext.confirmations.askForConfirmation({
                        content: {
                            header: `Are you sure you want to changed the bidder number for 
                                "${props.submittedEntity.firstName} ${props.submittedEntity.lastName}" from "${props.originalEntity?.bidderNumber ?? ''}" to "${props.submittedEntity.bidderNumber}"?`,
                            details: 'All lots purchased by this bidder will be updated accordingly',
                            width: '500px',
                        }                        
                    });
                }
            }

            return true;
        },
        action: async props => {
            let currentSale = getCurrentSale();

            return await updatedBidderNumberSubmitRequest.post({
                path: 'Bidder',
                postedData: {
                    id: props.submittedEntity.bidderId,
                    bidderNumber: props.submittedEntity.bidderNumber,
                    contactId: props.submittedEntity.id,
                    saleId: currentSale.id
                },
                convert: updatedBidder => ({
                    ...props.submittedEntity,
                    ...updatedBidder
                })
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDataBidderSlice.actions.updateBidder, {
                ...props.submittedEntity,
                contactId: props.submittedEntity.id
            });

            let didBidderNumberChange = props.originalEntity?.bidderNumber !== props.submittedEntity.bidderNumber;

            if (didBidderNumberChange) {
                await props.actionExecutor.executeAction(updateBidderLotsAction, {
                    submittedEntity: {
                        originalBidderNumber: props.originalEntity?.bidderNumber ?? '',
                        updatedBidderContact: props.submittedEntity
                    }
                });
            }

            props.appContext.popups.closeAll();
        }
    }
}

export const useBidderDataRemoveBidderAction = (): AgTechDataAction<{ bidder: SaleBidderContact, bidderNumber: string }, string> => {
    let removeBidderPostRequest = useAgTechHttpPostRequest<number, any>();
    
    return {
        name: 'DeleteBidder',
        getConfiguration: () => ({
            actionExecutionMessage: 'Removing bidder...',
            actionConfirmationMessage: `Successfully removed bidder from sale`
        }),
        validate: async props  => {
            let lotsAssociatedWithBidder = getSaleLotsPurchasedByBidder(props.submittedEntity.bidderNumber);
            
            if (lotsAssociatedWithBidder && lotsAssociatedWithBidder.length > 0) {
                let invoicedLotsAssociatedWithBidder = lotsAssociatedWithBidder.filter(lot => isLotInvoiced(lot));

                if (invoicedLotsAssociatedWithBidder && invoicedLotsAssociatedWithBidder.length > 0) {
                    props.validation.failWithWarning(`Bidder ${props.submittedEntity.bidderNumber} has ${invoicedLotsAssociatedWithBidder.length} invoiced lots and cannot be removed from this sale.`);
                }
                else {
                    props.validation.failWithWarning(
                        `Bidder "${props.submittedEntity.bidderNumber}" has purchased ${lotsAssociatedWithBidder.length === 1 ? 'lot "' + lotsAssociatedWithBidder[0].lotNumber + '"' : lotsAssociatedWithBidder.length + ' lots'}
                         in this sale. To remove this bidder, please remove bidder information from ${lotsAssociatedWithBidder.length === 1 ? 'that lot' : 'those lots'} first`);
                }
            }
        },
        confirm: async props => {
            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: `Are you sure you want to remove bidder "${props.originalEntity?.bidderNumber ?? ''}" from the current sale?`,
                    confirmationButtonText: 'Remove Bidder',
                    width: '400px',
                },
                submission: {
                    actionExecutionMessage: 'Removing bidder...',
                    actionConfirmationMessage: 'Bidder Removed'
                }
            });
        },
        action: async props => {
            return await removeBidderPostRequest.post({
                path: `Bidder/Remove/${getCurrentSale().id}/${props.submittedEntity.bidder.bidderId}`,
                postedData: props.submittedEntity.bidder.bidderId,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDataBidderSlice.actions.removeBidder, props.submittedEntity.bidder.bidderId);
        }
    }
}