import { AgTechUser, DefaultAgTechUser } from "agtech/core/auth/AgTechAuthData";
import React, { useContext } from "react";

export type AgTechAuthContext = {
    getLoggedInUser: () => Promise<AgTechUser | null>,
    isUserAuthenticated: () => Promise<boolean>,
    loginUser: () => any,
    onSuccessfulUserLogin: (props: AgTechUserLoginProps) => Promise<void>,
    logoutUser: () => Promise<void>,
    user: AgTechUser
};

export type AgTechUserLoginProps = {
    navigateToRoot: () => void
}

export const AuthContext = React.createContext<AgTechAuthContext>({
    getLoggedInUser: () => Promise.prototype.then(),
    isUserAuthenticated: async () => false,
    loginUser: () => {
        console.error('Attempted to login with default auth context');
    },
    onSuccessfulUserLogin: async () => {},
    logoutUser: () => new Promise<void>(res => {
        console.error('Default auth context was used');
        res();
    }),
    user: DefaultAgTechUser
});

export const useAgTechAuthContext = () => useContext(AuthContext);