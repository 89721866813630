import React, { CSSProperties, useRef } from 'react'
import { AgTechWebClickable, useClickable } from './AgTechButtons'

export type AgTechClickableContainerProps = {
    classes?: string,
    styles?: CSSProperties,
    onClick: (e: AgTechWebClickable) => void
}

const AgTechClickableContainer = (props: React.PropsWithChildren<AgTechClickableContainerProps>) => {
    let clickableContainerRef = useRef(null);
    let clikableHandler = useClickable(clickableContainerRef);

    return (
        <div
            ref={clickableContainerRef}
            className={`clickable ${props.classes} container`}
            style={props.styles}
            onClick={() => props.onClick(clikableHandler)}
        >
            {props.children}
        </div>
    )
}

export default AgTechClickableContainer