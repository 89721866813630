import OperationSetupModal from 'app/areas/operation/modals/OperationSetupModal';
import { useOperationData } from 'app/data/operation/SaleDataOperation'
import React from 'react'

const SalesAppSetupPage = (props: { children: JSX.Element }) => {
    let doesAppNeedSetup = useOperationData().needsSetup;

    return doesAppNeedSetup ? (
        <div className='stretched row-based centered fade-in-400 absolute container'>
            <div className='stretched row-based centered move-up container'>
                <h1 className='font-bold mb-5' style={{ fontSize: 36 }}>Welcome!</h1>
                <div className='row-based centered fade-in-below-move-up container'>
                    <h1 className='font-size-larger mb-5 font-bold font-color-secondary'>Before you begin, please setup your operation</h1>
                    <OperationSetupModal.Button
                        classes='highlight mt-5 p-3 px-5 font-size-medium'
                        styles={{ marginTop: 56 }}
                    />
                </div>
            </div>
        </div>
    )
    : props.children;
}

export default SalesAppSetupPage