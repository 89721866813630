import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from "agtech/web/components/Buttons/AgTechButtons";
import { AgTechFormSection } from "agtech/web/components/Forms/AgTechForm";
import AgTechFormDialog from "agtech/web/components/Portals/Dialogs/AgTechFormDialog";
import { ChangeItemOnInvoiceDTO, useDiscountDataWriteDiscountInvoiceChangesAction, useDiscountsDataGetDiscountsByInvoiceId } from "app/data/lots/invoices/LotInvoiceDataActions";
import { SaleDiscount, useSaleDiscounts } from "app/data/sales/discounts/SaleDiscountData";
import InvoiceChangeDiscountsGrid from "../components/grids/InvoiceChangeDiscountsGrid";
import { AgTechEntitySelectionPreservedOriginal } from "agtech/web/components/Grids/AgTechGridEntities";

export type InvoiceChangeDiscountsDialogProps = {
    invoiceId: number
}

export type SelectableDiscountsData = {
    Discounts: AgTechEntitySelectionPreservedOriginal<SaleDiscount>[],
}

const InvoiceChangeDiscountsDialog = (props: InvoiceChangeDiscountsDialogProps) => {
    let getDiscountsAction = useDiscountsDataGetDiscountsByInvoiceId();
    let writeAction = useDiscountDataWriteDiscountInvoiceChangesAction(props.invoiceId);
    let discounts = useSaleDiscounts();

    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: e => e.executeAndConvertRequest({
                    request: getDiscountsAction,
                    inputData: {invoiceId: props.invoiceId},
                    convert: async d => {
                        let ldata: SelectableDiscountsData = {
                            Discounts: discounts.map<AgTechEntitySelectionPreservedOriginal<SaleDiscount>>(l => ({id: l.id, entity: l, isSelected: d.find((elem) => elem.id === l.id) !== undefined, originalSelection: d.find((elem) => elem.id === l.id) !== undefined}))
                        }

                        return ldata;
                    }
                })
            }} 
            formAction={{
                dataAction: writeAction,
                getActionData: d => {
                    let filtered = d.Discounts.filter(d => d.isSelected != d.originalSelection);

                    return filtered.map<ChangeItemOnInvoiceDTO>(l => ({remove: l.originalSelection == true && l.isSelected == false, id: l.entity.id}))
                }
            }}
            structure={{
                header: 'Change Discounts',
                submitButtonText: 'Submit',
                width: 600
            }}
         >
            {(formContext) => (
                <AgTechFormSection
                header="Discounts"
                classes='flexed'
                bodyClasses='flexed pt-3'>
                    <InvoiceChangeDiscountsGrid formContext={formContext} />
                </AgTechFormSection>  
            )}
        </AgTechFormDialog>
    )
}

InvoiceChangeDiscountsDialog.Open = (e: AgTechClickEventHandler, props: InvoiceChangeDiscountsDialogProps) => e.dialogs.openDialog({
    dialog: () => <InvoiceChangeDiscountsDialog {...props} />
});

InvoiceChangeDiscountsDialog.Button = (props: AgTechButtonProps & InvoiceChangeDiscountsDialogProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'Change Discounts',
        bootstrapIcon: 'bi-arrow-left-right',
        ...props,
        action: e => InvoiceChangeDiscountsDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default InvoiceChangeDiscountsDialog;