import { useAppContext } from "agtech/core/app/AgTechAppContext";
import { AgTechDataAction, AgTechCoreDataActionProps, AgTechDataActionConfiguration, AgTechDataActionContext, DataActionContext, useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechConfirmationProps } from "agtech/core/functions/AgTechAppConfirmations";
import { AgTechColors } from "agtech/core/styles/AgTechColors";
import { delay } from "agtech/core/utilities/AgTechUtilities";
import { AgTechClickEventHandler, AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechTitleDetailHeader from "agtech/web/components/Headers/AgTechTitleDetailHeader";
import { AgTechIconBadge } from "agtech/web/components/Icons/AgTechIcons";
import AgTechFadeInView from "agtech/web/components/Pages/Loading/AgTechFadeInView";
import AgTechText from "agtech/web/components/Text/AgTechText";
import { useState, useEffect, useRef } from "react";

enum AgTechActionModalStatus {
    Open,
    Submitting,
    Submitted
}

export type AgTechActionModalState = {
    status: AgTechActionModalStatus,
    confirmationProps?: AgTechConfirmationProps
}

export type AgTechActionModalProps<TInput, TOutput> = {
    action: AgTechDataAction<TInput, TOutput>,
    actionProps: AgTechCoreDataActionProps<TInput>,
    closeModal: () => void,
}

export const AgTechActionModal = <TInput, TOutput>(props: AgTechActionModalProps<TInput, TOutput>) => {
    let [modalStatus, setModalStatus] = useState(AgTechActionModalStatus.Open);
    let [confirmationProps, setConfirmationProps] = useState<AgTechConfirmationProps | undefined>(undefined);

    let appContext = useAppContext();

    let actionConfiguration: AgTechDataActionConfiguration = props.action.getConfiguration ? props.action.getConfiguration(props.actionProps) : {
        actionExecutionMessage: 'Submitting...'
    };

    useEffect(() => {
        if (props.action.confirm) {
            props.action.confirm({
                appContext: {
                    ...appContext,
                    confirmations: {
                        askForConfirmation: async data => {
                            setConfirmationProps(data);
                            return true;
                        }
                    }
                },
                submittedEntity: props.actionProps.submittedEntity
            })
        }
    }, []);

    let dialogActionContext: AgTechDataActionContext<any, any> = {
        actionName: props.action.name,
        onExecutionStarted: async () => {
            setModalStatus(AgTechActionModalStatus.Submitting);
            await delay(600);
        },
        onExecutionSuccessful: async () => {
            setModalStatus(AgTechActionModalStatus.Submitted);

            await delay(actionConfiguration.actionConfirmationDelay ?? 800);

            props.closeModal();
        },
        onExecutionFailed: async props => {
            setModalStatus(AgTechActionModalStatus.Open);
        }
    }

    return confirmationProps ? (
        <div className='row-based bg-white rounded bordered container'>
            <DataActionContext.Provider value={dialogActionContext}>
                { modalStatus === AgTechActionModalStatus.Submitting ? (
                    <AgTechActionModalSubmittingView actionConfiguration={actionConfiguration} />
                ) : modalStatus === AgTechActionModalStatus.Submitted ? (
                    <AgTechActionModalConfirmationView actionConfiguration={actionConfiguration} />
                ) : (
                    <AgTechActionModalBody
                        action={props.action}
                        actionProps={props.actionProps}
                        confirmationProps={confirmationProps}
                        closeModal={props.closeModal}
                    />
                )}
            </DataActionContext.Provider>
        </div>
    ) : <h1>Error</h1>
}

const AgTechActionModalBody = <TInput, TOutput>(props: {
    action: AgTechDataAction<TInput, TOutput>,
    actionProps: AgTechCoreDataActionProps<TInput>,
    confirmationProps: AgTechConfirmationProps,
    closeModal: () => void,
}) => {
    let dataActionExecutor = useDataActionExecutor();
    let confirmButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (confirmButtonRef.current) {
            confirmButtonRef.current.focus();
        }
    }, []);

    let handleCancel = (e: AgTechClickEventHandler) => {
        e.clickEvent.stopPropagation();
        props.closeModal();
    }

    let confirmationProps = props.confirmationProps;

    return (
        <>
            <div className="tall row-based-vertically-centered flexed p-4 container">
                <AgTechTitleDetailHeader
                    title={confirmationProps.content.header}
                    titleFontSize='font-size-above-standard'
                    detail={confirmationProps.content.details}
                    detailClasses='mt-4'
                />
            </div>
            <div className="wide column-based-vertically-centered p-3 top-bordered container">
                <AgTechButton
                    classes="ml-auto mr-2"
                    text={confirmationProps.content.cancelButtonText ?? "Cancel"}
                    icon="times"
                    action={handleCancel}
                />
                <AgTechButton
                    text={confirmationProps.content.confirmationButtonText ?? "Continue"}
                    icon="check"
                    classes='highlight'
                    ref={confirmButtonRef}
                    action={e => {
                        e.clickEvent.stopPropagation();
                        
                        dataActionExecutor.executeAction(props.action, {
                            ...props.actionProps,
                            settings: {
                                shouldConfirmExecution: false
                            }
                        });
                    }}
                />
            </div>
        </>
    )
}

const AgTechActionModalSubmittingView = (props: {
    actionConfiguration: AgTechDataActionConfiguration
}) => {
    return (
        <div className="stretched absolute bg-white container">
            <div className="stretched row-based centered container">
                <div className="loader" />
                <h1 className="font-size-medium font-bold mt-4">{props.actionConfiguration.actionExecutionMessage ?? ''}</h1>      
            </div>
        </div>
    )
}

export const AgTechActionModalConfirmationView = <TFormInput, TFormOutput>(props: {
    actionConfiguration: AgTechDataActionConfiguration
}) => {
    return (
        <div className="stretched absolute bg-white centered container">
            <AgTechFadeInView classes='centered'>
                <div className="row-based centered container">
                    <AgTechIconBadge icon={'check'} styles={{
                        backgroundColor: AgTechColors.DarkGreen,
                        color: AgTechColors.White,
                        height: 36,
                        width: 36,
                        borderRadius: 44,
                        fontSize: 16,
                        paddingTop: 2
                    }} />
                    <AgTechText
                        text={props.actionConfiguration.actionConfirmationMessage ?? 'Submission successful!'}
                        classes='font-size-medium font-bold mt-4'
                    />
                </div>
            </AgTechFadeInView>
        </div>
    )
}