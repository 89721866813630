import React, { useRef, MouseEvent, CSSProperties, useState, useEffect } from 'react'
import { AgTechWebAppContext, useAgTechWebAppContext } from '../../App/AgTechWebAppContext';
import { AgTechPopUpCoreProps, AgTechPopUpPosition, AgTechRenderedPopUp } from './Helpers/AgTechPopUpData';
import { logDev } from 'agtech/core/logging/AgTechLogger';
import { AgTechClickEventHandler, useClickable } from 'agtech/web/components/Buttons/AgTechButtons';

export type AgTechCorePopUpOnHoverProps = {
    classes?: string,
    styles?: CSSProperties,
    popupRenderProps?: () => AgTechPopUpCoreProps,
    refreshWhenChanged?: string,
    closeOtherPopupsOnOpen?: boolean,
}

export type AgTechPopUpOnHoverContainerProps = AgTechCorePopUpOnHoverProps & {
    children: (isOpen: boolean) => React.ReactNode
}

export const usePopUpContainer = (props: {
    containerRef: React.RefObject<any>,
    popupRenderProps?: () => AgTechPopUpCoreProps,
    closeOtherPopupsOnOpen?: boolean,
}) => {
    let isPopUpOpen = useAgTechHoverable({
        container: props.containerRef,
        ...props
    });

    return isPopUpOpen;
}

const AgTechPopUpOnHoverContainerComponent = (props: AgTechPopUpOnHoverContainerProps) => {
    let containerRef = useRef<HTMLDivElement>(null);

    let isPopUpOpen = useAgTechHoverable({
        container: containerRef,
        ...props
    });

    return (
        <div
            className={props.classes}
            ref={containerRef}
            onClick={e => e.stopPropagation()}
            style={props.styles}
        >
            {props.children(isPopUpOpen)}
        </div>
    )
}

const AgTechPopUpOnHoverTextComponent = (props: AgTechCorePopUpOnHoverProps & { text: string, onClicked?: (e: AgTechClickEventHandler) => void }) => {
    let textRef = useRef<HTMLHeadingElement>(null);
    let linkClickable = useClickable(textRef);

    useAgTechHoverable({
        container: textRef,
        ...props,
    });
    
    return (
        <div className='inline-block block-centered-vertically clickable'>
            <h1
                className={`font-size-small inline ${props.onClicked ? 'clickable' : ''} ${props.classes}`}
                ref={textRef}
                onClick={e => props.onClicked ? props.onClicked({ ...linkClickable, clickEvent: e }) : e.stopPropagation()}
                style={props.styles}
            >
                {props.text}
            </h1>
        </div>
    )
}

export type AgTechHoverableProps = {
    container: React.RefObject<HTMLElement>,
    popupRenderProps?: () => AgTechPopUpCoreProps,
    closeOtherPopupsOnOpen?: boolean,
    onClick?: () => void
}

export const useAgTechHoverable = (props: AgTechHoverableProps) => {
    let webAppContext = useAgTechWebAppContext();
    let renderedPopUpRef = useRef<AgTechRenderedPopUp | null>(null);
    let hoverDelayRef = useRef(false);

    let [isPopUpOpen, setIsPopUpOpen] = useState(false);

    useEffect(() => {
        if (props.container.current && props.popupRenderProps) {
            let popupProps = props.popupRenderProps;

            props.container.current.onmouseenter = () => handleHoverableFocus(
                { ...props, popupRenderProps: popupProps },
                webAppContext,
                renderedPopUpRef,
                hoverDelayRef,
                setIsPopUpOpen
            );

            props.container.current.onmouseleave = (e) => handleHoverableBlur(
                e,
                { ...props, popupRenderProps: popupProps },
                renderedPopUpRef,
                hoverDelayRef
            );
        }
    });

    return isPopUpOpen;
}

export type AgTechHoverableHandlerProps = {
    container: React.RefObject<HTMLElement>,
    popupRenderProps: () => AgTechPopUpCoreProps,
    closeOtherPopupsOnOpen?: boolean,
    onClick?: () => void
}

const handleHoverableFocus = (
    props: AgTechHoverableHandlerProps,
    webAppContext: AgTechWebAppContext,
    renderedPopUpRef: React.MutableRefObject<AgTechRenderedPopUp | null>,
    hoverDelayRef: React.MutableRefObject<boolean>,
    setIsPopUpOpen: (isOpen: boolean) => void
) => {
    if (!renderedPopUpRef.current || renderedPopUpRef.current.popupRef.current === null) {
        hoverDelayRef.current = true;

        setTimeout(() => {
            if (hoverDelayRef.current) {
                let popupRenderProps = props.popupRenderProps();

                renderedPopUpRef.current = webAppContext.popups.showPopUp({
                    popup: popupRenderProps.popup,
                    config: {
                        ...popupRenderProps.config
                    },
                    handlers: {
                        onBeforePopUpOpened: () => {
                            if (props.closeOtherPopupsOnOpen ?? false) {
                                webAppContext.popups.closeAll();
                            }
                        },
                        onPopUpOpened: () => {
                            if (popupRenderProps.handlers?.onPopUpOpened) {
                                popupRenderProps.handlers.onPopUpOpened();
                            }

                            setIsPopUpOpen(true);
                        },
                        onPopUpClosed: () => {
                            if (popupRenderProps.handlers?.onPopUpClosed) {
                                popupRenderProps.handlers?.onPopUpClosed();
                                renderedPopUpRef.current = null;
                            }

                            setIsPopUpOpen(false);
                        }
                    },
                    callerRef: props.container,
                });
            }
        }, 200); 
    }
}

const handleHoverableBlur = (
    e: globalThis.MouseEvent,
    props: AgTechHoverableHandlerProps,
    renderedPopUpRef: React.MutableRefObject<AgTechRenderedPopUp | null>,
    hoverDelayRef: React.MutableRefObject<boolean>,
) => {
    hoverDelayRef.current = false;

    if (renderedPopUpRef.current && renderedPopUpRef.current.popupRef.current) {
        let popupRect = renderedPopUpRef.current.popupRef.current.getBoundingClientRect();
        let popupPosition = props.popupRenderProps().config?.position ?? AgTechPopUpPosition.Beside;
        let isMouseStillInPopUpContainer = true;

        if (popupPosition === AgTechPopUpPosition.Beside) {
            isMouseStillInPopUpContainer = (popupRect.left - 12) <= e.clientX && (popupRect.right + 12) >= e.clientX;
        }
        else {
            isMouseStillInPopUpContainer = (popupRect.top - 8) < e.clientY && (popupRect.bottom + 8) > e.clientY;
        }

        // If the mouse is not in the popup, give leeway vertically for the container
        if (!isMouseStillInPopUpContainer && props.container.current) {
            let containerRect = props.container.current?.getBoundingClientRect();
            isMouseStillInPopUpContainer = (containerRect.top - 16) < e.clientY && (containerRect.bottom + 16) > e.clientY;
        }

        if (!isMouseStillInPopUpContainer) {
            logDev('Closing popup');

            renderedPopUpRef.current.close();
            renderedPopUpRef.current = null;
        }
    }
}


const AgTechPopUpOnHoverContainer = React.memo(AgTechPopUpOnHoverContainerComponent, (prev, curr) => {
    return prev.refreshWhenChanged === curr.refreshWhenChanged;
}) as typeof AgTechPopUpOnHoverContainerComponent;

export const AgTechPopUpOnHoverText = React.memo(AgTechPopUpOnHoverTextComponent, (prev, curr) => {
    return prev.refreshWhenChanged === curr.refreshWhenChanged;
}) as typeof AgTechPopUpOnHoverTextComponent;

export default AgTechPopUpOnHoverContainer;