import React, { PropsWithChildren } from 'react'
import AgTechCard, { AgTechCoreCardProps } from './/AgTechCard';
import AgTechTitleDetailHeader from '../Headers/AgTechTitleDetailHeader';

export type AgTechTitleDetailContentCardProps = AgTechCoreCardProps & {
    title: string,
    detail?: string,
    headerContent?: () => React.ReactNode
}

let AgTechTitleDetailContentCard = (props: PropsWithChildren<AgTechTitleDetailContentCardProps>) => {

    let CardHeader = () => {
        return (
            <AgTechTitleDetailHeader title={props.title} detail={props.detail}>
                {props.headerContent ? props.headerContent() : null}
            </AgTechTitleDetailHeader>
        )
    }

    return (
        <AgTechCard header={CardHeader} bodyClasses='px-3 py-2' {...props}>
            {props.children}
        </AgTechCard>
    )
};

export default AgTechTitleDetailContentCard;