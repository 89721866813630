import React from 'react'
import AgTechWebAppContext, { useAgTechWebAppContext } from 'agtech/web/components/App/AgTechWebAppContext';
import { AgTechWebApplication } from 'agtech/web/application/AgTechWebApplication';
import AgTechWebAppSurface from 'agtech/web/components/App/AgTechWebAppSurface';
import AgTechWebAppLoadingPage from 'agtech/web/components/App/AgTechWebAppLoadingPage';
import AgTechWebAppAuthContext from 'agtech/web/components/Auth/AgTechWebAppAuthContext';
import AgTechWebAppDataContext from 'agtech/web/components/App/AgTechWebAppDataContext';
import AgTechWebAppErrorBoundary from 'agtech/web/components/App/AgTechWebAppErrorBoundary';

const AgTechWebApp = (props: { app: AgTechWebApplication }) => {
    let appNavigation = useAgTechWebAppContext().navigation;

    return (
        <AgTechWebAppErrorBoundary>
            <AgTechWebAppAuthContext auth={props.app.auth}>
                <AgTechWebAppDataContext app={props.app}>
                    <AgTechWebAppLoadingPage app={props.app}>
                        <AgTechWebAppContext app={props.app}>
                            <AgTechWebAppSurface 
                                app={props.app}
                                path={`${appNavigation.currentRoute.area}/${appNavigation.currentRoute.workspace}`}
                            />
                        </AgTechWebAppContext>
                    </AgTechWebAppLoadingPage>
                </AgTechWebAppDataContext>
            </AgTechWebAppAuthContext>
        </AgTechWebAppErrorBoundary>
    )
}

export default AgTechWebApp;