import { AgTechButtonProps, AgTechButton, AgTechActionButton, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechSelectionButton from 'agtech/web/components/Buttons/AgTechSelectionButton';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridSearchState } from 'agtech/web/components/Grids/Features/AgTechGridSearching';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import { AgTechPopUpCoreProps } from 'agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData';
import AgTechSearchControl from 'agtech/web/components/Widgets/AgTechSearchControl';
import ContactActionMenu from 'app/areas/contacts/components/menus/ContactActionMenu';
import ContactInformationPopUp from 'app/areas/contacts/components/popups/ContactInformationPopUp';
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog';
import { AgTechContact, getContactFullName, useContact, useContactCredit, useContactNote, useContacts } from 'app/data/operation/contacts/ContactData';
import { useContactDataDeleteContactAction } from 'app/data/operation/contacts/ContactDataActions';
import { ContactCredit, useContactCreditDataDeleteCreditAction } from 'app/data/operation/contacts/credits/ContactDataCredits';
import { ContactNote, useContactNoteDataDeleteNoteAction } from 'app/data/operation/contacts/notes/ContactDataNotes';
import React, { useContext } from 'react'
import { useContactSelectionProps } from '../selections/ContactSelectionControl';
import ContactNoteActionMenu from 'app/areas/contacts/components/menus/ContactNoteActionMenu';
import ContactCreditActionMenu from 'app/areas/contacts/components/menus/ContactCreditActionMenu';

export type AgTechContactButtonProps = AgTechButtonProps & {
    contactId: number
}

export const AgTechContactActionButton = (props: AgTechContactButtonProps) => {
    return (
        <AgTechActionButton>
            <ContactActionMenu contactId={props.contactId} />
        </AgTechActionButton>
    )
}

export const AgTechContactRemovalButton = (props: AgTechContactButtonProps) => {
    let contact = useContact(props.contactId);
    let contactDeleteAction = useContactDataDeleteContactAction();

    return (
        <AgTechButton
            text="Remove Contact"
            icon="times"
            action={async e => {
                await e.actions.executeDataActionInModal(contactDeleteAction, {
                    originalEntity: contact,
                    submittedEntity: contact
                });
            }}
        />
    )
}

export type ContactNoteRemovalButtonProps = AgTechButtonProps & {
    note: ContactNote
}

export const ContactNoteRemovalButton = (props: ContactNoteRemovalButtonProps) => {
    let noteDeleteAction = useContactNoteDataDeleteNoteAction();

    let buttonProps: AgTechButtonProps = {
        text: 'Delete Note',
        icon: 'times',
        ...props,
        action: async e => await e.actions.executeDataActionInModal(noteDeleteAction, {
            originalEntity: props.note,
            submittedEntity: props.note
        })
    };

    return <AgTechButton {...buttonProps} />
}

export const ContactNoteActionButton = (props: { contactId: number, noteId: number }) => {
    return (
        <AgTechActionButton>
            <ContactNoteActionMenu contactId={props.contactId} noteId={props.noteId} />
        </AgTechActionButton>
    )
}

export const ContactCreditActionButton = (props: { contactId: number, creditId: number }) => {
    return (
        <AgTechActionButton>
            <ContactCreditActionMenu {...props} />
        </AgTechActionButton>
    )
}

export const ContactCreditRemovalButton = (props: { contactId: number, creditId: number }) => {
    let credit = useContactCredit(props.contactId, props.creditId);
    let creditDeleteAction = useContactCreditDataDeleteCreditAction();

    let buttonProps: AgTechButtonProps = {
        text: 'Delete Credit',
        icon: 'times',
        ...props,
        action: async e => await e.actions.executeDataActionInModal(creditDeleteAction, {
            originalEntity: credit,
            submittedEntity: credit
        })
    };

    return <AgTechButton {...buttonProps} />
}

export const ContactGridLink = (props: { contactId: number }) => {
    let contact = useContact(props.contactId);

    return (
        <AgTechGridLink
            content={getContactFullName(contact)}
            onClick={e => ContactLinkClick(e, contact)}
            onHover={() => ContactLinkHover(props.contactId)}
        />
    )
}

export const ContactLinkClick = (e: AgTechClickEventHandler, contact?: AgTechContact) => {
    if (contact) {
        e.dialogs.openDialog({
            dialog: () => <ContactDialog contactId={contact.id} />
        });
    }
}

export const ContactLinkHover = (contactId: number): AgTechPopUpCoreProps => ({
    popup: () => <ContactInformationPopUp contactId={contactId} />,
    config: {
        topOffset: 24,
        shouldCloseOnBlur: false
    }
});

export type ContactSelectionProps = {
    contacts?: AgTechContact[],
    filter?: (contact: AgTechContact) => boolean,
    selectedContactId?: number,
    onContactSelected: (contact: AgTechContact) => Promise<void>
}

export const ContactSelectionButton = (props: ContactSelectionProps & AgTechButtonProps) => {
    let sourceContacts = props.contacts ?? useContacts();
    let contacts = props.filter ? sourceContacts.filter(props.filter) : sourceContacts;

    let contactSelectionProps = useContactSelectionProps({
        contacts: contacts,
        onContactSelected: props.onContactSelected,
        selectedContactId: props.selectedContactId
    });
    
    return (
        <AgTechSelectionButton
            text='Split lot'
            icon='plus'
            {...props}
            selectionProps={{
                ...contactSelectionProps,
                items: contactSelectionProps.items,
                content: {
                    ...contactSelectionProps.content,
                    header: headerProps => (
                        <div className='row-based stretched py-2 container'>
                            <AgTechTitleDetailHeader
                                title='Contacts'
                                titleFontSize='font-size-large'
                                content={() => (
                                    <AgTechSearchControl
                                        placeholder='Search contacts...'
                                        options={[
                                            { field: 'Name', value: cnt => cnt.firstName + ' ' + cnt.lastName}
                                        ]}
                                        onUpdateSearchState={(searchState: AgTechGridSearchState<AgTechContact>) => {
                                            let visibleItems = headerProps.state.allItems.filter(item => searchState.selectedField.value(item).includes(searchState.filter));
                                            headerProps.handlers.onItemsUpdated(visibleItems);
                                        }}
                                        width={180}
                                        styles={{ height: 32 }}
                                    />
                                )}
                                contentClasses='ml-auto'
                            />
                        </div>
                    ),
                    footer: footerProps => (
                        <div className='wide column-based-vertically-centered container'>
                            <ContactDialog.Button
                                classes='link ml-auto'
                                onContactSubmitted={footerProps.handlers.onItemSelected}
                            />
                        </div>
                    ),
                    styles: { height: 320, width: 420 }
                }
            }}
        />
    )
}