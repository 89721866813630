export const formatUSD = (amount: number) => {
    return '$' + amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatStringToUSD = (amount: string) => {
    return formatUSD(parseFloat(amount));
}

export const writeToArray = <TEntity extends { id: string | number }>(array: TEntity[], obj: TEntity) => {
    const index = array.findIndex((e) => e.id === obj.id);

    if (index === -1) {
        array.push({...obj});
    } else {
        array[index] = {...obj};
    }
}

export const deleteFromArray = <TEntity extends { id: string | number }>(array: TEntity[], id: string | number) => {
    array = array.filter(arr => arr.id !== id);
}