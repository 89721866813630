import React, { useRef, useState } from "react";
import { AgTechForm } from "agtech/web/components/Forms/AgTechForm";
import { AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechFadeInView from "agtech/web/components/Pages/Loading/AgTechFadeInView";
import { AgTechFormDialogBehaviorProps, useAgTechFormDialog } from "./AgTechFormDialog";
import AgTechTitleDetailHeader from "agtech/web/components/Headers/AgTechTitleDetailHeader";
import { AgTechFormContext } from "agtech/core/forms/AgTechFormContext";
import { useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import AgTechDataProvider from "agtech/core/data/providers/AgTechDataProvider";
import { AgTechFormActionProps, AgTechFormDataProps, AgTechFormSubmissionButton } from "agtech/core/forms/AgTechFormProps";
import AgTechCloseIcon from "../../Icons/AgTechCloseIcon";
import AgTechDataActionSurface from "agtech/core/data/actions/AgTechDataActionSurfaces";

export type AgTechFormDialogStep<TEntity> = {
    name: string,
    onValidateStep?: (currentFormEntity: TEntity) => Promise<boolean>,
    body: (formContext: AgTechFormContext<TEntity>) => React.ReactNode
}

export type AgTechMultiStepFormDialogProps<TFormData, TActionData, TResponseData> = {
    formData: AgTechFormDataProps<TFormData>,
    formAction: AgTechFormActionProps<TFormData, TActionData, TResponseData>,
    structure: AgTechMultiStepFormDialogStructureProps<TFormData>,
    behavior?: AgTechFormDialogBehaviorProps<TActionData, TResponseData>,
}

export type AgTechMultiStepFormDialogStructureProps<TFormData> = {
    title: string,
    details?: string,
    steps: AgTechFormDialogStep<TFormData>[]
    width?: number,
    submitButtonText?: string,
    cancelButtonText?: string,
}

const AgTechMultiStepFormDialog = <TFormData, TActionData, TResponseData>(
    props: AgTechMultiStepFormDialogProps<TFormData, TActionData, TResponseData>
) => {
    let [dialogWidth, setDialogWidth] = useState(props.structure.width);

    return (
        <div
            className="agtech-dialog agtech-multi-step-dialog"
            style={{ width: dialogWidth ?? 400 }}
            onClick={e => e.stopPropagation()}
        >
            <AgTechDataProvider
                loadData={props.formData.loadInitialFormData}
                content={{
                    loadingText: props.formData.loadingText
                }}
            >
                {(initialFormData) => (
                    <AgTechMultiStepFormDialogSurface
                        {...props}
                        grow={width => setDialogWidth(width)}
                        initialFormData={initialFormData}
                    />
                )}
            </AgTechDataProvider>
        </div>
    )
}

declare type AgTechMultiStepFormDialogSurfaceProps<TFormData, TActionData, TResponseData> = {
    initialFormData: TFormData,
    formAction: AgTechFormActionProps<TFormData, TActionData, TResponseData>,
    structure: AgTechMultiStepFormDialogStructureProps<TFormData>,
    behavior?: AgTechFormDialogBehaviorProps<TActionData, TResponseData>,
    grow: (updatedWidth: number) => void
}

const AgTechMultiStepFormDialogSurface = <TFormData, TActionData, TResponseData>(
    props: AgTechMultiStepFormDialogSurfaceProps<TFormData, TActionData, TResponseData>
) => {
    let formDialog = useAgTechFormDialog({
        formAction: props.formAction,
        behavior: props.behavior
    });

    let [currentStepIndex, setCurrentStepIndex] = useState(0);
    let formContextRef = useRef<AgTechFormContext<TFormData> | null>(null);

    let currentStep = props.structure.steps[currentStepIndex];
    let isCurrentStepLastStep = currentStepIndex === props.structure.steps.length - 1;

    let stepActionExecutor = useDataActionExecutor();

    const moveToPriorStep = async () => {
        if (formDialog.dialogSubmissionButton.current) {
            formDialog.dialogSubmissionButton.current.click = async () => {};
        }
        
        setCurrentStepIndex(currentStepIndex - 1);
    }

    const moveToNextStep = async () => {
        let isCurrentStepValid = true;

        if (formContextRef.current && formContextRef) {
            let currentStepSubmissionValidationResult = await stepActionExecutor.validateAction(props.formAction.dataAction, {
                originalEntity: props.formAction.getActionData(props.initialFormData),
                submittedEntity: props.formAction.getActionData(formContextRef.current.entity)
            });

            isCurrentStepValid = currentStepSubmissionValidationResult.wasSuccessful;
        }

        if (currentStep.onValidateStep && formContextRef.current) {
            isCurrentStepValid = await currentStep.onValidateStep(formContextRef.current.entity);
        }

        if (isCurrentStepValid ?? true) {
            setCurrentStepIndex(currentStepIndex + 1);
        }
    }

    return (
        <AgTechDataActionSurface
            action={props.formAction.dataAction}
            behavior={{
                eventHandlers: props.formAction.eventHandlers,
                confirmation: props.behavior?.confirmation ? {
                    getConfirmationPane: (data) => {
                        return props.behavior?.confirmation
                            ? props.behavior.confirmation.getConfirmationPane(data, {
                                close: formDialog.closeFormDialog,
                                grow: props.grow
                            })
                            : null;
                    }
                }
                : undefined
            }}
        >
            {() => (
                <>
                    <div className="agtech-dialog-header" style={{ backgroundColor: 'white' }}>
                        <AgTechTitleDetailHeader
                            title={props.structure.title}
                            detail={props.structure.details}
                            contentClasses='centered'
                            titleClasses="font-size-medium"
                        >
                            <AgTechCloseIcon onClicked={formDialog.closeFormDialog} />
                        </AgTechTitleDetailHeader>
                    </div>
                    <div className="column-based stretched non-scrollable container">
                        <div className='row-based tall p-5 py-6 right-bordered container' style={{ width: 200 }}>
                            <div className='row-based tall container'>
                                {props.structure.steps.map((step, index) => {
                                    const stepDotDiameter = 14;

                                    let isActiveStep = currentStepIndex === index;
                                    let isCompletedStep = index < currentStepIndex;
        
                                    let borderClasses = index === props.structure.steps.length - 1 ? '' : 'left-bordered';
                                    let activeClasses = isActiveStep ? 'bg-highlight' : isCompletedStep ? 'bg-green' : 'bg-gray';
        
                                    return  (
                                        <div key={step.name} className={`column-based ${borderClasses} container`} style={{ height: 56 }}>
                                            <div className={`${activeClasses} rounded-circle mr-3`} style={{ width: stepDotDiameter, height: stepDotDiameter, marginLeft: 0 - (stepDotDiameter / 2) }} />
                                            <h1 className='font-size-small font-bold' style={{ lineHeight: stepDotDiameter + 'px' }}>{step.name}</h1>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="agtech-dialog-body">
                            <div className="agtech-dialog-body-surface row-based bg-surface-light container">
                                <AgTechMultiStepFormDialogBody
                                    {...props}
                                    currentStep={currentStep}
                                    dialogSubmissionButton={formDialog.dialogSubmissionButton}
                                    setCurrentStepFormContext={context => {
                                        formContextRef.current = { ...context };
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="agtech-dialog-footer pt-5 top-bordered">
                        { currentStepIndex === 0 ? null : (
                            <AgTechButton
                                key={'Previous'}
                                text={props.structure.steps[currentStepIndex - 1].name}
                                icon="chevron-left"
                                action={moveToPriorStep}
                                styles={{ minWidth: 160, maxWidth: '45%' }}
                            />
                        )}
                        { isCurrentStepLastStep ? (
                            <AgTechButton
                                key={'Submit'}
                                text={(props.structure.submitButtonText ?? 'Submit')}
                                icon={'check'}
                                iconOnLeft={true}
                                classes='highlight'
                                action={() => formDialog.dialogSubmissionButton.current?.click()}
                                styles={{ minWidth: 160, maxWidth: '45%' }}
                            />
                        ) : (
                            <AgTechButton
                                key={'Next'}
                                text={props.structure.steps[currentStepIndex + 1].name}
                                classes='px-2'
                                icon={'chevron-right'}
                                iconOnLeft={false}
                                iconClasses="ml-auto"
                                action={moveToNextStep}
                                styles={{ minWidth: 160, maxWidth: '45%' }}
                            />
                        )}
                        
                    </div>
                </>
            )}
        </AgTechDataActionSurface>
    )
}

declare type AgTechMultiStepFormDialogBodyProps<TFormData, TActionData, TResponseData> =
    AgTechMultiStepFormDialogSurfaceProps<TFormData, TResponseData, TActionData> & {
    currentStep: AgTechFormDialogStep<TFormData>,
    dialogSubmissionButton: React.RefObject<AgTechFormSubmissionButton>,
    setCurrentStepFormContext: (formContext: AgTechFormContext<TFormData>) => void
}

const AgTechMultiStepFormDialogBody = <TFormData, TActionData, TResponseData>(
    props: AgTechMultiStepFormDialogBodyProps<TFormData, TActionData, TResponseData>
) => {
    return (
        <div className="stretched row-based pr-2 container">
            <AgTechForm
                initialFormData={props.initialFormData}
                action={{
                    dataAction: props.formAction.dataAction,
                    getActionData: props.formAction.getActionData
                }}
                structure={{
                    submitButton: props.dialogSubmissionButton
                }}
            >
                {(context) => {
                    props.setCurrentStepFormContext(context);

                    return (
                        <AgTechFadeInView key={props.currentStep.name} classes="row-based pl-5 pr-3 my-2 vertical-pane scrollable container">
                            {props.currentStep.body(context)}
                        </AgTechFadeInView>
                    );
                }}
            </AgTechForm>
        </div>
    )
}

export default React.memo(AgTechMultiStepFormDialog, () => true) as typeof AgTechMultiStepFormDialog;