import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import AgTechPortal, { useAgTechLayerCount } from "../AgTechPortal";

export type AgTechDialogSlotProps = {
    closeDialog: () => void
}

export type AgTechDialogContext = {
    closeDialog: (e: React.MouseEvent<HTMLElement> | null) => void
}

export const DialogContext = createContext<AgTechDialogContext>({
    closeDialog: (e: React.MouseEvent<HTMLElement> | null) => {}
});

export const useDialogContext = () => useContext(DialogContext);

let AgTechDialogSlotComponent = (props: PropsWithChildren<AgTechDialogSlotProps>) => {
    let [isActive, setIsActive] = useState(false);
    let layerCount = useAgTechLayerCount();

    let dialogContext: AgTechDialogContext = {
        closeDialog: (e: React.MouseEvent<HTMLElement> | null) => {
            if (e) {
                e.stopPropagation();
            }

            props.closeDialog();
        }
    };

    useEffect(() => {
        window.setTimeout(() => {
            setIsActive(true);
        }, 10);
    }, []);

    return (
        isActive ? (
            <AgTechPortal>
                <DialogContext.Provider value={dialogContext}>
                    <div
                        className="agtech-app-layer stretched relative container"
                        style={{ overflow: 'visible', justifyContent: 'flex-end' }}
                        data-layer={layerCount.current + 1}
                    >
                        {props.children}
                    </div>
                </DialogContext.Provider>
            </AgTechPortal>
        ) : null
    )
};

const AgTechDialogSlot = React.memo(AgTechDialogSlotComponent, (prev, curr) => true);

export default AgTechDialogSlot;