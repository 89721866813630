import { AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechCard from "agtech/web/components/Cards/AgTechCard";
import AgTechTitleDetailHeader from "agtech/web/components/Headers/AgTechTitleDetailHeader";
import { AgTechIconBadge } from "agtech/web/components/Icons/AgTechIcons";

export type ReportCardProps = {
    reportName: string,
    reportDescription: string,
    reportIcon: string,
    reportDownloadAction: () => Promise<any>
}

const ReportCard = (props: ReportCardProps) => {
    return (
        <AgTechCard
            height={180}
            header={() => (
                <AgTechTitleDetailHeader
                    title={props.reportName}
                    titleClasses='font-size-medium'
                >
                    <AgTechIconBadge
                        icon={props.reportIcon}
                        size={30}
                        classes='font-size-small'
                    />
                </AgTechTitleDetailHeader>
            )}  
            footer={() => (
                <AgTechButton
                    text='Download'
                    icon='download'
                    action={async e => await e.actions.executeWithVisual(
                        props.reportDownloadAction,
                        { message: 'Downloading report: ' + props.reportName, delay: 1000 },
                    )}
                    classes='ml-auto link'
                />
            )}
        >
            <h1 className='font-size-standard font-color-secondary px-4 py-2'>
                {props.reportDescription}
            </h1>
        </AgTechCard>
    )
}

export default ReportCard;