import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechFormRow, AgTechFormNumberControl, AgTechFormTextAreaControl, AgTechFormReadOnlyTextControl, AgTechFormDateControl, AgTechFormToggleControl, AgTechFormSection } from 'agtech/web/components/Forms/AgTechForm';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import { AgTechContact, getContactFullName, useContact } from 'app/data/operation/contacts/ContactData';
import { ContactCredit, useContactCreditDataWriteCreditAction } from 'app/data/operation/contacts/credits/ContactDataCredits';
import dayjs from 'dayjs';
import React, { useState } from 'react'

export type ContactCreditDialogProps = {
    contactId: number,
    credit?: ContactCredit
}

type ContactCreditDialogModel = ContactCredit & {
    hasExpiration: boolean
}

const ContactCreditDialog = (props: ContactCreditDialogProps) => {
    let contact = useContact(props.contactId);
    let creditSubmission = useContactCreditDataWriteCreditAction(props.contactId);

    let contactCredit: ContactCreditDialogModel = props.credit
        ? {
            ...props.credit,
            expires: props.credit?.expires ? new Date(props.credit?.expires) : undefined,
            hasExpiration: props.credit.expires !== undefined
        }
        : {
            id: 0,
            contactId: props.contactId,
            creditAmount: 0,
            description: '',
            privateNote: '',
            expires: undefined,
            hasExpiration: false
        };

    console.log(contactCredit);

    let [expiresVisible, setExpiresVisibility] = useState<boolean>(contactCredit.hasExpiration);

    return (
        <AgTechFormDialog
            structure={{
                header: props.credit ? 'Update Credit' : 'Create Credit',
                submitButtonText: props.credit ? 'Update' : 'Create',
                width: 450
            }}
            formData={{
                loadInitialFormData: () => contactCredit
            }}
            formAction={{
                dataAction: creditSubmission,
                getActionData: d => d
            }}
            refreshWhenChanged={expiresVisible}
        >
            <AgTechFormRow>
                <AgTechFormReadOnlyTextControl
                    entityType={contactCredit}
                    caption='Contact'
                    value={() => getContactFullName(contact)}
                />
                <AgTechFormNumberControl
                    entityType={contactCredit}
                    caption='Amount'
                    value={credit => credit.creditAmount}
                    required={true}
                    unit="$"
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        creditAmount: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextAreaControl
                    entityType={contactCredit}
                    caption='Description'
                    value={credit => credit.description}
                    rows={4}
                    width={100}
                    required={true}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        description: val
                    })}
                    resizable={false}
                />
            </AgTechFormRow>
            <AgTechFormSection header='Credit Details'>
                <AgTechFormRow>
                    <AgTechFormToggleControl
                        entityType={contactCredit}
                        caption='Expires?'
                        value={entity => expiresVisible}
                        onControlValueChanged={async (entity, value) => {
                            setExpiresVisibility(value);

                            return {
                                ...entity,
                                expires: value ? new Date() : undefined,
                                hasExpiration: value
                            };
                        }}
                    />
                    {expiresVisible ? (
                        <AgTechFormDateControl
                            entityType={contactCredit}
                            caption="Expiration Date" 
                            value={d => d.expires ?? new Date()} 
                            width={50}
                            readonly={!expiresVisible}
                            allowPreviousDates={false}
                            onControlValueChanged={async (e, val) => ({
                                ...e,
                                expires: dayjs(val).endOf('day').toDate()
                            })}
                        />
                    ) : (
                        <AgTechFormReadOnlyTextControl
                            entityType={contactCredit}
                            caption="Expiration Date" 
                            value={d => d.hasExpiration ? dayjs(d.expires).format('MM/DD/YYYY') : ''} 
                        />
                    )}
                </AgTechFormRow>
                <AgTechFormRow>
                    <AgTechFormTextAreaControl
                        entityType={contactCredit}
                        caption='Private Note'
                        value={credit => credit.privateNote}
                        rows={4}
                        width={100}
                        required={false}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            privateNote: val
                        })}
                        resizable={false}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
        </AgTechFormDialog>
    )
}

ContactCreditDialog.Open = (e: AgTechClickEventHandler, props: ContactCreditDialogProps) => e.dialogs.openDialog({
    dialog: () => <ContactCreditDialog {...props} />
});

ContactCreditDialog.Button = (props: ContactCreditDialogProps & AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        text: props.credit ? 'Update Credit' : 'Create Credit',
        icon: props.credit ? 'edit' : 'plus',
        ...props,
        action: e => ContactCreditDialog.Open(e, props)
    };

    return <AgTechButton {...buttonProps} />
}

export default ContactCreditDialog;