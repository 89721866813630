import { AgTechCheckBoxControl } from "agtech/web/components/Forms/AgTechEditableControls";
import { AgTechGridEntity, AgTechGridEntityState, AgTechGridEntityStatus } from "agtech/web/components/Grids/Data/AgTechGridEntities";
import { AgTechGridConfiguration, AgTechGridEventHandlers } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { AgTechGeneralIcon } from "agtech/web/components/Icons/AgTechIcons";
import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { IAgTechGridItem, AgTechGridColumnMetadata } from "agtech/web/components/Grids/Data/AgTechGridData";
import { AgTechGridRowStatusCreatedIcon, AgTechGridRowStatusInvalidIcon, AgTechGridRowStatusUpdatedIcon } from "agtech/web/components/Grids/Rows/AgTechGridRowStatusIcons";
import { logDev, logEvent } from "agtech/core/logging/AgTechLogger";

export type AgTechGridRowProps<TItem extends IAgTechGridItem> = {
    index: number,
    entity: AgTechGridEntity<TItem>,
    state: AgTechGridEntityState,
    columns: AgTechGridColumnMetadata<TItem>[],
    style: CSSProperties,
    gridConfiguration: AgTechGridConfiguration<TItem>,
    hasRowChanged: (prev: TItem, curr: TItem) => boolean,
    handlers: AgTechGridEventHandlers<TItem>,
    isFocused: boolean
}

const AgTechGridRow = <TItem extends IAgTechGridItem>(props: AgTechGridRowProps<TItem>) => {
    let gridContext = props.gridConfiguration;
    let gridRowEntity = useRef({
        ...props.entity
    });

    if (props.hasRowChanged(gridRowEntity.current.item, props.entity.item)) {
        logEvent('Refresh row entity', {
            propEntity: props.entity
        });
    
        gridRowEntity.current = {
            ...props.entity
        };
    }
    
    // Handles whenever a key is pressed within this grid row
    const onRowKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await props.handlers.onEnterKeyPressed(e.key, gridRowEntity.current, props.entity.item);
        }
    }

    // Handles whenever the user attempts to delete this grid row
    const onRowDeleted = async () => {
        await props.handlers.onItemDeleted(gridRowEntity.current);
    }

    const onRowFocused = async () => {
        await props.handlers.onRowFocused(gridRowEntity.current);
    }

    const handleRowSelectionChange = async (wasRowSelected: boolean) => {
        if (wasRowSelected && !props.state.isSelected) {
            await props.handlers.onItemSelected(gridRowEntity.current);
        }
        else if (props.state.isSelected) {
            await props.handlers.onItemDeselected(gridRowEntity.current);
        }
    }

    let isRowSelected = gridContext.selectionConfig && props.state.isSelected;
    let isRowEven = (props.index + 1) % 2 === 0;
    
    let rowClickableClass = gridContext.selectionConfig ? 'clickable' : '';
    let rowFocusClass = props.isFocused && !gridContext.selectionConfig && !gridContext.editConfig ? 'focused' : '';
    let rowSelectedClass = isRowSelected ? 'selected' : '';
    let rowIndexClass = isRowEven ? 'even' : 'odd';
    let rowStatusClass = props.state.status === AgTechGridEntityStatus.Invalid ? 'invalid' : '';

    return (
        <div
            className={`agtech-grid-surface-body-row ${rowClickableClass} ${rowIndexClass} ${rowStatusClass} ${rowFocusClass} ${rowSelectedClass}`}
            style={props.style}
            onBlur={e => onGridRowBlur(e, props.entity, gridRowEntity, props.handlers)}
            onKeyDown={onRowKeyDown}
            onClick={onRowFocused}
        >
            { gridContext.selectionConfig ? (
                <div className="tall right-bordered centered container" style={{ width: 36 }}>
                    <AgTechCheckBoxControl
                        isChecked={props.state.isSelected}
                        onToggle={handleRowSelectionChange}
                    />
                </div>
            )
            : null }

            { gridContext.editConfig && (gridContext.editConfig.showStatus ?? true) ? 
                <div className="centered snug right-bordered container" style={{ width: 36 }}>
                    <AgTechGridRowStatusIcon entity={gridRowEntity.current} />
                </div>
            : null }
                
            {props.columns.map(column => {                
                let columnSizeStyle: any = column.size ? { flex: column.size } : { flex: 1 };
                            
                if (column.width) {
                    columnSizeStyle = { width: column.width };
                }

                let columnValue = column.value(gridRowEntity.current.item, updateItem => {
                    let updatedItem = updateItem(gridRowEntity.current.item);

                    gridRowEntity.current = {
                        ...gridRowEntity.current,
                        item: { ...updatedItem }
                    };

                    logEvent('Grid item updated', {
                        column: column.header,
                        updateEntity: gridRowEntity.current,
                    });
                });

                let columnClasses = column.classes ?? '';
                let columnContentClasses = column.contentClasses ?? '';
                let columnItemClasses = column.itemBasedClasses ? column.itemBasedClasses(gridRowEntity.current.item) : '';

                return (
                    <div
                        key={column.header}
                        className={`agtech-grid-surface-body-row-cell ${columnClasses}`}
                        style={columnSizeStyle}
                    >
                        <div className={`agtech-grid-surface-body-row-cell-content ${columnContentClasses} ${columnItemClasses}`}>
                            {isGridFormElement(columnValue) ? columnValue : <div className="px-3">{columnValue}</div>}
                        </div>
                    </div>
                )
            })}

            { gridContext.editConfig?.deleteItem ? 
                <div className="agtech-grid-surface-action-column">
                    <i className="agtech-icon fas fa-times" onClick={onRowDeleted} />
                </div>
            : null }

            { gridContext.actions ? 
                <div className="agtech-grid-surface-action-column" style={{ width: gridContext.actions.width ?? 36 }}>
                    { gridContext.actions.rendering(gridRowEntity.current.item) }
                </div>
            : null }

        </div>
    )
}

const onGridRowBlur = async <TItem extends IAgTechGridItem>(
    rowDiv: React.FocusEvent<HTMLDivElement>,
    originalEntity: AgTechGridEntity<TItem>,
    updatedEntity: React.MutableRefObject<AgTechGridEntity<TItem>>,
    handlers: AgTechGridEventHandlers<TItem>,
) => {
    if (!rowDiv.relatedTarget) {
        // Wait to process blur if the blur occurred due to a click outside of the grid row. This could be due to a
        // click in a selection popup and will need time for the popup to process before processing the blur
        setTimeout(async () => {
            await handlers.onRowBlurred(updatedEntity.current, originalEntity.item);
        }, 200);
    }
    else {
        let wasRowBlurred = !rowDiv.currentTarget.contains(rowDiv.relatedTarget as Node);

        if (wasRowBlurred) {
            await handlers.onRowBlurred(updatedEntity.current, originalEntity.item);
        }
    }
}

function isGridFormElement(element: React.ReactNode | JSX.Element): element is JSX.Element {
    return (element as JSX.Element)?.type !== undefined;
}

const AgTechGridRowStatusIcon = <TItem extends IAgTechGridItem>( { entity }: { entity: AgTechGridEntity<TItem> }) => {
    return entity.state.status === AgTechGridEntityStatus.Invalid ? <AgTechGridRowStatusInvalidIcon entity={entity} />
         : entity.state.status === AgTechGridEntityStatus.Created ? <AgTechGridRowStatusCreatedIcon entity={entity} />
         : entity.state.status === AgTechGridEntityStatus.Updated ? <AgTechGridRowStatusUpdatedIcon entity={entity} />
         : <AgTechGeneralIcon />;
}

const AgTechGridRowMemo = React.memo(AgTechGridRow, (prev, curr) => {
    let isSame =
           prev.entity.versionNumber === curr.entity.versionNumber &&
           prev.entity.id === curr.entity.id &&
           prev.entity.state.isSelected === curr.entity.state.isSelected &&
           prev.entity.state.status === curr.entity.state.status &&
           !curr.hasRowChanged(prev.entity.item, curr.entity.item);

    if (!isSame) {
        logEvent('Grid row changed', {
            prevItem: prev.entity,
            currItem: curr.entity,
        });
    }

    return isSame;
}) as
typeof AgTechGridRow;

export default AgTechGridRowMemo;