import React, { useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { AgTechDialogRendering, AgTechDialogRenderProps, AgTechFormDialogRenderProps, IAgTechAppDialogs } from 'agtech/core/functions/AgTechAppDialogs';
import AgTechDialogSlot from '../../Portals/Dialogs/AgTechDialogSlot';

/// Components

export type AgTechDialogSurfaceProps = {
    dialogManager: AgTechDialogManager
}

let AgTechDialogSurface = (props: AgTechDialogSurfaceProps) => {
    let dialogManager = props.dialogManager;

    let handleDialogClosing = (dialog: AgTechDialogRendering) => {
        if (dialog.onDialogSubmit) {
            dialog.onDialogSubmit()
                .then(res => dialogManager.closeDialog(dialog.dialogId));
        }
        else {
            dialogManager.closeDialog(dialog.dialogId);
        }
    }

    return (
        dialogManager.dialogs.length > 0 ? (
            <>
                {dialogManager.dialogs.map(dialog => {
                    return (
                        <AgTechDialogSlot
                            key={dialog.dialogId}
                            closeDialog={() => handleDialogClosing(dialog)}
                        >
                            {dialog.dialog()}
                        </AgTechDialogSlot>
                    )
                })}
            </>
        ) : null
    );
}

/// Manager

export type AgTechDialogManager = IAgTechAppDialogs & {
    dialogs: AgTechDialogRendering[]
}

export const DefaultAgTechDialogManager: AgTechDialogManager = {
    dialogs: [],
    openDialog: dialog => {
        console.error('Attempted to open a dialog with the default app context');
    },
    closeDialog: dialog => {
        console.error('Attempted to close a dialog with the default app context');
    }
}

export const useDialogs = (): AgTechDialogManager => {
    let [dialogs, updateDialogs] = useState<AgTechDialogRendering[]>(() => {
        return [];
    });

    let dialogsRef = useRef(dialogs);

    return {
        dialogs: dialogs,
        openDialog: dialog => {
            dialogsRef.current = [...dialogsRef.current, {
                ...dialog,
                dialogId: uuidv4()
            }];

            updateDialogs(dialogsRef.current);
        },
        closeDialog: dialogId => {
            dialogsRef.current = dialogsRef.current.filter(dg => dg.dialogId !== dialogId);
            updateDialogs(dialogsRef.current);
        }
    }
}

export default AgTechDialogSurface;