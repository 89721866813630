import { AgTechClickEventHandler, AgTechWebClickable, useClickable } from "agtech/web/components/Buttons/AgTechButtons"
import { AgTechSelectionPopUp } from "agtech/web/components/Forms/Controls/Selection/AgTechSelectionPopUp"
import { AgTechNoRecordsPageProps } from "agtech/web/components/Pages/Error/AgTechNoRecordsPage"
import { AgTechRenderedPopUp, AgTechPopUpPosition } from "agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData"
import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from "react"

/// Types

export interface IAgTechSelectionPopUpItem {
    id: string | number
}

export type AgTechSelectionItems<TItem extends IAgTechSelectionPopUpItem> = {
    [id: string]: {
        item: TItem,
        index: number
    }
}

export type AgTechSelectionControlState<TItem extends IAgTechSelectionPopUpItem> = {
    allItems: TItem[],
    visibleSelectionItems: TItem[],
    activeItem?: TItem,
    activeItemListIndex: number
}

export type AgTechSelectionControlEventHandlers<TItem extends IAgTechSelectionPopUpItem> = {
    onItemSelected: (selectedItem: TItem) => Promise<void>,
    onItemsUpdated: (updatedItems: TItem[]) => void
}

export type AgTechSelectionControlRenderingProps<TItem extends IAgTechSelectionPopUpItem> = {
    state: AgTechSelectionControlState<TItem>,
    handlers: AgTechSelectionControlEventHandlers<TItem>
}

export type AgTechSelectionControlContentProps<TItem extends IAgTechSelectionPopUpItem> = {
    header: (props: AgTechSelectionControlRenderingProps<TItem>) => React.ReactNode,
    body: (props: AgTechSelectionControlRenderingProps<TItem>) => React.ReactNode,
    footer?: (props: AgTechSelectionControlRenderingProps<TItem>) => React.ReactNode,
    styles?: CSSProperties
}

export type AgTechSelectionControlItemCreationProps<TItem extends IAgTechSelectionPopUpItem> = {
    noRecordsMessage: string,
    noRecordsPrompt: string,
    creationButtonText: string,
    creationButtonAction: (e: AgTechClickEventHandler) => Promise<TItem>
}

export type AgTechSelectionControlItemProps<TItem extends IAgTechSelectionPopUpItem> = {
    selectionItems: TItem[],
    getSelectedItemId: () => string | undefined,
    onItemSelected: (selectedItem: TItem) => Promise<any>,
    filterItems: (item: TItem, filter: string) => boolean,
    itemCreationConfig?: AgTechSelectionControlItemCreationProps<TItem>
}

export type AgTechSelectionControlProps<TItem extends IAgTechSelectionPopUpItem> = {
    items: AgTechSelectionControlItemProps<TItem>,
    content: AgTechSelectionControlContentProps<TItem>,
}

export const useSelectionControlState = <TItem extends IAgTechSelectionPopUpItem>(props: AgTechSelectionControlProps<TItem>) => {
    let selectedItemId = props.items.getSelectedItemId();

    return useState<AgTechSelectionControlState<TItem>>({
        allItems: props.items.selectionItems,
        visibleSelectionItems: props.items.selectionItems,
        activeItem: selectedItemId ? props.items.selectionItems.find(item => item.id.toString() === selectedItemId) : undefined,
        activeItemListIndex: selectedItemId ? props.items.selectionItems.findIndex(item => item.id.toString() === selectedItemId) : -1
    });
}

/// Hooks

export type AgTechSelectionPopUpProps<TItem extends IAgTechSelectionPopUpItem> = {
    selectionRef: React.RefObject<HTMLInputElement>,
    selectionProps: AgTechSelectionControlProps<TItem>,
    onPopupOpened?: () => void,
    onPopupClosed?: () => void,
    onPopUpBlurred?: () => void
}

export const useAgTechSelectionPopUp = <TItem extends IAgTechSelectionPopUpItem>(props: AgTechSelectionPopUpProps<TItem>) => {
    let selectionPopUpRef = useRef<AgTechRenderedPopUp | null>(null);
    let selectionRefClickable = useClickable(props.selectionRef);

    useEffect(() => {
        if (props.selectionRef.current) {
            props.selectionRef.current.onfocus = () => handleSelectionPopUpFocus(props, selectionPopUpRef, selectionRefClickable);
            props.selectionRef.current.onblur = e => handleSelectionPopUpBlur(e, selectionPopUpRef, props.onPopUpBlurred);
        }
    });
}

const handleSelectionPopUpFocus = <TItem extends IAgTechSelectionPopUpItem>(
    props: AgTechSelectionPopUpProps<TItem>,
    selectionPopUpRef: MutableRefObject<AgTechRenderedPopUp | null>,
    selectionRefClickable: AgTechWebClickable
) => {
    if (selectionPopUpRef.current === null) {
        selectionPopUpRef.current = selectionRefClickable.popups.showPopUp({
            callerRef: props.selectionRef,
            popup: () => (
                <AgTechSelectionPopUp
                    selectionRef={props.selectionRef}
                    {...props.selectionProps}
                />
            ),
            config: {
                position: AgTechPopUpPosition.BelowRight,
                fadeInTime: '200ms',
                shouldCloseOnBlur: false
            },
            handlers: {
                onPopUpOpened: () => props.onPopupOpened ? props.onPopupOpened() : null,
                onPopUpClosed: () => {
                    selectionPopUpRef.current = null;

                    if (props.onPopupClosed) {
                        props.onPopupClosed();
                    }
                }
            }
        });
    }
}

const handleSelectionPopUpBlur = (e: any, selectionPopUpRef: MutableRefObject<AgTechRenderedPopUp | null>, onBlurHandler?: () => void) => {
    if (e.relatedTarget && selectionPopUpRef.current) {     
        if (e.relatedTarget.parentElement.id === 'selection-header') {
            return;
        }

        if (selectionPopUpRef.current && selectionPopUpRef.current.popupRef.current &&
            selectionPopUpRef.current.popupRef.current.contains(e.relatedTarget)) {
            return;
        }

        if (onBlurHandler) {
            onBlurHandler();
        }
        
        selectionPopUpRef.current.close();
        selectionPopUpRef.current = null;
    }
}