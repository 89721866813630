import { logDev } from 'agtech/core/logging/AgTechLogger';
import React, { useEffect, useState } from 'react'

export type AgTechEditableTextControlProps = {
    text: string,
    classes?: string,
    onUpdated: (value: string) => void
};

export const AgTechEditableTextControl = (props: AgTechEditableTextControlProps) => {

    let [text, setText] = useState(props.text);

    let handleTextControlBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        let value = e.currentTarget.innerText;

        // Check if the text has actually changed
        if (value !== text) {
            setText(value);
            props.onUpdated(value);
        }
    }

    return (
        <div className={`agtech-editable-text ${props.classes}`}
             contentEditable={true}
             onBlur={handleTextControlBlur}
             suppressContentEditableWarning={true}>
            {text}
        </div>
    );
};

export const AgTechEditableTextBoxControl = (props: AgTechEditableTextControlProps) => {

    let [text, setText] = useState(props.text);

    let handleTextControlBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        let value = e.currentTarget.value;

        // Check if the text has actually changed
        if (value !== text) {
            setText(value);
            props.onUpdated(value);
        }
    }

    return (
        <textarea
            className={`agtech-editable-text agtech-text-box ${props.classes}`}
            defaultValue={props.text}
            onBlur={handleTextControlBlur} />
    );
};

export type AgTechCheckBoxControlProps = {
    isChecked: boolean,
    onToggle: (isChecked: boolean) => void
}

export const AgTechCheckBoxControl = (props: AgTechCheckBoxControlProps) => { 
    let handleCheckBoxToggled = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedSelectionValue = !props.isChecked;
        logDev('Updated toggle: ' + updatedSelectionValue);
        
        props.onToggle(updatedSelectionValue);
    }

    return (
        <label className="agtech-checkbox">
            <input type="checkbox" onChange={handleCheckBoxToggled} checked={props.isChecked} />
            <span className="checkmark" />
        </label>
    )
}