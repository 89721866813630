import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import AutoSizer from "react-virtualized-auto-sizer";
import { AgTechGridEntity, initializeGridEntities, updateEntitiesOnItemsChanged } from "agtech/web/components/Grids/Data/AgTechGridEntities"
import AgTechGridHeader from "agtech/web/components/Grids/AgTechGridHeader"
import { FixedSizeList, ListChildComponentProps, VariableSizeList } from "react-window"
import AgTechGridRow from "agtech/web/components/Grids/AgTechGridRow"
import { AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps"
import { AgTechGridData, buildGridItemData, useGridItemData } from "agtech/web/components/Grids/Data/AgTechGridHooks"
import { AgTechGridState } from "agtech/web/components/Grids/Data/AgTechGridState"
import { AgTechGridGroupHeaderRow, AgTechGridItemGroup } from "agtech/web/components/Grids/Features/AgTechGridGrouping"
import { IAgTechGridItem } from "agtech/web/components/Grids/Data/AgTechGridData";
import { AgTechGridSearchState } from "agtech/web/components/Grids/Features/AgTechGridSearching";
import { useAgTechWebAppContext } from "agtech/web/components/App/AgTechWebAppContext";
import AgTechNoRecordsPage from "agtech/web/components/Pages/Error/AgTechNoRecordsPage";
import _ from "lodash";
import { useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechLoader } from "agtech/web/components/Pages/Loading/AgTechLoadingSurface";
import { useAgTechSafeState, useAgTechSafeStateFunc } from "agtech/core/utilities/AgTechUtilities";
import { AgTechGridView } from "agtech/web/components/Grids/Features/AgTechGridViews";
import { useUserGridConfiguration } from "agtech/web/configuration/AgTechWebConfiguration";

const GridHeaderHeightStandard = 32;
const GridHeaderHeightSmall = 28;

const GridRowHeightStandard = 38;
const GridRowHeightSmall = 30;

const AgTechGrid = <TItem extends IAgTechGridItem>(props: AgTechGridProps<TItem>) => {
    let appContext = useAgTechWebAppContext();
    let actionExecutor = useDataActionExecutor();
    let gridConfiguration = useUserGridConfiguration(props.name ?? '');

    let gridSurfaceRef = useRef<HTMLDivElement>(null);

    let [gridState, updateGridState] = useAgTechSafeStateFunc<AgTechGridState<TItem>>(() => {
        return {
            gridProps: props,
            gridSurfaceRef: gridSurfaceRef,
            entityState: initializeGridEntities(props),
            searchState: {
                filter: '',
                selectedField: {
                    field: '',
                    value: () => ''
                }
            },
            selectedView: props.viewConfig?.views && gridConfiguration?.selectedView
                ? props.viewConfig.views.find(view => view.name === gridConfiguration?.selectedView)
                : undefined
        };
    });

    useEffect(() => {
        updateEntitiesOnItemsChanged(
            props,
            gridState.current.entityState,
            entityState => {
                updateGridState({
                    ...gridState.current,
                    entityState: entityState
                });
            }
        )
    }, [props.items]);

    const handleGridSearchUpdated = (updatedSearchState: AgTechGridSearchState<TItem>) => {
        updateGridState({
            ...gridState.current,
            searchState: updatedSearchState
        });
    }

    const handleGridViewSelected = (selectedView: AgTechGridView<TItem>) => {
        updateGridState({
            ...gridState.current,
            selectedView: selectedView
        });
    }

    let gridItemData = useGridItemData({
        gridProps: props,
        gridState: gridState,
        updateGridState: updateGridState,
        appContext: appContext,
        actionExecutor: actionExecutor
    });

    let isGridEmpty =
        gridState.current.entityState.hasLoaded &&
        Object.keys(gridItemData.itemIndex).length === 0 &&
        (gridState.current.searchState?.filter ?? '') === '';

    return !gridState.current.entityState.hasLoaded ? (
        <AgTechLoader text='' />
    ) :
    isGridEmpty && props.noRecordsConfig ? (
        <AgTechNoRecordsPage {...props.noRecordsConfig} />
    ) : (
        <div className={`agtech-grid ${props.groupingConfig ? 'grouped' : ''}`} ref={gridSurfaceRef}>
            {props.header ? (
                <AgTechGridHeader
                    {...props}
                    gridState={gridState.current}
                    views={{
                        views: props.viewConfig,
                        onViewSelected: handleGridViewSelected
                    }}
                    search={{
                        searchConfig: props.searchConfig,
                        onSearchStateUpdated: handleGridSearchUpdated
                    }}
                    itemCount={Object.keys(gridItemData.itemIndex).length}
                />
            ) : null}
            <div className='agtech-grid-surface'>
                { isGridEmpty && props.noRecordsConfig ? (
                    <AgTechNoRecordsPage {...props.noRecordsConfig} />
                ) : (
                    <>
                        <AgTechGridTableHeader {...props} />
                        <AgTechGridTableBody gridProps={props} gridItemData={gridItemData} />
                    </>
                )}
            </div>
        </div>
    )
}

const AgTechGridTableHeader = <TItem extends IAgTechGridItem>(props: AgTechGridProps<TItem>) => {
    let gridHeaderOffset = props.groupingConfig?.offset ?? undefined;
    let gridHeaderHeight = props.sizingConfig?.size === 'small' ? GridHeaderHeightSmall : GridHeaderHeightStandard;

    return (
        <div className='agtech-grid-surface-header' style={{ paddingLeft: gridHeaderOffset, height: gridHeaderHeight }}>
            { props.selectionConfig ? <div className="agtech-grid-surface-action-column agtech-grid-surface-selection-column"></div> : null }
            { props.editConfig && (props.editConfig.showStatus ?? true) ? <div className="agtech-grid-surface-action-column"></div> : null }

            { props.columns.map(column => {
                let shouldShowColumnHeader = column.showHeader ?? true;
                let columnSizeStyle: any = column.size ? { flex: column.size } : { flex: 1 };
                
                if (column.width) {
                    columnSizeStyle = { width: column.width };
                }

                return (
                    <div className={`row-based-vertically-centered container`} style={columnSizeStyle} key={column.header}>
                        {shouldShowColumnHeader ? (
                            <h1 className={`stretched font-size-smaller font-bold px-3 container ${column.classes ?? ''}`}>{column.header}</h1>
                        ) : null}
                    </div>
                )
            })}

            { props.editConfig?.deleteItem ? <div className="agtech-grid-surface-action-column" style={{ width: '36px' }}></div> : null }
            { props.actions ? <div className="agtech-grid-surface-action-column" style={{ width: props.actions.width ?? 36 }}></div> : null }
        </div>
    )
}

declare type AgTechGridTableBodyProps<TItem extends IAgTechGridItem> = {
    gridProps: AgTechGridProps<TItem>,
    gridItemData: AgTechGridData<TItem>
}

const AgTechGridTableBody = <TItem extends IAgTechGridItem>(props: AgTechGridTableBodyProps<TItem>)  => {
    let gridHeaderHeight = props.gridProps.sizingConfig?.size === 'small' ? GridHeaderHeightSmall : GridHeaderHeightStandard;
    let gridRowHeight = props.gridProps.sizingConfig?.size === 'small' ? GridRowHeightSmall : GridRowHeightStandard;

    const gridMinHeight = props.gridProps.sizingConfig?.minRowsShown
        ? (gridRowHeight * Math.min(props.gridProps.sizingConfig?.minRowsShown, props.gridProps.items.length + 1)) + gridHeaderHeight + 2
        : undefined;

    return (
        <div className="stretched container" style={{ minHeight: gridMinHeight }}>
            <AutoSizer>
                {({ height, width }) => props.gridProps.groupingConfig ? (
                    <div
                        className="stretched non-scrollable bottom-bordered scrollbar-white bg-white container"
                        style={{
                            height: Math.min((gridRowHeight * Object.values(props.gridItemData.itemIndex).length + 1), height),
                            maxHeight: Math.min((gridRowHeight * Object.values(props.gridItemData.itemIndex).length + 1), height),
                            width: width
                        }}
                    >
                        <VariableSizeList
                            height={height - 36}
                            itemData={props.gridItemData}
                            itemCount={Object.keys(props.gridItemData.itemIndex).length}
                            itemSize={index => props.gridItemData.itemHeights[index]}
                            width={width}
                            className="agtech-grid-surface-body"
                        >
                            {AgTechInternalGridRow}
                        </VariableSizeList>
                    </div>
                ) : (
                    <div
                        className="stretched non-scrollable bottom-bordered scrollbar-white container"
                        style={{
                            height: Math.min((gridRowHeight * Object.values(props.gridItemData.itemIndex).length + 1), height),
                            maxHeight: Math.min((gridRowHeight * Object.values(props.gridItemData.itemIndex).length + 1), height),
                            width: width
                        }}
                    >
                        <FixedSizeList
                            height={height}
                            itemData={props.gridItemData}
                            itemCount={Object.keys(props.gridItemData.itemIndex).length}
                            itemSize={gridRowHeight}
                            width={width}
                            className="agtech-grid-surface-body"
                        >
                            {AgTechInternalGridRow}
                        </FixedSizeList>
                    </div>
                )}
            </AutoSizer>
        </div>
    )
}

// TODO: Memoize here
const AgTechInternalGridRow = <TItem extends IAgTechGridItem>(props: ListChildComponentProps<AgTechGridData<any>>) => {
    let gridRowEntityId = (props.data.itemIndex[props.index] as any).id;

    if (gridRowEntityId !== undefined) {
        let gridRowEntity = props.data.itemIndex[props.index] as AgTechGridEntity<TItem>;

        return (
            <AgTechGridRow
                entity={gridRowEntity}
                state={gridRowEntity.state}
                columns={props.data.columns}
                index={props.index}
                style={{
                    ...props.style,
                    paddingLeft: props.data.configuration.groupingConfig?.offset ?? undefined
                }}
                hasRowChanged={props.data.hasRowChanged}
                gridConfiguration={props.data.configuration}
                handlers={props.data.handlers}
                isFocused={props.data.state.entityState.focusedEntityId === gridRowEntity.id}
            />
        )
    }
    else {
        let gridRowGroupHeader = props.data.itemIndex[props.index] as AgTechGridItemGroup<TItem, any>;

        return (
            <AgTechGridGroupHeaderRow
                groupKey={gridRowGroupHeader.groupKey}
                groupText={gridRowGroupHeader.groupText}
                itemsInGroup={gridRowGroupHeader.itemsInGroup}
                header={gridRowGroupHeader.groupHeader}
                style={props.style}
            />
        )
    }
}

// export default React.memo(AgTechGrid, (prev, curr) => {
//     return prev.items.length === curr.items.length &&
//            curr.items.filter((item, index) => {

//            }).length > 0;
// }) as
// typeof AgTechGrid;

export default AgTechGrid;