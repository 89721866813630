import { AgTechFormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechActionButton } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import { AgTechEntitySelection } from 'agtech/web/components/Grids/AgTechGridEntities';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import ContactCreditActionMenu from 'app/areas/contacts/components/menus/ContactCreditActionMenu';
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog';
import { SaleInvoiceDialogFormData } from 'app/areas/invoices/dialogs/InvoiceCreationDialog';
import { useContact, getAvailableContactCredits } from 'app/data/operation/contacts/ContactData';
import { ContactCredit } from 'app/data/operation/contacts/credits/ContactDataCredits';
import React, { useEffect } from 'react'

export type InvoiceCreditSelectionGridProps = {
    formContext: AgTechFormContext<SaleInvoiceDialogFormData>
}

const InvoiceCreditsSelectionGrid = (props: InvoiceCreditSelectionGridProps) => {
    let invoiceSubmissionData = props.formContext.entity;

    let contact = useContact(invoiceSubmissionData.contactId);
    let credits = getAvailableContactCredits(contact);

    useEffect(() => {
        let updatedCredits = credits.map(d => invoiceSubmissionData.selectedCredits.filter(s => s.id === d.id)[0] ?? {
            id: d.id,
            entity: d,
            isSelected: false
        });

        if (updatedCredits.length !== invoiceSubmissionData.selectedCredits.length) {
            props.formContext.updateFormEntity(curr =>({
                ...curr,
                selectedCredits: [...updatedCredits]
            }));
        }
    }, [credits]);
    
    let gridConfiguration: AgTechGridProps<AgTechEntitySelection<ContactCredit>> = {
        items: invoiceSubmissionData.selectedCredits,
        columns: [
            {
                header: 'Description',
                value: c => (
                    <AgTechGridLink
                        content={c.entity.description}
                        onClick={e => e.dialogs.openDialog({
                            dialog: () => (
                                <ContactCreditDialog
                                    contactId={invoiceSubmissionData.contact.id}
                                    credit={c.entity}
                                />
                            )
                        })}
                    />
                ),
                size: 3
            },
            {
                header: 'Amount',
                value: c => AgTechMoneyFormatter.format(c.entity.creditAmount),
                size: 1
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.entity.description === curr.entity.description &&
                   prev.entity.creditAmount === curr.entity.creditAmount;
        },
        selectionConfig: {
            isItemSelected: c => c.isSelected,
            onItemSelected: async c => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    selectedCredits: [...curr.selectedCredits.map(cred => cred.id === c.entity.id
                        ? { ...cred, isSelected: true }
                        : { ...cred }
                    )]
                }));
            },
            onItemDeSelected: async c => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    selectedCredits: [...curr.selectedCredits.map(cred => cred.id === c.entity.id
                        ? { ...cred, isSelected: false }
                        : { ...cred }
                    )]
                }));
            }
        },
        noRecordsConfig: {
            message: `${invoiceSubmissionData.contact.firstName} ${invoiceSubmissionData.contact.lastName} has no credits in the system.`,
            action: {
                prompt: 'To create one, click the button below',
                button: () => (
                    <ContactCreditDialog.Button
                        contactId={invoiceSubmissionData.contact.id}
                        classes='highlight'
                    />
                )
            }
        },
        actions: {
            rendering: c => (
                <AgTechActionButton>
                    <ContactCreditActionMenu
                        contactId={invoiceSubmissionData.contact.id}
                        creditId={c.entity.id}
                    />
                </AgTechActionButton>
            )
        }
    };

    return <AgTechGrid {...gridConfiguration} />
};

export default InvoiceCreditsSelectionGrid;