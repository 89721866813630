// Invoicing Entities

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SalesAppState } from "app/data/SalesAppDataStore";
import { useSelector } from "react-redux";

export interface ISaleInvoice {
    id: number,
    invoiceNumber: string,
    checkNumber: string,
    date: Date
}

export type UnprocessedLotSale = {
    id: number;
    bidderNumber: string;
    bidderName: string;
    numberOfLots: number;
    totalPurchasePrice: number;
    contactId?: number;
}

export type SaleInvoice = ISaleInvoice & {
    id: number,
    saleId: number,
    invoiceNumber: string,
    checkNumber: string,
    invoiceTotal: number,
    timestamp: string,
    date: Date
}

export const DefaultSaleInvoice: SaleInvoice = {
    id: 0,
    saleId: 0,
    invoiceNumber: '',
    checkNumber: '',
    invoiceTotal: 0,
    timestamp: '',
    date: new Date()
}

export type ProcessedSaleInvoice = {
    id: number,
    invoiceNumber: string,
    invoiceTotal: number,
    numberOfLots: number,
    numberOfDiscountsApplied: number,
    numberOfCreditsApplied: number,
    purchaserName: string,
    invoiceDate: Date,
    contactId: number
}

export const PaymentTypes = [
    {description: 'Check', id: 0},
    {description: 'Cash', id: 1},
    {description: 'Card', id: 2},
    {description: 'Bank Wire', id: 3}
]

export type Payment = {
    id: number,
    amount: number,
    details: string,
    payerId: number,
    paymentType: number,
    invoiceId: number
}

export const DefaultPayment: Payment = {
    id: 0,
    amount: 0,
    payerId: 0,
    invoiceId: 0,
    details: '',
    paymentType: 0
}

/// Invoicing Stores

export type InvoicingData = {
    paymentsMade: number
}

const DefaultInvoicingData: InvoicingData = {
    paymentsMade: 0
}

export const invoicingDataSlice = createSlice({
    name: 'Invoicing',
    initialState: DefaultInvoicingData,
    reducers: {
        refreshInvoicingData: (state) => {
            state.paymentsMade++;
        }
    }
});

/// Invoicing Hooks

export const useInvoicingData = (): number => useSelector((state: SalesAppState) => state.invoicing).paymentsMade;

export default invoicingDataSlice.reducer;