import React from 'react'
import SaleLotSellingGrid from 'app/areas/lots/components/grids/SaleLotSellingGrid';
import AgTechMetric from 'agtech/web/components/Metrics/AgTechMetric';
import AgTechGap from 'agtech/web/components/MIsc/AgTechGap';
import AgTechLoadingSurface from 'agtech/web/components/Pages/Loading/AgTechLoadingSurface';
import { isLotInvoiced, useSaleLots } from 'app/data/lots/LotData';
import LotUpdateViewDialog from 'app/areas/lots/dialogs/LotUpdateViewDialog';
import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import LotDialog from 'app/areas/lots/dialogs/LotDialog';
import SaleLotImportModal from 'app/areas/lots/modals/SaleLotImportModal';
import LotManagementDialog from 'app/areas/lots/dialogs/LotManagementDialog';

const SaleLotOverviewPage = () => {
    let saleLots = useSaleLots();

    return (
        <AgTechLoadingSurface text='Loading sale lots...'>
            {saleLots.length > 0 ? (
                <div className='stretched column-based container'>
                    <div className='area-left flexed'>
                        <SaleLotSellingGrid lots={saleLots} />
                    </div>
                    <div className='tall row-based vertical-pane area-right w-240 container'>
                        <AgTechMetric
                            header='Lots In Sale'
                            value={saleLots.length.toString()}
                        />
                        <AgTechMetric
                            header='Lots Sold'
                            value={saleLots.filter(lot => lot.bidderNumber && lot.purchasePrice).length.toString()}
                        />
                        <AgTechMetric
                            header='Lots Invoiced'
                            value={saleLots.filter(lot => lot.bidderNumber && lot.purchasePrice && isLotInvoiced(lot)).length.toString()}
                        />
                        <LotManagementDialog.Button classes='block highlight mt-auto' />
                    </div>
                </div>
            ) : (
                <AgTechNoRecordsPage
                    message="We're sorry, there are no lots in the system for this sale."
                    action={{
                        prompt: 'To create one, click the button below',
                        button: () => <LotDialog.Button />
                    }}
                />
            )}
        </AgTechLoadingSurface>
    )
}

export default SaleLotOverviewPage;