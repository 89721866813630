import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlMetadata, AgTechFormControlProps } from 'agtech/core/forms/AgTechFormControls';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'

export type AgTechFormTextAreaInputProps<TFormEntity> = AgTechFormControlProps<TFormEntity, string> & {
    rows?: number,
    height?: string,
    resizable?: boolean,
    styles?: CSSProperties
};

export const AgTechFormTextAreaControl = <TEntity, >(props: AgTechFormTextAreaInputProps<TEntity>) => {
    let formContext = useContext(FormContext);
    let formControlWidth: string = `${props.width ?? 50}%`;
    let inputRef = useRef<HTMLTextAreaElement>(null);

    let inputValue = props.value(formContext.entity);

    let [textValue, updateTextValue] = useState(inputValue);

    let [field, updateField] = useState<AgTechFormControlMetadata<TEntity>>({
        id: props.caption,
        isValid: true,
        validateOnSubmit: (entity: TEntity) => validateControlOnFormSubmit(entity),
    });

    let markFieldValid = () => updateField({...field, isValid: true });
    let markFieldInvalid = () => updateField({...field, isValid: false });

    let handleTextChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateTextValue(e.currentTarget.value);
    }

    let validateControlOnFormSubmit = (entity: TEntity): string => {
        let fieldValidationWarningMessage = '';
        let controlValue = props.value(entity);

        if (props.required && controlValue === '') {
            fieldValidationWarningMessage = 'Field "' + props.caption + '" is required';
        }
        else if (props.validation?.validateOnSubmit) {
            fieldValidationWarningMessage = props.validation.validateOnSubmit(formContext.entity, controlValue) ?? '';
        }

        if (fieldValidationWarningMessage) {
            markFieldInvalid();
        }

        return fieldValidationWarningMessage;
    }

    useEffect(() => {
        formContext.registerFormField(field);

        if (props.focused) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, []);

    useEffect(() => {
        if (props.readonly) {
            updateTextValue(inputValue);
        }
    }, [inputValue]);

    let handleFocusOut = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        if (props.onControlValueChanged) {
            let updatedValue = e.currentTarget.value;

            formContext.handleFieldChanged(updatedValue, props.onControlValueChanged);

            if (props.validation?.validateOnFocusOut && !props.validation.validateOnFocusOut(updatedValue)) {
                markFieldInvalid();
            }
            else if (props.required && updatedValue === '') {
                if (field.isValid) {
                    markFieldInvalid();
                }
            }
            else if (!field.isValid) {
                markFieldValid();
            }
        }
    }

    let formControlStatus = field.isValid ? '' : 'invalid';

    return (
        <div className={`agtech-form-control tall ${formControlStatus}`} style={{maxWidth: formControlWidth}}>
            <h1 className="agtech-form-control-caption">{props.caption + (props.required ? ' *' : '')}</h1>
            <textarea
                value={textValue}
                rows={props.rows ?? 3}
                onChange={handleTextChanged}
                onBlur={handleFocusOut}
                readOnly={props.readonly}
                style={{
                    height: props.height ?? '',
                    resize: (props.resizable ?? true) ? undefined : 'none',
                    ...props.styles
                }}
            />
        </div>
    );
};