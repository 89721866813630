import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SalesAppState } from "app/data/SalesAppDataStore"
import { useSelector } from "react-redux"

/// Lot Category Entities

export enum SaleLotCategoryColors {
    None = "Gray",
    Blue = "Blue",
    Red = "Red",
    Yellow = "Yellow",
    Green = "Green"
}

export type SaleLotCategory = {
    id: number,
    name: string,
    color: string,
    description: string
}

export const DefaultSaleLotCategory: SaleLotCategory = {
    id: 0,
    name: '',
    color: SaleLotCategoryColors.None,
    description: ''
}

export type SaleDataLotCategories = {
    categories: SaleLotCategoryIndex
}

export type SaleLotCategoryIndex = {
    [lotCategoryId: number]: SaleLotCategory
}

/// Lot Category Slices

const DefaultSaleDataLotCategories: SaleDataLotCategories = {
    categories: {}
}

export const saleDataLotCategoriesSlice = createSlice({
    name: 'LotCategories',
    initialState: DefaultSaleDataLotCategories,
    reducers: {
        refreshLotCategories: (state, action: PayloadAction<SaleLotCategory[]>) => {
            state.categories = {};

            action.payload.forEach(category => {
                state.categories[category.id] = {...category};
            });
        },
        writeLotCategory: (state, action: PayloadAction<SaleLotCategory>) => {
            state.categories[action.payload.id] = { ...action.payload };
        },
        deleteLotCategory: (state, action: PayloadAction<SaleLotCategory>) => {
            delete state.categories[action.payload.id];
        }
    }
});

/// Lot Category Hooks

export const useLotCategoryData = (): SaleLotCategoryIndex => {
    return useSelector((state: SalesAppState) => state.lotCategories).categories;
}

export const useLotCategories = (): SaleLotCategory[] => Object.values(useLotCategoryData());

export default saleDataLotCategoriesSlice.reducer;