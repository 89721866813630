import { AgTechDataAction, DataActionResults, useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechApiEntityResponse, AgTechHttpRequest, convertFromEntityResponse, convertHttpResponse } from "agtech/core/data/actions/AgTechHttpRequests";
import { useAgTechGetRequest } from "agtech/core/data/http/AgTechApiGetRequests";
import { useAgTechHttpPostRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { useAgTechApiPutAction, useAgTechApiPutRequest } from "agtech/core/data/http/AgTechApiPutRequests";
import { logEvent } from "agtech/core/logging/AgTechLogger";
import { getBidderByBidderNumber, getCurrentSale, getLot, getLots } from "app/data/SalesAppDataStore";
import { SaleBidder, getContactIdByBidderNumber } from "app/data/bidders/BidderData";
import { SaleLot, saleLotDataSlice, useSaleLotData } from "app/data/lots/LotData";
import { LotBidderPurchase } from "app/data/lots/purchases/LotPurchaseData";
import { useCurrentSale } from "app/data/sales/SaleData";
import { SaleLotCategory, useLotCategoryData } from "../categories/LotCategoryData";
import AgTechDataProvider from "agtech/core/data/providers/AgTechDataProvider";

/// DTOs
export type LotPurchaseData = {
    lotId: number,
    lotTimestamp: string,
    bidderNumber: string,
    bidderId?: number,
    purchasePrice: number
}

export type CreateLotPurchaseRequestDTO = {
    lotId: number,
    purchasePrice: number,
    bidderNumber: string,
    bidderId?: number,
    lotTimestamp: string
}

export type UpdateLotPurchaseRequestDTO = {
    newBidderId?: number,
    newPurchasePrice: number,
    lotTimestamp: string
}

export type LotPurchaseUpdateDTO = {
    saleId: number,
    bidderId?: number,
    lotId: number,
    bidderNumber: string,
    originalBidderNumber: string,
    purchasePrice: number
}

export type UpdateLotResponseDTO = {
    timestamp: string
}

export type LotPurchaseDeleteDTO = {
    lotId: number,
    bidderNumber: string
}

/// Requests

type BidderLotPurchaseDTO = {
    lotId: number,
    percentage: number
}

export const useLotDataGetLotsToInvoiceForBidderRequest = (): AgTechHttpRequest<{ bidderId: number}, BidderLotPurchaseDTO[]> => {
    let getLotsRequest = useAgTechGetRequest<AgTechApiEntityResponse<BidderLotPurchaseDTO[]>>();

    return {
        execute: async props => {
            return getLotsRequest.get({
                path: 'Lot/GetForInvoicingByBidder/' + props.data.bidderId,
            })
            .then(res => convertHttpResponse(res))
        }
    }
}

/// Actions

export const useLotDataWriteLotPurchasesAction = (): AgTechDataAction<{ purchases: LotBidderPurchase[] }, any> => {
    return {
        name: 'WriteLotBidderPurchases',
        getConfiguration: () => ({
            actionExecutionMessage: 'Submitting lot purchases...',
            actionConfirmationMessage: `Successfully submitted lot purchases`
        }),
        action: async props => {
            return DataActionResults.Success('');
        }
    }
}

export const useLotDataWriteLotPurchaseAction = (): AgTechDataAction<SaleLot, SaleLot> => {
    let actionExecutor = useDataActionExecutor();

    let createLotPurchaseAction = useLotDataCreateLotPurchaseAction();
    let updateLotPurchaseAction = useLotDataUpdateLotPurchaseAction();
    let deleteLotPurchaseAction = useLotDataDeleteLotPurchaseAction();

    return {
        name: 'WriteLotBidderPurchase',
        getConfiguration: () => ({
            actionExecutionMessage: 'Updating lot purchases...',
            actionConfirmationMessage: `Successfully updated lot purchases`,
            shouldLogExecution: false
        }),
        confirm: async props => {
            let test = props.originalEntity?.bidderNumber !== props.submittedEntity.bidderNumber ||
                   props.originalEntity?.purchasePrice !== props.submittedEntity.purchasePrice;

            return test;
        },
        action: async props => {
            let wasOriginalLotSold = props.originalEntity?.bidderNumber !== ''
                && props.originalEntity?.purchasePrice
                && props.originalEntity?.purchasePrice >= 0;
            
            let isUpdatedLotSold = props.submittedEntity.bidderNumber
                && props.submittedEntity.purchasePrice
                && props.submittedEntity.purchasePrice > 0;

            if (!wasOriginalLotSold && isUpdatedLotSold) {
                let lotSellingResult = await actionExecutor.executeAction(createLotPurchaseAction, {
                    submittedEntity: {
                        lotId: props.submittedEntity.id,
                        bidderNumber: props.submittedEntity.bidderNumber ?? '',
                        purchasePrice: props.submittedEntity.purchasePrice ?? 0,
                        lotTimestamp: props.submittedEntity.timestamp
                    }
                });

                return lotSellingResult.success
                    ? { ...lotSellingResult, success: { data: props.submittedEntity }}
                    : lotSellingResult;
            }
            else if (wasOriginalLotSold && !isUpdatedLotSold) {
                let lotSaleDeleteResult = await actionExecutor.executeAction(deleteLotPurchaseAction, {
                    submittedEntity: {
                        lotId: props.submittedEntity.id,
                        bidderNumber: props.originalEntity?.bidderNumber ?? ''
                    }
                });

                return lotSaleDeleteResult.success
                    ? { ...lotSaleDeleteResult, success: { data: {
                        ...props.submittedEntity,
                        bidderNumber: undefined,
                        purchasePrice: undefined
                    }}}
                    : lotSaleDeleteResult;
            }
            else {
                let updateLotBidderId = props.submittedEntity.bidderNumber
                    ? getBidderByBidderNumber(props.submittedEntity.bidderNumber).id
                    : undefined;

                let lotSaleUpdateResult = await actionExecutor.executeAction(updateLotPurchaseAction, {
                    originalEntity: {
                        lotId: props.originalEntity?.id ?? 0,
                        lotTimestamp: props.originalEntity?.timestamp ?? '',
                        bidderNumber: props.originalEntity?.bidderNumber ?? '',
                        purchasePrice: props.originalEntity?.purchasePrice ?? 0,
                    },
                    submittedEntity: {
                        lotId: props.submittedEntity.id,
                        lotTimestamp: props.submittedEntity.timestamp,
                        bidderNumber: props.submittedEntity.bidderNumber ?? '',
                        purchasePrice: props.submittedEntity.purchasePrice ?? 0,
                        bidderId: updateLotBidderId
                    }
                });

                return lotSaleUpdateResult.success
                    ? { ...lotSaleUpdateResult, success: { data: props.submittedEntity }}
                    : lotSaleUpdateResult;
            }
        }
    }
}

const useLotDataCreateLotPurchaseAction = (): AgTechDataAction<LotPurchaseData, any> => {
    let createLotPurchasePostRequest = useAgTechHttpPostRequest<CreateLotPurchaseRequestDTO, AgTechApiEntityResponse<CreateLotPurchaseRequestDTO>>();

    return {
        name: 'CreateLotBidderPurchase',
        getConfiguration: () => ({
            actionExecutionMessage: 'Creating lot purchase...',
            actionConfirmationMessage: `Successfully created lot purchase`
        }),
        validate: async props => {
            props.validation.failWithWarningIf(
                props.submittedEntity.bidderNumber === '',
                'Unable to create a lot purchase. The purchasing bidder has not been specified.'
            );
        },
        action: async props => {
            let lot = getLot(props.submittedEntity.lotId);
            
            return await createLotPurchasePostRequest.post({
                path: 'Lot/Sell/' + getCurrentSale().id,
                postedData: {
                    lotId: props.submittedEntity.lotId,
                    lotTimestamp: lot.timestamp,
                    purchasePrice: props.submittedEntity.purchasePrice ?? 0,
                    bidderNumber: props.submittedEntity.bidderNumber ?? '',
                    bidderId: props.submittedEntity.bidderId,
                }
            })
            .then(res => convertFromEntityResponse(res));
        },
        onSuccess: async props => {
            props.executeReducerAction(saleLotDataSlice.actions.writeLotPurchase, {
                bidderNumber: props.submittedEntity.bidderNumber,
                lotId: props.submittedEntity.lotId,
                percentage: 100,
                purchasePrice: props.submittedEntity.purchasePrice,
                isInvoiced: false,
                lotTimestamp: props.responseData.timestamp
            });
        }
    }
}

const useLotDataUpdateLotPurchaseAction = (): AgTechDataAction<LotPurchaseData, UpdateLotResponseDTO> => {
    let updateLotPurchaseRequest = useAgTechHttpPostRequest<UpdateLotPurchaseRequestDTO, AgTechApiEntityResponse<UpdateLotResponseDTO>>();

    return {
        name: 'UpdateLotBidderPurchase',
        getConfiguration: () => ({
            actionExecutionMessage: 'Creating lot purchase...',
            actionConfirmationMessage: `Successfully created lot purchase`
        }),
        validate: async props => {
            props.validation.failWithWarningIf(
                !props.submittedEntity.bidderNumber,
                'Please specify a bidder number in order to mark this lot as purchased'
            );

            props.validation.failWithWarningIf(
                !props.submittedEntity.purchasePrice,
                'Please specify a purchase price greater than $0 in order to mark this lot as purchased'
            );
        },
        confirm: async props => {
            return props.originalEntity?.bidderNumber !== props.submittedEntity.bidderNumber ||
                   props.originalEntity?.purchasePrice !== props.submittedEntity.purchasePrice;
        },
        action: async props => {            
            return await updateLotPurchaseRequest.post({
                path: 'Lot/UpdatePurchase/' + props.submittedEntity.lotId,
                postedData: {
                    newBidderId: props.submittedEntity.bidderId,
                    newPurchasePrice: props.submittedEntity.purchasePrice,
                    lotTimestamp: props.submittedEntity.lotTimestamp
                }
            })
            .then(res => convertFromEntityResponse(res));
        },
        onSuccess: async props => {
            props.executeReducerAction(saleLotDataSlice.actions.deleteLotPurchase, {
                lotId: props.submittedEntity.lotId,
                lotTimestamp: props.responseData.timestamp
            });

            props.executeReducerAction(saleLotDataSlice.actions.writeLotPurchase, {
                bidderNumber: props.submittedEntity.bidderNumber,
                lotId: props.submittedEntity.lotId,
                percentage: 100,
                purchasePrice: props.submittedEntity.purchasePrice,
                isInvoiced: false,
                lotTimestamp: props.responseData.timestamp
            });
        }
    }
}

const useLotDataDeleteLotPurchaseAction = (): AgTechDataAction<{ lotId: number, bidderNumber: string }, UpdateLotResponseDTO> => {
    let deleteLotPurchaseRequest = useAgTechHttpPostRequest<{ lotId: number }, AgTechApiEntityResponse<UpdateLotResponseDTO>>();

    return {
        name: 'DeleteLotPurchase',
        getConfiguration: () => ({
            actionExecutionMessage: 'Deleting lot purhcase...',
            actionConfirmationMessage: `Successfully deleted lot purchase`
        }),
        confirm: async props => {
            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: "Sale information for this lot has been removed and this lot will no longer be marked as sold",
                    details: "Do you want to continue?",
                }
            });
        },
        action: async props => {
            return await deleteLotPurchaseRequest.post({
                path: 'Lot/DeletePurchase/' + props.submittedEntity.lotId,
                postedData: {
                    lotId: props.submittedEntity?.lotId ?? 0,
                }
            })
            .then(res => convertFromEntityResponse(res));
        },
        onSuccess: async props => {
            props.executeReducerAction(saleLotDataSlice.actions.deleteLotPurchase, {
                lotId: props.submittedEntity.lotId,
                lotTimestamp: props.responseData.timestamp
            });
        },
        onFailure: async props => {
            props.appContext.messaging.showError({
                header: 'An error occurred while deleting the lot purchase.',
                details: 'Please try again'
            })
        }
    }
}