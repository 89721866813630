import React, { CSSProperties } from 'react'

export type AgTechFieldProps = {
    name: string,
    value?: string,
    classes?: string,
    styles?: CSSProperties
}

const AgTechField = (props: AgTechFieldProps) => {
  return props.value ? (
    <div className={`row-based container ${props.classes ?? ''}`} style={props.styles}>
        <h1 className='font-bold mb-1 font-color-secondary font-size-smaller'>{props.name}</h1>
        <h1 className='font-size-small font-bold'>{props.value}</h1>
    </div>
  ) : null;
}

export default AgTechField