export type AgTechMessage = {
    header: string,
    details?: string,
    content?: () => React.ReactNode
}

export interface IAgTechAppMessaging {
    showMessage: (message: AgTechMessage) => void,
    showWarning: (warning: AgTechMessage | string) => boolean,
    showSuccess: (success: AgTechMessage) => boolean,
    showError: (error: AgTechMessage) => boolean,
}

export const DefaultAgTechAppMessaging: IAgTechAppMessaging = {
    showMessage: message => {
        console.error('Message shown by default handler: ' + message);
    },
    showWarning: message => {
        console.error('Message shown by default handler: ' + message);
        return false;
    },
    showSuccess: message => {
        console.error('Message shown by default handler: ' + message);
        return true;
    },
    showError: message => {
        console.error('Message shown by default handler: ' + message);
        return false;
    }
}

export const isAgTechMessage = (message: string | AgTechMessage): message is AgTechMessage => {
    return (message as AgTechMessage).header !== undefined;
}