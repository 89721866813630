
/// Contact Entities

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { executeReducerAction } from "agtech/core/data/actions/AgTechDataActions"
import { AgTechHttpResponse } from "agtech/core/data/actions/AgTechHttpRequests"
import { deleteFromArray, writeToArray } from "agtech/core/data/common/AgTechDataHelpers"
import { logDevError } from "agtech/core/logging/AgTechLogger"
import { SalesAppState, getSaleState } from "app/data/SalesAppDataStore"
import { ContactCredit } from "app/data/operation/contacts/credits/ContactDataCredits"
import { ContactNote } from "app/data/operation/contacts/notes/ContactDataNotes"
import React, { useContext } from "react"
import { useSelector } from "react-redux"

export type AgTechContact = {
    id: number,
    ranchName: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    emailAddress: string,
    address: string,
    zip: string,
    city: string,
    stateAbbreviation: string,
    notes: ContactNote[],
    credits: ContactCredit[],
    operationId: number
}

export const DefaultAgTechContact: AgTechContact = {
    id: 0,
    ranchName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    address: '',
    zip: '',
    city: '',
    stateAbbreviation: '',
    notes: [],
    credits: [],
    operationId: 0
}

export type ContactIndex = {
    [id: number]: AgTechContact
}

export type ContactDetails = AgTechContact & {
    notes: ContactNote[],
    credits: ContactCredit[]
}

/// Contact Data Slices

export type ContactDataState = {
    contacts: ContactIndex
};

const DefaultContactDataState: ContactDataState = {
    contacts: {}
};

const execute = (func: () => void) => {
    try
    {
        func();
    }
    catch (e) {
        logDevError(e);
    }
}

export const contactDataSlice = createSlice({
    name: 'contacts',
    initialState: DefaultContactDataState,
    reducers: {
        refreshContacts: (state, action: PayloadAction<AgTechContact[]>) => {
            state.contacts = {}
            action.payload.map(cnt => {
                state.contacts[cnt.id] = { ...cnt };
                
                // Ensure that all note dates are populated as deserialization won't instantiate them
                state.contacts[cnt.id].notes.forEach(note => {
                    note.date = new Date(note.date);
                });
            });
        },
        importContacts: (state, action: PayloadAction<AgTechContact[]>) => executeReducerAction(() => {
            action.payload.forEach(importedContact => {
                state.contacts[importedContact.id] = { ...importedContact };
            });
        }),
        writeContact: (state, action: PayloadAction<AgTechContact>) => {
            state.contacts[action.payload.id] = action.payload;
        },
        deleteContact: (state, action: PayloadAction<AgTechContact>) => {
            delete state.contacts[action.payload.id];
        },
        writeNote: (state, action: PayloadAction<ContactNote>) => {
            writeToArray(state.contacts[action.payload.contactId].notes, {
                ...action.payload,
                date: new Date(action.payload.date)
            });
        },
        deleteNote: (state, action: PayloadAction<ContactNote>) => {
            let contact = state.contacts[action.payload.contactId];
            contact.notes = contact.notes.filter(nt => nt.id !== action.payload.id);
        },
        writeCredit: (state, action: PayloadAction<ContactCredit>) => {
            writeToArray(state.contacts[action.payload.contactId].credits, action.payload);
        },
        deleteCredit: (state, action: PayloadAction<ContactCredit>) => {
            let contact = state.contacts[action.payload.contactId];
            contact.credits = contact.credits.filter(crd => crd.id !== action.payload.id);
        }
    }
});

/// Contact Contexts

export type AgTechContactDetailsContext = {
    contactDetails: ContactDetails,
    updateContactDetails: (contactDetails: ContactDetails) => void
}

export const ContactDetailsContext = React.createContext<AgTechContactDetailsContext>({
    contactDetails: {
        ...DefaultAgTechContact,
        notes: [],
        credits: []
    },
    updateContactDetails: details => {}
});

export const useContactDetailContext = () => useContext(ContactDetailsContext);

export const useContactDetailsContextHandler = () => {
    let contactDetailContext = useContext(ContactDetailsContext);

    return {
        updateContactDetails: <TEntity, >(response: AgTechHttpResponse<TEntity>, updateContact: (contactDetails: ContactDetails) => ContactDetails) => {
            if (contactDetailContext && response.success) {
                contactDetailContext.updateContactDetails({
                    ...updateContact(contactDetailContext.contactDetails)
                });
            }
        }
    }
}

/// Contact Getters

export const getContact = (contactId: number): AgTechContact => getSaleState().contacts.contacts[contactId];

export const getContactFullName = (contact?: AgTechContact) => contact ? contact.firstName + ' ' + contact.lastName : 'No Name Available';

/// Contact Hooks

export const useContactData = (): ContactDataState => useSelector((state: SalesAppState) => state.contacts);

export const useContacts = (): AgTechContact[] => {
    let contactData = useContactData();
    return Object.values(contactData.contacts);
}

export const useContact = (contactId: number): AgTechContact => useContactData().contacts[contactId];

export const useContactFullName = (contact: AgTechContact) => contact.firstName + ' ' + contact.lastName;

export const getContactNotes = (contact: AgTechContact) => Object.values(contact.notes); 
export const useContactNotes = (contactId: number) => Object.values(useContact(contactId).notes);
export const useContactNote = (contactId: number, noteId: number) => useContact(contactId).notes.filter(nt => nt.id === noteId)[0];

export const useContactCredit = (contactId: number, creditId: number) => useContact(contactId).credits.filter(c => c.id === creditId)[0];

export const useAvailableContactCredits = (contactId: number) => getAvailableContactCredits(useContact(contactId));

export const getAvailableContactCredits = (contact: AgTechContact) => {
    let credits = Object.values(contact.credits);
    let creditsNotInvoiced = credits.filter(cred => !cred.invoiceId)
    let availableCredits = creditsNotInvoiced.filter(cred => !cred.expires || (cred.expires > new Date()));

    console.log(availableCredits);

    return availableCredits;
}

export const getInvoicedContactCredits = (contact: AgTechContact) => Object.values(contact.credits)
    .filter(cred => cred.invoiceId !== undefined);

export const getExpiredContactCredits = (contact: AgTechContact) => Object.values(contact.credits)
    .filter(cred => !cred.invoiceId)
    .filter(cred => cred.expires && cred.expires < new Date())

export const useContactCreditsNotInvoiced = (contact: AgTechContact) => Object.values(contact.credits).filter(c => !c.invoiceId);

export default contactDataSlice.reducer;