import React from 'react'

let AgTechHorizontalDivider = () => {
    return (
        <div className="agtech-horizontal-divider">

        </div>
    )
}

export default AgTechHorizontalDivider;