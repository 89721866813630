import { AgTechTabbedPageTabProps } from 'agtech/web/components/Pages/Tabbed/AgTechTabPage'
import React, { CSSProperties, useEffect, useState } from 'react'

export type AgTechLeftPaneTabPageProps = {
    tabGroups: AgTechLeftPaneTabPageTabGroup[],
    classes?: string,
    styles?: CSSProperties
}

export type AgTechLeftPaneTabPageTabGroup = {
    header: string,
    tabs: AgTechTabbedPageTabProps[]
}

const AgTechLeftPaneTabPage = (props: AgTechLeftPaneTabPageProps) => {
    let [selectedTab, setSelectedTab] = useState(props.tabGroups[0].tabs[0]);

    let allTabs = props.tabGroups.map(grp => grp.tabs).reduce((a, b) => a.concat(b));

    useEffect(() => {

        // The tabs have changed for this tab page, but we should attempt to stay on the
        // currently selected tab if the new tabs also contain a tab wiht the same text
        let matchingTabs = allTabs.filter(tab => tab.text === selectedTab.text);

        if (matchingTabs.length === 1) {
            let matchingTab = matchingTabs[0];
            setSelectedTab(matchingTab);
        }
        else {
            setSelectedTab(allTabs[0]);
        }

    }, [props.tabGroups])
    
    return (
        <div className='column-based stretched container'>
            <div className='row-based bg-white w-240 tall right-bordered pr-4 py-4 container'>
                {props.tabGroups.length === 1 ? (
                    <>
                        {props.tabGroups[0].tabs.map(tab => {
                            let activeTabClass = tab.text === selectedTab.text ? 'bg-highlight font-color-white' : '';

                            return (
                                <div key={tab.text} className={`clickable wide rounded-right ml-sub-1 pl-5 py-2 mb-3 ${activeTabClass}`} onClick={() => setSelectedTab(tab)}>
                                    <h1 className='font-size-small font-bold'>{tab.text}</h1>
                                </div>
                            )
                        })}
                    </>
                ) : (
                    <>
                        {props.tabGroups.map(grp => {
                            return (
                                <div key={grp.header} className='row-based mb-3 container'>
                                    <h1 className='font-size-small font-bold font-color-secondary pl-3 pb-3'>{grp.header}</h1>
                                    {grp.tabs.map(tab => {
                                        let activeTabClass = tab.text === selectedTab.text ? 'bg-highlight font-color-white' : '';

                                        return (
                                            <div key={tab.text} className={`clickable wide rounded-right ml-sub-1 pl-6 py-2-half mb-1 ${activeTabClass}`} onClick={() => setSelectedTab(tab)}>
                                                <h1 className='font-size-standard font-bold'>{tab.text}</h1>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </>
                )}
                
            </div>
            <div className='row-based flexed p-6 container'>
                <AgTechLeftPaneTabPageBody currentTab={selectedTab} />
            </div>
        </div>
    )
}

declare type AgTechLeftPaneTabPageBodyProps = {
    currentTab: AgTechTabbedPageTabProps
}

let AgTechLeftPaneTabPageBody = (props: AgTechLeftPaneTabPageBodyProps) => {
    let [currentTabName, setCurrentTabName] = useState(props.currentTab?.text);

    useEffect(() => {
        setCurrentTabName(props.currentTab.text);
    }, [props.currentTab.text]);

    return (
        <div className="stretched container">
            {currentTabName === props.currentTab.text ? (
                <div className='stretched fade-in-400 container'>
                    {props.currentTab.content()}
                </div>
            ) : (
                <div className='stretched centered container'>
                    <div className='loader' />
                </div>
            )}
        </div>
    );
}

export default AgTechLeftPaneTabPage