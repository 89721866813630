import { useAgTechDataActionProps } from "agtech/core/data/AgTechDataAccess"
import { DataActionResults, AgTechDataAction, useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions"
import { useAgTechHttpPostEntityRequest, useAgTechHttpPostRequest, useAgTechHttpSubmitEntityRequest } from "agtech/core/data/http/AgTechApiPostRequests"
import { getLots, getCurrentSale } from "app/data/SalesAppDataStore"
import { SaleLot, LotIndex, saleLotDataSlice, useSaleLotData, isLotInvoiced, ReorderLotSaleOrderDTO } from "app/data/lots/LotData"
import { useDispatch } from "react-redux"
import { useLotDataWriteLotPurchaseAction } from "app/data/lots/purchases/LotPurchaseDataActions"
import { getBidderNumbersMappedToContacts } from "app/data/bidders/BidderData"
import _ from "lodash"
import { AgTechApiEntityResponse, AgTechHttpRequest, convertHttpResponse } from "agtech/core/data/actions/AgTechHttpRequests"
import { useAgTechGetDtoRequest, useAgTechGetRequest } from "agtech/core/data/http/AgTechApiGetRequests"
import AgTechDataProvider from "agtech/core/data/providers/AgTechDataProvider"
import { SaleLotCategory, useLotCategoryData } from "./categories/LotCategoryData"
import { SaleLotOrderModificationGridEntity } from "app/areas/lots/components/grids/SaleOrderModificationGrid"

/// Lot Requests

export type LotSplit = {
    contactId: number,
    bidderId?: number,
    percentage: number,
}

export type LotSplitSummary = {
    lotId: number,
    splits: LotSplit[]
}

type LotSplitDTO = {
    bidderId?: number,
    contactId: number,
    percentage: number
}

export const useLotDataGetLotSplitSummaryRequest = (): AgTechHttpRequest<{ lotId: number }, LotSplitSummary> => {
    let getLotSplitSummaryRequest = useAgTechGetDtoRequest<AgTechApiEntityResponse<LotSplitDTO[]>, LotSplitSummary>();

    return {
        execute: async props => {
            return getLotSplitSummaryRequest.getDtoAndConvert({
                path: 'Lot/Splits/' + props.data.lotId,
                convert: result => ({
                    lotId: props.data.lotId,
                    splits: result.data.map(result => ({ 
                        contactId: result.contactId,
                        bidderId: result.bidderId,
                        percentage: result.percentage
                    }))
                })
            });
        }
    }
}

/// Lot Actions

export const useLotDataWriteLotAction = (): AgTechDataAction<SaleLot, SaleLot> => {
    let actionExecutor = useDataActionExecutor();

    let lotSubmissionPostRequest = useAgTechHttpSubmitEntityRequest<SaleLot>();
    let lotPurchaseUpdateAction = useLotDataWriteLotPurchaseAction();

    return {
        name: 'WriteLot',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity?.id ? 'Updating lot...' : 'Creating lot...',
            actionConfirmationMessage: `Successfully ${props.originalEntity?.id ? 'updated' : 'created'} lot`
        }),
        validate: async props => {
            props.validation.failWithWarningIf(props.submittedEntity.lotNumber === '', 'Please specify a lot number for this lot.');

            let lotWithSameLotNumber = getLots().find(lot => {
                return lot.lotNumber === props.submittedEntity.lotNumber &&
                       lot.id !== props.submittedEntity.id
            });

            props.validation.failWithWarningIf(
                lotWithSameLotNumber !== undefined,
                'A lot with lot number ' + props.submittedEntity.lotNumber + ' already exists. Please specify a different lot number.'
            );

            let wasLotPreviouslyPurchased = props.originalEntity?.bidderNumber && props.originalEntity?.purchasePrice;

            let doesLotSpecifyBidderNumber = props.submittedEntity.bidderNumber !== undefined &&
                props.submittedEntity.bidderNumber !== '';

            let doesLotSpecifyPurchasePrice = props.submittedEntity.purchasePrice !== undefined &&
                props.submittedEntity.purchasePrice > 0;

            props.validation.failWithWarningIf(
                !wasLotPreviouslyPurchased && doesLotSpecifyPurchasePrice && !doesLotSpecifyBidderNumber,
                'Please specify a bidder number for this sale',
                () => props.originalEntity ?? props.submittedEntity
            );

            props.validation.failWithWarningIf(
                !wasLotPreviouslyPurchased && doesLotSpecifyBidderNumber && !doesLotSpecifyPurchasePrice,
                'Please specify a valid purchase price',
                () => props.originalEntity ?? props.submittedEntity
            );
        },
        action: async props => {
            if (props.submittedEntity.groupSize === 0)
                props.submittedEntity.groupSize = undefined;
                
            return await lotSubmissionPostRequest.post({
                path: 'Lot',
                postedData: {
                    ...props.submittedEntity,
                    saleId: props.submittedEntity.saleId !== 0 ? props.submittedEntity.saleId : getCurrentSale().id
                }
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleLotDataSlice.actions.writeLot, {
                ...props.responseData,
                bidderNumber: props.submittedEntity.bidderNumber,
                purchasePrice: props.submittedEntity.purchasePrice
            });

            await actionExecutor.executeAction(lotPurchaseUpdateAction, {
                originalEntity: props.originalEntity,
                submittedEntity: {
                    ...props.submittedEntity,
                    id: props.responseData.id
                },
                actionLoggingContext: props.actionLoggingContext
            });
        }
    }
}

export const useLotDataRemoveLotAction = (): AgTechDataAction<SaleLot, any> => {
    let lotDeleteRequest = useAgTechHttpPostRequest<number, boolean>();

    return {
        name: 'DeleteLot',
        getConfiguration: () => ({
            actionExecutionMessage: 'Deleting lot...',
            actionConfirmationMessage: `Successfully deleted lot`
        }),
        validate: async props => {
            return props.validation.failWithWarningIf(
                isLotInvoiced(props.submittedEntity),
                `Lot ${props.submittedEntity.lotNumber} has been invoiced and cannot be deleted`);
        },
        confirm: async props => {
            let lot = props.submittedEntity;
            let hasLotBeenPurchased = lot.bidderNumber && lot.purchasePrice;

            let lotDeleteConfirmationHeader = hasLotBeenPurchased
                ? `Lot "${lot.lotNumber}" is marked as purchased by bidder "${lot.bidderNumber}", removing this lot will also remove this purchase`
                : `Are you sure you wish to remove lot "${lot.lotNumber}" from the lots in this sale?`;
            
            let lotDeleteConfirmationDetails = hasLotBeenPurchased
                ? "Are you sure you want to remove this lot?"
                : undefined;

            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: lotDeleteConfirmationHeader,
                    details: lotDeleteConfirmationDetails,
                }
            });
        },
        action: async props => {
            return await lotDeleteRequest.post({
                path: 'Lot/Delete/' + props.submittedEntity.id,
                postedData: props.submittedEntity.id,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleLotDataSlice.actions.deleteLot, props.submittedEntity.id);
            props.appContext.popups.closeAll();
        }
    }
}

/// Lot Import Actions

export const useLotDataImportLotsAction = (): AgTechDataAction<SaleLot[], SaleLot[]> => {
    let lotImportPostRequest = useAgTechHttpPostEntityRequest<SaleLot[]>();

    return {
        name: 'ImportLots',
        getConfiguration: props => ({
            actionExecutionMessage: 'Importing lots...',
            actionConfirmationMessage: `Successfully imported ${props.submittedEntity.length} ${props.submittedEntity.length > 1 ? 'lots' : 'lot'}`
        }),
        validate: async props => {
            props.validation.failWithWarningIf(props.submittedEntity.length === 0, 'Please select one or more lots to import');
        },
        action: async props => {
            let currentSale = getCurrentSale();

            let lotsToImport = props.submittedEntity;
            lotsToImport.forEach(lt => { lt.saleId = currentSale.id; });

            return await lotImportPostRequest.post({
                path: 'Lot/Import/' + currentSale.id,
                postedData: lotsToImport,
                onSuccess: response => {
                    response.dispatch(saleLotDataSlice.actions.importLots(response.body));
                }
            });
        }
    }
}

/// Lot Refresh Actions

export const useLotDataRefreshLotsAction = (): AgTechDataAction<SaleLot[], LotIndex> => {
    return {
        name: 'RefreshLots',
        action: async props => {
            let indexedLots: LotIndex = {};
        
            props.submittedEntity.forEach(lot => indexedLots[lot.id] = {
                id: lot.id,
                saleId: lot.saleId,
                saleOrder: lot.saleOrder,
                lotNumber: lot.lotNumber,
                lotDescription: lot.lotDescription,
                bidderNumber: lot.bidderNumber,
                purchasePrice: lot.purchasePrice,
                lotCategoryId: lot.lotCategoryId,
                timestamp: lot.timestamp,
                invoiceState: lot.invoiceState,
                groupSize: lot.groupSize,
                isSplit: lot.isSplit,
                originalBidderId: lot.originalBidderId
            });
        
            return DataActionResults.Success(indexedLots);
        },
        onSuccess: async props => {
            let bidderNumberContactMapping = getBidderNumbersMappedToContacts();

            props.executeReducerAction(saleLotDataSlice.actions.refreshLots, {
                lots: props.responseData,
                bidderContacts: bidderNumberContactMapping
            });
        }
    }
}

export type RefreshLotBiddersActionProps = {
    existingBidderNumber: string,
    updatedBidderNumber: string,
    updatedBidderId: number
}

export const useLotDataRefreshLotBiddersAction = (): AgTechDataAction<RefreshLotBiddersActionProps, any> => {
    let dataActionProps = useAgTechDataActionProps();

    return {
        name: 'RefreshLotBidders',
        action: async props => {       
            dataActionProps.dispatch(saleLotDataSlice.actions.refreshLotBidders({
                existingBidderNumber: props.submittedEntity.existingBidderNumber,
                updatedBidderNumber: props.submittedEntity.updatedBidderNumber,
                updatedBidderId: props.submittedEntity.updatedBidderId,
            }));

            return DataActionResults.Success('');
        }
    }
}

export type LotSplitActionData = {
    lotId: number,
    bidderPercentages: { bidderId?: number, contactId: number, percentage: number }[]
}

export const useLotDataSplitLotAction = (): AgTechDataAction<LotSplitActionData, AgTechApiEntityResponse<SaleLot>> => {
    let lotSplitPostRequest = useAgTechHttpPostRequest<LotSplitActionData & { saleId: number }, any>();

    return {
        name: 'SplitLot',
        getConfiguration: props => ({
            actionExecutionMessage: 'Splitting lot...',
            actionConfirmationMessage: 'Lot splitting was successful'
        }),
        validate: async props => {
            props.validation.failWithWarningIf(
                props.submittedEntity.bidderPercentages.length === 1,
                'Please select one or more splits for this lot');

            props.validation.failWithWarningIf(
                _.sumBy(props.submittedEntity.bidderPercentages, bp => bp.percentage) !== 1,
                'Please make sure the total percentage of all splits is 100%');
        },
        action: async props => {
            return await lotSplitPostRequest.post({
                path: 'Lot/Split',
                postedData: {
                    ...props.submittedEntity,
                    saleId: getCurrentSale().id
                }
            });
        },
        onSuccess: async props => {

            props.executeReducerAction(saleLotDataSlice.actions.writeLot, {
                ...props.responseData.data
            });
        }
    }
}

/// Lot Invoicing Actions

export const useLotDataMarkLotAsInvoicedAction = (): AgTechDataAction<{ lotIds: number[] }, any> => {
    let dispatch = useDispatch();

    return {
        name: 'MarkLotsAsInvoiced',
        action: async props => {
            dispatch(saleLotDataSlice.actions.markLotInvoiced(props.submittedEntity));
            return DataActionResults.Success('');
        }
    }
}

export const useLotDataWriteSaleReorderAction = (): AgTechDataAction<SaleLotOrderModificationGridEntity[], boolean> => {
    let lotReorderPostRequest = useAgTechHttpPostRequest<ReorderLotSaleOrderDTO[], boolean>();

    return {
        name: "ReorderSale",
        validate: async props => {
            props.validation.failWithWarningIf(props.submittedEntity.find(e => e.saleOrder === 0) !== undefined, 'One or more of the sale orders are currently set to 0');
        },
        action: async props => {
            let lots = props.submittedEntity.filter(e => e.changed).map<ReorderLotSaleOrderDTO>((c, i) => ({lotId: c.id, newSaleOrder: c.saleOrder}));
            return await lotReorderPostRequest.post({
                path: 'Lot/Reorder/' + getCurrentSale().id,
                postedData: lots,
                onSuccess: response => {
                    response.dispatch(saleLotDataSlice.actions.reorderLots({changes: lots}));
                }
            });
        }
    };
};