import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons'
import AgTechLeftPaneTabPage, { AgTechLeftPaneTabPageTabGroup } from 'agtech/web/components/Pages/Tabbed/AgTechLeftPaneTabPage'
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog'
import React from 'react'
import SaleDiscountModificationGrid from 'app/areas/discounts/components/grids/SaleDiscountViewGrid';
import LotCategorySettingsPage from 'app/areas/lots/pages/LotCategorySettingsPage'
import InvoiceDocumentConfigurationPage from 'app/areas/operation/pages/InvoiceDocumentConfigurationPage'
import DocumentHeaderConfigurationPage from 'app/areas/operation/pages/DocumentHeaderConfigurationPage'
import LogoUpdateForm from 'app/areas/operation/components/forms/LogoUpdateForm';

const SaleSettingsViewDialog = () => {
    return (
        <AgTechViewDialog
            header={{
                title: 'My Sale Settings',
                detail: 'Use the options below to configure common settings across your sales'
            }}
            width='1100px'
            bodyPadding='snug'
            textWhileLoading='Loading sale settings...'
        >
            <SaleSettingsViewDialogBody />
        </AgTechViewDialog>
    )
}

export const SaleSettingsViewDialogBody = () => {
    let settingsTabs: AgTechLeftPaneTabPageTabGroup[] = [
        {
            header: 'Sales',
            tabs: [
                { text: 'Lot Categories', content: () => <LotCategorySettingsPage />},
                { text: 'Sale Discounts', content: () => <SaleDiscountModificationGrid />}
            ]
        },
        {
            header: 'Documents',
            tabs: [
                { text: 'Document Headers', content: () => <DocumentHeaderConfigurationPage />},
                { text: 'Invoice Fields', content: () => <InvoiceDocumentConfigurationPage />},
            ]
        },
        {
            header: 'Operation',
            tabs: [
                { text: 'Logo', content: () => <LogoUpdateForm />}
            ]
        },
    ]

    return (
        <AgTechLeftPaneTabPage
            tabGroups={settingsTabs}
        />
    )
}


SaleSettingsViewDialog.ButtonProps = {
    text: 'Update Sale Settings',
    icon: 'cogs',
    action: (e: AgTechClickEventHandler) => e.dialogs.openDialog({
        dialog: () => <SaleSettingsViewDialog />
    })
}

SaleSettingsViewDialog.Button = (props: AgTechButtonProps) => (
    <AgTechButton
        {...SaleSettingsViewDialog.ButtonProps}
        {...props}
    />
)

export default SaleSettingsViewDialog