import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import React from 'react'
import DocumentHeaderConfigurationForm from '../components/forms/DocumentHeaderConfigurationForm';

const DocumentHeaderConfigurationPage = () => {
    return (
        <div className='stretched row-based container'>
            <DocumentHeaderConfigurationForm />
        </div>
    )
}

export default DocumentHeaderConfigurationPage