export const Black = 'black';


export const FontColorPrimary = '#212529';
export const FontColorSecondary = '#9e9e9e';

export const fade = (color: string, opacity: number): string => {
    return color.replace('rgb', 'rgba').replace(')', `, ${opacity / 100})`);
}

export class AgTechColors {
    static White: string = 'rgb(255, 255, 255)';
    static Primary: string = 'rgb(20, 20, 20)';
    static Secondary: string = 'rgb(30, 43, 55)';//'rgb(40, 53, 67)';
    static Tertiary: string = 'rgb(55, 65, 82)';
    static HighlightLight: string = 'rgba(100, 204, 255, .2)';
    static Highlight: string = 'rgb(50, 120, 245)';
    static HighlightDark: string = 'rgb(35, 70, 140)';//'rgb(0, 37, 80)';//'#142F43';//'rgb(6, 40, 61)';//'rgb(24, 70, 168)';
    static Background: string = 'rgb(231, 234, 239)';
    static BorderColor: string = 'rgb(210, 210, 210)';
    static Shadow: string = 'rgb(230, 230, 230)';

    static DarkGreen: string = 'rgb(0,153,76)';
    static Green: string = 'rgb(17,221,17)';

    static Red = 'rgb(250, 20, 20)';
    static DarkRed = 'rgb(200, 10, 10)';

    static Yellow = 'rgb(240, 200, 40)';
    static DarkYellow = 'rgb(180, 120, 0)';

    static Cyan = 'rgb(0, 161, 255)';
    static DarkCyan = 'rgb(0, 100, 200)';

    static GreenAccent = (opacity: number) => fade(AgTechColors.Green, opacity);
    static RedAccent = (opacity: number) => fade(AgTechColors.Red, opacity);
    static YellowAccent = (opacity: number) => fade(AgTechColors.Yellow, opacity);
    static HighlightAccent = (opacity: number) => fade(AgTechColors.Highlight, opacity);
    static HighlightDarkAccent = (opacity: number) => fade(AgTechColors.HighlightDark, opacity);
    static GrayAccent = (opacity: number) => fade(AgTechColors.Background, opacity);
    static CyanAccent = (opacity: number) => fade(AgTechColors.Cyan, opacity);
    static PrimaryAccent = (opacity: number) => fade(AgTechColors.Primary, opacity);

    // Font
    static FontColorPrimary: string = '#212529';
    static FontColorSecondary: string = 'rgb(163, 163, 163)';
    static FontColorTertiary: string = '#858585';

    // Grays
    static GrayLightest = '#D3E0EA';

    // Future
    static PrimaryDark = '#20262e';
    static PrimaryLighter = '#282f39';

    // Blue
    static DarkBlue = 'rgb(41, 58, 83)';
    static PrimaryBlue = 'rgb(31, 63, 113)';
    static HighlightBlue = 'rgb(50, 120, 245)';
}