import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AgTechContact, DefaultAgTechContact, useContactData } from "app/data/operation/contacts/ContactData"
import { getSaleState, SalesAppState } from "app/data/SalesAppDataStore"
import { ContactCredit } from "app/data/operation/contacts/credits/ContactDataCredits"
import { useSelector } from "react-redux"

/// Bidder Data Entities

export type SaleBidder = {
    id: number,
    bidderNumber: string,
    contactId: number
}

export type SaleBidderContact = AgTechContact & {
    bidderNumber: string,
    bidderId: number
}

export const DefaultSaleBidderContact: SaleBidderContact = {
    ...DefaultAgTechContact,
    id: 0,
    bidderNumber: '',
    bidderId: 0,
}

export type BidderIndex = {
    [id: number]: SaleBidder
}

export type BidderContactIndex = {
    [contactId: number]: number 
}

export type BidderNumberToContactIdIndex = {
    [bidderNumber: string]: number
}

export const isSaleBidderTheSame = (prev?: SaleBidderContact, curr?: SaleBidderContact) => {
    return prev?.bidderNumber === curr?.bidderNumber &&
           prev?.firstName === curr?.firstName &&
           prev?.lastName === curr?.lastName &&
           prev?.ranchName === curr?.ranchName &&
           prev?.emailAddress === curr?.emailAddress &&
           prev?.bidderId === curr?.bidderId;
}

/// Bidder Data Slice

export type SaleBidderDataState = {
    bidderIdsMappedToBidders: BidderIndex;
    contactIdsMappedToBidders: BidderContactIndex;
}

const DefaultSaleBidderData: SaleBidderDataState = {
    bidderIdsMappedToBidders: {},
    contactIdsMappedToBidders: {}
}

export const saleDataBidderSlice = createSlice({
    name: 'SaleBidders',
    initialState: DefaultSaleBidderData,
    reducers: {
        refreshBidders: (state, action: PayloadAction<BidderIndex>) => {
            state.bidderIdsMappedToBidders = action.payload;
            state.contactIdsMappedToBidders = {};
            
            Object.values(action.payload).forEach(bidder => {
                state.contactIdsMappedToBidders[bidder.contactId] = bidder.id;
            });
        },
        registerBidder: (state, action: PayloadAction<SaleBidder>) => {
            state.bidderIdsMappedToBidders = {
                ...state.bidderIdsMappedToBidders,
                [action.payload.id]: action.payload
            };

            state.contactIdsMappedToBidders = {
                ...state.contactIdsMappedToBidders,
                [action.payload.contactId]: action.payload.id
            };
        },
        updateBidder: (state, action: PayloadAction<SaleBidder>) => {
            state.bidderIdsMappedToBidders[action.payload.id] = { ...action.payload };
        },
        removeBidder: (state, action: PayloadAction<number>) => {
            let bidderRemoved = state.bidderIdsMappedToBidders[action.payload];

            delete state.contactIdsMappedToBidders[bidderRemoved.contactId];
            delete state.bidderIdsMappedToBidders[action.payload];
        }
    }
});


/// Bidder Data Hooks

export type SaleBidderDetailsIndex = {
    [bidderNumber: string]: SaleBidderContact
}

export type SaleBidderContactIndex = {
    [contactId: number]: SaleBidderContact
}

export type SaleBidderData = SaleBidderDataState & {
    bidderDetails: SaleBidderDetailsIndex
}

export const useSaleBidderDetails = (): SaleBidderDetailsIndex => {
    let bidderData = useSelector((state: SalesAppState) => state.bidders);
    let contactData = useContactData();

    let bidderContactDetails: SaleBidderDetailsIndex = {};
    
    Object.values(bidderData.bidderIdsMappedToBidders).forEach(bd => {
        if (bd.bidderNumber !== undefined && bd.bidderNumber !== '') {
            let contact = contactData.contacts[bd.contactId];
            bidderContactDetails[bd.bidderNumber] = {
                ...contact,
                bidderNumber: bd.bidderNumber,
                bidderId: bd.id
            }
        }
    });

    return bidderContactDetails;
}

export const useSaleBidderContacts = (): SaleBidderContactIndex => {
    let bidderData = useSelector((state: SalesAppState) => state.bidders);
    let contactData = useContactData();

    let bidderContactDetails: SaleBidderContactIndex = {};
    
    Object.values(contactData.contacts).forEach(contact => {
        let contactBidderId = bidderData.contactIdsMappedToBidders[contact.id];
        let contactBidder = bidderData.bidderIdsMappedToBidders[contactBidderId];

        bidderContactDetails[contact.id] = {
            ...contact,
            bidderNumber: contactBidder?.bidderNumber ?? '',
            bidderId: contactBidder?.id ?? 0
        }
    });

    return bidderContactDetails;
}

export const useSaleBidderData = (): SaleBidderData => {
    let bidderData = useSelector((state: SalesAppState) => state.bidders);
    let bidderDetails = useSaleBidderDetails();

    return {
        bidderDetails: bidderDetails,
        ...bidderData
    };
}

export const useSaleBidders = (): SaleBidder[] => {
    return Object.values(useSaleBidderData().bidderIdsMappedToBidders);
}

export const useSaleBidderContact = (bidderNumber: string): SaleBidderContact => {
    let bidderDetails = useSaleBidderDetails();
    return bidderDetails[bidderNumber];
}

export const useSaleBidderCredits = (bidderNumber: string): any[] => {
    let bidderData = useSaleBidderData();
    let contactData = useContactData();

    let bidders = Object.values(bidderData.bidderIdsMappedToBidders);
    let bidderCredits: ContactCredit[] = [];

    let registeredBidderWithBidderNumber = bidders.filter(bid => bid.bidderNumber === bidderNumber)[0];

    if (registeredBidderWithBidderNumber) {
        let registeredBidderContact = contactData.contacts[registeredBidderWithBidderNumber.contactId];
        bidderCredits = registeredBidderContact.credits;
    }

    return bidderCredits;
}

export const getBidderNumbersMappedToContacts = () => {
    let bidderNumberContactIndex: BidderNumberToContactIdIndex = {};

    getBidders().forEach(bidder => {
        if (bidder.contactId) {
            bidderNumberContactIndex[bidder.bidderNumber] = bidder.contactId;
        }
    });

    return bidderNumberContactIndex;
}

export const getBidderById = (id: number) => getSaleState().bidders.bidderIdsMappedToBidders[id];

export const getBidderByBidderNumber = (bidderNumber: string) => {
    return getBidders().filter(bd => bd.bidderNumber === bidderNumber)[0];
};

export const getBidderData = () => getSaleState().bidders;

export const getBidders = () => Object.values(getBidderData().bidderIdsMappedToBidders);

export const getContactByBidderNumber = (bidderNumber: string): AgTechContact | undefined => {
    let contact = undefined;
    let contactId = getContactIdByBidderNumber(bidderNumber);

    if (contactId) {
        contact = getSaleState().contacts.contacts[contactId];
    }

    return contact;
}

export const getContactIdByBidderNumber = (bidderNumber: string): number | undefined => getBidderByBidderNumber(bidderNumber)?.contactId;

export const getBidderNumberByContactId = (contactId?: number): string | undefined => {
    return getBidderByContactId(contactId)?.bidderNumber;
};

export const getBidderByContactId = (contactId?: number) => {
    let bidder: SaleBidder | undefined = undefined;

    if (contactId) {
        let bidderId = getBidderData().contactIdsMappedToBidders[contactId];

        if (bidderId) {
            bidder = getBidderById(bidderId);
        }
    }

    return bidder;
}


export default saleDataBidderSlice.reducer;