import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { executeReducerAction } from "agtech/core/data/actions/AgTechDataActions";
import { logDevError, logEvent } from "agtech/core/logging/AgTechLogger";
import { SalesAppState, getLots } from "app/data/SalesAppDataStore"
import { BidderNumberToContactIdIndex } from "app/data/bidders/BidderData";
import { LotBidderPurchase, LotBidderPurchaseIndex, getLotPurchaseAmount } from "app/data/lots/purchases/LotPurchaseData"
import _ from 'lodash';
import { useSelector } from "react-redux"

/// Lot Data Entities

export type LotIndex = {
    [id: string]: SaleLot
}

export type SaleLot = {
    id: number,
    saleId: number,
    saleOrder: number,
    lotNumber: string,
    lotDescription: string,
    bidderNumber?: string,
    purchasePrice?: number,
    lotCategoryId?: number,
    timestamp: string,
    invoiceState: LotInvoiceState,
    isSplit: boolean,
    groupSize?: number,
    originalBidderId?: number
}

export enum LotInvoiceState {
    NotInvoiced = 0,
    PartiallyInvoiced = 1,
    Invoiced = 2
}

export const DefaultSaleLot: SaleLot = {
    id: 0,
    saleId: 0,
    saleOrder: 0,
    lotNumber: '',
    lotDescription: '',
    timestamp: '',
    invoiceState: LotInvoiceState.NotInvoiced,
    isSplit: false
}

export type AgTechImportedSaleLot = SaleLot & {
    rowId: number
}

export type AgTechImportedSaleLotIndex = {
    [rowId: number]: AgTechImportedSaleLot
}

export type LotBidderRefreshData = {
    existingBidderNumber: string,
    updatedBidderNumber: string,
    updatedBidderId: number
}

export type SaleLotComparison = {
    saleOrder: number,
    lotNumber: string,
    lotDescription: string,
    bidderNumber?: string,
    purchasePrice?: number,
    lotCategoryId?: number,
    timestamp: string,
    isInvoiced: boolean,
}

export type ReorderLotSaleOrderDTO = {
    lotId: number,
    newSaleOrder: number
};

export const isLotTheSame = (prev: SaleLot, curr: SaleLot) => {
    return prev.saleOrder === curr.saleOrder &&
           prev.lotNumber === curr.lotNumber &&
           prev.lotDescription === curr.lotDescription &&
           prev.bidderNumber === curr.bidderNumber &&
           prev.purchasePrice === curr.purchasePrice &&
           prev.lotCategoryId === curr.lotCategoryId &&
           prev.timestamp === curr.timestamp &&
           prev.invoiceState === curr.invoiceState &&
           prev.isSplit === curr.isSplit;
}

export const isLotSold = (lot: { bidderNumber?: string, purchasePrice?: number}) => lot.bidderNumber && lot.purchasePrice;

// Lot Data Slice

export type SaleLotsData = {
    lots: LotIndex,
    lotBidderPurchases: LotBidderPurchaseIndex,
}

const DefaultSaleLotsData: SaleLotsData = {
    lots: {},
    lotBidderPurchases: {},
}

export const saleLotDataSlice = createSlice({
    name: 'Lots',
    initialState: DefaultSaleLotsData,
    reducers: {
        refreshLots: (state, action: PayloadAction<{ lots: LotIndex, bidderContacts: BidderNumberToContactIdIndex }>) => {
            try
            {
                state.lots = action.payload.lots;

                let soldLots = Object.values(action.payload.lots).filter(lot => isLotSold(lot));
                let soldLotsGroupedByBidder = _.groupBy(soldLots, lt => lt.bidderNumber);

                Object.keys(soldLotsGroupedByBidder).forEach(bidderNumber => {
                    let lotsPurchasedByBidder = soldLotsGroupedByBidder[bidderNumber];

                    lotsPurchasedByBidder.forEach(lot => {
                        let lotPurchase: LotBidderPurchase = {
                            lotId: lot.id,
                            bidderNumber: bidderNumber,
                            purchasePrice: lot.purchasePrice ?? 0,
                            isInvoiced: isLotInvoiced(lot),
                            percentage: 100
                        };

                        let bidderPurchases = state.lotBidderPurchases[bidderNumber];

                        if (bidderPurchases) {
                            state.lotBidderPurchases[bidderNumber][lot.id] = lotPurchase;
                        }
                        else {
                            state.lotBidderPurchases[bidderNumber] = {
                                [lot.id]: lotPurchase
                            }
                        }
                    });
                });
            }
            catch (e) {
                logDevError(e);
            }
        },
        importLots: (state, action: PayloadAction<SaleLot[]>) => {
            action.payload.forEach(importedLot => {
                state.lots[importedLot.id] = { ...importedLot };
            })
        },
        refreshLotBidders: (state, action: PayloadAction<LotBidderRefreshData>) => {
            let refreshedLots: LotIndex = {};

            Object.values(state.lots).forEach(lot => {
                refreshedLots[lot.id] = lot.bidderNumber === action.payload.existingBidderNumber ? {
                    ...lot,
                    bidderNumber: action.payload.updatedBidderNumber
                }
                : { ...lot };
            });

            state.lots = refreshedLots;
        },
        writeLot: (state, action: PayloadAction<SaleLot>) => {
            state.lots[action.payload.id] = {...action.payload};
        },
        deleteLot: (state, action: PayloadAction<number>) => {
            delete state.lots[action.payload];

            let lotBidderPurchases = Object.values(state.lotBidderPurchases);

            lotBidderPurchases.forEach(bidderPurchases => {
                delete bidderPurchases[action.payload];
            });
        },
        writeLotPurchase: (state, action: PayloadAction<LotBidderPurchase & { lotTimestamp: string }>) => executeReducerAction(() => {
            let existingLot = state.lots[action.payload.lotId];

            logEvent('Writing Lot Purchase', {
                payload: action.payload
            });

            state.lots[action.payload.lotId] = {
                ...existingLot,
                timestamp: action.payload.lotTimestamp,
                bidderNumber: action.payload.bidderNumber,
                purchasePrice: action.payload.purchasePrice,
            };

            let existingBidderPurchases = state.lotBidderPurchases[action.payload.bidderNumber];

            if (existingBidderPurchases) {
                let existingBidderLotPurchase = state.lotBidderPurchases[action.payload.bidderNumber][action.payload.lotId];

                if (existingBidderLotPurchase) {
                    state.lotBidderPurchases[action.payload.bidderNumber][action.payload.lotId] = {
                        ...existingBidderLotPurchase,
                        percentage: action.payload.percentage,
                        purchasePrice: action.payload.purchasePrice
                    };
                }
                else {
                    state.lotBidderPurchases[action.payload.bidderNumber][action.payload.lotId] = {
                        ...action.payload
                    };
                }
            }
            else {
                state.lotBidderPurchases[action.payload.bidderNumber] = {
                    [action.payload.lotId]: {
                        lotId: action.payload.lotId,
                        bidderNumber: action.payload.bidderNumber,
                        percentage: action.payload.percentage,
                        isInvoiced: false,
                        purchasePrice: action.payload.purchasePrice
                    }
                }
            }
        }),
        deleteLotPurchase: (state, action: PayloadAction<{ lotId: number, lotTimestamp: string }>) => {
            let existingLot = state.lots[action.payload.lotId];

            if (existingLot && !isLotInvoiced(existingLot)) {
                state.lots[action.payload.lotId] = {
                    ...existingLot,
                    bidderNumber: undefined,
                    purchasePrice: undefined,
                    timestamp: action.payload.lotTimestamp
                };
    
                let bidderLotPurchases = Object.values(state.lotBidderPurchases);
    
                bidderLotPurchases.forEach(bidder => {
                    delete bidder[action.payload.lotId];
                });
            }
        },
        markLotInvoiced: (state, action: PayloadAction<{ lotIds: number[] }>) => {
            action.payload.lotIds.forEach(lotId => {
                state.lots[lotId].invoiceState = LotInvoiceState.Invoiced;
            });
        },
        reorderLots: (state, action: PayloadAction<{ changes: ReorderLotSaleOrderDTO[] }>) => {
            action.payload.changes.forEach(change => {
                state.lots[change.lotId].saleOrder = change.newSaleOrder;
            });
        }
    }
});


/// Lot Data Hooks

export const useSaleLotData = (): SaleLotsData => useSelector((state: SalesAppState) => state.lots);

export const useSaleLots = (): SaleLot[] => Object.values(useSaleLotData().lots);

export const useSaleLot = (lotId: number): SaleLot => useSaleLotData().lots[lotId];

export const useSaleLotsAssociatedWithBidder = (bidderNumber: string): SaleLot[] => {
    let lotData = useSaleLotData();
    let lots = Object.values(lotData.lots);

    return lots.filter(lt => lt.bidderNumber === bidderNumber);
}

export const useLotBidderPurchasesForBidder = (bidderNumber: string): LotBidderPurchase[] => {
    let lotData = useSaleLotData();
    let lotPurchases = Object.values(lotData.lotBidderPurchases[bidderNumber]);

    return lotPurchases.filter(lt => !lt.isInvoiced);
}

export const useUnprocessedSaleLots = (): SaleLot[] => {
    let lotData = useSaleLotData();
    let lots = Object.values(lotData.lots);

    return lots.filter(lot => lot.bidderNumber && lot.purchasePrice && lot.purchasePrice >= 0 && !isLotInvoiced(lot));
}

export const getSaleLotsPurchasedByBidder = (bidderNumber: string): SaleLot[] => {
    return getLots().filter(lot => lot.bidderNumber && lot.bidderNumber === bidderNumber);
}

export const isLotInvoiced = (lot: SaleLot) => lot.invoiceState === LotInvoiceState.Invoiced;

export const getLotStatus = (lot: SaleLot) => {
    return isLotInvoiced(lot) ? 'Invoiced'
        : lot.purchasePrice ? 'Sold'
        : 'Not sold';
}

export default saleLotDataSlice.reducer;