export interface IAgTechAppLoading {
    showLoader: (loadingText: string) => React.ReactNode,
    showLoadingError: (loadingError: string, retryConfiguration?: AgTechAppLoadingRetryConfiguration) => React.ReactNode,
    showSubmission: (submissionText: string) => React.ReactNode,
    showSubmissionConfirmation: (props: { confirmationMessage: string, confirmationDetails?: string }) => React.ReactNode,
}

export type AgTechAppLoadingRetryConfiguration = {
    retryButtonText: string,
    retryIconText: string,
    retryAction: () => Promise<any>
}

export const DefaultAgTechAppLoading: IAgTechAppLoading = {
    showLoader: loadingText => {
        console.error('Loader shown by default handler: ' + loadingText);
        return null;
    },
    showLoadingError: loadingError => {
        console.error('Loading error shown by default handler: ' + loadingError);
        return null;
    },
    showSubmission: submissionText => {
        console.error('Submissions shown by default handler: ' + submissionText);
        return null;
    },
    showSubmissionConfirmation: (props: { confirmationMessage: string, confirmationDetails?: string }) => {
        console.error('Submission confirmation shown by default handler');
        return null;
    },
}