import { AgTechFileUploadButton, AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechGridColumnMetadata } from "agtech/web/components/Grids/Data/AgTechGridData";
import { AgTechGridSearchOption } from "agtech/web/components/Grids/Features/AgTechGridSearching";
import { AgTechWarningIcon } from "agtech/web/components/Icons/AgTechIcons";
import { AgTechStandardModal } from "agtech/web/components/Portals/Modals/AgTechModal";
import { AgTechImportModalResultViewProps, AgTechImportGridItem } from "agtech/web/components/Portals/Modals/Imports/Common/AgTechImportTypes";

export type AgTechSuccessfulImportResultsViewProps<TImportItem> = AgTechImportModalResultViewProps<TImportItem> & {
    onImportSubmitted: (importedItems: TImportItem[]) => Promise<void>
}

const AgTechSuccessfulImportResultsView = <TImportItem, >(props: AgTechSuccessfulImportResultsViewProps<TImportItem>) => {
    let importResultsGridColumns = props.importProps.importColumns.map<AgTechGridColumnMetadata<AgTechImportGridItem<TImportItem>>>(column => ({
        header: column.columnName,
        value: gridItem => {
            let columnValidationErrors = gridItem.importItem.invalidColumns[column.columnName];
            
            if (columnValidationErrors) {
                return (
                    <div className='column-based stretched container'>
                        {columnValidationErrors.importedValue}
                        <AgTechWarningIcon classes='ml-auto' />
                    </div>
                )
            }
            else {
                return column.getItemValue(gridItem.importItem.importedItem);
            }
        },
        itemBasedClasses: gridItem => gridItem.importItem.invalidColumns[column.columnName] ? 'invalid' : '',
        ...(column.getItemGridColumn ? column.getItemGridColumn() : {})
    }));

    importResultsGridColumns = [{
        header: 'Row #',
        value: gridItem => gridItem.importItem.itemRow,
        size: .5,
        classes: 'centered emphasized'
    }, ...importResultsGridColumns];

    let importResultGridSearchOptions = props.importProps.importColumns.map<AgTechGridSearchOption<AgTechImportGridItem<TImportItem>>>(column => ({
        field: column.columnName,
        value: item => column.getItemValue(item.importItem.importedItem)
    }));

    let importedItems = props.importResult.items;

    return (
        <AgTechStandardModal
            title='Import Results'
            details='Below are the results for this import. To complete this import, click the button below.'
            body={() => (
                <div className='row-based stretched fade-in-400 container'>
                    <AgTechGrid
                        header={{
                            title: `Imported ${props.terminology.importEntityPlural}`
                        }}
                        searchConfig={{
                            options: importResultGridSearchOptions
                        }}
                        items={props.importResult.items.map<AgTechImportGridItem<TImportItem>>((item, index) => ({
                            id: index,
                            importItem: item
                        }))}
                        columns={importResultsGridColumns}
                        isRowEntityTheSame={() => true}
                    />
                </div>
            )}
            footer={() => (
                <div className='column-based-vertically-centered wide container'>
                    <AgTechFileUploadButton
                        text='Upload New File'
                        icon='upload'
                        onFileUploaded={props.onImportAgain}
                        classes='py-2 px-5'
                    />
                    <AgTechButton
                        text={'Import ' + props.terminology.importEntityPlural}
                        icon='plus'
                        action={async e => {
                            let wasImportConfirmed = await e.confirmations.askForConfirmation({
                                content: {
                                    header: `Are you sure you want to import ${importedItems.length} ${importedItems.length > 1 ? props.terminology.importEntityPlural : props.terminology.importEntitySingular}?`,
                                },
                                actions: {
                                    onConfirm: async () => {
                                        await props.onImportSubmitted(importedItems.map(e => e.importedItem));
                                        return true;
                                    }
                                }
                            });

                            return wasImportConfirmed;
                        }}
                        classes='highlight py-2 px-5 ml-auto'
                        styles={{ minWidth: 200 }}
                    />
                </div>
            )}
        />
    )
}

export default AgTechSuccessfulImportResultsView;