import { AgTechButtonProps, AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import InvoiceDocumentConfigurationForm from 'app/areas/invoices/components/forms/InvoiceDocumentConfigurationForm';
import { useInvoiceDataGetSampleInvoiceRequest } from 'app/data/invoicing/InvoicingDataActions';
import React from 'react'

const InvoiceDocumentConfigurationPage = () => {
    return (
        <div className='stretched row-based container'>
            <InvoiceDocumentConfigurationForm />
        </div>
    )
}

export default InvoiceDocumentConfigurationPage