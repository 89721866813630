import { AgTechGridCoreColumnMetadata, IAgTechGridItem } from "agtech/web/components/Grids/Data/AgTechGridData"

export type AgTechImportValidationError = {
    errorMessage: string,
    importedValue: string
}

export type AgTechImportInvalidColumnIndex = {
    [columnName: string]: AgTechImportValidationError
}

export type AgTechImportItem<TItem> = {
    itemRow: number,
    importedItem: TItem,
    isValidItem: boolean,
    isConflictingItem: boolean,
    invalidColumns: AgTechImportInvalidColumnIndex,
    importFailureMessage?: string
}

export const isItemImportValid = <TItem, >(importItem: AgTechImportItem<TItem>) => {
    return importItem.isValidItem && !importItem.isConflictingItem;
}

export type AgTechImportGridItem<TItem> = IAgTechGridItem & {
    importItem: AgTechImportItem<TItem>
}

export type AgTechImportValidationResult = {
    isValid: boolean,
    errorMessage?: string
}

export type AgTechImportColumn<TItem> = {
    columnName: string,
    getItemValue: (item: TItem) => string,
    validateItemValue?: (importedValue: string, allImportedValues: string[]) => AgTechImportValidationResult
    setItemValue: (item: TItem, value: string) => void,
    getItemGridColumn?: () => AgTechGridCoreColumnMetadata
}

export type AgTechImportExecutionProps<TItem> = {
    importColumns: AgTechImportColumn<TItem>[],
    defaultItem: TItem,
    conflictHandling: {
        existingItems: TItem[],
        itemKeys: ((item: TItem) => { key: string, warning: string })[]
    }
}

export type AgTechImportResult<TItem> = {
    items: AgTechImportItem<TItem>[],
    wasSuccessful: boolean,
    hadConflicts: boolean
}

export type AgTechImportStep = {
    instruction: string,
    details?: string,
    body?: () => React.ReactElement
}


/// Props

export type AgTechImportTerminologyProps = {
    importEntitySingular: string,
    importEntityPlural: string,
}

export type AgTechImportModalResultViewProps<TItem> = {
    importProps: AgTechImportExecutionProps<TItem>,
    importResult: AgTechImportResult<TItem>,
    terminology: AgTechImportTerminologyProps,
    onImportAgain: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}