import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import BidderListPopUpLink from 'app/areas/bidders/components/buttons/BidderListPopUpLink';
import { ContactGridLink } from 'app/areas/contacts/components/buttons/ContactButtons';
import InvoiceCreationDialog from 'app/areas/invoices/dialogs/InvoiceCreationDialog';
import BidderLotsListPopUp from 'app/areas/lots/components/popups/BidderLotsListPopUp';
import { SaleBidderContact, getContactIdByBidderNumber, useSaleBidderContacts } from 'app/data/bidders/BidderData';
import { SaleParticipantUnProcessedSalesSummary } from 'app/data/invoicing/InvoicingDataActions';
import { useSaleLotData } from 'app/data/lots/LotData';
import { useContactData } from 'app/data/operation/contacts/ContactData';
import _ from 'lodash';
import React from 'react'

const InvoiceInvoicingGrid = (props: { unprocessedSales: SaleParticipantUnProcessedSalesSummary[] }) => {
    let gridConfig = useInvoiceInvoicingGridConfig(props);

    return (
        <AgTechGrid
            {...gridConfig}
        />
    )
};

export type InvoiceInvoicingLotDTO = {
    id: string,
    bidderId: number,
    bidderName: string,
    numberOfLotsToInvoice: number,
    lotPurchaseTotal: number,
    bidderNumber?: string,
    bidderContact?: SaleBidderContact
}

const useInvoiceInvoicingGridConfig = (props: { unprocessedSales: SaleParticipantUnProcessedSalesSummary[] }) => {
    let invoiceLotGroups = useInvoiceLotGroups(props);

    let invoiceInvoicingGridProps: AgTechGridProps<InvoiceInvoicingLotDTO> = {
        header: {
            title: "Sold Lots Not Invoiced"
        },
        items: invoiceLotGroups,
        columns: [
            {
                header: "Bidder Number",
                value: lot => lot.bidderNumber,
                classes: 'emphasized centered',
                width: '140px'
            },
            {
                header: "Bidder Name",
                value: lot => lot.bidderContact ? <ContactGridLink contactId={lot.bidderContact.id} /> : 'N/A',
            },
            {
                header: "Lots Purchased",
                value: lot => (
                    <BidderListPopUpLink
                        bidderId={lot.bidderId}
                        content={lot.numberOfLotsToInvoice.toString()}
                        isClickable={lot.numberOfLotsToInvoice > 0}
                    />
                )
            },
            {
                header: "Purchase Totals",
                value: lot => AgTechMoneyFormatter.format(lot.lotPurchaseTotal)
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.bidderId === curr.bidderId &&
                   prev.numberOfLotsToInvoice === curr.numberOfLotsToInvoice &&
                   prev.lotPurchaseTotal === curr.lotPurchaseTotal;
        },
        searchConfig: {
            options: [
                {
                    field: 'Bidder Number',
                    value: lot => lot.bidderNumber ?? 'N/A'
                },
                {
                    field: 'Bidder Name',
                    value: lot => lot.bidderName
                }
            ]
        },
        actions: {
            width: '120px',
            rendering: lot => (
                <InvoiceCreationDialog.Button
                    invoicee={{
                        bidderId: lot.bidderId,
                        bidderNumber: lot.bidderNumber,
                        contactId: lot.bidderContact?.id
                    }}
                    classes='link'
                />
            )
        }
    }

    return invoiceInvoicingGridProps;
}

export const useInvoiceLotGroups = (props: { unprocessedSales: SaleParticipantUnProcessedSalesSummary[] }): InvoiceInvoicingLotDTO[] => {
    let lotData = useSaleLotData();
    let contactData = useContactData();
    let saleBidderContacts = useSaleBidderContacts();

    return props.unprocessedSales.map<InvoiceInvoicingLotDTO>(sale => {
        let bidderContact = sale.contactId ? saleBidderContacts[sale.contactId] : undefined;

        return {
            id: sale.bidderId.toString(),
            bidderId: sale.bidderId,
            bidderName: sale.name ?? 'N/A',
            numberOfLotsToInvoice: sale.numberOfLots,
            lotPurchaseTotal: sale.totalPurchasePrice,
            bidderNumber: sale.bidderNumber,
            bidderContact: bidderContact ? {
                ...bidderContact,
                bidderId: bidderContact?.bidderId,
                bidderNumber: bidderContact.bidderNumber
            } : undefined
        }
    })
    .filter(cnt => cnt.numberOfLotsToInvoice > 0);
}

export default InvoiceInvoicingGrid;