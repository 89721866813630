import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/// Operation Entities

export type AgTechOperation = {
    operationId: number,
    operationName: string,
    operationHostName: string,
    operationLogo?: string
}

export const DefaultAgTechOperation: AgTechOperation = {
    operationId: 0,
    operationName: '',
    operationHostName: ''
};

/// Operation Slices

const operationDataSlice = createSlice({
    name: 'operation',
    initialState: DefaultAgTechOperation,
    reducers: {
        setOperation: (state, action: PayloadAction<AgTechOperation>) => {
            return {
                ...state,
                ...action.payload
            }
        }
    }
});

/// Operation Actions

export type AgTechOperationDataActions = {
    setOperation: () => Promise<void>
}

/// Operation Context

export type AgTechOperationContext = {
    operation: AgTechOperation
}

export const OperationContext = createContext<AgTechOperationContext>({
    operation: DefaultAgTechOperation
});

/// Operation Hooks

export const useOperationData = (): AgTechOperation => {
    return useContext(OperationContext).operation;
}

export default operationDataSlice.reducer;