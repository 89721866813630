import React from 'react'

export type AgTechTextProps = {
    text: string,
    bolded?: boolean,
    classes?: string,
    styles?: React.CSSProperties
}

export default (props: AgTechTextProps) => {

    let fontBoldClass = props.bolded ? 'agtech-bold-font' : '';

    return (
        <div className={`agtech-standard-font ${fontBoldClass} ${props.classes}`} style={props.styles}>
            {props.text}
        </div>
    );
}