import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechIconBadge } from 'agtech/web/components/Icons/AgTechIcons'
import AgTechFadeInView from 'agtech/web/components/Pages/Loading/AgTechFadeInView'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

const AgTechWebAppErrorBoundary = (props: { children?: React.ReactElement }) => {
    return (
        <ErrorBoundary FallbackComponent={AgTechWebAppErrorPage}>
            {props.children}
        </ErrorBoundary>
    )
};

const AgTechWebAppErrorPage = () => {
    return (
        <AgTechFadeInView classes='stretched row-based centered bg-primary font-color-white container'>
            <AgTechIconBadge icon='exclamation' classes="bg-red font-color-white mb-4" />
            <h1 >We're Sorry!</h1>
            <h2 className='mb-1'>An error occurred within the application.</h2>
            <h3 className='font-size-medium mb-5'>To restart, please click the button below. If the issue persists, please contact us.</h3>
            <AgTechButton
                text="Restart Application"
                classes='highlight mt-6 py-2-half px-6 font-size-large'
                action={() => {
                    window.location.href = '/';
                }}
            />
        </AgTechFadeInView>
    )
}

export default React.memo(AgTechWebAppErrorBoundary);