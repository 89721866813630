import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import { ContactNoteRemovalButton } from 'app/areas/contacts/components/buttons/ContactButtons';
import ContactNoteDialog from 'app/areas/contacts/dialogs/notes/ContactNoteDialog';
import { useContactNote } from 'app/data/operation/contacts/ContactData';
import { ContactNote } from 'app/data/operation/contacts/notes/ContactDataNotes';
import React from 'react'

export type ContactNoteActionMenuProps = {
    contactId: number,
    noteId: number
}

const ContactNoteActionMenu = (props: ContactNoteActionMenuProps) => {
    let note = useContactNote(props.contactId, props.noteId);

    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Note">
                <ContactNoteDialog.Button contactId={props.contactId} note={note} />
                <ContactNoteRemovalButton note={note} />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
};

export default ContactNoteActionMenu;