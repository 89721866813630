import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlProps } from 'agtech/core/forms/AgTechFormControls';
import React, { useContext, useState } from 'react'

export type AgTechFormToggleControlProps<TEntity> = AgTechFormControlProps<TEntity, boolean> & {
    toggle?: (isToggled: boolean, onToggleChanged: (wasChecked: boolean) => void) => React.ReactNode
}

export const AgTechFormToggleControl = <TEntity, >(props: AgTechFormToggleControlProps<TEntity>) => {
    let formContext = useContext(FormContext);
    let formControlWidth: string = `${props.width ?? 50}%`;

    let [isChecked, setIsChecked] = useState(props.value(formContext.entity) ?? false);

    let handleToggleToggled = (wasChecked: boolean) => {
        if (props.readonly) {
            return;
        }

        if (props.onControlValueChanged) {
            let updatedValue = wasChecked;
            formContext.handleFieldChanged(updatedValue, props.onControlValueChanged);
        }

        setIsChecked(wasChecked);
    }

    let inputTabIndex = props.readonly ? {tabIndex: 1} : {};

    return (
        <div className="agtech-form-control"  style={{maxWidth: formControlWidth}}>
            { props.toggle ? props.toggle(isChecked, handleToggleToggled) : (
                <>
                    <h1 className="agtech-form-control-caption">{props.caption}</h1>
                    <div className="agtech-toggle-surface">
                        <label className="agtech-toggle-container">
                            <input type="checkbox" {...inputTabIndex} checked={!!isChecked} onChange={() => props.readonly ? false : handleToggleToggled(!isChecked)} disabled={props.readonly} />
                            <span className="agtech-toggle"></span>
                        </label>
                    </div>
                </>
            )}         
        </div>
    );
};