import AgTechButtonPane from 'agtech/web/components/Buttons/AgTechButtonPane';
import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import AgTechTabbedPage, { AgTechTabbedPageTabProps } from 'agtech/web/components/Pages/Tabbed/AgTechTabPage';
import SaleBidderRegistrationPage from 'app/areas/bidders/pages/SaleBidderRegistrationPage';
import SaleInvoicingOverviewPage from 'app/areas/invoices/pages/SaleInvoicingOverviewPage';
import SaleLotOverviewPage from 'app/areas/lots/SaleLotOverviewPage';
import LotDialog from 'app/areas/lots/dialogs/LotDialog';
import SaleLotImportModal from 'app/areas/lots/modals/SaleLotImportModal';
import { SaleOverviewPage } from 'app/areas/sales/pages/SaleOverviewPage';
import { useSaleLots } from 'app/data/lots/LotData';
import { Sale } from 'app/data/sales/SaleData'
import React from 'react'
import { SaleQuickActionsButton } from 'app/areas/sales/SaleQuickActions';

const SaleWorkspace = (props: { sale: Sale }) => {
    let saleLots = useSaleLots();

    return saleLots.length > 0 ? (
        // <>
        //     <LotSelectionButton onItemSelected={async lot => alert(lot.lotNumber)} />
        //     <LotSellLotDialog.Button />
        //     <div className='rounded bordered bg-white container' style={{ height: '100%' }}>
        //         <SaleQuickActionsPopUp />
        //     </div>
        // </>
        <SaleWorkspaceTabPage sale={props.sale} />
    ) : (
        <AgTechNoRecordsPage
            message={`Sale '${props.sale.saleName}' does not have any lots`}
            action={{
                prompt: 'To create or import lots, please use the buttons below',
                button: () => (
                    <AgTechButtonPane>
                        <LotDialog.Button classes='highlight' />
                        <SaleLotImportModal.Button classes='highlight' />
                    </AgTechButtonPane>
                )
            }}
            messageClasses='font-size-larger'
            promptClasses='font-size-medium mb-6'
        />
    )
}

const SaleWorkspaceTabPage = (props: { sale: Sale }) => {
    let saleHomePageTabs: AgTechTabbedPageTabProps[] = [
        {
            text: 'Overview',
            content: () => <SaleOverviewPage />  
        },
        {
            text: 'Manage Bidders',
            content: () => <SaleBidderRegistrationPage />
        },
        {
            text: 'Sell Lots',
            content: () => <SaleLotOverviewPage />
        },
        {
            text: 'Perform Invoicing',
            content: () => <SaleInvoicingOverviewPage />
        }
    ];

    return (
        <AgTechTabbedPage
            tabs={saleHomePageTabs}
            header={{
                content: () => (
                    <div className='ml-auto container'>
                        <SaleQuickActionsButton />
                    </div>
                )
            }}
        />
    )
}

export default SaleWorkspace