import { AgTechDataActionExecutionResult } from 'agtech/core/data/actions/AgTechDataActions';

/// Requests

export type AgTechHttpRequestProps<TInputType> = {
    data: TInputType
}

export type AgTechHttpRequest<TInputType, TResponseType> = {
    execute: (props: AgTechHttpRequestProps<TInputType>) => Promise<AgTechHttpResponse<TResponseType>>
}

export type AgTechHttpResponse<TResponseType> = {
    wasAttempted: boolean,
    errorMessage?: string,
    success?: {
        data: TResponseType
    }
}

/// Responses

export type AgTechPaginatedApiResponse<TEntity> = {
    items: TEntity[]
}

export class AgTechHttpResponses {
    static Default = <TData, >(data: TData): AgTechHttpResponse<TData> => {
        return {
            wasAttempted: false,
            success: {
                data: data
            }
        }
    }

    static Complete = (): AgTechHttpResponse<any> => {
        return {
            wasAttempted: true,
            success: {
                data: null
            }
        }
    }

    static Success = <TData, >(data: TData): AgTechHttpResponse<TData> => {
        return {
            wasAttempted: true,
            success: {
                data: data
            }
        }
    }

    static Failed = (error: any, data?: any): AgTechHttpResponse<any> => {
        return {
            errorMessage: error?.toString(),
            wasAttempted: true
        }
    }
}

/// Execution

export type AgTechHttpRequestExecutor = {
    executeRequest: <TInputData, TResponseData>(
        request: AgTechHttpRequest<TInputData, TResponseData>,
        inputData: TInputData
    ) => Promise<AgTechHttpResponse<TResponseData>>,
    executeAndConvertRequest: <TInputData, TResponseData, TConvertedData>(props: {
        request: AgTechHttpRequest<any, TResponseData>,
        inputData?: TInputData,
        convert: (responseData: TResponseData) => Promise<TConvertedData>,
    }) => Promise<AgTechHttpResponse<TConvertedData>>
}

export const useAgTechHttpRequestExecutor = (): AgTechHttpRequestExecutor => {
    return {
        executeRequest: async (request, inputData) => await request.execute({ data: inputData }),
        executeAndConvertRequest: async props => {
            let initialResponse = await props.request.execute({ data: props.inputData });

            if (initialResponse.success) {
                let convertedData = await props.convert(initialResponse.success.data);

                return {
                    wasAttempted: true,
                    success: {
                        data: convertedData
                    }
                }
            }
            else {
                return {
                    errorMessage: initialResponse.errorMessage,
                    wasAttempted: true,
                    success: undefined
                };
            }
        }
    }
}

/// Conversions

export type AgTechApiEntityResponse<TEntity> = {
    data: TEntity,
    errorMessage: string[]
}

export const convertHttpResponse = <TEntity, >(response: AgTechHttpResponse<AgTechApiEntityResponse<TEntity>>): AgTechHttpResponse<TEntity> => {
    return response.success ? {
        wasAttempted: true,
        success: {
            data: response.success.data.data
        }
    } : {
        wasAttempted: true,
        success: undefined
    }
}

export const convertFromEntityResponse = <TEntity, >(response: AgTechHttpResponse<AgTechApiEntityResponse<TEntity>>): AgTechDataActionExecutionResult<TEntity> => {
    return response.success ? {
        success: {
            data: response.success.data.data
        }
    } : {
        success: undefined
    }
}