import { AgTechHttpRequestProps, AgTechApiResponseHandlers, AgTechInternalAxiosRequestProps, handleFailedApiResponse, handleSuccessfulApiResponse } from './AgTechCoreApiRequests';
import { executeAgTechApiRequest, useAgTechHttpRequestConfigurationBuilder } from "agtech/core/data/http/AgTechCoreApiRequests";
import { AgTechHttpResponse, AgTechPaginatedApiResponse, AgTechHttpResponses } from "agtech/core/data/actions/AgTechHttpRequests";
import axios from 'axios';

export type AgTechApiGetRequestProps<TResponseData> = AgTechHttpRequestProps & AgTechApiResponseHandlers<TResponseData>

export type AgTechApiGetRequest<TResult> = {
    get: (props: AgTechApiGetRequestProps<TResult> | string) => Promise<AgTechHttpResponse<TResult>>,
}

export const useAgTechGetRequest = <TData>(): AgTechApiGetRequest<TData> => {
    let buildRequestConfiguration = useAgTechHttpRequestConfigurationBuilder();

    return {
        get: props => {
            let requestProps = props as AgTechApiGetRequestProps<TData>;

            if (typeof props === 'string') {
                requestProps = {
                    path: props as string
                };
            }

            return executeAgTechApiRequest({
                requestType: 'GET',
                requestConfiguration: buildRequestConfiguration(requestProps),
                requestResponseHandlers: requestProps,
                request: executeGetRequestInternal
            });
        }
    }
}

/// DTO Requests

export type AgTechDtoEntity<TDTO, TEntity> = {
    dto: TDTO,
    entity: TEntity
}

export type AgTechGetDtoRequestProps<TDTO, TEntity> = AgTechHttpRequestProps & AgTechApiResponseHandlers<AgTechDtoEntity<TDTO, TEntity>> & {
    convert: (result: TDTO) => TEntity
}

export type AgTechGetDtoRequest<TDTO, TEntity> = {
    getDtoAndConvert: (props: AgTechGetDtoRequestProps<TDTO, TEntity>) => Promise<AgTechHttpResponse<TEntity>>
}

export const useAgTechGetDtoRequest = <TDTO, TEntity>(): AgTechGetDtoRequest<TDTO, TEntity> => {
    let buildRequestConfiguration = useAgTechHttpRequestConfigurationBuilder();

    return {
        getDtoAndConvert: async requestProps => {
            let requestResponse = AgTechHttpResponses.Failed('An error occurred')
            let requestConfiguration = buildRequestConfiguration(requestProps);

            let dtoRequestProps: AgTechApiGetRequestProps<TDTO> = {
                ...requestProps,
                onSuccess: response => AgTechHttpResponses.Success(response.body),
                onError: failure => AgTechHttpResponses.Failed(failure.error)
            };

            let dtoRequestResponse = await executeAgTechApiRequest({
                requestType: 'GET',
                requestConfiguration: requestConfiguration,
                requestResponseHandlers: dtoRequestProps,
                request: executeGetRequestInternal
            });

            if (dtoRequestResponse.success) {
                try
                {
                    let entityFromResponse = requestProps.convert(dtoRequestResponse.success.data);

                    requestResponse = AgTechHttpResponses.Success(entityFromResponse);

                    await handleSuccessfulApiResponse({
                        ...requestConfiguration,
                        response: { wasSuccessful: true, data: {
                            dto: dtoRequestResponse.success.data,
                            entity: entityFromResponse
                        } },
                        handlers: requestProps
                    });
                }
                catch (error) {
                    console.log('An error occurred mapping DTO data to an entity: ' + JSON.stringify(error));

                    requestResponse = await handleFailedApiResponse({
                        ...requestConfiguration,
                        error: {
                            message: dtoRequestResponse.errorMessage ?? 'An error occurred',
                            statusCode: 403
                        },
                        handlers: dtoRequestProps
                    });
                }
            }

            return requestResponse;
        }
    }
}


/// Paginate Requests

export type AgTechPaginatedGetRequest<TEntity> = {
    getEntities: (props: AgTechApiGetRequestProps<TEntity[]>) => Promise<AgTechHttpResponse<TEntity[]>>
}

export const useAgTechPaginatedGetRequest = <TEntity, >(): AgTechPaginatedGetRequest<TEntity> => {
    let getDtoRequest = useAgTechGetDtoRequest<AgTechPaginatedApiResponse<TEntity>, TEntity[]>();

    return {
        getEntities: async props => getDtoRequest.getDtoAndConvert({
            path: props.path,
            convert: paginatedResponse => paginatedResponse.items
        })
    }
}

/// Image Requests

export const useAgTechImageRequest = () => {
    let buildRequestConfiguration = useAgTechHttpRequestConfigurationBuilder();

    return {
        execute: async (path: string) => {
            let requestConfiguration = buildRequestConfiguration({
                path: path
            });

            return await executeAgTechApiRequest<any, any>({
                requestType: 'GET',
                responseType: 'arraybuffer',
                requestConfiguration: requestConfiguration,
                requestResponseHandlers: {
                    onSuccess: props => {
                        let encodedImage = btoa(
                            new Uint8Array(props.body).reduce(
                                (data, byte) => data + String.fromCharCode(byte),
                                ''
                            )
                        );
            
                        return AgTechHttpResponses.Success(encodedImage)
                    }
                },
                request: executeGetRequestInternal
            });
        }
    }
}

/// Download Requests

export const useAgTechApiDownloadRequest = () => {
    let requestConfigurationBuilder = useAgTechHttpRequestConfigurationBuilder();

    let executeDownloadRequest = (props: { path: string }) => {
        let requestConfiguration = requestConfigurationBuilder(props);

        return executeAgTechApiRequest<any, any>({
            requestType: 'GET',
            responseType: 'blob',
            requestConfiguration: requestConfiguration,
            request: executeGetRequestInternal
        });
    }

    return {
        execute: executeDownloadRequest,
        download: async (props: {
            path: string,
            fileNameWithExtension: string
        }): Promise<AgTechHttpResponse<Blob>> => {
            let downloadedDocument = await executeDownloadRequest(props);

            if (downloadedDocument.success) {
                let documentBlob = new Blob([downloadedDocument.success?.data]);

                const mockDocumentURL = window.URL.createObjectURL(documentBlob);
                const documentDownloadLink = document.createElement('a');
                documentDownloadLink.href = mockDocumentURL;
                documentDownloadLink.setAttribute('download', props.fileNameWithExtension);
                document.body.appendChild(documentDownloadLink);
                documentDownloadLink.click();
    
                return AgTechHttpResponses.Success(documentBlob);
            }
            else {
                return AgTechHttpResponses.Failed('Download failed.');
            }

        }
    } 
}

/// Internal

const executeGetRequestInternal = <TData, >(props: AgTechInternalAxiosRequestProps<any>) => {
    console.log('Executing GET Request: ' + props.requestURL);
    return axios.get<TData>(props.requestURL, props.requestConfig);
}