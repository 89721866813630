import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlMetadata, AgTechFormControlProps } from "agtech/core/forms/AgTechFormControls";
import { useContext, useRef, useState, useEffect } from "react";

export const AgTechFormColorInputControl = <TFormEntity, >(props: AgTechFormControlProps<TFormEntity, string>) => {

    let formContext = useContext(FormContext);
    let inputRef = useRef<HTMLInputElement>(null);

    let [colorValue, updateColorValue] = useState<string>(props.value(formContext.entity));

    let [field, updateField] = useState<AgTechFormControlMetadata<TFormEntity>>({
        id: props.caption,
        isValid: true,
        validateOnSubmit: (entity: TFormEntity) => validateControlOnFormSubmit(entity),
    });

    let markFieldValid = () => updateField({...field, isValid: true });
    let markFieldInvalid = () => updateField({...field, isValid: false });

    let handleColorChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateColorValue(e.currentTarget.value);
    }

    let validateControlOnFormSubmit = (entity: TFormEntity): string => {
        let fieldValidationWarningMessage = '';
        let controlValue = props.value(entity);

        if (props.required && controlValue === '') {
            fieldValidationWarningMessage = 'Field "' + props.caption + '" is required';
        }
        else if (props.validation?.validateOnSubmit) {
            fieldValidationWarningMessage = props.validation.validateOnSubmit(formContext.entity, controlValue) ?? '';
        }

        if (fieldValidationWarningMessage) {
            markFieldInvalid();
        }

        return fieldValidationWarningMessage;
    }

    let formControlWidth: string = `${props.width ?? 50}%`;
    let formControlStatus: string = field.isValid ? '' : 'invalid';
    let formControlHighlightClass = props.highlighted ? 'highlighted' : '';

    useEffect(() => {
        formContext.registerFormField(field);

        if (props.focused) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, []);

    let handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {

        if (props.onControlValueChanged) {
            let updatedValue = e.currentTarget.value;

            formContext.handleFieldChanged(updatedValue, props.onControlValueChanged);

            if (props.validation?.validateOnFocusOut && !props.validation.validateOnFocusOut(updatedValue)) {
                markFieldInvalid();
            }
            else if (props.required && updatedValue === '') {
                if (field.isValid) {
                    markFieldInvalid();
                }
            }
            else if (!field.isValid) {
                markFieldValid();
            }
        }
    }

    return (
        <div className={`agtech-form-control ${formControlStatus} ${formControlHighlightClass}`} style={{maxWidth: formControlWidth}}>
            <h1 className="agtech-form-control-caption">{props.caption + (props.required ? ' *' : '')}</h1>
            <input type="color" value={colorValue} onChange={handleColorChanged}
                readOnly={props.readonly}
                ref={inputRef}
                onBlur={handleFocusOut} />
        </div>
    );
};