import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechFormReadOnlyTextControl, AgTechFormRow, AgTechFormSection, AgTechFormTextAreaControl, AgTechFormTextControl } from 'agtech/web/components/Forms/AgTechForm'
import AgTechMultiStepFormDialog, { AgTechFormDialogStep } from 'agtech/web/components/Portals/Dialogs/AgTechMultiStepFormDialog'
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities'
import BidderReconciliationDialog from 'app/areas/bidders/dialogs/BidderReconciliationDialog'
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog'
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog'
import SaleDiscountDialog from 'app/areas/discounts/dialogs/SaleDiscountDialog'
import InvoiceCreditsSelectionGrid from 'app/areas/invoices/components/grids/InvoiceCreditsSelectionGrid'
import InvoiceDiscountsSelectionGrid from 'app/areas/invoices/components/grids/InvoiceDiscountsSelectionGrid'
import InvoiceLotSelectionGrid from 'app/areas/invoices/components/grids/InvoiceLotSelectionGrid'
import { CreateInvoiceActionData, SaleParticipant, useInvoiceDataGetLotsToInvoiceRequest, useInvoiceDataCreateInvoiceAction, useInvoiceDataGetNewInvoiceNumberRequest } from 'app/data/invoicing/InvoicingDataActions'
import { SaleLot, LotIndex, useSaleLotData } from 'app/data/lots/LotData'
import { useSaleDiscounts, SaleDiscount } from 'app/data/sales/discounts/SaleDiscountData'
import { ContactCredit } from 'app/data/operation/contacts/credits/ContactDataCredits'
import _ from 'lodash'
import React from 'react'
import { AgTechFormContext } from 'agtech/core/forms/AgTechFormContext'
import { AgTechContact, getContactFullName, useContact, getAvailableContactCredits } from 'app/data/operation/contacts/ContactData'
import InvoiceSplitInvoiceDialog from 'app/areas/invoices/dialogs/InvoiceSplitInvoiceDialog'
import { LotBidderPurchase } from 'app/data/lots/purchases/LotPurchaseData'
import InvoiceInfoDialog from 'app/areas/invoices/dialogs/InvoiceInfoDialog'
import { getCurrentSale } from 'app/data/SalesAppDataStore'
import { AgTechEntitySelection } from 'agtech/web/components/Grids/AgTechGridEntities'
import AgTechFadeInView from 'agtech/web/components/Pages/Loading/AgTechFadeInView'

export type Invoicee = {
    bidderId: number,
    name?: string,
    contactId: number,
    bidderNumber?: string,
}

export type InvoiceCreationDialogProps = {
    invoicee: Invoicee
}

export type SaleInvoiceDialogFormData = {
    invoiceNumber: string,
    checkNumber: string,
    lotIndex: LotIndex,
    bidderId: number,
    contactId: number,
    bidderNumber?: string,
    selectedLots: AgTechEntitySelection<SaleLot>[],
    selectedDiscounts: AgTechEntitySelection<SaleDiscount>[],
    selectedCredits: AgTechEntitySelection<ContactCredit>[],
    date: Date,
    invoiceLotTotal: number,
    invoiceTotal: number,
    discountsTotal: number,
    creditsTotal: number,
    contact: AgTechContact
}

const InvoiceCreationDialog = (props: InvoiceCreationDialogProps) => {
    let invoiceeContact = useContact(props.invoicee.contactId);
    let invoiceeLotsRequest = useInvoiceDataGetLotsToInvoiceRequest();

    let saleBidderDiscounts = useSaleDiscounts();
    let saleBidderCredits = getAvailableContactCredits(invoiceeContact).filter(cd => !cd.invoiceId) ?? [];

    let invoiceCreationSubmission = useInvoiceDataCreateInvoiceAction();
    let invoiceCreationDialogSteps = useInvoiceCreationDialogSteps();
    let invoiceNumberGetter = useInvoiceDataGetNewInvoiceNumberRequest();
    let currentSale = getCurrentSale();

    return (
        <AgTechMultiStepFormDialog
            formData={{
                loadInitialFormData: async requestExecutor => {
                    let invNumber = await requestExecutor.executeRequest(invoiceNumberGetter, currentSale);

                    let invNumFromData = invNumber.success?.data;
                    return await requestExecutor.executeAndConvertRequest({
                        request: invoiceeLotsRequest,
                        inputData: props.invoicee.bidderId,
                        convert: async invoiceeLots => {
                            let lotIndex: LotIndex = {};
    
                            invoiceeLots.forEach(lot => {
                                lotIndex[lot.id.toString()] = {
                                    ...lot
                                };
                            });
                            
                            let invoiceCreationFormData: SaleInvoiceDialogFormData = {
                                invoiceNumber: invNumFromData?.toString() ?? "",
                                checkNumber: '',
                                bidderId: props.invoicee.bidderId,
                                bidderNumber: props.invoicee.bidderNumber,
                                contactId: props.invoicee.contactId,
                                lotIndex: lotIndex,
                                selectedLots: invoiceeLots.map<AgTechEntitySelection<SaleLot>>(lot => ({ id: lot.id, entity: lot, isSelected: true })),
                                selectedCredits: saleBidderCredits.map<AgTechEntitySelection<ContactCredit>>(credit => ({ id: credit.id, entity: credit, isSelected: false })),
                                selectedDiscounts: saleBidderDiscounts.map<AgTechEntitySelection<SaleDiscount>>(discount => ({ id: discount.id, entity: discount, isSelected: false })),
                                invoiceLotTotal: _.sum(invoiceeLots.map(lt => (lt.purchasePrice ?? 0) * (lt.groupSize ?? 1))),
                                invoiceTotal: _.sum(invoiceeLots.map(lt => (lt.purchasePrice ?? 0) * (lt.groupSize ?? 1))),
                                discountsTotal: 0,
                                creditsTotal: 0,
                                date: new Date(),
                                contact: invoiceeContact
                            };

                            return invoiceCreationFormData;
                        }
                    });
                },
                loadingText: 'Loading invoice data...'
            }}
            formAction={{
                dataAction: invoiceCreationSubmission,
                getActionData: d => {
                    let invoiceCreationData: CreateInvoiceActionData = {
                        invoiceNumber: d.invoiceNumber,
                        checkNumber: d.checkNumber,
                        bidderId: d.bidderId,
                        contactId: d.contactId,
                        lots: d.selectedLots.filter(lt => lt.isSelected).map(l => l.entity.id),
                        credits: d.selectedCredits.filter(c => c.isSelected).map(c => c.entity.id),
                        discounts: d.selectedDiscounts.filter(d => d.isSelected).map(d => d.entity.id),
                        date: new Date(),
                        invoiceTotal: d.invoiceTotal
                    };

                    return invoiceCreationData;
                }
            }}
            structure={{
                title: 'Create Invoice - ' + invoiceeContact?.firstName ?? props.invoicee.bidderNumber ?? 'N/A',
                details: `To create a new invoice for the selected bidder, please follow the steps below`,
                width: 860,
                submitButtonText: 'Create Invoice',
                steps: invoiceCreationDialogSteps
            }}
            behavior={{
                confirmation: {
                    getConfirmationPane: (data, actions) => {
                        return (
                            <div className='stretched bg-white absolute container'>
                                <AgTechFadeInView>
                                    <InvoiceInfoDialog
                                        invoiceId={data.responseData.invoiceId}
                                        invoice={{
                                            id: data.responseData.invoiceId,
                                            ...data.actionData,
                                            purchaserName: getContactFullName(invoiceeContact),
                                            invoiceDate: data.actionData.date,
                                            invoiceTotal: data.actionData.invoiceTotal,
                                            numberOfLots: data.actionData.lots.length,
                                            numberOfDiscountsApplied: data.actionData.lots.length,
                                            numberOfCreditsApplied: data.actionData.credits.length
                                        }}
                                        invoiceDocument={data.responseData.invoiceDocument}
                                        actions={actions}
                                        showNextSteps={true}
                                    />
                                </AgTechFadeInView>
                            </div>
                        )
                    }
                }
            }}
        />
    );
}

export const useLotBidderPurchasesForInvoice = (props: { lots: LotIndex }): LotBidderPurchase[] => {
    let lotData = useSaleLotData();
    let lotBidderPurchaseData = lotData.lotBidderPurchases;

    let bidderPurchases = Object.values(lotBidderPurchaseData);
    let lotIdsInInvoice = Object.keys(props.lots);

    let lotBidderPurchasesForInvoice: LotBidderPurchase[] = [];

    // TODO: useCallback
    lotIdsInInvoice.forEach(invoiceLotId => {
        bidderPurchases.forEach(p => {
            if (p[invoiceLotId]) {
                lotBidderPurchasesForInvoice.push(p[invoiceLotId]);
            }
        })
    });

    return lotBidderPurchasesForInvoice;
}

const useInvoiceCreationDialogSteps = (): AgTechFormDialogStep<SaleInvoiceDialogFormData>[] => {
    return [
        {
            name: 'Info & Lots',
            body: formContext => <InvoiceGeneralInfoPage formContext={formContext} />
        },
        {
            name: 'Discounts & Credits',
            body: formContext => <InvoiceDiscountsAndCreditsPage formContext={formContext} />
        },
        {
            name: 'Review & Create',
            body: formContext => <InvoiceReviewAndCompletePage formContext={formContext} />
        }
    ]
}

const InvoiceGeneralInfoPage = (props: { formContext: AgTechFormContext<SaleInvoiceDialogFormData> }) => {
    let invoiceData = props.formContext.entity;
    let invoiceContact = useContact(props.formContext.entity.contactId); 

    return (
        <>
            <AgTechFormSection
                header='General'
                headerContent={() => (
                    <ContactDialog.Button
                        contactId={props.formContext.entity.contactId}
                        classes='thin link'
                    />
                )}
            >
                <AgTechFormRow>
                    <div className='row-based pr-6 container'>
                        <div className='mb-auto'>
                            <AgTechFormTextControl
                                entityType={invoiceData}
                                caption="Invoice Number"
                                required={true}
                                focused={true}
                                value={entity => entity.invoiceNumber}
                                onControlValueChanged={async (e, val) => ({
                                    ...e,
                                    invoiceNumber: val
                                })}
                                width={100}
                            />
                        </div>
                        <div className='mt-auto'>
                            <AgTechFormReadOnlyTextControl
                                entityType={invoiceData}
                                caption='Bidder Number'
                                value={() => props.formContext.entity.bidderNumber ? props.formContext.entity.bidderNumber : 'N/A'}
                                controlStyles={{ width: 100 }}
                            />
                        </div>
                    </div>
                    <AgTechFormTextAreaControl
                        entityType={invoiceData}
                        caption='Contact information'
                        readonly={true}
                        value={() => `${invoiceContact.firstName + ' ' + invoiceContact.lastName} ${invoiceContact.ranchName ? ' - ' + invoiceContact.ranchName : ''}\n${invoiceContact.emailAddress}${invoiceContact.phoneNumber ? ' | ' + invoiceContact.phoneNumber : ''}\n\n${invoiceContact.address}\n${invoiceContact.city}, ${invoiceContact.stateAbbreviation} ${invoiceContact.zip}`}
                        width={100}
                        rows={5}
                        resizable={false}
                        styles={{ lineHeight: '20px' }}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
            <AgTechFormSection
                header={`Lots purchased (${props.formContext.entity.selectedLots.length})`}
                classes='flexed'
                bodyClasses='flexed pt-3'
                headerContent={() => (
                    <InvoiceSplitInvoiceDialog.Button
                        text='Split lots'
                        classes='thin link'
                        invoiceData={props.formContext.entity}
                        role='OA'
                    />
                )}
            >
                <InvoiceLotSelectionGrid
                    formContext={props.formContext}
                />
            </AgTechFormSection>
        </>
    )
}

const InvoiceDiscountsAndCreditsPage = (props: { formContext: AgTechFormContext<SaleInvoiceDialogFormData> }) => {
    let invoiceData = props.formContext.entity;
    let saleBidderContact = props.formContext.entity.contact;

    return (
        <>
            <AgTechFormSection
                header={`Discounts (${invoiceData.selectedDiscounts.length})`}
                classes='flexed'
                bodyClasses='flexed py-3'
                headerContent={() => <SaleDiscountDialog.Button classes='thin link' />}
            >
                <InvoiceDiscountsSelectionGrid formContext={props.formContext} />
            </AgTechFormSection>
            <AgTechFormSection
                header={`Credits (${invoiceData.selectedCredits.length})`}
                classes='flexed'
                bodyClasses='flexed py-3'
                headerContent={() => (
                    <ContactCreditDialog.Button
                        contactId={saleBidderContact.id}
                        classes='thin link'
                    />
                )}
            >
                <InvoiceCreditsSelectionGrid formContext={props.formContext} />
            </AgTechFormSection>
        </>
    )
}

const InvoiceReviewAndCompletePage = (props: { formContext: AgTechFormContext<SaleInvoiceDialogFormData> }) => {
    let invoiceData = props.formContext.entity;
    let invoiceSubmissionData = props.formContext.entity;
    let saleBidderContact = invoiceSubmissionData.contact;

    let selectedLots = invoiceSubmissionData.selectedLots.filter(l => l.isSelected);
    let selectedCredits = invoiceSubmissionData.selectedCredits.filter(c => c.isSelected);
    let selectedDiscounts = invoiceSubmissionData.selectedDiscounts.filter(d => d.isSelected);

    let sumOfSelectedLots = _.sum(selectedLots.map(l => (l.entity.purchasePrice ?? 0) * (l.entity.groupSize ?? 1)));
    let sumOfCreditsApplied = _.sum(selectedCredits.map(l => l.entity.creditAmount ?? 0));
    let sumOfDiscountsGiven = calculateDiscountTotal(sumOfSelectedLots, selectedDiscounts);
    let sumOfInvoice = sumOfSelectedLots - sumOfCreditsApplied - sumOfDiscountsGiven;

    props.formContext.entity.invoiceLotTotal = sumOfInvoice;

    return (
        <>
            <AgTechFormSection header="Invoice Information">
                <AgTechFormRow>
                    <div className='row-based tall pr-6 container'>
                        <div className='mb-auto'>
                            <AgTechFormReadOnlyTextControl
                                entityType={invoiceData}
                                caption="Invoice Number"
                                value={entity => entity.invoiceNumber}
                                controlStyles={{ width: 100 }}
                            />
                        </div>
                        <div className='mt-auto'>
                            <AgTechFormReadOnlyTextControl
                                entityType={invoiceData}
                                caption='Bidder Number'
                                value={() => invoiceData.bidderNumber ? invoiceData.bidderNumber : 'N/A'}
                                controlStyles={{ width: 100 }}
                            />
                        </div>
                    </div>
                    <AgTechFormTextAreaControl
                        entityType={invoiceData}
                        caption='Contact information'
                        readonly={true}
                        value={data => `${data.contact.firstName + ' ' + data.contact.lastName} ${data.contact.ranchName ? ' - ' + data.contact.ranchName : ''}\n${data.contact.emailAddress}${data.contact.phoneNumber ? ' | ' + data.contact.phoneNumber : ''}\n\n${data.contact.address}\n${data.contact.city}, ${data.contact.stateAbbreviation} ${data.contact.zip}`}
                        width={100}
                        rows={5}
                        resizable={false}
                        styles={{ lineHeight: '20px' }}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
            <AgTechFormSection header='Invoice summary'>
                <AgTechFormRow>
                    <AgTechFormReadOnlyTextControl
                        entityType={invoiceSubmissionData}
                        caption='Lots sold'
                        value={e => AgTechMoneyFormatter.format(sumOfSelectedLots)}
                        classes='snug'
                    />
                    <h1 className='pt-4 px-3'>-</h1>
                    <AgTechFormReadOnlyTextControl
                        entityType={invoiceSubmissionData}
                        caption='Discounts Given'
                        value={e => AgTechMoneyFormatter.format(sumOfDiscountsGiven)}
                        classes='snug'
                    />
                    <h1 className='pt-4 px-3'>-</h1>
                    <AgTechFormReadOnlyTextControl
                        entityType={invoiceSubmissionData}
                        caption='Credits Applied'
                        value={e => AgTechMoneyFormatter.format(sumOfCreditsApplied)}
                        classes='snug'
                    />
                    <h1 className='pt-4 px-3'>=</h1>
                    <AgTechFormReadOnlyTextControl
                        entityType={invoiceSubmissionData}
                        caption='Invoice Amount'
                        value={e => AgTechMoneyFormatter.format(sumOfInvoice)}
                        classes='snug'
                    />
                </AgTechFormRow>
            </AgTechFormSection>
            <AgTechFormSection
                header={`Lots Sold (${selectedLots.length})`}
                classes='flexed'
                bodyClasses='flexed py-3'
            >
                <InvoiceLotSelectionGrid formContext={props.formContext} />
            </AgTechFormSection>
        </>
    )
}

const calculateDiscountTotal = (sumOfLotsSold: number, selectedDiscounts: AgTechEntitySelection<SaleDiscount>[]): number => {
    let invoiceDiscountTotal = 0;

    selectedDiscounts.filter(d => d.isSelected).forEach(d => {
        let discountAmount = d.entity.isPercentage
            ? ((d.entity.amount / 100.0) * sumOfLotsSold)
            : d.entity.amount;

        invoiceDiscountTotal += discountAmount;
    });

    return invoiceDiscountTotal;
}

InvoiceCreationDialog.Button = (props: { invoicee: SaleParticipant } & AgTechButtonProps) => {
    return (
        <AgTechButton
            text='Invoice'
            icon='plus'
            {...props}
            action={async e => {
                if (props.invoicee.contactId) {
                    let invoiceeContactId = props.invoicee.contactId;

                    e.dialogs.openDialog({
                        dialog: () => <InvoiceCreationDialog invoicee={{ ...props.invoicee, contactId: invoiceeContactId }} />
                    });
                }
                else {
                    e.confirmations.askForConfirmation({
                        content: {
                            header: `Bidder ${props.invoicee.bidderNumber} is not registered in this sale.`,
                            details: 'Please register this bidder before invoicing'
                        },
                        actions: {
                            onConfirm: async () => {
                                let reconciledContactId = await new Promise<number>((res) => {
                                    BidderReconciliationDialog.Open(e, {
                                        bidderNumber: props.invoicee.bidderNumber ?? '',
                                        onBidderReconciled: async reconciledBidderContact => {
                                            res(reconciledBidderContact.id);
                                        }
                                    });
                                });

                                e.dialogs.openDialog({
                                    dialog: () => (
                                        <InvoiceCreationDialog
                                            invoicee={{
                                                ...props.invoicee,
                                                contactId: reconciledContactId
                                            }}
                                        />
                                    )
                                });

                                return true;
                            }
                        }
                    })
                }
            }}
        />
    )
}

export default InvoiceCreationDialog