import { AgTechDataAction, DataActionResults } from "agtech/core/data/actions/AgTechDataActions";
import { useAgTechHttpPostRequest, useAgTechHttpSubmitEntityRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { SaleDiscount, getSaleDiscounts, saleDiscountSlice } from "app/data/sales/discounts/SaleDiscountData";
import { useDispatch } from "react-redux";

export const useSaleDiscountDataRefreshDiscountsAction = (): AgTechDataAction<SaleDiscount[], SaleDiscount[]> => {
    return {
        name: 'RefreshDiscounts',
        action: async props => {
            return DataActionResults.Success(props.submittedEntity);
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDiscountSlice.actions.refreshSaleDiscounts, props.submittedEntity);
        }
    }
}

export const useSaleDiscountDataWriteDiscountAction = (): AgTechDataAction<SaleDiscount, SaleDiscount> => {
    let saleDiscountSubmitRequest = useAgTechHttpSubmitEntityRequest<SaleDiscount>();

    return {
        name: 'WriteDiscount',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity ? 'Updating discount...' : 'Creating discount...',
            actionConfirmationMessage: `Successfully ${props.originalEntity ? 'updated' : 'created' } discount`
        }),
        validate: async props  => {
            if (props.submittedEntity.amount <= 0) {
                props.validation.failWithWarning("Please specify a valid discount amount");
            }

            if (props.submittedEntity.isPercentage && props.submittedEntity.amount > 100) {
                props.validation.failWithWarning(props.submittedEntity.amount + '% is not a valid percentage');
            }

            let saleDiscountWithSameName = getSaleDiscounts().filter(disc =>
                disc.name === props.submittedEntity.name &&
                disc.id !== props.submittedEntity.id);

            if (saleDiscountWithSameName && saleDiscountWithSameName.length > 0) {
                props.validation.failWithWarning(`A discount with name "${props.submittedEntity.name}" already exists. Please choose a different name.`);
            }
        },
        action: async props => {
            return await saleDiscountSubmitRequest.post({
                path: 'Discounts',
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDiscountSlice.actions.writeSaleDiscount, props.responseData);
        }
    }
}

export const useSaleDiscountDataDeleteDiscountAction = (): AgTechDataAction<SaleDiscount, boolean> => {
    let discountDeletionRequest = useAgTechHttpPostRequest<SaleDiscount, boolean>();

    return {
        name: 'DeleteDiscount',
        getConfiguration: () => ({
            actionExecutionMessage: 'Deleting discount...',
            actionConfirmationMessage: `Successfully deleted discount`
        }),
        confirm: async props => {
            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: `Are you sure you want to delete discount "${props.submittedEntity.name}" from the system?`,
                    details: "This discount will no longer be available when creating invoices, but existing invoices with this discount will not be changed",
                    width: '500px',
                    confirmationButtonText: 'Delete Discount'
                },
                submission: {
                    actionExecutionMessage: 'Deleting discount...',
                    actionConfirmationMessage: 'Discount deleted'
                }
            })
        },
        action: async props => {
            return await discountDeletionRequest.post({
                path: 'Discounts/Delete',
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDiscountSlice.actions.deleteSaleDiscount, props.submittedEntity);
        }
    }
}