import { AgTechBadge } from 'agtech/web/components/Badges/AgTechBadges';
import AgTechTitleDetailContentCard from 'agtech/web/components/Cards/AgTechTitleDetailContentCard';
import AgTechCardCollection from 'agtech/web/components/Cards/Collections/AgTechCardCollection';
import { AgTechFormReadOnlyTextControl, AgTechFormRow } from 'agtech/web/components/Forms/AgTechForm';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import AgTechFastTabList, { AgTechFastTab } from 'agtech/web/components/Lists/AgTechFastTabList';
import AgTechListDetailPage from 'agtech/web/components/Pages/List/AgTechListDetailPage';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import { AgTechContactActionButton, ContactCreditActionButton, ContactNoteActionButton } from 'app/areas/contacts/components/buttons/ContactButtons';
import ContactNoteActionMenu from 'app/areas/contacts/components/menus/ContactNoteActionMenu';
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog';
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog';
import ContactNoteDialog from 'app/areas/contacts/dialogs/notes/ContactNoteDialog';
import { AgTechContact, useContactCreditsNotInvoiced, useContactData, getContactNotes, useContacts, getAvailableContactCredits } from 'app/data/operation/contacts/ContactData'
import dayjs from 'dayjs';
import React from 'react'

const ContactListDetailPage = () => {
    let contacts = useContacts();

    return (
        <div className='stretched non-scrollable container'>
            <AgTechListDetailPage
                items={contacts}
                itemSurface={cnt => <ContactDetailPageSurface contact={cnt} />}
                itemConfig={{
                    height: 80,
                    width: 280,
                    content: cnt => (
                        <AgTechTitleDetailHeader
                            title={cnt.firstName + ' ' + cnt.lastName}
                            detail={cnt.ranchName}
                            detailMargin={10}
                            content={() => <AgTechContactActionButton contactId={cnt.id} />}
                            titleFontSize='font-size-standard'
                            detailFontSize='font-size-small'
                        />
                    ),
                }}
                searchConfig={{
                    placeholder: 'Search contacts...',
                    options: [{ field: 'Name', value: cnt => cnt.firstName + ' ' + cnt.lastName },
                    {field: 'Ranch Name', value: cnt => cnt.ranchName},
                    {field: 'Phone Number', value: cnt => cnt.phoneNumber}]
                }}
                noRecordsConfig={{
                    message: "We're sorry, there are no contacts in the system for this operation",
                    action: {
                        prompt: 'To create a contact, please click the button below',
                        button: () => <ContactDialog.Button />
                    }
                }}
            />
        </div>
    )
}

export const ContactDetailPageSurface = (props: { contact: AgTechContact }) => {
    let contact = useContactData().contacts[props.contact.id];

    return (
        <div className='row-based stretched non-scrollable fade-in-400 container'>
            <AgTechFastTabList
                tabs={[
                    { title: 'General Information', content: () => <ContactGeneralInformationTab contact={contact} />},
                    ContactCreditsFastTab(contact),
                    ContactNotesFastTab(contact)
                ]}
            />
        </div>
    )
}

const ContactGeneralInformationTab = (props: { contact: AgTechContact }) => {
    return (
        <div className='row-based stretched container'>
            <AgTechFormRow>
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='First Name'
                    value={cnt => cnt.firstName}
                />
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='Last Name'
                    value={cnt => cnt.lastName}
                />
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='Ranch Name'
                    value={cnt => cnt.ranchName}
                />
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='Email Address'
                    value={cnt => cnt.emailAddress}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='Phone Number'
                    value={cnt => cnt.phoneNumber}
                />
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='Address'
                    value={cnt => cnt.address}
                />
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='City'
                    value={cnt => cnt.city}
                />
                <AgTechFormReadOnlyTextControl
                    entityType={props.contact}
                    entity={props.contact}
                    caption='Zip Code'
                    value={cnt => cnt.zip}
                />
            </AgTechFormRow>
        </div>
    )
}

const ContactCreditsFastTab = (contact: AgTechContact): AgTechFastTab => {
    let creditsNotInvoiced = getAvailableContactCredits(contact);

    return {
        title: 'Available Credits',
        tabHeight: 200,
        content: () => (
            <div className='row-based stretched py-2 container'>
                <AgTechCardCollection
                    entities={creditsNotInvoiced}
                    card={credit => (
                        <AgTechTitleDetailContentCard
                            title={AgTechMoneyFormatter.format(credit.creditAmount)}
                            headerContent={() => <ContactCreditActionButton contactId={contact.id} creditId={credit.id} />}
                            width={200}
                        >
                            <h1 className='font-size-small font-bold'>
                                {credit.description === '' ? 'No notes' : credit.description}
                            </h1>
                            <div className='row-based-vertically-centered wide mt-auto container'>
                                {credit.expires ? (
                                    <AgTechBadge text={`Expires: ${dayjs(credit.expires).format('MM/DD/YYYY')}`} />
                                ) : null}
                            </div>
                        </AgTechTitleDetailContentCard>
                    )}
                    classes='tall'
                    noRecordConfig={{
                        message: 'There are no credits in the system for this contact',
                        action: {
                            prompt: 'To create one, click the button below',
                            button: () => (
                                <ContactCreditDialog.Button
                                    contactId={contact.id}
                                    classes='highlight'
                                />
                            )
                        }
                    }}
                />
            </div>
        ),
        actionPane: () => creditsNotInvoiced.length > 0 ? (
            <div className='agtech-action-pane'>
                <ContactCreditDialog.Button
                    contactId={contact.id}
                    classes='link thin font-size-standard'
                />
            </div>
        ) : null
    }
}

const ContactNotesFastTab = (contact: AgTechContact): AgTechFastTab => {
    let contactNotes = getContactNotes(contact);

    return {
        title: 'Notes',
        tabHeight: 200,
        content: () => (
            <div className='row-based stretched py-2 container'>
                <AgTechCardCollection
                    entities={contactNotes}
                    card={note => (
                        <AgTechTitleDetailContentCard
                            title={dayjs(note.date).format("dddd - MM/DD/YYYY")}
                            headerContent={() => <ContactNoteActionButton contactId={contact.id} noteId={note.id} />}
                            width={200}
                            classes='tall'
                        >
                            <h1 className='font-size-small font-bold'>{note.note}</h1>
                        </AgTechTitleDetailContentCard>
                    )}
                    classes='tall'
                    noRecordConfig={{
                        message: 'There are no notes in the system for this contact',
                        action: {
                            prompt: 'To create one, click the button below',
                            button: () => (
                                <ContactNoteDialog.Button
                                    contactId={contact.id}
                                    classes='highlight'
                                />
                            )
                        }
                    }}
                />
            </div>
        ),
        actionPane: () => contactNotes.length > 0 ? (
            <div className='agtech-action-pane'>
                <ContactNoteDialog.Button
                    contactId={contact.id}
                    classes='link thin font-size-standard'
                />
            </div>
        ) : null
    }
}

export default ContactListDetailPage