import { AgTechGridEntity } from "agtech/web/components/Grids/Data/AgTechGridEntities"
import { IAgTechGridItem } from "agtech/web/components/Grids/Data/AgTechGridData"
import { CSSProperties } from "react"

export type AgTechGridItemGroup<TItem extends IAgTechGridItem, TGroupKey> = {
    groupKey: TGroupKey,
    itemsInGroup: AgTechGridEntity<TItem>[],
    groupText?: (itemGroupKey: TGroupKey, itemsInGroup: AgTechGridEntity<TItem>[]) => string,
    groupHeader?: (itemGroupKey: TGroupKey, itemsInGroup: AgTechGridEntity<TItem>[]) => React.ReactNode
}

export type AgTechGridGroupingConfiguration<TItem extends IAgTechGridItem, TGroupKey> = {
    group: (item: TItem) => TGroupKey,
    groupText?: (itemGroupKey: TGroupKey, itemsInGroup: AgTechGridEntity<TItem>[]) => string,
    groupHeader?: (itemGroupKey: TGroupKey, itemsInGroup: AgTechGridEntity<TItem>[]) => React.ReactNode,
    offset?: number
}

/// Grid Header Component

export type AgTechGridGroupHeaderRowProps<TItem extends IAgTechGridItem, TGroupKey> = {
    groupKey: any,
    itemsInGroup: AgTechGridEntity<TItem>[],
    groupText?: (itemGroupKey: TGroupKey, itemsInGroup: AgTechGridEntity<TItem>[]) => string,
    header?: (itemGroupKey: TGroupKey, itemsInGroup: AgTechGridEntity<TItem>[]) => React.ReactNode,
    style: CSSProperties
}

export const AgTechGridGroupHeaderRow = <TItem extends IAgTechGridItem, TGroupKey>(props: AgTechGridGroupHeaderRowProps<TItem, TGroupKey>) => {
    return (
        <div className={`agtech-grid-surface-body-row agtech-grid-surface-body-group-header`} style={props.style}>
            {props.header ? props.header(props.groupKey, props.itemsInGroup) : (
                <div className="column-based-vertically-centered font-bold font-size-small px-3 container">
                    {props.groupText ? props.groupText(props.groupKey, props.itemsInGroup) : props.groupKey.toString()}
                </div>
            )}
        </div>
    )
}

export const AgTechGridGroupHeaderBadge = (props: { text: string, width?: number, classes?: string }) => {
    return (
        <h1
            className={`tall centered font-size-small font-bold px-4 mr-3 container ${props.classes ?? ''}`}
            style={{ width: props.width ?? undefined }}
        >
            {props.text}
        </h1>
    )
}