import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechFormRow, AgTechFormTextControl, AgTechFormTextAreaControl, AgTechFormSection, AgTechFormNumberControl, AgTechFormReadOnlyTextControl, AgTechFormToggleControl } from 'agtech/web/components/Forms/AgTechForm';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import { LotCategoryDropdown } from 'app/areas/lots/components/forms/LotCategoryDropdown';
import { useSaleBidderData } from 'app/data/bidders/BidderData';
import { SaleLot, useSaleLotData, DefaultSaleLot, isLotInvoiced, getLotStatus } from 'app/data/lots/LotData';
import { useLotDataWriteLotAction } from 'app/data/lots/LotDataActions';
import _ from 'lodash';
import React, { useState } from 'react'

export type LotDialogProps = {
    lotId?: number
}

const LotDialog = (props: LotDialogProps) => {
    let lotData = useSaleLotData();
    let lots = Object.values(lotData.lots);
    let lotSubmission = useLotDataWriteLotAction();
    let saleBidderData = useSaleBidderData();

    let nextSaleOrder = _.max(lots.map(lt => lt.saleOrder)) ?? 0;
    let nextLotNumber = _.max(lots.map(lt => parseInt(lt.lotNumber))) ?? 0;

    nextSaleOrder++;
    nextLotNumber++;

    let lotEntity = props.lotId ? lotData.lots[props.lotId] : {
        ...DefaultSaleLot,
        lotNumber: nextLotNumber.toString(),
        saleOrder: nextSaleOrder
    };

    let [groupSizeVisible, setGroupSizeVisibility] = useState<boolean>(lotEntity.groupSize !== undefined && lotEntity.groupSize > 0);

    let lotFormEntity = {
        ...lotEntity,
        bidderSoldTo: lotEntity.bidderNumber ? saleBidderData.bidderDetails[lotEntity.bidderNumber] : undefined
    }

    return (
        <AgTechFormDialog
            structure={{
                header: props.lotId ? 'Update Lot - ' + lotEntity.lotNumber : 'Create Lot',
                submitButtonText: props.lotId ? 'Update Lot' : 'Create Lot',
                width: 500
            }}
            formData={{
                loadInitialFormData: () => lotFormEntity
            }}
            formAction={{
                dataAction: lotSubmission,
                getActionData: d => {
                    if (!groupSizeVisible) {
                        d.groupSize = undefined;
                    }

                    return d;
                }
            }}
            refreshWhenChanged={groupSizeVisible}
        >
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={lotFormEntity}
                    caption="Lot Number"
                    required={true}
                    focused={true}
                    value={entity => entity.lotNumber}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        lotNumber: val
                    })}
                    validation={{
                        validateOnSubmit: (lot, val) => {
                            return val.length > 10 ? 'A lot number cannot exceed 10 characters in length' : '';
                        }
                    }}
                />
                <AgTechFormNumberControl
                    entityType={lotFormEntity}
                    caption="Sale Order"
                    value={entity => entity.saleOrder}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        saleOrder: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextAreaControl
                    entityType={lotFormEntity}
                    caption="Description"
                    value={entity => entity.lotDescription}
                    width={100}
                    rows={4}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        lotDescription: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <LotCategoryDropdown
                    entityType={lotFormEntity}
                    caption='Category'
                    getSelectedLotCategoryId={lotFormEntity => lotFormEntity.lotCategoryId}
                    onLotCategorySelected={async (lot, value) => {
                        return ({
                            ...lot,
                            lotCategoryId: value?.id,
                        });
                    }}
                    width={65}
                />
                <AgTechFormReadOnlyTextControl
                    entityType={lotFormEntity}
                    caption="Status"
                    value={e => getLotStatus(e)}
                    controlStyles={{ width: 35 }}
                />
            </AgTechFormRow>
            <AgTechFormSection header='Group Information'>
                <AgTechFormRow>
                    <AgTechFormToggleControl
                        entityType={lotEntity}
                        caption='Is Group'
                        value={entity => groupSizeVisible}
                        onControlValueChanged={async (entity, value) => {
                            setGroupSizeVisibility(value);
                            return entity;
                        }}
                    />
                    <AgTechFormNumberControl
                        entityType={lotEntity}
                        caption='Size'
                        value={entity => entity.groupSize ?? 0 }
                        readonly={!groupSizeVisible}
                        onControlValueChanged={async (ent, val) => ({
                            ...ent,
                            groupSize: val
                        })}
                        />
                </AgTechFormRow>
            </AgTechFormSection>
            {/* <AgTechFormSection header='Sale'>
                <AgTechFormRow>
                    <AgTechFormTextControl
                        entityType={lotFormEntity}
                        caption="Bidder Number"
                        value={entity => entity.bidderNumber ?? ''}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            bidderNumber: val,
                            bidderSoldTo: val ? saleBidderData.bidderDetails[val] : undefined
                        })}
                        validateOnSubmit={(lot, val) => {
                            let validationMessage = '';

                            if (lot.purchasePrice && !val) {
                                validationMessage = "Please enter a bidder number in order to mark this lot as sold";
                            }

                            return validationMessage;
                        }}
                    />
                    <AgTechFormNumberControl
                        entityType={lotFormEntity}
                        caption="Purchase Price"
                        unit="$"
                        value={entity => entity.purchasePrice ?? 0}
                        validateOnSubmit={(lot, val) => {
                            let validationMessage = '';

                            if (lot.bidderNumber && (!val || val <= 0)) {
                                validationMessage = "Please enter a purchase price in order to mark this lot as sold";
                            }

                            return validationMessage;
                        }}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            purchasePrice: val
                        })}
                    />
                </AgTechFormRow>
                <AgTechFormRow>
                    <AgTechFormReadOnlyTextControl
                        entityType={lotFormEntity}
                        caption="Purchasing Contact"
                        value={e => e.bidderSoldTo ? e.bidderSoldTo.firstName + ' ' + e.bidderSoldTo.lastName : ''}
                        onClick={(e, entity) => ContactLinkClick(e, entity.bidderSoldTo)}
                        showPopUpOnHover={entity => entity.bidderSoldTo ? ContactLinkHover(entity.bidderSoldTo) : undefined}
                    />
                    <AgTechFormReadOnlyTextControl
                        entityType={lotFormEntity}
                        caption="Ranch Name"
                        value={e => e.bidderSoldTo ? e.bidderSoldTo.ranchName : ''}
                    />
                </AgTechFormRow>
            </AgTechFormSection> */}
        </AgTechFormDialog>
    )
};

LotDialog.Open = (e: AgTechClickEventHandler, props: LotDialogProps) => {
    e.dialogs.openDialog({
        dialog: () => <LotDialog lotId={props.lotId} />
    });
}

LotDialog.Button = (props: LotDialogProps & AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        text: props.lotId ? 'Update Lot' : 'Create New Lot',
        icon: props.lotId ? 'edit' : 'plus',
        ...props,
        action: e => LotDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default LotDialog;