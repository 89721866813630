export type AgTechSideNavigationAppLogoProps = {
    appIcon: string,
    appName: string,
    isCollapsed: boolean
}

const AgTechSideNavigationAppLogo = (props: AgTechSideNavigationAppLogoProps) => {
    return (
        <div className='agtech-app-side-navigation-logo'>
            {props.isCollapsed ? (
                <i className={`agtech-icon fas fa-${props.appIcon}`} />
            ) : props.appName}
        </div>
    )
}

export default AgTechSideNavigationAppLogo;