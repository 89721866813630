import { AgTechButtonProps, AgTechFileUploadButton } from 'agtech/web/components/Buttons/AgTechButtons'
import React from 'react'

export type OperationUploadLogoButtonProps = AgTechButtonProps & {
    onLogoUpdated: (uploadedLogo: File) => Promise<void>
}

const OperationUploadLogoButton = (props: OperationUploadLogoButtonProps) => {    
    const onLogoUploaded = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget && e.currentTarget.files) {
            let file = e.currentTarget.files[0];

            if (file) {
                await props.onLogoUpdated(file);
            }            
        }
    }
    
    return (
        <AgTechFileUploadButton
            text='Upload Logo'
            icon='upload'
            acceptTypes='image/png, image/jpg, image/jpeg'
            {...props}
            onFileUploaded={onLogoUploaded}
        />
    )
}

export default OperationUploadLogoButton