import AgTechSideNavigationMenuItem, { AgTechSideNavigationMenuItemProps } from "agtech/web/components/App/Navigation/Controls/AgTechSideNavigationMenuItem";
import AgTechPopUpOnHoverContainer from "agtech/web/components/Portals/Popups/AgTechPopUpOnHoverContainer";
import { PopUpContext } from "agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData";
import { useState, useEffect, useContext } from "react";

export type AgTechSideNavigationSubMenuProps = {
    text: string,
    icon: string,
    menuItems: AgTechSideNavigationMenuItemProps[],
    isCollapsed: boolean
}

const AgTechSideNavigationSubMenu = (props: AgTechSideNavigationSubMenuProps) => {
    let [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (props.isCollapsed) {
            setIsOpen(false);
        }
    }, [props.isCollapsed])

    let isMenuActive = props.menuItems.findIndex(mn => {
        return mn.link === window.location.pathname;
    }) >= 0;

    let isMenuOpen = isOpen || (isMenuActive && !props.isCollapsed);

    let isOpenClass = isMenuOpen ? 'open' : '';
    let isActiveClass = isMenuActive && props.isCollapsed ? 'active' : '';

    return (
        <div className={`agtech-side-navigation-sub-menu ${isOpenClass} ${isActiveClass}`}>
            {props.isCollapsed ? (
                <AgTechPopUpOnHoverContainer
                    classes='agtech-side-navigation-sub-menu-header'
                    popupRenderProps={() => ({
                        popup: () => <AgTechSideNavigationSubMenuPopUp {...props} />,
                        config: {
                            fadeInTime: '200ms'
                        },
                        handlers: {
                            onPopUpOpened: () => {
                                setIsOpen(true);
                            },
                            onPopUpClosed: () => {
                                setIsOpen(false);
                            }
                        }
                    })}
                    children={() => (
                        <i className={`agtech-side-navigation-item-icon fas fa-${props.icon}`} aria-hidden="true"></i>
                    )}
                />
            ) : (
                <div
                    className='agtech-side-navigation-sub-menu-header'
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <i className={`agtech-side-navigation-item-icon fas fa-${props.icon}`} aria-hidden="true"></i>
                    {props.text}
                    <i className={`agtech-side-navigation-sub-menu-header-icon fas fa-chevron-right`} aria-hidden="true"></i>
                </div>
            )}
            
            {props.isCollapsed ? null : (
                <div className='agtech-side-navigation-sub-menu-body' style={{ height: isMenuOpen ? (props.menuItems.length * 56) : 0 }}>
                    {props.menuItems.map(menuItem => <AgTechSideNavigationMenuItem key={menuItem.text} {...menuItem} />)}
                </div>
            )}
        </div>
    )
}

const AgTechSideNavigationSubMenuPopUp = (props: AgTechSideNavigationSubMenuProps) => {
    let popupContext = useContext(PopUpContext);

    return (
        <div
            className='agtech-side-navigation-sub-menu-body'
            style={{
                height: props.menuItems.length * 56,
                width: 200
            }}
        >
            {props.menuItems.map(menuItem => (
                <AgTechSideNavigationMenuItem
                    key={menuItem.text}
                    {...menuItem}
                    isCollapsed={false}
                    onSelected={link => {
                        menuItem.onSelected(link);
                        
                        if (popupContext) {
                            popupContext.closePopUp();
                        }
                    }}
                />
            ))}
        </div>
    )
}

export default AgTechSideNavigationSubMenu;