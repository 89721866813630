export interface IAgTechAppDialogs {
    openDialog: (dialog: AgTechDialogRenderProps) => void,
    closeDialog: (dialogId: string) => void
}

export const DefaultAgTechAppDialog: IAgTechAppDialogs = {
    openDialog: () => {
        console.error('Dialog opened from default context');
    },
    closeDialog: () => {
        console.error('Attempted to close dialog from default context');
    }
}

export type AgTechDialogRenderProps = {
    dialog: () => React.ReactNode,
    onDialogSubmit?: () => Promise<void>
}

export type AgTechFormDialogRenderProps = {
    dialog: () => React.ReactNode
}

export type AgTechDialogRendering = AgTechDialogRenderProps & {
    dialogId: string
}