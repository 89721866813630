import React from 'react'
import { useAgTechWebAppContext } from '../App/AgTechWebAppContext';
import { AgTechButton } from '../Buttons/AgTechButtons';
import AgTechTitleDetailHeader from '../Headers/AgTechTitleDetailHeader';
import { useAgTechAuthContext } from 'agtech/core/auth/AgTechAuthContext';
import { AgTechPopUpPosition } from '../Portals/Popups/Helpers/AgTechPopUpData';
import AgTechPopUpOnHoverContainer from '../Portals/Popups/AgTechPopUpOnHoverContainer';
import AgTechFutureLoadingSurface from '../Pages/Loading/AgTechFutureLoadingSurface';
import AgTechClickableContainer from '../Buttons/AgTechClickableContainer';
import { AgTechIconBadge } from '../Icons/AgTechIcons';

let AgTechHeaderUserDetail = () => {
    let authContext = useAgTechAuthContext();
    let loggedInUser = authContext.user;
    let isSystemAdmin = loggedInUser?.role.findIndex(f => f === 'Admin') > -1;
    var userRole = 'User';

    if (isSystemAdmin) {
        userRole = 'Sys Admin';
    }
    else if (loggedInUser?.role.findIndex(f => f ==='OA') > -1) {
        userRole = 'Administrator';
    }

    return loggedInUser ? (
        <AgTechClickableContainer
            classes='tall column-based clickable centered px-2 py-2 container'
            styles={{ minWidth: 220 }}
            onClick={e => e.popups.showPopUp({
                callerRef: e.clickableRef,
                popup: () => <AgTechUserDetailPopup />,
                config: {
                    position: AgTechPopUpPosition.BelowCentered
                }
            })}
        >
            <AgTechIconBadge icon='user' size={32} classes='mr-4 font-size-small' />
            <AgTechTitleDetailHeader
                title={loggedInUser.name}
                detail={userRole}
                titleFontSize='font-size-small'
                detailMargin={2}
                detailFontSize='font-size-very-small'
            />
        </AgTechClickableContainer>
    ) : null;
}

export const AgTechUserDetailPopup = () => {
    let appContext = useAgTechWebAppContext();
    let authContext = useAgTechAuthContext();
    let loggedInUser = authContext.user;

    return (
        <div className='row-based container' style={{ width: 360 }}>
            <AgTechFutureLoadingSurface>
                <AgTechTitleDetailHeader
                    title={loggedInUser.name}
                    detail={loggedInUser.role.reduce(c => c + ' ')}
                    icon='user'
                    iconSize={40}
                    classes='p-4'
                    iconClasses='mr-4'
                />
                <div className="px-4 py-3 column-based-vertically-centered top-bordered container">
                    <AgTechButton
                        text="Logout"
                        classes='ml-auto highlight'
                        action={() => {
                            appContext.confirmations.askForConfirmation({
                                content: {
                                    header: "Are you sure you want to log out?",
                                    confirmationButtonText: "Log Out"
                                },
                                actions: {
                                    onCancel: () => {},
                                    onConfirm: async () => {
                                        await authContext.logoutUser();
                                        return true;
                                    }
                                }
                            })
                        }}
                    />
                </div>
            </AgTechFutureLoadingSurface>
        </div>
    )
}

export default AgTechHeaderUserDetail;