import { AgTechDataActionConfiguration } from "agtech/core/data/actions/AgTechDataActions"
import { logDev } from "agtech/core/logging/AgTechLogger"

export interface IAgTechAppConfirmations {
    askForConfirmation: (confirmationData: AgTechConfirmationProps) => Promise<boolean>,
}

export type AgTechConfirmationProps = {
    content: AgTechConfirmationContent,
    actions?: AgTechConfirmationAction,
    submission?: AgTechDataActionConfiguration
}

export type AgTechConfirmationContent = {
    header: string,
    details?: string,
    body?: () => React.ReactNode,
    cancelButtonText?: string,
    confirmationButtonText?: string,
    width?: string
}

export type AgTechConfirmationAction = {
    onConfirm?: () => Promise<boolean>,
    onCancel?: () => void
}

export const DefaultAgTechConfirmations: IAgTechAppConfirmations = {
    askForConfirmation: async () => {
        logDev('Asked for confirmation from default context');
        return false;
    }
}

export const DefaultAgTechConfirmationActions = {
    onConfirm: async () => true,
    onCancel: async () => false
}