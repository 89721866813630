import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink'
import BidderLotsListPopUp from 'app/areas/lots/components/popups/BidderLotsListPopUp'
import React from 'react'

const BidderListPopUpLink = (props: { bidderId: number, content: string, isClickable: boolean }) => {
    return (
        <AgTechGridLink
            content={props.content}
            onHover={props.isClickable ? () => ({
                popup: () => <BidderLotsListPopUp bidderId={props.bidderId} />,
                config: {
                    topOffset: 24,
                    shouldCloseOnBlur: false
                }
            }) : undefined}
            classes='px-2'
        />
    )
}

export default BidderListPopUpLink