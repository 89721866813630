import { AgTechRendering } from 'agtech/core/components/AgTechRendering';
import { AgTechMessage, IAgTechAppMessaging, isAgTechMessage } from 'agtech/core/functions/AgTechAppMessaging';
import { logEvent } from 'agtech/core/logging/AgTechLogger';
import { isDev } from 'agtech/core/utilities/AgTechUtilities';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import { AgTechIconBadge } from 'agtech/web/components/Icons/AgTechIcons';
import AgTechToast, { AgTechToastType } from 'agtech/web/components/Portals/Toasts/AgTechToast';
import { uniqueId } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'

/// Types

export type AgTechToast = AgTechMessage & {
    icon?: string,
    type?: AgTechToastType
}

export type AgTechToastRendering = {
    id: string,
    toast: AgTechRendering
}

/// Components

export type AgTechToastSurfaceProps = {
    toastManager: AgTechToastManager
}

const AgTechToastSurface = (props: AgTechToastSurfaceProps) => {
    let toastManager = props.toastManager;
    let toasts = toastManager.renderedToasts;
    let surfaceClass = toasts.length > 0 ? 'show' : '';

    return (
        <div className={`agtech-toast-surface ${surfaceClass}`}>
            {toasts.map(toast => (
                <AgTechRenderedToast
                    key={toast.id}
                    toast={toast}
                    toastManager={toastManager}
                />
            ))}
        </div>
    );
};

declare type AgTechRenderedToastProps = {
    toast: AgTechToastRendering,
    toastManager: AgTechToastManager
}

let AgTechRenderedToast = (props: AgTechRenderedToastProps) => {
    let [isFaded, setIsFaded] = useState(false);
    let [isShown, setIsShown] = useState(true);

    let shownClass = isFaded ? 'faded' : '';

    let removeToast = () => {
        setIsShown(false);
        props.toastManager.removeToast(props.toast.id);
    }
    
    useEffect(() => {
        if (isFaded) {
            setTimeout(removeToast, 500);
        }
    }, [isFaded]);

    useEffect(() => {
       setTimeout(() => {
            if (process.env.NODE_ENV !== 'development') {
                setIsFaded(true);
            }
       }, 5000);
    }, []);

    return isShown ? (
        <div className={`agtech-rendered-toast ${shownClass}`}>
            <i className="agtech-toast-removal-icon fas fa-times" onClick={() => setIsFaded(true)} />
            {props.toast.toast.rendering()}
        </div>
    ) : null;
}

/// Utilities

export type AgTechToastManager = IAgTechAppMessaging & {
    renderedToasts: AgTechToastRendering[],
    show: (rendering: AgTechRendering) => void,
    showToast: (toast: AgTechToast) => void,
    showWarnings: (warnings: AgTechMessage[]) => void,
    removeToast: (toastID: string) => void
}

export const DefaultAgTechToastManager: AgTechToastManager = {
    renderedToasts: [],
    show: (rendering: AgTechRendering) => {},
    showToast: (toast: AgTechToast) => {},
    showMessage: (message: AgTechMessage) => {},
    showWarning: () => {
        console.warn('Toasted warning with default toast manager');
        return false;
    },
    showWarnings: (warnings: AgTechMessage[]) => {},
    showError: (error: AgTechMessage) => {
        console.warn('Toasted error with default toast manager');
        return false;
    },
    showSuccess: () => {
        console.warn('Toasted success with default toast manager');
        return true;
    },
    removeToast: (toastID: string) => {}
}

export function useToasts(): AgTechToastManager {
    let [toasts, setToasts] = useState<AgTechToastRendering[]>([]);
    let toastsRef = useRef<AgTechToastRendering[]>([]);

    const updateToasts = (toasts: AgTechToastRendering[]) => {
        toastsRef.current = [...toasts];
        setToasts(toastsRef.current);
    }

    let showToast = (toast: AgTechToast) => {
        let newToast = getWebToast(toast);
        updateToasts([...toastsRef.current, newToast]);
    }

    let showToasts = (toastsToShow: AgTechToast[]) => {
        let newToasts = toastsToShow.map<AgTechToastRendering>(toast => getWebToast(toast));
        updateToasts([...toastsRef.current, ...newToasts]);
    }

    return {
        renderedToasts: toasts,
        show: rendering => setToasts([...toasts, {
            id: uniqueId(),
            toast: rendering
        }]),
        showToast: toast => showToast(toast),
        showMessage: (message: AgTechMessage) => showToast({
            ...message,
            type: AgTechToastType.Info,
            icon: 'info'
        }),
        showWarning: (message: AgTechMessage | string) => {
            let messageProps = isAgTechMessage(message) ? {
                ...message
            } : {
                header: message
            };
            
            showToast({
                ...messageProps,
                type: AgTechToastType.Warning,
                icon: 'exclamation'
            });

            return false;
        },
        showWarnings: (warnings: AgTechMessage[]) => showToasts(warnings.map(message => ({
            ...message,
            type: AgTechToastType.Warning,
            icon: 'exclamation'
        }))),
        showError: (message: AgTechMessage) => {
            logEvent('ShowErrorToast', {
                message: message
            });

            if (isDev()) {
                showToast({
                    ...message,
                    type: AgTechToastType.Error,
                    icon: 'exclamation'
                });
            }
            else {
                showToast({
                    header: message.header,
                    type: AgTechToastType.Error,
                    icon: 'exclamation'
                });
            }

            return false;
        },
        showSuccess: message => {
            showToast({
                ...message,
                type: AgTechToastType.Success,
                icon: 'check'
            });

            return true;
        },
        removeToast: toastID => {
            toastsRef.current = toastsRef.current.filter(t => t.id !== toastID);

            // Only update state if all toasts have been removed, as auto-fading toasts
            // can cause threading issues if state is updated with each removal
            if (toastsRef.current.length === 0) {
                updateToasts([]);
            }
        }
    }
}

const getWebToast = (toast: AgTechToast): AgTechToastRendering => {
    return {
        id: uniqueId(),
        toast: {
            rendering: () => (
                <AgTechToast type={toast.type ?? AgTechToastType.Info}>
                    <div className='agtech-toast-indicator' />
                    <AgTechTitleDetailHeader
                        title={toast.header}
                        titleFontSize='font-size-large'
                        detail={toast.details}
                        detailClasses='mt-2'
                        detailFontSize='font-size-standard'
                        icon={toast.icon}
                        iconContent={() => toast.icon ? (
                            <AgTechIconBadge
                                icon={toast.icon}
                                size={34}
                                rounded={true}
                                classes='agtech-toast-icon'
                            />
                        ) : null}
                        classes='tall column-based-vertically-centered py-3'
                    >
                        {toast.content ? toast.content() : null}
                    </AgTechTitleDetailHeader>
                </AgTechToast>
            )
        }
    }
}

export default AgTechToastSurface;