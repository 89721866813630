import React, { useEffect, useState } from "react";

export type AgTechDialogProps = {
    width?: number
}

let AgTechDialog = (props: React.PropsWithChildren<AgTechDialogProps>) => {
    let dialogWidth = props.width ?? 400;

    return (
        <div
            className={`agtech-dialog`}
            style={{ width: dialogWidth }}
            onClick={e => e.stopPropagation()}
        >
            {props.children}
        </div>
    )
};

export default AgTechDialog;