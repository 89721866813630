import React from 'react'
import { AgTechBadge, AgTechBadgeColor } from 'agtech/web/components/Badges/AgTechBadges';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import { LotActionButton } from 'app/areas/lots/components/buttons/SaleLotButtons';
import LotDialog from 'app/areas/lots/dialogs/LotDialog';
import { useLotCategoryData } from 'app/data/lots/categories/LotCategoryData';
import { SaleLot, isLotInvoiced, isLotSold, useSaleLots } from 'app/data/lots/LotData';
import _ from 'lodash';
import { AgTechColorBadge } from 'agtech/web/components/Badges/AgTechColorBadge';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import LotCategoryDialog from 'app/areas/lots/dialogs/LotCategoryDialog';
import LotLink from 'app/areas/lots/components/links/LotLink';
import LotStatusBadge from 'app/areas/lots/components/badges/LotStatusBadge';

export type SaleLotViewGridProps = {
    lotFilter?: (lot: SaleLot) => boolean
}

const SaleLotViewGrid = (props: SaleLotViewGridProps) => {
    let saleLotGridConfiguration = useLotViewGridConfiguration({ lotFilter: props.lotFilter });
    return <AgTechGrid {...saleLotGridConfiguration} />
};

declare type SaleLotViewGridEntity = {
    id: number,
    saleOrder: number,
    lotNumber: string,
    bidderNumber?: string,
    purchasePrice?: number,
    isInvoiced: boolean,
    lotCategoryId?: number,
    lotCategoryName?: string,
    lotCategoryColor?: string
}

const isLotViewGridEntityTheSame = (prev: SaleLotViewGridEntity, curr: SaleLotViewGridEntity) => {
    return prev.saleOrder === curr.saleOrder &&
           prev.lotNumber === curr.lotNumber &&
           prev.bidderNumber === curr.bidderNumber &&
           prev.purchasePrice === curr.purchasePrice &&
           prev.lotCategoryId === curr.lotCategoryId &&
           prev.isInvoiced === curr.isInvoiced;
}

const useLotViewGridConfiguration = (props: { lotFilter?: (lot: SaleLot) => boolean }): AgTechGridProps<SaleLotViewGridEntity> => {
    let lots = useSaleLots();
    let lotsToShow = props.lotFilter ? lots.filter(props.lotFilter) : lots;
    let lotCategoryData = useLotCategoryData();

    let lotGridEntity: SaleLotViewGridEntity[] = lotsToShow.map(lot => {
        let lotCategory = lot.lotCategoryId ? lotCategoryData[lot.lotCategoryId] : undefined;

        return {
            ...lot,
            lotCategoryId: lotCategory?.id,
            lotCategoryName: lotCategory?.name,
            lotCategoryColor: lotCategory?.color,
            isInvoiced: isLotInvoiced(lot)
        }
    });

    return {
        name: 'SaleLotViewGrid',
        header: {
            title: 'Sale Lots',
            content: () => (
                <div className='column-based horizontal-pane container'>
                    <LotDialog.Button />
                </div>
            )
        },
        items: _.orderBy(lotGridEntity, lt => lt.saleOrder),
        columns: [
            {
                header: 'Sale Order',
                value: l => l.saleOrder,
                classes: 'emphasized centered',
                width: '100px'
            },
            {
                header: 'Lot Number',
                value: l => (
                    <LotLink lotId={l.id} classes='mx-3' />
                ),
                width: '100px'
            },
            {
                header: 'Purchasing Bidder',
                value: l => l.bidderNumber ?? '',
            },
            {
                header: 'Purchase Price ($)',
                value: l => l.purchasePrice && l.purchasePrice > 0 ? AgTechMoneyFormatter.format(l.purchasePrice) : '',
            },
            {
                header: '',
                value: l => <LotStatusBadge lotId={l.id} />,
                width: '100px',
                classes: 'px-3'
            }
        ],
        isRowEntityTheSame: (prev, curr) => isLotViewGridEntityTheSame(prev, curr) && prev.lotCategoryColor === curr.lotCategoryColor,
        actions: {
            rendering: l => <LotActionButton lotId={l.id} />
        },
        searchConfig: {
            options: [
                { field: 'Lot Number', value: l => l.lotNumber },
                { field: 'Purchasing Bidder', value: l => l.bidderNumber ?? '' },
                { field: 'Category', value: l => l.lotCategoryId ? lotCategoryData[l.lotCategoryId].name : ''}
            ]
        },
        viewConfig: {
            views: [
                { name: 'All Lots', description: 'View all lots in this sale', filter: lot => true },
                { name: 'Lots not sold', description: 'View only lots not yet sold in this sale', filter: lot => !isLotSold(lot) },
                { name: 'Invoiced lots', description: 'View only invoiced lots in this sale', filter: lot => lot.isInvoiced },
            ]
        },
        noRecordsConfig: {
            message: "We're sorry, there are no lots in the system for this sale.",
            action: {
                prompt: 'To create one, please click the button below',
                button: () => <LotDialog.Button classes='highlight' />
            }
        },
        sortingConfig: {
            getSortingKey: lot => lot.saleOrder
        }
    };
}

export default React.memo(SaleLotViewGrid, () => true) as typeof SaleLotViewGrid;