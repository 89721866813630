import { AgTechButton, AgTechFileUploadButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechTabbedPage from "agtech/web/components/Pages/Tabbed/AgTechTabPage";
import { AgTechStandardModal } from "agtech/web/components/Portals/Modals/AgTechModal";
import { useFailedImportResultsTabs } from "agtech/web/components/Portals/Modals/Imports/Common/AgTechImportHelpers";
import { AgTechImportModalResultViewProps, isItemImportValid } from "agtech/web/components/Portals/Modals/Imports/Common/AgTechImportTypes";

export type AgTechConflictingImportResultsViewProps<TImportItem> = AgTechImportModalResultViewProps<TImportItem> & {
    onImportSubmitted: (importedItems: TImportItem[]) => Promise<void>
}

const AgTechConflictingImportResultsView = <TImportItem, >(props: AgTechConflictingImportResultsViewProps<TImportItem>) => {
    let successfulImports = props.importResult.items.filter(isItemImportValid);

    let failedImportResultsTabs = useFailedImportResultsTabs(props);
    
    return (
        <AgTechStandardModal
            title={`${props.terminology.importEntitySingular} Import Results & Conflicts`}
            details='Below are the results of your import. To proceed with importing those items that do not already exist, please click the button below.'
            body={() => <AgTechTabbedPage {...failedImportResultsTabs} />}
            footer={() => (
                <div className='column-based-vertically-centered wide container'>
                    <AgTechFileUploadButton
                        text='Upload New File'
                        icon='upload'
                        onFileUploaded={props.onImportAgain}
                        classes='py-2 px-5'
                    />
                    {successfulImports.length > 0 && (
                        <AgTechButton
                            text={`Complete ${props.terminology.importEntitySingular} Import`}
                            icon='check'
                            action={async e => {
                                let wasImportConfirmed = await e.confirmations.askForConfirmation({
                                    content: {
                                        header: `Are you sure you want to import ${successfulImports.length} ${successfulImports.length > 1 ? props.terminology.importEntityPlural : props.terminology.importEntitySingular}?`,
                                    },
                                    actions: {
                                        onConfirm: async () => {
                                            await props.onImportSubmitted(successfulImports.map(e => e.importedItem));
                                            return true;
                                        }
                                    }
                                });

                                return wasImportConfirmed;
                            }}
                            classes='highlight py-2 px-5 ml-auto'
                            styles={{ minWidth: 200 }}
                        />    
                    )}
                </div>
            )}
        />
    )
}

export default AgTechConflictingImportResultsView;