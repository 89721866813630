import { useAppContext } from "agtech/core/app/AgTechAppContext";
import { useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechFormControlIndex, AgTechFormControlMetadata, AgTechFormControlUpdate } from "agtech/core/forms/AgTechFormControls";
import { useFormDataAction } from "agtech/core/forms/AgTechFormDataActions";
import { AgTechFormProps } from "agtech/core/forms/AgTechFormProps";
import { AgTechFormState } from "agtech/core/forms/AgTechFormState";
import { useAgTechSafeState } from "agtech/core/utilities/AgTechUtilities";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

export type AgTechFormContext<TInput> = {
    entity: TInput,
    state: AgTechFormState,
    updateFormEntity: <TFormCallbackEntity extends TInput>(formEntityCallback: (currentFormEntity: TFormCallbackEntity) => TFormCallbackEntity) => void,
    handleFieldChanged: <TFormControlDataType, >(value: TFormControlDataType, getUpdatedEntity: AgTechFormControlUpdate<TInput, TFormControlDataType>) => Promise<void>,
    registerFormField: (field: AgTechFormControlMetadata<TInput>) => void,
}

export const FormContext = createContext<AgTechFormContext<any>>({
    entity: undefined,
    state: AgTechFormState.Open,
    updateFormEntity: () => {
        console.error('Updated form entity in default form context.');
    },
    handleFieldChanged: async (val, getUpdatedEntity) => {
        console.error('Handled field change in default form context.');
        await getUpdatedEntity(undefined, val);
    },
    registerFormField: () => {
        console.error('Registered a form field with the default form context');
    }
});

export const useFormContext = () => useContext(FormContext);

export const useFormContextData = <TFormData, TActionData, TResponseData>(
    props: AgTechFormProps<TFormData, TActionData, TResponseData>
): AgTechFormContext<TFormData> => {
    let appContext = useAppContext();
    let dispatch = useDispatch();

    let [formData, updateFormDataRef] = useAgTechSafeState<TFormData>(props.initialFormData);    
    let [formState, updateFormState] = useState(AgTechFormState.Open);

    let formControlsRef = useRef<AgTechFormControlIndex<TFormData>>({});
    let formDataActionExecutor = useDataActionExecutor();

    // Hooks called for realtime form submissions
    let formRealtimeEntityLastUpdateRef = useRef<TFormData>(props.initialFormData);

    const updateFormData = (updatedFormData: TFormData) => {
        if (props.behavior?.eventHandlers?.onFormEntityChanged) {
            props.behavior.eventHandlers.onFormEntityChanged(updatedFormData);
        }
        
        updateFormDataRef(updatedFormData);
    }

    // Wrap the given data action in a wrapping action with form specific business logic
    let formDataAction = useFormDataAction({
        appContext: appContext,
        dispatch: dispatch,
        initialFormData: props.initialFormData,
        getActionData: props.action.getActionData,
        formDataRef: formData,
        formControlsRef: formControlsRef,
        formDataAction: props.action.dataAction,
        setFormState: state => {
            updateFormState(state);
        }
    });

    useEffect(() => {
        if (props.behavior?.realtimeSubmissionProps) {
            let hasFormEntityChanged = !props.behavior.realtimeSubmissionProps.isFormEntityTheSame(
                formRealtimeEntityLastUpdateRef.current,
                formData.current);
            
            if (hasFormEntityChanged) {
                formRealtimeEntityLastUpdateRef.current = { ...formData.current };

                formDataActionExecutor.executeAction(formDataAction, {
                    originalEntity: props.action.getActionData(props.initialFormData),
                    submittedEntity: props.action.getActionData(formData.current)
                });
            }
        }
    }, [formData.current]);

    useEffect(() => {
        if (props.structure?.submitButton && props.structure.submitButton.current) {
            props.structure.submitButton.current.click = async () => {       
                await formDataActionExecutor.executeAction(formDataAction, {
                    originalEntity: props.action.getActionData(props.initialFormData),
                    submittedEntity: props.action.getActionData(formData.current)
                });
            }
        }

        return () => {
            if (props.structure?.submitButton && props.structure.submitButton.current) {
                props.structure.submitButton.current.click = async () => {};
            }
        }
    });
    
    return {
        entity: formData.current,
        state: formState,
        updateFormEntity: function updateEntity<TFormInput extends TFormData>(callback: (currentFormEntity: TFormInput) => TFormInput) {
            let updatedFormEntity = callback(formData.current as TFormInput);
            updateFormData({ ...updatedFormEntity });
        },
        handleFieldChanged: async (val, update) => {
            var updatedEntity = await update(formData.current, val);
            updateFormData({ ...updatedEntity });
        },
        registerFormField: (field: AgTechFormControlMetadata<TFormData>) => {
            formControlsRef.current[field.id] = { ...field };
        }
    }
}