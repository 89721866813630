import { useAgTechHoverable } from 'agtech/web/components/Portals/Popups/AgTechPopUpOnHoverContainer'
import { AgTechPopUpCoreProps } from 'agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData'
import React, { useRef } from 'react'

export type AgTechPopUpIconProps = {
    icon: string,
    classes?: string,
    popupRenderProps: () => AgTechPopUpCoreProps,
    styles?: React.CSSProperties
}

const AgTechPopUpIcon = (props: AgTechPopUpIconProps) => {
    let iconRef = useRef<HTMLElement>(null);

    useAgTechHoverable({
        container: iconRef,
        ...props
    });

    return (
        <i
            ref={iconRef}
            className={`clickable bi ${props.icon} ${props.classes ?? ''}`}
            style={props.styles}
        />
    )
}

export default AgTechPopUpIcon