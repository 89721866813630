import { AgTechDataActionGetDataActionProps, AgTechDataActionExecutionResult } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechHttpRequestExecutor } from "agtech/core/data/actions/AgTechHttpRequests";
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from "agtech/web/components/Buttons/AgTechButtons";
import { AgTechFormRow } from "agtech/web/components/Forms/AgTechForm";
import AgTechFormDialog from "agtech/web/components/Portals/Dialogs/AgTechFormDialog";
import SaleLotOrderModificationGrid, { SaleLotOrderModificationGridEntity } from "../components/grids/SaleOrderModificationGrid";
import { useState } from "react";
import { useSaleLots } from "app/data/lots/LotData";
import { useLotDataWriteSaleReorderAction } from "app/data/lots/LotDataActions";

const LotSaleOrderUpdateDialog = () => {
    let lots = useSaleLots();
    let [entities, updateEntities] = useState<SaleLotOrderModificationGridEntity[]>(lots.map<SaleLotOrderModificationGridEntity>(lot => ({id: lot.id, saleOrder: lot.saleOrder, originalOrder: lot.saleOrder, lotNumber: lot.lotNumber, lotDescription: lot.lotDescription, changed: false})));
    let postAction = useLotDataWriteSaleReorderAction();

    return (
        <AgTechFormDialog
            structure={{
                header: 'Change Lot Sale Order',
                submitButtonText: 'Submit',
                width: 800
            }}
            formData={{
                loadInitialFormData: () => entities
            }}
            formAction={{
                dataAction: postAction,
                getActionData: d => d
            }}
        >
            <SaleLotOrderModificationGrid entities={entities} updateEntities={(ent: SaleLotOrderModificationGridEntity[]) => { updateEntities(ent); }} />
        </AgTechFormDialog>
    );
};

LotSaleOrderUpdateDialog.Open = (e: AgTechClickEventHandler) => e.dialogs.openDialog({
    dialog: () => <LotSaleOrderUpdateDialog />
})

LotSaleOrderUpdateDialog.Button = (props: AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        ...props,
        text: 'Change Sale Order',
        icon: 'arrow-down-1-9',
        action: LotSaleOrderUpdateDialog.Open
    };

    return <AgTechButton {...buttonProps} />;
};

export default LotSaleOrderUpdateDialog;