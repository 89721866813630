import { useAgTechAuthContext } from 'agtech/core/auth/AgTechAuthContext';
import AgTechWebAppLogin from 'agtech/web/components/Auth/AgTechWebAppLogin';
import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons'
import React, {  } from 'react'
import { Route, Routes } from 'react-router-dom'

const AgTechWebAppLoginPage = () => {
    return (
        <Routes>
            <Route path="/*" element={<AgTechWebAppLoginPane />} />
            <Route path='/auth/login' element={<AgTechWebAppLogin />} />
        </Routes>
    )
}

const AgTechWebAppLoginPane = () => {
    let authContext = useAgTechAuthContext();

    return (
        <div className='row-based fade-in-400 centered container'>
            <AgTechButton
                text="Login"
                action={authContext.loginUser}
                classes='agtech-login-button'
            />
            <AgTechButton
                text="Register"
                action={authContext.loginUser}
                classes='agtech-register-button agtech-button-white-outline'
            />
        </div>
    )
}

export default AgTechWebAppLoginPage