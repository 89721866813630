import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from "agtech/web/components/Menus/AgTechVerticalPopUpMenu"
import { Payment } from "app/data/invoicing/InvoicingData"
import EditPaymentDialog from "../../dialogs/InvoiceEditPaymentDialog"
import { PaymentRemovalButton } from "../buttons/PaymentButtons"

export type PaymentActionMenuProps = {
    payment: Payment
}

const PaymentsActionMenu = (props: PaymentActionMenuProps) => {
    
    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Payments">
                <EditPaymentDialog.Button payment={props.payment} />
                <PaymentRemovalButton payment={props.payment} />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
}

export default PaymentsActionMenu