import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import LotDialog from 'app/areas/lots/dialogs/LotDialog';
import { useSaleLot } from 'app/data/lots/LotData';
import { useLotDataRemoveLotAction } from 'app/data/lots/LotDataActions';
import React from 'react'
import LotSplitDialog from '../../dialogs/LotSplitDialog';

export type LotActionMenuProps = {
    lotId: number
}

const LotActionMenu = (props: LotActionMenuProps) => {
    let lot = useSaleLot(props.lotId);
    let lotRemovalAction = useLotDataRemoveLotAction();

    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Lot">
                <LotDialog.Button lotId={props.lotId} />
                <AgTechButton
                    text="Delete Lot"
                    icon="times"
                    action={async e => {
                        await e.actions.executeDataAction(lotRemovalAction, {
                            originalEntity: lot,
                            submittedEntity: lot
                        });
                    }}
                />
            </AgTechVerticalPopUpMenuSection>
            {lot.purchasePrice ? (
                <AgTechVerticalPopUpMenuSection header='Sale'>
                    <LotSplitDialog.Button lotId={props.lotId} />
                </AgTechVerticalPopUpMenuSection>
            ) : undefined}
        </AgTechVerticalPopUpMenu>
    )
}

export default LotActionMenu