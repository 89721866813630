import { AgTechFormControlUpdate } from 'agtech/web/components/Forms/Controls/Base/AgTechFormControls';
import { AgTechSelectionFormControl, AgTechFormSelectionControlProps } from 'agtech/web/components/Forms/Controls/Selection/AgTechFormSelectionControl';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import LotCategoryDialog from 'app/areas/lots/dialogs/LotCategoryDialog';
import { SaleLotCategory, useLotCategories } from 'app/data/lots/categories/LotCategoryData';
import React from 'react'

export type LotCategoryDropdownProps<TFormEntity> = {
    entityType: TFormEntity,
    getSelectedLotCategoryId: (formEntity: TFormEntity) => number | undefined,
    onLotCategorySelected: AgTechFormControlUpdate<TFormEntity, SaleLotCategory | undefined>,
    caption?: string,
    width?: number
}

export const useLotCategorySelectionProps = <TFormEntity, >(
    props: LotCategoryDropdownProps<TFormEntity>
): AgTechFormSelectionControlProps<TFormEntity, SaleLotCategory> => {
    let lotCategories = useLotCategories();

    return {
        entityType: props.entityType,
        control: {
            caption: props.caption ?? "Category",
            width: props.width
        },
        selection: {
            selectionControlTitle: 'Lot Categories',
            items: lotCategories,
            itemText: c => c.name,
            itemHeight: 60,
            itemRendering: e => (
                <AgTechTitleDetailHeader
                    title={e.name}
                    detail={e.description}
                    titleFontSize='font-size-small'
                    detailFontSize='font-size-very-small'
                    detailClasses='mt-1-half'
                />
            ),
            getSelectedItemId: entity => props.getSelectedLotCategoryId(entity)?.toString(),
            onItemSelected: async (e, cat) => {
                return await props.onLotCategorySelected(e, cat);
            },
            filterItems: (item, filter) => item.name.includes(filter),
            itemCreationConfig: {
                creationButtonText: 'New Category',
                creationButtonAction: e => {
                    return new Promise<SaleLotCategory>((res, fail) => {
                        e.dialogs.openDialog({
                            dialog: () => (
                                <LotCategoryDialog onLotCategorySubmitted={async createdCategory => {
                                    res(createdCategory);
                                }} />
                            ),
                        });
                    });
                },
                noRecordsMessage: 'No lot categories were found in the system',
                noRecordsPrompt: 'To create one, please click the button below'
            },
            styles: { height: 240, width: 360 }
        }
    }
}

export const LotCategoryDropdown = <TFormEntity, >(props: LotCategoryDropdownProps<TFormEntity>) => {
    let lotCategorySelectionProps = useLotCategorySelectionProps(props);
    
    return (
        <AgTechSelectionFormControl
            {...lotCategorySelectionProps}     
        />
    )
}