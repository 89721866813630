import { AgTechLink, AgTechActionButton } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { ContactNoteActionButton } from 'app/areas/contacts/components/buttons/ContactButtons';
import ContactNoteActionMenu from 'app/areas/contacts/components/menus/ContactNoteActionMenu';
import ContactNoteDialog from 'app/areas/contacts/dialogs/notes/ContactNoteDialog';
import { AgTechContact, getContactNotes, useContact, useContactNotes } from 'app/data/operation/contacts/ContactData';
import { ContactNote } from 'app/data/operation/contacts/notes/ContactDataNotes';
import dayjs from 'dayjs';
import React from 'react'

export type ContactNotesGridProps = {
    contactId: number,
    gridProps?: (props: AgTechGridProps<ContactNote>) => AgTechGridProps<ContactNote>
}

const ContactNotesGrid = (props: ContactNotesGridProps) => {
    let contact = useContact(props.contactId);
    let notes = getContactNotes(contact);

    let noteGridConfiguration: AgTechGridProps<ContactNote> = {
        header: {
            title: 'Notes',
        },
        items: notes,
        columns: [
            {
                header: 'Date',
                value: note => dayjs(note.date).format('MM/DD/YYYY'),
                classes: 'emphasized centered font-size-small',
                width: '100px'
            },
            {
                header: "Note",
                value: note =>
                    <AgTechGridLink content={note.note}
                        onClick={(e => e.dialogs.openDialog({
                            dialog: () => <ContactNoteDialog contactId={props.contactId} note={note} />
                        }))}
                    />,
                size: 2
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.date === curr.date &&
                   prev.note === curr.note;
        },
        noRecordsConfig: {
            message: 'No notes were found for this contact',
            action: {
                prompt: 'To create one, click the button below',
                button: () => <ContactNoteDialog.Button contactId={props.contactId} classes='highlight' />
            }
        },
        actions: {
            rendering: note => <ContactNoteActionButton contactId={props.contactId} noteId={note.id} />,
            width: '36px'
        }
    };

    if (props.gridProps) {
        noteGridConfiguration = props.gridProps(noteGridConfiguration);
    }

    return <AgTechGrid {...noteGridConfiguration} />
}

export default ContactNotesGrid;