import { useClickable } from 'agtech/web/components/Buttons/AgTechButtons';
import { IAgTechGridItem } from 'agtech/web/components/Grids/Data/AgTechGridData';
import { AgTechGridState } from 'agtech/web/components/Grids/Data/AgTechGridState';
import AgTechGridSearchControl, { AgTechGridSearchProps, AgTechGridSearchState } from 'agtech/web/components/Grids/Features/AgTechGridSearching';
import { AgTechGridView, AgTechGridViewConfig, AgTechGridViewSelectionPopUp } from 'agtech/web/components/Grids/Features/AgTechGridViews';
import { AgTechPopUpPosition, AgTechRenderedPopUp } from 'agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData';
import AgTechSearchControl from 'agtech/web/components/Widgets/AgTechSearchControl';
import React, { useRef } from 'react'

export type AgTechGridHeaderConfiguration = {
    title: string,
    content?: () => React.ReactNode,
    showCount?: boolean
}

export type AgTechGridHeaderSearchConfig<TItem> = {
    searchConfig?: AgTechGridSearchProps<TItem>,
    onSearchStateUpdated: (searchState: AgTechGridSearchState<TItem>) => void
}

export type AgTechGridHeaderViewsConfig<TItem extends IAgTechGridItem> = {
    views?: AgTechGridViewConfig<TItem>,
    onViewSelected: (selectedView: AgTechGridView<TItem>) => void
}

export type AgTechGridHeaderProps<TItem extends IAgTechGridItem> = {
    gridState: AgTechGridState<TItem>,
    header?: AgTechGridHeaderConfiguration,
    search?: AgTechGridHeaderSearchConfig<TItem>,
    views?: AgTechGridHeaderViewsConfig<TItem>,
    itemCount: number
}

const AgTechGridHeader = <TItem extends IAgTechGridItem>(props: AgTechGridHeaderProps<TItem>) => {
    return (
        <div
            className="column-based-vertically-centered pb-3 container"
            style={{ height: props.gridState.gridProps.sizingConfig?.size === 'small' ? undefined : 44 }}
        >
            <AgTechGridTitle {...props} />
            <div className='column-based-verically-centered tall ml-auto container'>
                { props.header?.content ? props.header.content() : null }
            </div>
            { props.search?.searchConfig ? (
                <AgTechSearchControl
                    {...props.search.searchConfig}
                    placeholder={props.search.searchConfig?.placeholder ?? 'Search...'}
                    onUpdateSearchState={props.search.onSearchStateUpdated}
                    width={200}
                    classes='ml-4'
                />
            ) : null }
        </div>
    );
}

const AgTechGridTitle = <TItem extends IAgTechGridItem>(props: AgTechGridHeaderProps<TItem>) => {
    let gridTitle = props.header?.title;
    let showCounts = props.header?.showCount ?? true;

    if (props.views?.views?.views) {
        let selectedView = props.gridState.selectedView ?? props.views.views.views[0];
        gridTitle = selectedView?.name;
    }

    gridTitle = gridTitle ? showCounts ? `${gridTitle} (${props.itemCount})` : gridTitle : undefined;

    if (!gridTitle) {
        return null;
    }

    let gridTitleClasses = props.gridState.gridProps.sizingConfig?.size === 'small'
        ? 'font-size-small'
        : 'centered px-6 tall font-size-standard bg-white bordered rounded';

    return props.views?.views ? (
        <AgTechGridTitleWithViewSelection
            {...props}
            gridTitle={gridTitle}
            onViewSelected={props.views.onViewSelected}
        />
    ) : (
        <h1 className={`${gridTitleClasses} font-bold container`}>{gridTitle}</h1>
    );
}

const AgTechGridTitleWithViewSelection = <TItem extends IAgTechGridItem>(props: AgTechGridHeaderProps<TItem> & {
    gridTitle: string,
    onViewSelected: (selectedView: AgTechGridView<TItem>) => void
}) => {
    let gridViewSelectionRef = useRef(null);
    let renderedPopUp = useRef<AgTechRenderedPopUp>();

    let gridViewSelectionClickable = useClickable(gridViewSelectionRef);

    return (
        <div
            className="centered clickable pl-6 pr-4 tall font-size-medium bg-white bordered rounded font-bold container"
            ref={gridViewSelectionRef}
            onClick={() => {
                if (!renderedPopUp.current) {
                    renderedPopUp.current = gridViewSelectionClickable.popups.showPopUp({
                        callerRef: gridViewSelectionRef,
                        popup: () => (
                            <AgTechGridViewSelectionPopUp
                                gridName={props.gridState.gridProps.name}
                                selectedView={props.gridState.selectedView?.name}
                                viewConfig={props.views?.views ?? { views: [] }}
                                onViewSelected={props.onViewSelected}
                            />
                        ),
                        config: {
                            position: AgTechPopUpPosition.Beside,
                            fadeInTime: '200ms'
                        },
                        handlers: {
                            onPopUpClosed: () => renderedPopUp.current = undefined
                        }
                    })
                }
            }}
        >
            {props.gridTitle}
            <i className='bi bi-chevron-down ml-4 font-size-small' style={{ marginTop: 1 }} />
        </div>
    )
}

export default AgTechGridHeader;