import React from 'react'
import _ from 'lodash';
import { AgTechBadge, AgTechBadgeColor } from 'agtech/web/components/Badges/AgTechBadges';
import { LotActionButton } from 'app/areas/lots/components/buttons/SaleLotButtons';
import LotDialog from 'app/areas/lots/dialogs/LotDialog';
import { useSaleBidderData } from 'app/data/bidders/BidderData';
import { SaleLot, isLotInvoiced, isLotTheSame } from 'app/data/lots/LotData';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechGridNumberControl } from 'agtech/web/components/Grids/Controls/AgTechGridNumberControl';
import { AgTechGridTextControl } from 'agtech/web/components/Grids/Controls/AgTechGridTextControl';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { ContactGridLink } from 'app/areas/contacts/components/buttons/ContactButtons';
import { useLotDataWriteLotPurchaseAction } from 'app/data/lots/purchases/LotPurchaseDataActions';
import { useContactData } from 'app/data/operation/contacts/ContactData';
import LotLink from '../links/LotLink';
import AgTechPopUpIcon from 'agtech/web/components/Icons/AgTechPopUpIcon';
import LotSplitPopUp from 'app/areas/lots/components/popups/LotSplitPopUp';
import { AgTechColorBadge } from 'agtech/web/components/Badges/AgTechColorBadge';
import { useLotCategoryData } from 'app/data/lots/categories/LotCategoryData';

export type SaleLotSellingGridProps = {
    lots: SaleLot[]
}

const SaleLotSellingGrid = (props: SaleLotSellingGridProps) => {
    let lotSellingGridConfig = useLotSellingGridConfig(props);
    return <AgTechGrid {...lotSellingGridConfig} />
};

const useLotSellingGridConfig = (props: SaleLotSellingGridProps): AgTechGridProps<SaleLot> => {
    let saleBidderData = useSaleBidderData();
    let contacts = useContactData();
    let lotPurchaseSubmission = useLotDataWriteLotPurchaseAction();
    let lotCategoryData = useLotCategoryData();
    
    return {
        header: {
            title: 'Sale Lots',
            content: () => <LotDialog.Button />
        },
        items: _.orderBy(props.lots.filter(lt => !isLotInvoiced(lt)), lt => lt.saleOrder),
        columns: [
            {
                header: 'Sale Order',
                value: lot => lot.saleOrder,
                width: '100px',
                classes: 'centered emphasized'
            },
            {
                header: 'Lot Number',
                value: lot => (
                    <LotLink lotId={lot.id} classes='mx-3' />
                ),
                width: '100px'
            },
            {
                header: 'Purchasing Bidder',
                value: (lot, update) => (
                    <AgTechGridTextControl
                        value={lot.bidderNumber ?? ''}
                        onUpdated={val => {
                            update(l => ({
                                ...l,
                                bidderNumber: val
                            }));
                        }}
                    />
                ),
                classes: 'snug'
            },
            {
                header: 'Purchase Price ($)',
                value: (lot, update) => (
                    <AgTechGridNumberControl
                        value={lot.purchasePrice ?? 0}
                        onUpdated={val => {
                            update(l => ({
                                ...l,
                                purchasePrice: val === 0 ? undefined : val
                            }));
                        }} />
                ),
                classes:'snug'
            },
            {
                header: 'Contact Info',
                value: lot => (
                    <div className='stretched relative vertically-centered container'>
                        {lot.bidderNumber && saleBidderData.bidderDetails[lot.bidderNumber] !== undefined ? (
                            <ContactGridLink contactId={saleBidderData.bidderDetails[lot.bidderNumber].id } />
                        ) : undefined}
                        {lot.isSplit ? (
                            <AgTechPopUpIcon
                                icon='bi-arrow-left-right'
                                classes='absolute'
                                styles={{ right: 16 }}
                                popupRenderProps={() => ({
                                    popup: () => <LotSplitPopUp lotId={lot.id} />,
                                    config: {
                                        shouldCloseOnBlur: false
                                    }
                                })}
                            />
                        ) : undefined}
                    </div>
                ),
                size: 1.5
            },
            {
                header: '',
                value: lot => {
                    return lot.bidderNumber && lot.purchasePrice
                        ? <AgTechBadge classes='mx-3' color={AgTechBadgeColor.Green} text="Sold" customizations={{ width: '100%' }} />
                        : <AgTechBadge classes='mx-3' text="Not Sold" customizations={{ width: '100%' }} />
                },
                width: '100px'
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            let isSame = isLotTheSame(prev, curr);            
            return isSame;
        },
        actions: {
            rendering: lot => <LotActionButton lotId={lot.id} />
        },
        editConfig: {
            onEntityUpdated: async props => {
                return await props.actionExecutor.executeAction(lotPurchaseSubmission, {
                    originalEntity: props.originalItem,
                    submittedEntity: props.entity.item
                });
            },
            showStatus: false
        },
        searchConfig: {
            options: [
                { field: 'Lot Number', value: lot => lot.lotNumber },
                { field: 'Description', value: lot => lot.lotDescription },
                { field: 'Purchasing Bidder', value: lot => lot.bidderNumber ?? '' },
            ]
        },
        noRecordsConfig: {
            message: "We're sorry, there are no lots available for selling right now.",
            action: {
                prompt: 'To create a new lot, please click the button below',
                button: () => <LotDialog.Button classes='highlight' />
            }
        },
        sortingConfig: {
            getSortingKey: lot => lot.saleOrder
        }
    };
}

export default SaleLotSellingGrid;