import React, { FunctionComponent, useEffect, useState } from "react";

export type AgTechLoadingSurfaceProps = {
    waitOn?: boolean,
    text?: string,
    showLoader?: boolean,
    refreshWhenChanged?: string
};

declare type AgTechLoadingSurfaceState = {
    shouldChildContentBeShown: boolean,
    contentKey?: string
}

let AgTechFutureLoadingSurface = React.memo((props: React.PropsWithChildren<AgTechLoadingSurfaceProps>) => {
    let [loadingSurfaceState, updateLoadingSurfaceState] = useState<AgTechLoadingSurfaceState>({
        shouldChildContentBeShown: false,
        contentKey: props.refreshWhenChanged
    });

    useEffect(() => {
        let shouldShowChildContent = props.waitOn ?? true;
        
        if (shouldShowChildContent && props.refreshWhenChanged) {
            shouldShowChildContent = props.refreshWhenChanged === loadingSurfaceState.contentKey;
        }

        if (shouldShowChildContent !== loadingSurfaceState.shouldChildContentBeShown) {
            updateLoadingSurfaceState({
                shouldChildContentBeShown: shouldShowChildContent,
                contentKey: props.refreshWhenChanged
            });
        }
    });

    let showLoader = props.showLoader ?? true;

    return (
        <>
            { loadingSurfaceState.shouldChildContentBeShown ? props.children : showLoader ? <AgTechLoader text={props.text} /> : null }
        </>
    );
}, (prev, curr) => {
    return prev.waitOn === curr.waitOn &&
           prev.refreshWhenChanged === curr.refreshWhenChanged
});

export const AgTechLoader = ({ text }: { text: string | undefined }) => {
    return (
        <div className="stretched row-based centered container">
            <div className="loader" />
            { text ? <h1 className="agtech-loader-text">{text}</h1> : null }         
        </div>
    )
}

export default AgTechFutureLoadingSurface