import { AgTechWebApplication } from "agtech/web/application/AgTechWebApplication";
import { AgTechButton, AgTechButtonProps } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechActionPane from "agtech/web/components/Headers/AgTechActionPane";
import AgTechHeaderUserDetail from "agtech/web/components/Layout/AgTechHeaderUserDetail";
import React from "react";

const AgTechWebAppHeader = (props: { app: AgTechWebApplication}) => {
    return (
        <AgTechActionPane
            customization={{
                padLastElement: true
            }}
            elements={[
                props.app.header ? props.app.header(props.app) : null,
                <AgTechHeaderUserDetail />,
                <AgTechWebAppHeaderButton
                    bootstrapIcon={'bi bi-grid-3x3-gap-fill'}
                />
            ]}
        />
    )
};

export const AgTechWebAppHeaderButton = (props: AgTechButtonProps) => {
    return (
        <AgTechButton
            {...props}
            classes={`icon-only px-1 non-bordered font-color-secondary ${props.classes ?? ''}`}
            iconClasses={`font-size-emphasized ${props.iconClasses ?? ''}`}
        />
    )
}

const AgTechWebAppHeaderMemo = React.memo(AgTechWebAppHeader);

export default AgTechWebAppHeaderMemo;