import React from 'react'

export type AgTechFadeInViewProps = {
    classes?: string
}

const AgTechFadeInView = (props: React.PropsWithChildren<AgTechFadeInViewProps>) => {
    return (
        <div className={`fade-in-400 row-based stretched container ${props.classes ?? ''}`}>
            {props.children}
        </div>
    )
}

export default AgTechFadeInView