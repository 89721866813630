import AgTechDataProvider from 'agtech/core/data/providers/AgTechDataProvider';
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import { AgTechClickableIcon } from 'agtech/web/components/Icons/AgTechIcons';
import AgTechBorderedList from 'agtech/web/components/Lists/AgTechBorderedList';
import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import AgTechDialog from 'agtech/web/components/Portals/Dialogs/AgTechDialog';
import { useDialogContext } from 'agtech/web/components/Portals/Dialogs/AgTechDialogSlot';
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog';
import AddPaymentDialog from 'app/areas/invoices/dialogs/InvoiceAddPaymentDialog';
import InvoiceChangeContactCreditsDialog from 'app/areas/invoices/dialogs/InvoiceChangeContactCreditsDialog';
import InvoiceChangeDiscountsDialog from 'app/areas/invoices/dialogs/InvoiceChangeDiscountsDialog';
import InvoiceChangeLotsDialog from 'app/areas/invoices/dialogs/InvoiceChangeLotsDialog';
import { ProcessedSaleInvoice } from 'app/data/invoicing/InvoicingData';
import { InvoiceDetailsDTO, useInvoiceDataGetInvoiceDetailsHttpRequest, useInvoiceDataGetInvoiceDocumentHttpRequest } from 'app/data/invoicing/InvoicingDataActions';
import { useContact, getContactFullName } from 'app/data/operation/contacts/ContactData';
import React from 'react'

export type InvoiceInfoDialogProps = {
    invoiceId: number,
    invoice?: ProcessedSaleInvoice | undefined,
    invoiceDocument?: Blob | undefined,
    actions?: {
        close?: () => void,
    },
    showNextSteps: boolean
}

const InvoiceInfoDialog = (props: InvoiceInfoDialogProps) => {
    let invoiceGetDocumentRequest = useInvoiceDataGetInvoiceDocumentHttpRequest();
    let invoiceDetailsRequest = useInvoiceDataGetInvoiceDetailsHttpRequest();

    return props.invoice && props.invoiceDocument ? (
        <InvoiceInfoDialogBody
            invoice={props.invoice}
            invoiceDocument={props.invoiceDocument}
            actions={props.actions}
            showNextSteps={props.showNextSteps}
        />
    ) : (
        <AgTechDialog
            width={860}
        >
            <AgTechDataProvider
                loadData={async e => await e.executeRequest(invoiceDetailsRequest, props.invoiceId)}
                content={{
                    loadingText: 'Loading invoice details...'
                }}
            >
                {(invoiceDetails: InvoiceDetailsDTO) => (
                    <AgTechDataProvider
                        loadData={async executor => await executor.executeRequest(invoiceGetDocumentRequest, {
                            invoiceId: props.invoiceId
                        })}
                        content={{
                            loadingText: 'Loading invoice document...'
                        }}
                    >
                        {(invoiceDocument: Blob) => (
                            <InvoiceInfoDialogBody
                                invoice={{
                                    id: props.invoiceId,
                                    ...invoiceDetails,
                                    numberOfCreditsApplied: invoiceDetails.numberOfCreditsApplied ?? 0,
                                    numberOfDiscountsApplied: invoiceDetails.numberOfDiscountsApplied ?? 0
                                }}
                                invoiceDocument={invoiceDocument}
                                actions={props.actions}
                                showNextSteps={props.showNextSteps}
                            />
                        )}
                    </AgTechDataProvider>
                )}
            </AgTechDataProvider>
        </AgTechDialog>
    )
}

export type InvoiceInfoDialogBodyProps = {
    invoice: ProcessedSaleInvoice,
    invoiceDocument: Blob,
    actions?: {
        close?: () => void
    },
    showNextSteps: boolean
}

const InvoiceInfoDialogBody = (props: InvoiceInfoDialogBodyProps) => {
    let invoiceContact = useContact(props.invoice.contactId);
    let dialogContext = useDialogContext();

    if (!props.invoice || !props.invoiceDocument) {
        return (
            <AgTechNoRecordsPage
                message='Unable to find the invoice. Please try again'
            />
        )
    }

    var invoiceFile: File | undefined = props.invoiceDocument
        ? new File([props.invoiceDocument], props.invoice.id.toString(), { type: 'application/pdf' })
        : undefined;
    
    return (
        <div className='column-based stretched non-scrollable container'>
            <div className='flexed row-based tall right-bordered container'>
                <div className='row-based flexed container'>
                    <AgTechTitleDetailHeader
                        title={`Invoice #${props.invoice.invoiceNumber}`}
                        titleFontSize='font-size-larger'
                        detail={getContactFullName(invoiceContact)}
                        detailFontSize='font-size-smaller'
                        detailMargin={7}
                        classes='mb-3 px-4 py-3 bottom-bordered'
                    />
                    <div className='row-based px-4 py-3 container'>
                        <h1 className='font-bold font-size-smaller mb-2 ml-half'>Update</h1>
                        <AgTechBorderedList
                            items={[
                                () => (
                                    <div className='wide column-based-vertically-centered container'>
                                        <h1 className='font-size-small font-bold font-color-secondary ml-3'>Lots Invoiced</h1>
                                        <h1 className='font-size-small font-bold ml-auto'>{props.invoice.numberOfLots}</h1>
                                        <AgTechClickableIcon
                                            icon='bi bi-pencil-square'
                                            classes='px-3 py-2 ml-3 left-bordered'
                                            onClick={e => InvoiceChangeLotsDialog.Open(e, {
                                                invoiceId: props.invoice.id,
                                                contactId: props.invoice.contactId
                                            })}
                                        />
                                    </div>
                                ),
                                () => (
                                    <div className='wide column-based-vertically-centered container'>
                                        <h1 className='font-size-small font-bold font-color-secondary ml-3'>Discounts Applied</h1>
                                        <h1 className='font-size-small font-bold ml-auto'>{props.invoice.numberOfDiscountsApplied}</h1>
                                        <AgTechClickableIcon
                                            icon='bi bi-pencil-square'
                                            classes='px-3 py-2 ml-3 left-bordered'
                                            onClick={e => {
                                                InvoiceChangeDiscountsDialog.Open(e, {
                                                    invoiceId: props.invoice.id
                                                })
                                            }}
                                        />
                                    </div>
                                ),
                                () => (
                                    <div className='wide column-based-vertically-centered container'>
                                        <h1 className='font-size-small font-bold font-color-secondary ml-3'>Credits Used</h1>
                                        <h1 className='font-size-small font-bold ml-auto'>{props.invoice.numberOfCreditsApplied}</h1>
                                        <AgTechClickableIcon
                                            icon='bi bi-pencil-square'
                                            classes='px-3 py-2 ml-3 left-bordered'
                                            onClick={e => {
                                                InvoiceChangeContactCreditsDialog.Open(e, {
                                                    invoiceId: props.invoice.id,
                                                    contactId: props.invoice.contactId
                                                })
                                            }}
                                        />
                                    </div>
                                )
                            ]}
                        />
                    </div>
                    {props.showNextSteps ?
                    <div className='row-based px-4 py-3 container'>
                        <h1 className='font-bold font-size-smaller mb-2 ml-half'>Next Steps</h1>
                        <div className='row-based wide container'>
                            <AddPaymentDialog.Button
                                invoiceId={props.invoice.id}
                                classes='highlight wide'
                            />
                        </div>
                    </div>
                    :
                    null
                    }
                </div>
                <div className='mt-auto top-bordered px-5 py-4 container'>
                    <AgTechButton
                        text='Close'
                        icon='times'
                        action={e => dialogContext ? dialogContext.closeDialog(e.clickEvent) : null}
                        classes='wide'
                    />
                </div>
            </div>
            <div className='row-based tall centered py-5 bg-secondary container' style={{ width: 600 }}>
                {invoiceFile ? (
                    <>
                        <object
                            data={URL.createObjectURL(invoiceFile) + '#view=FitH'}
                            type='application/pdf'
                            className='tall bordered'
                            style={{ width: 520 }}
                        />
                    </>
                )
                : null}
            </div>
        </div>
    )
}

InvoiceInfoDialog.Open = (e: AgTechClickEventHandler, props: InvoiceInfoDialogProps) => e.dialogs.openDialog({
    dialog: () => <InvoiceInfoDialog {...props} />
});

InvoiceInfoDialog.Button = (props: InvoiceInfoDialogProps & AgTechButtonProps) => {
    return (
        <AgTechButton
            text='View'
            icon='eye'
            {...props}
            action={e => InvoiceInfoDialog.Open(e, props)}
        />
    )
}

export default InvoiceInfoDialog