import { executeWithMinimumDelay } from 'agtech/core/utilities/AgTechUtilities';
import { AgTechWebAppHeaderButton } from 'agtech/web/components/App/Layout/AgTechWebAppHeader';
import AgTechActionPane from 'agtech/web/components/Headers/AgTechActionPane';
import SaleDialog from 'app/areas/sales/dialogs/SaleDialog';
import { useCurrentSale } from 'app/data/sales/SaleData';
import { useSaleDataChangeSelectedSaleAction } from 'app/data/sales/SaleDataActions';
import React from 'react'

const SalesAppHeader = () => {
    let currentSale = useCurrentSale();
    let saleChangeAction = useSaleDataChangeSelectedSaleAction();

    return (
        <AgTechActionPane
            elements={[
                <AgTechWebAppHeaderButton
                    bootstrapIcon='bi-plus-lg'
                    styles={{ fontSize: 32 }}
                    action={e => SaleDialog.Open(e, {})}
                    tooltip='Create a new sale'
                />,
                <AgTechWebAppHeaderButton
                    bootstrapIcon='bi-arrow-clockwise'
                    styles={{ fontSize: 24 }}
                    action={async e => {
                        await e.actions.executeDataActionWithVisual(
                            saleChangeAction,
                            { submittedEntity: currentSale.id },
                            { message: 'Refreshing sale data...', delay: 1000 }
                        )
                    }}
                    tooltip='Refresh current sale data'
                />
            ]}
        />
    )
}

export default SalesAppHeader