import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import AgTechList from 'agtech/web/components/Lists/AgTechList';
import AgTechNoRecordsPage, { AgTechNoRecordsPageProps } from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import AgTechFutureLoadingSurface, { AgTechLoader } from 'agtech/web/components/Pages/Loading/AgTechFutureLoadingSurface';
import AgTechSearchControl, { AgTechCoreSearchControlProps, AgTechSearchControlState } from 'agtech/web/components/Widgets/AgTechSearchControl';
import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'

export type AgTechListDetailBaseItem = {
    id: any
}

export type AgTechListDetailPageProps<TItem extends AgTechListDetailBaseItem> = {
    items: TItem[],
    itemConfig: AgTechlistDetailPageItemConfiguration<TItem>,
    itemSurface: (entity: TItem) => React.ReactNode,
    selectedItem?: TItem,
    searchConfig?: AgTechCoreSearchControlProps<TItem>,
    noRecordsConfig: AgTechNoRecordsPageProps
}

export type AgTechListDetailPageSearchConfiguration<TItem extends AgTechListDetailBaseItem> = {
    itemSearchKey?: (entity: TItem) => string,
}

export type AgTechlistDetailPageItemConfiguration<TItem extends AgTechListDetailBaseItem> = {
    height: number,
    width?: number,
    content: (entity: TItem) => React.ReactNode,
    classes?: string,
    styles?: CSSProperties
}

const AgTechListDetailPage = <TItem extends AgTechListDetailBaseItem>(props: PropsWithChildren<AgTechListDetailPageProps<TItem>>) => {
    let [activeItemId, setActiveItemId] = useState<any>(props.selectedItem?.id ?? props.items[0]?.id ?? 0);
    let [searchState, setSearchState] = useState<AgTechSearchControlState<TItem> | undefined>(undefined)

    if (props.items.length === 0) {
        return <AgTechNoRecordsPage {...props.noRecordsConfig} />
    }

    let activeItems = searchState !== undefined && searchState.filter !== ""
        ? props.items.filter(item => searchState?.selectedField.value(item)?.toLowerCase().includes(searchState.filter.toLowerCase()))
        : props.items;

    let activeItem = activeItems.filter(item => item.id === activeItemId)[0] ?? activeItems[0] ?? undefined;

    return (
        <div className="column-based stretched container">
            <div className={`row-based right-bordered container`} style={{ width: props.itemConfig.width ?? 240 }}>
                { props.searchConfig ? (
                    <AgTechListDetailSearchControl
                        searchConfig={props.searchConfig}
                        setSearchState={setSearchState}
                    />
                ) : null }
                <div className={`row-based wide flexed non-scrollable container`}>          
                    <AgTechListDetailItemList
                        items={activeItems}
                        itemConfig={props.itemConfig}
                        activeItem={activeItem}
                        setActiveItem={item => setActiveItemId(item.id)}
                    />
                </div>
            </div>
            <div className={`flexed p-6`}>
                <AgTechLoading
                    refreshWhenChanged={activeItem?.id}
                    content={() => activeItem ? props.itemSurface(activeItem) : <h1>No item</h1>}
                />
                {/* {activeItem ? (
                    <div key={activeItem?.id} className='stretched fade-in-400'>
                        {props.itemSurface(activeItem)}
                    </div>
                ) : <h1>No item</h1>} */}
            </div>
        </div>
    )
};


export const AgTechLoading = (props: { refreshWhenChanged: string, content: () => React.ReactNode }) => {
    let [currentKey, setShowLoader] = useState('');

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(props.refreshWhenChanged);
        }, 400);
    },
    [props.refreshWhenChanged]);

    return (
        <>
            { currentKey !== props.refreshWhenChanged ? <AgTechLoader text='' /> : props.content()  }
        </>
    )
}

declare type AgTechListDetailSearchControlProps<TItem extends AgTechListDetailBaseItem> = {
    searchConfig: AgTechCoreSearchControlProps<TItem>,
    setSearchState: (searchState: AgTechSearchControlState<TItem>) => void
}

const AgTechListDetailSearchControl = <TItem extends AgTechListDetailBaseItem>(props: AgTechListDetailSearchControlProps<TItem>) => {
    return (
        <div className="px-3 py-2 bg-light-gray bottom-bordered wide" style={{ height: 50 }}>
            <AgTechSearchControl
                {...props.searchConfig}
                onUpdateSearchState={updated => props.setSearchState(updated)}
            />
        </div>
    )
}

declare type AgTechListDetailListProps<TItem extends AgTechListDetailBaseItem> = {
    items: TItem[],
    itemConfig: AgTechlistDetailPageItemConfiguration<TItem>,
    activeItem?: TItem,
    setActiveItem: (item: TItem) => void
}

const AgTechListDetailItemList = <TItem extends AgTechListDetailBaseItem>(props: AgTechListDetailListProps<TItem>) => {
    return (
        <AgTechList
            items={props.items}
            itemHeight={props.itemConfig.height}
            itemRow={item => (
                <AgTechListDetailItemListItemCard
                    item={item}
                    itemConfig={props.itemConfig}
                    isItemActiveItem={item.id === props.activeItem?.id}
                    setActiveItem={props.setActiveItem}
                />
            )}
            listClasses='scrollbar-white'
        />
    )
}

declare type AgTechListDetailItemListItemCardProps<TItem extends AgTechListDetailBaseItem> = {
    item: TItem,
    itemConfig: AgTechlistDetailPageItemConfiguration<TItem>,
    isItemActiveItem: boolean,
    setActiveItem: (item: TItem) => void
}

const AgTechListDetailItemListItemCard = <TItem extends AgTechListDetailBaseItem>(props: AgTechListDetailItemListItemCardProps<TItem>) => {
    let itemActiveClasses = props.isItemActiveItem ? 'bg-highlight-light' : '';
    let itemClasses = props.itemConfig.classes ?? '';

    // TODO: Hover over active item overrides active color

    return (
        <div
            className={`column-based-vertically-centered stretched bottom-bordered clickable bg-white pr-6 hover-bg-highlight-lightest container ${itemActiveClasses} ${itemClasses}`}
            tabIndex={1}
            onClick={() => {
                // Only update state if the selected item has changed
                if (!props.isItemActiveItem) {
                    props.setActiveItem(props.item);
                }
            }}
        >
            <div className={`tall mr-4 ${props.isItemActiveItem ? 'bg-highlight' : 'bg-transparent'}`} style={{ width: 5 }} />
            <div className="flexed vertically-centered container">
                {props.itemConfig.content(props.item)}
            </div>
        </div>
    )
}

export default AgTechListDetailPage;