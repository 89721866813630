import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import { AgTechTitleDetailHeaderProps } from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import AgTechList, { AgTechListProps } from 'agtech/web/components/Lists/AgTechList'
import React, { useEffect, useRef, useState } from 'react'

export type AgTechListPopUpProps<TListItem extends { id: any }> = {
    header: AgTechTitleDetailHeaderProps,
    list: AgTechListProps<TListItem>,
    footer?: () => React.ReactNode,
    maxItemsToShow: number,
    width?: number
}

const AgTechListPopUp = <TListItem extends { id: any }>(props: AgTechListPopUpProps<TListItem>) => {
    let itemsInList = props.list.items.length;
    let itemsToShow = itemsInList > props.maxItemsToShow ? props.maxItemsToShow : itemsInList;

    return (
        <div className='stretched row-based container' style={{ width: props.width ?? '100%' }}>
            <AgTechTitleDetailHeader
                classes='px-3-half py-2 bottom-bordered'
                {...props.header}
            />
            <AgTechList
                {...props.list}
                listClasses='flexed'
                listStyles={{
                    height: itemsToShow * props.list.itemHeight
                }}
            />
            {props.footer ? (
                <div className='wide px-3-half py-2 top-bordered container'>
                    {props.footer()}
                </div>
            ) : null}
        </div>
    )
}

export default AgTechListPopUp