import { isDev } from './../../utilities/AgTechUtilities';
import { logDev, logDevError, logEvent } from '../../logging/AgTechLogger';
import { IAgTechAppContext, useAppContext } from "agtech/core/app/AgTechAppContext";
import { useAgTechAuthContext } from "agtech/core/auth/AgTechAuthContext";
import { useAgTechDataContext } from "agtech/core/data/common/AgTechDataContext";
import { AgTechHttpResponse, AgTechHttpResponses } from "agtech/core/data/actions/AgTechHttpRequests";
import { AgTechError } from "agtech/core/exceptions/AgTechExceptions";
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType as AxiosResponseType, Method as AxiosHttpMethod, AxiosError } from "axios";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { AgTechDataActionLoggingContext } from '../actions/AgTechDataActions';

///
/// Requests
///

export type AgTechHttpRequestProps = {
    path: string,
    baseURL?: string
}

/// Building Requests

export type AgTechHttpRequestBuildingProps = {
    path: string,
    baseURL: string,
    onError?: (error: AgTechApiError) => Promise<void>,
    appContext: IAgTechAppContext
    token: string,
    logout: () => Promise<void>
}

export type AgTechHttpRequestConfiguration = AgTechHttpRequestBuildingProps & AgTechApiResponseHandlingHelpers;
export type AgTechHttpRequestConfigurationBuilder = (requestProps: AgTechHttpRequestProps) => AgTechHttpRequestConfiguration;

export const useAgTechHttpRequestConfigurationBuilder = (): AgTechHttpRequestConfigurationBuilder => {
    let appContext = useAppContext();
    let authContext = useAgTechAuthContext();
    let dataContext = useAgTechDataContext();
    let dispatch = useDispatch();
    
    return requestProps => ({
        path: requestProps.path,
        baseURL: requestProps.baseURL ?? dataContext.baseURL,
        token: authContext.user.token,
        logout: authContext.logoutUser,
        appContext: appContext,
        dispatch: dispatch
    })
};

/// Executing Requests

export type AgTechApiCoreRequestExecutionProps<TData> = {
    requestConfiguration: AgTechHttpRequestConfiguration,
    requestResponseHandlers?: AgTechApiResponseHandlers<TData>
}

export type AgTechApiRequestExecutionProps<TInputData, TResponseData> = AgTechApiCoreRequestExecutionProps<TResponseData> & {
    requestType: AxiosHttpMethod,
    responseType?: AxiosResponseType,
    requestData?: TInputData,
    request: AgTechInternalAxiosApiRequest<TInputData, TResponseData>,
    loggingContext?: AgTechDataActionLoggingContext<TInputData, TResponseData>
}

export const executeAgTechApiRequest = async <TInputData, TResponseData>(
    props: AgTechApiRequestExecutionProps<TInputData, TResponseData>
): Promise<AgTechHttpResponse<TResponseData>> =>
{
    return await props.request({
        requestURL: `${props.requestConfiguration.baseURL}/api/${props.requestConfiguration.path}`,
        requestData: props.requestData,
        requestConfig: {
            responseType: props.responseType,
            headers: { 
                Authorization: `Bearer ${props.requestConfiguration.token}`
            }
        }
    })
    .then(async response => {
        logEvent('API Request Successful', {
            responseData: response.data
        });

        // TODO: Need to handle failure 200s from server

        return await handleSuccessfulApiResponse({
            ...props.requestConfiguration,
            response: {
                wasSuccessful: true,
                data: response.data
            },
            handlers: props.requestResponseHandlers,
        });
    })
    .catch(async error => {
        logEvent('API Request Failed', error);

        let responseError: AgTechApiError = {
            message: 'An error occurred, please try again',
            shouldToast: true,
            statusCode: 500
        }

        if (axios.isAxiosError(error)) {
            let axiosError = error as AxiosError;

            if (axiosError.response?.status === 401) {
                props.requestConfiguration.logout();
            }

            let errorMessageShownToUser = 'An error occurred, please refresh the application';

            logEvent('HttpRequestFailed', {
                response: axiosError.response
            });
            
            if (isDev()) {
                let responseData = axiosError.response?.data;
                let responseErrorMessage = responseData?.errorMessages ? responseData.errorMessages[0]?.message : '';

                if (responseErrorMessage) {
                    responseErrorMessage = responseErrorMessage.substring(0, 200);
                }

                errorMessageShownToUser = `An axios error occurred: ${axiosError.response?.status ?? '500'} | ${responseErrorMessage ?? axiosError.message}`;
            }

            responseError = {
                message: errorMessageShownToUser,
                shouldToast: true,
                statusCode: error.response?.status ?? 403
            };
        }

        return await handleFailedApiResponse({
            ...props.requestConfiguration,
            error: responseError,
            handlers: props.requestResponseHandlers
        });
    });
}

export type AgTechInternalAxiosRequestProps<TInputData> = {
    requestURL: string,
    requestData?: TInputData,
    requestConfig: AxiosRequestConfig<any>
}

export type AgTechInternalAxiosApiRequest<TInputData, TResponseData> = (props: AgTechInternalAxiosRequestProps<TInputData>) =>
    Promise<AxiosResponse<any, TResponseData>>;

///
/// Responses
///

export type AgTechApiResponse<TData> = {
    wasSuccessful: boolean,
    data: TData,
    error?: AgTechApiError,
}

export type AgTechApiError = AgTechError & {
    statusCode: number
}

///
/// Response Handling
///

export type AgTechApiResponseHandlingHelpers = {
    appContext: IAgTechAppContext,
    dispatch: Dispatch<any>
};

export type AgTechApiResponseHandlers<TResponseData> = {
    onSuccess?: AgTechApiSuccessfulResponseHandler<TResponseData>, 
    onError?: AgTechApiFailedResponseHandler<TResponseData>
}

export type AgTechApiResponseHandlingProps<TData> = AgTechApiResponseHandlingHelpers & {
    handlers?: AgTechApiResponseHandlers<TData>
}

/// Successful Responses

export type AgTechApiCoreSuccessfulResponseHandlingProps<TData> = AgTechApiResponseHandlingProps<TData> & {
    response: AgTechApiResponse<TData>
}

export type AgTechApiSuccessfulResponseHandlingProps<TData> = AgTechApiCoreSuccessfulResponseHandlingProps<TData> & {
    body: TData
}

export type AgTechApiSuccessfulResponseHandler<TResponseData> = (successfulResponse: AgTechApiSuccessfulResponseHandlingProps<TResponseData>) => 
    Promise<AgTechHttpResponse<TResponseData> | void> | AgTechHttpResponse<TResponseData> | void

export const handleSuccessfulApiResponse = async <TData, >(props: AgTechApiCoreSuccessfulResponseHandlingProps<TData>) => {
    let dataActionResponse = AgTechHttpResponses.Success(props.response.data);

    if (props.handlers?.onSuccess) {
        let handledSuccess = await props.handlers.onSuccess({
            ...props,
            body: props.response.data
        });

        if (handledSuccess instanceof Object) {
            dataActionResponse = handledSuccess as AgTechHttpResponse<TData>;
        }
    }

    return dataActionResponse;
}

/// Failed Responses

export type AgTechApiCoreFailedResponseHandlingProps<TData> = AgTechApiResponseHandlingProps<TData> & {
    error: AgTechApiError,
}

export type AgTechApiFailedResponseHandlingProps<TData> = AgTechApiCoreFailedResponseHandlingProps<TData> & {
    showError: (errorMessage: string) => void
}

export type AgTechApiFailedResponseHandler<TData> = (props: AgTechApiFailedResponseHandlingProps<TData>) => 
    Promise<AgTechHttpResponse<TData> | void> | AgTechHttpResponse<TData> | void

export const handleFailedApiResponse = async <TData, >(props: AgTechApiCoreFailedResponseHandlingProps<TData>) => {
    let failedDataActionResponse = AgTechHttpResponses.Failed(props.error);

    if (props.handlers?.onError) {
        let handledError = await props.handlers.onError({
            ...props,
            error: props.error,
            handlers: props.handlers,
            showError: errorMessage => props.appContext.messaging.showError({
                header: errorMessage
            })
        });

        if (handledError instanceof Object) {
            failedDataActionResponse = handledError as AgTechHttpResponse<TData>;
        }
    }
    else {
        logDevError(props.error.message);

        if (process.env.NODE_ENV !== 'development') {
            props.appContext.messaging.showError({
                header: 'An error occurred. Please try again.'
            });
        }
    }

    if (isDev()) {
        props.appContext.messaging.showError({
            header: 'An error occurred reaching out to the API',
            details: props.error.message
        });
    }

    return failedDataActionResponse;
}