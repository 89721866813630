import React, { CSSProperties, MutableRefObject, useContext, useRef } from 'react'
import { AgTechWebAppContext, useAgTechWebAppContext } from 'agtech/web/components/App/AgTechWebAppContext';
import { AgTechModalRenderProps } from 'agtech/web/components/Portals/Modals/AgTechModal';
import { AgTechDialogRenderProps } from 'agtech/core/functions/AgTechAppDialogs';
import { AgTechPopUpRenderProps } from '../App/Functions/AgTechPopUps';
import { AgTechPopUpCoreProps, AgTechPopUpPosition } from '../Portals/Popups/Helpers/AgTechPopUpData';
import AgTechPopUpOnHoverContainer, { AgTechPopUpOnHoverText } from 'agtech/web/components/Portals/Popups/AgTechPopUpOnHoverContainer';
import { IAgTechAppDataActions } from 'agtech/core/functions/AgTechAppDataActions';
import { buildDataActionExecutor, useDataActionExecutionContextProps } from 'agtech/core/data/actions/AgTechDataActions';
import { useAgTechTooltip } from 'agtech/web/components/Portals/Popups/AgTechTooltipContainer';
import { useAgTechAuthContext } from 'agtech/core/auth/AgTechAuthContext';
import { executeWithMinimumDelay } from 'agtech/core/utilities/AgTechUtilities';
import { AgTechActionModal } from 'agtech/web/components/Portals/Modals/AgTechActionModal';

export interface IShowDialog {
    openDialog: (dialogRendering: AgTechDialogRenderProps) => void
}

export interface IShowPopUp {
    openPopup: (popupRendering: AgTechPopUpRenderProps) => void
}
export interface IShowModal {
    openModal: (modal: AgTechModalRenderProps) => void
}

export interface IShowToasts {
    showError: (errorMessage: string) => void
}

export type AgTechRendering = {
    rendering: () => React.ReactNode
}

export type AgTechPopUpRendering = AgTechRendering & {
    position?: AgTechPopUpPosition,
    emphasizedShadow?: boolean
}

export type AgTechWebClickable = AgTechWebAppContext & {
    actions: IAgTechAppDataActions,
    clickableRef: React.RefObject<any>,
    renderPopup: (popup: () => React.ReactNode) => void,
};

export const useClickable = (ref: React.RefObject<any>): AgTechWebClickable => {
    let webAppContext = useAgTechWebAppContext();
    let dataActionExecutionProps = useDataActionExecutionContextProps();

    let buttonClickable: AgTechWebClickable = {
        ...webAppContext,
        actions: {
            executeDataAction: async (action, executionProps) => {
                return await buildDataActionExecutor(dataActionExecutionProps).executeAction(action, {
                    submittedEntity: executionProps.submittedEntity,
                    originalEntity: executionProps.originalEntity
                });
            },
            executeDataActionInModal: async (action, props) => {
                webAppContext.modals.showActionModal(action, props);
            },
            executeDataActionWithVisual: async (action, executionProps, visualProps) => {
                let actionExecutor = buildDataActionExecutor(dataActionExecutionProps);
                let loadingModalId = '';

                let result = await executeWithMinimumDelay(async () => {
                    return await actionExecutor.executeAction(action, {
                        submittedEntity: executionProps.submittedEntity,
                        originalEntity: executionProps.originalEntity,
                        handlers: {
                            onActionExecuted: async () => {
                                loadingModalId = webAppContext.modals.showLoader(visualProps.message);
                            }
                        }
                    });
                },
                visualProps.delay);

                if (loadingModalId) {
                    webAppContext.modals.closeModal(loadingModalId);
                }

                return result;
            },
            executeWithVisual: async (action, visualProps) => {
                let loadingModalId = webAppContext.modals.showLoader(visualProps.message);

                let result = await executeWithMinimumDelay(async () => {
                    await action();
                },
                visualProps.delay);

                webAppContext.modals.closeModal(loadingModalId);

                return result;
            }
        },
        clickableRef: ref,
        renderPopup: popup => webAppContext.popups.showPopUp({
            callerRef: ref,
            popup: popup
        })
    };

    return buttonClickable;
}

export type AgTechClickEventHandler = AgTechWebClickable & {
    clickEvent: React.MouseEvent<HTMLElement>
}

export type AgTechButtonProps = {
    text?: string,
    icon?: string,
    bootstrapIcon?: string,
    iconOnLeft?: boolean,
    action?: (handler: AgTechClickEventHandler) => any | Promise<any>,
    children?: any,
    classes?: string,
    textClasses?: string,
    iconClasses?: string,
    disableIf?: boolean,
    styles?: React.CSSProperties,
    height?: number,
    tooltip?: string,
    role?: string
}

export const AgTechButton = React.forwardRef<HTMLButtonElement, AgTechButtonProps>((props, ref) => {
    let buttonRef = useRef<HTMLButtonElement>();
    let buttonClickable = useClickable(buttonRef);
    let authContext = useAgTechAuthContext();

    useAgTechTooltip<HTMLButtonElement>({
        containerRef: buttonRef,
        tooltip: props.tooltip
    });

    let buttonIconOnLeft = props.iconOnLeft ?? true;

    let buttonClasses = `agtech-button ${props.classes ?? ''}`
    let buttonIconClasses = `agtech-button-icon agtech-icon fas fa-${props.icon ?? ''} ${props.bootstrapIcon ?? ''} ${props.iconClasses ?? ''}`;

    let handleButtonClick = async (e: React.MouseEvent<HTMLElement>) => {
        if (props.action) {
            await props.action({
                ...buttonClickable,
                clickEvent: e
            });
        }
    }

    let canUserSeeButton = true;

    if (props.role) {
        canUserSeeButton = authContext.user.role.findIndex(f => f === props.role) > -1;
    }

    let hasIcon = props.icon !== undefined || props.bootstrapIcon !== undefined;

    return canUserSeeButton ? (
        <button
            disabled={props.disableIf}
            className={buttonClasses}
            onClick={handleButtonClick} ref={(button) => {
                if (button) {
                    buttonRef.current = button;

                    if (ref) {
                        (ref as MutableRefObject<HTMLButtonElement>).current = button;
                    }
                }
            }}
            style={{
                height: props.height ?? undefined,
                minHeight: props.height ?? undefined,
                ...props.styles
            }}
        >
            {hasIcon && buttonIconOnLeft ? <i className={buttonIconClasses} aria-hidden="true"></i> : null}
            {props.text}
            {hasIcon && !buttonIconOnLeft ? <i className={buttonIconClasses + ' icon-right'} aria-hidden="true"></i> : null}
            {props.children}
        </button>
    ) : null;
});

export type AgTechActionButtonProps = {
    icon?: string,
    classes?: string
}

export const AgTechActionButton = (props: React.PropsWithChildren<AgTechActionButtonProps>) => {
    let actionButtonRef = useRef(null);
    let actionButtonClickable = useClickable(actionButtonRef);
    let actionButtonIcon = props.icon ?? 'fas fa-ellipsis-v';

    let handleActionButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        actionButtonClickable.renderPopup(() => props.children);
    }

    return (
        <div className={`agtech-action-button clickable ${props.classes ?? ''}`} ref={actionButtonRef} onClick={handleActionButtonClick}>
            <i className={`agtech-icon agtech-actions-icon ${actionButtonIcon}`} />
        </div>
    );
}

export type AgTechLinkProps = {
    content: string,
    body?: () => React.ReactNode,
    classes?: string,
    styles?: CSSProperties,
    onClick?: (e: AgTechClickEventHandler) => void,
    onHover?: () => AgTechPopUpCoreProps
}

export const AgTechLink = (props: AgTechLinkProps) => {
    let agtechLinkRef = useRef(null);
    let linkClickable = useClickable(agtechLinkRef);

    let handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
        if (props.onClick) {
            e.stopPropagation();
            
            props.onClick({
                ...linkClickable,
                clickEvent: e
            });
        }
    }

    return props.onHover ? (
        <div className={'column-based container'} >
            <AgTechPopUpOnHoverText
                classes={`${props.onClick ? 'agtech-link' : ''} ${props.classes ?? ''}`}
                refreshWhenChanged={props.content}
                popupRenderProps={props.onHover}
                text={props.content}
                onClicked={props.onClick}
            />
            {props.body?.()}
        </div>

    ) : (
        <div
            className={`font-size-small inline-block ${props.onClick ? 'agtech-link' : ''} ${props.classes ?? ''}`} 
            style={props.styles} ref={agtechLinkRef}
            onClick={handleLinkClick}
        >
            {props.content}
            {props.body?.()}
        </div>
    )
}

export type AgTechFileUploadButtonProps = AgTechButtonProps & {
    onFileUploaded: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
    acceptTypes?: string
}

export const AgTechFileUploadButton = (props: AgTechFileUploadButtonProps) => {
    let buttonIconOnLeft = props.iconOnLeft ?? true;
    let buttonIconClasses = `agtech-button-icon agtech-icon fas fa-${props.icon} ${props.iconClasses ?? ''}`;

    return (
        <label className={`agtech-button ${props.classes ?? ''}`} style={{...props.styles}}>
            <form encType="multipart/form-data">
                { props.acceptTypes === undefined ? <input id="image-file" type="file" onChange={props.onFileUploaded} style={{ display: 'none' }} />
                : <input id="image-file" type="file" accept={props.acceptTypes} onChange={props.onFileUploaded} style={{ display: 'none' }} />
                }
            </form>
            {props.icon && buttonIconOnLeft ? <i className={buttonIconClasses} aria-hidden="true"></i> : null}
            {props.text}
            {props.icon && !buttonIconOnLeft ? <i className={buttonIconClasses + ' icon-right'} aria-hidden="true"></i> : null}
            {props.children}
        </label>
    )
}