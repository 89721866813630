import AgTechDataActionSurface from 'agtech/core/data/actions/AgTechDataActionSurfaces';
import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechForm, AgTechFormRow, AgTechFormTextControl } from 'agtech/web/components/Forms/AgTechForm';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import AgTechWizardModal, { AgTechWizardModalStepProps } from 'agtech/web/components/Portals/Modals/AgTechWizardModal';
import ContactImportModal from 'app/areas/contacts/modals/ContactImportModal';
import SaleDiscountViewGrid from 'app/areas/discounts/components/grids/SaleDiscountViewGrid';
import LotCategoryGrid from 'app/areas/lots/components/grids/LotCategoryGrid';
import LogoUpdateForm from 'app/areas/operation/components/forms/LogoUpdateForm';
import { DefaultDocumentHeaderConfiguration, useOperationData } from 'app/data/operation/SaleDataOperation';
import { useOperationDataSetupOperationAction, useOperationDataUpdateDocumentConfigurationAction } from 'app/data/operation/SaleDataOperationActions';
import React from 'react'

const OperationSetupModal = () => {
    let setupAction = useOperationDataSetupOperationAction();

    return (
        <AgTechWizardModal
            data={{
                loadInitialFormData: () => ({})
            }}
            completionAction={{
                dataAction: setupAction,
                getActionData: e => e
            }}
            structure={{
                title: 'Operation Setup',
                details: 'Follow the steps below to setup your operation',
                steps: [
                    {
                        name: 'Upload Your Logo',
                        page: props => <OperationSetupUploadLogoPage {...props} />
                    },
                    { name: 'Import Contacts', page: props => <OperationSetupImportContactsPage {...props} />},
                    { name: 'Enter Sale Discounts', page: props => <OperationSetupInputSaleDiscountsPage {...props} />},
                    { name: "Enter Lot Categories", page: props => <OperationSetupInputLotCategoryPage {...props} />},
                    {
                        name: 'Address for documents',
                        page: props => <OperationSetupInformationPage {...props} />
                    }
                ],
                submissionButtonText: 'Complete Setup'
            }}
            behavior={{
                allowCancel: false
            }}
        />
    )
}

OperationSetupModal.Button = (props: AgTechButtonProps) => {
    return (
        <AgTechButton
            text='Setup My Operation'
            icon='bi bi-gear'
            {...props}
            action={e => e.modals.showModal({
                height: '90%',
                width: '1200px',
                content: () => <OperationSetupModal />,
                showCloseButton: false
            })}
        />
    )
}

export type OperationSetupInformationPageProps = AgTechWizardModalStepProps<{}> & {

}

const OperationSetupInformationPage = (props: OperationSetupInformationPageProps) => {
    var operationData = useOperationData();

    var configData = {
        ...DefaultDocumentHeaderConfiguration,
        ...operationData.documentHeaderConfiguration,
        address: operationData.address,
        city: operationData.city,
        stateAbbreviation: operationData.stateAbbreviation,
        zip: operationData.zip
    };
    
    let invoiceConfigurationSubmission = useOperationDataUpdateDocumentConfigurationAction();
    
    return (
        <AgTechDataActionSurface
            action={invoiceConfigurationSubmission}
        >
            {() => (
                <AgTechForm
                    structure={{
                        header: {
                            title: 'Change the address that will be used on documents',
                        },
                        submitButton: props.nextButton
                    }}
                    initialFormData={configData}
                    action={{
                        dataAction: invoiceConfigurationSubmission,
                        getActionData: d => d
                    }}
                >
                    <div className='row-based tall container' style={{ maxWidth: 600 }}>
                        <AgTechFormRow>
                            <AgTechFormTextControl
                                entityType={configData}
                                caption='Before Address Text'
                                focused={true}
                                value={entity => entity.preAddressLine ?? ""}
                                width={100}
                                onControlValueChanged={async (e, val) => ({
                                    ... e,
                                    preAddressLine: val
                                })}
                                />
                        </AgTechFormRow>
                        <AgTechFormRow>
                            <AgTechFormTextControl
                                entityType={configData}
                                caption="Address"
                                value={entity => entity.addressOverride ?? ''}
                                width={100}
                                placeHolder={configData.address}
                                onControlValueChanged={async (e, val) => ({
                                    ...e,
                                    addressOverride: val
                                })}
                            />
                        </AgTechFormRow>
                        <AgTechFormRow>
                            <AgTechFormTextControl
                                entityType={configData}
                                caption="City"
                                value={entity => entity.addressCityOverride ?? ''}
                                width={100}
                                placeHolder={configData.city}
                                onControlValueChanged={async (e, val) => ({
                                    ...e,
                                    addressCityOverride: val
                                })}
                            />
                            <AgTechFormTextControl
                                entityType={configData}
                                caption="State"
                                value={entity => entity.addressStateOverride ?? ''}
                                width={100}
                                placeHolder={configData.stateAbbreviation}
                                onControlValueChanged={async (e, val) => ({
                                    ...e,
                                    addressStateOverride: val
                                })}
                            />
                            <AgTechFormTextControl
                                entityType={configData}
                                caption="Zip"
                                value={entity => entity.addressZipOverride ?? ''}
                                width={100}
                                placeHolder={configData.zip}
                                onControlValueChanged={async (e, val) => ({
                                    ...e,
                                    addressZipOverride: val
                                })}
                            />
                        </AgTechFormRow>
                        <AgTechFormRow>
                            <AgTechFormTextControl
                                entityType={configData}
                                caption="After Address Text"
                                value={entity => entity.postAddressLine ?? ''}
                                width={100}
                                onControlValueChanged={async (e, val) => ({
                                    ...e,
                                    postAddressLine: val
                                })}
                            />
                        </AgTechFormRow>
                    </div>
                </AgTechForm>
            )}
        </AgTechDataActionSurface>
    )
}

const OperationSetupUploadLogoPage = (props: OperationSetupInformationPageProps) => {

    return (
        <div className='stretched row-based container'>
            <AgTechTitleDetailHeader
                title='Upload Operation Logo (optional)'
                titleFontSize='font-size-large'
                detail='If your operation has a logo, upload it here. This logo will be used on your invoices and other documents.'
                detailFontSize='font-size-standard'
                detailMargin={8}
            />
            <LogoUpdateForm />
        </div>
    )
}

const OperationSetupImportContactsPage = (props: OperationSetupInformationPageProps) => {
    return (
        <div className='stretched row-based container'>
            <AgTechTitleDetailHeader
                title='Import Contacts (optional)'
                titleFontSize='font-size-large'
                detail="If available, please import your operation's contact list."
                detailFontSize='font-size-standard'
                detailMargin={8}
            />
            <div className='flexed row-based centered container'>
                <ContactImportModal.Button classes='highlight'/>
            </div>
        </div>
    )
}

const OperationSetupInputSaleDiscountsPage = (props: OperationSetupInformationPageProps) => {
    return (
        <div className='stretched row-based container'>
            <AgTechTitleDetailHeader
                title='Enter Sale Discounts (optional)'
                titleFontSize='font-size-large'
                detail="Sale discounts can be applied to lot sales during invoicing. If you are not ready to input your discounts right now, sale discounts can be updated at any time."
                detailFontSize='font-size-standard'
                detailMargin={8}
            />
            <div className='flexed row-based pt-6 container'>
                <SaleDiscountViewGrid />
            </div>
        </div>
    )
}

const OperationSetupInputLotCategoryPage = (props: OperationSetupInformationPageProps) => {
    return (
        <div className='stretched row-based container'>
            <AgTechTitleDetailHeader
                title='Enter Lot Categories (optional)'
                titleFontSize='font-size-large'
                detail="Lot categories can be added to a lot. You can tag a lot with a certain category and we will calculate totals and allow filtering on them."
                detailFontSize='font-size-standard'
                detailMargin={8}
            />
            <div className='flexed row-based pt-6 container'>
                <LotCategoryGrid />
            </div>
        </div>
    )
}

export default OperationSetupModal