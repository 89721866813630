import AgTechCollapsibleContainer from "agtech/web/components/Layout/AgTechCollapsibleContainer";
import { CSSProperties, useState } from "react";

export type AgTechFormSectionProps = {
    header: string,
    headerContent?: () => React.ReactNode,
    collapsible?: boolean,
    isCollapsed?: boolean,
    maxHeight?: number,
    canOverflow?: boolean,
    classes?: string,
    bodyClasses?: string,
    styles?: CSSProperties
};

export const AgTechFormSection = (props: React.PropsWithChildren<AgTechFormSectionProps>) => {
    let [isSectionCollapsed, setIsSectionCollapsed] = useState<boolean>(props.isCollapsed ?? false);

    let sectionHeaderClass = props.collapsible ? 'agtech-clickable' : '';

    let handleFormSectionHeaderClicked = () => {
        if (props.collapsible) {
            setIsSectionCollapsed(!isSectionCollapsed);
        }
    }

    let sectionStyles = {
        ...props.styles,
        overflow: props.canOverflow ? 'auto' : undefined
    }

    return (
        <div className={`row-based container pt-1 ${props.classes ?? ''}`} style={sectionStyles}>
            <div className={`column-based-vertically-centered font-size-small bottom-bordered highlight-bordered font-bold pt-2 pb-1-half pl-1 pr-2 mb-2 container ${sectionHeaderClass}`}  onClick={handleFormSectionHeaderClicked}>
                {props.header}
                <div className='flexed' />
                { props.headerContent ? props.headerContent() : null }
                { props.collapsible ? <i className={`fas fa-${isSectionCollapsed ? 'chevron-down' : 'chevron-up'}`} /> : null }
            </div>

            { props.collapsible ? (
                <AgTechCollapsibleContainer isCollapsed={isSectionCollapsed} classes={`agtech-form-section-body column-based container ${props.bodyClasses ?? ''}`}>
                    {props.children}
                </AgTechCollapsibleContainer>
            ) : (
                <div className={`row-based px-1 container ${props.bodyClasses ?? ''}`}>
                    {props.children}
                </div>
            )}
        </div>
    );
}