import { useClickable } from 'agtech/web/components/Buttons/AgTechButtons';
import OperationLogoUpdateDialog from 'app/areas/operation/dialogs/OperationLogoUpdateDialog';
import { useOperationLogo } from 'app/data/operation/SaleDataOperation';
import React, { useRef } from 'react'

const OperationLogo = (props: { height: number, width: number }) => {
    let operationLogo = useOperationLogo();

    let logoRef = useRef<HTMLImageElement>(null);
    let logoClickable = useClickable(logoRef);

    return (
        <img
            title="Operation Logo"
            src={`data:;base64,${operationLogo}`}
            height={props.height}
            width={props.width}
            onClick={e => OperationLogoUpdateDialog.Open({
                ...logoClickable,
                clickEvent: e
            })}
            ref={logoRef}
            className='clickable'
        />
    )
}

export default OperationLogo