import { AgTechFormContext } from "agtech/core/forms/AgTechFormContext";
import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechGridHeaderConfiguration } from "agtech/web/components/Grids/AgTechGridHeader";
import { AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { SelectableLotsData } from "../../dialogs/InvoiceChangeLotsDialog";
import { LotsByContactDTO } from "app/data/lots/invoices/LotInvoiceDataActions";
import { AgTechEntitySelectionPreservedOriginal } from "agtech/web/components/Grids/AgTechGridEntities";

export type InvoiceChangeLotsGridProps = {
    formContext: AgTechFormContext<SelectableLotsData>,
    header?: AgTechGridHeaderConfiguration
}

const InvoiceChangeLotsGrid = (props: InvoiceChangeLotsGridProps) => {
    let invoiceLotSelectionGridConfiguration: AgTechGridProps<AgTechEntitySelectionPreservedOriginal<LotsByContactDTO>> = {
        header: props.header,
        items: props.formContext.entity.Lots,
        columns: [
            {
                header: 'Lot Number',
                value: lot => lot.entity.lotNumber,
                width: '100px'
            },
            {
                header: 'IsSplit',
                value: lot => lot.entity.isSplit ? "Yes" : "No"
            },
            {
                header: 'Currently invoiced',
                value: lot => lot.entity.invoiceId !== undefined ? "Yes" : "No"
            },
            {
                header: 'Current State',
                value: lot => lot.entity.invoiceState.toString()
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.entity.lotNumber === curr.entity.lotNumber &&
                   prev.entity.invoiceId === curr.entity.invoiceId &&
                   prev.entity.invoiceState === curr.entity.invoiceState;
        },
        selectionConfig: {
            isItemSelected: lot => lot.isSelected,
            onItemSelected: async (lot) => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    Lots: [...curr.Lots.map(formLot => formLot.id === lot.entity.id
                        ? { ...formLot, isSelected: true }
                        : { ...formLot }
                    )]
                }));
            },
            onItemDeSelected: async lot => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    Lots: [...curr.Lots.map(formLot => formLot.id === lot.entity.id
                        ? { ...formLot, isSelected: false }
                        : { ...formLot }
                    )]
                }));
            }
        }
    };

    return <AgTechGrid {...invoiceLotSelectionGridConfiguration} />
}

export default InvoiceChangeLotsGrid;