import React, { useState } from 'react'

export type AgTechOptionsProps = {
    selectedOption: string,
    options: string[],
    onOptionSelected: (selectedOption: string) => void
}

const AgTechOptions = (props: AgTechOptionsProps) => {
    let [selectedOption, setSelectedOption] = useState(props.selectedOption);

    let handleOptionSelected = (option: string) => {
        setSelectedOption(option);
        props.onOptionSelected(option);
    }

    return (
        <>
            { props.options.map(option => {
                let isSelectedOption = selectedOption === option;
                let optionSelectionClass = isSelectedOption ? 'font-color-highlight' : '';

                return (
                    <div key={option} className={`clickable non-scrollable row-based-vertically-centered py-1 container`}>
                        <h1 className={`font-size-small font-bold font-color-secondary ${optionSelectionClass}`} onClick={() => handleOptionSelected(option)}>
                            { option }
                        </h1>
                    </div>
                )
            }) }
        </>
    ) 
};

export default AgTechOptions;