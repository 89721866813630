import { isDev } from 'agtech/core/utilities/AgTechUtilities';
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons'
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import InvoiceLotSplitGroupingList from 'app/areas/invoices/components/lists/InvoiceLotSplitGroupingList';
import { SaleInvoiceDialogFormData, useLotBidderPurchasesForInvoice } from 'app/areas/invoices/dialogs/InvoiceCreationDialog';
import { useLotDataWriteLotPurchasesAction } from 'app/data/lots/purchases/LotPurchaseDataActions';
import React from 'react'

export type InvoiceSplitInvoiceDialogProps = {
    invoiceData: SaleInvoiceDialogFormData
}

const InvoiceSplitInvoiceDialog = (props: InvoiceSplitInvoiceDialogProps) => {
    let lotBidderPurchasesForInvoice = useLotBidderPurchasesForInvoice({ lots: props.invoiceData.lotIndex });
    let submitLotPurchasesAction = useLotDataWriteLotPurchasesAction();

    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: () => ({
                    purchases: lotBidderPurchasesForInvoice
                })
            }}
            formAction={{
                dataAction: submitLotPurchasesAction,
                getActionData: d => d
            }}
            structure={{
                header: {
                    title: 'Invoice Lot Splitting',
                    detail: 'To split the lots within this invoice among contacts, please enter the contacts below'
                },
                submitButtonText: 'Split lots',
                width: 650,
                bodyClasses: 'py-2'
            }}
        >
            {(formContext) => (
                <>
                    <InvoiceLotSplitGroupingList
                        getLotBidderPurchases={() => formContext.entity.purchases}
                        updateLotBidderPurchases={updatedLotBidderPurchases => {
                            formContext.updateFormEntity(curr => {
                                return {
                                    ...curr,
                                    purchases: [...updatedLotBidderPurchases]
                                };
                            })
                        }}
                    />
                    {isDev() ? (
                        <div className='mt-auto'>
                            {JSON.stringify(formContext.entity.purchases)}
                        </div>
                    ) : null}
                </>
            )}
        </AgTechFormDialog>
    )
}

InvoiceSplitInvoiceDialog.Open = (e: AgTechClickEventHandler, props: InvoiceSplitInvoiceDialogProps) => e.dialogs.openDialog({
    dialog: () => <InvoiceSplitInvoiceDialog {...props} />
});

InvoiceSplitInvoiceDialog.Button = (props: AgTechButtonProps & InvoiceSplitInvoiceDialogProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'Split lots',
        bootstrapIcon: 'bi-person-plus-fill',
        ...props,
        action: e => InvoiceSplitInvoiceDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default InvoiceSplitInvoiceDialog