import { AgTechFormContext } from "agtech/core/forms/AgTechFormContext";
import { AgTechActionButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechEntitySelection } from "agtech/web/components/Grids/AgTechGridEntities";
import { AgTechGridHeaderConfiguration } from "agtech/web/components/Grids/AgTechGridHeader";
import AgTechGridLink from "agtech/web/components/Grids/Controls/AgTechGridLink";
import { AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from "agtech/web/components/Menus/AgTechVerticalPopUpMenu";
import { SaleInvoiceDialogFormData } from "app/areas/invoices/dialogs/InvoiceCreationDialog";
import LotDialog from "app/areas/lots/dialogs/LotDialog";
import LotSplitDialog from "app/areas/lots/dialogs/LotSplitDialog";
import { SaleLot, useSaleLots } from "app/data/lots/LotData";

export type InvoiceLotSelectionGridProps = {
    formContext: AgTechFormContext<SaleInvoiceDialogFormData>,
    isReadonly?: boolean,
    header?: AgTechGridHeaderConfiguration
}

const InvoiceLotSelectionGrid = (props: InvoiceLotSelectionGridProps) => {
    let shouldBeReadonly = props.isReadonly ?? false;
    let lots = useSaleLots();

    let invoiceLotSelectionGridConfiguration: AgTechGridProps<AgTechEntitySelection<SaleLot>> = {
        header: props.header,
        items: props.formContext.entity.selectedLots,
        columns: [
            {
                header: 'Lot Number',
                value: lot => (
                    <AgTechGridLink
                        content={lot.entity.lotNumber}
                    />
                ),
                width: '100px'
            },
            {
                header: 'Description',
                value: lot => lot.entity.lotDescription,
                size: 2
            },
            {
                header: 'Purchase Price',
                value: lot => lot.entity.purchasePrice && lot.entity.purchasePrice > 0 ? `$${lot.entity.purchasePrice * (lot.entity.groupSize ?? 1)}` : '',
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.entity.lotNumber === curr.entity.lotNumber &&
                   prev.entity.lotDescription === curr.entity.lotDescription &&
                   prev.entity.purchasePrice === curr.entity.purchasePrice;
        },
        selectionConfig: shouldBeReadonly ? undefined : {
            isItemSelected: lot => lot.isSelected,
            onItemSelected: async (lot) => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    selectedLots: [...curr.selectedLots.map(formLot => formLot.id === lot.entity.id
                        ? { ...formLot, isSelected: true }
                        : { ...formLot }
                    )]
                }));
            },
            onItemDeSelected: async lot => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    selectedLots: [...curr.selectedLots.map(formLot => formLot.id === lot.entity.id
                        ? { ...formLot, isSelected: false }
                        : { ...formLot }
                    )]
                }));
            }
        },
        sizingConfig: {
            minRowsShown: 4
        },
        actions: shouldBeReadonly ? undefined : {
            rendering: lot => (
                <AgTechActionButton>
                    <LotInvoiceActionMenu lotId={lot.entity.id} />
                </AgTechActionButton>
            )
        }
    };

    return <AgTechGrid {...invoiceLotSelectionGridConfiguration} />
}

const LotInvoiceActionMenu = (props: { lotId: number }) => {
    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Lot">
                <LotDialog.Button lotId={props.lotId} />
                <LotSplitDialog.Button lotId={props.lotId} />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
}

export default InvoiceLotSelectionGrid;