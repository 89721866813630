import React, { useEffect, useState } from 'react'
import AgTechLoadingSurface from 'agtech/web/components/Pages/Loading/AgTechLoadingSurface';
import { useDialogContext } from 'agtech/web/components/Portals/Dialogs/AgTechDialogSlot';
import AgTechTitleDetailHeader, { AgTechTitleDetailHeaderProps } from 'agtech/web/components/Headers/AgTechTitleDetailHeader';

export type AgTechViewDialogProps = {
    header?: AgTechTitleDetailHeaderProps,
    footer?: (closeDialog: (e: React.MouseEvent<HTMLElement>) => void) => React.ReactNode,
    width: string,
    waitOnDataLoaded?: () => boolean,
    textWhileLoading?: string,
    growAfterLoading?: boolean,
    shouldRefresh?: boolean,
    bodyClasses?: string,
    bodyPadding?: string
}

let AgTechViewDialog = (props: React.PropsWithChildren<AgTechViewDialogProps>) => {
    let [hasRendered, setHasRendered] = useState(false);
    let [hasGrown, setHasGrown] = useState(false);

    let shouldGrow = props.growAfterLoading ?? true;
    let hasLoaded = props.waitOnDataLoaded ? props.waitOnDataLoaded() : true;
    let dialogContext = useDialogContext();
    let dialogWidth = (hasLoaded && hasRendered) || !shouldGrow ? props.width : '400px';

    setTimeout(() => {
        setHasRendered(true);
    }, 500);

    let handleDialogTransitionEnd = (e: React.TransitionEvent) => {
        if (e.propertyName === 'width') {
            setHasGrown(true);
        }
    }

    useEffect(() => {
        if (!shouldGrow) {
            setHasGrown(true);
        }
    }, []);

    return (
        <div
            className={`agtech-dialog agtech-view-dialog ${hasLoaded ? 'loaded' : ''}`}
            style={{width: dialogWidth}}
            onClick={e => e.stopPropagation()}
            onTransitionEnd={handleDialogTransitionEnd}
        >
            <AgTechLoadingSurface waitOn={hasLoaded && hasRendered && hasGrown} text={props.textWhileLoading ?? ''}>
                <div className="row-based non-scrollable stretched container">
                    {props.header ? (
                        <div className="row-based bottom-bordered py-3 px-5 container">
                            <AgTechTitleDetailHeader
                                {...props.header}
                                titleFontSize='font-size-large'
                                detailClasses='mt-2'
                                content={() => (
                                    <div className='ml-auto tall column-based-vertically-centered container'>
                                        {props.header?.content ? props.header.content() : null}
                                        <div className='tall ml-5 pl-3 centered container'>
                                            <i 
                                                className={`agtech-icon-badge fas fa-times clickable bg-light-gray bordered container`}
                                                onClick={e => dialogContext.closeDialog(e)}
                                                style={{
                                                    height: 36,
                                                    width: 36,
                                                    marginRight: 0
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    ) : null}
                    <div className="agtech-dialog-body non-scrollable">
                        <div className={`agtech-dialog-body-surface ${props.bodyClasses ?? ''} ${props.bodyPadding ?? 'p-4'}`}>
                            {props.children}
                        </div>
                    </div>
                    { props.footer ? (
                        <div className="agtech-dialog-footer">
                            {props.footer(e => dialogContext.closeDialog(e))}
                        </div>
                    ) : null}
                </div>
            </AgTechLoadingSurface>
        </div>
    )
};

export default React.memo(AgTechViewDialog, (prev, curr) => {
    let prevLoaded = prev.waitOnDataLoaded ? prev.waitOnDataLoaded() : null;
    let currLoaded = curr.waitOnDataLoaded ? curr.waitOnDataLoaded() : null;

    return prevLoaded === currLoaded && !(curr.shouldRefresh ?? false);

}) as typeof AgTechViewDialog;