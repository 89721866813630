import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import { SaleDiscountRemovalButton } from 'app/areas/discounts/components/buttons/SaleDiscountButtons';
import SaleDiscountDialog from 'app/areas/discounts/dialogs/SaleDiscountDialog';
import { SaleDiscount } from 'app/data/sales/discounts/SaleDiscountData';
import React from 'react'

export type SaleDiscountActionMenuProps = {
    discount: SaleDiscount
};

const SaleDiscountActionMenu = (props: SaleDiscountActionMenuProps) => {
    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Discount">
                <SaleDiscountDialog.Button discount={props.discount} />
                <SaleDiscountRemovalButton discount={props.discount} />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
};

export default SaleDiscountActionMenu;