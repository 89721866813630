import { AgTechApiEntityResponse, AgTechHttpResponse, AgTechHttpResponses } from 'agtech/core/data/actions/AgTechHttpRequests';
import { executeAgTechApiRequest, useAgTechHttpRequestConfigurationBuilder, AgTechHttpRequestProps, AgTechApiResponseHandlers, handleSuccessfulApiResponse, AgTechInternalAxiosRequestProps } from './AgTechCoreApiRequests';
import axios from 'axios';
import { logEvent } from 'agtech/core/logging/AgTechLogger';

export type AgTechApiPostRequestProps<TPostedData, TResponseData> = AgTechHttpRequestProps & AgTechApiResponseHandlers<TResponseData> & {
    postedData: TPostedData
}

export type AgTechPostRequest<TInput, TResponseData> = {
    post: (props: AgTechApiPostRequestProps<TInput, TResponseData>) => Promise<AgTechHttpResponse<TResponseData>>
}

export const useAgTechHttpPostRequest = <TPostedData, TResponseData>(): AgTechPostRequest<TPostedData, TResponseData> => {
    let requestConfigurationBuilder = useAgTechHttpRequestConfigurationBuilder();

    return {
        post: async requestProps => await executeAgTechApiRequest<TPostedData, TResponseData>({
            requestType: 'POST',
            requestData: requestProps.postedData,
            requestConfiguration: requestConfigurationBuilder(requestProps),
            requestResponseHandlers: requestProps,
            request: executePostRequestInternal
        })
    }
}

export const useAgTechHttpPostEntityRequest = <TPostedEntity>(): AgTechPostRequest<TPostedEntity, TPostedEntity> => {
    let httpPostAndConvertRequest = useAgTechHttpPostAndConvertRequest<TPostedEntity, AgTechApiEntityResponse<TPostedEntity>, TPostedEntity>();

    return {
        post: async requestProps => httpPostAndConvertRequest.post({
            ...requestProps,
            convert: entityResponse => entityResponse.data
        })
    }
}

export const useAgTechHttpSubmitEntityRequest = <TPostedEntity>(): AgTechPostRequest<TPostedEntity, TPostedEntity> => {
    let httpPostAndConvertRequest = useAgTechHttpPostAndConvertRequest<TPostedEntity, AgTechApiEntityResponse<TPostedEntity>, TPostedEntity>();

    return {
        post: async requestProps => httpPostAndConvertRequest.post({
            ...requestProps,
            path: requestProps.path + '/Submit',
            convert: entityResponse => entityResponse.data
        })
    }
}

export const useAgTechHttpSubmitEntityAndConvertRequest = <TPostedEntity, TConvertedEntity>(): AgTechPostAndReturnRequest<TPostedEntity, TPostedEntity, TConvertedEntity> => {
    let httpPostAndConvertRequest = useAgTechHttpPostAndConvertRequest<TPostedEntity, AgTechApiEntityResponse<TPostedEntity>, TConvertedEntity>();

    return {
        post: async requestProps => httpPostAndConvertRequest.post({
            ...requestProps,
            path: requestProps.path + '/Submit',
            convert: entityResponse => requestProps.convert(entityResponse.data)
        })
    }
}

///
/// Conversions
///

export type AgTechPostAndReturnRequestProps<TPostedData, TResponseData, TResult> = AgTechApiPostRequestProps<TPostedData, TResult> & {
    convert: (responseData: TResponseData) => TResult
}

export type AgTechPostAndReturnRequest<TPostedData, TResponseData, TResult> = {
    post: (props: AgTechPostAndReturnRequestProps<TPostedData, TResponseData, TResult>) => Promise<AgTechHttpResponse<TResult>>
}

export const useAgTechHttpPostAndConvertRequest = <TPostedData, TResponseData, TResult>(): AgTechPostAndReturnRequest<TPostedData, TResponseData, TResult> => {
    let requestConfigurationBuilder = useAgTechHttpRequestConfigurationBuilder();

    return {
        post: async props => {
            let requestResponse = AgTechHttpResponses.Failed('An error occurred');
            let requestConfiguration = requestConfigurationBuilder(props);

            let initialRequestProps: AgTechApiPostRequestProps<TPostedData, TResponseData> = {
                path: props.path,
                postedData: props.postedData,
                onSuccess: response => AgTechHttpResponses.Success(response.body),
                onError: failure => AgTechHttpResponses.Failed(failure.error)
            };

            let initialDataPostResponse = await executeAgTechApiRequest<TPostedData, TResponseData>({
                requestType: 'POST',
                requestData: initialRequestProps.postedData,
                requestConfiguration: requestConfiguration,
                requestResponseHandlers: initialRequestProps,
                request: executePostRequestInternal
            });

            if (initialDataPostResponse.success) {
                let initialData = initialDataPostResponse.success.data;
                let convertedData = props.convert(initialData);

                requestResponse = await handleSuccessfulApiResponse({
                    ...requestConfiguration,
                    response: { wasSuccessful: true, data: convertedData },
                    handlers: props
                });
            }

            return requestResponse;
        }
    }
}


/// Internal

const executePostRequestInternal = <TInputData, TData>(props: AgTechInternalAxiosRequestProps<TInputData>) => {
    logEvent('Execute POST request', {
        url: props.requestURL,
        data: props.requestData
    });

    return axios.post<TData>(props.requestURL, props.requestData, props.requestConfig);
}