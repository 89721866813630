import { useDefaultAgTechDataAction } from 'agtech/core/data/actions/AgTechDataActions';
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import LogoUpdateForm from 'app/areas/operation/components/forms/LogoUpdateForm';
import { useOperationLogo } from 'app/data/operation/SaleDataOperation'
import { useOperationDataSetOperationLogoAction } from 'app/data/operation/SaleDataOperationActions';
import React from 'react'

const OperationLogoUpdateDialog = () => {
    let action = useDefaultAgTechDataAction('Name');

    return (
        <AgTechFormDialog
            structure={{
                header: 'Update operation logo',
                width: 600
            }}
            formData={{
                loadInitialFormData: () => ({})
            }}
            formAction={{
                dataAction: action,
                getActionData: d => d
            }}
        >
            <LogoUpdateForm />
        </AgTechFormDialog>
    )
}

OperationLogoUpdateDialog.Open = (e: AgTechClickEventHandler) => e.dialogs.openDialog({
    dialog: () => <OperationLogoUpdateDialog />
});

OperationLogoUpdateDialog.Button = (props: AgTechButtonProps) => (
    <AgTechButton
        text={'Update Operation Logo'}
        icon='upload'
        {...props}
        action={OperationLogoUpdateDialog.Open}
    />
)

export default OperationLogoUpdateDialog