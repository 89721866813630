import { isDev } from 'agtech/core/utilities/AgTechUtilities';
import { AgTechDataActionLoggingContext } from './../data/actions/AgTechDataActions';

export const logDev = (message: any) => {
    if (isDev()) {
        console.log(message);
    }
}

export const logDevError = (message: any) => {
    if (isDev()) {
        console.error(message);
    }
}

export const logEvent = (name: string, data: any) => {
    logDev({
        event: name,
        ...data
    });
}

export const logAction = (actionData: AgTechDataActionLoggingContext<any, any>) => {
    if (actionData.execution.result.wasActionSuccessful) {
        logDev({
            event: 'ACTION',
            ...actionData
        });
    }
    else {
        logDevError({
            event: 'ACTION',
            ...actionData
        });
    }
}