import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechGridColumnMetadata } from "agtech/web/components/Grids/Data/AgTechGridData";
import { AgTechGridSearchOption } from "agtech/web/components/Grids/Features/AgTechGridSearching";
import { AgTechWarningIcon } from "agtech/web/components/Icons/AgTechIcons";
import { AgTechImportModalResultViewProps, AgTechImportGridItem, isItemImportValid } from "agtech/web/components/Portals/Modals/Imports/Common/AgTechImportTypes";
import AgTechTooltipContainer from "agtech/web/components/Portals/Popups/AgTechTooltipContainer";

export const useGridColumnsForImportResults = <TImportItem, >(props: AgTechImportModalResultViewProps<TImportItem>) => {
    let importResultsGridColumns = props.importProps.importColumns.map<AgTechGridColumnMetadata<AgTechImportGridItem<TImportItem>>>(column => ({
        header: column.columnName,
        value: gridItem => {
            let columnValidationErrors = gridItem.importItem.invalidColumns[column.columnName];
            
            if (columnValidationErrors) {
                return (
                    <div className='column-based stretched line-height-inherit invalid px-3 container'>
                        {columnValidationErrors.importedValue}
                        <AgTechTooltipContainer
                            message={columnValidationErrors.errorMessage}
                            classes='ml-auto clickable vertically-centered'
                        >
                            <AgTechWarningIcon classes='ml-auto' />
                        </AgTechTooltipContainer>
                    </div>                        
                )
            }
            else {
                return column.getItemValue(gridItem.importItem.importedItem);
            }
        },
        itemBasedClasses: gridItem => gridItem.importItem.invalidColumns[column.columnName] ? 'invalid' : '',
        ...(column.getItemGridColumn ? column.getItemGridColumn() : {})
    }));

    importResultsGridColumns = [{
        header: 'Row #',
        value: gridItem => gridItem.importItem.itemRow,
        size: .5,
        classes: 'centered emphasized'
    }, ...importResultsGridColumns];

    return importResultsGridColumns;
}

export const useFailedImportResultsTabs = <TImportItem, >(props: AgTechImportModalResultViewProps<TImportItem>) => {
    let importResultsGridColumns = useGridColumnsForImportResults(props);

    let successfulImports = props.importResult.items.filter(isItemImportValid);
    let conflictingImports = props.importResult.items.filter(item => item.isConflictingItem);
    let failedImports = props.importResult.items.filter(item => !item.isValidItem);

    let importResultTabs = [];

    let importResultGridSearchOptions = props.importProps.importColumns.map<AgTechGridSearchOption<AgTechImportGridItem<TImportItem>>>(column => ({
        field: column.columnName,
        value: item => column.getItemValue(item.importItem.importedItem)
    }));

    if (failedImports.length > 0) {
        importResultTabs.push({
            text: `Failed Imports (${failedImports.length})`,
            content: () => (
                <div className='row-based stretched container'>
                    <AgTechGrid
                        header={{
                            title: `${props.terminology.importEntityPlural} that failed to import`
                        }}
                        searchConfig={{
                            options: importResultGridSearchOptions
                        }}
                        items={failedImports.map<AgTechImportGridItem<TImportItem>>((item, index) => ({
                            id: index,
                            importItem: {
                                ...item,
                                importedItem: { ...item.importedItem, id: index }
                            }
                        }))}
                        columns={[
                            ...importResultsGridColumns
                        ]}
                        isRowEntityTheSame={() => true}
                    />
                </div>
            )
        });
    }

    if (conflictingImports.length > 0) {
        importResultTabs.push({
            text: `Conflicting Imports (${conflictingImports.length})`,
            content: () => (
                <div className='row-based stretched container'>
                    <AgTechGrid
                        header={{
                            title: `${props.terminology.importEntityPlural} that already exist`
                        }}
                        searchConfig={{
                            options: importResultGridSearchOptions
                        }}
                        items={conflictingImports.map<AgTechImportGridItem<TImportItem>>((item, index) => ({
                            id: index,
                            importItem: {
                                ...item,
                                importedItem: { ...item.importedItem, id: index }
                            }
                        }))}
                        columns={[
                            ...importResultsGridColumns,
                            {
                                header: 'Message',
                                value: gridItem => gridItem.importItem.importFailureMessage ?? '',
                                width: '360px'
                            }
                        ]}
                        isRowEntityTheSame={() => true}
                    />
                </div>
            )
        });
    }

    importResultTabs.push({
        text: `Successful Imports (${successfulImports.length})`,
        content: () => (
            <div className='row-based stretched container'>
                <AgTechGrid
                    header={{
                        title: `${props.terminology.importEntityPlural} that imported successfully`
                    }}
                    searchConfig={{
                        options: importResultGridSearchOptions
                    }}
                    items={successfulImports.map<AgTechImportGridItem<TImportItem>>((item, index) => ({
                        id: index,
                        importItem: {
                            ...item,
                            importedItem: { ...item.importedItem, id: index }
                        }
                    }))}
                    columns={[
                        ...importResultsGridColumns
                    ]}
                    isRowEntityTheSame={() => true}
                />
            </div>
        )
    });

    importResultTabs.push({
        text: `All Imports (${props.importResult.items.length})`,
        content: () => (
            <div className='row-based stretched container'>
                <AgTechGrid
                    header={{
                        title: `All Imported ${props.terminology.importEntityPlural.toLowerCase()}`
                    }}
                    searchConfig={{
                        options: importResultGridSearchOptions
                    }}
                    items={props.importResult.items.map<AgTechImportGridItem<TImportItem>>((item, index) => ({
                        id: index,
                        importItem: item
                    }))}
                    columns={[
                        ...importResultsGridColumns,
                        {
                            header: 'Import Status',
                            value: gridItem => isItemImportValid(gridItem.importItem) ? 'Valid' : 'Invalid',
                            classes: 'emphasized centered'
                        }
                    ]}
                    isRowEntityTheSame={() => true}
                />
            </div>
        )
    });

    
    return {
        tabs: importResultTabs
    }
}