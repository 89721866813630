import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechForm, AgTechFormRow, AgTechFormTextControl } from 'agtech/web/components/Forms/AgTechForm'
import { useInvoiceDataGetSampleInvoiceRequest } from 'app/data/invoicing/InvoicingDataActions';
import { DefaultInvoiceDocumentConfiguration, useOperationData } from 'app/data/operation/SaleDataOperation';
import { useOperationDataUpdateInvoiceDocumentConfigurationAction } from 'app/data/operation/SaleDataOperationActions';
import React from 'react'

const InvoiceDocumentConfigurationForm = () => {
    var operationData = useOperationData();

    var configData = {
        ...DefaultInvoiceDocumentConfiguration,
        ...operationData.invoiceDocumentConfiguration
    };
    
    let invoiceConfigurationSubmission = useOperationDataUpdateInvoiceDocumentConfigurationAction();

    return (
        <AgTechForm
            structure={{
                header: {
                    title: 'Invoice Configuration',
                    content: () => <GenerateSampleInvoiceButton classes='highlight' />
                }
            }}
            initialFormData={configData}
            action={{
                dataAction: invoiceConfigurationSubmission,
                getActionData: d => d
            }}
            behavior={{
                realtimeSubmissionProps: {
                    isFormEntityTheSame: (prev, curr) => 
                        prev.thanksText === curr.thanksText &&
                        prev.itemNumberText === curr.itemNumberText &&
                        prev.checksPayableText === curr.checksPayableText &&
                        prev.purchasePriceText === curr.purchasePriceText &&
                        prev.descriptionText === curr.descriptionText
                }
            }}
        >
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Item # Text"
                    focused={true}
                    value={entity => entity.itemNumberText ?? ''}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        itemNumberText: val
                    })}
                />
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Purchase Price Text"
                    value={entity => entity.purchasePriceText ?? ''}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        purchasePriceText: val
                    })}
                />
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Checks Payable Text"
                    value={entity => entity.checksPayableText ?? ''}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        checksPayableText: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Description Text"
                    value={entity => entity.descriptionText ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        descriptionText: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Thank You Text"
                    value={entity => entity.thanksText ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        thanksText: val
                    })}
                />
            </AgTechFormRow>
        </AgTechForm>
    )
}

const GenerateSampleInvoiceButton = (props: AgTechButtonProps) => {
    let sampleInvoiceRequest = useInvoiceDataGetSampleInvoiceRequest();
    
    let buttonProps: AgTechButtonProps = {
        text: "Download Sample Invoice",
        icon: 'download',
        ...props,
        action: async () => {
            await sampleInvoiceRequest.execute({
                data: undefined
            });
        }
    };

    return <AgTechButton {...buttonProps} />
}

export default InvoiceDocumentConfigurationForm