import { AgTechFormControlUpdate } from 'agtech/web/components/Forms/Controls/Base/AgTechFormControls'
import { AgTechSelectionControlProps } from 'agtech/web/components/Forms/Controls/Selection/AgTechCoreSelectionControlData'
import { AgTechSelectionFormControl, AgTechFormSelectionControlProps } from 'agtech/web/components/Forms/Controls/Selection/AgTechFormSelectionControl'
import { AgTechSelectionListBody, AgTechSelectionListHeader } from 'agtech/web/components/Forms/Controls/Selection/AgTechSelectionPopUp'
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog'
import { AgTechContact, useContacts } from 'app/data/operation/contacts/ContactData'
import React from 'react'

export type ContactSelectionControlProps<TFormEntity> = {
    contacts?: AgTechContact[],
    entityType: TFormEntity,
    caption?: string,
    getSelectedContactId: (formEntity: TFormEntity) => number | undefined,
    onContactSelected: AgTechFormControlUpdate<TFormEntity, AgTechContact | undefined>,
    isRequired?: boolean,
    width?: number
}

export const useContactSelectionFormControlProps = <TFormEntity, >(props: ContactSelectionControlProps<TFormEntity>): AgTechFormSelectionControlProps<TFormEntity, AgTechContact> => {
    let contacts = useContacts();

    return {
        entityType: props.entityType,
        control: {
            caption: props.caption ?? "Contact",
            isRequired: props.isRequired,
            width: props.width
        },
        selection: {
            selectionControlTitle: 'Contacts',
            items: props.contacts ?? contacts,
            itemText: c => c.firstName + ' ' + c.lastName,
            itemHeight: 60,
            itemRendering: e => (
                <AgTechTitleDetailHeader
                    title={e.firstName + ' ' + e.lastName}
                    detail={e.ranchName}
                    titleFontSize='font-size-small'
                    detailFontSize='font-size-very-small'
                    detailClasses='mt-1-half'
                />
            ),
            onItemSelected: async (e, selectedContact) => {
                return await props.onContactSelected(e, selectedContact);
            },
            getSelectedItemId: entity => props.getSelectedContactId(entity)?.toString(),
            filterItems: (item, filter) => (item.firstName + ' ' + item.lastName).includes(filter),
            itemCreationConfig: {
                creationButtonText: 'New Contact',
                creationButtonAction: e => {
                    return new Promise<AgTechContact>((res, fail) => {
                        e.dialogs.openDialog({
                            dialog: () => (
                                <ContactDialog onContactSubmitted={async createdContact => {
                                    res(createdContact);
                                }} />
                            ),
                        });
                    });
                },
                noRecordsMessage: 'No contacts were found in the system',
                noRecordsPrompt: 'To create one, click the button below'
            },
            styles: { height: 240, width: 360 }
        }
    }
}

export const useContactSelectionProps = (
    props: ContactSelectionProps
): AgTechSelectionControlProps<AgTechContact> => {
    let contacts = props.contacts ?? useContacts();

    return {
        items: {
            selectionItems: contacts,
            getSelectedItemId: () => props.selectedContactId?.toString(),
            onItemSelected: props.onContactSelected,
            filterItems: (contact, filter) => (contact.firstName + ' ' + contact.lastName).includes(filter)
        },
        content: {
            header: headerProps => (
                <AgTechSelectionListHeader
                    {...headerProps}
                    title='Contacts'
                />
            ),
            body: bodyProps => (
                <AgTechSelectionListBody
                    {...bodyProps}
                    itemHeight={60} 
                    item={e => (
                        <AgTechTitleDetailHeader
                            title={e.firstName + ' ' + e.lastName}
                            detail={e.ranchName}
                            titleFontSize='font-size-small'
                            detailFontSize='font-size-very-small'
                            detailClasses='mt-1-half'
                        />
                    )}
                />
            ),
            styles: { height: 260, width: 400 }
        }
    }
}

export type ContactSelectionProps = {
    contacts?: AgTechContact[],
    selectedContactId?: number | undefined,
    onContactSelected: (selectedContact: AgTechContact) => Promise<any>,
}

const ContactSelectionControl = <TFormEntity, >(props: ContactSelectionControlProps<TFormEntity>) => {
    let contactSelectionProps = useContactSelectionFormControlProps(props);
    
    return (
        <AgTechSelectionFormControl {...contactSelectionProps} />
    )
}

export default ContactSelectionControl