import { AgTechClickEventHandler, AgTechLink } from '../Buttons/AgTechButtons';
import React, { CSSProperties } from 'react'
import { AgTechEditableTextControl } from '../Forms/AgTechEditableControls';
import { AgTechPopUpCoreProps } from 'agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData';
import { AgTechIconBadge } from 'agtech/web/components/Icons/AgTechIcons';

// TODO: Should use a render prop here rather than children, makes it more clear
export type AgTechTitleDetailHeaderProps = {
    title: string,
    detail?: string,
    icon?: string,
    iconContent?: () => React.ReactNode,
    onTitleClicked?: (e: AgTechClickEventHandler) => void,
    onTitleHover?: () => AgTechPopUpCoreProps,
    onTitleUpdated?: (updatedTitle: string) => void,
    classes?: string,
    titleClasses?: string,
    titleFontSize?: string,
    detailClasses?: string,
    detailFontSize?: string,
    detailMargin?: number,
    iconClasses?: string,
    iconSize?: number,
    content?: () => React.ReactNode,
    contentClasses?: string,
    styles?: CSSProperties
}

let AgTechTitleDetailHeader = React.forwardRef<HTMLDivElement, React.PropsWithChildren<AgTechTitleDetailHeaderProps>>((props, ref) => {
    let titleFontSize = props.titleFontSize ?? 'font-size-standard';
    let titleClass = `${titleFontSize} font-bold ${props.titleClasses ?? ''}`;

    let detailFontSize = props.detailFontSize ?? 'font-size-smaller';
    let detailClass = `${detailFontSize} font-color-secondary mt-1 ${props.detailClasses ?? ''}`;

    let icon = props.iconContent
        ? props.iconContent()
        : props.icon ? <AgTechIconBadge icon={props.icon} size={props.iconSize} classes={props.iconClasses} /> : null;

    let title = props.onTitleUpdated ? (    
        <AgTechEditableTextControl
            text={props.title}
            onUpdated={props.onTitleUpdated}
            classes={titleClass}
        />
    ) : (
        <AgTechLink
            content={props.title}
            onClick={props.onTitleClicked}
            onHover={props.onTitleHover}
            classes={titleClass}
        />
    );

    let details = props.detail ? (
        <div className={detailClass} style={{
            marginTop: props.detailMargin
        }}>
            {props.detail}
        </div>
    ) : null;

    return (
        <div ref={ref} className={`column-based wide container ${props.classes ?? ''}`} style={props.styles}>
            {icon ? (
                <div className='tall centered mr-3 container'>
                    {icon}
                </div>
            ) : null}
            <div className="wide row-based-vertically-centered pr-4 container">
                {title}
                {details}
            </div>
            <div className={`ml-auto column-based container ${props.contentClasses ?? ''}`}>
                { props.content ? props.content() : props.children }
            </div>
        </div>
    )
});

export const isTitleDetail = (result: string | AgTechTitleDetailHeaderProps): result is AgTechTitleDetailHeaderProps => {
    return (result as AgTechTitleDetailHeaderProps).title !== undefined;
}

export default AgTechTitleDetailHeader;