import React, { createRef, useRef, useState } from 'react'
import AgTechPopUp from 'agtech/web/components/Portals/Popups/AgTechPopUp';
import { v4 as uuidv4 } from 'uuid';
import { AgTechPopUpConfiguration, AgTechPopUpCoreProps, AgTechPopUpRendering, AgTechRenderedPopUp } from '../../Portals/Popups/Helpers/AgTechPopUpData';

/// Types

export type AgTechPopUpRenderProps = AgTechPopUpCoreProps & {
    callerRef: React.RefObject<any>
}

/// Components

export type AgTechPopUpSurfaceProps = {
    popupManager: AgTechPopUpManager
}

const AgTechPopUpSurface = (props: AgTechPopUpSurfaceProps) => {
    return (
        <>
           { props.popupManager.popups.map((popupRendering: AgTechPopUpRendering) => {
                return (
                    <AgTechPopUp
                        key={popupRendering.popupId}
                        ref={popupRendering.popupRef}
                        {...popupRendering}
                    />
                )
            })}
        </>
    );
};

/// Manager

export type AgTechPopUpManager = {
    popups: AgTechPopUpRendering[],
    showPopUp: (popup: AgTechPopUpRenderProps) => AgTechRenderedPopUp,
    closeAll: () => void
}

export const DefaultAgTechPopUpManager: AgTechPopUpManager = {
    popups: [],
    showPopUp: (popup: AgTechPopUpRenderProps) => {
        console.error('Attempted to show popup with default app context');
        return {
            callerRef: createRef(),
            popupRef: createRef(),
            close: () => {}
        }
    },
    closeAll: () => {
        console.error('Attempted to close all popups from the default popup context');
    }
}

export const usePopups = (): AgTechPopUpManager => {
    let [popups, updatePopups] = useState<AgTechPopUpRendering[]>([]);
    let renderedPopUps = useRef(popups);

    return {
        popups: popups,
        showPopUp: (popupRenderProps: AgTechPopUpRenderProps) => {     
            let popupKey = uuidv4();
            let popupRef = createRef<HTMLDivElement>();

            let popupRendering: AgTechPopUpRendering = {
                popupId: popupKey,
                callerRef: popupRenderProps.callerRef,
                popupRef: popupRef,
                popup: popupRenderProps.popup,
                popupConfig: {
                    ...popupRenderProps.config
                },
                closePopup: (popupId: string) => {
                    if (popupId !== popupKey) {
                        return;
                    }

                    renderedPopUps.current = renderedPopUps.current.filter(dg => dg.popupId !== popupKey);
                    updatePopups(renderedPopUps.current);

                    if (popupRenderProps.handlers && popupRenderProps.handlers.onPopUpClosed) {
                        popupRenderProps.handlers.onPopUpClosed();
                    }
                }
            };

            if (popupRenderProps.handlers && popupRenderProps.handlers.onBeforePopUpOpened) {
                popupRenderProps.handlers.onBeforePopUpOpened();
            }

            renderedPopUps.current = [...renderedPopUps.current, popupRendering];
            updatePopups(renderedPopUps.current);

            if (popupRenderProps.handlers && popupRenderProps.handlers.onPopUpOpened) {
                popupRenderProps.handlers.onPopUpOpened();
            }

            return {
                callerRef: popupRenderProps.callerRef,
                popupRef: popupRef,
                close: () => popupRendering.closePopup(popupKey)
            }
        },
        closeAll: () => {
            renderedPopUps.current = [];
            updatePopups(renderedPopUps.current);
        }
    }
}

export default AgTechPopUpSurface;