import AgTechDataProvider from "agtech/core/data/providers/AgTechDataProvider";
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from "agtech/web/components/Buttons/AgTechButtons";
import { Payment } from "app/data/invoicing/InvoicingData";
import InvoicePaymentsViewGrid from "../components/grids/InvoicePaymentsGrid";
import { useInvoiceDataGetPaymentsOnInvoice } from "app/data/invoicing/InvoicingDataActions";
import AgTechViewDialog from "agtech/web/components/Portals/Dialogs/AgTechViewDialog";

export type ViewPaymentsDialogProps = {
    invoiceId: number
}

const ViewPaymentsDialog = (props: ViewPaymentsDialogProps) => {
    let getPaymentsRequest = useInvoiceDataGetPaymentsOnInvoice();

    return (
        <AgTechViewDialog
            header={{
                title: `View Payments`,
                detail: `View and edit payments.`
            }}
            width="650px"
        >
            <AgTechDataProvider
                loadData={async executor => await executor.executeRequest(getPaymentsRequest, props.invoiceId)}
                content={{
                    loadingText: 'Loading processed sales...',
                    failureText: 'Unable to load processed sales. Please try again.'
                }}
            >
                {
                    (payments: Payment[]) => (
                        <InvoicePaymentsViewGrid payments={payments} AddActions={true}/>
                    )
                }
            </AgTechDataProvider>
        </AgTechViewDialog>
    )
}

ViewPaymentsDialog.Open = (e: AgTechClickEventHandler, props: ViewPaymentsDialogProps) => e.dialogs.openDialog({
    dialog: () => <ViewPaymentsDialog {...props} />
});

ViewPaymentsDialog.Button = (props: AgTechButtonProps & ViewPaymentsDialogProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'View Payments',
        bootstrapIcon: 'bi-credit-card-2-front-fill',
        ...props,
        action: e => ViewPaymentsDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default ViewPaymentsDialog;