import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSaleState, SalesAppState } from "app/data/SalesAppDataStore";
import { useSelector } from "react-redux";

/// Sale Data Entities

export type Sale = {
    id: number,
    saleName: string,
    saleStartDate: Date,
    saleEndDate: Date
}

export const DefaultSale = {
    id: 0,
    saleName: '',
    saleStartDate: new Date(),
    saleEndDate: new Date()
}

export type SaleSalesData = {
    currentSale: Sale,
    sales: { [id: number]: Sale }
};

export type SaleDataSummary = {
    currentSale: Sale,
    sales: Sale[]
};


/// Sale Data Slice

const DefaultSaleSalesData: SaleSalesData = {
    currentSale: {
        id: 0,
        saleName: '',
        saleStartDate: new Date(),
        saleEndDate: new Date()
    },
    sales: []
};

export const salesDataSlice = createSlice({
    name: 'Sales',
    initialState: DefaultSaleSalesData,
    reducers: {
        refreshSaleData: (state, action: PayloadAction<SaleDataSummary>) => {            
            return {
                ...action.payload
            }
        },
        writeSale: (state, action: PayloadAction<Sale>) => {
            state.currentSale = action.payload;
            state.sales[action.payload.id] = { ...action.payload };
        }
    }
});


/// Sale Data Hooks

export const useCurrentSale = (): Sale => useSelector((state: SalesAppState) => {
    return state.sales.currentSale;
});

export type SaleSelectionData = {
    currentSale: Sale | null,
    sales: Sale[]
};

export const useSales = (): Sale[] => {
    let saleData = useSelector((state: SalesAppState) => state.sales);
    return Object.values(saleData.sales);
}

export const useSaleSelectionData = (): SaleSelectionData => {
    let saleData = useSelector((state: SalesAppState) => state.sales);

    return {
        currentSale: saleData.currentSale,
        sales: Object.values(saleData.sales)
    };
}

export const getSales = () => Object.values(getSaleState().sales.sales);

export default salesDataSlice.reducer;