import { AgTechHttpResponse } from "agtech/core/data/actions/AgTechHttpRequests";
import { AgTechHttpRequestProps, AgTechApiResponseHandlers, AgTechInternalAxiosRequestProps, executeAgTechApiRequest, useAgTechHttpRequestConfigurationBuilder } from "agtech/core/data/http/AgTechCoreApiRequests";
import axios from "axios";

export type AgTechHttpDeleteRequestProps = AgTechHttpRequestProps & AgTechApiResponseHandlers<boolean>;

export type AgTechHttpDeleteRequest = {
    delete: (props: AgTechHttpDeleteRequestProps) => Promise<AgTechHttpResponse<boolean>>
}

export const useAgTechHttpDeleteRequest = (): AgTechHttpDeleteRequest => {
    let buildRequestConfiguration = useAgTechHttpRequestConfigurationBuilder();

    return {
        delete: async props => executeAgTechApiRequest({
            requestType: 'DELETE',
            requestConfiguration: buildRequestConfiguration(props),
            requestResponseHandlers: props,
            request: executeDeleteRequestInternal
        })
    }
}

export type AgTechHttpDeleteEntityRequestProps = AgTechApiResponseHandlers<any> & {
    baseURL?: string,
    controller: string,
    entityId: number,
    usesOperation?: boolean
}

export type AgTechHttpDeleteEntityRequest = {
    deleteEntity: (props: AgTechHttpDeleteEntityRequestProps) => Promise<AgTechHttpResponse<boolean>>
}

export const useAgTechHttpDeleteEntityRequest = (): AgTechHttpDeleteEntityRequest => {
    let buildRequestConfiguration = useAgTechHttpRequestConfigurationBuilder();

    return {
        deleteEntity: props => executeAgTechApiRequest({
            requestType: 'DELETE',
            requestConfiguration: {
                ...buildRequestConfiguration({
                    ...props,
                    path: `${props.controller}/Delete/${props.entityId}`
                })
            },
            requestResponseHandlers: props,
            request: executeDeleteRequestInternal
        })
    }
}

/// Internal

const executeDeleteRequestInternal = (props: AgTechInternalAxiosRequestProps<any>) => {
    console.log('Executing DELETE Request: ' + props.requestURL);

    return axios.delete(props.requestURL, props.requestConfig);
}