import { AgTechClickEventHandler, AgTechRendering, useClickable } from 'agtech/web/components/Buttons/AgTechButtons'
import React, { createContext, CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import AgTechFutureLoadingSurface from '../Loading/AgTechFutureLoadingSurface'

export enum AgTechTabPageStyle {
    Standard,
    Badge,
    BadgeUnderline,
    MultiPage,
    Thin
};

export type AgTechTabbedPageProps = {
    tabs: AgTechTabbedPageTabProps[],
    header?: AgTechTabbedPageHeaderProps,
    body?: AgTechTabbedPageBodyConfigurationProps,
    style?: AgTechTabPageStyle,
    panel?: AgTechRendering,
    classes?: string,
    styles?: CSSProperties
}

export type AgTechTabbedPageHeaderProps = {
    content?: () => React.ReactNode,
    classes?: string
}

export type AgTechTabbedPageBodyConfigurationProps = {
    classes?: string
}

export type AgTechTabbedPageTabProps = {
    text: string,
    content: () => React.ReactNode,
    containerClasses?: string,
    tabClasses?: string,
    isEmphasized?: boolean,
    onRightClick?: (e: AgTechClickEventHandler) => void
}

export type AgTechTabPageContext = {
    currentTab?: AgTechTabbedPageTabProps,
    setCurrentTab: (tab: AgTechTabbedPageTabProps) => void
}

export const AgTechTabPageContext = createContext<AgTechTabPageContext>({
    setCurrentTab: (tab) => { }
});

let AgTechTabbedPage = (props: AgTechTabbedPageProps) => {
    let [selectedTab, setSelectedTab] = useState(props.tabs[0]);

    useEffect(() => {

        // The tabs have changed for this tab page, but we should attempt to stay on the
        // currently selected tab if the new tabs also contain a tab wiht the same text
        let matchingTabs = props.tabs.filter(tab => tab.text === selectedTab.text);

        if (matchingTabs.length === 1) {
            let matchingTab = matchingTabs[0];
            setSelectedTab(matchingTab);
        }
        else {
            setSelectedTab(props.tabs[0]);
        }

    }, [props.tabs])

    let tabPageContext: AgTechTabPageContext = {
        currentTab: selectedTab,
        setCurrentTab: (tab: AgTechTabbedPageTabProps) => setSelectedTab(tab)
    }

    let tabPageStyle = props.style ?? AgTechTabPageStyle.Standard;
    let tabPageStyleClass = tabPageStyle === AgTechTabPageStyle.Badge ? 'agtech-badge-tabbed-page'
                          : tabPageStyle === AgTechTabPageStyle.BadgeUnderline ? 'agtech-badge-tabbed-page agtech-badge-tabbed-page-underline'
                          : tabPageStyle === AgTechTabPageStyle.MultiPage ? 'agtech-multi-page-tabbed-page'
                          : tabPageStyle === AgTechTabPageStyle.Thin ? 'agtech-thin-tabbed-page'
                          : 'standard';

    return (
        <div className={`agtech-tabbed-page ${props.classes ?? ''} ${tabPageStyleClass}`} style={props.styles}>
            <AgTechTabPageContext.Provider value={tabPageContext}>
                { props.panel ? props.panel.rendering() : <AgTechTabbedPageHeader tabPageConfiguration={props} /> }
                <AgTechTabbedPageBody
                    currentTab={selectedTab}
                    configuration={props.body}
                />
            </AgTechTabPageContext.Provider>
        </div>
    )
}

let AgTechTabbedPageHeader = ({ tabPageConfiguration }: { tabPageConfiguration: AgTechTabbedPageProps }) => {
    return (
      <div className={`agtech-tabbed-page-header ${tabPageConfiguration.header?.classes ?? ''}`}>
            <div className="agtech-tabbed-page-header-tabs">
                {tabPageConfiguration.tabs.map(tab => <AgTechTabbedPageTab key={tab.text} {...tab} />)}
            </div>
            <div className="agtech-tabbed-page-header-content">
                {tabPageConfiguration.header?.content ? tabPageConfiguration.header.content() : null}
            </div>
        </div>
    );
}

export const AgTechTabbedPageTab = (props: AgTechTabbedPageTabProps) => {

    let tabButtonRef = useRef(null);
    let tabButtonClickable = useClickable(tabButtonRef);
    
    let tabPageContext: AgTechTabPageContext = useContext<AgTechTabPageContext>(AgTechTabPageContext);
    let isActiveTab = tabPageContext.currentTab?.text === props.text;
    let isEmphasized = props.isEmphasized ?? false;

    // Handles a right click on the tab
    let handleContextMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (props.onRightClick) {
            e.preventDefault();
            props.onRightClick({
                clickEvent: e,
                ...tabButtonClickable
            });
        }
    }

    return (
        <div className={`agtech-tab-container ${isEmphasized ? 'emphasized' : ''}`}>
            <div 
                className={`agtech-tab ${props.tabClasses ?? ''} ${isActiveTab ? 'active' : ''}`}
                onClick={() => tabPageContext.setCurrentTab(props)}
                onContextMenu={handleContextMenuClick} 
                key={props.text} 
                ref={tabButtonRef}
            >        
                {props.text}
            </div>
        </div>
    );
}

export type AgTechTabbedPageBodyProps = {
    currentTab: AgTechTabbedPageTabProps,
    configuration?: AgTechTabbedPageBodyConfigurationProps
}

export const AgTechTabbedPageBody = (props: AgTechTabbedPageBodyProps) => {
    let [currentTabName, setCurrentTabName] = useState(props.currentTab?.text);

    useEffect(() => {
        setCurrentTabName(props.currentTab.text);
    }, [props.currentTab.text]);

    return (
        <div className={`flexed scrollable container ${props.configuration?.classes ?? ''}`}>
            {currentTabName === props.currentTab.text ? (
                <div className='stretched fade-in-400 container'>
                    {props.currentTab.content()}
                </div>
            ) : (
                <div className='stretched centered container'>
                    <div className='loader' />
                </div>
            )}
        </div>
    );
}

export default AgTechTabbedPage;