import { useAgTechLayerCount } from "agtech/web/components/Portals/AgTechPortal";
import { createContext, createRef, useContext } from "react";

/// Types

export type AgTechCoordinates = {
    x: number,
    y: number
};

export const DefaultAgTechCoordinate: AgTechCoordinates = {
    x: 0,
    y: 0
}

export enum AgTechPopUpPosition {
    Beside,
    BesideCentered,
    BelowCentered,
    BelowRight
}

export type AgTechPopUpEventHandlers = {
    onBeforePopUpOpened?: () => void,
    onPopUpOpened?: () => void,
    onPopUpClosed?: () => void
}

export type AgTechPopUpConfiguration = {
    position?: AgTechPopUpPosition,
    emphasizeShadow?: boolean,
    fadeInTime?: string,
    shouldCloseOnBlur?: boolean,
    shouldCloseOthersOnOpen?: boolean,
    topOffset?: number
}

export type AgTechPopUpCoreProps = {
    popup: () => React.ReactNode,
    config?: AgTechPopUpConfiguration,
    handlers?: AgTechPopUpEventHandlers
}

export type AgTechRenderedPopUp = {
    popupRef: React.RefObject<HTMLDivElement>,
    callerRef: React.MutableRefObject<any>,
    close: () => void
}

export type AgTechPopUpRendering = {
    popupId: string,
    callerRef: React.RefObject<any>,
    popupRef: React.RefObject<HTMLDivElement>,
    popup: () => React.ReactNode,
    popupConfig?: AgTechPopUpConfiguration,
    closePopup: (popupId: string) => void
}

/// Context

export type AgTechPopUpContext = {
    caller: React.MutableRefObject<any>,
    closePopUp: () => void,
    childPopUpContext?: AgTechPopUpContext,
    layer: number
}

export const PopUpContext = createContext<AgTechPopUpContext>({
    caller: createRef(),
    closePopUp: () => {},
    layer: 1
});

export const usePopUpContext = (props: AgTechRenderedPopUp): AgTechPopUpContext => {
    const layerCount = useAgTechLayerCount();

    let popupContext: AgTechPopUpContext = {
        caller: props.callerRef,
        closePopUp: props.close,
        layer: layerCount.current
    };

    let parentPopUpContext = useContext(PopUpContext);

    // If a parent popup exists for this popup, assign this popup as a child of that parent
    if (parentPopUpContext && parentPopUpContext.caller.current) {
        popupContext.layer = parentPopUpContext.layer + 1;
        parentPopUpContext.childPopUpContext = popupContext;
    }

    return popupContext;
}