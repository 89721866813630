import { useAppContext } from 'agtech/core/app/AgTechAppContext'
import { AgTechHttpRequestExecutor, useAgTechHttpRequestExecutor } from 'agtech/core/data/actions/AgTechHttpRequests'
import { logDev } from 'agtech/core/logging/AgTechLogger'
import React, { useEffect, useState } from 'react'

export type AgTechDataLoadingRequest<TData> = (httpRequestExecutor: AgTechHttpRequestExecutor) => Promise<AgTechDataLoadingResult<TData>> | Promise<TData> | TData

export type AgTechDataProviderProps<TData> = {
    loadData: AgTechDataLoadingRequest<TData>,
    children: (data: TData, refresh: () => Promise<void>) => JSX.Element,
    content?: AgTechDataProviderContentConfiguration,
    retry?: AgTechDataProviderRetryConfiguration,
    refresh?: AgTechDataProviderRefreshConfiguration
}

export type AgTechDataLoadingResult<TData> = {
    wasAttempted: boolean,
    errorMessage?: string,
    success?: {
        data: TData
    }
}

export type AgTechDataProviderContentConfiguration = {
    loadingText?: string,
    failureText?: string,
}

export type AgTechDataProviderRetryConfiguration = {
    retryButtonText: string,
    retryButtonIcon?: string
}

export type AgTechDataProviderRefreshConfiguration = {
    refreshWhenChanged?: any,
    refreshOnAllRerenders?: boolean
}

declare type AgTechDataProviderState<TData> = {
    isRefreshing: boolean,
    dataLoadingResult?: AgTechDataLoadingResult<TData>
}

const isFormalResponse = <TData, >(response: any): response is AgTechDataLoadingResult<TData> => {
    return response.wasAttempted !== undefined;
}

const AgTechDataProvider = <TData, >(props: AgTechDataProviderProps<TData>): JSX.Element => {
    let appContext = useAppContext();
    let httpRequestExecutor = useAgTechHttpRequestExecutor();
    
    const [dataLoadingState, updateDataLoadingState] = useState<AgTechDataProviderState<TData>>({
        isRefreshing: false
    });

    const loadData = () => {
        if (dataLoadingState.dataLoadingResult?.wasAttempted) {
            updateDataLoadingState({
                isRefreshing: true
            });
        }

        Promise.resolve(props.loadData(httpRequestExecutor)).then(res => {
            updateDataLoadingState({
                isRefreshing: false,
                dataLoadingResult: isFormalResponse(res) ? res : {
                    wasAttempted: true,
                    success: res ? {
                        data: res
                    } : undefined
                }
            });
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (props.refresh?.refreshWhenChanged) {
            logDev('Reloading on refreshWhenChanged');
            loadData();
        }

    }, [props.refresh?.refreshWhenChanged]);

    useEffect(() => {
        if ((props.refresh?.refreshOnAllRerenders ?? false)) {
            logDev('Reloading on rerender');
            loadData();
        }

    }, [props]);

    let requestLoadingText = props.content?.loadingText
        ?? 'Loading...';

    let requestErrorText = props.content?.failureText 
        ?? dataLoadingState.dataLoadingResult?.errorMessage
        ?? 'An error occurred. Please restart the app.';

    if (dataLoadingState.dataLoadingResult?.wasAttempted) {
        return dataLoadingState.dataLoadingResult.success?.data
            ? props.children(dataLoadingState.dataLoadingResult.success.data, async () => loadData())
            : (
                <>
                    {appContext.loading.showLoadingError(requestErrorText, props.retry ? {
                        retryButtonText: props.retry.retryButtonText ?? 'Try again',
                        retryIconText: props.retry.retryButtonIcon ?? 'bi bi-arrow-clockwise',
                        retryAction: async () => loadData()
                    } : undefined)}
                </>
            )
    }
    else {
        return (
            <>
                {appContext.loading.showLoader(requestLoadingText)}
            </>
        )
    }
}

export default AgTechDataProvider