import { AgTechDataAction } from "agtech/core/data/actions/AgTechDataActions";
import { useAgTechHttpDeleteEntityRequest } from "agtech/core/data/http/AgTechApiDeleteRequests";
import { useAgTechHttpSubmitEntityRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { DefaultAgTechConfirmationActions } from "agtech/core/functions/AgTechAppConfirmations";
import { AgTechContact, contactDataSlice } from "app/data/operation/contacts/ContactData";

/// Entities

export type ContactCredit = {
    id: number,
    contactId: number,
    creditAmount: number,
    description: string,
    privateNote: string,
    invoiceId?: number,
    expires?: Date
}

export type ContactCreditIndex = {
    [id: number]: ContactCredit
}

/// Submissions

export const useContactCreditDataWriteCreditAction = (contactId: number): AgTechDataAction<ContactCredit, ContactCredit> => {
    let contactCreditSubmissionRequest = useAgTechHttpSubmitEntityRequest<ContactCredit>();

    return {
        name: 'WriteCredit',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity?.id ? 'Updating credit...' : 'Creating credit...',
            actionConfirmationMessage: `Successfully ${props.originalEntity?.id ? 'updated' : 'created'} credit`
        }),
        validate: async props  => {
            props.validation.failWithWarningIf(props.submittedEntity.creditAmount <= 0, 'Please specify a positive credit amount');
            props.validation.failWithWarningIf(props.submittedEntity.description === '', 'Please specify a description for this credit');
        },
        action: async props => {
            return await contactCreditSubmissionRequest.post({
                path: 'ContactCredit',
                postedData: {
                    ...props.submittedEntity,
                    contactId: contactId
                }
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(contactDataSlice.actions.writeCredit, {
                ...props.responseData,
                expires: props.submittedEntity.expires ? new Date(props.submittedEntity.expires) : undefined
            });
        }
    }
}

export const useContactCreditDataDeleteCreditAction = (): AgTechDataAction<ContactCredit, boolean> => {
    let contactCreditDeletionRequest = useAgTechHttpDeleteEntityRequest();

    return {
        name: 'DeleteCredit',
        getConfiguration: () => ({
            actionExecutionMessage: 'Deleting credit...',
            actionConfirmationMessage: `Successfully deleted credit`,
            showConfirmationMessage: true,
            actionConfirmationDelay: 300
        }),
        validate: async props => {
            props.validation.failWithWarningIf(
                props.submittedEntity.invoiceId !== undefined,
                'This credit has been used in a customer invoice and cannot be deleted.');
        },
        confirm: async props => {
            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: 'Are you sure you want to delete this credit?',
                    confirmationButtonText: 'Delete Credit'
                },
                actions: DefaultAgTechConfirmationActions
            });
        },
        action: async props => {
            return await contactCreditDeletionRequest.deleteEntity({
                controller: 'ContactCredit',
                entityId: props.submittedEntity.id,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(contactDataSlice.actions.deleteCredit, props.submittedEntity);
            props.appContext.popups.closeAll();
        }
    }
}