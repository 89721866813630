import AgTechDataProvider from 'agtech/core/data/providers/AgTechDataProvider';
import AgTechMetric from 'agtech/web/components/Metrics/AgTechMetric';
import AgTechGap from 'agtech/web/components/MIsc/AgTechGap';
import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import InvoiceProcessedInvoiceGrid from 'app/areas/invoices/components/grids/InvoiceProcessedInvoiceGrid';
import { ProcessedSaleInvoice } from 'app/data/invoicing/InvoicingData';
import { useInvoiceDataGetPaidSalesRequest } from 'app/data/invoicing/InvoicingDataActions';
import _ from 'lodash';
import React from 'react'

const SaleInvoicingCompletedInvoicesPage = () => {
    let getProcessedSalesRequest = useInvoiceDataGetPaidSalesRequest();

    return (
        <AgTechDataProvider
            loadData={async executor => await executor.executeRequest(getProcessedSalesRequest, {})}
            content={{
                loadingText: 'Loading paid invoices...',
                failureText: 'Unable to load paid invoices. Please try again.'
            }}
        >
            {(processedSales: ProcessedSaleInvoice[]) => processedSales.length > 0 
                ? (
                    <div className='stretched column-based container'>
                        <div className='tall flexed column-based pr-3 container'>
                            <InvoiceProcessedInvoiceGrid processedInvoices={processedSales} />
                        </div>
                        <div className='tall row-based pl-3 vertical-pane container w-240'>
                            <AgTechMetric header='Processed Invoices' value={processedSales.length.toString()} />
                            <AgTechMetric header='Total Invoiced' value={AgTechMoneyFormatter.format(_.sum(processedSales.map(sale => sale.invoiceTotal)))} />
                            <AgTechGap />
                        </div>
                    </div>
                ) : (
                    <AgTechNoRecordsPage message="There are no paid invoices in the system right now. Invoice paying can be performed beneath the 'Unpaid Invoices' tab." />
                ) }
        </AgTechDataProvider>
    )
}

export default SaleInvoicingCompletedInvoicesPage;