import { AgTechLink } from 'agtech/web/components/Buttons/AgTechButtons'
import LotDetailPopUp from 'app/areas/lots/components/popups/LotDetailPopUp'
import LotDialog from 'app/areas/lots/dialogs/LotDialog'
import { useSaleLot } from 'app/data/lots/LotData'
import { useLotCategoryData } from 'app/data/lots/categories/LotCategoryData'
import React from 'react'

const LotLink = (props: { lotId: number, classes?: string }) => {
    const lot = useSaleLot(props.lotId);
    const lotCategoryData = useLotCategoryData();

    const lotCategory = lot.lotCategoryId ? lotCategoryData[lot.lotCategoryId] : undefined;

    return (
        <AgTechLink
            content={lot.lotNumber}
            body={() => lotCategory ? (
                <div className='row-based tall ml-auto mr-3 centered container'>
                    <div className='bordered' style={{ height: 14, width: 24, borderRadius: 2, backgroundColor: lotCategory.color }} />
                </div>
            ) : undefined}
            onClick={e => e.dialogs.openDialog({
                dialog: () => <LotDialog lotId={props.lotId} />
            })}
            onHover={() => ({
                popup: () => <LotDetailPopUp lotId={props.lotId} />,
                config: {
                    topOffset: 24,
                    shouldCloseOthersOnOpen: true
                }})
            }
            classes={props.classes}
        />
    )
}

export default LotLink