import { IAgTechAppContext } from "agtech/core/app/AgTechAppContext";
import { AgTechDataAction, AgTechDataActionExecutionResult, AgTechDataActionValidationProps, getActionLoggingContext } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechFormControlIndex } from "agtech/core/forms/AgTechFormControls";
import { AgTechFormState } from "agtech/core/forms/AgTechFormState";
import { logDev } from "agtech/core/logging/AgTechLogger";
import { executeWithMinimumDelay } from "agtech/core/utilities/AgTechUtilities";
import { Dispatch } from "redux";

export type AgTechFormDataActionProps<TFormData, TActionData, TResponseData> = {
    appContext: IAgTechAppContext,
    dispatch: Dispatch,
    initialFormData: TFormData,
    getActionData: (formData: TFormData) => TActionData,
    formDataAction: AgTechDataAction<TActionData, TResponseData>,
    formDataRef: React.MutableRefObject<TFormData>,
    formControlsRef: React.MutableRefObject<AgTechFormControlIndex<TFormData>>,
    setFormState: (formState: AgTechFormState) => void
}

export const useFormDataAction = <TFormData, TActionData, TResponseData>(
    formDataActionProps: AgTechFormDataActionProps<TFormData, TActionData, TResponseData>
): AgTechDataAction<TActionData, TResponseData> => {
    return {
        name: formDataActionProps.formDataAction.name,
        getConfiguration: formDataActionProps.formDataAction.getConfiguration,
        confirm: formDataActionProps.formDataAction.confirm,
        onSuccess: formDataActionProps.formDataAction.onSuccess,
        onFailure: formDataActionProps.formDataAction.onFailure,
        validate: async props => await validateFormDataAction(formDataActionProps, props),
        action: async () => {
            return await executeFormDataAction(formDataActionProps);
        }
    }
}

const validateFormDataAction = async <TFormData, TActionData, TResponseData>(
    props: AgTechFormDataActionProps<TFormData, TActionData, TResponseData>,
    validationProps: AgTechDataActionValidationProps<TActionData>
) => {
    let warningMessages: string[] = [];

    logDev('Executing form validation');

    Object.values(props.formControlsRef.current).forEach(field => {
        let fieldWarningMessage = field.validateOnSubmit(props.formDataRef.current);

        if (fieldWarningMessage) {
            warningMessages.push(fieldWarningMessage);
        }
    });

    if (warningMessages && warningMessages.length > 0) {
        warningMessages.forEach(warning => {
            validationProps.validation.failWithWarning(warning);
        });
    }
    else if (props.formDataAction.validate) {
        if (props.formDataAction.validate) {
            await props.formDataAction.validate({
                ...validationProps,
            });
        }
    }
}

const executeFormDataAction = async <TFormData, TActionData, TResponseData>(
    props: AgTechFormDataActionProps<TFormData, TActionData, TResponseData>
) => {
    return new Promise<AgTechDataActionExecutionResult<TResponseData>>(res => {
        props.setFormState(AgTechFormState.Submitting);

        let executionProps = {
            originalEntity: props.getActionData(props.initialFormData),
            submittedEntity: props.getActionData(props.formDataRef.current)
        };

        executeWithMinimumDelay(async () => {
            props.formDataAction.action({
                ...executionProps,
                actionLoggingContext: getActionLoggingContext({
                    ...executionProps,
                    dataAction: props.formDataAction
                })
            }).then(result => {
                if (result.success) {
                    props.setFormState(AgTechFormState.Successful);
                }
                else {
                    props.setFormState(AgTechFormState.Failed);
                }

                res(result);
            });
        }, 500)
    });
}