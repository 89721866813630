import { AgTechClickEventHandler, useClickable } from 'agtech/web/components/Buttons/AgTechButtons';
import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlStyleProps } from 'agtech/core/forms/AgTechFormControls';
import { useAgTechHoverable } from 'agtech/web/components/Portals/Popups/AgTechPopUpOnHoverContainer';
import { AgTechPopUpCoreProps } from 'agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData';
import React, { useContext, useRef } from 'react'

export type AgTechFormReadOnlyTextControlProps<TEntity> = {
    entityType: TEntity,
    entity?: TEntity,
    caption: string,
    value: (entity: TEntity) => string,
    onClick?: (e: AgTechClickEventHandler, entity: TEntity) => void,
    showPopUpOnHover?: (entity: TEntity) => AgTechPopUpCoreProps | undefined,
    controlStyles?: AgTechFormControlStyleProps,
    classes?: string,
    formControlClasses?: string,
    content?: (entity: TEntity) => React.ReactNode
}

export const AgTechFormReadOnlyTextControl = <TEntity, >(props: AgTechFormReadOnlyTextControlProps<TEntity>) => {
    let formContext = useContext(FormContext);
    let formControlContainerRef = useRef<HTMLDivElement>(null);
    let formControlRef = useRef<HTMLInputElement>(null);
    let formControlWidth: string = `${props.controlStyles?.width ?? 50}%`;
    let formControlHighlightClass = props.controlStyles?.highlighted ? 'highlighted' : '';

    let inputClickable = useClickable(formControlRef);

    let handleInputClicked = (e: React.MouseEvent<HTMLInputElement>) => {
        if (props.onClick) {
            e.stopPropagation();

            let inputClickableEvent: AgTechClickEventHandler = {
                ...inputClickable,
                clickEvent: e
            };

            props.onClick(inputClickableEvent, formContext.entity);
        }
    }

    let popUpProps = undefined;

    if (props.showPopUpOnHover) {
        let show = props.showPopUpOnHover;
        let renderProps = show(formContext.entity);

        if (renderProps) {
            let givenPopupProps = renderProps;
            popUpProps = () => givenPopupProps
        }
    }

    useAgTechHoverable({
        container: formControlContainerRef,
        popupRenderProps: popUpProps
    });

    let formControlClasses = `${props.onClick ? 'agtech-link' : ''} ${props.formControlClasses ?? ''}`;

    return (
        <div
            ref={formControlContainerRef}
            className={`agtech-form-control relative ${formControlHighlightClass} ${props.classes ?? ''}`}
            style={{ maxWidth: formControlWidth }}
        >
            <h1 className="agtech-form-control-caption">{props.caption + (props.controlStyles?.required ? ' *' : '')}</h1>
            <input
                className={formControlClasses}
                type="text"
                value={props.value(props.entity ?? formContext.entity)}
                readOnly={true}
                ref={formControlRef}
                onClick={handleInputClicked}
            />
            {props.content ? (
                <div className='absolute centered container' style={{ bottom: 0, height: 36, right: 12 }}>
                    {props.content(formContext.entity)}
                </div>
            ) : null}
        </div>
    );
}