import { FormContext } from "agtech/core/forms/AgTechFormContext"
import { AgTechFormControlUpdate } from "agtech/core/forms/AgTechFormControls"
import { logEvent } from "agtech/core/logging/AgTechLogger"
import { IAgTechSelectionPopUpItem, useAgTechSelectionPopUp, AgTechSelectionControlContentProps, AgTechSelectionControlItemProps, AgTechSelectionControlItemCreationProps } from "agtech/web/components/Forms/Controls/Selection/AgTechCoreSelectionControlData"
import { AgTechSelectionListBody, AgTechSelectionListHeader } from "agtech/web/components/Forms/Controls/Selection/AgTechSelectionPopUp"
import { CSSProperties, ReactNode, useContext, useEffect, useRef, useState } from "react"

export type AgTechFormSelectionControlItemProps<TFormEntity, TItem extends IAgTechSelectionPopUpItem> = {
    selectionControlTitle: string,
    items: TItem[],
    getSelectedItemId: (formEntity: TFormEntity) => string | undefined,
    onItemSelected: AgTechFormControlUpdate<TFormEntity, TItem | undefined>,
    onBlur?: (text: string) => void,
    filterItems: (item: TItem, filter: string) => boolean,
    itemText: (item: TItem) => string,
    itemHeight: number,
    itemRendering: (item: TItem) => React.ReactNode,
    itemCreationConfig?: AgTechSelectionControlItemCreationProps<TItem>,
    styles?: CSSProperties
}

export type AgTechFormSelectionControlInputProps = {
    caption: string,
    readonlyIf?: (val: any) => boolean,
    isRequired?: boolean,
    showAsRequired?: boolean,
    width?: number,
    tabIndex?: number,
    inputClasses?: string,
    captionClasses?: string,
    containerClasses?: string,
    containerStyles?: CSSProperties,
    controlClasses?: string,
}

export type AgTechFormSelectionControlProps<TFormEntity, TItem extends IAgTechSelectionPopUpItem> = {
    entityType: TFormEntity,
    selection: AgTechFormSelectionControlItemProps<TFormEntity, TItem>,
    control: AgTechFormSelectionControlInputProps,
    customSelectionContent?: AgTechSelectionControlContentProps<TItem>
}

declare type AgTechFormSelectionControlState = {
    searchFilter: string,
    selectedItemText: string,
    isOpen: boolean
}

export const AgTechSelectionFormControl = <TFormEntity, TSelectionItem extends IAgTechSelectionPopUpItem>(props: AgTechFormSelectionControlProps<TFormEntity, TSelectionItem>) => {
    let formContext = useContext(FormContext);
    let selectionInputRef = useRef<HTMLInputElement>(null);

    let selectedItemId = props.selection.getSelectedItemId(formContext.entity);

    let selectedItem = selectedItemId
        ? props.selection.items.filter(item => item.id.toString() === selectedItemId)[0]
        : undefined;

    let [selectionState, updateSelectionState] = useState<AgTechFormSelectionControlState>({
        searchFilter: '',
        selectedItemText: selectedItem ? props.selection.itemText(selectedItem) : '',
        isOpen: false
    });

    let selectionStateRef = useRef<AgTechFormSelectionControlState>(selectionState);

    const setSelectionState = (updatedState: AgTechFormSelectionControlState) => {
        selectionStateRef.current = { ...updatedState };
        updateSelectionState(updatedState);
    }

    useEffect(() => {
        if (selectedItem) {     
            setSelectionState({
                ...selectionStateRef.current,
                selectedItemText: props.selection.itemText(selectedItem)
            });
        }
    }, [selectedItemId]);

    let handleItemCleared = async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        if (formContext.entity) {
            await formContext.handleFieldChanged(undefined, props.selection.onItemSelected);
        }
        else {
            await props.selection.onItemSelected(formContext.entity, undefined);
        }

        setSelectionState({
            selectedItemText: '',
            isOpen: false,
            searchFilter: ''
        });
    }

    useAgTechSelectionPopUp({
        selectionRef: selectionInputRef,
        selectionProps: {
            items: {
                selectionItems: props.selection.items,
                getSelectedItemId: () => selectedItemId,
                onItemSelected: async selectedItem => {
                    if (formContext.entity) {
                        await formContext.handleFieldChanged(selectedItem, props.selection.onItemSelected);
                    }
                    else {
                        await props.selection.onItemSelected(formContext.entity, selectedItem);
                    }
        
                    setSelectionState({
                        ...selectionStateRef.current,
                        selectedItemText: props.selection.itemText(selectedItem)
                    });
                },
                filterItems: props.selection.filterItems,
                itemCreationConfig: props.selection.itemCreationConfig
            },
            content: props.customSelectionContent ?? {
                header: headerProps => (
                    <AgTechSelectionListHeader
                        {...headerProps}
                        title={props.selection.selectionControlTitle}
                        creationConfig={props.selection.itemCreationConfig}
                    />
                ),
                body: bodyProps => (
                    <AgTechSelectionListBody
                        {...bodyProps}
                        itemHeight={props.selection.itemHeight} 
                        item={props.selection.itemRendering}
                    />
                ),
                styles: {
                    width: selectionInputRef.current?.offsetWidth,
                    ...props.selection.styles
                }
            }
        },
        onPopupOpened: () => setSelectionState({
            ...selectionStateRef.current,
            isOpen: true
        }),
        onPopupClosed: () => {
            setSelectionState({
                ...selectionStateRef.current,
                searchFilter: '',
                isOpen: false
            });
        },
        onPopUpBlurred: () => {
            if (props.selection.onBlur) {
                props.selection.onBlur(selectionStateRef.current.searchFilter);
            }
        }
    });

    let selectionValueActiveClass = selectionState.isOpen ? 'active' : '';
    let selectionValueFilteredClass = selectionState.searchFilter ? 'filtered' : '';

    let shouldShowAsRequired = props.control.isRequired && (props.control.showAsRequired ?? true);
    let formControlWidth: string = `${props.control.width ?? 50}%`;

    return (
        <div className={`agtech-form-control ${props.control.containerClasses ?? ''}`} style={{ maxWidth: formControlWidth, ...props.control.containerStyles }}>
            { props.control.caption ? (
                <h1 className={`agtech-form-control-caption ${props.control.captionClasses ?? ''}`}>{props.control.caption + (shouldShowAsRequired ? ' *' : '')}</h1>
            ) : null }
            <div className={`selection-form-control ${props.control.controlClasses ?? ''}`}>
                <div className={`selection-form-control-value ${selectionValueActiveClass} ${selectionValueFilteredClass}`}>
                    {selectionStateRef.current.selectedItemText}
                </div>
                <input
                    ref={selectionInputRef}
                    className={`selection-form-control-search ${props.control.inputClasses ?? ''}`}
                    aria-autocomplete="list"
                    autoComplete="off"
                    type='text'
                    tabIndex={props.control.tabIndex}
                    value={selectionState.searchFilter}
                    onChange={e => {
                        setSelectionState({
                            ...selectionState,
                            searchFilter: e.target.value
                        });
                    }}
                />
                {!props.control.isRequired && selectedItemId && selectedItemId !== '0' ? 
                    <i className="fas fa-times selection-form-control-icon" onClick={handleItemCleared} /> : 
                    <i className="fas fa-caret-down selection-form-control-icon" /> }
            </div>
        </div>
    )
}
