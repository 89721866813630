
/// Contact DTOs

import { AgTechApiEntityResponse, AgTechHttpRequest, AgTechHttpResponses, convertFromEntityResponse } from "agtech/core/data/actions/AgTechHttpRequests";
import { DataActionResults, AgTechDataAction } from "agtech/core/data/actions/AgTechDataActions";
import { useAgTechGetRequest } from "agtech/core/data/http/AgTechApiGetRequests";
import { useAgTechHttpPostEntityRequest, useAgTechHttpPostRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { ContactCredit } from "app/data/operation/contacts/credits/ContactDataCredits";
import { AgTechContact, ContactDetails, DefaultAgTechContact, contactDataSlice } from "app/data/operation/contacts/ContactData";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ContactNote } from "app/data/operation/contacts/notes/ContactDataNotes";
import { SaleBidder } from "app/data/bidders/BidderData";
import { useOperationData, useOperationId } from "app/data/operation/SaleDataOperation";

declare type ContactDetailsDTO = {
    contact: AgTechContact,
    notes: ContactNote[],
    credits: ContactCredit[]
}

/// Contact Getters

export const useContactDataGetContactDetailsRequest = (): AgTechHttpRequest<number, ContactDetailsDTO> => {
    let contactDetailGetRequest = useAgTechGetRequest<ContactDetailsDTO>();

    return {
        execute: contactId => contactDetailGetRequest.get({
            path: `Contact/${contactId}/Details`,
            onSuccess: response => {
                return AgTechHttpResponses.Success({
                    ...response.body,
                    credits: response.body.credits.map<ContactCredit>(credit => ({
                        ...credit,
                        expires: credit.expires && credit.expires.toString() !== '0001-01-01T00:00:00' ? credit.expires : undefined
                    })),
                    notes: response.body.notes.map<ContactNote>(note => { return { 
                        ...note,
                        date: new Date(note.date) 
                    }})
                });
            }
        })
    }
}

/// Contact Data Actions

export const useContactDataRefreshContactAction = (): AgTechDataAction<AgTechContact[], any> => {
    let dispatch = useDispatch();

    return {
        name: 'RefreshContacts',
        action: async props => {
            let hydratedContacts = props.submittedEntity.map<AgTechContact>(cnt => ({
                ...cnt,
                credits: cnt.credits.map<ContactCredit>(credit => ({
                    ...credit,
                    expires: credit.expires && credit.expires.toString() !== '0001-01-01T00:00:00'
                        ? new Date(credit.expires)
                        : undefined
                })),
                notes: cnt.notes.map<ContactNote>(note => ({
                    ...note,
                    date: new Date(note.date) 
                }))
            }));

            dispatch(contactDataSlice.actions.refreshContacts(hydratedContacts));

            return DataActionResults.Success('');
        }
    }
}

export const useContactDataWriteContactAction = (): AgTechDataAction<AgTechContact, AgTechContact> => {
    let writeContactSubmissionRequest = useAgTechHttpPostEntityRequest<AgTechContact>();

    return {
        name: 'WriteContact',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity?.id ? 'Updating contact...' : 'Creating contact...',
            actionConfirmationMessage: `Successfully ${props.originalEntity?.id ? 'updated' : 'created'} contact`
        }),
        action: async props => {
            return await writeContactSubmissionRequest.post({
                path: 'Contact/Submit',
                postedData: props.submittedEntity
            });
        },
        onSuccess: async response => {
            response.executeReducerAction(contactDataSlice.actions.writeContact, response.responseData);
        }
    }
}

export const useContactDataDeleteContactAction = (): AgTechDataAction<AgTechContact, boolean> => {
    let saleBiddersGetRequest = useAgTechGetRequest<SaleBidder[]>();
    let contactDeletionHttpRequest = useAgTechHttpPostRequest<AgTechContact, boolean>();

    return {
        name: 'DeleteContact',
        getConfiguration: props => ({
            actionExecutionMessage: 'Deleting contact...',
            actionConfirmationMessage: `Successfully deleted contact`
        }),
        validate: async props  => {
            let bidderRegistrationsForContactResponse = await saleBiddersGetRequest.get({
                path: 'Bidder/BidderRegistrations/' + props.submittedEntity.id
            });

            props.validation.failWithWarningIf(
                bidderRegistrationsForContactResponse.success === undefined,
                {
                    header: `Contact "${props.submittedEntity.firstName} ${props.submittedEntity.lastName}" has active bidder registrations and cannot be deleted.`,
                    details: 'Please remove all bidder registrations for this contact before deleting.'
                }
            )
        },
        confirm: async props => {
            let contactFullName = `${props.submittedEntity.firstName} ${props.submittedEntity.lastName}`;
            
            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: `Are you sure you want to remove "${contactFullName}" from your contacts?`,
                    details: `This contact does not have any active bidder registrations, but all notes and credits will be removed.`,
                    confirmationButtonText: 'Delete Contact',
                    width: '480px'
                }
            });
        },
        action: async props => {
            return await contactDeletionHttpRequest.post({
                path: 'Contact/Delete',
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(contactDataSlice.actions.deleteContact, props.submittedEntity);
            props.appContext.popups.closeAll();
        }
    }
}

export const useContactDataImportContactsAction = (): AgTechDataAction<AgTechContact[], AgTechContact[]> => {
    let operationId = useOperationId();
    let contactImportPostRequest = useAgTechHttpPostRequest<AgTechContact[], AgTechApiEntityResponse<AgTechContact[]>>();

    return {
        name: 'ImportContacts',
        action: async props => {
            return await contactImportPostRequest.post({
                path: 'Contact/Import',
                postedData: props.submittedEntity.map(cnt => ({
                    ...cnt,
                    operationId: operationId
                })),
            })
            .then(res => convertFromEntityResponse(res));
        },
        onSuccess: async props => {
            props.executeReducerAction(contactDataSlice.actions.importContacts, props.responseData);
        }
    }
}

/// Contact Details

export type ContactDetailData = {
    contactDetails: ContactDetails,
    updateContactDetails: (details: ContactDetails) => void
}

export const useContactDetails = (contactId: number): ContactDetailData => {
    let contactDetailsRequest = useContactDataGetContactDetailsRequest();

    let [contactDetails, updateContactDetails] = useState<ContactDetails>({
        ...DefaultAgTechContact,
        credits: [],
        notes: []
    });

    useEffect(() => {
        contactDetailsRequest.execute({
            data: contactId
        })
        .then(detailsResponse => {
            if (detailsResponse.success) {
                let contactDetails = detailsResponse.success.data;

                updateContactDetails({
                    ...contactDetails.contact,
                    credits: contactDetails.credits,
                    notes: contactDetails.notes
                });
            }
        });
    }, [contactId]);

    return {
        contactDetails,
        updateContactDetails
    };
}