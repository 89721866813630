import { AgTechAppLoadingRetryConfiguration } from 'agtech/core/functions/AgTechAppLoading'
import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechErrorIcon } from 'agtech/web/components/Icons/AgTechIcons'
import React from 'react'

const AgTechErrorMessage = (props: { errorMessage: string, retryConfiguration?: AgTechAppLoadingRetryConfiguration }) => {
    return (
        <div className="stretched row-based centered container">
            <AgTechErrorIcon size={38} classes='mb-4' />
            <h1 className='font-size-larger font-bold mb-3'>{props.errorMessage}</h1>
            {props.retryConfiguration ? (
                <AgTechButton
                    text={props.retryConfiguration.retryButtonText}
                    icon={props.retryConfiguration.retryIconText}
                    action={props.retryConfiguration.retryAction}
                    classes='mt-6 highlight'
                />
            ) : null}
        </div>
    )
}

export default AgTechErrorMessage