import AgTechGap from 'agtech/web/components/MIsc/AgTechGap';
import AgTechNoRecordsPage, { AgTechNoRecordsPageProps } from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import React from 'react'

export type AgTechCardCollectionProps<TEntity> = {
    header?: React.ReactNode,
    headerContent?: () => React.ReactNode,
    footer?: React.ReactNode,
    card: (entity: TEntity) => React.ReactNode,
    entities: TEntity[],
    maxCardWidth?: string,
    title?: string,
    classes?: string,
    noRecordConfig?: AgTechNoRecordsPageProps
};

let AgTechCardCollection = <TEntity,>(props: AgTechCardCollectionProps<TEntity>) => {

    let cardCollectionHeader = () => {
        if (props.header) {
            return (
                <div className="agtech-card-collection-header">
                    {props.header}
                </div>
            );
        }
        else if (props.title) {
            return (
                <div className="agtech-card-collection-header agtech-columns-container agtech-vertically-centered-container">
                    {props.title}
                    { props.headerContent ? (
                        <>
                            <AgTechGap />
                            {props.headerContent()}
                        </>
                    ) : null }
                </div>
            )
        }
        else {
            return null;
        }
    }

    return props.entities.length > 0 ? (
        <div className={`agtech-card-collection`}>
            {cardCollectionHeader()}
            <div
                className={`agtech-card-collection-body ${props.classes ?? ''}`}
                style={{gridTemplateColumns: `repeat(auto-fill, ${props.maxCardWidth ?? '300px'})`}}
            >
                {props.entities.map(entity => props.card(entity))}
            </div>
            {props.footer ?
                <div className="agtech-card-collection-footer">
                    {props.footer}
                </div>
                : null
            }
        </div>
    ) : props.noRecordConfig ? (
        <AgTechNoRecordsPage {...props.noRecordConfig} />
    ) : null;
};

export const AgTechTwoColumnCardCollection = <TEntity, >(props: AgTechCardCollectionProps<TEntity>) => {
    return (
        <AgTechCardCollection
            {...props}
            maxCardWidth='48%'
            classes='horizontally-centered'
        />
    )
}

export default AgTechCardCollection;