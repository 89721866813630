import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog';
import SaleBidderRegistrationSelectionGrid from 'app/areas/bidders/components/grids/BidderRegistrationSelectionGrid';
import { useCurrentSale } from 'app/data/sales/SaleData';
import React from 'react'

const BidderSelectionViewDialog = () => {
    let currentSale = useCurrentSale();

    return (
        <AgTechViewDialog
            header={{
                title: `Register Bidders - ${currentSale.saleName}`,
                detail: "To register bidders for this sale, select from the contacts below"
            }}
            width="1200px"
            waitOnDataLoaded={() => true}
            textWhileLoading='Loading sale bidders...'
        >
            <SaleBidderRegistrationSelectionGrid />
        </AgTechViewDialog>
    )
};

BidderSelectionViewDialog.Button = (props: AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        ...props,
        action: e => e.dialogs.openDialog({
            dialog: () => <BidderSelectionViewDialog />
        })
    };

    buttonProps.text = buttonProps.text ?? 'Register New Bidders';
    buttonProps.icon = buttonProps.icon ?? 'bi bi-card-checklist';

    return <AgTechButton {...buttonProps} />
}

export default BidderSelectionViewDialog;