import AgTechDataActionSurface from 'agtech/core/data/actions/AgTechDataActionSurfaces';
import OperationUploadLogoButton from 'app/areas/operation/components/buttons/OperationUploadLogoButton';
import { useOperationLogo } from 'app/data/operation/SaleDataOperation'
import { useOperationDataSetOperationLogoAction } from 'app/data/operation/SaleDataOperationActions';
import React from 'react'

export type LogoUpdateFormProps = {
    classes?: string,
    onLogoSubmitted?: () => Promise<void>
}

const LogoUpdateForm = (props: LogoUpdateFormProps) => {
    let operationLogo = useOperationLogo();
    let updateOperationLogoAction = useOperationDataSetOperationLogoAction();

    return (
        <AgTechDataActionSurface
            action={updateOperationLogoAction}
            classes={props.classes}
            behavior={{
                returnToOpenStateOnCompletion: true,
                eventHandlers: {
                    onExecutionCompleted: props.onLogoSubmitted
                }
            }}
        >
            {(executor) => (
                <div className='row-based stretched container'>
                    <div className='flexed centered container'>
                        {operationLogo ? (
                            <div className='row-based rounded container' style={{ height: 280, width: 420 }}>
                                <img
                                    title="Operation Logo"
                                    src={`data:;base64,${operationLogo}`}
                                />
                                <div className='wide row-based-horizontally-centered container'>
                                    <OperationUploadLogoButton
                                        text='Upload new logo'
                                        classes='highlight mt-6'
                                        styles={{ width: 180 }}
                                        onLogoUpdated={async logo => {
                                            await executor.executeAction(updateOperationLogoAction, {
                                                submittedEntity: logo
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='row-based bordered rounded container' style={{ height: 280, width: 420 }}>
                                <OperationUploadLogoButton
                                    text='Upload logo'
                                    classes='highlight'
                                    styles={{ width: 180 }}
                                    onLogoUpdated={async logo => {
                                        await executor.executeAction(updateOperationLogoAction, {
                                            submittedEntity: logo
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </AgTechDataActionSurface>
    )
}

export default LogoUpdateForm