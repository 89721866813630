export const DefaultAgTechUser: AgTechUser = {
    name: '',
    username: '',
    token: '',
    role: []
};

export type AgTechUser = {
    name: string,
    username: string,
    token: string,
    role: string[]
};

export const AuthTokenStorageKey = 'auth_token';
export const RefreshTokenStorageKey = 'refresh_token';