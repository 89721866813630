import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from "agtech/web/components/Buttons/AgTechButtons";
import { AgTechFormSection } from "agtech/web/components/Forms/AgTechForm";
import AgTechFormDialog from "agtech/web/components/Portals/Dialogs/AgTechFormDialog";
import { ChangeItemOnInvoiceDTO, LotsByContactDTO, useLotDataGetLotsByContactForLotChange, useLotDataWriteLotInvoiceChangesAction } from "app/data/lots/invoices/LotInvoiceDataActions";
import InvoiceChangeLotsGrid from "../components/grids/InvoiceChangeLotsGrid";
import { AgTechEntitySelectionPreservedOriginal } from "agtech/web/components/Grids/AgTechGridEntities";

export type InvoiceChangeLotsDialogProps = {
    invoiceId: number,
    contactId: number
}

export type SelectableLotsData = {
    Lots: AgTechEntitySelectionPreservedOriginal<LotsByContactDTO>[],
}

const InvoiceChangeLotsDialog = (props: InvoiceChangeLotsDialogProps) => {
    let getLotsAction = useLotDataGetLotsByContactForLotChange();
    let writeAction = useLotDataWriteLotInvoiceChangesAction(props.invoiceId);

    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: e => e.executeAndConvertRequest({
                    request: getLotsAction,
                    inputData: {contactId: props.contactId, invoiceId: props.invoiceId},
                    convert: async lots => {
                        let ldata: SelectableLotsData = {
                            Lots: lots.map<AgTechEntitySelectionPreservedOriginal<LotsByContactDTO>>(l => ({id: l.id, entity: l, isSelected: l.invoiceId === props.invoiceId, originalSelection: l.invoiceId === props.invoiceId}))
                        }

                        return ldata;
                    }
                })
            }} 
            formAction={{
                dataAction: writeAction,
                getActionData: d => {
                    let filtered = d.Lots.filter(l => l.isSelected != l.originalSelection);

                    return filtered.map<ChangeItemOnInvoiceDTO>(l => ({
                        remove: l.originalSelection == true && l.isSelected == false,
                        id: l.entity.id
                    }));
                }
            }}
            structure={{
                header: 'Change Lots',
                submitButtonText: 'Submit',
                width: 600
            }}
         >
            {(formContext) => (
                <AgTechFormSection
                header="Lots"
                classes='flexed'
                bodyClasses='flexed pt-3'>
                    <InvoiceChangeLotsGrid formContext={formContext} />
                </AgTechFormSection>  
            )}
        </AgTechFormDialog>
    )
}

InvoiceChangeLotsDialog.Open = (e: AgTechClickEventHandler, props: InvoiceChangeLotsDialogProps) => e.dialogs.openDialog({
    dialog: () => <InvoiceChangeLotsDialog {...props} />
});

InvoiceChangeLotsDialog.Button = (props: AgTechButtonProps & InvoiceChangeLotsDialogProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'Change Lots',
        bootstrapIcon: 'bi-arrow-left-right',
        ...props,
        action: e => InvoiceChangeLotsDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default InvoiceChangeLotsDialog;