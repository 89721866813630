import { AgTechHttpRequest } from 'agtech/core/data/actions/AgTechHttpRequests';
import { useAgTechApiDownloadRequest } from 'agtech/core/data/http/AgTechApiGetRequests';
import { useCurrentSale } from 'app/data/sales/SaleData';

export const useReportDataGetAfterSaleReportRequest = (): AgTechHttpRequest<number, any> => {
    let afterSaleReportRequest = useAgTechApiDownloadRequest();

    return {
        execute: async props => await afterSaleReportRequest.download({
            path: 'Sales/GetAfterSaleReportDocument/' + props.data,
            fileNameWithExtension: `AfterSaleReport_${new Date().toDateString()}.pdf`
        })
    }
}

export const useReportDataGetOutstandingCreditsReportRequest = (): AgTechHttpRequest<any, any> => {
    let outstandingCreditsRequest = useAgTechApiDownloadRequest();

    return {
        execute: async () =>  await outstandingCreditsRequest.download({
            path: 'ContactCredit/GetOutstandingContactCreditsReport',
            fileNameWithExtension: `OutstandingCredits_${new Date().toDateString()}.pdf`
        })
    }
}

export const useReportDataGetLotOrderReportRequest = (): AgTechHttpRequest<number, any> => {
    let lotOrderRequest = useAgTechApiDownloadRequest();

    return {
        execute: async props =>  await lotOrderRequest.download({
            path: 'Lot/GetSaleOrderDocument/' + props.data,
            fileNameWithExtension: `LotSaleOrder_${new Date().toDateString()}.pdf`
        })
    }
}

export const useReportDataGetLotsByPurchaserReportRequest = (): AgTechHttpRequest<number, any> => {
    let lotsByPurchaserRequest = useAgTechApiDownloadRequest();

    return {
        execute: async props => await lotsByPurchaserRequest.download({
            path: 'Sales/GetLotsByPurchasersReport/' + props.data,
            fileNameWithExtension: `LotsByPurchaser_${new Date().toDateString()}.pdf`
        })
    }
}

export const useReportDataGetLotsPurchasedInOrderReportRequest = (): AgTechHttpRequest<number, any> => {
    let lotsByPurchaserRequest = useAgTechApiDownloadRequest();

    return {
        execute: async props => await lotsByPurchaserRequest.download({
            path: 'Sales/GetLotsPurchasedInOrderReport/' + props.data,
            fileNameWithExtension: `LotsInOrderByPurchaser_${new Date().toDateString()}.pdf`
        })
    }
}

export const useReportDateGetUnpaidInvoicesRequest = (): AgTechHttpRequest<number, any> => {
    let lotsByPurchaserRequest = useAgTechApiDownloadRequest();

    return {
        execute: async props => await lotsByPurchaserRequest.download({
            path: 'Invoice/GetUnpaidInvoiceReport/' + props.data,
            fileNameWithExtension: `UnpaidInvoicesReport_${new Date().toDateString()}.pdf`
        })
    }
}

/// Reports

export const useSaleReports = () => {
    let currentSale = useCurrentSale();
    let afterSaleReportRequest = useReportDataGetAfterSaleReportRequest();
    let outstandingCreditsReportRequest = useReportDataGetOutstandingCreditsReportRequest();
    let lotOrderReportRequest = useReportDataGetLotOrderReportRequest();
    let lotDetailReportRequest = useReportDataGetLotsByPurchaserReportRequest();
    let lotPurchaseOrderReportRequest = useReportDataGetLotsPurchasedInOrderReportRequest();
    let upaidInvoicesReportRequest = useReportDateGetUnpaidInvoicesRequest();
    
    return [
        {
            reportName: 'After Sale Report',
            reportDescription: 'The after sale report for viewing after the sale.',
            reportDownloadAction: async () => afterSaleReportRequest.execute({
                data: currentSale.id
            }),
            reportIcon: 'chart-area'
        },
        {
            reportName: 'Outstanding Credits Report',
            reportDescription: 'Report for showing outstanding credits',
            reportDownloadAction: async () => outstandingCreditsReportRequest.execute({
                data: undefined
            }),
            reportIcon: 'dollar-sign'
        },
        {
            reportName: 'Lot Order Report',
            reportDescription: 'Report shows lot order for handing out to bidders',
            reportDownloadAction: async () => lotOrderReportRequest.execute({
                data: currentSale.id
            }),
            reportIcon: 'list'
        },
        {
            reportName: 'Lot Details by Purchaser Report',
            reportDescription: 'This report contains lot sale details ordered by lot purchaser.',
            reportDownloadAction: async () => lotDetailReportRequest.execute({
                data: currentSale.id
            }),
            reportIcon: 'list'
        },
        {
            reportName: 'Lot Details by Lot Number Report',
            reportDescription: 'This report contains lot sale details ordered by lot number.',
            reportDownloadAction: async () => lotPurchaseOrderReportRequest.execute({
                data: currentSale.id
            }),
            reportIcon: 'list'
        },
        {
            reportName: 'Unpaid Invoices',
            reportDescription: 'This report contains a list of the unpaid invoices for the current sale.',
            reportDownloadAction: async () => upaidInvoicesReportRequest.execute({
                data: currentSale.id
            }),
            reportIcon: 'list'
        }
    ]
}