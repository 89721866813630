import AgTechTitleDetailHeader from '../../Headers/AgTechTitleDetailHeader';
import React, { useEffect, useRef } from 'react'
import { AgTechButton, AgTechClickEventHandler } from '../../Buttons/AgTechButtons';
import AgTechModal from './AgTechModal';
import { AgTechConfirmationProps } from 'agtech/core/functions/AgTechAppConfirmations';

export type AgTechConfirmationModalProps = AgTechConfirmationProps & {
    height?: string,
    width?: string,
    closeModal: () => void,
    onCancel: () => void,
    onConfirm: () => Promise<void>
}

let AgTechConfirmationModal = (props: AgTechConfirmationModalProps) => (
    <AgTechModal
        width={props.width ?? ''}
        content={() => <AgTechConfirmationModalContent {...props} />}
        closeModal={props.closeModal}
        showCloseButton={false}
        styles={{
            maxWidth: 420
        }}
    />
);

let AgTechConfirmationModalContent = (props: AgTechConfirmationModalProps) => {
    let confirmButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (confirmButtonRef.current) {
            confirmButtonRef.current.focus();
        }
    }, []);

    let handleCancel = (e: AgTechClickEventHandler) => {
        e.clickEvent.stopPropagation();
        props.onCancel();
        close();
    }

    let handleConfirm = (e: AgTechClickEventHandler) => {
        e.clickEvent.stopPropagation();
        props.onConfirm();
    }

    return (
        <div className='agtech-confirmation-modal-content'>
            <div className="tall row-based-vertically-centered flexed p-3 container">
                <AgTechTitleDetailHeader
                    title={props.content.header}
                    titleFontSize='font-size-above-standard'
                    detail={props.content.details}
                    detailClasses='mt-2'
                />
            </div>
            <div className="wide column-based-vertically-centered p-3 top-bordered container">
                <AgTechButton
                    classes="ml-auto mr-2"
                    text={props.content.cancelButtonText ?? "Cancel"}
                    icon="times"
                    action={handleCancel}
                />
                <AgTechButton
                    text={props.content.confirmationButtonText ?? "Continue"}
                    icon="check"
                    classes='highlight'
                    ref={confirmButtonRef}
                    action={handleConfirm}
                />
            </div>
        </div>
    )
}

export default AgTechConfirmationModal;