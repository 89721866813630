import { createContext, useContext } from "react";
import { DefaultAgTechConfirmations, IAgTechAppConfirmations } from "../functions/AgTechAppConfirmations";
import { DefaultAgTechAppDialog, IAgTechAppDialogs } from "../functions/AgTechAppDialogs";
import { DefaultAgTechAppLoading, IAgTechAppLoading } from "../functions/AgTechAppLoading";
import { DefaultAgTechAppMessaging, IAgTechAppMessaging } from "../functions/AgTechAppMessaging";
import { DefaultAgTechPopUps, IAgTechAppPopUps } from "agtech/core/functions/AgTechAppPopUps";

export interface IAgTechAppContext {
    messaging: IAgTechAppMessaging,
    loading: IAgTechAppLoading,
    dialogs: IAgTechAppDialogs,
    confirmations: IAgTechAppConfirmations,
    popups: IAgTechAppPopUps
};

export const DefaultAgTechAppContext: IAgTechAppContext = {
    messaging: DefaultAgTechAppMessaging,
    loading: DefaultAgTechAppLoading,
    dialogs: DefaultAgTechAppDialog,
    confirmations: DefaultAgTechConfirmations,
    popups: DefaultAgTechPopUps
}

export const AgTechAppContext = createContext<IAgTechAppContext>(DefaultAgTechAppContext);

export const useAppContext = () => useContext(AgTechAppContext);