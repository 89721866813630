import { AgTechForm, AgTechFormRow, AgTechFormTextControl } from 'agtech/web/components/Forms/AgTechForm';
import { DefaultDocumentHeaderConfiguration, useOperationData } from 'app/data/operation/SaleDataOperation';
import { useOperationDataUpdateDocumentConfigurationAction } from 'app/data/operation/SaleDataOperationActions';
import React from 'react'

const DocumentHeaderConfigurationForm = () => {
    var operationData = useOperationData();

    var configData = {
        ...DefaultDocumentHeaderConfiguration,
        ...operationData.documentHeaderConfiguration
    };
    
    let invoiceConfigurationSubmission = useOperationDataUpdateDocumentConfigurationAction();

    return (
        <AgTechForm
            structure={{
                header: {
                    title: 'Document header configuration'
                },
            }}
            initialFormData={configData}
            action={{
                dataAction: invoiceConfigurationSubmission,
                getActionData: d => d
            }}
            behavior={{
                realtimeSubmissionProps: {
                    isFormEntityTheSame: (prev, curr) => 
                        prev.preAddressLine === curr.preAddressLine &&
                        prev.postAddressLine === curr.postAddressLine &&
                        prev.addressCityOverride === curr.addressCityOverride &&
                        prev.addressStateOverride === curr.addressStateOverride &&
                        prev.addressZipOverride === curr.addressZipOverride
                }
            }}
        >
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Address"
                    focused={true}
                    value={entity => entity.addressOverride ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        addressOverride: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={configData}
                    caption="City"
                    value={entity => entity.addressCityOverride ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        addressCityOverride: val
                    })}
                />
                <AgTechFormTextControl
                    entityType={configData}
                    caption="State"
                    value={entity => entity.addressStateOverride ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        addressStateOverride: val
                    })}
                />
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Zip"
                    value={entity => entity.addressZipOverride ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        addressZipOverride: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Post-Address"
                    value={entity => entity.postAddressLine ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        postAddressLine: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={configData}
                    caption="Pre-Address"
                    value={entity => entity.preAddressLine ?? ''}
                    width={100}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        preAddressLine: val
                    })}
                />
            </AgTechFormRow>
        </AgTechForm>
    )
}

export default DocumentHeaderConfigurationForm