import AgTechVirtualizedList from 'agtech/web/components/Lists/AgTechVirtualizedList'
import React from 'react'

export type AgTechFastTab = {
    title: string,
    actionPane?: () => React.ReactNode,
    content: () => React.ReactNode,
    tabHeight?: number
}

export type AgTechFastTabListProps = {
    tabs: AgTechFastTab[]
}

const AgTechFastTabList = (props: AgTechFastTabListProps) => {
    return (
        <div className='stretched row-based non-scrollable container pl-1'>
            <div className='stretched row-based scrollable container'>
                {props.tabs.map((tab, index) => (
                    <div key={tab.title} className='row-based stretched pr-2 pb-1 container'>
                        <div className={`${index === 0 ? 'pb-2' : 'pb-2 pt-3'} px-1 pr-4 bottom-bordered dark-bordered font-bold wide column-based-vertically-centered container`}>
                            <h1 className='font-size-standard font-bold mt-half'>{tab.title}</h1>
                            {tab.actionPane ? (
                                <div className='tall ml-auto container'>
                                    {tab.actionPane()}
                                </div>
                            ) : null}
                        </div>
                        <div className='row-based flexed pt-2 container' style={{ minHeight: tab.tabHeight ?? undefined }}>
                            {tab.content()}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AgTechFastTabList