import { IAgTechAppLoading } from "agtech/core/functions/AgTechAppLoading";
import AgTechErrorMessage from "../../Messaging/AgTechErrorMessage";
import { AgTechLoader } from "../../Pages/Loading/AgTechLoadingSurface";
import AgTechFadeInView from "agtech/web/components/Pages/Loading/AgTechFadeInView";
import AgTechConfirmation from "agtech/web/components/Widgets/AgTechConfirmation";

/// Types

export type AgTechWebDataLoader = IAgTechAppLoading;

/// Manager

export const useAgTechWebDataLoader = (): AgTechWebDataLoader => {
    return {
        showLoader: loadingText => {
            return <AgTechLoader text={loadingText} />
        },
        showLoadingError: (loadingError, retryConfiguration) => {
            return (
                <AgTechFadeInView>
                    <AgTechErrorMessage errorMessage={loadingError} retryConfiguration={retryConfiguration} />
                </AgTechFadeInView>
            )
        },
        showSubmission: submissionText => {
            return (
                <div className="stretched absolute bg-white container">
                    <AgTechLoader text={submissionText} />
                </div>
            )
        },
        showSubmissionConfirmation: props => {
            return (
                <div className="stretched absolute bg-white container">
                    <AgTechConfirmation message={props.confirmationMessage} />
                </div>
            )
        },
    }
}