import { AgTechWebApplication } from "agtech/web/application/AgTechWebApplication";
import AgTechWebAppHeaderMemo from "agtech/web/components/App/Layout/AgTechWebAppHeader";
import AgTechStandardSidebarNavigation from "agtech/web/components/App/Navigation/AgTechStandardSidebarNavigation";
import { AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechActionPane from "agtech/web/components/Headers/AgTechActionPane";
import AgTechHeaderUserDetail from "agtech/web/components/Layout/AgTechHeaderUserDetail";
import AgTechHorizontalDivider from "agtech/web/components/MIsc/AgTechHorizontalDivider";
import AgTechFadeInView from "agtech/web/components/Pages/Loading/AgTechFadeInView";
import React from "react";
import { Route, Routes } from "react-router-dom";

declare type AgTechWebAppSurfaceProps = {
    app: AgTechWebApplication,
    path: string
}

const AgTechWebAppSurface = (props: AgTechWebAppSurfaceProps) => {
    const surface = (
        <>
            <div className="agtech-app-side-navigation">
                <AgTechWebAppNavigationMemo app={props.app} />
            </div>
            <div className="agtech-app-surface" id="agtech-app-surface">
                <div className="agtech-app-header">
                    <div className="agtech-app-header-content">
                        <AgTechWebAppHeaderMemo app={props.app} />
                    </div>
                </div>
                <div className="agtech-app-body-surface">
                    <div className="agtech-app-body">
                        <AgTechWebAppBodyMemo {...props} /> 
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <div className="agtech-web-app-surface">
            <div className="agtech-app" id="agtech-app">
                {props.app.setup ? props.app.setup(surface) : surface}
            </div>
        </div>
    )
};

const AgTechWebAppNavigation = (props: { app: AgTechWebApplication}) => {
    return props.app.navigation ? props.app.navigation(props.app) : (
        <AgTechStandardSidebarNavigation app={props.app} />
    );
};

const AgTechWebAppNavigationMemo = React.memo(AgTechWebAppNavigation);

declare type AgTechAppRoutesProps = {
    app: AgTechWebApplication,
}

const AgTechWebAppBody = (props: AgTechAppRoutesProps) => {
    return (
        <Routes>
            <Route path='/' element={(
                <AgTechFadeInView>
                    {props.app.structure.home()}
                </AgTechFadeInView>
            )} />
            {props.app.structure.areas.map(area => {
                return area.workspaces.map(workspace => {
                    return (
                        <Route
                            path={`${area.name}/${workspace.name}`}
                            element={(
                                <AgTechFadeInView>
                                    <workspace.workspace />
                                </AgTechFadeInView>
                            )}
                        />
                    )
                })
            })}
        </Routes>
    )
};

const AgTechWebAppBodyMemo = React.memo(AgTechWebAppBody);

export default React.memo(AgTechWebAppSurface, (prev, curr) => prev.path === curr.path);