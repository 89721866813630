import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import AgTechListDetailPage from 'agtech/web/components/Pages/List/AgTechListDetailPage'
import { Sale, useSales } from 'app/data/sales/SaleData'
import React from 'react'
import SaleDialog from '../dialogs/SaleDialog'
import dayjs from 'dayjs'

const SaleHistoryItemDetailPage = () => {
    let saleHistory = useSales();

    return (
        <div className='stretched non-scrollable container'>
            <AgTechListDetailPage
                items={saleHistory}
                itemSurface={s => <SaleHistorySaleDetailPage sale={s} />}
                itemConfig={{
                    height: 80,
                    width: 280,
                    content: e => (
                        <AgTechTitleDetailHeader
                            title={e.saleName}
                            detail={`${dayjs(e.saleStartDate).format('MM/DD/YYYY')} - ${dayjs(e.saleEndDate).format("MM/DD/YYYY")}`}
                            titleFontSize='font-size-medium'
                            detailFontSize='font-size-small'
                            detailClasses='mt-3'
                        />
                    ),
                }}
                searchConfig={{
                    placeholder: 'Search sales...',
                    options: [{ field: 'Name', value: e => e.saleName }]
                }}
                noRecordsConfig={{
                    message: "We're sorry, there are no sales in the system for this operation",
                    action: {
                        prompt: 'To create a sale, please click the button below',
                        button: () => <SaleDialog.Button />
                    }
                }}
            />
        </div>
    )
}

const SaleHistorySaleDetailPage = (props: { sale: Sale }) => {
    return (
        <h1>{props.sale.saleName}</h1>
    )
}

export default SaleHistoryItemDetailPage