import React, { FunctionComponent } from 'react'

export const AgTechVerticalPopUpMenu = (props: { children: React.ReactNode }) => {
    return (
        <div className="agtech-popup-menu">
            <div className="agtech-popup-menu-body">
                {props.children}
            </div>
        </div>
    );
}

export type AgTechVerticalPopUpMenuSectionProps = {
    header: string
}

export const AgTechVerticalPopUpMenuSection = (props: React.PropsWithChildren<AgTechVerticalPopUpMenuSectionProps>) => {
    return (
        <div className="agtech-popup-menu-section">
            <h1 className="agtech-popup-menu-section-title">{props.header}</h1>
            {props.children}
        </div>
    )
}