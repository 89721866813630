import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AgTechGridUserConfiguration, AgTechGridUserConfigurationIndex } from "agtech/web/components/Grids/Configuration/AgTechGridConfiguration"

export type AgTechWebConfiguration = {
    grids: AgTechGridUserConfigurationIndex
}

const DefaultWebConfiguration: AgTechWebConfiguration = {
    grids: {}
}

export const webConfigurationDataSlice = createSlice({
    name: 'Sales',
    initialState: DefaultWebConfiguration,
    reducers: {
        setGridView: (state, action: PayloadAction<{ gridName: string, viewName: string }>) => {            
            state.grids[action.payload.gridName] = {
                selectedView: action.payload.viewName
            };
        }
    }
});

export const useUserWebConfiguration = (): AgTechWebConfiguration => ({
    grids: {}
});

export const useUserGridConfigurations = () => useUserWebConfiguration().grids;

export const useUserGridConfiguration = (gridName: string): AgTechGridUserConfiguration | undefined => useUserGridConfigurations()[gridName];

export default webConfigurationDataSlice.reducer;