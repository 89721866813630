import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechFormSection } from 'agtech/web/components/Forms/AgTechForm'
import AgTechActionPane from 'agtech/web/components/Headers/AgTechActionPane'
import AgTechSection from 'agtech/web/components/Layout/AgTechSection'
import { AgTechPopUpPosition } from 'agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData'
import BidderRegistrationDialog from 'app/areas/bidders/dialogs/BidderRegistrationDialog'
import BidderSelectionViewDialog from 'app/areas/bidders/dialogs/BidderSelectionViewDialog'
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog'
import ContactRegisterBidderDialog from 'app/areas/contacts/dialogs/ContactRegisterBidderDialog'
import OperationContactViewDialog from 'app/areas/contacts/dialogs/OperationContactViewDialog'
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog'
import ContactImportModal from 'app/areas/contacts/modals/ContactImportModal'
import LotCategoryDialog from 'app/areas/lots/dialogs/LotCategoryDialog'
import LotDialog from 'app/areas/lots/dialogs/LotDialog'
import LotManagementDialog from 'app/areas/lots/dialogs/LotManagementDialog'
import LotSaleOrderUpdateDialog from 'app/areas/lots/dialogs/LotSaleOrderUpdateDialog'
import LotSellLotDialog from 'app/areas/lots/dialogs/LotSellLotDialog'
import LotSellingViewDialog from 'app/areas/lots/dialogs/LotSellingViewDialog'
import SaleLotImportModal from 'app/areas/lots/modals/SaleLotImportModal'
import SaleDialog from 'app/areas/sales/dialogs/SaleDialog'
import React from 'react'

export const SaleQuickActionsPopUp = () => {
    return (
        <div className='rounded px-3 pt-1 pb-4 stretched row-based container' style={{ width: 600 }}>
            <AgTechFormSection header='Lots' bodyClasses='wide py-2 container'>
                <AgTechActionPane
                    elements={[
                        <LotManagementDialog.Button text='Manage My Lots' classes='link' />,
                        <LotDialog.Button text='Create New Lot' classes='link' />,
                        <SaleLotImportModal.Button text='Import Lots' classes='link' />
                    ]}
                    customization={{
                        elementClasses: 'flexed centered',
                        width: 150,
                    }}
                />
                <AgTechActionPane
                    classes='mt-4'
                    elements={[
                        <LotSaleOrderUpdateDialog.Button classes='link' />,
                        <></>,
                        <></>
                    ]}
                    customization={{
                        elementClasses: 'flexed centered',
                        minElements: 3,
                        width: 150,
                    }}
                />
                {/* <AgTechActionPane
                    classes='mt-3'
                    elements={[
                        <LotSellingViewDialog.Button classes='link' />,
                        <LotCategoryDialog.Button text='New Lot Category' classes='link' />,
                    ]}
                /> */}
            </AgTechFormSection>
            <AgTechFormSection header='Bidders' bodyClasses='wide py-2 container'>
                <AgTechActionPane
                    elements={[
                        <BidderSelectionViewDialog.Button text='View Bidders' classes='link' />,
                        <BidderRegistrationDialog.Button text='Register Bidder' classes='link' />,
                    ]}
                    customization={{
                        elementClasses: 'flexed centered',
                        width: 150,
                        minElements: 3
                    }}
                />
            </AgTechFormSection>
            <AgTechFormSection header='Contacts' bodyClasses='wide py-2 container'>
                <AgTechActionPane
                    elements={[
                        <OperationContactViewDialog.Button text='View Contacts' classes='link' />,
                        <ContactDialog.Button classes='link' />,
                        <ContactImportModal.Button classes='link' />
                    ]}
                    customization={{
                        elementClasses: 'flexed centered',
                        width: 150,
                        minElements: 3
                    }}
                />
            </AgTechFormSection>
            {/* <AgTechFormSection header='Sales' bodyClasses='wide pb-2 container'>
                <AgTechActionPane
                    elements={[
                        <SaleDialog.Button classes='link' />,
                    ]}
                    customization={{
                        elementClasses: 'centered',
                        width: 150,
                        minElements: 3
                    }}
                />
            </AgTechFormSection> */}
        </div>
    )
}

export const SaleQuickActionsButton = (props: AgTechButtonProps) => {
    return (
        <AgTechButton
            text='Quick Actions'
            icon='bi bi-lightning-fill'
            {...props}
            action={e => e.popups.showPopUp({
                popup: () => <SaleQuickActionsPopUp />,
                callerRef: e.clickableRef,
                config: {
                    position: AgTechPopUpPosition.BelowRight,
                    shouldCloseOnBlur: false
                }
            })}
        />
    )
}