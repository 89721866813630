import LotCategoryGrid from 'app/areas/lots/components/grids/LotCategoryGrid'
import React from 'react'

const LotCategorySettingsPage = () => {
    return (
        <div className='stretched row-based container'>
            <LotCategoryGrid />
        </div>
    )
}

export default LotCategorySettingsPage