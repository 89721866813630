import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import AgTechLoadingSurface from 'agtech/web/components/Pages/Loading/AgTechLoadingSurface';
import SaleWorkspace from 'app/areas/sales/SaleWorkspace';
import SaleDialog from 'app/areas/sales/dialogs/SaleDialog';
import { useCurrentSale } from 'app/data/sales/SaleData';
import React from 'react'
import _ from 'lodash';

const SalesAppHomePage = () => {
    let currentSale = useCurrentSale();

    return (
        <AgTechLoadingSurface refreshWhenChanged={currentSale.id.toString()} text="Loading sale...">
            { currentSale && currentSale.id !== 0 ? (
                <SaleWorkspace sale={currentSale} /> 
            ) : (
                <AgTechNoRecordsPage
                    message="There are no sales in the system right now."
                    action={{
                        prompt: 'To create one, please click the button below',
                        button: () => <SaleDialog.Button /> 
                    }}
                />
            )}
        </AgTechLoadingSurface>
    )
}

export default SalesAppHomePage