import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechFormRow, AgTechFormTextControl, AgTechFormNumberControl, AgTechFormToggleControl } from 'agtech/web/components/Forms/AgTechForm';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import { SaleDiscount, DefaultSaleDiscount } from 'app/data/sales/discounts/SaleDiscountData';
import { useSaleDiscountDataWriteDiscountAction } from 'app/data/sales/discounts/SaleDiscountDataActions';
import React from 'react'

export type SaleDiscountDialogProps = {
    discount?: SaleDiscount
}

const SaleDiscountDialog = (props: SaleDiscountDialogProps) => {
    let discountEntity = props.discount ?? DefaultSaleDiscount;
    let discountWriteSubmission = useSaleDiscountDataWriteDiscountAction();

    return (
        <AgTechFormDialog
            structure={{
                header: props.discount ? 'Update Discount - ' + props.discount.name : 'Create Discount',
                submitButtonText: props.discount ? 'Update' : 'Create',
                width: 450
            }}
            formData={{
                loadInitialFormData: () => discountEntity
            }}
            formAction={{
                dataAction: discountWriteSubmission,
                getActionData: d => d
            }}
        >
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={discountEntity}
                    caption="Name"
                    required={true}
                    focused={true}
                    value={entity => entity.name}
                    width={99}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        name: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormNumberControl
                    entityType={discountEntity}
                    caption="Amount"
                    required={true}
                    value={entity => entity.amount}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        amount: val
                    })}
                    validation={{
                        validateOnSubmit: (ent, val) => {
                            let discountAmountValidationMessage = '';

                            if (ent.isPercentage && val > 100) {
                                discountAmountValidationMessage = "A percentage discount cannot exceed 100%";
                            }
    
                            return discountAmountValidationMessage;
                        }
                    }}
                />
                <AgTechFormToggleControl
                    entityType={discountEntity}
                    caption="Is Percentage?"
                    value={entity => entity.isPercentage ?? false}
                    onControlValueChanged={async (e, val) => ({
                        ...e,
                        isPercentage: val
                    })}
                />
            </AgTechFormRow>
        </AgTechFormDialog>
    )
}

SaleDiscountDialog.Button = (props: SaleDiscountDialogProps & AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        text: props.discount ? 'Update Discount' : 'Create Discount',
        icon: props.discount ? 'edit' : 'plus',
        ...props,
        action: e => e.dialogs.openDialog({
            dialog: () => <SaleDiscountDialog discount={props.discount} />
        })
    };

    return <AgTechButton {...buttonProps} />
}

export default SaleDiscountDialog