import React, { CSSProperties } from 'react'

export type AgTechButtonPaneProps = {
    classes?: string,
    styles?: CSSProperties
}

const AgTechButtonPane = (props: React.PropsWithChildren<AgTechButtonPaneProps>) => {
    return (
        <div className={`column-based horizontal-pane-2 container ${props.classes}`} style={props.styles}>
            {props.children}
        </div>
    )
}

export default AgTechButtonPane