import AgTechMetric from 'agtech/web/components/Metrics/AgTechMetric';
import AgTechGap from 'agtech/web/components/MIsc/AgTechGap';
import AgTechNoRecordsPage from 'agtech/web/components/Pages/Error/AgTechNoRecordsPage';
import AgTechLoadingSurface from 'agtech/web/components/Pages/Loading/AgTechLoadingSurface';
import BidderRegistrationGrid from 'app/areas/bidders/components/grids/BidderRegistrationGrid';
import BidderSelectionViewDialog from 'app/areas/bidders/dialogs/BidderSelectionViewDialog';
import OperationContactViewDialog from 'app/areas/contacts/dialogs/OperationContactViewDialog';
import { useContactData } from 'app/data/operation/contacts/ContactData';
import { useSaleBidderData } from 'app/data/bidders/BidderData';
import React from 'react'

const SaleBidderRegistrationPage = () => {
    let saleBidderData = useSaleBidderData();
    let operationContacts = useContactData();

    let saleBidders = Object.values(saleBidderData.bidderDetails);

    return (
        <AgTechLoadingSurface waitOn={true} text="Loading sale bidders...">
            {saleBidders.length > 0 ? (
                <div className='stretched column-based container'>
                    <div className='flexed area-left container'>
                        <BidderRegistrationGrid bidders={saleBidders} />
                    </div>
                    <div className='w-240 row-based tall area-right container'>
                        <AgTechMetric header='Registered Bidders' value={saleBidders.length.toString()} />
                        <AgTechGap />
                        <BidderSelectionViewDialog.Button classes='block highlight' />
                    </div>
                </div>
            )
            : operationContacts.contacts ? (
                <AgTechNoRecordsPage
                    message="There are currently no bidders registered for this sale."
                    action={{
                        prompt: 'To register bidders, please click the button below',
                        button: () => <BidderSelectionViewDialog.Button text='Register Bidders' classes='highlight' />
                    }}
                />
            )
            : (
                <AgTechNoRecordsPage
                    message="There are no contacts in the system. To register bidders, contacts must first be created. "
                    action={{
                        prompt: 'To create contacts, please click the button below',
                        button: () => <OperationContactViewDialog.Button text='Create Contacts' />
                    }}
                />
            )}
        </AgTechLoadingSurface>
    ) 
}

export default SaleBidderRegistrationPage;