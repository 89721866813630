import { configureStore } from '@reduxjs/toolkit'
import ContactData from './operation/contacts/ContactData'
import SalesData from './sales/SaleData';
import SalesOperationData from './operation/SaleDataOperation';
import SaleBidderData from './bidders/BidderData';
import SaleLotData from './lots/LotData';
import SaleLotCategoryData from './lots/categories/LotCategoryData';
import SaleDiscountData from './sales/discounts/SaleDiscountData';
import InvoicingData from './invoicing/InvoicingData';
import WebConfigurationData from 'agtech/web/configuration/AgTechWebConfiguration';

export const SalesAppDataStore = configureStore({
  reducer: {
    salesOperation: SalesOperationData,
    contacts: ContactData,
    sales: SalesData,
    bidders: SaleBidderData,
    lots: SaleLotData,
    lotCategories: SaleLotCategoryData,
    discounts: SaleDiscountData,
    web: WebConfigurationData,
    invoicing: InvoicingData
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false
  })
});

export const getSaleState = () => SalesAppDataStore.getState();
export const getCurrentSale = () => getSaleState().sales.currentSale;
export const getLot = (lotId: number) => getSaleState().lots.lots[lotId];
export const getLots = () => Object.values(getSaleState().lots.lots);

export const getBidderById = (id: number) => getSaleState().bidders.bidderIdsMappedToBidders[id];
export const getBidderByBidderNumber = (bidderNumber: string) => getBidders().filter(bd => bd.bidderNumber === bidderNumber)[0];
export const getBidders = () => Object.values(getSaleState().bidders.bidderIdsMappedToBidders);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type SalesAppState = ReturnType<typeof SalesAppDataStore.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type SalesAppDispatch = typeof SalesAppDataStore.dispatch