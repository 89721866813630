import { AgTechGridSelectionControl } from 'agtech/web/components/Grids/Controls/AgTechGridSelectionControl';
import { useLotCategorySelectionProps } from 'app/areas/lots/components/forms/LotCategoryDropdown';
import { SaleLotCategory } from 'app/data/lots/categories/LotCategoryData';
import { SaleLot } from 'app/data/lots/LotData';
import React from 'react'

export type LotCategoryGridSelectionControlProps = {
    lot: SaleLot,
    onLotCategorySelected: (selectedLotCategory?: SaleLotCategory) => Promise<any>
}

const LotCategoryGridSelectionControl = (props: LotCategoryGridSelectionControlProps) => {
    let lotCategorySelectionProps = useLotCategorySelectionProps({
        entityType: props.lot,
        getSelectedLotCategoryId: () => props.lot.lotCategoryId,
        onLotCategorySelected: async (e, sel) => await props.onLotCategorySelected(sel)
    });

    return (
        <AgTechGridSelectionControl
            rowEntity={lotCategorySelectionProps.entityType}
            {...lotCategorySelectionProps}
        />
    );
}

export default LotCategoryGridSelectionControl