import { AgTechBadge, AgTechBadgeColor, AgTechBadgeCustomizationProps } from 'agtech/web/components/Badges/AgTechBadges'
import { getLotStatus, isLotInvoiced, useSaleLot } from 'app/data/lots/LotData'
import React from 'react'

export type LotStatusBadgeProps = {
    lotId: number,
    classes?: string,
    customizations?: AgTechBadgeCustomizationProps
}

const LotStatusBadge = (props: LotStatusBadgeProps) => {
    let lot = useSaleLot(props.lotId);

    let badgeText = getLotStatus(lot);

    let badgeColor = isLotInvoiced(lot) ? AgTechBadgeColor.Cyan
        : lot.bidderNumber && lot.purchasePrice ? AgTechBadgeColor.Green
        : AgTechBadgeColor.Gray;

    return (
        <AgTechBadge
            color={badgeColor}
            text={badgeText}
            classes={props.classes}
            customizations={{ width: '100%', ...props.customizations }}
        />
    )
}

export default LotStatusBadge