import { useDataActionExecutor } from 'agtech/core/data/actions/AgTechDataActions';
import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import LotCategoryDialog from 'app/areas/lots/dialogs/LotCategoryDialog';
import { SaleLotCategory } from 'app/data/lots/categories/LotCategoryData';
import { useLotCategoriesDeleteCategoryAction } from 'app/data/lots/categories/LotCategoryDataActions';
import React from 'react'

const LotCategoryActionMenu = (props: { lotCategory: SaleLotCategory }) => {
    let lotRemovalAction = useLotCategoriesDeleteCategoryAction();

    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Lot">
                <LotCategoryDialog.Button lotCategoryId={props.lotCategory.id} />
                <AgTechButton
                    text="Delete Category"
                    icon="times"
                    action={async e => {
                        await e.actions.executeDataAction(lotRemovalAction, {
                            originalEntity: props.lotCategory,
                            submittedEntity: props.lotCategory
                        });
                    }}
                />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
}

export default LotCategoryActionMenu