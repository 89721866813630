import React, { CSSProperties, FunctionComponent } from 'react'

export type AgTechActionPaneProps = {
    classes?: string,
    styles?: CSSProperties,
    elements: React.ReactNode[],
    customization?: AgTechActionPaneCustomizationProps
}

export type AgTechActionPaneCustomizationProps = {
    showDividers?: boolean,
    padLastElement?: boolean,
    elementClasses?: string,
    minElements?: number,
    width?: number
}

const AgTechActionPane = (props: AgTechActionPaneProps) => {
    let shouldShowDividers = props.customization?.showDividers ?? true;
    let elements = [...props.elements];

    if (props.customization?.minElements && props.customization.minElements > elements.length) {
        for (let i = 0; i < props.customization.minElements - elements.length; i++) {
            elements.push(undefined);
        }
    }

    return (
        <div className={`column-based-vertically-centered container ${props.classes ?? ''}`} style={props.styles}>
            {elements.map((elem, index) => {
                let leftPadding = index === 0 ? '' : 'pl-4';
                let rightPadding = (shouldShowDividers && index !== props.elements.length - 1) || props.customization?.padLastElement ? 'pr-4' : '';
                let borderClass = shouldShowDividers && index !== props.elements.length - 1 ? 'right-bordered' : '';

                return (
                    <div
                        key={index}
                        className={`vertically-centered flexed ${leftPadding} ${rightPadding} ${borderClass} ${props.customization?.elementClasses ?? ''} container`}
                        style={props.customization?.width ? { minWidth: props.customization.width } : undefined}
                    >
                        {elem}
                    </div>
                )
            })}
        </div>
    )
};

export default AgTechActionPane;