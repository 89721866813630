import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import React from 'react'

export type AgTechTooltipProps = {
    title: string,
    details?: string,
    icon?: string
}

const AgTechTooltip = (props: AgTechTooltipProps) => {
    return (
        <AgTechTitleDetailHeader
            title={props.title}
            detail={props.details}
            icon={props.icon}
        />
    )
}

export default AgTechTooltip