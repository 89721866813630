import React, { CSSProperties, useRef } from 'react'
import AgTechVirtualizedList, { AgTechVirtualizedListGroupingProps } from 'agtech/web/components/Lists/AgTechVirtualizedList';

export type AgTechCoreListProps<TItem extends { id: string }> = {
    items: TItem[],
    itemRow: (item: TItem) => React.ReactNode,
    itemHeight: number,
    listClasses?: string,
    listStyles?: CSSProperties,
    grouping?: AgTechVirtualizedListGroupingProps<TItem>
}

export type AgTechListProps<TItem extends { id: string }> = AgTechCoreListProps<TItem> &  {
    header?: () => React.ReactNode,
    footer?: () => React.ReactNode
};

const AgTechList = <TItem extends { id: string }>(props: AgTechListProps<TItem>) => {   
    return (
        <div className={`agtech-list ${props.listClasses ?? ''}`} style={props.listStyles}>
            { props.header ? <div className="agtech-list-header">{props.header()}</div> : null }

            <div className="agtech-list-body">
                <AgTechVirtualizedList
                    items={props.items}
                    itemRow={props.itemRow}
                    itemHeight={props.itemHeight}
                    grouping={props.grouping}
                />
            </div>

            { props.footer ? props.footer() : null }
        </div>
    )
}

export default AgTechList;