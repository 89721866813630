import { useAgTechAuthContext } from "agtech/core/auth/AgTechAuthContext";
import { useEffect } from "react";
import { AgTechLoader } from "../Pages/Loading/AgTechLoadingSurface";
import { useNavigate } from "react-router-dom";

const AgTechWebAppLogin = () => {
    let authContext = useAgTechAuthContext();
    let navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            authContext.onSuccessfulUserLogin({
                navigateToRoot: () => {
                    navigate('/');
                }
            });
        }, 2000);
    }, []);

    return (
        <div className="agtech-web-app-loader">
            <div className="loader" />
            <h1 className="agtech-web-app-loader-text">Logging in...</h1>        
        </div>
    )
};

export default AgTechWebAppLogin;