import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSaleState, SalesAppState } from "app/data/SalesAppDataStore";
import { useSelector } from "react-redux";

// Discount Entities

export type SaleDiscount = {
    id: number;
    name: string;
    amount: number;
    isPercentage: boolean;
}

export const DefaultSaleDiscount: SaleDiscount = {
    id: 0,
    name: '',
    amount: 0,
    isPercentage: false
}

// Discount Slices

declare type SaleDiscountIndex = {
    [id: number]: SaleDiscount
}

export type SaleDiscountData = {
    discounts: SaleDiscountIndex
}

const DefaultSaleDiscountData: SaleDiscountData = {
    discounts: {}
}

export const saleDiscountSlice = createSlice({
    name: 'Discounts',
    initialState: DefaultSaleDiscountData,
    reducers: {
        refreshSaleDiscounts: (state, action: PayloadAction<SaleDiscount[]>) => {
            state.discounts = {};
            action.payload.forEach(disc => state.discounts[disc.id] = { ...disc });
        },
        writeSaleDiscount: (state, action: PayloadAction<SaleDiscount>) => {
            state.discounts[action.payload.id] = { ...action.payload };
        },
        deleteSaleDiscount: (state, action: PayloadAction<SaleDiscount>) => {
            delete state.discounts[action.payload.id];
        }
    }
});

/// Discount Hooks

export const useSaleDiscounts = (): SaleDiscount[] => {
    let saleDiscountData = useSelector((state: SalesAppState) => state.discounts).discounts;
    return Object.values(saleDiscountData);
}

export const getSaleDiscounts = () => Object.values(getSaleState().discounts.discounts);

export default saleDiscountSlice.reducer;