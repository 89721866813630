import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList, ListChildComponentProps, VariableSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import _ from 'lodash';
import { logDev, logEvent } from 'agtech/core/logging/AgTechLogger';

export type AgTechVirtualizedListProps<TItem> = {
    items: TItem[],
    itemHeight: number,
    itemRow: (item: TItem, itemIndex: number) => React.ReactNode,
    selectedItemIndex?: number,
    grouping?: AgTechVirtualizedListGroupingProps<TItem>,
    hasFooter?: boolean
}

export type AgTechVirtualizedListGroupingProps<TItem> = {
    group: (item: TItem) => string,
    groupText?: (itemGroupKey: string, itemsInGroup: TItem[]) => string,
    groupHeader?: (itemsInGroup: TItem[]) => React.ReactNode,
    groupHeaderHeight: number
}

declare type AgTechVirtualizedListItemIndex<TItem> = {
    [index: number]: TItem | AgTechVirtualizedListItemGroup<TItem>
}

declare type AgTechVirtualizedListItemGroup<TItem> = {
    groupKey: string,
    itemsInGroup: TItem[],
    groupText?: (itemGroupKey: string, itemsInGroup: TItem[]) => string,
    groupHeader?: (itemsInGroup: TItem[]) => React.ReactNode
}

declare type AgTechVirtualizedListItemData<TItem> = {
    itemIndex: AgTechVirtualizedListItemIndex<TItem>,
    itemRow: (item: TItem, itemIndex: number) => React.ReactNode,
    hasGrouping: boolean
}

const AgTechVirtualizedList = <TItem, >(props: AgTechVirtualizedListProps<TItem>) => {
    let listRef = useRef<any>(null);
    let listContainerRef = useRef<HTMLDivElement>(null);
    let listBodyRef = useRef<HTMLElement>(null);

    let itemData = useAgTechListItemData(props);

    useEffect(() => {
        if (listBodyRef.current) {
            listBodyRef.current.classList.add('bg-white');
        }

        handleResize();

    }, [listBodyRef.current]);

    useEffect(() => {
        if (listRef.current && (props.selectedItemIndex !== undefined)) {
            let updatedItemIndex = props.selectedItemIndex > 0 ? props.selectedItemIndex : 0;
            listRef.current.scrollToItem(updatedItemIndex);
        }
    }, [props.selectedItemIndex]);

    const handleResize = () => {
        if (!(props.hasFooter ?? false) && listContainerRef.current && listBodyRef.current) {
            if (listContainerRef.current.offsetHeight < listBodyRef.current.offsetHeight) {
                listContainerRef.current.classList.add('bottom-bordered');
            }
            else {
                listContainerRef.current.classList.remove('bottom-bordered');
            }
        }
    }

    let initialSelectedItemIndex = props.selectedItemIndex ?? 0;
    let initialScrollOffset = (initialSelectedItemIndex > 0 ? initialSelectedItemIndex - 1 : 0) * props.itemHeight;

    return (
        <div className='flexed wide container' ref={listContainerRef}>
            <AutoSizer onResize={handleResize}>
                {({ height, width }) => {
                    return props.grouping ? (
                        <VariableSizeList
                            ref={listRef}
                            height={height - 1}
                            itemData={itemData}
                            itemCount={Object.keys(itemData.itemIndex).length}
                            itemSize={index => itemData.itemHeights[index]}
                            initialScrollOffset={initialScrollOffset}
                            width={width}
                            innerRef={listBodyRef}
                        >
                            {AgTechInternalListRow}
                        </VariableSizeList>
                    ) : (
                        <FixedSizeList
                            ref={listRef}
                            height={height}
                            itemData={itemData}
                            itemCount={Object.keys(itemData.itemIndex).length}
                            itemSize={props.itemHeight}
                            initialScrollOffset={initialScrollOffset}
                            width={width}
                        >
                            {AgTechInternalListRow}
                        </FixedSizeList>
                    );
                }}
            </AutoSizer>
        </div>
    )
}

const useAgTechListItemData = <TItem, >(props: AgTechVirtualizedListProps<TItem>) => {
    let itemIndex: AgTechVirtualizedListItemIndex<TItem> = {};
    let itemHeights: number[] = [];

    if (props.grouping) {
        let itemGroups = _.groupBy(props.items, item => props.grouping?.group(item));
        let itemGroupKeys = _.orderBy(Object.keys(itemGroups), key => key);

        let itemNumericIndex = 0;

        itemGroupKeys.forEach(key => {
            let itemsInGroup = itemGroups[key];

            itemIndex[itemNumericIndex] = {
                groupKey: key,
                itemsInGroup: itemsInGroup,
                groupText: props.grouping?.groupText,
                groupHeader: props.grouping?.groupHeader
            }

            itemHeights.push(props.grouping?.groupHeaderHeight ?? 36);

            itemsInGroup.forEach(item => {
                itemNumericIndex++;
                itemIndex[itemNumericIndex] = item;

                itemHeights.push(props.itemHeight);
            });

            itemNumericIndex++;
        })
    }
    else {
        props.items.forEach((item, index) => itemIndex[index] = item);
    }

    return {
        itemIndex: itemIndex,
        itemHeights: itemHeights,
        itemRow: props.itemRow,
        hasGrouping: props.grouping !== undefined
    };
}

const AgTechInternalListRow = <TItem, >(props: ListChildComponentProps<AgTechVirtualizedListItemData<any>>) => {
    if (props.data.hasGrouping) {
        let listItemGroupKey = (props.data.itemIndex[props.index] as AgTechVirtualizedListItemGroup<TItem>).groupKey;

        if (listItemGroupKey !== undefined) {
            let gridRowGroupHeader = props.data.itemIndex[props.index] as AgTechVirtualizedListItemGroup<TItem>;

            return (
                <div className='bg-light-gray top-bordered-when-not-first bottom-bordered' style={props.style}>
                    {gridRowGroupHeader.groupHeader
                        ? gridRowGroupHeader.groupHeader(gridRowGroupHeader.itemsInGroup)
                        : (
                        <div className="tall column-based-vertically-centered px-3 font-size-smaller font-bold container">
                            {gridRowGroupHeader.groupText
                                ? gridRowGroupHeader.groupText(gridRowGroupHeader.groupKey, gridRowGroupHeader.itemsInGroup)
                                : gridRowGroupHeader.groupKey.toString()}
                        </div>
                    )}
                </div>
            )
        }
    }

    let listRowItem = props.data.itemIndex[props.index] as TItem;
    let nextItemGroupKey = (props.data.itemIndex[props.index + 1] as AgTechVirtualizedListItemGroup<TItem>)?.groupKey;

    let itemRowClasses = nextItemGroupKey ? '' : 'bottom-bordered-when-not-last';

    return (
        <div className={'table-bordered ' + itemRowClasses} style={props.style}>
            {props.data.itemRow(listRowItem, props.index)}
        </div>  
    )
}

export default AgTechVirtualizedList