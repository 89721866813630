import { FormContext } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormControlProps, AgTechFormControlMetadata } from 'agtech/core/forms/AgTechFormControls';
import React, { Ref, useContext, useEffect, useRef, useState } from 'react'

export type AgTechFormTextControlProps<TFormEntity> = AgTechFormControlProps<TFormEntity, string> & {
    onFocus?: (inputRef: React.RefObject<HTMLInputElement>, formEntity: TFormEntity) => void
}

export const AgTechFormTextControl = <TFormEntity, >(props: AgTechFormTextControlProps<TFormEntity>) => {
    let formContext = useContext(FormContext);
    let inputRef = useRef<HTMLInputElement>(null);

    let [textValue, updateTextValue] = useState<string>(props.value(formContext.entity));

    let [field, updateField] = useState<AgTechFormControlMetadata<TFormEntity>>({
        id: props.caption,
        isValid: true,
        validateOnSubmit: (entity: TFormEntity) => validateControlOnFormSubmit(entity),
    });

    let markFieldValid = () => updateField({...field, isValid: true });
    let markFieldInvalid = () => updateField({...field, isValid: false });

    let handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateTextValue(e.currentTarget.value);
    }

    let validateControlOnFormSubmit = (entity: TFormEntity): string => {

        let fieldValidationWarningMessage = '';
        let controlValue = props.value(entity);

        if (props.required && controlValue === '') {
            fieldValidationWarningMessage = 'Field "' + props.caption + '" is required';
        }
        else if (props.validation?.validateOnSubmit) {
            fieldValidationWarningMessage = props.validation.validateOnSubmit(formContext.entity, controlValue) ?? '';
        }

        if (fieldValidationWarningMessage) {
            markFieldInvalid();
        }

        return fieldValidationWarningMessage;
    }

    let formControlWidth: string = `${props.width ?? 50}%`;
    let formControlStatus: string = field.isValid ? '' : 'invalid';
    let formControlHighlightClass = props.highlighted ? 'highlighted' : '';

    useEffect(() => {
        formContext.registerFormField(field);

        if (props.focused) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, []);

    const handleFocusIn = (e: React.FocusEvent<HTMLInputElement>) => {
        if (props.onFocus) {
            props.onFocus(inputRef, formContext.entity);
        }
    }

    let handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {

        if (props.onControlValueChanged) {
            let updatedValue = e.currentTarget.value;

            formContext.handleFieldChanged(updatedValue, props.onControlValueChanged);

            if (props.validation?.validateOnFocusOut && !props.validation.validateOnFocusOut(updatedValue)) {
                markFieldInvalid();
            }
            else if (props.required && updatedValue === '') {
                if (field.isValid) {
                    markFieldInvalid();
                }
            }
            else if (!field.isValid) {
                markFieldValid();
            }
        }
    }

    return (
        <div className={`agtech-form-control ${formControlStatus} ${formControlHighlightClass}`} style={{maxWidth: formControlWidth}}>
            <h1 className="agtech-form-control-caption">{props.caption + (props.required ? ' *' : '')}</h1>
            <input
                type="text"
                value={textValue}
                onChange={handleTextChanged}
                readOnly={props.readonly}
                ref={inputRef}
                placeholder={props.placeHolder ?? ''}
                onFocus={handleFocusIn}
                onBlur={handleFocusOut}
            />
        </div>
    );
};