import { AgTechFormContext } from "agtech/core/forms/AgTechFormContext";
import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechGridHeaderConfiguration } from "agtech/web/components/Grids/AgTechGridHeader";
import { AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { SaleDiscount } from "app/data/sales/discounts/SaleDiscountData";
import { SelectableDiscountsData } from "../../dialogs/InvoiceChangeDiscountsDialog";
import { AgTechMoneyFormatter } from "agtech/web/utilities/AgTechUtilities";
import { AgTechEntitySelectionPreservedOriginal } from "agtech/web/components/Grids/AgTechGridEntities";

export type InvoiceChangeDiscountsGridProps = {
    formContext: AgTechFormContext<SelectableDiscountsData>,
    header?: AgTechGridHeaderConfiguration
}

const InvoiceChangeDiscountsGrid = (props: InvoiceChangeDiscountsGridProps) => {
    let invoiceDiscountSelectionGridConfiguration: AgTechGridProps<AgTechEntitySelectionPreservedOriginal<SaleDiscount>> = {
        header: props.header,
        items: props.formContext.entity.Discounts,
        columns: [
            {
                header: 'Name',
                value: d => d.entity.name,
                size: 3
            },
            {
                header: 'Discount',
                value: d => d.entity.isPercentage ?  `${d.entity.amount}%` : AgTechMoneyFormatter.format(d.entity.amount),
                size: 1
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.entity.name === curr.entity.name &&
                   prev.entity.amount === curr.entity.amount;
        },
        selectionConfig: {
            isItemSelected: discount => discount.isSelected,
            onItemSelected: async (discount) => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    Discounts: [...curr.Discounts.map(formDiscount => formDiscount.id === discount.entity.id
                        ? { ...formDiscount, isSelected: true }
                        : { ...formDiscount }
                    )]
                }));
            },
            onItemDeSelected: async discount => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    Discounts: [...curr.Discounts.map(formDiscount => formDiscount.id === discount.entity.id
                        ? { ...formDiscount, isSelected: false }
                        : { ...formDiscount }
                    )]
                }));
            }
        }
    };

    return <AgTechGrid {...invoiceDiscountSelectionGridConfiguration} />
}

export default InvoiceChangeDiscountsGrid;