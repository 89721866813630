import { useAgTechWebAppContext } from "agtech/web/components/App/AgTechWebAppContext";
import { AgTechPopUpPosition, AgTechRenderedPopUp } from "agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData";
import { useRef, useEffect } from "react";

export type AgTechTooltipContainerProps = {
    message: string,
    classes?: string,
}

export type AgTechTooltipProps<THtmlElement extends HTMLElement> = {
    containerRef: React.RefObject<THtmlElement | undefined>,
    tooltip?: string
}

export const useAgTechTooltip = <THtmlElement extends HTMLElement>(props: AgTechTooltipProps<THtmlElement>) => {
    let webAppContext = useAgTechWebAppContext();
    let renderedPopUpRef = useRef<AgTechRenderedPopUp | null>(null);

    useEffect(() => {
        if (props.containerRef.current && props.tooltip) {
            props.containerRef.current.onmouseenter = e => {
                renderedPopUpRef.current = webAppContext.popups.showPopUp({
                    callerRef: props.containerRef,
                    popup: () => (
                        <div className='column-based centered px-4 py-2 bg-secondary font-color-white font-no-wrap rounded-inherit container'>
                            <h1 className='font-bold font-size-small'>{props.tooltip}</h1>
                        </div>
                    ),
                    config: {
                        position: AgTechPopUpPosition.BelowCentered
                    }
                });
            }
        
            props.containerRef.current.onmouseleave = e => {
                if (renderedPopUpRef.current) {
                    renderedPopUpRef.current.close();
                }
            }
        }
    }, []);
}

const AgTechTooltipContainer = (props: React.PropsWithChildren<AgTechTooltipContainerProps>) => {    
    let containerRef = useRef<HTMLDivElement>(null);
    
    useAgTechTooltip<HTMLDivElement>({
        containerRef,
        tooltip: props.message
    });

    return (
        <div className={`container ${props.classes ?? ''}`} ref={containerRef}>
            {props.children}
        </div>
    )
}

export default AgTechTooltipContainer;