import { useEffect } from "react";
import { AgTechCoordinates, AgTechPopUpPosition, AgTechPopUpContext, AgTechPopUpRendering, DefaultAgTechCoordinate } from "./AgTechPopUpData";

export const usePopUpClickHandler = (
    ref: React.RefObject<HTMLDivElement>,
    popupContext: AgTechPopUpContext,
    props: AgTechPopUpRendering) =>
{
    useEffect(() => {
        function handleClickOutside(event: any) {
            let didClickOccurOutsidePopUpRef = ref.current && !ref.current.contains(event.target);
    
            // First check to see if the click event occurred outside of this current popup
            if (didClickOccurOutsidePopUpRef) {

                // First check to see if the click occurred in the popup's caller. If so, the popup should not
                // be closed and no action should be performed
                if (props.callerRef.current && props.callerRef.current.contains(event.target)) {
                    return;
                }

                // Verify that the click event did not occur beneath an open portal. Dialogs are rendered in portals, and if
                // this check does not occur a click within a dialog produced by a popup will cause both the dialog and popup to close.
                let openPortals = document.querySelectorAll('.agtech-portal-backdrop, .agtech-popup');

                if (openPortals.length > 0 && ref.current) {
                    let previousPortal = ref.current.previousSibling as any;

                    if (previousPortal) {
                        if (previousPortal.contains(event.target)) {
                            props.closePopup(props.popupId);
                        }
                        else if (previousPortal.id === 'agtech-app-surface' && openPortals.length === 1) {
                            props.closePopup(props.popupId);
                        }
                    }
                }
                else {
                    props.closePopup(props.popupId);
                }
            }
            else {
                // The click occurred within the popup, so check to see if the popup has any children and, if so, close those child popups
                let doesPopUpHaveChildPopUp =
                    ref.current &&
                    popupContext.childPopUpContext;
        
                if (doesPopUpHaveChildPopUp && popupContext.childPopUpContext) {
                    popupContext.childPopUpContext.closePopUp();
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [ref, popupContext]);
}

const popupVerticalPadding = 10;
const popupHorizontalPadding = 10;

export type AgTechPopUpPositionCalculationProps = {
    popupProps: AgTechPopUpRendering,
    popupRef: React.MutableRefObject<any>,
    popupSurface: HTMLElement
}

export const calculatePopUpPosition = (props: AgTechPopUpPositionCalculationProps): AgTechCoordinates => {
    let popupLocation: AgTechCoordinates = DefaultAgTechCoordinate;

    let popupCaller = props.popupProps.callerRef.current;

    if (popupCaller) {
        let popupPosition = props.popupProps.popupConfig?.position ?? AgTechPopUpPosition.Beside;

        if (popupPosition === AgTechPopUpPosition.Beside) {
            popupLocation = calculatePositionForBesidePopUp(props);
        }
        else if (popupPosition === AgTechPopUpPosition.BelowCentered) {
            popupLocation = calculatePositionForBottomPopup(props);
        }
        else if (popupPosition === AgTechPopUpPosition.BelowRight) {
            popupLocation = calculatePositionForBottomRightPopup(props);
        }
    }

    return popupLocation;
}

const calculatePositionForBesidePopUp = (props: AgTechPopUpPositionCalculationProps): AgTechCoordinates => {
    let popupCaller = props.popupProps.callerRef.current;
    let popupCallerRect = popupCaller.getBoundingClientRect();

    let callerLeft = popupCallerRect.left;
    let callerRight = popupCallerRect.right;
    let callerTop = popupCallerRect.top;

    // By default, position the popup so the top left corner of the pop up is next
    // to the top right corner of the caller
    let popupLocation = {
        x: callerRight,
        y: callerTop
    };

    if (props.popupRef.current) {
        let popupHeight = props.popupRef.current.scrollHeight;
        let popupWidth = props.popupRef.current.offsetWidth;

        let callerHeight = popupCallerRect.height;

        // If the popup is taller than the caller, apply a negative vertical padding
        // on the popup for styling reasons
        if ((popupHeight - callerHeight) > popupVerticalPadding) {
            popupLocation.y -= (popupVerticalPadding + (props.popupProps.popupConfig?.topOffset ?? 0));
        }

        // If the right side of the popup will go off the screen, flip the popup to the left of the caller,
        // this means that the top right corner of the popup will touch the top left corner of the caller
        let popupRightEdge = popupLocation.x + popupWidth;

        if (popupRightEdge > props.popupSurface.offsetWidth) {
            popupLocation.x = callerLeft - popupWidth - popupHorizontalPadding;
        }

        // If the top of the popup is touching the top of the screen, adjust the vertical position
        // of the popup downward so there is a padding between the popup and edge of the screen 
        if (popupLocation.y <= 0) {
            popupLocation.y = popupVerticalPadding;
        }

        // If the bottom of the popup will go off the screen, adjust the vertical position
        // of the popup upward so it is fully visible 
        let popupBottomEdge = popupLocation.y + popupHeight;

        if (popupBottomEdge > props.popupSurface.offsetHeight) {
            let amountOfPopUpBelowScreen = popupBottomEdge - props.popupSurface.offsetHeight;

            popupLocation.y -= amountOfPopUpBelowScreen + popupVerticalPadding;
        }
    }

    return popupLocation;
}

const calculatePositionForBottomPopup = (props: AgTechPopUpPositionCalculationProps): AgTechCoordinates => {
    let popupCaller = props.popupProps.callerRef.current;
    let popupCallerRect = popupCaller.getBoundingClientRect();

    let callerLeft = popupCallerRect.left;
    let callerTop = popupCallerRect.top;
    let callerBottom = popupCallerRect.bottom;

    let callerWidth = popupCallerRect.width;

    let popupHeight = props.popupRef.current.offsetHeight;
    let popupWidth = props.popupRef.current.offsetWidth;

    let popupCallerWidthDifference = popupWidth - callerWidth;

    // By default, position the popup so the top left corner of the pop up is next
    // to the top right corner of the caller
    let popupLocation = {
        x: callerLeft - (popupCallerWidthDifference / 2),
        y: callerBottom + popupVerticalPadding
    };

    // If the bottom of the popup will go off the screen, flip the popup to open
    // above the caller rather than below it
    let popupBottomEdge = popupLocation.y + popupHeight;

    if (popupBottomEdge > props.popupSurface.offsetHeight) {
        popupLocation.y = callerTop - popupVerticalPadding - popupHeight;
    }

    // If the right side of the popup will go off the screen, move the popup to the left
    // so that the entire popup is visible
    let popupRightEdge = popupLocation.x + popupWidth;
    let distancePopupIsOffScreenToTheRight = popupRightEdge - (props.popupSurface.offsetWidth + popupHorizontalPadding);

    if (distancePopupIsOffScreenToTheRight > 0) {
        popupLocation.x -= (distancePopupIsOffScreenToTheRight + popupHorizontalPadding);
    }

    // If the left side of the popup will go off the screen, move the popup to the right
    // so that the entire popup is visible
    let popupLeftEdge = popupLocation.x;

    if (popupLeftEdge <= 0) {
        popupLocation.x += (popupLeftEdge + popupHorizontalPadding);
    }

    return popupLocation;
}

const calculatePositionForBottomRightPopup = (props: AgTechPopUpPositionCalculationProps): AgTechCoordinates => {
    let popupCaller = props.popupProps.callerRef.current;
    let popupCallerRect = popupCaller.getBoundingClientRect();

    let callerLeft = popupCallerRect.left;
    let callerTop = popupCallerRect.top;
    let callerBottom = popupCallerRect.bottom;
    let callerRight = popupCallerRect.right;

    let callerWidth = popupCallerRect.width;

    let popupHeight = props.popupRef.current.offsetHeight;
    let popupWidth = props.popupRef.current.offsetWidth;

    // By default, position the popup so the top left corner of the pop up is next
    // to the top right corner of the caller
    let popupLocation = {
        x: (callerRight + popupHorizontalPadding) - popupWidth,
        y: callerBottom + popupVerticalPadding
    };

    // If the bottom of the popup will go off the screen, flip the popup to open
    // above the caller rather than below it
    let popupBottomEdge = popupLocation.y + popupHeight;

    if (popupBottomEdge > props.popupSurface.offsetHeight) {
        popupLocation.y = callerTop - popupVerticalPadding - popupHeight;
    }

    // If the right side of the popup will go off the screen, move the popup to the left
    // so that the entire popup is visible
    let popupRightEdge = popupLocation.x + popupWidth;
    let distancePopupIsOffScreenToTheRight = popupRightEdge - (props.popupSurface.offsetWidth + popupHorizontalPadding);

    if (distancePopupIsOffScreenToTheRight > 0) {
        popupLocation.x -= (distancePopupIsOffScreenToTheRight + popupHorizontalPadding);
    }

    // If the left side of the popup will go off the screen, move the popup to the right
    // so that the entire popup is visible
    let popupLeftEdge = popupLocation.x;

    if (popupLeftEdge <= 0) {
        popupLocation.x = popupHorizontalPadding;
    }

    return popupLocation;
}