import React, { useEffect, useRef, useState } from 'react'
import { AgTechAuthContext, AuthContext } from 'agtech/core/auth/AgTechAuthContext';
import { AgTechWebAppAuthConfiguration } from 'agtech/web/application/AgTechWebAppAuthConfiguration';
import AgTechWebAppAuthenticationService from 'agtech/web/components/Auth/AgTechWebAppAuthenticationService';
import Oidc, { User } from 'oidc-client';

declare type AuthContextState = {
    user: User | null,
    hasLoaded: boolean
}

const DefaultUser = {
    name: 'Mock User',
    username: 'mockusername',
    email: 'mockuser@gmail.com',
    phoneNumber: '111-123-2932',
    token: '',
    role: ['user']
};

const AgTechWebAppAuthContext = (props: React.PropsWithChildren<{ auth: AgTechWebAppAuthConfiguration }>) => {
    let authService = useRef(new AgTechWebAppAuthenticationService(props.auth));

    let [authState, updateAuthState] = useState<AuthContextState>({
        user: null,
        hasLoaded: false
    });

    let doesAppRequireAuthentication = props.auth.usesAuth ?? true;

    useEffect(() => {
        if (doesAppRequireAuthentication) {
            authService.current.getUser()
                .then(user => {                   
                    updateAuthState({
                        user: user,
                        hasLoaded: true
                    });
                });
        }
        else {
            updateAuthState({
                user: null,
                hasLoaded: true
            });
        }
    }, []);

    let authContext: AgTechAuthContext = {
        getLoggedInUser: async () => {
            if (doesAppRequireAuthentication) {
                let loggedInUser = await authService.current.getUser();
                let role = [];
                if (loggedInUser?.profile?.role) {
                    // If there are multiple roles specified it will be an array from the server, otherwise a single value
                    if (typeof loggedInUser?.profile?.role === 'string') {
                        role = [loggedInUser?.profile?.role];
                    }
                    else {
                        role = loggedInUser?.profile?.role;
                    }
                }

                return {
                    name: loggedInUser?.profile?.name ?? '',
                    username: loggedInUser?.profile?.preferred_username ?? '',
                    token: loggedInUser?.access_token ?? '',
                    role: role
                }
            }
            else {
                return DefaultUser;
            }
        },
        isUserAuthenticated: async () => {
            let isUserAuthenticated = true;

            if (doesAppRequireAuthentication) {
                isUserAuthenticated = await authService.current.isAuthenticated();
            }

            return isUserAuthenticated;
        },
        loginUser: () => authService.current.login(),
        onSuccessfulUserLogin: async props => {
            await authService.current.signinRedirectCallback(props)
                .then(loggedInUser => {
                    updateAuthState({
                        user: loggedInUser,
                        hasLoaded: true
                    });

                    props.navigateToRoot();
                })
                .catch(error => {
                    console.error(error);
                    return null;
                });
        },
        logoutUser: () => authService.current.signoutRedirectCallback(),
        user: doesAppRequireAuthentication ? {
            username: authState.user?.profile?.preferred_username ?? '',
            name: authState.user?.profile?.name ?? '',
            token: authState.user?.access_token ?? '',
            role: typeof authState.user?.profile?.role === 'string' ? [authState.user?.profile?.role] : authState.user?.profile?.role
        } : DefaultUser
    };

    return authState.hasLoaded ? (
        <AuthContext.Provider value={authContext}>
            {props.children}
        </AuthContext.Provider>
    ) : null
};

export default React.memo(AgTechWebAppAuthContext);