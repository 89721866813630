import { isDev } from 'agtech/core/utilities/AgTechUtilities';
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechFormRow, AgTechFormTextControl, AgTechFormSection } from 'agtech/web/components/Forms/AgTechForm';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import { AgTechContact, DefaultAgTechContact, useContact, useContactData } from 'app/data/operation/contacts/ContactData';
import { useContactDataWriteContactAction } from 'app/data/operation/contacts/ContactDataActions';
import React from 'react'

export type ContactDialogProps = {
    contactId?: number,
    onContactSubmitted?: (submission: AgTechContact) => Promise<void>
}

const ContactDialog = (props: ContactDialogProps) => {
    let contactData = useContactData();
    let dialogContact = props.contactId ? contactData.contacts[props.contactId] : DefaultAgTechContact;
    let contactSubmission = useContactDataWriteContactAction();

    if (isDev() && !props.contactId) {
        let numberOfContacts = Object.values(contactData.contacts).length;

        dialogContact = {
            ...DefaultAgTechContact,
            firstName: 'Test',
            lastName: 'Contact' + (numberOfContacts + 1),
            ranchName: 'Test Farms',
            emailAddress: 'TestContact' + (numberOfContacts + 1) + '@gmail.com',
            address: 'Test Address',
            city: 'Fargo',
            stateAbbreviation: 'ND',
            zip: '58104'
        }
    }
    
    let dialogHeader = props.contactId
        ? `Update Contact - ${dialogContact.firstName} ${dialogContact.lastName}`
        : 'Create Contact';

    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: () => dialogContact
            }}
            formAction={{
                dataAction: contactSubmission,
                getActionData: d => d
            }}
            structure={{
                header: dialogHeader,
                width: 500
            }}
            behavior={{
                eventHandlers: {
                    onDialogSubmitted: props.onContactSubmitted
                }
            }}
        >
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={dialogContact}
                    caption="First Name"
                    required={true}
                    focused={true}
                    value={entity => entity.firstName}
                    onControlValueChanged={async (ent, val) => ({
                        ...ent,
                        firstName: val
                    })}
                />
                <AgTechFormTextControl
                    entityType={dialogContact}
                    caption="Last Name"
                    required={true}
                    value={entity => entity.lastName}
                    onControlValueChanged={async (ent, val) => ({
                        ...ent,
                        lastName: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={dialogContact}
                    caption="Ranch Name"
                    required={false}
                    value={entity => entity.ranchName}
                    onControlValueChanged={async (ent, val) => ({
                        ...ent,
                        ranchName: val
                    })}
                />
            </AgTechFormRow>
            <AgTechFormSection header="Contact Information">
                <AgTechFormRow>
                    <AgTechFormTextControl
                        entityType={dialogContact}
                        caption="Email Address"
                        required={false}
                        value={entity => entity.emailAddress}
                        onControlValueChanged={async (ent, val) => ({
                            ...ent,
                            emailAddress: val
                        })}
                    />
                    <AgTechFormTextControl
                        entityType={dialogContact}
                        caption="Phone Number"
                        value={entity => entity.phoneNumber}
                        onControlValueChanged={async (ent, val) => ({
                            ...ent,
                            phoneNumber: val
                        })}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
            <AgTechFormSection header="Address Information">
                <AgTechFormRow>
                    <AgTechFormTextControl
                        entityType={dialogContact}
                        caption="Address"
                        required={true}
                        value={entity => entity.address}
                        onControlValueChanged={async (ent, val) => ({ ...ent, address: val })}
                        width={100}
                    />
                </AgTechFormRow>
                <AgTechFormRow>
                    <AgTechFormTextControl
                        entityType={dialogContact}
                        caption="City"
                        required={true}
                        value={entity => entity.city}
                        onControlValueChanged={async (ent, val) => ({ ...ent, city: val })}
                    />
                    <AgTechFormTextControl
                        entityType={dialogContact}
                        caption="State"
                        required={true}
                        value={entity => entity.stateAbbreviation}
                        onControlValueChanged={async (ent, val) => ({ ...ent, stateAbbreviation: val })}
                    />
                    <AgTechFormTextControl
                        entityType={dialogContact}
                        caption="Zip"
                        required={true}
                        value={entity => entity.zip}
                        onControlValueChanged={async (ent, val) => ({ ...ent, zip: val })}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
        </AgTechFormDialog>
    )
};

ContactDialog.Open = (e: AgTechClickEventHandler, props: ContactDialogProps) => e.dialogs.openDialog({
    dialog: () => <ContactDialog {...props} />
});

ContactDialog.Button = (props: ContactDialogProps & AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        text: props.contactId ? 'Update Contact' : 'Create Contact',
        icon: props.contactId ? 'edit' : 'plus',
        ...props,
        action: e => ContactDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default ContactDialog;