import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechGridNumberControl } from "agtech/web/components/Grids/Controls/AgTechGridNumberControl";
import { AgTechGridTextControl } from "agtech/web/components/Grids/Controls/AgTechGridTextControl";
import { AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { useSaleLots } from "app/data/lots/LotData";
import { useState } from "react";

export type SaleLotOrderModificationGridEntity = {
    id: number,
    saleOrder: number,
    originalOrder: number,
    lotNumber: string,
    lotDescription: string,
    changed: boolean
};

export type SaleLotOrderModificationGridProps = {
    entities: SaleLotOrderModificationGridEntity[];
    updateEntities: (ent: SaleLotOrderModificationGridEntity[]) => void;
}

const SaleLotOrderModificationGrid = (props: SaleLotOrderModificationGridProps) => {

    let saleLotGridConfiguration: AgTechGridProps<SaleLotOrderModificationGridEntity> = {
        items: props.entities,
        columns: [
            {
                header: 'Sale Order',
                value: (l, update) => (
                    <AgTechGridNumberControl
                        value={l.saleOrder}
                        onUpdated={val => {
                            let fidx = props.entities.findIndex(e => e.saleOrder === val);
                            
                            let nextOrders = props.entities.map((c, i) => {
                                if (i === fidx) {
                                    c.saleOrder = 0;
                                    c.changed = true;
                                }

                                if (c.originalOrder === l.originalOrder) {
                                    c.saleOrder = val;
                                    c.changed = val !== c.originalOrder;
                                }

                                return c;
                            });
                            
                            props.updateEntities(nextOrders);
                        }}
                    />
                ),
                width: '120px',
                itemBasedClasses: item => item.saleOrder === 0 ? 'invalid' : ''
            },
            {
                header: 'Previous Order',
                value: l => l.originalOrder,
                width: '120px',
            },
            {
                header: 'Lot Number',
                value: l => l.lotNumber,
                width: '120px',
                classes: 'centered emphasized'
            },
            {
                header: 'Lot Description',
                value: l => l.lotDescription,
                classes: 'centered emphasized'
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.id === curr.id && prev.lotNumber === curr.lotNumber && prev.saleOrder === curr.saleOrder;
        }
    };

    return <AgTechGrid {...saleLotGridConfiguration} />
}

export default SaleLotOrderModificationGrid;