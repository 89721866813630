import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import React from 'react'

export type AgTechStepInstructionProps = {
    stepNumber: number,
    stepInstruction: string,
    stepDetails?: string,
    children?: React.ReactElement
}

const AgTechStepInstruction = (props: AgTechStepInstructionProps) => {
    return (
        <div className='row-based container'>
            <div className='column-based container'>
                <h1 className='bordered centered font-size-large font-bold mr-4 bg-highlight-light container' style={{
                    height: 36,
                    width: 36,
                    borderRadius: 36
                }}>
                    {props.stepNumber}
                </h1>
                <AgTechTitleDetailHeader
                    title={props.stepInstruction}
                    detail={props.stepDetails}
                    titleClasses='font-size-medium'
                />
            </div>
            {props.children}
        </div>
    )
}

export default AgTechStepInstruction