import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import { DialogContext } from 'agtech/web/components/Portals/Dialogs/AgTechDialogSlot';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import ContactSelectionGrid from 'app/areas/contacts/components/grids/ContactSelectionGrid';
import ContactRegisterBidderDialog from 'app/areas/contacts/dialogs/ContactRegisterBidderDialog';
import { DefaultAgTechContact } from 'app/data/operation/contacts/ContactData';
import { useSaleBidderContacts, SaleBidderContact } from 'app/data/bidders/BidderData';
import { BidderContactRegistration, useBidderDataResolveBidderAction } from 'app/data/bidders/BidderDataActions';
import React, { useContext } from 'react'

export type BidderReconciliationDialogProps = {
    bidderNumber: string,
    onBidderReconciled: (reconciledBidder: SaleBidderContact) => Promise<void>
}

const BidderReconciliationDialog = (props: BidderReconciliationDialogProps) => {
    let contacts = Object.values(useSaleBidderContacts());
    let dialogContext = useContext(DialogContext);

    let resolveBidderSubmission = useBidderDataResolveBidderAction();

    let contactsNotRegistered = contacts.filter(cnt => !cnt.bidderId);

    let handleContactCreated = async (registeredContact: BidderContactRegistration) => {
        dialogContext.closeDialog(null);
        await props.onBidderReconciled(registeredContact);
    };

    return (
        <AgTechFormDialog
            structure={{
                header: {
                    title: `Resolve Bidder - ${props.bidderNumber}`,
                    titleFontSize: 'font-size-medium',
                    detail: `Please select one of the contacts from the below grid to register under bidder number "${props.bidderNumber}". If the correct contact does not exist, please register a new contact for this bidder number.`,
                    detailFontSize: 'font-size-small',
                    detailClasses: 'mt-2'
                },
                submitButtonText: 'Resolve',
                width: 1200,
                bodyClasses: 'bg-surface bottom-bordered py-3'
            }}
            formData={{
                loadInitialFormData: () => ({
                    ...DefaultAgTechContact,
                    bidderId: 0,
                    bidderNumber: props.bidderNumber
                })
            }}
            formAction={{
                dataAction: resolveBidderSubmission,
                getActionData: d => d
            }}
            behavior={{
                eventHandlers: {
                    onDialogSubmitted: props.onBidderReconciled
                }
            }}
        >
            {context => (
                <div className='row-based stretched non-scrollable container'>
                    <ContactSelectionGrid
                        contacts={contactsNotRegistered}
                        onContactSelected={async cnt => {
                            context.updateFormEntity(ent => ({
                                ...cnt,
                                bidderId: 0,
                                bidderNumber: props.bidderNumber
                            }));
                        }}
                        bidderNumber={props.bidderNumber}
                        gridProps={gProps => {
                            return {
                                ...gProps,
                                columns: gProps.columns.filter(clm => clm.header !== 'Bidder Number'),
                                header: {
                                    title: 'Contacts not yet registered',
                                    content: () => (
                                        <ContactRegisterBidderDialog.Button
                                            bidderNumber={props.bidderNumber}
                                            onContactRegistered={async bidderRegistration => {
                                                await handleContactCreated(bidderRegistration);
                                            }}
                                        />
                                    )
                                },
                                noRecordsConfig: {
                                    message: 'All contacts in your operation have been registered for this sale.',
                                    action: {
                                        prompt: 'To resolve this bidder, please register a new contact by clicking the button below',
                                        button: () => (
                                            <ContactRegisterBidderDialog.Button
                                                bidderNumber={props.bidderNumber}
                                                onContactRegistered={async bidderRegistration => {
                                                    await handleContactCreated(bidderRegistration);
                                                }}
                                            />
                                        )
                                    }
                                }
                            }
                        }}
                    />
                </div>
            )}
        </AgTechFormDialog>
    );
}

BidderReconciliationDialog.Open = (e: AgTechClickEventHandler, props: BidderReconciliationDialogProps) => e.dialogs.openDialog({
    dialog: () => <BidderReconciliationDialog {...props} />
});

BidderReconciliationDialog.Button = (props: BidderReconciliationDialogProps & AgTechButtonProps) => {
    return (
        <AgTechButton
            {...props}
            text={props.text ?? 'Register Bidder'}
            action={e => BidderReconciliationDialog.Open(e, props)}
        />
    )
}

export default BidderReconciliationDialog