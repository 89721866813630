import React from 'react';
import { AgTechClickEventHandler, AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";

export type AgTechSideNavigationButtonProps = {
    icon: string,
    text: string,
    role?: string,
    action: (e: AgTechClickEventHandler) => void,
    isCollapsed: boolean
}

const AgTechSideNavigationButton = (props: AgTechSideNavigationButtonProps) => {    
    return (
        <AgTechButton
            classes='link icon-only'
            text={props.isCollapsed ? undefined : props.text}
            icon={props.icon}
            role={props.role}
            action={props.action}
        />
    )
}

export default AgTechSideNavigationButton;