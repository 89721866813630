import { useAppContext } from "agtech/core/app/AgTechAppContext";
import { AgTechRendering } from "agtech/core/components/AgTechRendering";
import { AgTechDataAction, AgTechDataActionExecutor, AgTechDataActionContext, AgTechDataActionEventHandlers, AgTechDataActionConfiguration, useDataActionExecutor, DataActionContext, DefaultAgTechDataActionConfiguration } from "agtech/core/data/actions/AgTechDataActions";
import { delay } from "agtech/core/utilities/AgTechUtilities";
import React, { useState } from "react";

export enum AgTechDataActionSurfaceStatus {
    Open,
    Submitting,
    Submitted
}

export type AgTechDataActionSurfaceState<TActionData, TResponseData> = {
    status: AgTechDataActionSurfaceStatus,
    confirmationData?: AgTechDataActionSurfaceConfirmationProps<TActionData, TResponseData>,
    actionConfiguration?: AgTechDataActionConfiguration,
    confirmationPane?: AgTechRendering
}

export type AgTechDataActionSurfaceProps<TActionData, TResponseData> = {
    action: AgTechDataAction<TActionData, TResponseData>,
    behavior?: AgTechDataActionSurfaceBehaviorProps<TActionData, TResponseData>,
    children: (dataActionExecutor: AgTechDataActionExecutor) => React.ReactNode,
    classes?: string,
}

export type AgTechDataActionSurfaceConfirmationProps<TActionData, TResponseData> = {
    actionData: TActionData,
    responseData: TResponseData
}

export type AgTechDataActionSurfaceBehaviorProps<TActionData, TResponseData> = {
    returnToOpenStateOnCompletion?: boolean,
    eventHandlers?: AgTechDataActionEventHandlers<TActionData, TResponseData>,
    confirmation?: {
        getConfirmationPane?: (confirmationData: AgTechDataActionSurfaceConfirmationProps<TActionData, TResponseData>) => React.ReactNode
    }
}

const useDataActionSurface = <TActionData, TResponseData>(
    props: AgTechDataActionSurfaceProps<TActionData, TResponseData>
) => {
    let [surfaceState, updateSurfaceState] = useState<AgTechDataActionSurfaceState<TActionData, TResponseData>>({
        status: AgTechDataActionSurfaceStatus.Open
    });
    
    let actionSubmissionContext: AgTechDataActionContext<any, any> = {
        actionName: props.action.name,
        onExecutionStarted: async actionProps => {
            updateSurfaceState({
                status: AgTechDataActionSurfaceStatus.Submitting,
                actionConfiguration: actionProps.actionConfiguration
            });

            if (props.behavior?.eventHandlers?.onExecutionStarted) {
                await props.behavior.eventHandlers.onExecutionStarted(actionProps);
            }

            await delay(actionProps.actionConfiguration.actionExecutionDelay ?? 300);
        },
        onExecutionSuccessful: async actionProps => {
            updateSurfaceState({
                status: AgTechDataActionSurfaceStatus.Submitted,
                actionConfiguration: actionProps.actionConfiguration,
                confirmationData: {
                    actionData: actionProps.actionData,
                    responseData: actionProps.responseData,
                }
            });

            await delay(actionProps.actionConfiguration.actionConfirmationDelay ?? 750);

            if (props.behavior?.eventHandlers?.onExecutionSuccessful) {
                await props.behavior.eventHandlers.onExecutionSuccessful(actionProps);
            }
        },
        onExecutionCompleted: async result => {
            if (props.behavior?.eventHandlers?.onExecutionCompleted) {
                props.behavior?.eventHandlers?.onExecutionCompleted(result);
            }
            
            if (props.behavior?.returnToOpenStateOnCompletion ?? false) {
                updateSurfaceState({
                    status: AgTechDataActionSurfaceStatus.Open,
                });
            }
        },
        onExecutionFailed: async failureProps => {
            updateSurfaceState({
                status: AgTechDataActionSurfaceStatus.Open,
                actionConfiguration: failureProps.actionConfiguration
            });

            if (props.behavior?.eventHandlers?.onExecutionFailed) {
                await props.behavior.eventHandlers.onExecutionFailed(failureProps);
            }
        }
    }

    return {
        state: surfaceState,
        context: actionSubmissionContext
    };
}

const AgTechDataActionSurface = <TActionData, TResponseData>(props: AgTechDataActionSurfaceProps<TActionData, TResponseData>) => {
    let dataActionSurface = useDataActionSurface(props);
    let actionConfiguration = dataActionSurface.state.actionConfiguration ?? DefaultAgTechDataActionConfiguration;
    
    return (
        <DataActionContext.Provider value={dataActionSurface.context}>
            <AgTechDataActionSurfaceOpenView {...props} />
            {dataActionSurface.state.status === AgTechDataActionSurfaceStatus.Submitting ? (
                <AgTechDataActionSurfaceSubmittingView
                    submissionConfiguration={actionConfiguration}
                />
            ) : dataActionSurface.state.status === AgTechDataActionSurfaceStatus.Submitted ? (
                <AgTechDataActionSurfaceConfirmationView
                    {...props}
                    state={{...dataActionSurface.state}}
                />
            ) : null}
        </DataActionContext.Provider> 
    )
}

const AgTechDataActionSurfaceOpenView = <TActionData, TResponseData>(props: AgTechDataActionSurfaceProps<TActionData, TResponseData>) => {
    let dataActionExecutor = useDataActionExecutor();

    return (
        <>
            {props.children(dataActionExecutor)}
        </>
    )
}

export const AgTechDataActionSurfaceSubmittingView = <TFormInput, TFormOutput>(props: {
    submissionConfiguration: AgTechDataActionConfiguration
}) => {
    let appContext = useAppContext();

    return (
        <>
            {appContext.loading.showSubmission(props.submissionConfiguration.actionExecutionMessage ?? 'Submitting...')}
        </>
    )
}

const AgTechDataActionSurfaceConfirmationView = <TActionData, TResponseData>(
    props: AgTechDataActionSurfaceProps<TActionData, TResponseData> & {
        state: AgTechDataActionSurfaceState<TActionData, TResponseData>
    }
) => {
    let appContext = useAppContext();
    let actionConfiguration = props.state.actionConfiguration ?? DefaultAgTechDataActionConfiguration;

    return props.behavior?.confirmation?.getConfirmationPane && props.state.confirmationData
        ? (
            <>
                {props.behavior.confirmation.getConfirmationPane(props.state.confirmationData)}
            </>
        )
        : (
            <>
                {appContext.loading.showSubmissionConfirmation({
                    confirmationMessage: actionConfiguration.actionConfirmationMessage ?? 'Success!',
                    confirmationDetails: actionConfiguration.actionConfirmationDetails
                })}
            </>
        )
}

export default AgTechDataActionSurface