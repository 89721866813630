import React, { CSSProperties } from 'react'

export type AgTechBorderedListProps = {
    items: (() => React.ReactNode)[],
    itemClasses?: string,
    classes?: string,
    styles?: CSSProperties
}

const AgTechBorderedList = (props: AgTechBorderedListProps) => {
    return (
        <div className={`row-based stretched container ${props.classes}`} style={props.styles}>
            {props.items.map((item, index) => (
                <div className={`bordered wide container ${props.itemClasses}`} style={{ borderTopWidth: index === 0 ? 1 : 0 }}>
                    {item()}
                </div>
            ))}
        </div>
    )
}

export default AgTechBorderedList