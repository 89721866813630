import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from "agtech/web/components/Buttons/AgTechButtons"
import { AgTechFormNumberControl, AgTechFormRow, AgTechFormSection, AgTechFormTextControl } from "agtech/web/components/Forms/AgTechForm"
import { AgTechSelectionFormControl } from "agtech/web/components/Forms/Controls/Selection/AgTechFormSelectionControl"
import AgTechTitleDetailHeader from "agtech/web/components/Headers/AgTechTitleDetailHeader"
import AgTechFormDialog from "agtech/web/components/Portals/Dialogs/AgTechFormDialog"
import ContactSelectionControl from "app/areas/contacts/components/selections/ContactSelectionControl"
import { DefaultPayment, Payment, PaymentTypes } from "app/data/invoicing/InvoicingData"
import { useInvoiceDataGetInvoiceDetailsHttpRequest, usePaymentDataWritePaymentAction } from "app/data/invoicing/InvoicingDataActions"
import { useContacts } from "app/data/operation/contacts/ContactData"
import _ from 'lodash'
import InvoicePaymentsViewGrid from "../components/grids/InvoicePaymentsGrid"

export type AddPaymentDialogProps = {
    invoiceId: number
}

type PaymentFormData = {
    payment: Payment,
    previousPayments: Payment[]
}

const AddPaymentDialog = (props: AddPaymentDialogProps) => {
    let contacts = useContacts();

    const getInvoiceDetailsRequest = useInvoiceDataGetInvoiceDetailsHttpRequest();
    const writePaymentAction = usePaymentDataWritePaymentAction();
    
    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: e => e.executeAndConvertRequest(
                    {
                        request: getInvoiceDetailsRequest,
                        inputData: props.invoiceId,
                        convert: async p => {
                            let formData: PaymentFormData = {
                                payment: {
                                    ...DefaultPayment,
                                    payerId: p.contactId,
                                    invoiceId: p.invoiceId,
                                    amount: p.invoiceTotal
                                },
                                previousPayments: [...p.payments]
                            }                        
                            
                            if (p.payments.length > 0)
                            {
                                formData.payment.amount = formData.payment.amount - _.sum(p.payments.map(paym => paym.amount));
                            }

                            return formData;
                        }
                    }
                )
            }} 
            formAction={{
                dataAction: writePaymentAction,
                getActionData: d => d.payment
            }}
            structure={{
                header: 'Add Payment',
                submitButtonText: 'Create',
                width: 600
            }}
         >
            {(formContext) => (
                <>
                    <AgTechFormRow>
                        <ContactSelectionControl
                            contacts={contacts}
                            entityType={formContext.entity}
                            caption={'Payer'}
                            getSelectedContactId={ent => ent.payment.payerId}
                            onContactSelected={async (ent, val) => {                       
                                ent.payment.payerId = val?.id ?? 0;

                                return ent;
                            }}
                            isRequired={true}
                            width={65}
                        />
                        <AgTechFormNumberControl
                            entityType={formContext.entity}
                            caption="Amount"
                            required={true}
                            value={entity => entity.payment.amount}
                            onControlValueChanged={async (e, val) => {
                                e.payment.amount = val;
                                return e;
                            }}
                            width={35}
                            unit="$"
                        />
                    </AgTechFormRow>
                    <AgTechFormRow>
                        <AgTechSelectionFormControl
                            entityType={formContext.entity}
                            control={{caption: 'Payment Type'}}
                            selection={{
                                selectionControlTitle: 'Payment Type',
                                items: PaymentTypes,
                                itemText: e => e.description,
                                getSelectedItemId: e => e.payment.paymentType.toString(),
                                onItemSelected: async (e, v) => {
                                    e.payment.paymentType = v?.id ?? 0;
                                    return e;
                                },
                                itemHeight: 60,
                                itemRendering: e => (
                                    <AgTechTitleDetailHeader
                                        title={e.description}
                                        titleFontSize='font-size-small'
                                        detailFontSize='font-size-very-small'
                                        detailClasses='mt-1-half'
                                        />
                                ),
                                filterItems: (item, filter) => (item.description).includes(filter),
                                styles: { height: 240, width: 360 }
                            }} />
                            <AgTechFormTextControl
                                entityType={formContext.entity}
                                caption="Details"
                                required={false}
                                value={entity => entity.payment.details}
                                onControlValueChanged={async (e, val) => {
                                    e.payment.details = val;

                                    return e;
                                }}
                            />
                    </AgTechFormRow>
                    <AgTechFormSection header="Previous Payments"
                        classes='flexed'
                        bodyClasses='flexed pt-3'>
                            <InvoicePaymentsViewGrid payments={formContext.entity.previousPayments} />
                    </AgTechFormSection>
                </>
            )}
        </AgTechFormDialog>
    )
}

AddPaymentDialog.Open = (e: AgTechClickEventHandler, props: AddPaymentDialogProps) => e.dialogs.openDialog({
    dialog: () => <AddPaymentDialog {...props} />
});

AddPaymentDialog.Button = (props: AgTechButtonProps & AddPaymentDialogProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'Add Payment',
        bootstrapIcon: 'bi-credit-card-2-front-fill',
        ...props,
        action: e => AddPaymentDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default AddPaymentDialog;