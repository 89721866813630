import AgTechConfirmationModal, { AgTechConfirmationModalProps } from 'agtech/web/components/Portals/Modals/AgTechConfirmationModal';
import React, { useRef, useState } from 'react'
import AgTechModal, { AgTechModalRenderProps } from 'agtech/web/components/Portals/Modals/AgTechModal';
import AgTechWarningModal, { AgTechWarningModalProps, AgTechWarningModalRenderProps } from 'agtech/web/components/Portals/Modals/AgTechWarningModal';
import { AgTechConfirmationProps, DefaultAgTechConfirmationActions, IAgTechAppConfirmations } from 'agtech/core/functions/AgTechAppConfirmations';
import { AgTechRendering } from 'agtech/core/components/AgTechRendering';
import { v4 as uuidv4 } from 'uuid';
import { AgTechLoader } from '../../Pages/Loading/AgTechLoadingSurface';
import { AgTechCoreDataActionProps, AgTechDataAction, AgTechDataActionExecutionResult, DataActionResults } from 'agtech/core/data/actions/AgTechDataActions';
import { AgTechActionModal } from 'agtech/web/components/Portals/Modals/AgTechActionModal';

/// Components

export type AgTechModalSurfaceProps = {
    modalManager: AgTechModalManager
}

let AgTechModalSurface = (props: AgTechModalSurfaceProps) => {
    return props.modalManager.modals ? (
        <>
            {props.modalManager.modals.map(modal => modal ? (
                <MemoModal key={modal.id} modal={modal} />
            ) : null)}
        </>
    ) : null;
}

const Modal = (props: { modal: AgTechRendering }) => {
    return (
        <>
            { props.modal.rendering() }
        </>
    )
}

const MemoModal = React.memo(Modal, (prev, curr) => true);

/// Manager

declare type AgTechModalRendering = {
    id: string,
    rendering: () => React.ReactNode
}

export type AgTechModalManager = IAgTechAppConfirmations & {
    modals: AgTechModalRendering[],
    showModal: (modal: AgTechModalRenderProps) => string,
    showActionModal: <TInput, TOutput>(
        dataAction: AgTechDataAction<TInput, TOutput>,
        dataActionExecutionProps: AgTechCoreDataActionProps<TInput>,
    ) => void,
    showWarningModal: (modal: AgTechWarningModalRenderProps) => Promise<void>,
    showLoader: (loadingMessage: string) => string,
    closeModal: (modalId: string) => void
}

export const DefaultAgTechModalManager: AgTechModalManager = {
    modals: [],
    showModal: modal => {
        console.error('Tried to show modal with default app context.');
        return '';
    },
    askForConfirmation: modal => new Promise<boolean>(function(resolve, reject) {
        console.error('Tried to show confirmation modal with default app context');
        resolve(false);
    }),
    showActionModal: async () => {
        console.error('Tried to show action modal with default app context');
    },
    showWarningModal: modal => new Promise<void>((res, rej) => {
        console.error('Tried to show warning modal with default app context');
        res();
    }),
    showLoader: modal => {
        console.error('Tried to show loader modal with default app context.');
        return '';
    },
    closeModal: () => {
        console.error('Tried to close modal with default app context.');
    }
}

export const useModals = (): AgTechModalManager => {
    let [activeModalsState, updatedActiveModals] = useState<AgTechModalRendering[]>([]);
    let activeModalsRef = useRef<AgTechModalRendering[]>(activeModalsState);

    const showModal = (modalRendering: AgTechModalRendering) => {
        activeModalsRef.current = [...activeModalsRef.current, { ...modalRendering }];
        updatedActiveModals(activeModalsRef.current);
    };

    const closeModal = (modalId: string) => {
        activeModalsRef.current = [...activeModalsRef.current.filter(modal => modal.id !== modalId)];
        updatedActiveModals(activeModalsRef.current);
    }

    const showCustomModal = (rendering: (id: string) => React.ReactNode) => {
        let modalId = uuidv4();

        showModal({
            id: modalId,
            rendering: () => rendering(modalId)
        });

        return modalId;
    }

    const showStandardModal = (modal: AgTechModalRenderProps) => {
        return showCustomModal(modalId => (
            <AgTechModal
                closeModal={() => closeModal(modalId)}
                height={modal.height}
                width={modal.width}
                classes={modal.classes}
                content={() => modal.content({
                    closeModal: () => closeModal(modalId)
                })}
                showCloseButton={modal.showCloseButton}
            />
        ));
    };

    const showConfirmationModal = async (props: AgTechConfirmationProps) => {
        return new Promise<boolean>(function(resolve, reject) {
            let modalId = uuidv4();
            let modalActions = props.actions ?? DefaultAgTechConfirmationActions;

            let modalProps: AgTechConfirmationModalProps = {
                ...props,
                width: props.content.width,
                closeModal: () => closeModal(modalId),
                onCancel: () => {
                    if (modalActions.onCancel) {
                        modalActions.onCancel();
                    }
                    
                    closeModal(modalId);
                    resolve(false);
                },
                onConfirm: async () => {
                    closeModal(modalId);
                    let didConfirmationSucceed = modalActions?.onConfirm ? await modalActions.onConfirm() : true;

                    resolve(didConfirmationSucceed);
                }
            };

            showModal({
                id: modalId,
                rendering: () => (
                    <AgTechConfirmationModal {...modalProps} />
                )
            });
        });
    }

    let showWarningModal = (props: AgTechWarningModalRenderProps) => new Promise<void>((res, rej) => {
        let modalId = uuidv4();

        let warningModalProps: AgTechWarningModalProps = {
            ...props,
            closeModal: () => {
                closeModal(modalId);
                res();
            }
        };

        showModal({
            id: modalId,
            rendering: () => <AgTechWarningModal {...warningModalProps} />
        });
    });
    
    return {
        modals: activeModalsRef.current,
        showModal: showStandardModal,
        showLoader: message => showStandardModal({
            content: () => <AgTechLoader text={message} classes='font-color-white' textClasses='font-color-white' />,
            classes: 'bg-transparent no-shadow',
            showCloseButton: false
        }),
        showActionModal: (action, props) => {
            showStandardModal({
                height: '140px',
                width: '0px',
                classes: 'bg-transparent no-shadow',
                showCloseButton: false,
                content: modalProps => (
                    <AgTechActionModal
                        action={action}
                        actionProps={props}
                        closeModal={modalProps.closeModal}
                    />
                )
            })
        },
        closeModal: closeModal,
        askForConfirmation: (props: AgTechConfirmationProps) => showConfirmationModal(props),
        showWarningModal: (props: AgTechWarningModalRenderProps) => showWarningModal(props)
    };
}

export default AgTechModalSurface;