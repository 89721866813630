import { AgTechDataContext, DataContext } from 'agtech/core/data/common/AgTechDataContext'
import { AgTechWebApplication } from 'agtech/web/application/AgTechWebApplication'
import React from 'react'

const AgTechWebAppDataContext = (props: React.PropsWithChildren<{ app: AgTechWebApplication }>) => {
    let webAppDataContext: AgTechDataContext = {
        baseURL: props.app.data.baseURL
    };

    return (
        <DataContext.Provider value={webAppDataContext}>
            {props.children}
        </DataContext.Provider>
    )
}

export default AgTechWebAppDataContext