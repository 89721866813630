import { AgTechClickEventHandler, AgTechRendering, useClickable } from 'agtech/web/components/Buttons/AgTechButtons'
import React, { createContext, CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import AgTechFutureLoadingSurface from '../Loading/AgTechFutureLoadingSurface'
import AgTechTabbedPage, { AgTechTabPageContext, AgTechTabPageStyle, AgTechTabbedPageBody, AgTechTabbedPageProps, AgTechTabbedPageTabProps } from 'agtech/web/components/Pages/Tabbed/AgTechTabPage';

export type AgTechThinTabbedPageProps = AgTechTabbedPageProps & {
    tabFontSize?: string
}

const AgTechThinTabbedPage = (props: AgTechThinTabbedPageProps) => {
    let [selectedTab, setSelectedTab] = useState(props.tabs[0]);

    useEffect(() => {
        // The tabs have changed for this tab page, but we should attempt to stay on the
        // currently selected tab if the new tabs also contain a tab wiht the same text
        let matchingTabs = props.tabs.filter(tab => tab.text === selectedTab.text);

        if (matchingTabs.length === 1) {
            let matchingTab = matchingTabs[0];
            setSelectedTab(matchingTab);
        }
        else {
            setSelectedTab(props.tabs[0]);
        }

    }, [props.tabs])

    let tabPageContext: AgTechTabPageContext = {
        currentTab: selectedTab,
        setCurrentTab: (tab: AgTechTabbedPageTabProps) => setSelectedTab(tab)
    }

    return (
        <div className={`stretched row-based container ${props.classes ?? ''}`} style={props.styles}>
            <AgTechTabPageContext.Provider value={tabPageContext}>
                { props.panel
                    ? props.panel.rendering()
                    : <AgTechThinTabbedPageHeader tabPageConfiguration={props} />
                }
                <AgTechTabbedPageBody
                    currentTab={selectedTab}
                    configuration={props.body}
                />
            </AgTechTabPageContext.Provider>
        </div>
    )
}

let AgTechThinTabbedPageHeader = ({ tabPageConfiguration }: { tabPageConfiguration: AgTechThinTabbedPageProps }) => {
    return (
      <div className={`wide column-based bottom-bordered container ${tabPageConfiguration.header?.classes ?? ''}`}>
            <div className="column-based-vertically-centered flexed container" style={{ height: 24 }}>
                {tabPageConfiguration.tabs.map(tab => (
                    <AgTechTabbedPageTab key={tab.text} {...tab} {...tabPageConfiguration} />
                ))}
            </div>
            <div className="ml-auto vertically-centered container">
                {tabPageConfiguration.header?.content ? tabPageConfiguration.header.content() : null}
            </div>
        </div>
    );
}

export const AgTechTabbedPageTab = (props: AgTechTabbedPageTabProps & AgTechThinTabbedPageProps) => {

    let tabButtonRef = useRef(null);
    let tabButtonClickable = useClickable(tabButtonRef);
    
    let tabPageContext: AgTechTabPageContext = useContext<AgTechTabPageContext>(AgTechTabPageContext);
    let isActiveTab = tabPageContext.currentTab?.text === props.text;
    let isEmphasized = props.isEmphasized ?? false;

    // Handles a right click on the tab
    let handleContextMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (props.onRightClick) {
            e.preventDefault();
            props.onRightClick({
                clickEvent: e,
                ...tabButtonClickable
            });
        }
    }

    let fontSizeClasses = props.tabFontSize ?? 'font-size-small';
    let activeClasses = isActiveTab ? 'highlight-bordered' : 'clear-bordered';

    return (
        <div 
            className={`clickable px-6 centered bottom-bordered thick-bordered tall font-bold pb-1-half ${fontSizeClasses} container ${activeClasses}`}
            onClick={() => tabPageContext.setCurrentTab(props)}
            onContextMenu={handleContextMenuClick} 
            key={props.text} 
            ref={tabButtonRef}
            style={{ marginTop: 1 }}
        >        
            {props.text}
        </div>
    );
}


export default AgTechThinTabbedPage;