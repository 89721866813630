import { AgTechActionButton, AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import { ProcessedSaleInvoice } from 'app/data/invoicing/InvoicingData';
import React from 'react'
import InvoiceActionMenu from '../menus/InvoiceActionMenu';
import AddPaymentDialog from '../../dialogs/InvoiceAddPaymentDialog';
import { useInvoiceDataDownloadInvoiceAction } from 'app/data/invoicing/InvoicingDataActions';
import InvoiceInfoDialog from 'app/areas/invoices/dialogs/InvoiceInfoDialog';
import { ContactGridLink } from 'app/areas/contacts/components/buttons/ContactButtons';
import { useSaleBidderData } from 'app/data/bidders/BidderData';

export type InvoiceProcessedInvoiceGridProps = {
    processedInvoices: ProcessedSaleInvoice[],
    renderPaymentsButton?: boolean
}

const InvoiceProcessedInvoiceGrid = (props: InvoiceProcessedInvoiceGridProps) => {
    let bidderData = useSaleBidderData();

    let invoiceProcessedInvoiceGridConfiguration: AgTechGridProps<ProcessedSaleInvoice> = {
        header: {
            title: 'Invoices'
        },
        items: props.processedInvoices,
        columns: [
            {
                header: "Invoice Number",
                value: invoice => <AgTechGridLink content={invoice.invoiceNumber} />,
                classes: 'emphasized centered',
                width: '120px'
            },
            {
                header: 'Bidder Number',
                value: invoice => {
                    let bidderId = bidderData.contactIdsMappedToBidders[invoice.contactId];
                    let bidder = bidderData.bidderIdsMappedToBidders[bidderId];

                    return bidder?.bidderNumber ?? 'N/A';
                },
                width: '130px'
            },
            {
                header: "Purchaser",
                value: invoice => <ContactGridLink contactId={invoice.contactId} />,
                size: 1.5
            },
            {
                header: "Lots Purchased",
                value: invoice => <AgTechGridLink content={invoice.numberOfLots.toString()} />,
                width: '130px'
            },
            {
                header: "Invoice Total",
                value: invoice => <AgTechGridLink content={AgTechMoneyFormatter.format(invoice.invoiceTotal)} />,
                width: '130px'
            },
            {
                header: 'View',
                value: invoice => (
                    <InvoiceInfoDialog.Button
                        invoiceId={invoice.id}
                        classes='link'
                        text='View Invoice'
                        showNextSteps={props.renderPaymentsButton ?? false}
                    />
                ),
                showHeader: false,
                width: '140px'
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.purchaserName === curr.purchaserName &&
                   prev.numberOfLots === curr.numberOfLots &&
                   prev.numberOfDiscountsApplied === curr.numberOfDiscountsApplied &&
                   prev.invoiceTotal === curr.invoiceTotal;
        },
        searchConfig: {
            options: [
                {
                    field: 'Bidder Number',
                    value: invoice => invoice.invoiceNumber
                },
                {
                    field: 'Purchaser',
                    value: invoice => invoice.purchaserName
                },
                {
                    field: 'Invoice Total',
                    value: invoice => invoice.invoiceTotal.toString() + '_' + AgTechMoneyFormatter.format(invoice.invoiceTotal)
                }
            ]
        },
        actions: {
            rendering: invoice => (
                <AgTechActionButton classes='stretched centered container'>
                    <InvoiceActionMenu invoice={invoice} />
                </AgTechActionButton>
            )
        }
    };

    if (props.renderPaymentsButton === true)
    {
        invoiceProcessedInvoiceGridConfiguration.columns.push(
            {
                header: 'Pay',
                value: invoice => <AddPaymentDialog.Button invoiceId={invoice.id} classes='link' />,
                showHeader: false,
                width: '140px'
            }
        );
    }

    return <AgTechGrid {...invoiceProcessedInvoiceGridConfiguration} />
}

export default InvoiceProcessedInvoiceGrid