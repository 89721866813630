import AgTechDataProvider from 'agtech/core/data/providers/AgTechDataProvider';
import { AgTechBadge, AgTechBadgeColor } from 'agtech/web/components/Badges/AgTechBadges';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import AgTechListPopUp from 'agtech/web/components/Portals/Popups/AgTechListPopUp';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import InvoiceCreationDialog from 'app/areas/invoices/dialogs/InvoiceCreationDialog';
import LotDialog from 'app/areas/lots/dialogs/LotDialog';
import { SaleLotCategory, useLotCategoryData } from 'app/data/lots/categories/LotCategoryData';
import { SaleLot, useSaleLotData } from 'app/data/lots/LotData';
import { useLotDataGetLotsToInvoiceForBidderRequest } from 'app/data/lots/purchases/LotPurchaseDataActions';
import _ from 'lodash';
import React from 'react'

export type BidderLotsListPopUpProps = {
    bidderId: number
}

type BidderLotsListPopUpPurchase = {
    id: number,
    lot: SaleLot,
    lotCategory?: SaleLotCategory,
    purchaseAmount: number
}

const BidderLotsListPopUp = (props: BidderLotsListPopUpProps) => {
    return (
        <div style={{ height: 240, width: 320 }}>
            <BidderLotsListPopUpDataProvider bidderId={props.bidderId}>
                {(lotsPurchasedByBidder: BidderLotsListPopUpPurchase[]) => (
                    <AgTechListPopUp
                        header={{
                            title: `Lots Purchased (${lotsPurchasedByBidder.length})`
                        }}
                        maxItemsToShow={4}
                        list={{
                            items: lotsPurchasedByBidder,
                            itemRow: lotPurchase => {
                                return (
                                    <AgTechTitleDetailHeader
                                        icon='bars'
                                        iconSize={24}
                                        iconClasses='mr-3 font-size-small'
                                        title={'Lot ' + lotPurchase.lot.lotNumber}
                                        onTitleClicked={e => LotDialog.Open(e, { lotId: lotPurchase.lot.id })}
                                        detail={`${lotPurchase.lot.saleOrder}${lotPurchase.lotCategory ? ' - ' + lotPurchase.lotCategory.name : ''}`}
                                        classes='stretched p-3 centered bottom-bordered table-bordered'
                                        content={() => (
                                            <AgTechBadge
                                                text={lotPurchase.purchaseAmount ? AgTechMoneyFormatter.format(lotPurchase.purchaseAmount) : 'N/A'}
                                                color={AgTechBadgeColor.Green}
                                                customizations={{
                                                    width: '80px'
                                                }}
                                            />
                                        )}
                                    />
                                )
                            },
                            itemHeight: 70
                        }}
                        footer={() => (
                            <div className='wide column-based-vertically-centered container'>
                                <AgTechTitleDetailHeader
                                    title='Total'
                                    titleFontSize='font-size-small'
                                    detail={AgTechMoneyFormatter.format(_.sumBy(lotsPurchasedByBidder, p => p.purchaseAmount))}
                                    contentClasses='centered'
                                    // content={() => (
                                    //     <InvoiceCreationDialog.Button
                                    //         bidderId={props.bidderId}
                                    //         icon='plus'
                                    //         text='Invoice Bidder'
                                    //         classes='highlight'
                                    //         height={32}
                                    //     />
                                    // )}
                                    classes='py-half'
                                />
                            </div>
                        )}
                        width={320}
                    />
                )}
            </BidderLotsListPopUpDataProvider>
        </div>
    )
}

const BidderLotsListPopUpDataProvider = (
    props: { bidderId: number, children: (data: BidderLotsListPopUpPurchase[]) => JSX.Element }
) => {
    let lotData = useSaleLotData();
    let lotCategories = useLotCategoryData();
    let bidderLotsRequest = useLotDataGetLotsToInvoiceForBidderRequest();
    
    return (
        <AgTechDataProvider
            loadData={async e => await e.executeAndConvertRequest({
                request: bidderLotsRequest,
                inputData: props,
                convert: async data => {
                    return data.map(purchase => {
                        let lot = lotData.lots[purchase.lotId];

                        return {
                            id: lot.id,
                            lot: lot,
                            lotCategory: lot.lotCategoryId ? lotCategories[lot.lotCategoryId] : undefined,
                            purchaseAmount: (lot.purchasePrice ?? 0) * purchase.percentage 
                        }
                    });
                }})}
        >
           {props.children}
        </AgTechDataProvider>
    )
}

export default BidderLotsListPopUp