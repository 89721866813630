import { AgTechFormContext } from 'agtech/core/forms/AgTechFormContext'
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons'
import AgTechSelectionButton from 'agtech/web/components/Buttons/AgTechSelectionButton'
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid'
import { AgTechGridHeaderConfiguration } from 'agtech/web/components/Grids/AgTechGridHeader'
import { AgTechGridNumberControl } from 'agtech/web/components/Grids/Controls/AgTechGridNumberControl'
import { AgTechGridSearchState } from 'agtech/web/components/Grids/Features/AgTechGridSearching'
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog'
import AgTechSearchControl from 'agtech/web/components/Widgets/AgTechSearchControl'
import { ContactGridLink, ContactSelectionButton } from 'app/areas/contacts/components/buttons/ContactButtons'
import { useContactSelectionProps } from 'app/areas/contacts/components/selections/ContactSelectionControl'
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog'
import { getBidderNumberByContactId, useSaleBidderContacts } from 'app/data/bidders/BidderData'
import { useSaleLot, useSaleLotData } from 'app/data/lots/LotData'
import { LotSplit, LotSplitSummary, useLotDataGetLotSplitSummaryRequest, useLotDataSplitLotAction } from 'app/data/lots/LotDataActions'
import { AgTechContact, useContacts } from 'app/data/operation/contacts/ContactData'
import { split } from 'lodash'
import React from 'react'

export type LotSplitDialogProps = {
    lotId: number
}

const LotSplitDialog = (props: LotSplitDialogProps) => {
    let lot = useSaleLot(props.lotId);
    let lotSplitSummaryRequest = useLotDataGetLotSplitSummaryRequest();
    let lotSplitAction = useLotDataSplitLotAction();
    
    return (
        <AgTechFormDialog
            structure={{
                header: 'Split Lot',
                submitButtonText: 'Split Lot',
                width: 550
            }}
            formData={{
                loadInitialFormData: e => e.executeRequest(lotSplitSummaryRequest, {
                    lotId: props.lotId,
                })
            }}
            formAction={{
                dataAction: lotSplitAction,
                getActionData: d => ({
                    lotId: d.lotId,
                    bidderPercentages: d.splits
                })
            }}
        >
            {(formContext) => (
                <LotSplitGrid formContext={formContext} lotSplits={formContext.entity} />
            )}
        </AgTechFormDialog>
    )
}

export type InvoiceLotSplitGroupingGridProps = {
    formContext: AgTechFormContext<LotSplitSummary>,
    lotSplits: LotSplitSummary,
    updateLotSplits?: (updatedLotSplits: LotSplit[]) => void,
}

const LotSplitGrid = (props: InvoiceLotSplitGroupingGridProps) => {
    let contacts = useContacts();

    let lot = useSaleLot(props.lotSplits.lotId);
    let bidderContacts = useSaleBidderContacts();

    let gridItems = props.lotSplits.splits.map(item => ({
        id: item.contactId,
        bidder: bidderContacts[item.contactId],
        percentage: item.percentage
    }));

    let contactSelectionProps = useContactSelectionProps({
        contacts: contacts,
        onContactSelected: async (sel) => {},
        selectedContactId: undefined
    });

    return (
        <AgTechGrid
            header={{
                title: 'Lot Splits: ' + lot.lotNumber,
                showCount: false,
                content: () => (
                    <ContactSelectionButton
                        filter={cnt => !props.lotSplits.splits.find(s => s.contactId === cnt.id)}
                        onContactSelected={async selectedContact => {
                            props.formContext.updateFormEntity(curr => ({
                                ...curr,
                                splits: [...curr.splits, {
                                    contactId: selectedContact.id,
                                    percentage: 0
                                }]
                            }));
                        }}
                        classes='link ml-auto'
                    />
                )
            }}
            items={gridItems}
            columns={[
                {
                    header: 'Bidder Number',
                    value: i => i.bidder?.bidderNumber ?? 'N/A',
                    classes: 'centered emphasized',
                    width: '120px',
                },
                {
                    header: 'Name',
                    value: i => i.bidder ? <ContactGridLink contactId={i.bidder.id} /> : 'Not Registered',
                },
                {
                    header: 'Percentage',
                    value: i => false ? (i.percentage * 100) : (
                        <div className='column-based-vertically-centered relative stretched container'>
                            <AgTechGridNumberControl
                                value={i.percentage * 100}
                                onUpdated={val => {
                                    props.formContext.updateFormEntity(curr => ({
                                        ...curr,
                                        splits: curr.splits.map(s => s.contactId === i.id
                                            ? { ...s, percentage: val / 100 }
                                            : { ...s }
                                        )
                                    }));
                                }}
                            />
                            <i className='bi bi-percent absolute' style={{ left: 60, marginTop: 1 }} />
                        </div>
                    ),
                    width: '120px',
                }
            ]}
            isRowEntityTheSame={(prev, curr) => false}
        />
    )
}

LotSplitDialog.Open = (e: AgTechClickEventHandler, props: LotSplitDialogProps) => e.dialogs.openDialog({
    dialog: () => <LotSplitDialog {...props} />
});

LotSplitDialog.Button = (props: LotSplitDialogProps & AgTechButtonProps) => {
    return (
        <AgTechButton
            text="Split Lot"
            icon="clone"
            {...props}
            action={e => LotSplitDialog.Open(e, props)}
        />
    )
}

export default LotSplitDialog