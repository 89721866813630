import React from 'react'

export type AgTechHeaderMetricProps = {
    header: string,
    value: string,
    classes?: string,
    width?: number,
    titleMarginBottom?: string
}

let AgTechHeaderMetric = (props: AgTechHeaderMetricProps) => {
    let marginBottom = props.titleMarginBottom ?? 'mb-2';

    return (
        <div className={`tall flexed row-based-vertically-centered container ${props.classes ?? ''}`} style={{
            width: props.width
        }}>
            <h1 className={`font-size-large font-bold ${marginBottom}`}>{props.value}</h1>
            <h1 className="font-size-small font-bold font-color-secondary font-ellipses">{props.header}</h1>
        </div>
    );
};

export default AgTechHeaderMetric;