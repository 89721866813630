import { AgTechActionButton } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import ContactCreditActionMenu from 'app/areas/contacts/components/menus/ContactCreditActionMenu';
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog';
import { AgTechContact, useContact, getAvailableContactCredits } from 'app/data/operation/contacts/ContactData';
import { ContactCredit } from 'app/data/operation/contacts/credits/ContactDataCredits';
import React from 'react'

export type ContactCreditsGridProps = {
    contact: AgTechContact,
    credits?: ContactCredit[],
    gridProps?: (props: AgTechGridProps<ContactCredit>) => AgTechGridProps<ContactCredit>
}

const ContactCreditsGrid = (props: ContactCreditsGridProps) => {
    let creditGridConfiguration = useContactCreditGridConfiguration(props.contact.id, props.credits);

    if (props.gridProps) {
        creditGridConfiguration = props.gridProps(creditGridConfiguration);
    }

    return <AgTechGrid {...creditGridConfiguration} />
}

export const useContactCreditGridConfiguration = (contactId: number, credits?: ContactCredit[]) => {
    let contact = useContact(contactId);
    let contactCredits = credits ?? getAvailableContactCredits(contact);
    
    let creditGridConfiguration: AgTechGridProps<ContactCredit> = {
        header: {
            title: 'Credits',
        },
        items: contactCredits,
        columns: [
            {
                header: "Amount",
                value: credit => credit.creditAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                classes: 'emphasized centered',
                width: '100px'
            },
            {
                header: 'Description',
                value: credit => 
                    <AgTechGridLink
                        content={credit.description}
                        onClick={(e => e.dialogs.openDialog({
                            dialog: () => <ContactCreditDialog contactId={contact.id} credit={credit} />
                        }))}
                    />,
                size: 2
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.creditAmount === curr.creditAmount &&
                   prev.description === curr.description;
        },
        noRecordsConfig: {
            message: 'No credits were found for this contact',
            action: {
                prompt: 'To create one, click the button below',
                button: () => (
                    <ContactCreditDialog.Button
                        contactId={contact.id}
                        classes='highlight'
                    />
                )
            }
        },
        actions: {
            rendering: credit => {
                return (
                    <AgTechActionButton>
                        <ContactCreditActionMenu contactId={contact.id} creditId={credit.id} />
                    </AgTechActionButton>
                )
            }
        }
    };

    return creditGridConfiguration;
}

export default ContactCreditsGrid;