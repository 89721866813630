import React, { useRef } from 'react'
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from './AgTechButtons'
import { AgTechSelectionControlProps, IAgTechSelectionPopUpItem } from '../Forms/Controls/Selection/AgTechCoreSelectionControlData'
import { AgTechSelectionPopUp } from '../Forms/Controls/Selection/AgTechSelectionPopUp'
import { AgTechPopUpPosition } from '../Portals/Popups/Helpers/AgTechPopUpData'

export type AgTechSelectionButtonProps<TItem extends IAgTechSelectionPopUpItem> = AgTechButtonProps & {
    selectionProps: AgTechSelectionControlProps<TItem>
}

const AgTechSelectionButton = <TItem extends IAgTechSelectionPopUpItem>(props: AgTechSelectionButtonProps<TItem>) => {
    let buttonRef = useRef(null);

    return (
        <AgTechButton
            {...props}
            ref={buttonRef}
            action={async e => showSelectionPopUp(e, buttonRef, props.selectionProps)}
        />
    )
}

export const showSelectionPopUp = <TItem extends IAgTechSelectionPopUpItem>(
    e: AgTechClickEventHandler,
    callerRef: React.RefObject<any>,
    props: AgTechSelectionControlProps<TItem>
) => {
    e.popups.showPopUp({
        callerRef: callerRef,
        popup: () => (
            <AgTechSelectionPopUp
                selectionRef={callerRef}
                items={props.items}
                content={props.content}
            />
        ),
        config: {
            position: AgTechPopUpPosition.BelowRight,
            fadeInTime: '200ms'
        }
    });
}
export default AgTechSelectionButton