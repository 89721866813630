import React, { FunctionComponent } from 'react'

export enum AgTechToastType
{
    Info,
    Warning,
    Error,
    Success
}

export type AgTechToastProps = {
    type: AgTechToastType
}

let AgTechToast = (props: React.PropsWithChildren<AgTechToastProps>) => {
    let toastClass = props.type === AgTechToastType.Info ? 'toast-info'
                   : props.type === AgTechToastType.Warning ? 'toast-warning'
                   : props.type === AgTechToastType.Error ? 'toast-error'
                   : props.type === AgTechToastType.Success ? 'toast-success'
                   : 'toast-info';
                   
    return (
        <div className={`agtech-toast ${toastClass}`}>
            {props.children}
        </div>
    )
};

export default AgTechToast;