import React, { useEffect, useState } from 'react'
import AgTechFormDevelopmentWindow from 'agtech/web/components/Forms/Layout/AgTechFormDevelopmentWindow';
import { FormContext, useFormContextData } from 'agtech/core/forms/AgTechFormContext';
import { AgTechFormProps } from 'agtech/core/forms/AgTechFormProps';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import { AgTechSuccessIcon, AgTechWarningIcon } from 'agtech/web/components/Icons/AgTechIcons';
import AgTechHorizontalDivider from 'agtech/web/components/MIsc/AgTechHorizontalDivider';
import { isDev } from 'agtech/core/utilities/AgTechUtilities';
import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechPopUpPosition } from 'agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData';
import { AgTechFormState } from 'agtech/core/forms/AgTechFormState';

const AgTechFormComponent = <TFormData, TActionData, TResponseData>(props: AgTechFormProps<TFormData, TActionData, TResponseData>) => {
    let formContext = useFormContextData(props);

    return (
        <div className={`agtech-form ${props.structure?.classes ?? ''}`}>
            <FormContext.Provider value={formContext}>
                {props.structure?.header ? (
                    <AgTechTitleDetailHeader
                        title={props.structure.header.title}
                        titleFontSize='font-size-larger'
                        classes='mb-2'
                        content={() => (
                            <div className='column-based-vertically-centered container'>
                                {props.structure?.header?.content ? props.structure.header.content() : null}
                                {props.structure?.header?.content ? <AgTechHorizontalDivider /> : null}
                                {props.behavior?.realtimeSubmissionProps ? <AgTechFormStateControl state={formContext.state}/> : null}
                            </div>
                        )}
                    />
                ) : null}
                {typeof props.children === 'function' ? props.children(formContext) : props.children}
            </FormContext.Provider>
            {isDev() ? (
                <AgTechButton
                    text='View Form Data'
                    icon='eye'
                    classes='absolute link'
                    styles={{
                        bottom: 12,
                        left: 12
                    }}
                    action={e => e.popups.showPopUp({
                        callerRef: e.clickableRef,
                        popup: () => <AgTechFormDevelopmentWindow formEntity={formContext.entity} />,
                        config: {
                            shouldCloseOnBlur: false,
                            position: AgTechPopUpPosition.Beside
                        }
                    })}
                />
            ) : null}
        </div>
    );
};

export const AgTechFormStateControl = (props: { state: AgTechFormState, classes?: string }) => {
    let [currentState, setCurrentState] = useState(props.state);

    useEffect(() => {
        if (props.state === AgTechFormState.Submitting) {
            setCurrentState(AgTechFormState.Submitting);
        }
        else if (props.state === AgTechFormState.Successful) {
            setTimeout(() => {
                setCurrentState(AgTechFormState.Successful);
            }, 1500);
        }
    }, [props.state]);

    return (
        <div className={`bg-white centered bordered rounded container ${props.classes ?? ''}`} style={{ height: 32, width: 36 }}>
            {currentState === AgTechFormState.Submitting ? <div className={`loader`} style={{ height: 16, width: 16 }} />
           : currentState === AgTechFormState.Failed ? <AgTechWarningIcon />
           : <AgTechSuccessIcon classes='agtech-fade-in font-size-large' />}
        </div>
    )
}

// Barrel form controls for easier importing in uptake modules
export { AgTechFormTextControl } from 'agtech/web/components/Forms/Controls/AgTechFormTextControl';
export { AgTechFormNumberControl } from 'agtech/web/components/Forms/Controls/AgTechFormNumberControl';
export { AgTechFormDateControl } from 'agtech/web/components/Forms/Controls/AgTechFormDateControl';
export { AgTechFormToggleControl } from 'agtech/web/components/Forms/Controls/AgTechFormToggleControl';
export { AgTechFormTextAreaControl } from 'agtech/web/components/Forms/Controls/AgTechFormTextAreaControl';
export { AgTechFormReadOnlyTextControl } from 'agtech/web/components/Forms/Controls/AgTechFormReadOnlyTextControl';
export { AgTechFormSection } from 'agtech/web/components/Forms/Layout/AgTechFormSection';
export { AgTechFormRow } from 'agtech/web/components/Forms/Layout/AgTechFormRow';
export { FormContext, useFormContext } from 'agtech/core/forms/AgTechFormContext';

export const AgTechForm = React.memo(AgTechFormComponent) as typeof AgTechFormComponent;