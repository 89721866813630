import { AgTechGridEntity, AgTechGridEntityStatus, getEntitiesAvailableBySearchState, getEntitiesAvailableByView, getUpdatedEntitiesOnItemsChanged, updateEntitiesForNewDefaultEntity, updateEntitiesOnEntityDeselected, updateEntitiesOnEntityFocused, updateEntitiesOnEntityRemoved, updateEntitiesOnEntitySelected } from "agtech/web/components/Grids/Data/AgTechGridEntities";
import { AgTechGridConfiguration, AgTechGridEventHandlers, AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { IAgTechGridItem, AgTechGridColumnMetadata } from "agtech/web/components/Grids/Data/AgTechGridData";
import { AgTechGridItemGroup } from "agtech/web/components/Grids/Features/AgTechGridGrouping";
import _ from "lodash";
import { AgTechGridEntityIndex, AgTechGridState, AgTechGridStateHandlingProps } from "agtech/web/components/Grids/Data/AgTechGridState";
import { logDev, logEvent } from "agtech/core/logging/AgTechLogger";
import { useMemo } from "react";
import { AgTechWebAppContext } from "agtech/web/components/App/AgTechWebAppContext";
import { AgTechDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";

const GridRowHeight = 40;
const GridGroupHeaderHeight = 36;

export type AgTechGridItemIndex<TItem extends IAgTechGridItem> = {
    [index: string]: AgTechGridEntity<TItem> | AgTechGridItemGroup<TItem, any>
}

export type AgTechGridData<TItem extends IAgTechGridItem> = {
    itemIndex: AgTechGridItemIndex<TItem>,
    itemHeights: number[],
    columns: AgTechGridColumnMetadata<TItem>[],
    configuration: AgTechGridConfiguration<TItem>,
    hasRowChanged: (prev: TItem, curr: TItem) => boolean,
    handlers: AgTechGridEventHandlers<TItem>,
    state: AgTechGridState<TItem>
}

export const useGridItemData = <TItem extends IAgTechGridItem>(props: {
    gridProps: AgTechGridProps<TItem>,
    gridState: React.MutableRefObject<AgTechGridState<TItem>>,
    updateGridState: (updatedState: AgTechGridState<TItem>) => void,
    appContext: AgTechWebAppContext,
    actionExecutor: AgTechDataActionExecutor
}) => {
    return useMemo(() => {
        return buildGridItemData({
            gridProps: props.gridProps,
            stateProps: {
                ...props,
                gridState: () => props.gridState.current,
                updateGridEntities: updatedEntityState => {
                    props.updateGridState({
                        ...props.gridState.current,
                        entityState: {
                            ...updatedEntityState,
                            versionNumber: updatedEntityState.versionNumber + 1
                        }
                    });
                },
                updateGridEntity: (updatedEntityId, updatedEntity) => {
                    if (updatedEntity.item.id.toString() !== updatedEntityId) {
                        delete props.gridState.current.entityState.allEntities[updatedEntityId];
                    }

                    logEvent('Updated grid row entity', {
                        entity: updatedEntity,
                    });

                    let existingEntities = Object.values(props.gridState.current.entityState.allEntities);
                    let updatedEntities: AgTechGridEntityIndex<TItem> = {};

                    existingEntities.forEach(entity => {
                        updatedEntities[entity.id] = entity.id === updatedEntityId ? {
                            ...updatedEntity,
                            versionNumber: entity.versionNumber + 1
                        } : {
                            ...entity
                        }
                    });
                    
                    props.updateGridState({
                        ...props.gridState.current,
                        entityState: {
                            ...props.gridState.current.entityState,
                            versionNumber: props.gridState.current.entityState.versionNumber + 1,
                            allEntities: updatedEntities
                        }
                    });
                },
            }
        });
    },
    [
        props.gridProps.items,
        props.gridState.current.entityState.versionNumber,
        props.gridState.current.searchState?.filter,
        props.gridState.current.selectedView
    ])
}

export const buildGridItemData = <TItem extends IAgTechGridItem>(props: {
    gridProps: AgTechGridProps<TItem>,
    stateProps: AgTechGridStateHandlingProps<TItem>
}) => {
    let gridState = props.stateProps.gridState();
    let eventHandlers = getAgTechGridEventHandlers(props.stateProps);

    let gridItemData: AgTechGridData<TItem> = {
        itemIndex: {},
        itemHeights: [],
        columns: gridState.gridProps.columns,
        configuration: gridState.gridProps,
        hasRowChanged: (prev, curr) =>{
            return prev && curr && !gridState.gridProps.isRowEntityTheSame(prev, curr);
        },
        handlers: eventHandlers,
        state: gridState
    }

    let availableEntityIndex = getUpdatedEntitiesOnItemsChanged(props.gridProps, gridState.entityState);

    if (gridState.searchState?.filter) {
        availableEntityIndex = getEntitiesAvailableBySearchState(gridState);
    }

    if (gridState.selectedView) {
        availableEntityIndex = getEntitiesAvailableByView(gridState, availableEntityIndex);
    }

    let availableEntities = Object.values(availableEntityIndex);

    if (props.gridProps.sortingConfig) {
        availableEntities = _.sortBy(availableEntities, e => props.gridProps.sortingConfig?.getSortingKey(e.item));
    }

    if (gridState.gridProps.groupingConfig) {
        let entityGroups = _.groupBy(availableEntities, entity => gridState.gridProps.groupingConfig?.group(entity.item));
        let entityGroupKeys = _.orderBy(Object.keys(entityGroups), key => key);

        let entityNumericIndex = 0;

        entityGroupKeys.forEach(key => {
            let entitiesInGroup = entityGroups[key];

            gridItemData.itemIndex[entityNumericIndex] = {
                groupKey: key,
                itemsInGroup: entitiesInGroup,
                groupText: gridState.gridProps.groupingConfig?.groupText,
                groupHeader: gridState.gridProps.groupingConfig?.groupHeader
            }

            gridItemData.itemHeights.push(GridGroupHeaderHeight);

            entitiesInGroup.forEach(entity => {
                entityNumericIndex++;

                gridItemData.itemIndex[entityNumericIndex] = entity;
                gridItemData.itemHeights.push(GridRowHeight);
            });

            entityNumericIndex++;
        })
    }
    else {
        availableEntities.forEach((entity, index) => {
            gridItemData.itemIndex[index] = entity;
        });
    }

    return gridItemData;
}

export const getAgTechGridEventHandlers = <TItem extends IAgTechGridItem>(props: AgTechGridStateHandlingProps<TItem>): AgTechGridEventHandlers<TItem> => {
    return {
        onRowBlurred: async (blurredEntity: AgTechGridEntity<TItem>, originalItem: TItem) => {
            if (props.gridState().gridProps.editConfig) {
                await handleGridRowBlurred(props, blurredEntity, originalItem);
            }
        },
        onRowFocused: async (focusedEntity: AgTechGridEntity<TItem>) => {
            await updateEntitiesOnEntityFocused(props, focusedEntity);
        },
        onItemSelected: async (selectedEntity: AgTechGridEntity<TItem>) => {
            if (props.gridState().gridProps.selectionConfig) {
                await updateEntitiesOnEntitySelected(props, selectedEntity);
            }
        },
        onItemDeselected: async (deselectedEntity: AgTechGridEntity<TItem>) => {
            if (props.gridState().gridProps.selectionConfig) {
                await updateEntitiesOnEntityDeselected(props, deselectedEntity);
            }
        },
        onItemDeleted: async (deletedEntity: AgTechGridEntity<TItem>) => {
            let gridState = props.gridState();

            // If the entity was created but not written, it can be removed immediately
            if (deletedEntity.state.wasCreated && !deletedEntity.state.wasWritten) {
                await updateEntitiesOnEntityRemoved(props, deletedEntity);
            }
            else if (gridState.gridProps.editConfig && gridState.gridProps.editConfig.deleteItem) {
                let wasRowDeleted = await gridState.gridProps.editConfig.deleteItem({
                    actionExecutor: props.actionExecutor,
                    entity: deletedEntity
                });

                if (wasRowDeleted) {
                    await updateEntitiesOnEntityRemoved(props, deletedEntity);
                }
            }
        },
        onEnterKeyPressed: async (key: string, entity: AgTechGridEntity<TItem>, originalItem: TItem) => {
            let gridEditConfig = props.gridState().gridProps.editConfig;

            if (gridEditConfig && gridEditConfig.defaultItem && gridEditConfig.onEntityCreated) {
                await handleGridRowBlurred(props, entity, originalItem);

                if (entity.state.status !== AgTechGridEntityStatus.Invalid) {
                    await updateEntitiesForNewDefaultEntity(props);
                }
            }
        }
    }
}

const handleGridRowBlurred = async <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    blurredEntity: AgTechGridEntity<TItem>,
    originalItem: TItem
) => {
    let gridEditConfig = props.gridState().gridProps.editConfig;

    if (!gridEditConfig) {
        return;
    }

    let hasEntityChanged = (blurredEntity.state.wasCreated && !blurredEntity.state.wasWritten) ||
        !props.gridState().gridProps.isRowEntityTheSame(originalItem, blurredEntity.item);

    if (hasEntityChanged) {
        logEvent('Grid row entity has changed', { entity: blurredEntity.id });

        // If the entity has been created but not written to the parent data source, write it
        // to the parent data source and mark it accordingly
        if (blurredEntity.state.wasCreated && !blurredEntity.state.wasWritten) {
            await HandleGridEntityCreation(props, blurredEntity);
        }
        else {
            await HandleGridEntityUpdating(props, blurredEntity, originalItem);
        }
    }
}

const HandleGridEntityCreation = async <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    blurredEntity: AgTechGridEntity<TItem>
) => {
    let gridEditConfig = props.gridState().gridProps.editConfig;

    if (gridEditConfig && gridEditConfig.onEntityCreated) {
        logDev('Creating entity...');

        try
        {
            let entityCreationResponse = await gridEditConfig.onEntityCreated({
                actionExecutor: props.actionExecutor,
                entity: blurredEntity
            });

            if (entityCreationResponse.success) {      
                props.updateGridEntity(blurredEntity.id, {
                    ...blurredEntity,
                    id: entityCreationResponse.success.data.id.toString(),
                    item: { ...entityCreationResponse.success.data },
                    state: {
                        ...blurredEntity.state,
                        wasWritten: true,
                        status: AgTechGridEntityStatus.Created
                    }
                });
    
                logDev('Entity created with ID: ' + entityCreationResponse.success.data.id);
            }
            else {
                throw 'Entity creation failed!';
            }
        }
        catch (error)
        {
            console.error('An exception occurred creating an entity: ' + error);

            if (!blurredEntity.state.hasBeenWarned) {
                let gridState = props.gridState();

                let updateFailureMessage = gridState.gridProps.editConfig?.itemCreationError
                ? gridState.gridProps.editConfig.itemCreationError(blurredEntity.item)
                : 'An error occurred creating the item';

                props.appContext.messaging.showWarning({
                    header: updateFailureMessage
                });
            }

            props.updateGridEntity(blurredEntity.id, {
                ...blurredEntity,
                state: {
                    ...blurredEntity.state,
                    status: AgTechGridEntityStatus.Invalid,
                    hasBeenWarned: true
                }
            });
        }
    }
}

const HandleGridEntityUpdating = async <TItem extends IAgTechGridItem>(
    props: AgTechGridStateHandlingProps<TItem>,
    blurredEntity: AgTechGridEntity<TItem>,
    originalItem: TItem
) => {
    let gridEditConfig = props.gridState().gridProps.editConfig;

    if (!gridEditConfig) {
        return;
    }

    let updateEntityResponse = await gridEditConfig.onEntityUpdated({
        actionExecutor: props.actionExecutor,
        entity: blurredEntity,
        originalItem: originalItem
    });

    // let updateEntityResponse = {
    //     wasCancelled: false,
    //     success: {
    //         data: blurredEntity.item
    //     }
    // };

    if (updateEntityResponse.success) {
        if (updateEntityResponse.success.data) {            
            props.updateGridEntity(blurredEntity.id, {
                ...blurredEntity,
                item: { ...updateEntityResponse.success.data },
                state: {
                    ...blurredEntity.state,
                    wasWritten: true,
                    status: blurredEntity.state.wasCreated ? AgTechGridEntityStatus.Created : AgTechGridEntityStatus.Updated
                }
            });
        }
        else {
            logDev('Entity update failed.');
        }
    }
    else {
        let wasUpdateCancelled = updateEntityResponse.wasCancelled ?? false;

        // No update was made so reset back to the prior row entity
        props.updateGridEntity(blurredEntity.id, {
            ...blurredEntity,
            item: { ...originalItem },
            state: {
                ...blurredEntity.state,
                status: wasUpdateCancelled ? blurredEntity.state.status : AgTechGridEntityStatus.Invalid
            }
        });

        if (!wasUpdateCancelled) {
            let gridState = props.gridState();

            let updateFailureMessage = gridState.gridProps.editConfig?.itemUpdateError
                ? gridState.gridProps.editConfig.itemUpdateError(blurredEntity.item)
                : 'An error occurred updating the item';
    
            props.appContext.messaging.showWarning({
                header: updateFailureMessage
            });
        }
    }
}