import { AgTechColors } from 'agtech/core/styles/AgTechColors'
import React, { CSSProperties } from 'react'

const AgTechCloseIcon = (props: { 
    onClicked: () => Promise<void>,
    classes?: string,
    styles?: CSSProperties
}) => {
    return (
        <i 
            className={`agtech-icon-badge fas fa-times clickable bg-white bordered ${props.classes ?? ''}`}
            onClick={props.onClicked}
            style={{
                height: 36,
                width: 36,
                marginRight: 0,
                backgroundColor: AgTechColors.White,
                ...props.styles
            }}
        />
    )
}

export default AgTechCloseIcon