import { AgTechDataActionExecutionResult, useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { AgTechButton, AgTechButtonProps, AgTechFileUploadButton } from "agtech/web/components/Buttons/AgTechButtons";
import { AgTechStandardModal } from "agtech/web/components/Portals/Modals/AgTechModal";
import AgTechImportModal from "agtech/web/components/Portals/Modals/Imports/AgTechImportModal";
import { AgTechImportValidationResult } from "agtech/web/components/Portals/Modals/Imports/Common/AgTechImportTypes";
import AgTechStepInstruction from "agtech/web/components/Widgets/AgTechStepInstruction";
import { useLotCategories, useLotCategoryData } from "app/data/lots/categories/LotCategoryData";
import { LotInvoiceState, SaleLot, useSaleLots } from "app/data/lots/LotData";
import { useLotDataImportLotsAction } from "app/data/lots/LotDataActions";
import { useCurrentSale } from "app/data/sales/SaleData";

export type SaleLotImportModalProps = {
    onLotsImported?: (importedLots: SaleLot[]) => Promise<AgTechDataActionExecutionResult<any>>
}

const SaleLotImportModal = (props: SaleLotImportModalProps) => {
    let saleLots = useSaleLots();
    let currentSale = useCurrentSale();
    let lotCategoryData = useLotCategoryData();
    let lotCategories = useLotCategories();
    let lotImportAction = useLotDataImportLotsAction();

    let actionExecutor = useDataActionExecutor();

    return (
        <AgTechImportModal
            title='Import Lots'
            importEntitySingular='Lot'
            importEntityPlural='Lots'
            onImportSubmitted={props.onLotsImported ?? (async importedLots => {
                return await actionExecutor.executeAction(lotImportAction, {
                    submittedEntity: importedLots
                });
            })}
            importExecutionProps={{
                importColumns: [
                    {
                        columnName: 'Lot Number',
                        getItemValue: item => item.lotNumber.toString(),
                        setItemValue: (item, value) => {
                            item.lotNumber = value;
                        }
                    },
                    {
                        columnName: 'Sale Order',
                        getItemValue: item => item.saleOrder.toString(),
                        validateItemValue: value => {
                            let validationResult: AgTechImportValidationResult = { isValid: true };

                            if (isNaN(parseInt(value))) {
                                validationResult = {
                                    isValid: false,
                                    errorMessage: 'The given sales order value was not a number'
                                };
                            }

                            return validationResult;
                        },
                        setItemValue: (item, value) => {
                            item.saleOrder = parseInt(value);
                        }
                    },
                    {
                        columnName: 'Lot Description',
                        getItemValue: item => item.lotDescription,
                        setItemValue: (item, value) => {
                            item.lotDescription = value;
                        },
                        getItemGridColumn: () => ({
                            size: 2
                        })
                    },
                    {
                        columnName: 'Lot Category',
                        getItemValue: item => item.lotCategoryId ? lotCategoryData[item.lotCategoryId]?.name ?? '' : '',
                        setItemValue: (item, value) => {
                            let correspondingLotCategory = lotCategories.find(cat => cat.name === value);

                            item.lotCategoryId = correspondingLotCategory?.id ?? undefined;
                        }
                    },
                    {
                        columnName: 'Group Size',
                        getItemValue: item => item.groupSize?.toString() ?? '',
                        setItemValue: (item, value) => {
                            let parsedValue = parseInt(value);
                            if (!Number.isNaN(parsedValue) && parsedValue > 1) {
                                item.groupSize = parsedValue;
                            }
                            else {
                                item.groupSize = undefined;
                            }
                        }
                    },
                ],
                defaultItem: {
                    id: 0,
                    saleId: currentSale.id,
                    saleOrder: 0,
                    lotNumber: '',
                    lotDescription: '',
                    lotCategoryId: undefined,
                    timestamp: '',
                    invoiceState: LotInvoiceState.NotInvoiced,
                    isSplit: false
                },
                conflictHandling: {
                    existingItems: saleLots,
                    itemKeys: [
                        (lot) => ({ key: 'lotNumber_' + lot.lotNumber, warning: 'A lot with this lot number already exists' }),
                        (lot) => ({ key: 'saleOrder_' + lot.saleOrder.toString(), warning: 'A lot with this sale order already exists' })
                    ]
                }
            }}
            body={importBodyProps => (
                <AgTechStandardModal
                    title='Import Lots'
                    details='To import lots for this sale, follow the instructions below'
                    // closeModal={props.closeModal}
                    body={() => (
                        <div className='stretched row-based container'>
                            <AgTechStepInstruction
                                stepNumber={1}
                                stepInstruction='Before importing your lots, please be sure the first line of your file matches the columns below. When entering the lot data group size is optional but the column must be there.'
                            >
                                <div className='column-based centered container mb-6 mt-6'>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Sale Order
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Lot Number
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Lot Description
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Lot Category
                                    </div>
                                    <div className='bordered py-1 px-4 bg-white'>
                                        Group Size
                                    </div>
                                </div>
                            </AgTechStepInstruction>
                            <AgTechStepInstruction
                                stepNumber={2}
                                stepInstruction='If your columns match those above, click the button below and select the csv file you wish to import'
                            >
                                <div className='centered container mt-5'>
                                    <AgTechFileUploadButton
                                        text='Import Lots'
                                        icon='upload'
                                        acceptTypes='.csv,.xlsx,.xlsm,xls,.ods,.fods'
                                        onFileUploaded={importBodyProps.handleFileUploaded}
                                        classes='highlight p-2 px-5 font-size-above-standard rounded mt-4 mb-4'
                                    />
                                </div>
                            </AgTechStepInstruction>
                        </div>
                    )}
                />
            )}
        />
    )
}

SaleLotImportModal.Button = (props: AgTechButtonProps & SaleLotImportModalProps) => (
    <AgTechButton
        text='Import New Lots'
        icon='upload'
        {...props}
        action={e => e.modals.showModal({
            height: '400px',
            width: '800px',
            content: modalProps => <SaleLotImportModal {...modalProps} {...props} />
        })}
    />
)

export default SaleLotImportModal;