import AgTechCardCollection from 'agtech/web/components/Cards/Collections/AgTechCardCollection'
import AgTechFieldList from 'agtech/web/components/Fields/AgTechFieldList'
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid'
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import AgTechThinTabbedPage from 'agtech/web/components/Pages/Tabbed/AgTechThinTabPage'
import { useContactCreditGridConfiguration } from 'app/areas/contacts/components/grids/ContactCreditsGrid'
import ContactNotesGrid from 'app/areas/contacts/components/grids/ContactNotesGrid'
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog'
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog'
import ContactNoteDialog from 'app/areas/contacts/dialogs/notes/ContactNoteDialog'
import { AgTechContact, getContactFullName, useContact, getContactNotes } from 'app/data/operation/contacts/ContactData'
import React from 'react'

export type ContactInformationPopUpProps = {
    contactId: number
}

const ContactInformationPopUp = (props: ContactInformationPopUpProps) => {
    let contact = useContact(props.contactId);

    return (
        <div className='row-based container' style={{ height: 300, width: 480 }}>
            <AgTechTitleDetailHeader
                title={getContactFullName(contact)}
                classes='px-3 pt-2 pb-5'
            />
            <div className='flexed scrollable container'>
                <AgTechThinTabbedPage
                    header={{
                        classes: 'px-3'
                    }}
                    body={{
                        classes: 'bg-light-gray'
                    }}
                    tabFontSize='font-size-smaller'
                    tabs={[
                        { text: 'Info', content: () => (
                            <div className='flexed p-4 row-based bg-light-gray container'>
                                <AgTechFieldList
                                    fields={[
                                        { name: 'Ranch Name', value: contact.ranchName ? contact.ranchName : 'N/A' },
                                        { name: 'Email Address', value: contact.emailAddress ? contact.emailAddress : 'N/A' },
                                        { name: 'Phone Number', value: contact.phoneNumber ? contact.phoneNumber : 'N/A' },
                                    ]}
                                />
                                <AgTechFieldList
                                    fields={[
                                        { name: 'Address', value: contact.address ?? 'N/A' },
                                    ]}
                                />
                                <AgTechFieldList
                                    fields={[
                                        { name: 'City', value: contact.city ?? 'N/A' },
                                        { name: 'State', value: contact.stateAbbreviation ?? 'N/A' },
                                        { name: 'Zip', value: contact.zip ?? 'N/A' },
                                    ]}
                                />
                            </div>
                        )},
                        { text: 'Notes', content: () => (
                            <ContactInformationPopUpNotesTab contact={contact} />
                        )},
                        { text: 'Credits', content: () => (
                            <ContactInformationPopUpCreditsTab contact={contact} />
                        )}
                    ]}
                />
            </div>
            <div className='column-based px-4 top-bordered container' style={{ height: 40 }}>
                <ContactDialog.Button contactId={props.contactId} classes='ml-auto link' />
            </div>
        </div>
    )
}

const ContactInformationPopUpNotesTab = (props: { contact: AgTechContact }) => {
    let contactNotes = getContactNotes(props.contact);
    let gridHeader = `Notes (${contactNotes.length})`;

    return (
        <div className='stretched row-based scrollable px-5 py-3 container'>
            <div className='column-based-vertically-centered wide mb-2 container'>
                <h1 className='font-size-small font-bold pl-1'>{gridHeader}</h1>
                <ContactNoteDialog.Button
                    contactId={props.contact.id}
                    classes='link ml-auto thin'
                    styles={{ width: 100 }}
                />
            </div>
            <ContactNotesGrid
                contactId={props.contact.id}
                gridProps={props => ({
                    ...props,
                    header: undefined,
                    size: 'small'
                })}
            />
        </div>
    )
}

const ContactInformationPopUpCreditsTab = (props: { contact: AgTechContact }) => {
    let contactCreditsGridConfiguration = useContactCreditGridConfiguration(props.contact.id);

    if (contactCreditsGridConfiguration.noRecordsConfig) {
        contactCreditsGridConfiguration.noRecordsConfig.size = 'small';
    }

    return (
        <div className='stretched row-based scrollable p-4 container'>
            <div className='column-based-vertically-centered wide mb-2 container'>
                <h1 className='font-size-small font-bold pl-1'>Available Credits</h1>
                <ContactCreditDialog.Button
                    contactId={props.contact.id}
                    classes='link ml-auto thin'
                    styles={{ width: 100 }}
                />
            </div>
            <AgTechGrid
                {...contactCreditsGridConfiguration}
                header={undefined}
                sizingConfig={{
                    size: 'small'
                }}
            />
        </div>
    )
}

export default ContactInformationPopUp