import { useState, useEffect, useRef } from "react";
import { AgTechGridControlProps } from "../Data/AgTechGridProps";

export const AgTechGridTextControl = (props: React.PropsWithChildren<AgTechGridControlProps<string>>) => {
    let [controlValue, setControlValue] = useState(props.value);
    let lastValue = useRef(props.value);

    useEffect(() => {
        if (props.value !== controlValue) {
            setControlValue(props.value);
            lastValue.current = props.value;
        }
    }, [props]);

    return (
        <div className="agtech-form-control">
            <input
                value={controlValue}
                readOnly={props.readonly}
                onChange={e => setControlValue(e.currentTarget.value) }
                onFocus={e => e.stopPropagation()}
                onKeyDown={e => {
                    if (e.key === 'Enter' && props.onUpdated && (controlValue !== lastValue.current)) {
                        lastValue.current = controlValue;
                        props.onUpdated(controlValue);
                    }
                }}
                onBlur={e => {
                    if (props.onUpdated && (controlValue !== lastValue.current)) {
                        lastValue.current = controlValue;
                        props.onUpdated(controlValue);
                    } 
                }}
                tabIndex={props.readonly ? -1 : 1}
            />
        </div>
    )
}