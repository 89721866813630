import React from 'react'
import ContactCreditsGrid from 'app/areas/contacts/components/grids/ContactCreditsGrid';
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog';
import { AgTechContact, getAvailableContactCredits, getContactFullName, getExpiredContactCredits, getInvoicedContactCredits, useContact, useContactData } from 'app/data/operation/contacts/ContactData';
import { AgTechButton, AgTechButtonProps, AgTechLink } from 'agtech/web/components/Buttons/AgTechButtons';
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog';
import AgTechTabbedPage from 'agtech/web/components/Pages/Tabbed/AgTechTabPage';
import AgTechGridLink from 'agtech/web/components/Grids/Controls/AgTechGridLink';
import { ContactCredit } from 'app/data/operation/contacts/credits/ContactDataCredits';
import InvoiceInfoDialog from 'app/areas/invoices/dialogs/InvoiceInfoDialog';

export type ContactCreditsViewDialogProps = {
    contactId: number
}

const ContactCreditViewDialog = (props: ContactCreditsViewDialogProps) => {
    let contact = useContact(props.contactId);

    return (
        <AgTechViewDialog
            header={{
                title: 'Credits - ' + getContactFullName(contact),
                detail: "Below are all active credits for this contact"
            }}
            width="800px"
            textWhileLoading='Loading contact credits...'
        >
            <ContactCreditViewDialogBody {...props} />
        </AgTechViewDialog>
    )
}

const ContactCreditViewDialogBody = (props: ContactCreditsViewDialogProps) => {
    let contactData = useContactData();
    let contact = contactData.contacts[props.contactId];

    let availableCredits = getAvailableContactCredits(contact);
    let invoicedCredits = getInvoicedContactCredits(contact);
    let expiredCredits = getExpiredContactCredits(contact);
    
    return (
        <AgTechTabbedPage
            tabs={[
                {
                    text: `Available (${availableCredits.length})`,
                    content: () => (
                        <ContactCreditsGrid
                            contact={contact}
                            credits={availableCredits}
                            gridProps={contactGridProps => ({
                                ...contactGridProps,
                                header: {
                                    title: 'Credits',
                                    content: () => <ContactCreditDialog.Button contactId={props.contactId} />
                                },
                                searchConfig: {
                                    options: [
                                        { field: 'Amount', value: credit => credit.creditAmount.toString() },
                                        { field: 'Description', value: credit => credit.description }
                                    ]
                                }
                            })}
                        />
                    )
                },
                {
                    text: `Invoiced (${invoicedCredits.length})`,
                    content: () => <InvoicedCreditsPage contact={contact} invoicedCredits={invoicedCredits} />
                },
                {
                    text: `Expired (${expiredCredits.length})`,
                    content: () => (
                        <ContactCreditsGrid
                            contact={contact}
                            credits={expiredCredits}
                            gridProps={contactGridProps => ({
                                ...contactGridProps,
                                header: {
                                    title: 'Credits',
                                    content: () => <ContactCreditDialog.Button contactId={props.contactId} />
                                },
                                searchConfig: {
                                    options: [
                                        { field: 'Amount', value: credit => credit.creditAmount.toString() },
                                        { field: 'Description', value: credit => credit.description }
                                    ]
                                }
                            })}
                        />
                    )
                }
            ]}
        />
    )
}

const InvoicedCreditsPage = (props: { contact: AgTechContact, invoicedCredits: ContactCredit[] }) => {
    return (
        <ContactCreditsGrid
            contact={props.contact}
            credits={props.invoicedCredits}
            gridProps={contactGridProps => ({
                ...contactGridProps,
                header: {
                    title: 'Credits',
                    content: () => <ContactCreditDialog.Button contactId={props.contact.id} />
                },
                columns: [
                    ...contactGridProps.columns,
                    {
                        header: 'Invoice',
                        value: (credit) => credit.invoiceId ? (
                            <InvoiceInfoDialog.Button
                                invoiceId={credit.invoiceId}
                                classes='link'
                                text='View Invoice'
                                showNextSteps={false}
                            />
                        ) : undefined,
                        showHeader: false,
                        width: '140px'
                    }
                ],
                searchConfig: {
                    options: [
                        { field: 'Amount', value: credit => credit.creditAmount.toString() },
                        { field: 'Description', value: credit => credit.description }
                    ]
                }
            })}
        />
    )
}

ContactCreditViewDialog.Button = (props: ContactCreditsViewDialogProps & AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        ...props,
        action: e => e.dialogs.openDialog({
            dialog: () => <ContactCreditViewDialog {...props} />
        })
    };

    buttonProps.text = buttonProps.text ? buttonProps.text : 'View credits';
    buttonProps.icon = buttonProps.icon ? buttonProps.text : 'eye';

    return <AgTechButton {...buttonProps} />
}

export default ContactCreditViewDialog;