import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import { ProcessedSaleInvoice } from 'app/data/invoicing/InvoicingData';
import { useInvoiceDataDownloadInvoiceAction } from 'app/data/invoicing/InvoicingDataActions';
import React from 'react'
import AddPaymentDialog from '../../dialogs/InvoiceAddPaymentDialog';
import InvoiceChangeLotsDialog from '../../dialogs/InvoiceChangeLotsDialog';
import InvoiceChangeDiscountsDialog from '../../dialogs/InvoiceChangeDiscountsDialog';
import InvoiceChangeContactCreditsDialog from '../../dialogs/InvoiceChangeContactCreditsDialog';
import ViewPaymentsDialog from '../../dialogs/InvoiceViewPaymentsDialog';

export type InvoiceActionMenuProps = {
    invoice: ProcessedSaleInvoice
}

const InvoiceActionMenu = (props: InvoiceActionMenuProps) => {
    let invoiceDownloadAction = useInvoiceDataDownloadInvoiceAction();
    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Lots">
                <InvoiceChangeLotsDialog.Button invoiceId={props.invoice.id} contactId={props.invoice.contactId} />
            </AgTechVerticalPopUpMenuSection>
            <AgTechVerticalPopUpMenuSection header="Discounts">
                <InvoiceChangeDiscountsDialog.Button invoiceId={props.invoice.id} />
            </AgTechVerticalPopUpMenuSection>
            <AgTechVerticalPopUpMenuSection header="Credits">
                <InvoiceChangeContactCreditsDialog.Button invoiceId={props.invoice.id} contactId={props.invoice.contactId} />
            </AgTechVerticalPopUpMenuSection>
            <AgTechVerticalPopUpMenuSection header="Payments">
                <ViewPaymentsDialog.Button invoiceId={props.invoice.id} />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
}

export default InvoiceActionMenu