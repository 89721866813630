import { AgTechButtonProps, AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';
import { SaleBidderContact } from 'app/data/bidders/BidderData';
import { useBidderDataRemoveBidderAction } from 'app/data/bidders/BidderDataActions';
import React from 'react'

export type BidderRemovalButtonProps = AgTechButtonProps & {
    bidderContact: SaleBidderContact
}

export const BidderRemovalButton = (props: BidderRemovalButtonProps) => {
    let bidderRemovalSubmission = useBidderDataRemoveBidderAction();

    let buttonProps: AgTechButtonProps = {
        text: 'Remove Bidder',
        icon: 'times',
        action: async e => {
            await e.actions.executeDataAction(bidderRemovalSubmission, {
                originalEntity: { bidder: props.bidderContact, bidderNumber: props.bidderContact.bidderNumber},
                submittedEntity: { bidder: props.bidderContact, bidderNumber: props.bidderContact.bidderNumber},
            });
        },
        ...props
    };

    return <AgTechButton {...buttonProps} />
}