import { AgTechGridEntity } from 'agtech/web/components/Grids/Data/AgTechGridEntities'
import { IAgTechGridItem } from 'agtech/web/components/Grids/Data/AgTechGridData'
import { AgTechCreatedIcon, AgTechLoadingIcon, AgTechUpdatedIcon, AgTechWarningIcon } from 'agtech/web/components/Icons/AgTechIcons'
import AgTechPopUpOnHoverContainer from 'agtech/web/components/Portals/Popups/AgTechPopUpOnHoverContainer'
import AgTechTooltip from 'agtech/web/components/Text/AgTechTooltip'
import React from 'react'

export type AgTechGridRowStatusIconProps<TItem extends IAgTechGridItem> = {
    entity: AgTechGridEntity<TItem>
}

export const AgTechGridRowStatusCreatedIcon = <TItem extends IAgTechGridItem>(props: AgTechGridRowStatusIconProps<TItem>) => {
    return (
        <AgTechPopUpOnHoverContainer
            classes="stretched centered container"
            refreshWhenChanged={JSON.stringify(props.entity)}
            popupRenderProps={() => ({
                popup: () => <AgTechTooltip title={props.entity.state.message ?? ''} />,
            })}
            children={() => props.entity.state.wasWritten ? <AgTechCreatedIcon /> : <AgTechLoadingIcon />}
        />
    )
}

export const AgTechGridRowStatusUpdatedIcon = <TItem extends IAgTechGridItem>(props: AgTechGridRowStatusIconProps<TItem>) => {
    return (
        <AgTechPopUpOnHoverContainer
            classes="stretched centered container"
            refreshWhenChanged={JSON.stringify(props.entity)}
            popupRenderProps={() => ({
                popup: () => <AgTechTooltip title={props.entity.state.message ?? 'The selected row has been successfully updated'} />,
            })}
            children={() => <AgTechUpdatedIcon />}
        />
    )
}

export const AgTechGridRowStatusInvalidIcon = <TItem extends IAgTechGridItem>(props: AgTechGridRowStatusIconProps<TItem>) => {
    return (
        <AgTechPopUpOnHoverContainer
            classes="stretched centered container"
            refreshWhenChanged={JSON.stringify(props.entity)}
            popupRenderProps={() => ({
                popup: () => <AgTechTooltip title={props.entity.state.message ?? 'The selected row is in an invalid state'} />,
            })}
            children={() => <AgTechWarningIcon />}
        />
    )
}