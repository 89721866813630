import React from 'react'

export type AgTechNoRecordsPageProps = {
    message: string,
    action?: { prompt: string, button: () => React.ReactNode }
    classes?: string,
    messageClasses?: string,
    promptClasses?: string,
    size?: 'small' | 'medium' | 'large'
};

let AgTechNoRecordsPage = (props: AgTechNoRecordsPageProps) => {
    let size = props.size ?? 'medium';
    let messageFontSize = size === 'small' ? 'font-size-large' : size === 'medium' ? 'font-size-larger' : 'font-size-extra-large';
    let promptFontSize = size === 'small' ? 'font-size-small' : size === 'medium' ? 'font-size-standard' : 'font-size-large';

    return (
        <div className={`stretched row-based centered container ${props.classes ?? ''}`}>
            <h1 className={`${messageFontSize} font-bold font-centered font-height-2 px-6 ${props.messageClasses ?? ''}`}>
                {props.message}
            </h1>
            { props.action ? (
                <>
                    <h1 className={`${promptFontSize} font-color-secondary font-centered font-height-2 mb-6 px-6 ${props.promptClasses ?? ''}`}>{props.action.prompt}</h1>
                    {props.action ? props.action.button() : null}
                </>
            )
            : null}
        </div>
    )
};

export default AgTechNoRecordsPage;