import { AgTechWebApplication } from 'agtech/web/application/AgTechWebApplication';
import { useSaleDataLoadingStep } from 'app/data/sales/SaleDataActions';
import SalesAppSideNavigationMenu from 'app/layout/SalesAppSideNavigationMenu';
import SalesAppHomePage from 'app/SalesAppHomePage';
import React from 'react'
import BackgroundImage from './resources/Landing.jpg'
import { useOperationDataLoadOperationStep, useOperationDataLoadOperationLogoStep } from './data/operation/SaleDataOperationActions';
import SalesAppHeader from './layout/SalesAppHeader';
import SalesAppSetupPage from 'app/SalesAppSetupPage';

const useSalesWebApp = (): AgTechWebApplication => {
    return {
        name: 'Sellabull',
        icon: 'balance-scale-left',
        structure: {
            home: () => <SalesAppHomePage />,
            areas: []
        },
        header: app => <SalesAppHeader />,
        navigation: app => <SalesAppSideNavigationMenu app={app} />,
        data: {
            baseURL: process.env.REACT_APP_API_URL + ''
        },
        auth: {
            authority: process.env.REACT_APP_AUTHORITY + '',
            clientId: process.env.REACT_APP_CLIENT_ID + '',
            clientSecret: process.env.REACT_APP_CLIENT_SECRET + '',
            redirectUri: process.env.REACT_APP_BASE_URL + '/auth/login',
            silentRedirectUri: process.env.REACT_APP_BASE_URL + '/auth/login',
            postLogoutRedirectUri: process.env.REACT_APP_BASE_URL + '',
            responseType: process.env.REACT_APP_RESPONSE_TYPE + '',
            scopes: process.env.REACT_APP_SCOPES + '',
            usesAuth: true
        },
        loadingSteps: [
            useSaleDataLoadingStep,
            useOperationDataLoadOperationStep,
            useOperationDataLoadOperationLogoStep
        ],
        backgroundImage: BackgroundImage,
        setup: (children) => <SalesAppSetupPage children={children} />
    }
}

export default useSalesWebApp;