import { AgTechButton } from "agtech/web/components/Buttons/AgTechButtons";
import { IAgTechSelectionPopUpItem, AgTechSelectionControlProps, AgTechSelectionControlItemCreationProps, AgTechSelectionControlState, useSelectionControlState, AgTechSelectionControlEventHandlers, AgTechSelectionControlRenderingProps } from "agtech/web/components/Forms/Controls/Selection/AgTechCoreSelectionControlData";
import AgTechVirtualizedList from "agtech/web/components/Lists/AgTechVirtualizedList";
import AgTechNoRecordsPage from "agtech/web/components/Pages/Error/AgTechNoRecordsPage";
import { PopUpContext } from "agtech/web/components/Portals/Popups/Helpers/AgTechPopUpData";
import { useContext, useRef, useEffect, CSSProperties } from "react";

const SELECTION_POPUP_KEYS_TO_IGNORE = [
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Shift',
    'Tab'
]

export type AgTechSelectionPopUpProps<TItem extends IAgTechSelectionPopUpItem> = AgTechSelectionControlProps<TItem> & {
    selectionRef: React.RefObject<HTMLInputElement>
}

export const SelectionPopUpHeaderHeight = 32;

export const AgTechSelectionPopUp = <TItem extends IAgTechSelectionPopUpItem>(props: AgTechSelectionPopUpProps<TItem>) => {
    let [popupState, setPopupState] = useSelectionControlState(props);

    let itemsRef = useRef<TItem[]>(props.items.selectionItems);
    let popupStateRef = useRef<AgTechSelectionControlState<TItem>>(popupState);

    const updatePopUpState = (updatedState: AgTechSelectionControlState<TItem>) => {
        popupStateRef.current = updatedState;
        setPopupState({...popupStateRef.current});
    }

    let selectionEventHandlers = useSelectionPopUpEventHandlers({
        props,
        itemsRef,
        popupStateRef,
        updatePopUpState
    });

    return props.items.selectionItems.length > 0 ? (
        <div className='row-based non-scrollable rounded-inherit container' style={props.content.styles}>
            <div className="column-based-vertically-centered px-3 bg-white bottom-bordered container">
                {props.content.header({
                    state: popupStateRef.current,
                    handlers: selectionEventHandlers
                })}
            </div>
            <div className="fixed stretched non-scrollable container">
                {props.content.body({
                    state: popupStateRef.current,
                    handlers: selectionEventHandlers
                })}
            </div>
            {props.content.footer ? (
                <div className="column-based-vertically-centered px-4 py-1 top-bordered dark-bordered container">
                    {props.content.footer({
                        state: popupStateRef.current,
                        handlers: selectionEventHandlers
                    })}
                </div>
            ): null}
        </div>
    ) : (
        <div className='row-based non-scrollable rounded-inherit py-5 container' style={{
            minWidth: 400
        }}>
            <AgTechNoRecordsPage
                message={props.items.itemCreationConfig?.noRecordsMessage ?? 'No options were found'}
                promptClasses='mt-1'
                action={props.items.itemCreationConfig?.creationButtonAction &&
                    props.items.itemCreationConfig?.creationButtonText ? {
                    prompt: props.items.itemCreationConfig?.noRecordsPrompt,
                    button: () => (
                        <AgTechButton 
                            text={props.items.itemCreationConfig?.creationButtonText}
                            icon='plus'
                            classes='highlight'
                            action={props.items.itemCreationConfig?.creationButtonAction}
                        />
                    )
                } : undefined}
            />
        </div>
    )
}

export type AgTechSelectionListHeaderProps<TItem extends IAgTechSelectionPopUpItem> = AgTechSelectionControlRenderingProps<TItem> & {
    title: string,
    content?: () => React.ReactNode,
    creationConfig?: AgTechSelectionControlItemCreationProps<TItem>,
    styles?: CSSProperties,
}

export const AgTechSelectionListHeader = <TItem extends IAgTechSelectionPopUpItem>(props: AgTechSelectionListHeaderProps<TItem>) => {
    return (
        <div
            className='wide column-based-vertically-centered py-1 container'
            onClick={e => e.stopPropagation()}
        >
            <h1 className='font-size-small font-bold'>
                {props.title}
            </h1>
            { props.content ? (
                <div className='ml-auto pr-2 container'>
                    {props.content ? props.content() : null}
                </div>
            ) : props.creationConfig ? (
                <AgTechButton
                    classes='link ml-auto'
                    height={28}
                    icon='plus'
                    text={props.creationConfig.creationButtonText}
                    action={async e => {
                        if (props.creationConfig) {
                            await (props.creationConfig.creationButtonAction(e)
                                .then(createdItem => {
                                    props.handlers.onItemSelected(createdItem);
                                }));
                        }
                    }}
                />
            ) : null}
        </div>
)
}

export type AgTechSelectionListProps<TItem extends IAgTechSelectionPopUpItem> = AgTechSelectionControlRenderingProps<TItem> & {
    itemHeight: number,
    item: (item: TItem) => React.ReactNode
}

export const AgTechSelectionListBody = <TItem extends IAgTechSelectionPopUpItem>(props: AgTechSelectionListProps<TItem>) => {
    return (
        <AgTechVirtualizedList
            items={props.state.visibleSelectionItems}
            itemHeight={props.itemHeight}
            selectedItemIndex={props.state.activeItemListIndex}
            itemRow={(item: TItem, index: number) => {
                let isSelectedItem = index === props.state.activeItemListIndex;                    
                let itemDotColor = isSelectedItem ? 'font-color-highlight' : 'font-color-tertiary';
                let itemBackgroundColor = index % 2 === 0 ? 'bg-lightest-gray' : '';

                let itemSelectionClass = isSelectedItem
                    ? 'left-bordered-highlight bg-highlight-light'
                    : 'left-bordered-transparent hover-bg-highlight-lightest';

                return (
                    <div
                        className={`wide bottom-bordered table-bordered column-based-vertically-centered px-3 clickable non-scrollable container ${itemSelectionClass} ${itemBackgroundColor}`}
                        style={{ height: props.itemHeight }}
                        onClick={() => props.handlers.onItemSelected(item)}
                    >
                        <i className={`fas fa-circle mr-3-half ${itemDotColor}`} style={{ fontSize: 9 }} />
                        {props.item(item)}
                    </div>
                )
            }}
        />
    )
}

const useSelectionPopUpEventHandlers = <TItem extends IAgTechSelectionPopUpItem>(eventHandlerProps: {
    props: AgTechSelectionPopUpProps<TItem>,
    itemsRef: React.MutableRefObject<TItem[]>, 
    popupStateRef: React.MutableRefObject<AgTechSelectionControlState<TItem>>,
    updatePopUpState: (updatedState: AgTechSelectionControlState<TItem>) => void
}): AgTechSelectionControlEventHandlers<TItem> => {
    let popupContext = useContext(PopUpContext);

    let props = eventHandlerProps.props;
    let itemsRef = eventHandlerProps.itemsRef;
    let popupStateRef = eventHandlerProps.popupStateRef;
    let updatePopUpState = eventHandlerProps.updatePopUpState;

    useEffect(() => {
        if (props.selectionRef.current) {
            props.selectionRef.current.onkeydown = handleKeyDown;
            props.selectionRef.current.onkeyup = handleKeyUp;
        }
    }, []);

    const moveSelectedItemUp = () => {
        let updatedListIndex = popupStateRef.current.activeItemListIndex - 1;
        updatedListIndex = updatedListIndex < 0 ? 0 : updatedListIndex;

        let updatedActiveItem = popupStateRef.current.visibleSelectionItems[updatedListIndex]; 

        updatePopUpState({
            ...popupStateRef.current,
            activeItem: updatedActiveItem,
            activeItemListIndex: updatedListIndex
        });

        props.items.onItemSelected(updatedActiveItem);
    }

    const moveSelectedItemDown = () => {
        let numberOfVisibleItems = popupStateRef.current.visibleSelectionItems.length;

        let updatedListIndex = popupStateRef.current.activeItemListIndex + 1;
        updatedListIndex = updatedListIndex >= numberOfVisibleItems ? numberOfVisibleItems - 1 : updatedListIndex;

        let updatedActiveItem = popupStateRef.current.visibleSelectionItems[updatedListIndex]; 

        updatePopUpState({
            ...popupStateRef.current,
            activeItem: updatedActiveItem,
            activeItemListIndex: updatedListIndex
        });

        props.items.onItemSelected(updatedActiveItem);
    }

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            moveSelectedItemUp();                    
        }
        else if (e.key === 'ArrowDown') {
            e.preventDefault();
            moveSelectedItemDown();
        }
        else if (e.key === 'Enter') {
            e.preventDefault();
            
            if (popupStateRef.current.visibleSelectionItems.length === 1) {
                // If only one item is visible in the dropdown and enter has been clicked, select that item
                onSelectionItemSelected(popupStateRef.current.visibleSelectionItems[0]);
            }
            else if (popupStateRef.current.activeItem) {
                onSelectionItemSelected(popupStateRef.current.activeItem);
            }
        }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
        if (SELECTION_POPUP_KEYS_TO_IGNORE.indexOf(e.key) >= 0) { return; }

        let updatedSelectionSearchValue = props.selectionRef.current?.value ?? '';

        if (updatedSelectionSearchValue) {
            updatePopUpState({
                ...popupStateRef.current,
                activeItemListIndex: -1,
                visibleSelectionItems: itemsRef.current.filter(item => {
                    return props.items.filterItems(item, updatedSelectionSearchValue);
                })
            });
        }
        else if (popupStateRef.current.activeItem) {
            let activeItemIndex = popupStateRef.current.visibleSelectionItems.findIndex(item => item.id === popupStateRef.current.activeItem?.id ?? -1);

            updatePopUpState({
                ...popupStateRef.current,
                activeItemListIndex: activeItemIndex,
                visibleSelectionItems: itemsRef.current
            });
        }
        else {
            updatePopUpState({
                ...popupStateRef.current,
                activeItemListIndex: -1,
                visibleSelectionItems: itemsRef.current
            });
        }
    }

    const onSelectionItemSelected = async (item: TItem) => {
        await props.items.onItemSelected(item);
        popupContext.closePopUp();
    }

    return {
        onItemSelected: onSelectionItemSelected,
        onItemsUpdated: updatedItems => updatePopUpState({
            ...popupStateRef.current,
            visibleSelectionItems: updatedItems
        })
    }
}