import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechFormRow, AgTechFormTextControl } from 'agtech/web/components/Forms/AgTechForm'
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog'
import ContactSelectionControl from 'app/areas/contacts/components/selections/ContactSelectionControl'
import { useContacts } from 'app/data/operation/contacts/ContactData'
import { SaleBidder, useSaleBidderContacts } from 'app/data/bidders/BidderData'
import { useBidderDataRegisterBidderAction } from 'app/data/bidders/BidderDataActions'
import _ from 'lodash'
import React from 'react'

const BidderRegistrationDialog = () => {
    let contacts = useContacts();
    let bidderContacts = useSaleBidderContacts();
    let bidders = Object.values(bidderContacts);

    let contactsNotRegistered = contacts.filter(cnt => !bidderContacts[cnt.id]?.bidderNumber);
    let bidderRegistrationSubmission = useBidderDataRegisterBidderAction();

    let nextBidderNumber = _.max(bidders.map(bd => parseInt(bd.bidderNumber))) ?? 0;

    let dialogBidder: SaleBidder = {
        id: 0,
        bidderNumber: nextBidderNumber ? (nextBidderNumber + 1).toString() : '',
        contactId: 0
    };

    return (
        <AgTechFormDialog
            structure={{
                header: 'Register Bidder',
                submitButtonText: 'Register',
                width: 500
            }}
            formData={{
                loadInitialFormData: () => dialogBidder
            }}
            formAction={{
                dataAction: bidderRegistrationSubmission,
                getActionData: d => d
            }}
        >
            <AgTechFormRow>
                <ContactSelectionControl
                    contacts={contactsNotRegistered}
                    entityType={dialogBidder}
                    caption={'Contacts not yet registered'}
                    getSelectedContactId={ent => ent.contactId}
                    onContactSelected={async (ent, val) => {                       
                        let updatedContact = {
                            ...ent,
                            contactId: val?.id ?? 0
                        }

                        return updatedContact;
                    }}
                    isRequired={true}
                    width={65}
                />
                <AgTechFormTextControl
                    entityType={dialogBidder}
                    caption="Bidder Number"
                    required={true}
                    value={entity => entity.bidderNumber}
                    onControlValueChanged={async (e, val) => ({ ...e, bidderNumber: val })}
                    width={35}
                />
            </AgTechFormRow>
        </AgTechFormDialog>
    )
}

BidderRegistrationDialog.Button = (props: AgTechButtonProps) => {
    return (
        <AgTechButton
            text='Register Bidder'
            icon='bi bi-r-square-fill'
            {...props}
            action={e => e.dialogs.openDialog({
                dialog: () => <BidderRegistrationDialog />
            })}
        />
    )
}

export default BidderRegistrationDialog