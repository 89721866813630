import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import { ContactCreditRemovalButton } from 'app/areas/contacts/components/buttons/ContactButtons';
import ContactCreditDialog from 'app/areas/contacts/dialogs/credits/ContactCreditDialog';
import { useContactCredit } from 'app/data/operation/contacts/ContactData';
import React from 'react'

export type ContactCreditActionMenuProps = {
    contactId: number,
    creditId: number
}

const ContactCreditActionMenu = (props: ContactCreditActionMenuProps) => {
    let credit = useContactCredit(props.contactId, props.creditId);

    return (
        <AgTechVerticalPopUpMenu>
            <AgTechVerticalPopUpMenuSection header="Credit">
                <ContactCreditDialog.Button contactId={props.contactId} credit={credit} />
                <ContactCreditRemovalButton contactId={props.contactId} creditId={credit.id} />
            </AgTechVerticalPopUpMenuSection>
        </AgTechVerticalPopUpMenu>
    )
};

export default ContactCreditActionMenu;