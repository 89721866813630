import { Link } from "react-router-dom";

export type AgTechSideNavigationMenuItemProps = {
    text: string,
    icon?: string,
    link: string,
    isCollapsed: boolean,
    onSelected: (link: string) => void
}

const AgTechSideNavigationMenuItem = (props: AgTechSideNavigationMenuItemProps) => {
    let activeClass = window.location.pathname === props.link ? 'active' : '';
    let collapsedClass = props.isCollapsed ? 'collapsed' : '';

    return (
        <Link
            to={`${props.link}`}
            className={`agtech-side-navigation-item ${activeClass} ${collapsedClass}`}
            onClick={() => props.onSelected(props.link)}
        >
            {props.icon ? <i className={`agtech-side-navigation-item-icon fas fa-${props.icon}`} aria-hidden="true"></i> : null}
            {props.isCollapsed ? null : props.text}
        </Link>
    )
}

export default AgTechSideNavigationMenuItem;