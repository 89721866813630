import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechContactActionButton } from 'app/areas/contacts/components/buttons/ContactButtons';
import ContactRegisterBidderDialog from 'app/areas/contacts/dialogs/ContactRegisterBidderDialog';
import { AgTechContact, DefaultAgTechContact } from 'app/data/operation/contacts/ContactData';
import { SaleBidderContact } from 'app/data/bidders/BidderData';
import React, { useState } from 'react'

export type ContactSelectionGridProps = {
    bidderNumber?: string,
    contacts: SaleBidderContact[],
    gridProps?: (props: AgTechGridProps<SaleBidderContact>) => AgTechGridProps<SaleBidderContact>,
    onContactSelected: (contact: AgTechContact) => Promise<void>
}

const ContactSelectionGrid = (props: ContactSelectionGridProps) => {
    let [selectedContact, setSelectedContact] = useState<SaleBidderContact>({
        ...DefaultAgTechContact,
        bidderNumber: '',
        bidderId: 0
    });

    let contactGridProps: AgTechGridProps<SaleBidderContact> = {
        header: {
            title: "Operation Contacts",
            content: () => {
                return (
                    <ContactRegisterBidderDialog.Button
                        bidderNumber={props.bidderNumber}
                        onContactRegistered={async bidderRegistration => {
                            await props.onContactSelected(bidderRegistration);
                            setSelectedContact({ ...bidderRegistration, bidderId: 0});
                        }}
                    />
                )
            }
        },
        items: props.contacts,        
        searchConfig: {
            options: [
                { field: 'Name', value: contact => contact.firstName + ' ' + contact.lastName },
                { field: 'Ranch name', value: contact => contact.ranchName },
                { field: 'Email', value: contact => contact.emailAddress },
                { field: 'Phone Number', value: contact => contact.phoneNumber }
            ]
        },
        columns: [
            {
                header: 'Bidder Number',
                value: contact => contact.bidderNumber,
                classes: 'emphasized',
                width: '120px'
            },
            {
                header: 'Name',
                value: contact => contact.firstName + ' ' + contact.lastName
            },
            {
                header: 'Ranch name',
                value: contact => contact.ranchName
            },
            {
                header: 'Email',
                value: contact => contact.emailAddress,
            },
            {
                header: 'Address',
                value: contact => `${contact.address}, ${contact.city} ${contact.stateAbbreviation ?? ''} ${contact.zip}`
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.firstName === curr.firstName &&
                   prev.lastName === curr.lastName &&
                   prev.ranchName === curr.ranchName &&
                   prev.emailAddress === curr.emailAddress &&
                   prev.address === curr.address &&
                   prev.city === curr.city &&
                   prev.stateAbbreviation === curr.stateAbbreviation &&
                   prev.zip === curr.zip &&
                   prev.bidderNumber === curr.bidderNumber;
        },
        selectionConfig: {
            isItemSelected: contact => contact.id === selectedContact.id,
            onItemSelected: contact => new Promise<SaleBidderContact>((res, rej) => {
                props.onContactSelected(contact)
                    .then(r => {
                        setSelectedContact(contact);
                        res(contact);
                    });
            }),
            onItemDeSelected: contact => new Promise<SaleBidderContact>((res, rej) => {
                res(contact);
            }),
            allowMultipleSelections: false
        },
        actions: {
            rendering: contact => <AgTechContactActionButton contactId={contact.id} />
        }
    };

    if (props.gridProps) {
        contactGridProps = props.gridProps(contactGridProps);
    }

    return <AgTechGrid {...contactGridProps} />
}

export default React.memo(ContactSelectionGrid, (prev, curr) => prev.contacts.length === curr.contacts.length);