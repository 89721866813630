import { AgTechFileUploadButton } from "agtech/web/components/Buttons/AgTechButtons";
import AgTechTabbedPage from "agtech/web/components/Pages/Tabbed/AgTechTabPage";
import { AgTechStandardModal } from "agtech/web/components/Portals/Modals/AgTechModal";
import { useFailedImportResultsTabs } from "agtech/web/components/Portals/Modals/Imports/Common/AgTechImportHelpers";
import { AgTechImportModalResultViewProps } from "agtech/web/components/Portals/Modals/Imports/Common/AgTechImportTypes";

const AgTechFailedImportResultsView = <TImportItem, >(props: AgTechImportModalResultViewProps<TImportItem>) => {
    let failedImportResultsTabs = useFailedImportResultsTabs(props);
    
    return (
        <AgTechStandardModal
            title={`${props.terminology.importEntitySingular} Import Results & Errors`}
            details='Below are the import errors found while importing the selected file. To see why each error occurred, hover over the warning symbol on the error.'
            body={() => <AgTechTabbedPage {...failedImportResultsTabs} />}
            footer={() => (
                <div className='column-based wide container'>
                    <h1 className='font-size-medium font-bold'>
                        When all import errors have been addressed, please import your file again
                    </h1>
                    <div className='vertically-centered ml-auto container'>
                        <AgTechFileUploadButton
                            text='Upload New File'
                            icon='upload'
                            onFileUploaded={props.onImportAgain}
                            classes='highlight py-2 px-5'
                        />
                    </div>
                </div>
            )}
        />
    )
}

export default AgTechFailedImportResultsView;