import React, { createContext, useContext, useState } from 'react'
import { AgTechAppContext, DefaultAgTechAppContext, IAgTechAppContext } from 'agtech/core/app/AgTechAppContext';
import { AgTechWebApplication } from 'agtech/web/application/AgTechWebApplication';
import AgTechToastSurface, { AgTechToastManager, DefaultAgTechToastManager, useToasts as useAgTechWebToasts } from './Functions/AgTechToasts';
import AgTechModalSurface, { AgTechModalManager, DefaultAgTechModalManager, useModals as useAgTechWebModals } from './Functions/AgTechModals';
import AgTechDialogSurface, { AgTechDialogManager, DefaultAgTechDialogManager, useDialogs as useAgTechWebDialogs } from './Functions/AgTechDialogs';
import AgTechPopUpSurface, { AgTechPopUpManager, DefaultAgTechPopUpManager, usePopups as useAgTechWebPopUps } from './Functions/AgTechPopUps';
import { AgTechNavigationManager, DefaultAgTechNavigationManager, useAgTechWebNavigation } from './Functions/AgTechNavigation';
import { useAgTechWebDataLoader } from './Functions/AgTechLoaders';

export type AgTechWebAppContext = IAgTechAppContext & {
    toasts: AgTechToastManager,
    modals: AgTechModalManager,
    dialogs: AgTechDialogManager,
    popups: AgTechPopUpManager,
    navigation: AgTechNavigationManager,
};

export const WebAppContext = createContext<AgTechWebAppContext>({
    ...DefaultAgTechAppContext,
    toasts: DefaultAgTechToastManager,
    modals: DefaultAgTechModalManager,
    dialogs: DefaultAgTechDialogManager,
    popups: DefaultAgTechPopUpManager,
    navigation: DefaultAgTechNavigationManager,
});

export type AgTechAppContextProps = {
    app: AgTechWebApplication
}

let AgTechWebAppContext = (props: React.PropsWithChildren<AgTechAppContextProps>) => {
    let toastManager = useAgTechWebToasts();
    let modalManager = useAgTechWebModals();
    let dialogManager = useAgTechWebDialogs();
    let popupManager = useAgTechWebPopUps();
    let navigationManager = useAgTechWebNavigation();
    let loadingManager = useAgTechWebDataLoader();

    let [appContext, updateAppContext] = useState<AgTechWebAppContext>({
        toasts: toastManager,
        modals: modalManager,
        dialogs: dialogManager,
        popups: popupManager,
        navigation: navigationManager,
        messaging: toastManager,
        confirmations: modalManager,
        loading: loadingManager,
    });

    return (
        <AgTechAppContext.Provider value={appContext}>
            <WebAppContext.Provider value={appContext}>
                {props.children}
                <AgTechModalSurface modalManager={modalManager} />
                <AgTechDialogSurface dialogManager={dialogManager} />
                <AgTechToastSurface toastManager={toastManager} />
                <AgTechPopUpSurface popupManager={popupManager} />
            </WebAppContext.Provider>
        </AgTechAppContext.Provider>
    )
};

export const useAgTechWebAppContext = () => useContext(WebAppContext);

export default React.memo(AgTechWebAppContext);