import React from 'react'
import SaleInvoicingUnprocessedLotsPage from 'app/areas/invoices/pages/SaleInvoicingUnprocessedLotsPage';
import SaleInvoicingProcessedLotsPage from 'app/areas/invoices/pages/SaleInvoicingProcessedLotsPage';
import AgTechTabbedPage, { AgTechTabbedPageProps, AgTechTabPageStyle } from 'agtech/web/components/Pages/Tabbed/AgTechTabPage';
import SaleInvoicingCompletedInvoicesPage from './SaleInvoiceCompletedInvoicesPage';

const SaleInvoicingOverviewPage = () => {

    let invoiceOverviewTabbedPageProps: AgTechTabbedPageProps = {
        tabs: [
            {
                text: "Unprocessed Sales",
                content: () => <SaleInvoicingUnprocessedLotsPage />
            },
            {
                text: "Unpaid Invoices",
                content: () => <SaleInvoicingProcessedLotsPage />
            },
            {
                text: "Paid Invoices",
                content: () => <SaleInvoicingCompletedInvoicesPage />
            }
        ]
    }
    
    return (
        <AgTechTabbedPage
            {...invoiceOverviewTabbedPageProps}
            style={AgTechTabPageStyle.BadgeUnderline}
        />
    )
}

export default SaleInvoicingOverviewPage;