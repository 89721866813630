import AgTechDataProvider from 'agtech/core/data/providers/AgTechDataProvider';
import { AgTechBadge } from 'agtech/web/components/Badges/AgTechBadges';
import AgTechActionPane from 'agtech/web/components/Headers/AgTechActionPane';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import AgTechListPopUp from 'agtech/web/components/Portals/Popups/AgTechListPopUp';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import { LotActionButton } from 'app/areas/lots/components/buttons/SaleLotButtons';
import LotSplitDialog from 'app/areas/lots/dialogs/LotSplitDialog';
import { useSaleBidderData } from 'app/data/bidders/BidderData';
import { useSaleLot } from 'app/data/lots/LotData';
import { LotSplitSummary, useLotDataGetLotSplitSummaryRequest } from 'app/data/lots/LotDataActions';
import { getContactFullName, useContactData } from 'app/data/operation/contacts/ContactData';
import React from 'react'

const LotSplitPopUp = (props: { lotId: number }) => {
    let lot = useSaleLot(props.lotId);
    let contactData = useContactData();
    let bidderData = useSaleBidderData();
    let lotSplitSummaryRequest = useLotDataGetLotSplitSummaryRequest();
    
    return (
        <div className='row-based container' style={{ height: 230, width: 420 }}>
            <AgTechDataProvider
                loadData={async executor => await executor.executeRequest(lotSplitSummaryRequest, {
                    lotId: props.lotId,
                })}
                refresh={{
                    refreshOnAllRerenders: true
                }}
            >
                {(lotSplitSummary: LotSplitSummary) => (
                    <AgTechListPopUp
                        header={{
                            title: `Splits - Lot #${lot.lotNumber}`,
                            content: () => (
                                <LotActionButton lotId={props.lotId} />
                            ),
                            contentClasses: 'ml-auto'
                        }}
                        maxItemsToShow={4}
                        list={{
                            items: lotSplitSummary.splits.map(s => ({
                                ...s,
                                id: s.contactId
                            })),
                            itemRow: split => {
                                let contact = contactData.contacts[split.contactId];
                                let bidder = split.bidderId ? bidderData.bidderIdsMappedToBidders[split.bidderId] : undefined;
                                let splitAmount = AgTechMoneyFormatter.format((lot.purchasePrice ?? 0) * split.percentage);

                                return (
                                    <div className='row-based-vertically-centered p-3 stretched non-scrollable container'>
                                        <AgTechTitleDetailHeader
                                            title={getContactFullName(contact)}
                                            detail={bidder ? bidder.bidderNumber ? `Bidder #${bidder.bidderNumber}` : undefined : undefined}
                                            icon='bi bi-person-fill'
                                            iconSize={24}
                                            content={() => (
                                                <AgTechActionPane
                                                    elements={[
                                                        <AgTechBadge text={`${split.percentage * 100}%`} />,
                                                        <h1 className='font-size-small font-bold mr-1'>{splitAmount}</h1>
                                                    ]}
                                                    
                                                />
                                            )}
                                        />
                                    </div>
                                )
                            },
                            itemHeight: 64
                        }}
                        footer={() => (
                            <div className='wide pr-2 column-based-vertically-centered container'>
                                <LotSplitDialog.Button
                                    lotId={props.lotId}
                                    classes='thin link'
                                    text='Update Splits'
                                    icon='edit'
                                />
                                <h1 className='font-bold font-size-standard ml-auto'>{AgTechMoneyFormatter.format(lot.purchasePrice ?? 0)}</h1>
                            </div>
                        )}
                    />
                )}
            </AgTechDataProvider>
        </div>
    )
}

export default LotSplitPopUp