import { AgTechFormContext } from "agtech/core/forms/AgTechFormContext";
import AgTechGrid from "agtech/web/components/Grids/AgTechGrid";
import { AgTechGridHeaderConfiguration } from "agtech/web/components/Grids/AgTechGridHeader";
import { AgTechGridProps } from "agtech/web/components/Grids/Data/AgTechGridProps";
import { SelectableCreditsData } from "../../dialogs/InvoiceChangeContactCreditsDialog";
import { ContactCredit } from "app/data/operation/contacts/credits/ContactDataCredits";
import { AgTechMoneyFormatter } from "agtech/web/utilities/AgTechUtilities";
import dayjs from "dayjs";
import { AgTechClickableIcon } from "agtech/web/components/Icons/AgTechIcons";
import ContactCreditDialog from "app/areas/contacts/dialogs/credits/ContactCreditDialog";
import { AgTechEntitySelectionPreservedOriginal } from "agtech/web/components/Grids/AgTechGridEntities";

export type InvoiceChangeCreditsGridProps = {
    formContext: AgTechFormContext<SelectableCreditsData>,
    header?: AgTechGridHeaderConfiguration
}

const InvoiceChangeCreditsGrid = (props: InvoiceChangeCreditsGridProps) => {
    let invoiceCreditSelectionGridConfiguration: AgTechGridProps<AgTechEntitySelectionPreservedOriginal<ContactCredit>> = {
        header: props.header,
        items: props.formContext.entity.Credits,
        columns: [
            {
                header: 'Amount',
                value: c => AgTechMoneyFormatter.format(c.entity.creditAmount),
            },
            {
                header: 'Description',
                value: c => c.entity.description,
                size: 2
            },
            {
                header: 'Expires',
                value: c => c.entity.expires ? dayjs(c.entity.expires).format('MM/DD/YYYY') : "N/A",
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.entity.creditAmount === curr.entity.creditAmount &&
                   prev.entity.description === curr.entity.description &&
                   prev.entity.expires === curr.entity.expires;
        },
        selectionConfig: {
            isItemSelected: credit => credit.isSelected,
            onItemSelected: async (credit) => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    Credits: [...curr.Credits.map(formCredit => formCredit.id === credit.entity.id
                        ? { ...formCredit, isSelected: true }
                        : { ...formCredit }
                    )]
                }));
            },
            onItemDeSelected: async credit => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    Credits: [...curr.Credits.map(formCredit => formCredit.id === credit.entity.id
                        ? { ...formCredit, isSelected: false }
                        : { ...formCredit }
                    )]
                }));
            }
        },
        actions: {
            rendering: credit => {
                return (
                    <AgTechClickableIcon
                        icon='bi bi-pencil-square'
                        tooltip="Update credit"
                        onClick={e => ContactCreditDialog.Open(e, {
                            contactId: credit.entity.contactId,
                            credit: credit.entity
                        })}
                    />
                )
            }
        }
    };

    return <AgTechGrid {...invoiceCreditSelectionGridConfiguration} />
}

export default InvoiceChangeCreditsGrid;