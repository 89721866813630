import { useState } from "react"

/// Types

export type AgTechNavigationRoute = {
    area: string,
    workspace: string
}

const DefaultAgTechNavigationRoute = {
    area: '',
    workspace: ''
}

/// Manager

export type AgTechNavigationManager = {
    currentRoute: AgTechNavigationRoute,
    goToWorkspace: (workspace: AgTechNavigationRoute) => void
}

export const DefaultAgTechNavigationManager: AgTechNavigationManager = {
    currentRoute: DefaultAgTechNavigationRoute,
    goToWorkspace: () => {
        console.error('Attempted to navigate to a workspace on the default app context');
    }
}

export const useAgTechWebNavigation = (): AgTechNavigationManager => {
    let [currentRoute, setCurrentRoute] = useState<AgTechNavigationRoute>(DefaultAgTechNavigationRoute);

    return {
        currentRoute,
        goToWorkspace: route => setCurrentRoute(route)
    }
}
