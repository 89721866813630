import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { SalesAppState } from 'app/data/SalesAppDataStore';
import { AgTechOperation, DefaultAgTechOperation } from 'agtech/core/data/AgTechOperationData';

/// Operation Entities

export type SaleOperation = AgTechOperation & {
    documentHeaderConfiguration: DocumentHeaderConfiguration,
    invoiceDocumentConfiguration: InvoiceDocumentConfiguration,
    needsSetup: boolean,
    address?: string,
    city?: string,
    zip?: string,
    stateAbbreviation?: string
}

export type DocumentHeaderConfiguration = {
    id: number,
    preAddressLine?: string,
    addressOverride?: string,
    addressCityOverride?: string,
    addressStateOverride?: string,
    addressZipOverride?: string,
    postAddressLine?: string
}

export const DefaultDocumentHeaderConfiguration: DocumentHeaderConfiguration = {
    id: 0,
    preAddressLine: "",
    addressOverride: "",
    addressCityOverride: "",
    addressStateOverride: "",
    addressZipOverride: "",
    postAddressLine: ""
}

export type InvoiceDocumentConfiguration = {
    id: number,
    itemNumberText?: string,
    descriptionText?: string,
    purchasePriceText?: string,
    checksPayableText?: string,
    thanksText?: string
}

export const DefaultInvoiceDocumentConfiguration: InvoiceDocumentConfiguration = {
    id: 0,
    itemNumberText: "",
    descriptionText: "",
    purchasePriceText: "",
    checksPayableText: "",
    thanksText: ""
}

export const DefaultSaleOperation: SaleOperation = {
    ...DefaultAgTechOperation,
    documentHeaderConfiguration: DefaultDocumentHeaderConfiguration,
    invoiceDocumentConfiguration: DefaultInvoiceDocumentConfiguration,
    needsSetup: false
}

/// Operation Slices

export const operationDataSlice = createSlice({
    name: 'operation',
    initialState: DefaultSaleOperation,
    reducers: {
        setOperation: (state, action: PayloadAction<SaleOperation>) => {
            return {
                ...state,
                ...action.payload
            };
        },
        setupOperation: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                needsSetup: false
            };
        },
        setOperationLogo: (state, action: PayloadAction<string | undefined>) => {
            return {
                ...state,
                operationLogo: action.payload
            };
        },
        updateDocumentHeaderConfiguration: (state, action: PayloadAction<DocumentHeaderConfiguration>) => {
            state.documentHeaderConfiguration = {
                id: action.payload.id,
                preAddressLine: action.payload.preAddressLine,
                addressOverride: action.payload.addressOverride,
                addressCityOverride: action.payload.addressCityOverride,
                addressStateOverride: action.payload.addressStateOverride,
                addressZipOverride: action.payload.addressZipOverride,
                postAddressLine: action.payload.postAddressLine
            }
        },
        updateInvoiceDocumentConfiguration: (state, action: PayloadAction<InvoiceDocumentConfiguration>) => {
            state.invoiceDocumentConfiguration = {
                id: action.payload.id,
                itemNumberText: action.payload.itemNumberText,
                descriptionText: action.payload.descriptionText,
                purchasePriceText: action.payload.purchasePriceText,
                checksPayableText: action.payload.checksPayableText,
                thanksText: action.payload.thanksText
            }
        }
    }
});

/// Operation Hooks

export const useOperationId = () => useOperationData().operationId;

export const useOperationData = (): SaleOperation => useSelector((state: SalesAppState) => state.salesOperation);

export const useOperationLogo = () => useOperationData().operationLogo;

export default operationDataSlice.reducer;