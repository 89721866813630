import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons'
import { AgTechTwoColumnCardCollection } from 'agtech/web/components/Cards/Collections/AgTechCardCollection'
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog'
import ReportCard from 'app/areas/reports/components/cards/ReportCard'
import { useSaleReports } from 'app/data/SaleDataReports'
import React from 'react'

const ReportsDialog = () => {
    let reports = useSaleReports();

    return (
        <AgTechViewDialog
            header={{
                title: 'My Reports',
                detail: 'Below is a list of reports available for the current sale. To download a report, click the "Download" button beneath the report description'
            }}
            width="800px"
            textWhileLoading='Loading reports...'
        >
            <AgTechTwoColumnCardCollection
                entities={reports}
                card={report => (
                    <ReportCard key={report.reportName} {...report} />
                )}
            />
        </AgTechViewDialog>
    )
}

ReportsDialog.ButtonProps = {
    text: 'Download Reports',
    icon: 'chart-area',
    action: (e: AgTechClickEventHandler) => e.dialogs.openDialog({
        dialog: () => <ReportsDialog />
    })
};

ReportsDialog.Button = (props: AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        ...ReportsDialog.ButtonProps,
        ...props
    }

    return <AgTechButton {...buttonProps} />
}

export default ReportsDialog