import { AgTechDataAction } from "agtech/core/data/actions/AgTechDataActions";
import { useAgTechHttpDeleteEntityRequest } from "agtech/core/data/http/AgTechApiDeleteRequests";
import { useAgTechHttpSubmitEntityRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { AgTechContact, contactDataSlice, useContactDetailContext } from "app/data/operation/contacts/ContactData";

/// Entities

export type ContactNote = {
    id: number,
    contactId: number,
    note: string,
    date: Date
}

export type ContactNoteIndex = {
    [id: number]: ContactNote
}

/// Submissions

export const useContactNoteDataWriteNoteAction = (contactId: number): AgTechDataAction<ContactNote, ContactNote> => {
    let contactDetailContext = useContactDetailContext();
    let writeNoteSubmissionRequest = useAgTechHttpSubmitEntityRequest<ContactNote>();

    return {
        name: 'WriteNote',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity?.id ? 'Updating note...' : 'Creating note...',
            actionConfirmationMessage: `Successfully ${props.originalEntity?.id ? 'updated' : 'created'} note`,
            showConfirmationMessage: true,
            actionConfirmationDelay: 500
        }),
        validate: async props => {
            props.validation.failWithWarningIf(!props.submittedEntity.note, 'Please specify content for this note.');
        },
        action: async props => {
            return await writeNoteSubmissionRequest.post({
                path: 'ContactNote',
                postedData: {
                    ...props.submittedEntity,
                    contactId: contactId
                }
            });
        },
        onSuccess: async props => {
            let writtenNote = props.responseData;
            writtenNote.date = new Date(writtenNote.date);

            props.executeReducerAction(contactDataSlice.actions.writeNote, writtenNote);

            if (contactDetailContext) {
                let updatedNotes = props.submittedEntity.id
                    ? contactDetailContext.contactDetails.notes.map(n => n.id === props.submittedEntity.id ? props.submittedEntity : n)
                    : [...contactDetailContext.contactDetails.notes, props.submittedEntity];

                contactDetailContext.updateContactDetails({
                    ...contactDetailContext.contactDetails,
                    notes: updatedNotes
                });
            }
        }
    }
}

export const useContactNoteDataDeleteNoteAction = (): AgTechDataAction<ContactNote, boolean> => {
    let contactDetailContext = useContactDetailContext();
    let contactNoteDeleteRequest = useAgTechHttpDeleteEntityRequest();

    return {
        name: 'DeleteNote',
        getConfiguration: () => ({
            actionExecutionMessage: 'Deleting note...',
            actionConfirmationMessage: `Successfully deleted note`,
            showConfirmationMessage: true,
            actionConfirmationDelay: 300
        }),
        confirm: async props => {
            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: 'Are you sure you want to delete this note?'
                },
                actions: {
                    onConfirm: async () => true,
                    onCancel: async () => false
                }
            })
        },
        action: async props => {
            return await contactNoteDeleteRequest.deleteEntity({
                controller: 'ContactNote',
                entityId: props.submittedEntity.id,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(contactDataSlice.actions.deleteNote, props.submittedEntity);

            if (contactDetailContext) {
                contactDetailContext.updateContactDetails({
                    ...contactDetailContext.contactDetails,
                    notes: contactDetailContext.contactDetails.notes.filter(n => n.id !== props.submittedEntity.id)
                });
            }
        }
    }
}