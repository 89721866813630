import { AgTechWebApplication } from 'agtech/web/application/AgTechWebApplication';
import React, { useState } from 'react'
import AgTechSideNavigationMenuItem from 'agtech/web/components/App/Navigation/Controls/AgTechSideNavigationMenuItem';
import AgTechSideNavigationSubMenu from 'agtech/web/components/App/Navigation/Controls/AgTechSideNavigationSubMenu';
import AgTechSideNavigationMenu from 'agtech/web/components/App/Navigation/Controls/AgTechSideNavigationMenu';

const AgTechStandardSidebarNavigation = (props: { app: AgTechWebApplication }) => {
    let [currentPath, setCurrentPath] = useState('/');
    
    return (
        <AgTechSideNavigationMenu
            app={props.app}
            content={isCollapsed => (
                <>
                    <AgTechSideNavigationMenuItem
                        text='Home'
                        icon='home'
                        link='/'
                        isCollapsed={isCollapsed}
                        onSelected={link => setCurrentPath(link)}
                    />
                    { props.app.structure.areas.map(area => (
                        <AgTechSideNavigationSubMenu
                            key={area.text}
                            text={area.text}
                            icon={area.icon}
                            isCollapsed={isCollapsed}
                            menuItems={area.workspaces.map(workspace => {
                                let workspacePath = `/${area.name}/${workspace.name}`;

                                return {
                                    text: workspace.name,
                                    link: workspacePath,
                                    isCollapsed: isCollapsed,
                                    onSelected: link => setCurrentPath(link)
                                };
                            })}
                        />
                    ))}
                </>
            )}   
        />
    )
}

export default AgTechStandardSidebarNavigation;