import { WebAppContext } from 'agtech/web/components/App/AgTechWebAppContext';
import AgTechOptions from 'agtech/web/components/Menus/AgTechOptions';
import { AgTechVerticalPopUpMenu, AgTechVerticalPopUpMenuSection } from 'agtech/web/components/Menus/AgTechVerticalPopUpMenu';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'

export type AgTechSearchOption<TItem> = {
    field: string,
    value: (item: TItem) => string
}

export type AgTechCoreSearchControlProps<TItem> = {
    placeholder: string,
    options: AgTechSearchOption<TItem>[],
    width?: number,
    styles?: CSSProperties,
    classes?: string
}

export type AgTechSearchControlProps<TItem> = AgTechCoreSearchControlProps<TItem> & {
    onUpdateSearchState: (searchState: AgTechSearchControlState<TItem>) => void
}

export type AgTechSearchControlState<TItem> = {
    filter: string,
    selectedField: AgTechSearchOption<TItem>
}

const AgTechSearchControl = <TItem, >(props: AgTechSearchControlProps<TItem>) => {
    let appContext = useContext(WebAppContext);
    
    let gridSearchInputRef = useRef<HTMLInputElement>(null);
    let gridSearchRef = useRef<HTMLDivElement>(null);

    let [searchState, updateSearchState] = useState<AgTechSearchControlState<TItem>>({
        filter: '',
        selectedField: props.options[0]
    });

    let searchFieldOptions = props.options.map(option => option.field);

    let handleSearchFieldSelected = (selectedField: string) => {
        updateSearchState({
            ...searchState,
            selectedField: props.options.find(option => option.field === selectedField) ?? props.options[0]
        });
    }

    useEffect(() => {
        handleSearch();
    }, [searchState.selectedField.field])

    let handleGridSearchFocus = () => {
        appContext.popups.showPopUp({
            popup: () => {
                return (
                    <AgTechVerticalPopUpMenu>
                        <AgTechVerticalPopUpMenuSection header="Search By:">
                            <AgTechOptions
                                selectedOption={searchState.selectedField.field}
                                options={searchFieldOptions}
                                onOptionSelected={handleSearchFieldSelected} />
                        </AgTechVerticalPopUpMenuSection>
                    </AgTechVerticalPopUpMenu>
                )
            },
            callerRef: gridSearchRef
        });
    }

    let handleSearch = () => {
        let searchTerm = gridSearchInputRef.current ? gridSearchInputRef.current.value : '';
        let searchField = searchState.selectedField;

        props.onUpdateSearchState({
            filter: searchTerm,
            selectedField: searchField
        });
    }

    let handleSearchKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    return (
        <div
            className={`tall rounded bordered bg-white column-based-vertically-centered non-scrollable container ${props.classes ?? ''}`}
            onFocus={handleGridSearchFocus}
            ref={gridSearchRef}
            style={{
                ...props.styles,
                minHeight: 30,
                minWidth: props.width ?? '100%',
                width: props.width ?? '100%'
            }}
        >
            <i
                className='fas fa-search tall font-size-very-small font-color-secondary px-2-half right-bordered centered container'
                onClick={handleSearch}
            />
            <input
                type="text"
                className="tall font-size-small non-bordered no-outline px-2 pb-half container"
                placeholder={props.placeholder}
                onKeyDown={handleSearchKeyDown}
                ref={gridSearchInputRef}
            />
        </div>
    )
}

export default AgTechSearchControl