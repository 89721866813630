import AgTechDataActionSurface from 'agtech/core/data/actions/AgTechDataActionSurfaces'
import AgTechDataProvider from 'agtech/core/data/providers/AgTechDataProvider'
import { AgTechFormActionProps, AgTechFormDataProps, AgTechFormSubmissionButton } from 'agtech/core/forms/AgTechFormProps'
import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons'
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import { useModalContext } from 'agtech/web/components/Portals/Modals/AgTechModal'
import React, { useRef } from 'react'

export type AgTechWizardModalProps<TFormData, TActionData, TResponseData> = {
    data: AgTechFormDataProps<TFormData>,
    completionAction: AgTechFormActionProps<TFormData, TActionData, TResponseData>,
    structure: AgTechWizardModalStructureProps<TFormData>,
    behavior?: AgTechWizardModalBehaviorProps
}

export type AgTechWizardModalStructureProps<TFormData> = {
    title: string,
    details?: string,
    steps: AgTechWizardModalStep<TFormData>[],
    submissionButtonText: string
}

export type AgTechWizardModalBehaviorProps = {
    allowCancel?: boolean
}

export type AgTechWizardModalStep<TFormData> = {
    name: string,
    page: (props: AgTechWizardModalStepProps<TFormData>) => React.ReactNode,
    behavior?: {
        controlsWhenMoveToNextStep?: boolean
    }
}

export type AgTechWizardModalStepProps<TFormData> = {
    formData: TFormData,
    updateFormData: (updatedFormData: TFormData) => void,
    nextButton: React.RefObject<AgTechFormSubmissionButton>,
    moveToNextStep: () => void,
}

const AgTechWizardModal = <TFormData, TActionData, TResponseData>(props: AgTechWizardModalProps<TFormData, TActionData, TResponseData>) => {
    return (
        <AgTechDataProvider
            loadData={props.data.loadInitialFormData}
            content={{
                loadingText: props.data.loadingText
            }}
            retry={props.data.retry ?? {
                retryButtonText: 'Try again'
            }}
        >
            {data => (
                <AgTechWizardModalSurface {...props} formData={data} />
            )}
        </AgTechDataProvider>
    )
}

const AgTechWizardModalSurface = <TFormData, TActionData, TResponseData>(
    props: AgTechWizardModalProps<TFormData, TActionData, TResponseData> & {
        formData: TFormData
    }
) => {
    let modalContext = useModalContext();

    let [currentStep, setCurrentStep] = React.useState(0);

    let nextButton = useRef<{ click: () => Promise<void> }>({
        click: async () => {}
    });

    let isOnLastStep = currentStep === props.structure.steps.length - 1;
    let formDataRef = useRef<TFormData>(props.formData);

    return (
        <AgTechDataActionSurface
            action={props.completionAction.dataAction}
            behavior={{
                eventHandlers: {
                    onExecutionCompleted: async () => {
                        modalContext.closeModal();
                    }
                }
            }}
        >
            {executor => (
                <div className='stretched bg-white bordered rounded row-based container'>
                    <div className='p-3 px-4 bottom-bordered rounded-top-inherit bg-light-gray container' style={{ height: 72 }}>
                        <AgTechTitleDetailHeader
                            title={props.structure.title}
                            titleFontSize='font-size-large'
                            detail={props.structure.details}
                            detailFontSize='font-size-small'
                            detailMargin={8}
                        />
                    </div>
                    <div className='column-based flexed container'>
                        <div className='row-based tall p-5 py-6 right-bordered container' style={{ width: 200 }}>
                            <div className='row-based tall container'>
                                {props.structure.steps.map((step, index) => {
                                    let isActiveStep = currentStep === index;
                                    let isCompletedStep = index < currentStep;
        
                                    let borderClasses = index === props.structure.steps.length - 1 ? '' : 'left-bordered';
                                    let activeClasses = isActiveStep ? 'bg-highlight' : isCompletedStep ? 'bg-green' : 'bg-gray';
        
                                    return  (
                                        <div key={step.name} className={`column-based ${borderClasses} container`} style={{ height: 56 }}>
                                            <div className={`${activeClasses} rounded-circle mr-3`} style={{ width: 12, height: 12, marginLeft: -6 }} />
                                            <h1 className='font-size-small font-bold' style={{ lineHeight: '12px' }}>{step.name}</h1>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='flexed row-based container'>
                            <div className='flexed row-based px-6 py-4 container'>
                                <AgTechWizardModalBody
                                    {...props}
                                    formData={formDataRef.current}
                                    updateFormData={u => {
                                        formDataRef.current = {
                                            ...u
                                        };
                                    }}
                                    currentStep={currentStep}
                                    nextButton={nextButton}
                                    moveToNextStep={() => setCurrentStep(currentStep + 1)}
                                />
                            </div>
                            <div className='top-bordered px-4 py-3 wide column-based container'>
                                <AgTechButton
                                    text='Back'
                                    icon='arrow-left'
                                    action={() => setCurrentStep(currentStep - 1)}
                                    disableIf={currentStep === 0}
                                />
                                <AgTechButton
                                    text={isOnLastStep ? props.structure.submissionButtonText : 'Next'}
                                    icon={isOnLastStep ? 'check' : 'arrow-right'}
                                    iconOnLeft={false}
                                    action={() => {
                                        if (isOnLastStep) {
                                            executor.executeAction(props.completionAction.dataAction, {
                                                originalEntity: props.completionAction.getActionData(props.formData),
                                                submittedEntity: props.completionAction.getActionData(formDataRef.current)
                                            })
                                        }
                                        else {
                                            let currentPage = props.structure.steps[currentStep];
        
                                            if (!currentPage.behavior?.controlsWhenMoveToNextStep) {
                                                setCurrentStep(currentStep + 1);
                                            }
                                        }
                                    }}
                                    classes='ml-3 highlight'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AgTechDataActionSurface>
    )
}

const AgTechWizardModalBody = <TFormData, TActionData, TResponseData>(
    props: AgTechWizardModalProps<TFormData, TActionData, TResponseData> & {
        formData: TFormData,
        updateFormData: (updatedFormData: TFormData) => void,
        currentStep: number,
        nextButton: React.RefObject<AgTechFormSubmissionButton>,
        moveToNextStep: () => void
    }
) => {
    let currentPage = props.structure.steps[props.currentStep];

    return (
        <div className='stretched fade-in-400 px-1 container'>
            {currentPage.page({
                formData: props.formData,
                updateFormData: props.updateFormData,
                nextButton: props.nextButton,
                moveToNextStep: props.moveToNextStep
            })}
        </div>
    )
}
export default AgTechWizardModal