import { useDispatch } from 'react-redux';
import { useAgTechHttpPostRequest, useAgTechHttpSubmitEntityRequest } from 'agtech/core/data/http/AgTechApiPostRequests';
import { AgTechHttpResponses } from 'agtech/core/data/actions/AgTechHttpRequests';
import { AgTechDataAction } from 'agtech/core/data/actions/AgTechDataActions';
import { SaleLotCategory, saleDataLotCategoriesSlice } from 'app/data/lots/categories/LotCategoryData';
import { getLots } from 'app/data/SalesAppDataStore';

/// Lot Category Actions

export const useLotCategoryDataRefreshLotCategoriesAction = (): AgTechDataAction<SaleLotCategory[], SaleLotCategory[]> => {
    return {
        name: 'RefreshLotCategories',
        action: async props => {
            return AgTechHttpResponses.Success(props.submittedEntity);
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDataLotCategoriesSlice.actions.refreshLotCategories, props.submittedEntity);
        }
    }
}

export const useLotCategoriesWriteCategoryAction = (): AgTechDataAction<SaleLotCategory, SaleLotCategory> => {
    let lotCategorySubmitRequest = useAgTechHttpSubmitEntityRequest<SaleLotCategory>();

    return {
        name: 'WriteLotCategory',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity?.id ? 'Updating lot category...' : 'Creating lot category...',
            actionConfirmationMessage: `Successfully ${props.originalEntity?.id ? 'updated' : 'created'} lot categry`
        }),
        action: async props => {
            return await lotCategorySubmitRequest.post({
                path: 'LotCategory',
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDataLotCategoriesSlice.actions.writeLotCategory, props.responseData);
        }
    }
}

export const useLotCategoriesDeleteCategoryAction = (): AgTechDataAction<SaleLotCategory, any> => {
    let deleteLotCategoryHttpRequest = useAgTechHttpPostRequest<SaleLotCategory, boolean>();

    return {
        name: 'DeleteLotCategory',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity?.id ? 'Deleting lot category...' : 'Deleting lot category...',
            actionConfirmationMessage: `Successfully deleted lot category`
        }),
        validate: async props => {
            let lotsWithCategory = getLots().filter(lot => lot.lotCategoryId === props.submittedEntity.id);

            props.validation.failWithWarningIf(
                lotsWithCategory && lotsWithCategory.length > 0,
                'The selected lot category is associated with one or more lots in this sale and cannot be deleted.'
            );
        },
        confirm: async props => {
            return props.appContext.confirmations.askForConfirmation({
                content: {
                    header: 'Are you sure you want to delete this lot category?',
                    details: 'All lots assigned to this category will have their category removed. This may impact reporting.'
                }
            });
        },
        action: async props => {
            return await deleteLotCategoryHttpRequest.post({
                path: 'LotCategory/Delete',
                postedData: props.submittedEntity
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(saleDataLotCategoriesSlice.actions.deleteLotCategory, props.submittedEntity);
            props.appContext.popups.closeAll();
        }
    }
}