import { AgTechApiEntityResponse, AgTechHttpResponse, AgTechHttpResponses } from "agtech/core/data/actions/AgTechHttpRequests"
import { AgTechHttpRequestProps, AgTechApiResponseHandlers, AgTechInternalAxiosRequestProps, executeAgTechApiRequest, useAgTechHttpRequestConfigurationBuilder, handleSuccessfulApiResponse } from "agtech/core/data/http/AgTechCoreApiRequests"
import axios from "axios"

export type AgTechApiPutRequestProps<TPostedData, TResponseData> = AgTechHttpRequestProps & AgTechApiResponseHandlers<TResponseData> & {
    postedData: TPostedData
}

export type AgTechPutRequest<TInput, TResponseData> = {
    put: (props: AgTechApiPutRequestProps<TInput, TResponseData>) => Promise<AgTechHttpResponse<TResponseData>>
}

export const useAgTechApiPutRequest = <TData, TResult>(): AgTechPutRequest<TData, TResult> => {
    let requestConfigurationBuilder = useAgTechHttpRequestConfigurationBuilder();

    return {
        put: async props => executeAgTechApiRequest({
            requestType: 'PUT',
            requestData: props.postedData,
            requestConfiguration: requestConfigurationBuilder(props),
            requestResponseHandlers: props,
            request: executePutRequestInternal
        })
    }
}

export const useAgTechApiPutAction = <TData>(): AgTechPutRequest<TData, any> => useAgTechApiPutRequest<TData, any>();

const executePutRequestInternal = <TInputData, TData>(props: AgTechInternalAxiosRequestProps<TInputData>) => {
    console.log('Executing PUT Request: ' + JSON.stringify({
        url: props.requestURL,
        data: props.requestData
    }, null, 2));

    return axios.put<TData>(props.requestURL, props.requestData, props.requestConfig);
}

export const useAgTechHttpPutEntityRequest = <TPostedEntity>(): AgTechPutRequest<TPostedEntity, TPostedEntity> => {
    let httpPutAndConvertRequest = useAgTechHttpPutAndConvertRequest<TPostedEntity, AgTechApiEntityResponse<TPostedEntity>, TPostedEntity>();

    return {
        put: async requestProps => httpPutAndConvertRequest.post({
            ...requestProps,
            convert: entityResponse => entityResponse.data
        })
    }
}

export type AgTechPutAndReturnRequestProps<TPutData, TResponseData, TResult> = AgTechApiPutRequestProps<TPutData, TResult> & {
    convert: (responseData: TResponseData) => TResult
}

export type AgTechPutAndReturnRequest<TPutData, TResponseData, TResult> = {
    post: (props: AgTechPutAndReturnRequestProps<TPutData, TResponseData, TResult>) => Promise<AgTechHttpResponse<TResult>>
}

export const useAgTechHttpPutAndConvertRequest = <TPostedData, TResponseData, TResult>(): AgTechPutAndReturnRequest<TPostedData, TResponseData, TResult> => {
    let requestConfigurationBuilder = useAgTechHttpRequestConfigurationBuilder();

    return {
        post: async props => {
            let requestResponse = AgTechHttpResponses.Failed('An error occurred');
            let requestConfiguration = requestConfigurationBuilder(props);

            let initialRequestProps: AgTechApiPutRequestProps<TPostedData, TResponseData> = {
                path: props.path,
                postedData: props.postedData,
                onSuccess: response => AgTechHttpResponses.Success(response.body),
                onError: failure => AgTechHttpResponses.Failed(failure.error)
            };

            let initialDataPutResponse = await executeAgTechApiRequest<TPostedData, TResponseData>({
                requestType: 'PUT',
                requestData: initialRequestProps.postedData,
                requestConfiguration: requestConfiguration,
                requestResponseHandlers: initialRequestProps,
                request: executePutRequestInternal
            });

            if (initialDataPutResponse.success) {
                let initialData = initialDataPutResponse.success.data;
                let convertedData = props.convert(initialData);

                requestResponse = await handleSuccessfulApiResponse({
                    ...requestConfiguration,
                    response: { wasSuccessful: true, data: convertedData },
                    handlers: props
                });
            }

            return requestResponse;
        }
    }
}