import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from "agtech/web/components/Buttons/AgTechButtons";
import { AgTechFormNumberControl, AgTechFormRow, AgTechFormTextControl } from "agtech/web/components/Forms/AgTechForm";
import { AgTechSelectionFormControl } from "agtech/web/components/Forms/Controls/Selection/AgTechFormSelectionControl";
import AgTechTitleDetailHeader from "agtech/web/components/Headers/AgTechTitleDetailHeader";
import AgTechFormDialog from "agtech/web/components/Portals/Dialogs/AgTechFormDialog";
import ContactSelectionControl from "app/areas/contacts/components/selections/ContactSelectionControl";
import { Payment, PaymentTypes } from "app/data/invoicing/InvoicingData";
import { usePaymentDataEditPaymentAction } from "app/data/invoicing/InvoicingDataActions";
import { useContacts } from "app/data/operation/contacts/ContactData";

export type EditPaymentDialogProps = {
    payment: Payment
}

const EditPaymentDialog = (props: EditPaymentDialogProps) => {
    let contacts = useContacts();
    const writePaymentAction = usePaymentDataEditPaymentAction();
    
    return (
        <AgTechFormDialog
            formData={{
                loadInitialFormData: () => props.payment
            }} 
            formAction={{
                dataAction: writePaymentAction,
                getActionData: d => d
            }}
            structure={{
                header: 'Edit Payment',
                submitButtonText: 'Save',
                width: 600
            }}
         >
            {(formContext) => (
                <>
                    <AgTechFormRow>
                        <ContactSelectionControl
                            contacts={contacts}
                            entityType={formContext.entity}
                            caption={'Payer'}
                            getSelectedContactId={ent => ent.payerId}
                            onContactSelected={async (ent, val) => {                       
                                ent.payerId = val?.id ?? 0;

                                return ent;
                            }}
                            isRequired={true}
                            width={65}
                        />
                        <AgTechFormNumberControl
                            entityType={formContext.entity}
                            caption="Amount"
                            required={true}
                            value={entity => entity.amount}
                            onControlValueChanged={async (e, val) => {
                                e.amount = val;
                                return e;
                            }}
                            width={35}
                            unit="$"
                        />
                    </AgTechFormRow>
                    <AgTechFormRow>
                        <AgTechSelectionFormControl
                            entityType={formContext.entity}
                            control={{caption: 'Payment Type'}}
                            selection={{
                                selectionControlTitle: 'Payment Type',
                                items: PaymentTypes,
                                itemText: e => e.description,
                                getSelectedItemId: e => e.paymentType.toString(),
                                onItemSelected: async (e, v) => {
                                    e.paymentType = v?.id ?? 0;
                                    return e;
                                },
                                itemHeight: 60,
                                itemRendering: e => (
                                    <AgTechTitleDetailHeader
                                        title={e.description}
                                        titleFontSize='font-size-small'
                                        detailFontSize='font-size-very-small'
                                        detailClasses='mt-1-half'
                                        />
                                ),
                                filterItems: (item, filter) => (item.description).includes(filter),
                                styles: { height: 240, width: 360 }
                            }} />
                            <AgTechFormTextControl
                                entityType={formContext.entity}
                                caption="Details"
                                required={false}
                                value={entity => entity.details}
                                onControlValueChanged={async (e, val) => {
                                    e.details = val;

                                    return e;
                                }}
                            />
                    </AgTechFormRow>
                </>
            )}
        </AgTechFormDialog>
    )
}

EditPaymentDialog.Open = (e: AgTechClickEventHandler, props: EditPaymentDialogProps) => e.dialogs.openDialog({
    dialog: () => <EditPaymentDialog {...props} />
});

EditPaymentDialog.Button = (props: AgTechButtonProps & EditPaymentDialogProps) => {
    let buttonProps: AgTechButtonProps = {
        text: 'Edit Payment',
        bootstrapIcon: 'bi-credit-card-2-front-fill',
        ...props,
        action: e => EditPaymentDialog.Open(e, props)
    }

    return <AgTechButton {...buttonProps} />
}

export default EditPaymentDialog;