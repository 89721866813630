import { DataActionContext, AgTechDataActionContext } from 'agtech/core/data/actions/AgTechDataActions'
import { AgTechClickEventHandler, useClickable } from 'agtech/web/components/Buttons/AgTechButtons'
import { useAgTechTooltip } from 'agtech/web/components/Portals/Popups/AgTechTooltipContainer'
import React, { CSSProperties, useRef, useState } from 'react'

export const AgTechSuccessIcon = (props: { classes?: string }) => {
    return (
        <i className={`agtech-icon fas fa-check ${props.classes ?? ''}`} style={{color: 'green'}} />
    )
}

export const AgTechSuccessCircleIcon = (props: { size?: number, classes?: string }) => {
    return (
        <i
            className={`agtech-icon fas fa-check font-color-white font-size-large bg-green centered rounded-circle container ${props.classes ?? ''}`}
            style={{
                height: props.size ?? 36,
                width: props.size ?? 36,
            }}
        />
    )
}

export const AgTechGeneralIcon = () => {
    return (
        <i className="agtech-icon fas fa-circle font-size-very-small font-color-border" />
    )
}

export const AgTechWarningIcon = (props: { classes?: string, style?: CSSProperties }) => {
    return (
        <i className={`agtech-icon fas fa-exclamation-triangle ${props.classes ?? ''}`} style={{ color: 'goldenrod', ...props.style }} />
    )
}

export const AgTechCreatedIcon = () => {
    return (
        <i className="agtech-icon fas fa-plus" style={{color: 'green'}} />
    )
}

export const AgTechLoadingIcon = () => <i className="agtech-icon fas fa-spinner font-color-secondary" />

export const AgTechUpdatedIcon = () => {
    return (
        <i className="agtech-icon fas fa-pen font-color-highlight" />
    )
}

export const AgTechErrorIcon = (props: { size?: number, classes?: string }) => {
    let iconSize = props.size ?? 36;

    return (
        <div className={`centered bg-dark-red container ${props.classes}`} style={{ height: iconSize, width: iconSize, borderRadius: '50%' }}>
            <i className='bi bi-exclamation font-color-white centered container' style={{ fontSize: iconSize * .8 }}/>
        </div>
    )
}

export type AgTechIconBadgeProps = {
    icon: string,
    action?: () => Promise<void>,
    classes?: string,
    styles?: React.CSSProperties,
    size?: number,
    rounded?: boolean
}

export const AgTechIconBadge = (props: AgTechIconBadgeProps) => {
    let badgeStyles: React.CSSProperties = props.styles ?? {};

    if (props.size) {
        badgeStyles.height = props.size;
        badgeStyles.maxHeight = props.size;
        badgeStyles.width = props.size;
        badgeStyles.maxWidth = props.size;
        badgeStyles.minWidth = props.size;
    }

    if (props.rounded ?? false) {
        badgeStyles.borderRadius = badgeStyles.height;
    }

    return props.action ? (
        <AgTechClickableIconBadge
            {...props}
            styles={badgeStyles}
            action={props.action}
        />
    ): (
        <i
            className={`agtech-icon-badge fas fa-${props.icon} ${props.classes}`}
            style={badgeStyles}
        />
    )
}

export type AgTechClickableIconBadgeProps = {
    icon: string,
    action: () => Promise<void>,
    classes?: string,
    styles?: React.CSSProperties
}

const AgTechClickableIconBadge = (props: AgTechClickableIconBadgeProps) => {
    let [isSubmitting, setIsSubmitting] = useState(false);

    const onClicked = async () => {
        setIsSubmitting(true);

        await props.action();

        setIsSubmitting(false);
    }

    return isSubmitting ? (
        <div
            className="loader"
            style={{
                height: 16,
                width: 16,
                borderWidth: 2
            }}
        />
    ) : (
        <i
            className={`agtech-icon-badge fas fa-${props.icon} ${props.classes} clickable`}
            style={props.styles}
            onClick={onClicked}
        />
    );
}

export const AgTechValueBadge = ({ value }: { value: string }) => {
    return (
        <div className='agtech-icon-badge'>
            {value}
        </div>
    )
}

export type AgTechClickableIconProps = {
    icon: string,
    tooltip?: string
    onClick: (e: AgTechClickEventHandler) => void,
    classes?: string,
    styles?: React.CSSProperties
}

export const AgTechClickableIcon = (props: AgTechClickableIconProps) => {
    let iconRef = useRef<HTMLElement>(null);
    let iconClickable = useClickable(iconRef);

    useAgTechTooltip<HTMLElement>({
        containerRef: iconRef,
        tooltip: props.tooltip
    });

    let handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
            
        props.onClick({
            ...iconClickable,
            clickEvent: e
        });
    }

    return (
        <i
            ref={iconRef}
            className={`${props.icon} ${props.classes ?? ''} clickable hover-font-color-highlight`}
            style={props.styles}
            onClick={handleIconClick}
        />
    )
}