import { AgTechDataAction, useDataActionExecutor } from "agtech/core/data/actions/AgTechDataActions";
import { useAgTechGetRequest, useAgTechImageRequest } from "agtech/core/data/http/AgTechApiGetRequests";
import { useAgTechHttpPostRequest, useAgTechHttpPostEntityRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { useContactDataRefreshContactAction } from "./contacts/ContactDataActions";
import { AgTechAppLoadingStep } from "agtech/core/app/AgTechApplication";
import { AgTechContact } from "./contacts/ContactData";
import { DefaultDocumentHeaderConfiguration, DefaultInvoiceDocumentConfiguration, DocumentHeaderConfiguration, InvoiceDocumentConfiguration, SaleOperation, operationDataSlice } from "./SaleDataOperation";
import { ContactNote } from "app/data/operation/contacts/notes/ContactDataNotes";
import { ContactCredit } from "app/data/operation/contacts/credits/ContactDataCredits";

/// Operation DTOs

declare type ContactDataDTO = AgTechContact & {
    notes: ContactNote[],
    credits: ContactCredit[]
}

declare type SaleOperationDataDTO = SaleOperation & {
    contacts: ContactDataDTO[]
}

/// Operation Actions

export const useOperationDataLoadOperationAction = (): AgTechDataAction<any, any> => {
    let operationDataGetRequest = useAgTechGetRequest<SaleOperationDataDTO>();
    let refreshContactsAction = useContactDataRefreshContactAction();

    return {
        name: 'LoadOperationData',
        action: async () => {
            return await operationDataGetRequest.get({
                path: 'Operation/GetOperationData'
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(operationDataSlice.actions.setOperation, {
                operationId: props.responseData.id ?? 0,
                operationName: props.responseData.operationName ?? '',
                operationHostName: props.responseData.operationHostName ?? '',
                documentHeaderConfiguration: props.responseData.documentHeaderConfiguration ?? DefaultDocumentHeaderConfiguration,
                invoiceDocumentConfiguration: props.responseData.invoiceDocumentConfiguration ?? DefaultInvoiceDocumentConfiguration,
                needsSetup: props.responseData.needsSetup,
                address: props.responseData.address ?? '',
                city: props.responseData.city ?? '',
                stateAbbreviation: props.responseData.stateAbbreviation ?? '',
                zip: props.responseData.zip ?? '',
            });

            await props.actionExecutor.executeAction(refreshContactsAction, {
                submittedEntity: props.responseData.contacts ?? [],
                actionLoggingContext: props.actionLoggingContext
            });
        }
    }
}


export const useOperationDataSetupOperationAction = (): AgTechDataAction<any, any> => {
    let operationDataGetRequest = useAgTechHttpPostRequest<any, any>();

    return {
        name: 'SetupOperation',
        getConfiguration: props => ({
            actionExecutionMessage: 'Completing operation setup...'
        }),
        action: async () => {
            return await operationDataGetRequest.post({
                path: 'Operation/CompleteSetup',
                postedData: {}
            });
        },
        onSuccess: async props => {
            props.executeReducerAction(operationDataSlice.actions.setupOperation, {});
        }
    }
}

export const useOperationDataLoadOperationLogoAction = (): AgTechDataAction<any, string> => {
    let logoRequest = useAgTechImageRequest();

    return {
        name: 'LoadOperationLogo',
        action: async () => {
            return await logoRequest.execute('Operation/Logo');
        },
        onSuccess: async props => {
            props.executeReducerAction(operationDataSlice.actions.setOperationLogo, props.responseData);
        }
    }
}

export const useOperationDataSetOperationLogoAction = (): AgTechDataAction<File, any> => {
    let getLogoAction = useOperationDataLoadOperationLogoAction();
    let setLogoRequest = useAgTechHttpPostRequest<FormData, string>();
    let actionExecutor = useDataActionExecutor();

    return {
        name: 'SetOperationLogo',
        getConfiguration: props => ({
            actionExecutionMessage: 'Uploading your logo...'
        }),
        validate: async props => {
            props.validation.failWithWarningIf(
                !props.submittedEntity.type.startsWith('image/'),
                'The file you selected is not an image. Please select an image file.'
            );

            props.validation.failWithWarningIf(
                props.submittedEntity.size > 10000000,
                'The file you selected is too large. Please select a file smaller than 10 MB.'
            );
        },
        action: async props => {
            const formData = new FormData();
            formData.append('file', props.submittedEntity);

            let logoUpdateResponse = await setLogoRequest.post({
                path: 'Operation/UploadInvoiceLogo',
                postedData: formData
            });

            await actionExecutor.executeAction(getLogoAction, {
                submittedEntity: ''
            });

            return logoUpdateResponse;
        }
    }
}

export const useOperationDataUpdateDocumentConfigurationAction = (): AgTechDataAction<DocumentHeaderConfiguration, DocumentHeaderConfiguration> => {
    let updateConfigPostRequest = useAgTechHttpPostEntityRequest<DocumentHeaderConfiguration>();

    return {
        name: 'UpdateDocumentConfiguration',
        getConfiguration: () => ({
            actionExecutionMessage: 'Updating document headers...',
            actionConfirmationMessage: `Successfully updated document headers`,
        }),
        action: async props => {
            return await updateConfigPostRequest.post({
                path: "Operation/Settings/DocumentHeaderConfiguration",
                postedData: props.submittedEntity
            });
        },
        onSuccess: async response => {
            response.executeReducerAction(operationDataSlice.actions.updateDocumentHeaderConfiguration, response.responseData);
        }
    }
}

export const useOperationDataUpdateInvoiceDocumentConfigurationAction = (): AgTechDataAction<InvoiceDocumentConfiguration, InvoiceDocumentConfiguration> => {
    let updateConfigPostRequest = useAgTechHttpPostEntityRequest<InvoiceDocumentConfiguration>();

    return {
        name: 'UpdateInvoiceDocumentConfiguration',
        getConfiguration: () => ({
            actionExecutionMessage: 'Updating invoice configuration...',
            actionConfirmationMessage: `Successfully updated invoice configuration`,
        }),
        action: async props => {
            return await updateConfigPostRequest.post({
                path: "Operation/Settings/InvoiceDocumentConfiguration",
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async response => {
            response.executeReducerAction(operationDataSlice.actions.updateInvoiceDocumentConfiguration, response.responseData);
        }
    }
}


/// Operation Loading Steps

export const useOperationDataLoadOperationStep = (): AgTechAppLoadingStep => {
    let actionExecutor = useDataActionExecutor();
    let operationDataAction = useOperationDataLoadOperationAction();

    return {
        loadingDescription: 'Loading operation data...',
        loadingAction: async () => {
            let loadingResult = await actionExecutor.executeAction(operationDataAction, {
                submittedEntity: undefined
            });

            return loadingResult.success !== undefined;
        }
    }
}

export const useOperationDataLoadOperationLogoStep = (): AgTechAppLoadingStep => {
    let actionExecutor = useDataActionExecutor();
    let logoRequest = useOperationDataLoadOperationLogoAction();

    return {
        loadingDescription: 'Loading operation data...',
        loadingAction: async () => {
            try
            {
                await actionExecutor.executeAction(logoRequest, {
                    submittedEntity: undefined
                });
            }
            catch (error)
            {
                console.error('Unable to load operation log: ' + error);
            }

            return true;
        }
    }
}