import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { IAgTechAppContext, useAppContext } from "agtech/core/app/AgTechAppContext";
import { AgTechApiError } from "agtech/core/data/http/AgTechCoreApiRequests";
import { AgTechMessage } from "agtech/core/functions/AgTechAppMessaging";
import { logAction, logDevError, logEvent } from "agtech/core/logging/AgTechLogger";
import { delay } from "agtech/core/utilities/AgTechUtilities";
import React from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";

export interface IConvertToActionData<TSourceData, TActionData> {
    convertToActionData: (sourceData: TSourceData) => TActionData
}

export type AgTechDataAction<TInput, TOutput> = {
    name: string,
    getConfiguration?: (props: AgTechDataActionGetActionConfigurationProps<TInput>) => AgTechDataActionConfiguration,
    validate?: (props: AgTechDataActionValidationProps<TInput>) => Promise<void>,
    confirm?: (props: AgTechDataActionConfirmationProps<TInput>) => Promise<boolean>,
    action: (props: AgTechDataActionGetDataActionProps<TInput>) => Promise<AgTechDataActionExecutionResult<TOutput>> | Promise<AgTechDataActionExecutionResult<TOutput>>,
    onSuccess?: (props: AgTechDataActionSuccessfulExecutionHandlingProps<TInput, TOutput>) => Promise<void>,
    onFailure?: (failureHandlerProps: AgTechDataActionFailedExecutionHandlingProps<TInput>) => Promise<void>,
    isBackgroundAction?: boolean
}

export type AgTechCoreDataActionProps<TInput> = {
    originalEntity?: TInput,
    submittedEntity: TInput,
    actionLoggingContext?: AgTechDataActionLoggingContext<TInput, any>,
}

export type AgTechCoreDataActionHandlers<TOutput> = {
    onActionExecuted?: (result: AgTechDataActionExecutionResult<TOutput>) => Promise<any>
}

/// Context

export type DataActionContextProps =  & {
    actionConfiguration: AgTechDataActionConfiguration
}

export type AgTechDataActionContext<TInput, TOutput> = AgTechDataActionEventHandlers<TInput, TOutput> & {
    actionName: string,
}

export type AgTechDataActionEventHandlers<TInput, TOutput> = {
    onExecutionStarted?: (props: DataActionContextProps & AgTechCoreDataActionProps<TInput>) => Promise<void>,
    onExecutionSuccessful?: (props: DataActionContextProps & AgTechDataActionExecutionResult<TInput> & { 
        actionData: TInput,
        responseData: TOutput
    }) => Promise<void>,
    onExecutionCompleted?: (result: DataActionContextProps & AgTechDataActionExecutionResult<TOutput>) => Promise<void>,
    onExecutionFailed?: (props: DataActionContextProps) => Promise<void>
}

export const DataActionContext = React.createContext<AgTechDataActionContext<any, any>>({
    actionName: '',
    onExecutionStarted: async() => {},
    onExecutionCompleted: async() => {},
    onExecutionFailed: async() => {},
});

export const useDataActionContext = <TInput, TOutput>() => useContext<AgTechDataActionContext<TInput, TOutput>>(DataActionContext);

/// Configuration

export type AgTechDataActionGetActionConfigurationProps<TEntity> = AgTechCoreDataActionProps<TEntity>;

export type AgTechDataActionConfiguration = {
    actionExecutionMessage?: string,
    actionExecutionDelay?: number,
    showConfirmationMessage?: boolean, // Will default to true if not provided
    actionConfirmationMessage?: string,
    actionConfirmationDetails?: string,
    actionConfirmationDelay?: number,
    shouldLogExecution?: boolean
}

export const DefaultAgTechDataActionConfiguration: AgTechDataActionConfiguration = {
    actionExecutionMessage: ''
}

/// Validation

export type AgTechDataActionValidationProps<TEntity> = AgTechCoreDataActionProps<TEntity> & {
    validation: AgTechActionValidationResult<TEntity>
}

export class AgTechActionValidationResult<TInput> {
    appContext: IAgTechAppContext;
    wasSuccessful: boolean;
    correctedEntity: TInput;
    errorMessages: (string | AgTechMessage)[];

    constructor(appContext: IAgTechAppContext, submittedEntity: TInput) {
        this.appContext = appContext;
        this.wasSuccessful = true;
        this.correctedEntity = submittedEntity;
        this.errorMessages = [];
    }

    public failWithWarning = (message: string | AgTechMessage, correctEntity?: (currentEntity: TInput) => TInput) => {
        this.appContext.messaging.showWarning(message);

        if (correctEntity) {
            let correctedEntity = correctEntity(this.correctedEntity);
            this.correctedEntity = { ...correctedEntity };
        }

        this.wasSuccessful = false;
        this.errorMessages.push(message);
    }

    public failWithWarningIf = (condition: boolean, message: string | AgTechMessage, correctEntity?: (currentEntity: TInput) => TInput) => {
        if (condition) {
            return this.failWithWarning(message, correctEntity);
        }
    }
}

/// Confirmation

export type AgTechDataActionConfirmationProps<TEntity> = AgTechCoreDataActionProps<TEntity> & {
    appContext: IAgTechAppContext,
};

/// Execution

export type AgTechDataActionGetDataActionProps<TEntity> = {
    originalEntity?: TEntity,
    submittedEntity: TEntity,
    actionLoggingContext: AgTechDataActionLoggingContext<any, any>
};

export type AgTechDataActionSuccess<TEntity> = {
    data: TEntity
}

export type AgTechDataActionExecutionResult<TEntity> = {
    success?: AgTechDataActionSuccess<TEntity>,
    errorMessage?: string,
    wasCancelled?: boolean
}

export class DataActionResults {
    static Success = <TData, >(data: TData): AgTechDataActionExecutionResult<TData> => {
        return {
            success: {
                data: data
            }
        }
    }

    static Failed = (error: any): AgTechDataActionExecutionResult<any> => {
        return {
            errorMessage: error?.toString()
        }
    }
}

export type AgTechDataActionSuccessfulExecutionHandlingProps<TActionInputType, TActionResponseType> = AgTechCoreDataActionProps<TActionInputType> & {
    responseData: TActionResponseType,
    appContext: IAgTechAppContext,
    actionExecutor: AgTechDataActionExecutor,
    actionLoggingContext: AgTechDataActionLoggingContext<TActionInputType, TActionResponseType>,
    executeReducerAction: <TData>(action: ActionCreatorWithPayload<TData>, data: TData) => void
}

export type AgTechDataActionFailedExecutionHandlingProps<TEntity> = {
    appContext: IAgTechAppContext,
    error: AgTechApiError,
    showError: (errorMessage: string, errorDetails?: string) => void,
    submittedEntity: TEntity
}

export const useDefaultAgTechDataAction = (name: string, configuration?: AgTechDataActionConfiguration): AgTechDataAction<any, any> => {
    return {
        name: name,
        getConfiguration: () => configuration ?? DefaultAgTechDataActionConfiguration,
        action: async () => {
            return DataActionResults.Success({});
        }
    }
}

/// Action Execution Handling

export type AgTechDataActionExecutionProps<TInput, TOutput> = {
    action: AgTechDataAction<TInput, TOutput>,
    executionInputProps: AgTechCoreDataActionProps<TInput>,
    executionConfiguration: AgTechDataActionExecutionConfigurationProps<TInput, TOutput>
}

export type AgTechDataActionStepExecutionProps<TActionInput, TActionOutput, TNextStepOutput> = AgTechCoreDataActionProps<TActionInput> & AgTechDataActionExecutionConfigurationProps<TActionInput, TActionOutput> & {
    onSuccess: (output: TActionOutput) => Promise<AgTechDataActionExecutionResult<TNextStepOutput>>
}

export type AgTechDataActionExecutionConfigurationProps<TInput, TOutput> = {
    handlers?: AgTechCoreDataActionHandlers<TOutput>,
    settings?: AgTechDataActionExecutionSettings
}

export type AgTechDataActionExecutionSettings = {
    shouldConfirmExecution?: boolean
}

export type AgTechDataActionExecutionContextProps = {
    appContext: IAgTechAppContext,
    dispatch: Dispatch,
    dataActionContext?: AgTechDataActionContext<any, any>
}

export const useDataActionExecutionContextProps = (): AgTechDataActionExecutionContextProps => {
    let appContext = useAppContext();
    let dispatch = useDispatch();
    let dataActionContext = useDataActionContext();

    return {
        appContext,
        dispatch,
        dataActionContext: dataActionContext
    }; 
}

export type AgTechDataActionExecutor = {
    executeAction: <TInput, TOutput>(
        action: AgTechDataAction<TInput, TOutput>,
        props: AgTechCoreDataActionProps<TInput> & AgTechDataActionExecutionConfigurationProps<TInput, TOutput>,
    ) => Promise<AgTechDataActionExecutionResult<TOutput>>,
    executeStep: <TActionInput, TActionOutput, TNextStepOutput>(
        actionStep: AgTechDataAction<TActionInput, TActionOutput>,
        props: AgTechDataActionStepExecutionProps<TActionInput, TActionOutput, TNextStepOutput>
    ) => Promise<AgTechDataActionExecutionResult<TNextStepOutput>>,
    validateAction: <TInput, TOutput>(
        action: AgTechDataAction<TInput, TOutput>,
        props: AgTechCoreDataActionProps<TInput> & AgTechDataActionExecutionConfigurationProps<TInput, TOutput>
    ) => Promise<AgTechActionValidationResult<TInput>>
}

export const useDataActionExecutor = (): AgTechDataActionExecutor => {
    let dataActionExecutionProps = useDataActionExecutionContextProps();
    return buildDataActionExecutor(dataActionExecutionProps);
}

export const buildDataActionExecutor = <TInput, TOutput>(executionContextProps: AgTechDataActionExecutionContextProps): AgTechDataActionExecutor => {
    return {
        executeAction: async (action, props) => {
            return await executeAgTechDataAction({
                action: action,
                executionInputProps: props,
                executionConfiguration: { ...props },
                executionContextProps: executionContextProps,
            });
        },
        validateAction: async (action, props) => {
            let actionConfiguration = await getActionConfiguration({
                ...props,
                dataAction: action
            });

            let actionLoggingContext = getActionLoggingContext({
                ...props,
                dataAction: action
            });

            return await validateAction({
                action: action,
                executionInputProps: props,
                executionConfiguration: { ...props },
                executionContextProps: executionContextProps,
                dataActionConfiguration: actionConfiguration,
                dataActionLoggingContext: actionLoggingContext
            });
        },
        executeStep: async (action, props) => {
            let initialActionExecutionResult = await executeAgTechDataAction({
                action: action,
                executionInputProps: props,
                executionConfiguration: { ...props },
                executionContextProps: executionContextProps,
            });

            if (initialActionExecutionResult.success) {
                return await props.onSuccess(initialActionExecutionResult.success.data);
            }
            else {
                return {
                    success: undefined
                }
            }
        }
    }
}

export type AgTechDataActionLoggingContext<TInput, TOutput> = {
    name: string,
    execution: {
        inputProps: AgTechCoreDataActionProps<TInput>,
        result: {
            wasActionExecuted: boolean,
            wasActionSuccessful: boolean,
            resultData?: TOutput
        },
        validation?: {
            wasValid: boolean,
            validationErrors?: (string | AgTechMessage)[],
            correctedEntity?: TInput
        },
        confirmation?: {
            wasConfirmed: boolean
        },
        requests?: {
            [request: string]: {
                type: string,
                url: string,
                postedData?: any,
                responseData?: any,
                wasSuccessful: boolean
            }
        }
        childActions?: { [name: string]: AgTechDataActionLoggingContext<any, any> },
        messages: []
    }
    onSuccess?: {
        dataSentToSuccessHandler: TOutput,
        childActions?: AgTechDataActionLoggingContext<any, any>[],
        messages?: [],
        reducerActions: { [action: string]: { action: string, payload: any } }
    },
}

const executeAgTechDataAction = async <TInput, TOutput>(props: AgTechDataActionExecutionProps<TInput, TOutput> & {
    executionContextProps: AgTechDataActionExecutionContextProps
}) => {
    let executionResult: AgTechDataActionExecutionResult<TOutput> = {
        success: undefined
    };

    let dataActionLoggingContext = getActionLoggingContext({
        ...props.executionInputProps,
        dataAction: props.action
    });

    let actionConfiguration = await getActionConfiguration({
        ...props.executionInputProps,
        dataAction: props.action
    });

    let actionExecutionHelperProps: DataActionExecutorHelperProps<TInput, TOutput> = {
        ...props,
        dataActionConfiguration: actionConfiguration,
        dataActionLoggingContext: dataActionLoggingContext,
        executionContextProps: {
            ...props.executionContextProps,
            dataActionContext: props.executionContextProps.dataActionContext?.actionName === props.action.name
                ? props.executionContextProps.dataActionContext
                : undefined
        }
    }

    let validationResult = await validateAction(actionExecutionHelperProps);

    if (validationResult && validationResult.wasSuccessful) {       
        let shouldConfirmAction = props.executionConfiguration.settings?.shouldConfirmExecution ?? true;
        let shouldExecuteAction = shouldConfirmAction ? await confirmAction(actionExecutionHelperProps): true;

        if (shouldExecuteAction) {
            executionResult = await executeAction(actionExecutionHelperProps);

            if (props.executionConfiguration.handlers?.onActionExecuted) {
                await props.executionConfiguration.handlers.onActionExecuted(executionResult);
            }

            dataActionLoggingContext.execution.result = {
                wasActionExecuted: !(executionResult.wasCancelled ?? false),
                wasActionSuccessful: executionResult.success !== undefined,
                resultData: executionResult.success?.data
            };

            if (executionResult.success) {
                await handleSuccessfulActionExecution({
                    ...actionExecutionHelperProps,
                    executionResultData: executionResult.success.data
                });
            }
            else {
                await handleFailedActionExecution({
                    ...actionExecutionHelperProps,
                    executionResult
                });
            }
        }
        else {
            executionResult.wasCancelled = true;
        }
    }

    if (actionConfiguration.shouldLogExecution ?? true) {
        if (props.executionInputProps.actionLoggingContext) {
            props.executionInputProps.actionLoggingContext.execution.childActions =
                props.executionInputProps.actionLoggingContext.execution.childActions ?? {};

            props.executionInputProps.actionLoggingContext.execution.childActions[dataActionLoggingContext.name] = {
                ...dataActionLoggingContext
            };
        }
        else {
            logAction(dataActionLoggingContext);
        }
    }

    return executionResult;
}

/// Internal Action Helpers

declare type DataActionExecutorHelperProps<TInput, TOutput> = AgTechDataActionExecutionProps<TInput, TOutput> & {
    executionContextProps: AgTechDataActionExecutionContextProps,
    dataActionConfiguration: AgTechDataActionConfiguration,
    dataActionLoggingContext: AgTechDataActionLoggingContext<TInput, TOutput>,
}

export const getActionConfiguration = async <TInput, TOutput>(props: AgTechCoreDataActionProps<TInput> & {
    dataAction: AgTechDataAction<TInput, TOutput>
}
) => {
    return props.dataAction.getConfiguration
        ? props.dataAction.getConfiguration({
            ...props
        })
        : DefaultAgTechDataActionConfiguration;
}

export const getActionLoggingContext = <TInput, TOutput>(props: AgTechCoreDataActionProps<TInput> & {
    dataAction: AgTechDataAction<TInput, TOutput>
}): AgTechDataActionLoggingContext<TInput, TOutput> => {
    return {
        name: props.dataAction.name ?? 'N/A',
        execution: {
            inputProps: {
                originalEntity: props.originalEntity,
                submittedEntity: props.submittedEntity
            },
            result: {
                wasActionExecuted: false,
                wasActionSuccessful: false
            },
            messages: []
        }
    };
}

const validateAction = async <TInput, TOutput>(props: DataActionExecutorHelperProps<TInput, TOutput>) => {
    let wasValidationPerformed = false;

    let actionValidationResult: AgTechActionValidationResult<TInput> = new AgTechActionValidationResult<TInput>(
        props.executionContextProps.appContext,
        props.executionInputProps.submittedEntity
    );

    if (props.action.validate) {
        wasValidationPerformed = true;

        await props.action.validate({
            ...props.executionInputProps,
            validation: actionValidationResult
        });
    }

    if (wasValidationPerformed) {
        props.dataActionLoggingContext.execution.validation = {
            wasValid: actionValidationResult.wasSuccessful,
            validationErrors: actionValidationResult.errorMessages,
            correctedEntity: actionValidationResult.correctedEntity
        };
    }

    return actionValidationResult;
}

const confirmAction = async <TInput, TOutput>(props: DataActionExecutorHelperProps<TInput, TOutput>) => {
    let wasActionConfirmed = true;

    if (props.action.confirm) {
        wasActionConfirmed = await props.action.confirm({
            ...props.executionInputProps,
            appContext: props.executionContextProps.appContext
        });

        props.dataActionLoggingContext.execution.confirmation = {
            wasConfirmed: wasActionConfirmed
        };
    }

    return wasActionConfirmed;
}

const executeAction = async <TInput, TOutput>(props: DataActionExecutorHelperProps<TInput, TOutput>) => {
    let actionExecutionResult: AgTechDataActionExecutionResult<TOutput> = {
        success: undefined
    };

    let isBackgroundAction = props.action.isBackgroundAction ?? false;

    try
    {
        if (!isBackgroundAction && props.executionContextProps.dataActionContext?.onExecutionStarted) {
            await props.executionContextProps.dataActionContext.onExecutionStarted({
                actionConfiguration: props.dataActionConfiguration,
                ...props.executionInputProps
            });
        }

        actionExecutionResult = await props.action.action({
            ...props.executionInputProps,
            actionLoggingContext: props.dataActionLoggingContext
        });

        if (props.dataActionConfiguration.actionConfirmationDelay) {
            await delay(props.dataActionConfiguration.actionConfirmationDelay);
        }
    }
    catch (e) {
        actionExecutionResult = {
            success: undefined,
            errorMessage: 'An error occurred'
        }
    };

    return actionExecutionResult;
}

const handleSuccessfulActionExecution = async <TInput, TOutput>(
    props: DataActionExecutorHelperProps<TInput, TOutput> & {
        executionResultData: TOutput,
    }
) => {
    let isBackgroundAction = props.action.isBackgroundAction ?? false;

    if (!isBackgroundAction && props.executionContextProps.dataActionContext?.onExecutionSuccessful) {
        await props.executionContextProps.dataActionContext.onExecutionSuccessful({
            actionConfiguration: props.dataActionConfiguration,
            actionData: props.executionInputProps.submittedEntity,
            responseData: props.executionResultData
        });
    }

    if (props.action.onSuccess) {
        props.dataActionLoggingContext.onSuccess = {
            dataSentToSuccessHandler: props.executionResultData,
            reducerActions: {}
        }

        await props.action.onSuccess({
            ...props.executionContextProps,
            responseData: props.executionResultData,
            originalEntity: props.executionInputProps.originalEntity,
            submittedEntity: props.executionInputProps.submittedEntity,
            actionExecutor: buildDataActionExecutor({
                ...props.executionContextProps
            }),
            actionLoggingContext: props.dataActionLoggingContext,
            executeReducerAction: (action, data) => {
                let reducerActionName = action.toString();

                if (props.dataActionLoggingContext.onSuccess) {
                    props.dataActionLoggingContext.onSuccess.reducerActions[reducerActionName] = {
                        action: reducerActionName,
                        payload: data
                    };
                }

                props.executionContextProps.dispatch(action(data));
            }
        });
    }

    if (!isBackgroundAction && props.executionContextProps.dataActionContext?.onExecutionCompleted) {
        await props.executionContextProps.dataActionContext.onExecutionCompleted({
            actionConfiguration: props.dataActionConfiguration,
            success: {
                data: props.executionResultData
            }
        });
    }
}

const handleFailedActionExecution = async <TInput, TOutput>(props: DataActionExecutorHelperProps<TInput, TOutput> & { executionResult: AgTechDataActionExecutionResult<TOutput> }) => {
    let isBackgroundAction = props.action.isBackgroundAction ?? false;

    if (!isBackgroundAction && props.executionContextProps.dataActionContext?.onExecutionFailed) {
        await props.executionContextProps.dataActionContext.onExecutionFailed({
            actionConfiguration: props.dataActionConfiguration,
            ...props.executionInputProps
        });
    }

    if (props.action.onFailure) {
        await props.action.onFailure({
            appContext: props.executionContextProps.appContext,
            error: {
                message: props.executionResult.errorMessage ?? 'An error occurred',
                statusCode: 400
            },
            showError: (message, details) => props.executionContextProps.appContext.messaging.showError({
                header: message,
                details
            }),
            submittedEntity: props.executionInputProps.submittedEntity
        })
    }
}

export const executeReducerAction = (func: () => void) => {
    try
    {
        func();
    }
    catch (e) {
        logEvent('ReducerActionError', {
            exception: e
        });
    }
}