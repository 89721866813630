import React, { FunctionComponent, useRef, } from "react";
import ReactDOM from "react-dom";

let AgTechPortal = (props: { children: React.ReactNode }) => {
    const portalContainer = React.useMemo(() => {
        let containerDiv = document.createElement("div");
        containerDiv.classList.add("agtech-portal-backdrop");
        
        return containerDiv;
    }, []);

    React.useEffect(() => { 
        const target = document.getElementById("agtech-app");

        if (target) {
            let existingBackdrops = target.getElementsByClassName('agtech-portal-backdrop');

            portalContainer.classList.add('active');

            if (existingBackdrops && existingBackdrops.length === 0) {
                portalContainer.classList.add('first');
            }

            target.appendChild(portalContainer);
        }

        return () => {
            portalContainer.classList.remove('active');

            // Give the portal time to fade out before removal (300ms)
            window.setTimeout(function() {
                if (target) {
                    target.removeChild(portalContainer);
                }
            }, 400);
        };

    }, []);

    return ReactDOM.createPortal(props.children, portalContainer);
};

export const useAgTechLayerCount = () => {
    return useRef(document.getElementsByClassName('agtech-app-layer').length);
}

export default AgTechPortal;