import { AgTechActionButton } from 'agtech/web/components/Buttons/AgTechButtons';
import LotActionMenu from 'app/areas/lots/components/menus/LotActionMenu';
import LotCategoryActionMenu from 'app/areas/lots/components/menus/LotCategoryActionMenu';
import { SaleLotCategory } from 'app/data/lots/categories/LotCategoryData';
import React from 'react'

export type LotActionButtonProps = {
    lotId: number
}

export const LotActionButton = (props: LotActionButtonProps) => {
    return (
        <AgTechActionButton classes='stretched centered container'>
            <LotActionMenu lotId={props.lotId} />
        </AgTechActionButton>
    )
}

export const LotCategoryActionButton = (props: { lotCategory: SaleLotCategory }) => {
    return (
        <AgTechActionButton classes='stretched centered container'>
            <LotCategoryActionMenu lotCategory={props.lotCategory} />
        </AgTechActionButton>
    )
}