import { AgTechFormContext } from 'agtech/core/forms/AgTechFormContext';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import { AgTechEntitySelection } from 'agtech/web/components/Grids/AgTechGridEntities';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities';
import { SaleDiscountActionButton } from 'app/areas/discounts/components/buttons/SaleDiscountButtons';
import SaleDiscountDialog from 'app/areas/discounts/dialogs/SaleDiscountDialog';
import { SaleInvoiceDialogFormData } from 'app/areas/invoices/dialogs/InvoiceCreationDialog';
import { SaleDiscount, useSaleDiscounts } from 'app/data/sales/discounts/SaleDiscountData';
import React, { useEffect } from 'react'

export type InvoiceDiscountsSelectionGridProps = {
    formContext: AgTechFormContext<SaleInvoiceDialogFormData>
}

const InvoiceDiscountsSelectionGrid = (props: InvoiceDiscountsSelectionGridProps) => {
    let gridConfiguration = useInvoiceDiscountsSelectionGridConfiguration(props);
    
    return <AgTechGrid {...gridConfiguration} />
};

const useInvoiceDiscountsSelectionGridConfiguration = (props: InvoiceDiscountsSelectionGridProps): AgTechGridProps<AgTechEntitySelection<SaleDiscount>> => {
    let invoiceSubmissionData = props.formContext.entity;
    let discounts = useSaleDiscounts();

    useEffect(() => {
        let updatedDiscounts = discounts.map(d => props.formContext.entity.selectedDiscounts.filter(s => s.id === d.id)[0] ?? {
            id: d.id,
            entity: d,
            isSelected: false
        });

        if (updatedDiscounts.length !== invoiceSubmissionData.selectedDiscounts.length) {
            props.formContext.updateFormEntity(curr =>({
                ...curr,
                selectedDiscounts: [...updatedDiscounts]
            }));
        }
    }, [discounts]);

    return {
        items: invoiceSubmissionData.selectedDiscounts,
        columns: [
            {
                header: 'Name',
                value: d => d.entity.name,
                size: 3
            },
            {
                header: 'Discount',
                value: d => d.entity.isPercentage ?  `${d.entity.amount}%` : AgTechMoneyFormatter.format(d.entity.amount),
                size: 1
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return prev.entity.name === curr.entity.name &&
                   prev.entity.amount === curr.entity.amount;
        },
        selectionConfig: {
            isItemSelected: c => c.isSelected,
            onItemSelected: async c => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    selectedDiscounts: [...curr.selectedDiscounts.map(d => d.id === c.entity.id
                        ? { ...d, isSelected: true }
                        : { ...d }
                    )]
                }));
            },
            onItemDeSelected: async c => {
                props.formContext.updateFormEntity(curr =>({
                    ...curr,
                    selectedDiscounts: [...curr.selectedDiscounts.map(d => d.id === c.entity.id
                        ? { ...d, isSelected: false }
                        : { ...d }
                    )]
                }));
            }
        },
        noRecordsConfig: {
            message: "There are no discounts for this sale in the system.",
            action: {
                prompt: 'To create one, click the button below',
                button: () => (
                    <SaleDiscountDialog.Button
                        classes='highlight'
                    />
                )
            }
        },
        actions: {
            rendering: c => (
                <SaleDiscountActionButton
                    discount={c.entity}
                />
            )
        }
    };
}

export default InvoiceDiscountsSelectionGrid;