import React, { PropsWithChildren } from 'react'

export type AgTechMetricProps = {
    header: string,
    value: string
}

let AgTechMetric = (props: PropsWithChildren<AgTechMetricProps>) => {
    return (
        <div className="rounded bordered left-bordered-thick-highlight bg-white wide column-based px-3 py-2-half pl-4 container">
            <div className="tall row-based container">
                <h1 className="font-bold font-size-emphasized line-height-1">{props.value}</h1>
                <h1 className="font-size-standard font-color-secondary font-bold mt-2">{props.header}</h1>
            </div>
            <div className="tall ml-auto vertically-centered container">
                {props.children}
            </div>
        </div>
    );
};

export default AgTechMetric;