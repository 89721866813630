import { logDev, logEvent } from 'agtech/core/logging/AgTechLogger';
import { AgTechBadge, AgTechBadgeColor } from 'agtech/web/components/Badges/AgTechBadges';
import AgTechGrid from 'agtech/web/components/Grids/AgTechGrid';
import { AgTechGridTextControl } from 'agtech/web/components/Grids/Controls/AgTechGridTextControl';
import { AgTechGridProps } from 'agtech/web/components/Grids/Data/AgTechGridProps';
import { AgTechGridEditConfiguration } from 'agtech/web/components/Grids/Features/AgTechGridEditing';
import LotStatusBadge from 'app/areas/lots/components/badges/LotStatusBadge';
import LotCategoryGridSelectionControl from 'app/areas/lots/components/controls/LotCategoryGridSelectionControl';
import { SaleLotCategory, useLotCategoryData } from 'app/data/lots/categories/LotCategoryData';
import { SaleLot, isLotInvoiced, isLotTheSame, useSaleLots } from 'app/data/lots/LotData';
import { useLotDataWriteLotAction } from 'app/data/lots/LotDataActions';
import React from 'react'
import LotSaleOrderUpdateDialog from '../../dialogs/LotSaleOrderUpdateDialog';

export type SaleLotModificationGridProps = {
    lotFilter?: (lot: SaleLot) => boolean
}

declare type SaleLotModificationGridEntity = {
    id: number,
    lot: SaleLot,
    lotCategory?: SaleLotCategory
}

const SaleLotModificationGrid = (props: SaleLotModificationGridProps) => {
    let lotEntityConverter = useLotToGridEntityConverter();
    let lotEditConfig = useLotModificationGridEditConfig();

    logDev('Rendering sale lot modification grid');

    let lots = useSaleLots();
    let lotsToShow = props.lotFilter ? lots.filter(props.lotFilter) : lots;
    let lotGridEntities: SaleLotModificationGridEntity[] = lotsToShow.map(lot => lotEntityConverter.convertLotToEntity(lot));

    let saleLotGridConfiguration: AgTechGridProps<SaleLotModificationGridEntity> = {
        header: {
            title: 'Edit Sale Lots',
            content: () => <LotSaleOrderUpdateDialog.Button />
        },
        items: lotGridEntities,
        columns: [
            {
                header: 'Sale Order',
                value: l => l.lot.saleOrder,
                width: '120px',
                classes: 'centered emphasized'
            },
            {
                header: 'Lot Number',
                value: (l, update) => (
                    <AgTechGridTextControl
                        value={l.lot.lotNumber ?? ''}
                        onUpdated={val => {
                            update(e => ({
                                ...e,
                                lot: { ...e.lot, lotNumber: val }
                            }));
                        }}
                    />
                )
            },
            {
                header: 'Lot Description',
                value: (l, update) => (
                    <AgTechGridTextControl
                        value={l.lot.lotDescription ?? ''}
                        onUpdated={val => {
                            update(e => ({
                                ...e,
                                lot: { ...e.lot, lotDescription: val }
                            }));
                        }}
                    />
                ),
                size: 2
            },
            {
                header: 'Lot Category',
                value: (l, update) => (
                    <LotCategoryGridSelectionControl
                        lot={l.lot}
                        onLotCategorySelected={async sel => {
                            update(e => ({
                                id: e.id,
                                lot: {...e.lot, lotCategoryId: sel?.id },
                                lotCategory: sel ? { ...sel } : undefined
                            }));
                        }}
                    />
                )
            },
            {
                header: 'Status',
                value: l => <LotStatusBadge lotId={l.lot.id} />,
                width: '100px',
                classes: 'px-3'
            }
        ],
        isRowEntityTheSame: (prev, curr) => {
            return isLotTheSame(prev.lot, curr.lot);
        },
        editConfig: lotEditConfig,
        searchConfig: {
            options: [
                { field: 'Lot Number', value: lot => lot.lot.lotNumber },
                { field: 'Description', value: lot => lot.lot.lotDescription },
                { field: 'Purchasing Bidder', value: lot => lot.lot.bidderNumber ?? '' },
            ]
        }
    };

    return <AgTechGrid {...saleLotGridConfiguration} />
};

const useLotModificationGridEditConfig = (): AgTechGridEditConfiguration<SaleLotModificationGridEntity> => {
    let lotWriteAction = useLotDataWriteLotAction();
    let lotEntityConverter = useLotToGridEntityConverter();

    return {
        onEntityUpdated: async props => {
            let lotUpdateResult = await props.actionExecutor.executeAction(lotWriteAction, {
                originalEntity: props.originalItem.lot,
                submittedEntity: props.entity.item.lot
            });

            return {
                success: {
                    data: lotUpdateResult.success
                        ? lotEntityConverter.convertLotToEntity(lotUpdateResult.success.data)
                        : props.originalItem
                }
            }
        }
    };
}

const useLotToGridEntityConverter = () => {
    let lotCategoryData = useLotCategoryData();   

    return {
        convertLotToEntity: (lot: SaleLot): SaleLotModificationGridEntity => ({
            id: lot.id,
            lot: { ...lot },
            lotCategory: lot.lotCategoryId ? lotCategoryData[lot.lotCategoryId] : undefined
        }) 
    }
};

export default React.memo(SaleLotModificationGrid, () => true) as typeof SaleLotModificationGrid;