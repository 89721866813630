import { AgTechHttpResponses, AgTechApiEntityResponse, AgTechHttpRequest, convertHttpResponse, convertFromEntityResponse, AgTechHttpResponse } from "agtech/core/data/actions/AgTechHttpRequests";
import { AgTechDataAction, DataActionResults } from "agtech/core/data/actions/AgTechDataActions";
import { useAgTechGetRequest, useAgTechApiDownloadRequest, useAgTechGetDtoRequest } from "agtech/core/data/http/AgTechApiGetRequests";
import { useAgTechHttpPostEntityRequest, useAgTechHttpPostRequest } from "agtech/core/data/http/AgTechApiPostRequests";
import { getCurrentSale, getLot } from "app/data/SalesAppDataStore";
import { UnprocessedLotSale, ProcessedSaleInvoice, Payment, invoicingDataSlice } from "app/data/invoicing/InvoicingData";
import { SaleLot } from "app/data/lots/LotData";
import { useLotDataMarkLotAsInvoicedAction } from "app/data/lots/LotDataActions";
import { Sale } from "app/data/sales/SaleData";
import { getContact } from "app/data/operation/contacts/ContactData";
import { useAgTechHttpPutEntityRequest } from "agtech/core/data/http/AgTechApiPutRequests";
import { useAgTechHttpDeleteRequest } from "agtech/core/data/http/AgTechApiDeleteRequests";

// Invoicing DTOs

declare type CreateInvoicePostDTO = {
    saleId: number;
    invoiceNumber: string,
    checkNumber: string,
    bidderId: number,
    contactId: number,
    date: Date,
    lots: number[],
    discounts: number[],
    credits: number[]
}

declare type InvoiceCoreDetailsDTO = {
    invoiceNumber: string,
    invoiceTotal: number,
    numberOfLots: number,
    numberOfDiscountsApplied?: number,
    numberOfCreditsApplied?: number,
    purchaserName: string,
    invoiceDate: Date,
    contactId: number
}

declare type ProcessedInvoiceDTO = InvoiceCoreDetailsDTO & {
    id: number,
}

export type InvoiceDetailsDTO = InvoiceCoreDetailsDTO & {
    invoiceId: number,
    payments: Payment[]
}

export type SaleParticipant = {
    bidderId: number,
    name?: string,
    contactId?: number,
    bidderNumber?: string,
}

export type SaleParticipantUnProcessedSalesSummary = SaleParticipant & {
    numberOfLots: number,
    totalPurchasePrice: number
}

// Invoicing Getters

export const useInvoiceDataGetUnProcessedSalesRequest = (): AgTechHttpRequest<any, SaleParticipantUnProcessedSalesSummary[]> => {
    let getInvoiceNumberRequest = useAgTechGetRequest<AgTechApiEntityResponse<SaleParticipantUnProcessedSalesSummary[]>>();

    return {
        execute: props => getInvoiceNumberRequest.get({
            path: 'Lot/Unprocessed/' + getCurrentSale().id
        })
        .then(res => convertHttpResponse(res))
    }
}

export const useInvoiceDataGetLotsToInvoiceRequest = (): AgTechHttpRequest<number, SaleLot[]> => {
    let getLotsToInvoiceRequest = useAgTechGetDtoRequest<AgTechApiEntityResponse<{ lotId: number, percentage: number }[]>, SaleLot[]>();

    return {
        execute: async props => getLotsToInvoiceRequest.getDtoAndConvert({
            path: 'Lot/GetForInvoicingByBidder/' + props.data,
            convert: lots => {
                return lots.data.map(lot => {
                    let lotInSale = getLot(lot.lotId);

                    return {
                        ...lotInSale,
                        purchasePrice: (lotInSale.purchasePrice ?? 0) * lot.percentage
                    }
                });
            }
        })
    }
}

export const useInvoiceDataGetNewInvoiceNumberRequest = (): AgTechHttpRequest<Sale, number> => {
    let getInvoiceNumberRequest = useAgTechGetRequest<AgTechApiEntityResponse<number>>();

    return {
        execute: async props => getInvoiceNumberRequest.get({
            path: 'Invoice/GetInvoiceNumber/' + props.data.id
        }).then(res => convertHttpResponse(res))
    }
}

export const useInvoiceDataGetUnprocessedSalesForBidderRequest = (): AgTechHttpRequest<string, UnprocessedLotSale[]> => {
    let unprocessedLotsRequest = useAgTechGetRequest<UnprocessedLotSale[]>();

    return {
        execute: props => unprocessedLotsRequest.get({
            path: 'LotBidderPurchase/GetUnprocessedSales',
            onSuccess: response => {
                let unprocessedSales: UnprocessedLotSale[] = response.body
                    .filter(item => item.bidderNumber === props.data)
                    .map<UnprocessedLotSale>(item => {
                        return {
                            id: item.id,
                            bidderNumber: item.bidderNumber,
                            bidderName: item.bidderName,
                            numberOfLots: item.numberOfLots,
                            totalPurchasePrice: item.totalPurchasePrice,
                            contactId: item.contactId
                        }
                    });

                return AgTechHttpResponses.Success(unprocessedSales);
            },
            onError: failure => failure.showError('Unable to retrieve unprocessed sales for bidder. Please try again.')
        })
    }
}

export const useInvoiceDataGetUnpaidSalesRequest = (): AgTechHttpRequest<any, ProcessedSaleInvoice[]> => {
    let getUnpaidInvoicesRequest = useAgTechGetRequest<AgTechApiEntityResponse<ProcessedInvoiceDTO[]>>();

    return {
        execute: async () => {
            let unprocessedSalesResult = AgTechHttpResponses.Failed('An error occurred while retrieving unpaid invoices.');

            await getUnpaidInvoicesRequest.get({
                path: 'Invoice/GetUnpaidInvoices/' + getCurrentSale().id,
                onSuccess: response => {
                    let processedInvoices = response.body.data;
                    let processedInvoicesWithLots = processedInvoices.filter(sl => sl.numberOfLots && sl.numberOfLots > 0);
    
                    let unprocessedInvoices = processedInvoicesWithLots.map<ProcessedSaleInvoice>(inv => {
                        return {
                            ...inv,
                            numberOfDiscountsApplied: inv.numberOfDiscountsApplied ?? 0,
                            numberOfCreditsApplied: inv.numberOfCreditsApplied ?? 0
                        }
                    });
    
                    unprocessedSalesResult = AgTechHttpResponses.Success(unprocessedInvoices);
                }
            });

            return unprocessedSalesResult;
        }
    }
}

export const useInvoiceDataGetPaidSalesRequest = (): AgTechHttpRequest<any, ProcessedSaleInvoice[]> => {
    let getUnpaidInvoicesRequest = useAgTechGetRequest<AgTechApiEntityResponse<ProcessedInvoiceDTO[]>>();

    return {
        execute: async () => {
            let unprocessedSalesResult = AgTechHttpResponses.Failed('An error occurred while retrieving unpaid invoices.');

            await getUnpaidInvoicesRequest.get({
                path: 'Invoice/GetPaidInvoices/' + getCurrentSale().id,
                onSuccess: response => {
                    let processedInvoices = response.body.data;
                    let processedInvoicesWithLots = processedInvoices.filter(sl => sl.numberOfLots && sl.numberOfLots > 0);
    
                    let unprocessedInvoices = processedInvoicesWithLots.map<ProcessedSaleInvoice>(inv => {
                        return {
                            ...inv,
                            numberOfDiscountsApplied: inv.numberOfDiscountsApplied ?? 0,
                            numberOfCreditsApplied: inv.numberOfCreditsApplied ?? 0
                        }
                    });
    
                    unprocessedSalesResult = AgTechHttpResponses.Success(unprocessedInvoices);
                }
            });

            return unprocessedSalesResult;
        }
    }
}

export type InvoiceDocumentGetDTO = {
    invoiceId: number,
    fileName: string
}

export const useInvoiceDataGetInvoiceDocumentHttpRequest = (): AgTechHttpRequest<{ invoiceId: number }, Blob> => {
    let invoiceDownloadRequest = useAgTechApiDownloadRequest();
    
    return {
        execute: async props => {
            return await invoiceDownloadRequest.execute({
                path: 'Invoice/GetInvoiceDocument/' + props.data.invoiceId,
            });
        }
    }
}

export const useInvoiceDataDownloadInvoiceDocumentHttpRequest = (): AgTechHttpRequest<InvoiceDocumentGetDTO, Blob> => {
    let invoiceDownloadRequest = useAgTechApiDownloadRequest();
    
    return {
        execute: async props => {
            return await invoiceDownloadRequest.download({
                path: 'Invoice/GetInvoiceDocument/' + props.data.invoiceId,
                fileNameWithExtension: props.data.fileName
            });
        }
    }
}

export const useInvoiceDataGetSampleInvoiceRequest = (): AgTechHttpRequest<any, any> => {
    let sampleInvoiceDownloadRequest = useAgTechApiDownloadRequest();

    return {
        execute: async () => await sampleInvoiceDownloadRequest.download({
            path: 'Invoice/GetSampleInvoiceDocument/',
            fileNameWithExtension: `Sample_Invoice_T1_Test_${new Date().toDateString()}.pdf`
        })
    }
}

export const useInvoiceDataGetInvoiceDetailsHttpRequest = (): AgTechHttpRequest<number, InvoiceDetailsDTO> => {
    let getUnpaidInvoicesRequest = useAgTechGetRequest<AgTechApiEntityResponse<InvoiceDetailsDTO>>();

    return {
        execute: async props => {
            return await getUnpaidInvoicesRequest.get({
                path: 'Invoice/GetInvoiceDetails/' + getCurrentSale().id + '/' + props.data,
            })
            .then(res => convertHttpResponse(res))
        }
    }
}

export const useInvoiceDataGetPaymentsOnInvoice = (): AgTechHttpRequest<number, Payment[]> => {
    let getPaymentsRequest = useAgTechGetRequest<AgTechApiEntityResponse<Payment[]>>();

    return {
        execute: async props => {
            return await getPaymentsRequest.get({
                path: `Invoice/${props.data}/Payments`
            })
            .then(res => convertHttpResponse(res))
        }
    }
}

/// Invoicing Actions

export type CreateInvoiceActionData = {
    invoiceNumber: string,
    checkNumber: string,
    bidderId: number,
    contactId: number,
    lots: number[]
    discounts: number[],
    credits: number[],
    date: Date,
    invoiceTotal: number
}

export type InvoiceCreationResult = {
    invoiceId: number,
    invoiceDocument: Blob | undefined
}

export const useInvoiceDataCreateInvoiceAction = (): AgTechDataAction<CreateInvoiceActionData, InvoiceCreationResult> => {
    let submitInvoicePostRequest = useAgTechHttpPostRequest<CreateInvoicePostDTO, AgTechApiEntityResponse<number>>();

    let markLotAsInvoicedAction = useLotDataMarkLotAsInvoicedAction();
    let getInvoiceDocumentRequest = useInvoiceDataDownloadInvoiceDocumentHttpRequest();

    return {
        name: 'CreateInvoice',
        getConfiguration: () => ({
            actionExecutionMessage: 'Processing invoice...',
            actionConfirmationMessage: `Successfully created new invoice`,
            actionConfirmationDelay: 1000
        }),
        validate: async props => {
            props.validation.failWithWarningIf(props.submittedEntity.invoiceNumber === '', 'Please specify an invoice number.');
            props.validation.failWithWarningIf(props.submittedEntity.lots.length === 0, 'Please specify one or more lots to invoice');
        },
        action: async props => {
            let invoiceCreationResponse = await submitInvoicePostRequest.post({
                path: `Invoice/SubmitForProcessSale`,
                postedData: {
                    saleId: getCurrentSale().id,
                    bidderId: props.submittedEntity.bidderId,
                    contactId: props.submittedEntity.contactId,
                    invoiceNumber: props.submittedEntity.invoiceNumber,
                    checkNumber: props.submittedEntity.checkNumber,
                    date: props.submittedEntity.date,
                    credits: props.submittedEntity.credits,
                    discounts: props.submittedEntity.discounts,   
                    lots: props.submittedEntity.lots,
                }
            })
            .then(res => convertHttpResponse(res));

            if (invoiceCreationResponse.success) {
                let invoiceContact = getContact(props.submittedEntity.contactId);
                let invoiceDate = new Date(props.submittedEntity.date);
                let invoicePdfFileName = `Invoice_${props.submittedEntity.invoiceNumber}_${invoiceContact.firstName}_${invoiceContact.lastName}_${invoiceDate.toDateString()}.pdf`

                let getInvoiceDocumentResponse = await getInvoiceDocumentRequest.execute({
                    data: {
                        invoiceId: invoiceCreationResponse.success?.data,
                        fileName: invoicePdfFileName
                    }
                });

                if (getInvoiceDocumentResponse.success) {
                    return DataActionResults.Success({
                        invoiceId: invoiceCreationResponse.success.data,
                        invoiceDocument: getInvoiceDocumentResponse.success.data
                    });
                }
            }

            return DataActionResults.Failed('Unable to create the invoice, please try again.'); 
        },
        onSuccess: async props => {
            await props.actionExecutor.executeAction(markLotAsInvoicedAction, {
                submittedEntity: {
                    lotIds: props.submittedEntity.lots
                }
            });

            await props.executeReducerAction(invoicingDataSlice.actions.refreshInvoicingData, undefined);
        }
    }
}

export const useInvoiceDataDownloadInvoiceAction = (): AgTechDataAction<InvoiceDocumentGetDTO, Blob> => {
    let downloadInvoiceRequest = useInvoiceDataDownloadInvoiceDocumentHttpRequest();

    return {
        name: 'DownloadInvoiceDocument',
        getConfiguration: () => ({
            actionExecutionMessage: 'Downloading invoice...',
            actionConfirmationMessage: `Successfully downloaded invoice`,
            actionConfirmationDelay: 1000
        }),
        action: async props => {
            return await downloadInvoiceRequest.execute({
                data: props.submittedEntity
            });
        },
    }
}

export const useInvoiceDataDownloadInvoiceImagesAction = (): AgTechDataAction<InvoiceDocumentGetDTO, Blob> => {
    let downloadInvoiceRequest = useInvoiceDataDownloadInvoiceDocumentHttpRequest();

    return {
        name: 'DownloadInvoiceImages',
        getConfiguration: () => ({
            actionExecutionMessage: 'Downloading invoice...',
            actionConfirmationMessage: `Successfully downloaded invoice`,
            actionConfirmationDelay: 1000
        }),
        action: async props => {
            return await downloadInvoiceRequest.execute({
                data: props.submittedEntity
            });
        },
    }

}

// Payment Actions
export const usePaymentDataWritePaymentAction = (): AgTechDataAction<Payment, Payment> => {
    let salePaymentSubmitRequest = useAgTechHttpPostEntityRequest<Payment>();

    return {
        name: 'WritePayment',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity ? 'Updating payment...' : 'Creating payment...',
            actionConfirmationMessage: `Successfully ${props.originalEntity ? 'updated' : 'created' } payment`
        }),
        validate: async props  => {
            if (props.submittedEntity.amount <= 0) {
                props.validation.failWithWarning("Please specify a valid payment amount");
            }

            if (props.submittedEntity.invoiceId == 0) {
                props.validation.failWithWarning('An invoice was not specified. This is a bug.');
            }

            if (props.submittedEntity.payerId == 0) {
                props.validation.failWithWarning('Please specify a payer.');
            }
        },
        action: async props => {
            return await salePaymentSubmitRequest.post({
                path: `Invoice/${props.submittedEntity.invoiceId}/Payment`,
                postedData: props.submittedEntity,
            });
        },
        onSuccess: async props => {
            await props.executeReducerAction(invoicingDataSlice.actions.refreshInvoicingData, undefined);
        }
    }
}

export const usePaymentDataEditPaymentAction = (): AgTechDataAction<Payment, Payment> => {
    let salePaymentSubmitRequest = useAgTechHttpPutEntityRequest<Payment>();

    return {
        name: 'EditPayment',
        getConfiguration: props => ({
            actionExecutionMessage: props.originalEntity ? 'Updating payment...' : 'Creating payment...',
            actionConfirmationMessage: `Successfully ${props.originalEntity ? 'updated' : 'created' } payment`
        }),
        validate: async props  => {
            if (props.submittedEntity.amount <= 0) {
                props.validation.failWithWarning("Please specify a valid payment amount");
            }

            if (props.submittedEntity.invoiceId == 0) {
                props.validation.failWithWarning('An invoice was not specified. This is a bug.');
            }

            if (props.submittedEntity.payerId == 0) {
                props.validation.failWithWarning('Please specify a payer.');
            }
        },
        action: async props => {
            return await salePaymentSubmitRequest.put({
                path: `Invoice/${props.submittedEntity.invoiceId}/Payment`,
                postedData: props.submittedEntity,
            });
        }
    }
}

export const usePaymentDataGetPaymentsAction = (): AgTechHttpRequest<number, Payment[]> => {
    let getPaymentsForInvoiceRequest = useAgTechGetRequest<AgTechApiEntityResponse<Payment[]>>();

    return {
        execute: props => getPaymentsForInvoiceRequest.get({
            path: `Invoice/${props.data}/Payments`
        }).then(res => convertHttpResponse(res))
    }
}

export const usePaymentDataDeletePaymentAction = (): AgTechDataAction<Payment, boolean> => {
    let paymentDeleteRequest = useAgTechHttpDeleteRequest()

    return {
        name: 'DeletePayment',
        getConfiguration: props => ({
            actionExecutionMessage: 'Deleting payment',
            actionConfirmationMessage: 'Successfully deleted payment'
        }),
        confirm: async props => {
            return await props.appContext.confirmations.askForConfirmation({
                content: {
                    header: `Are you sure you want to delete the payment with amount "$${props.submittedEntity.amount}" from the invoice?`,
                    details: "The payment will be deleted and will no longer be applied to the invoice.",
                    width: '500px',
                    confirmationButtonText: 'Delete Payment'
                },
                submission: {
                    actionExecutionMessage: 'Deleting payment...',
                    actionConfirmationMessage: 'Payment deleted'
                }
            })
        },
        action: async props => {
            return await paymentDeleteRequest.delete({
                path: `Invoice/${props.submittedEntity.invoiceId}/Payment/${props.submittedEntity.id}`
            });
        }
    }
}