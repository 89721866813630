import AgTechSelectionButton from 'agtech/web/components/Buttons/AgTechSelectionButton'
import { AgTechGridSearchState } from 'agtech/web/components/Grids/Features/AgTechGridSearching'
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader'
import { useContactSelectionProps } from 'app/areas/contacts/components/selections/ContactSelectionControl'
import { AgTechContact, useContactData } from 'app/data/operation/contacts/ContactData'
import React from 'react'
import AgTechSearchControl from 'agtech/web/components/Widgets/AgTechSearchControl'
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog'
import { getBidderNumberByContactId, getBidderNumbersMappedToContacts } from 'app/data/bidders/BidderData'
import { AgTechGridHeaderConfiguration } from 'agtech/web/components/Grids/AgTechGridHeader'
import { AgTechLink } from 'agtech/web/components/Buttons/AgTechButtons'
import LotDialog from 'app/areas/lots/dialogs/LotDialog'
import AgTechHorizontalDivider from 'agtech/web/components/MIsc/AgTechHorizontalDivider'
import AgTechFormalList from 'agtech/web/components/Lists/AgTechFormalList'
import { AgTechMoneyFormatter } from 'agtech/web/utilities/AgTechUtilities'
import { ContactLinkHover } from 'app/areas/contacts/components/buttons/ContactButtons'
import { LotBidderPurchase } from 'app/data/lots/purchases/LotPurchaseData'
import { useSaleLotData } from 'app/data/lots/LotData'
import { AgTechNumberControl } from 'agtech/web/components/Forms/Controls/AgTechNumberControl'

export type InvoiceLotSplitGroupingListProps = {
    header?: AgTechGridHeaderConfiguration,
    getLotBidderPurchases: () => LotBidderPurchase[],
    updateLotBidderPurchases: (updatedLotSplits: LotBidderPurchase[]) => void,
    isReadonly?: boolean,
    showHeader?: boolean
}

const InvoiceLotSplitGroupingList = (props: InvoiceLotSplitGroupingListProps) => {
    let lotData = useSaleLotData();
    let contactData = useContactData();
    let bidderNumberContactMap = getBidderNumbersMappedToContacts();

    let gridItems = props.getLotBidderPurchases().map(item => ({
        id: item.lotId + '_' + item.bidderNumber,
        purchase: item,
        lot: lotData.lots[item.lotId],
        contact: contactData.contacts[bidderNumberContactMap[item.bidderNumber]]
    }));

    let contactSelectionProps = useContactSelectionProps({
        contacts: Object.values(contactData.contacts),
        onContactSelected: async (sel) => {},
        selectedContactId: undefined
    });

    let isReadonly = props.isReadonly ?? false;

    return (
        <AgTechFormalList
            items={gridItems}
            itemHeight={64}
            itemRow={split => (
                <div className='stretched px-3 py-1 column-based-vertically-centered container'>
                    <AgTechTitleDetailHeader
                        title={split.contact.firstName + ' ' + split.contact.lastName}
                        titleFontSize='font-size-small'
                        onTitleHover={() => ContactLinkHover(split.contact.id)}
                        detail={`Bidder ${split.purchase.bidderNumber} ${split.contact.ranchName ? '  -  ' + split.contact.ranchName : ''}` }
                        detailFontSize='font-size-very-small'
                        content={() => (
                            <div className='column-based-vertically-centered tall container' style={{ width: 86 }}>
                                <AgTechNumberControl
                                    caption=''
                                    value={() => split.purchase.percentage}
                                    onControlValueChanged={async (val) => {
                                        let existingLotPurchases = [...props.getLotBidderPurchases()];

                                        let updatedPurchases = existingLotPurchases.map(purchase => {
                                            if (purchase.lotId === split.lot.id && purchase.bidderNumber === split.purchase.bidderNumber) {
                                                return {
                                                    ...purchase,
                                                    percentage: val
                                                }
                                            }
                                            else return { ...purchase };
                                        })

                                        props.updateLotBidderPurchases(updatedPurchases);
                                    }}
                                    unit='%'
                                    unitOnRight={true}
                                />
                            </div>
                        )}
                    />
                </div>
            )}
            grouping={{
                group: split => split.lot.id.toString(),
                groupHeaderHeight: 32,
                groupHeader: (splits) => {
                    let split = splits[0];

                    return (
                        <div className='stretched px-4 column-based-vertically-centered container'>
                            <AgTechLink
                                content={`Lot ${split.lot.lotNumber}`}
                                onClick={e => LotDialog.Open(e, { lotId: split.lot.id })}
                                classes='font-bold'
                            />
                            <AgTechHorizontalDivider />
                            <h1 className='font-size-small centered container' style={{ width: 60 }}>{AgTechMoneyFormatter.format(split.lot.purchasePrice ?? 0)}</h1>
                            <AgTechHorizontalDivider />
                            <h1 className='font-size-small'>{split.lot.lotDescription}</h1>
                            {isReadonly ? null : (
                                <AgTechSelectionButton
                                    text='Split lot'
                                    icon='plus'
                                    classes='link ml-auto'
                                    selectionProps={{
                                        ...contactSelectionProps,
                                        items: {
                                            ...contactSelectionProps.items,
                                            selectionItems: contactSelectionProps.items.selectionItems.filter(i => {
                                                return !splits.find(s => s.contact.id === i.id);
                                            }),
                                            onItemSelected: async (selectedContact) => {
                                                let selectedContactBidderNumber = getBidderNumberByContactId(selectedContact.id);

                                                if (selectedContactBidderNumber) {
                                                    props.updateLotBidderPurchases([
                                                        ...props.getLotBidderPurchases(),
                                                        { 
                                                            lotId: split.lot.id,
                                                            bidderNumber: selectedContactBidderNumber,
                                                            percentage: 0,
                                                            purchasePrice: split.lot.purchasePrice ?? 0,
                                                            isInvoiced: false
                                                        }
                                                    ]);
                                                }
                                            }
                                        },
                                        content: {
                                            ...contactSelectionProps.content,
                                            header: headerProps => (
                                                <div className='row-based stretched py-3 container'>
                                                    <AgTechTitleDetailHeader
                                                        title='Contacts'
                                                        titleFontSize='font-size-large'
                                                        content={() => (
                                                            <AgTechSearchControl
                                                                placeholder='Search contacts...'
                                                                options={[
                                                                    { field: 'Name', value: cnt => cnt.firstName + ' ' + cnt.lastName}
                                                                ]}
                                                                onUpdateSearchState={(searchState: AgTechGridSearchState<AgTechContact>) => {
                                                                    let visibleItems = headerProps.state.allItems.filter(item => searchState.selectedField.value(item).includes(searchState.filter));
                                                                    headerProps.handlers.onItemsUpdated(visibleItems);
                                                                }}
                                                                width={200}
                                                                styles={{ height: 32 }}
                                                            />
                                                        )}
                                                        contentClasses='ml-auto'
                                                    />
                                                </div>
                                            ),
                                            footer: footerProps => (
                                                <div className='wide column-based-vertically-centered container'>
                                                    <ContactDialog.Button
                                                        classes='link ml-auto'
                                                        onContactSubmitted={footerProps.handlers.onItemSelected}
                                                    />
                                                </div>
                                            ),
                                            styles: { height: 320, width: 420 }
                                        }
                                    }}
                                />
                            )}
                        </div>
                    )
                }
            }}
        />
    )
}

export default InvoiceLotSplitGroupingList