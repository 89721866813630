import { AgTechButton, AgTechButtonProps } from 'agtech/web/components/Buttons/AgTechButtons';
import { AgTechFormRow, AgTechFormTextControl, AgTechFormSection } from 'agtech/web/components/Forms/AgTechForm';
import AgTechFormDialog from 'agtech/web/components/Portals/Dialogs/AgTechFormDialog';
import { DefaultAgTechContact } from 'app/data/operation/contacts/ContactData';
import { useSaleBidders } from 'app/data/bidders/BidderData';
import { BidderContactRegistration, useBidderDataRegisterNewContactAction } from 'app/data/bidders/BidderDataActions';
import _ from 'lodash';
import React, { useState } from 'react'

export type ContactRegisterBidderDialogProps = {
    bidderNumber?: string,
    onContactRegistered?: (registeredContact: BidderContactRegistration) => Promise<void>
}

const ContactRegisterBidderDialog = (props: ContactRegisterBidderDialogProps) => {
    let registrationSubmission = useBidderDataRegisterNewContactAction();

    let bidders = useSaleBidders();
    let nextBidderNumber = _.max(bidders.map(bd => parseInt(bd.bidderNumber))) ?? 0;

    let bidderContact: BidderContactRegistration = {
        ...DefaultAgTechContact,
        bidderId: 0,
        bidderNumber: props.bidderNumber ?? (nextBidderNumber + 1).toString()
    }

    return (
        <AgTechFormDialog
            structure={{
                header: 'Register New Contact',
                submitButtonText: 'Register',
                width: 700
            }}
            formData={{
                loadInitialFormData: () => bidderContact
            }}
            formAction={{
                dataAction: registrationSubmission,
                getActionData: d => d
            }}
            behavior={{
                eventHandlers: {
                    onDialogSubmitted: async contact => {
                        if (props.onContactRegistered) {
                            await props.onContactRegistered({
                                ...contact,
                                bidderNumber: props.bidderNumber ?? ''
                            });
                        }
                    }
                }
            }}
        >
            <AgTechFormRow>
                <AgTechFormTextControl
                    entityType={bidderContact}
                    caption="First Name"
                    required={true}
                    focused={true}
                    value={entity => entity.firstName}
                    onControlValueChanged={async (e, val) => ({ ...e, firstName: val })}
                />
                <AgTechFormTextControl
                    entityType={bidderContact}
                    caption="Last Name"
                    required={true}
                    value={entity => entity.lastName}
                    onControlValueChanged={async (e, val) => ({ ...e, lastName: val })}
                />
                <AgTechFormTextControl
                    entityType={bidderContact}
                    caption="Bidder Number"
                    required={true}
                    value={entity => entity.bidderNumber}
                    onControlValueChanged={async (e, val) => ({ ...e, bidderNumber: val })}
                />
            </AgTechFormRow>
            <AgTechFormSection header='Contact Information'>
                <AgTechFormRow>
                    <AgTechFormTextControl
                        entityType={bidderContact}
                        caption="Ranch Name"
                        required={false}
                        value={entity => entity.ranchName}
                        onControlValueChanged={async (e, val) => ({ ...e, ranchName: val })}
                    />
                    <AgTechFormTextControl
                        entityType={bidderContact}
                        caption="Email Address"
                        required={false}
                        value={entity => entity.emailAddress}
                        onControlValueChanged={async (e, val) => ({ ...e, emailAddress: val })}
                    />
                    <AgTechFormTextControl
                        entityType={bidderContact}
                        caption="Phone Number"
                        value={entity => entity.phoneNumber}
                        onControlValueChanged={async (e, val) => ({ ...e, phoneNumber: val })}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
            <AgTechFormSection header="Address Information">
                <AgTechFormRow>
                    <AgTechFormTextControl
                        entityType={bidderContact}
                        caption="Address"
                        required={true}
                        value={entity => entity.address}
                        width={66}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            address: val
                        })}
                    />
                </AgTechFormRow>
                <AgTechFormRow>
                    <AgTechFormTextControl
                        entityType={bidderContact}
                        caption="City"
                        required={true}
                        value={entity => entity.city}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            city: val
                        })}
                    />
                    <AgTechFormTextControl
                        entityType={bidderContact}
                        caption="State"
                        required={true}
                        value={entity => entity.stateAbbreviation}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            stateAbbreviation: val
                        })}
                    />
                    <AgTechFormTextControl
                        entityType={bidderContact}
                        caption="Zip"
                        required={true}
                        value={entity => entity.zip}
                        onControlValueChanged={async (e, val) => ({
                            ...e,
                            zip: val
                        })}
                    />
                </AgTechFormRow>
            </AgTechFormSection>
        </AgTechFormDialog>
    )
};

ContactRegisterBidderDialog.Button = (props: ContactRegisterBidderDialogProps & AgTechButtonProps) => {
    let buttonProps: AgTechButtonProps = {
        text: "Register New Contact",
        icon: "plus",
        ...props,
        action: e => e.dialogs.openDialog({
            dialog: () => <ContactRegisterBidderDialog {...props} />
        }),
    }

    return (
        <AgTechButton {...buttonProps} />
    );
}

export default ContactRegisterBidderDialog;