import React, { useContext } from 'react'
import AgTechModal, { ModalContext } from './AgTechModal';
import AgTechGap from 'agtech/web/components/MIsc/AgTechGap';
import AgTechTitleDetailHeader from 'agtech/web/components/Headers/AgTechTitleDetailHeader';
import { AgTechButton } from 'agtech/web/components/Buttons/AgTechButtons';

export type AgTechWarningModalRenderProps = {
    header: string,
    details?: string,
    okayButtonText?: string
}

export type AgTechWarningModalProps = AgTechWarningModalRenderProps & {
    closeModal: () => void
}

let AgTechWarningModal = (props: AgTechWarningModalProps) => {
    return (
        <AgTechModal
            classes="agtech-warning-modal"
            content={() => (
                <>
                    <div className="agtech-modal-body p-4">
                        <AgTechTitleDetailHeader
                            title={props.header}
                            detail={props.details}
                        />
                    </div>
                    <div className="agtech-modal-footer">
                        <AgTechGap />
                        <AgTechButton
                            text={props.okayButtonText ?? "Okay"}
                            action={e => props.closeModal()}
                        />
                    </div>
                </>
            )}
            closeModal={props.closeModal}
        />
    )
};

let AgTechWarningModalContent = (props: AgTechWarningModalProps) => {
    let modalContext = useContext(ModalContext);

    return 
}

export default AgTechWarningModal;