import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog';
import ContactNotesGrid from 'app/areas/contacts/components/grids/ContactNotesGrid';
import ContactNoteDialog from 'app/areas/contacts/dialogs/notes/ContactNoteDialog';
import { AgTechContact, getContactFullName, useContact, useContactData, getContactNotes, useContactNotes } from 'app/data/operation/contacts/ContactData';
import React from 'react'

export type ContactNotesViewDialogProps = {
    contactId: number
}

const ContactNotesViewDialog = (props: ContactNotesViewDialogProps) => {
    let contact = useContact(props.contactId);

    return (
        <AgTechViewDialog
            header={{
                title: 'Notes - ' + getContactFullName(contact),
                detail: "Below are all active notes for this contact"
            }}
            width="800px"
            waitOnDataLoaded={() => props.contactId !== 0}
            textWhileLoading='Loading contact notes...'
        >
            <ContactNotesGrid
                contactId={props.contactId}
                gridProps={contactGridProps => {
                    return {
                        ...contactGridProps,
                        header: {
                            title: 'Notes',
                            content: () => <ContactNoteDialog.Button contactId={props.contactId} />
                        },
                        searchConfig: {
                            options: [
                                { field: 'Date', value: note => new Date(note.date).toDateString() },
                                { field: 'Note', value: note => note.note }
                            ]
                        }
                    }
                }}
            />
        </AgTechViewDialog>
    );
}

ContactNotesViewDialog.Open = (e: AgTechClickEventHandler, props: ContactNotesViewDialogProps) => e.dialogs.openDialog({
    dialog: () => <ContactNotesViewDialog {...props} />
});

ContactNotesViewDialog.Button = (props: ContactNotesViewDialogProps & AgTechButtonProps) => (
    <AgTechButton
        text='View notes'
        icon='eye'
        {...props}
        action={e => ContactNotesViewDialog.Open(e, props)}
    />
);

export default ContactNotesViewDialog;