import React from 'react'
import { AgTechButton, AgTechButtonProps, AgTechClickEventHandler } from 'agtech/web/components/Buttons/AgTechButtons';
import AgTechViewDialog from 'agtech/web/components/Portals/Dialogs/AgTechViewDialog';
import ContactListDetailPage from 'app/areas/contacts/pages/ContactDetailPage';
import ContactImportModal from 'app/areas/contacts/modals/ContactImportModal';
import ContactDialog from 'app/areas/contacts/dialogs/ContactDialog';
import AgTechActionPane from 'agtech/web/components/Headers/AgTechActionPane';

const OperationContactViewDialog = () => (
    <AgTechViewDialog
        header={{
            title: "My Contacts",
            detail: "Below is a list of all contacts for this operation, as well as details for each contact",
            content: () => (
                <AgTechActionPane
                    elements={[
                        <ContactDialog.Button
                            text='Create New Contact'
                            classes='highlight'
                        />,
                        <ContactImportModal.Button
                            classes='highlight'
                        />
                    ]}
                />
            )
        }}
        width="90%"
        textWhileLoading='Loading contacts...'
        bodyPadding='snug'
    >
        <ContactListDetailPage />
    </AgTechViewDialog>
);

OperationContactViewDialog.ButtonProps = {
    text: 'Manage Contacts',
    icon: 'address-book',
    action: (e: AgTechClickEventHandler) => e.dialogs.openDialog({
        dialog: () => <OperationContactViewDialog />
    })
}

OperationContactViewDialog.Button = (props: AgTechButtonProps) => {
    return (
        <AgTechButton
            {...OperationContactViewDialog.ButtonProps}
            {...props}
        />
    )
}

export default OperationContactViewDialog;